import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getApplicationMeetings } from "../../../../actions/applications";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import { AppState } from "../../../../store";
import ApplicationMeetingTable from "./ApplicationMeetingTable";
import styled from "styled-components";
import ApplicationMeetingContextProvider from "./ApplicationMeetingContextProvider";
import moment from "moment";
import PxCalendar from "../../../../components/PxCalendar/PxCalendar";
import { parallaxCalculation } from "../../../../utils/momentTz";

const Title = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
  height: 36px;
  font-size: 24px;
  font-weight: 500;
  padding-left: 12px;
  color: rgba(0 0 0, 0.87);
  &::before {
    position: absolute;
    left: 0;
    width: 4px;
    height: 28px;
    transform: translate(0%, 20%);
    content: "";
    border: 2px solid rgb(81, 45, 168);
    border-radius: 2px;
  }
`;

const ApplicationMeetingInfo = () => {
  const dispatch = useDispatch();
  const params: { secondId: string } = useParams();
  const [date, setDate] = useState<moment.Moment>(() => moment());
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const application = useSelector(
    (state: AppState) => state.applications.application
  );
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const [selectableDate, setSelectableDate] = useState({
    startDate: "",
    endDate: "",
  });
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );

  useEffect(() => {
    if (subEventApplication.id) {
      dispatch(getApplicationMeetings(params.secondId, subEventApplication.id));
    }
  }, [dispatch, params.secondId, subEventApplication.id]);
  useEffect(() => {
    if (subEvent) {
      !date.isBetween(
        subEvent.subEventStartDate,
        subEvent.subEventEndDate,
        undefined,
        "[]"
      ) &&
        setDate(
          moment(
            `${subEvent.subEventStartDate.split("T")[0]}T${
              subEvent.matchStartTime
            }`
          )
        );

      //달력선태기간 설정 ----------------------
      const countryCode = subEvent!.mainEvent?.countryCode;
      const countMinute =
        subEvent.matchNumPerDay! *
          (subEvent.matchReqTime! + subEvent.matchBreakTime!) -
        subEvent.matchBreakTime!;
      // 미팅시작 기준 날짜
      const defaultDate = `${subEvent!.subEventEndDate.split("T")[0]}T${
        subEvent.matchStartTime
      }:00`;
      //미팅 마지막 시간 계산
      const endTimeCalculation = moment(defaultDate).add(countMinute, "m");

      const startDate = parallaxCalculation(
        `${subEvent!.subEventStartDate.split("T")[0]}T${
          subEvent.matchStartTime
        }:00`,
        countries![countryCode! as string] as any,
        countries![country!.countryCode!] as any,
        "YYYY-MM-DD"
      );

      // 국가 시간 변경시 마지막 미팅시간이 다음날로 넘어갈 경우가 있음
      // 마지막 미팅시간 구해서 계산해줘야함
      const endDate = parallaxCalculation(
        endTimeCalculation.format("YYYY-MM-DDTHH:mm"),
        countries![countryCode! as string] as any,
        countries![country!.countryCode!] as any,
        "YYYY-MM-DD"
      );
      setSelectableDate({ startDate, endDate });
      //------------------------------------------
    }
  }, [subEvent]);

  return (
    <ApplicationMeetingContextProvider>
      <PxGridContainer direction="column">
        <PxGridItem marginBottom={10}>
          <Title>{application.name}</Title>
          <PxCalendar
            date={date}
            setDate={setDate}
            selectableEndDate={selectableDate.endDate}
            selectableStartDate={selectableDate.startDate}
          />
          {/* <SubHeadingTypo>
            {t("meetingDetail.connectionCountry")}: {country?.country}{" "}
            &nbsp;&nbsp; • &nbsp;&nbsp;&nbsp;
            {t("meetingDetail.connectionTime")}: {exposureTime()}
          </SubHeadingTypo> */}
        </PxGridItem>
        <PxGridItem>
          {subEvent && (
            <ApplicationMeetingTable subEvent={subEvent} date={date} />
          )}
        </PxGridItem>
      </PxGridContainer>
    </ApplicationMeetingContextProvider>
  );
};

export default ApplicationMeetingInfo;
