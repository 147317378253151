import React from "react";

// material comp
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

import ControlsForMany from "../Controls/ControlsForMany";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#000000",
    backgroundImage:
      "linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2))",
    height: theme.mainNavBarHeight,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 56,
  },
  toolbar: {
    maxHeight: 56,
    [theme.breakpoints.up(600)]: {
      maxHeight: 56,
      minHeight: 56,
    },
    [theme.breakpoints.down(600)]: {
      maxHeight: 56,
      minHeight: 56,
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 56,
      padding: 0,
    },
  },
  rightButtonContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
}));

const MainFooter = () => {
  const classes = useStyles();
  return (
    <AppBar className={classes.root} position="static">
      <Toolbar className={classes.toolbar}>
        <ControlsForMany />
      </Toolbar>
    </AppBar>
  );
};

export default MainFooter;
