import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  height: 100%;
  padding: 0 50px 0 50px;
  margin: auto;
  @media only screen and (max-width: 1279px) {
    padding: 0 24px 0 24px;
  }
  @media only screen and (max-width: 599px) {
    padding: 0 16px 0 16px;
  }
`;

export const Layout: React.FC = ({ children }) => (
  <Container>{children}</Container>
);
