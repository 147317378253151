import React from "react";
import ReactDOM from "react-dom";
import { StylesProvider } from "@material-ui/core/styles";

import { Provider } from "react-redux";
import store from "./store";
import { SnackbarProvider } from "notistack";
import "./i18n/i18n";

import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// FontAwesome icons
import "./utils/FontAwesome";

// bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";

// material ui global theme
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";

// layout component
import PxAlert from "./components/Alerts/PxAlert";
import App from "./App";
import PxBackDrop from "./components/BackDrop/PxBackDrop";

// break points
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 1024px
// lg, large: 1280px

const theme = createMuiTheme({
  // overrides: {
  //   MuiTypography: {
  //     root: {
  //       fontFamily: "Spoqa Han Sans Neo",
  //     },
  //     body1: {
  //       fontFamily: "Spoqa Han Sans Neo",
  //     },
  //   },
  // },
  palette: {
    primary: purple,
    secondary: green,
  },
  typography: {
    fontFamily: '"Spoqa Han Sans Neo",sans-serif !important',
  },
});

// 인터넷익스플로러를 Edge로 리다이렉트
if (window.navigator.userAgent.match(/MSIE|Internet Explorer|Trident/i)) {
  window.open("microsoft-edge:" + document.URL, "_self");
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      {/* <GlobalStyles /> */}
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            maxSnack={3}
          >
            <PxAlert />
          </SnackbarProvider>
          <PxBackDrop />
          <App />
        </MuiThemeProvider>
      </StylesProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// import * as serviceWorker from './serviceWorker';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
