import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import PanToolIcon from "@material-ui/icons/PanTool";

import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";
import useVideoContext from "../../hooks/useVideoContext";
import { LocalDataTrack } from "twilio-video";
import { useAppState } from "../AppStateProvider/hooks/useAppState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      animationName: "$blinker",
      animationDuration: "1s",
      animationIterationCount: "5",

      boxShadow: "none",
      backgroundColor: "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
  })
);

export default function ToggleHandsUpButton(props: {
  disabled?: boolean;
  backgroundColor?: string;
}) {
  const classes = useStyles();
  const [t] = useTranslation("lang", { useSuspense: false });
  const {
    localTracks,
    room: { localParticipant },
  } = useVideoContext();
  const {
    setWaitingForRequest,
    waitingForRequest,
    acceptHands,
  } = useAppState();

  const sendHandsUp = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const dataTrack = localTracks.find(
      (track) => track.kind === "data"
    ) as LocalDataTrack;
    const data = {
      audioTrack: localTracks[0],
      videoTrack: localTracks[1],
      dataTrack: localTracks[2],
      participant: localParticipant,
    };
    dataTrack.send(JSON.stringify({ type: "handsUp", handsUp: data }));
    setWaitingForRequest(true);
  };
  const wait = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    alert("발표자가 검토중입니다. 잠시만 기다려주세요");
  };

  return (
    <Tooltip
      title="Hands up"
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        className={classes.fab}
        onClick={waitingForRequest ? wait : sendHandsUp}
        disabled={acceptHands?.sid === localParticipant.sid}
        style={waitingForRequest ? { color: "#bb86fc" } : {}}
      >
        <div>
          <PanToolIcon />
          <CaptionTypo txtcolor="white">
            {t("videoController.handsUp")}
          </CaptionTypo>
        </div>
      </Fab>
    </Tooltip>
  );
}
