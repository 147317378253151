import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
    font-size:10px;
    font-weight:500;
    color:rgb(81, 45, 168);
`;

interface children {
    children : ReactNode;
}

const CompanyTypo = ({children} : children) =>{
    return <Container>{children}</Container>;
}

export default CompanyTypo;