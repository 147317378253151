import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import PxContainer from "../../../../../components/Containers/PxContainer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../store";
import { getForm } from "../../../../../actions/forms";
import CounselDetailTop from "./CounselDetailTop";
import { FormData } from "../../../../../types/models/Form";
import CounselDetailBottom from "./CounselDetailBottom";
import PxButton from "../../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../../components/Typhography/ButtonTypo";
import {
  CounselFormAnswer,
  CounselCreate,
} from "../../../../../types/models/CounselForm";
import {
  createCounselForm,
  updateCounselForm,
} from "../../../../../actions/counselForms";
import { resetMeetingAction } from "../../../../../actions/meeting";
import { useTranslation } from "react-i18next";

const CounselDetail = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, secondId } = useParams<{ id: string; secondId: string }>(); // id: 서브이벤트id, secondId: 미팅id

  const [counselFormAnswers, setCounseFormAnswers] =
    useState<CounselFormAnswer[]>();
  const [formId, setFormId] = useState<number>();
  const [counselForm, setCounselForm] = useState<FormData>();
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );

  const { sellerCounselForm, buyerCounselForm } = useSelector(
    (state: AppState) => state.subEvents.subEvent! // 자율양식 form의 id값
  );

  const form = useSelector((state: AppState) => state.forms);

  const meeting = useSelector((state: AppState) => state.meetings.meeting);

  useEffect(() => {
    return () => {
      dispatch(resetMeetingAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (meeting !== undefined) {
      if (subEventApplication.id! === meeting.meetingApplicant?.id) {
        setCounseFormAnswers(meeting.applicantMeetingSurvey);
      } else {
        setCounseFormAnswers(meeting.acceptorMeetingSurvey);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting]);

  // 상담일지 form을 서버에서 가져옴
  useEffect(() => {
    // 접속한 유저의 type 체크 (ex. seller, buyer, booth etc...)
    if (
      subEventApplication.type === "seller" &&
      sellerCounselForm !== undefined &&
      sellerCounselForm !== 0
    ) {
      dispatch(getForm(sellerCounselForm.toString()));
    } else if (
      subEventApplication.type === "buyer" &&
      sellerCounselForm !== undefined &&
      sellerCounselForm !== 0
    ) {
      dispatch(getForm(sellerCounselForm.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEventApplication.type, sellerCounselForm, buyerCounselForm]);

  // db에서 가져온 상담일지 양식을 Json형식으로 변환 후 state에 저장
  useEffect(() => {
    if (form.id !== undefined) {
      setFormId(form.id);
      const formData = JSON.parse(form.content! as string);
      setCounselForm(formData);
    }
  }, [form]);

  // 저장 onClick method, 작성한 상담일지 저장 api call
  const onSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const data: CounselFormAnswer[] = [];
    const checkBoxData: CounselFormAnswer[] = [];
    // 작성 된 자율양식 데이터를 서버로 보내기전 알맞은 형태로 가공
    counselForm!.formIds.forEach((formUuid) => {
      const counselFormAnswer: CounselFormAnswer = {
        id: counselForm!.formData[formUuid].defaultId ?? "",
        title: counselForm!.formData[formUuid].title ?? "",
        type: counselForm!.formData[formUuid].type,
        uuid: formUuid,
        formId: formId!,
      };

      switch (counselForm!.formData[formUuid].type) {
        case "single":
          counselFormAnswer["content"] =
            counselForm!.formData[formUuid].radioContent?.content;
          counselFormAnswer["answerUuid"] =
            counselForm!.formData[formUuid].radioContent?.id;
          data.push(counselFormAnswer);
          break;
        case "many":
          counselForm!.formData[formUuid].checkBoxContent?.forEach(
            ({ id, content }, index) => {
              const checkBoxForm: CounselFormAnswer = {
                id: counselForm!.formData[formUuid].defaultId ?? "",
                title: counselForm!.formData[formUuid].title ?? "",
                type: counselForm!.formData[formUuid].type,
                uuid: formUuid,
                formId: formId!,
              };
              checkBoxForm["content"] = content;
              checkBoxForm["answerUuid"] = id;
              checkBoxData.push(checkBoxForm);
            }
          );
          break;
        case "file":
          counselFormAnswer["content"] =
            counselForm!.formData[formUuid].fileContent?.fileId;
          data.push(counselFormAnswer);
          break;
        case "star":
          counselFormAnswer["content"] = counselForm!.formData[formUuid]
            .content as string;
          data.push(counselFormAnswer);
          break;
        default:
          counselFormAnswer["content"] = counselForm!.formData[formUuid]
            .content as string;
          data.push(counselFormAnswer);
          break;
      }
    });

    const answerData = data.concat(checkBoxData);

    const counsel: CounselCreate = {
      meetingId: Number(meeting?.id!),
      applicationId: Number(subEventApplication.id!),
      meetingSurveys: answerData,
    };

    // 미팅 수락자, 신청자 판단 후 상담일지 답변 업데이트 혹은 생성
    if (subEventApplication.id === meeting?.meetingAcceptor?.id) {
      if (meeting?.acceptorMeetingSurvey?.length !== 0) {
        await dispatch(updateCounselForm(counsel));
      } else {
        await dispatch(createCounselForm(counsel));
      }
    } else {
      if (meeting?.applicantMeetingSurvey?.length !== 0) {
        await dispatch(updateCounselForm(counsel));
      } else {
        await dispatch(createCounselForm(counsel));
      }
    }

    history.goBack();
  };

  return (
    <PxContainer maxWidth="sm">
      <CounselDetailTop
        meetingId={secondId}
        applicationId={subEventApplication.id}
      />
      <CounselDetailBottom
        meetingSurveys={counselFormAnswers}
        applicationId={subEventApplication.id!}
        counselForm={counselForm}
        setCounselForm={setCounselForm}
        meetingId={secondId}
      />
      <PxButton
        backgroundcolor="purple"
        padding="10px 30px 10px 30px"
        style={{ float: "right" }}
        onClick={onSubmit}
      >
        <ButtonTypo>{t("counseList.confirm")}</ButtonTypo>
      </PxButton>
      <PxButton
        className="mr-2"
        backgroundcolor="grey"
        padding="10px 30px 10px 30px"
        style={{ float: "right" }}
        onClick={() => {
          history.goBack();
        }}
      >
        <ButtonTypo>{t("counseList.cancel")}</ButtonTypo>
      </PxButton>
    </PxContainer>
  );
};

export default CounselDetail;
