import React, { createContext, ReactNode, useEffect, useState } from "react";
import { MeetingInfo } from "../../../../types/models/Meeting";
import MeetingAcceptConfirmDialog from "./MeetingAcceptConfirmDialog";
import MeetingApproveOrRejectDialog from "./MeetingApproveOrRejectDialog";
import MeetingCancelConfirmDialog from "./MeetingCancelConfirmDialog";

export interface ApplicationMeetingContextType {
  meetingRequestCancelDialogOpen: boolean;
  setMeetingRequestCancelDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedMeeting: React.Dispatch<
    React.SetStateAction<
      | {
          status?: "agree" | "disagree" | undefined;
          meetingData?: MeetingInfo | undefined;
          isConfirmed?: boolean | undefined;
          meetingDateAndTime?:
            | {
                date: string;
                time: string;
                showDate: string;
                showTime: string;
              }
            | undefined;
        }
      | undefined
    >
  >;
  meetingApproveOrRejectDialogOpen: boolean;
  setMeetingApproveOrRejectDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  meetingAcceptConfirmDialogOpen: boolean;
  setMeetingAcceptConfirmDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export const ApplicationMeetingContext = createContext<ApplicationMeetingContextType>(
  null!
);

interface ApplicationMeetingContextProviderProps {
  children: ReactNode;
}

const ApplicationMeetingContextProvider = ({
  children,
}: ApplicationMeetingContextProviderProps) => {
  const [
    meetingRequestCancelDialogOpen,
    setMeetingRequestCancelDialogOpen,
  ] = useState<boolean>(false);
  const [
    meetingApproveOrRejectDialogOpen,
    setMeetingApproveOrRejectDialogOpen,
  ] = useState<boolean>(false);
  const [
    meetingAcceptConfirmDialogOpen,
    setMeetingAcceptConfirmDialogOpen,
  ] = useState<boolean>(false);

  const [selectedMeeting, setSelectedMeeting] = useState<{
    status?: "agree" | "disagree";
    meetingData?: MeetingInfo;
    isConfirmed?: boolean;
    meetingDateAndTime?: {
      date: string;
      time: string;
      showDate: string;
      showTime: string;
    };
  }>();

  //////////// dialog가 꺼질때 선택한 미팅정보 초기화 ////////////
  useEffect(() => {
    if (!meetingRequestCancelDialogOpen) {
      setSelectedMeeting(undefined);
    }
  }, [meetingRequestCancelDialogOpen]);

  useEffect(() => {
    if (!meetingApproveOrRejectDialogOpen) {
      setSelectedMeeting(undefined);
    }
  }, [meetingApproveOrRejectDialogOpen]);

  useEffect(() => {
    if (!meetingAcceptConfirmDialogOpen) {
      setSelectedMeeting(undefined);
    }
  }, [meetingAcceptConfirmDialogOpen]);
  /////////////////////////////////////////////////////////

  return (
    <ApplicationMeetingContext.Provider
      value={{
        meetingRequestCancelDialogOpen: meetingRequestCancelDialogOpen,
        setMeetingRequestCancelDialogOpen: setMeetingRequestCancelDialogOpen,
        setSelectedMeeting: setSelectedMeeting,
        meetingApproveOrRejectDialogOpen: meetingApproveOrRejectDialogOpen,
        setMeetingApproveOrRejectDialogOpen: setMeetingApproveOrRejectDialogOpen,
        meetingAcceptConfirmDialogOpen: meetingAcceptConfirmDialogOpen,
        setMeetingAcceptConfirmDialogOpen: setMeetingAcceptConfirmDialogOpen,
      }}
    >
      {children}

      {/* 신청한 미팅 취소 dialog */}
      <MeetingCancelConfirmDialog
        open={meetingRequestCancelDialogOpen}
        setOpen={setMeetingRequestCancelDialogOpen}
        meeting={selectedMeeting?.meetingData}
        meetingDateAndTime={selectedMeeting?.meetingDateAndTime}
      />

      {/* 수락대기중인 미팅 수락 or 거절 and 체결된 미팅 취소 dialog */}
      <MeetingApproveOrRejectDialog
        open={meetingApproveOrRejectDialogOpen}
        setOpen={setMeetingApproveOrRejectDialogOpen}
        status={selectedMeeting?.status}
        meeting={selectedMeeting?.meetingData}
        isConfirmed={selectedMeeting?.isConfirmed}
        meetingDateAndTime={selectedMeeting?.meetingDateAndTime}
      />

      {/* 미팅신청 dialog */}
      <MeetingAcceptConfirmDialog
        open={meetingAcceptConfirmDialogOpen}
        setOpen={setMeetingAcceptConfirmDialogOpen}
        meetingDateAndTime={selectedMeeting?.meetingDateAndTime}
      />
    </ApplicationMeetingContext.Provider>
  );
};

export default ApplicationMeetingContextProvider;
