import React, { useState, useEffect, useContext } from "react";
import OutlinedTxtField from "../../../components/Inputs/OutlinedTxtField";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store";
import { makeStyles } from "@material-ui/core";
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typhography/ButtonTypo";
import { getAuthToken, getUser } from "../../../actions/users";
import { ManagerContext } from "./Manager";
import { addManagerUser, checkWhtEventAlreadyAttended } from "../../../actions/applications";

const useStyles = makeStyles((theme) => ({
  eventInfoContainer: {
    padding: 5,
  },
  eventEnterButton: {
    marginTop: 10,
    padding: 10,
  },
  findPwdIdButton: {
    marginTop: 10,
  },
}));

const ManagerLogin = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setActiveStep } = useContext(ManagerContext);
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const [loginForm, setLoginForm] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });
  const manager = useSelector((state: AppState) => state.managers.manager);
  const users = useSelector((state: AppState) => state.users);

  const handleOnChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setLoginForm({
      ...loginForm,
      [event.target.name as string]: event.target.value as string,
    });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(getAuthToken(loginForm));
  };

  useEffect(() => {
    if (manager.email !== "") {
      setLoginForm({ ...loginForm, email: manager.email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager]);

  useEffect(() => {
    if (users.isAuthenticated === true) {
      dispatch(getUser(localStorage.userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.isAuthenticated]);

  // 담당자 추가 api call
  const addManagerCall = async () => {
    const result: any = await dispatch(addManagerUser({ authCode: manager.code!, userExist: "Y" }));
    if (result) {
      history.push(`/subEvent/${manager.subEventId}/meeting`);
    } else {
      window.location.reload();
    }
  };

  const checkWhtEventAttended = async () => {
    const result: any = await dispatch(
      checkWhtEventAlreadyAttended(manager.subEventId, "", history)
    );
    if (result === false) {
      addManagerCall();
    } else {
      history.push(`/subEvent/${manager.subEventId}/meeting`);
    }
  };

  useEffect(() => {
    if (users.userId && manager.code !== undefined) {
      checkWhtEventAttended();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.userId, manager.code]);

  return (
    <form onSubmit={onSubmit}>
      <OutlinedTxtField
        label={t("register.email")}
        name="email"
        fullWidth
        value={loginForm.email}
        padding="0px 0px 20px 0px"
      />
      <OutlinedTxtField
        name="password"
        value={loginForm.password}
        label={t("register.password")}
        fullWidth
        padding="0px 0px 20px 0px"
        onChange={handleOnChange}
      />
      <PxButton
        className={classes.eventEnterButton}
        fullWidth
        backgroundcolor="purple"
        type="submit"
      >
        <ButtonTypo>이벤트 참가하기</ButtonTypo>
      </PxButton>
      <div className={classes.findPwdIdButton}>
        <Link to="/auth/findUser">
          <span className="text-muted">아이디/비밀번호 찾기</span>
        </Link>
      </div>
    </form>
  );
};

export default ManagerLogin;
