import Manager from "../types/models/Manager";
import ManagerActionTypes from "../types/actions/ManagerAction";

interface ManagerReducerType {
  manager: Manager;
  managers: Manager[];
}

const managerDefaultState: ManagerReducerType = {
  managers: [],
  manager: {
    name: "",
    company: "",
    email: "",
    position: "",
    countryNumber: "",
    phoneNumber: "",
    applicationId: "",
    subEventId: "",
  },
};

const managerReducer = (
  state = managerDefaultState,
  action: ManagerActionTypes
): ManagerReducerType => {
  switch (action.type) {
    case "CREATE_MANAGER":
    case "GET_MANAGER":
      return { ...state, manager: action.manager };
    case "GET_MANAGERS":
      return { ...state, managers: action.managers };
    default:
      return state;
  }
};

export default managerReducer;
