import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { postPwdCode } from "../../actions/users";
import { setAlertAction } from "../../actions/alerts";
import Alert from "../../types/models/Alert";
import { removeLoadingAction, setLoadingAction } from "../../actions/loading";
import { useTranslation } from "react-i18next";

const FindUserPwd = styled.div`
  width: 100%;
  min-width: 280px;
  max-width: 640px;
  padding: 24px;
`;

const ButtonBase = styled.button`
  font-size: 18px;
  padding: 0.7em 2em;
  border-radius: 3px;
  border: none;
  // margin: 0 0.32em;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const SendMailButton = styled(ButtonBase)`
  height: 56px;
  width: 100%;
  background: black;
  color: white;
  margin-top: 58px;
`;

const Title = styled.div`
  height: 36px;
  font-size: 24px;
  font-weight: bold;
  color: #282828;

  cursor: pointer;
`;

const SubTitle = styled.div`
  min-height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Content = styled.div`
  height: 0;
  transition: height 0.3s;
  transition-timing-function: linear;
  overflow: hidden;
  ${(props: { on: boolean }) => {
    if (props.on) {
      return `height:400px`;
    }
  }}
`;

const FindPwd = () => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const [findUserPwd, setFindUserPwd] = useState({
    role: "user",
    email: "",
  });
  const [hidden, setHidden] = useState(false);

  const { email } = findUserPwd;

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    const name = e.target.name as string;
    let value = e.target.value as string;
    setFindUserPwd({ ...findUserPwd, [name]: value });
  };

  const submitSendMail = async () => {
    dispatch(setLoadingAction());
    const mailRes: any = await dispatch(postPwdCode(findUserPwd));
    const alert: Alert = {
      id: "mailSent",
      msg: t("findUser.hasSendMail"),
      alertType: "success",
    };
    if (mailRes === true) {
      dispatch(setAlertAction(alert));
      setFindUserPwd({ ...findUserPwd, email: "" });
    }
    dispatch(removeLoadingAction());
  };

  return (
    <>
      <FindUserPwd>
        <Title onClick={() => setHidden(!hidden)}>
          {t("findUser.forgetPassword")}
        </Title>
        <Content on={hidden}>
          <SubTitle>
            <span>{t("findUser.enterNameAndNumber")}</span>
          </SubTitle>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label={t("findUser.email")}
            name="email"
            value={email}
            onChange={(e) => handleOnChange(e)}
          />
          <SendMailButton type="button" onClick={submitSendMail}>
            {t("findUser.submitBtn")}
          </SendMailButton>
        </Content>
      </FindUserPwd>
    </>
  );
};

export default FindPwd;
