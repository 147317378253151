import axios from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

export default function useSubscriptionUpdate() {
  const [t] = useTranslation("lang", { useSuspense: false });
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const webinar = useSelector((state: AppState) => state.webinars.webinar);
  const applicationType = useSelector(
    (state: AppState) => state.applications.subEventApplication.type
  );
  const myBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );
  

  // 설명회일때 모든 참석자는 발표자의 track만 subscribe 하도록 하는 api call (room에 참석한 후 동작되어야함)
  // 참석자들만 동작 (참석자가 발표자들의 track subscription)
  const subscribeUserOnPresenter = useCallback(
    (room) => {
      if (
        room.state === "connected" &&
        subEvent?.category === "presentation" &&
        myBusinessCard &&
        webinar &&
        applicationType === "seller"
      ) {
        // 내 identity
        const identity = `${myBusinessCard.name} (${t("webinar.attendee")})|${
          myBusinessCard.company
        }`;

        // 발표자 명함정보
        const presenterBusinessCard = webinar.presenters![0].applicants![0]
          .businessCard;
      
        const body = {
          presenterIdentity: `${presenterBusinessCard.name} (${t(
            "webinar.presenter"
          )})|${presenterBusinessCard.company}`, // 발표자 Identity
          roomSid: room.sid, // roomId
          identity: identity, // 내 identity
        };

        const url =
          process.env.NODE_ENV === "production"
            ? "/video/videoCall/userIncludePresenter"
            : "http://localhost:3001/video/videoCall/userIncludePresenter";

        axios
          .post(url, body)
          .then((result) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [applicationType, myBusinessCard, subEvent, t, webinar]
  );

  // 설명회일때 발표자는 참여자들의 data track만 subscribe 하도록 하는 api call (room에 참석한 후 동작되어야함)
  const subscribePresenterOnUserDataTrack = useCallback(
    (room) => {
      if (
        room.state === "connected" &&
        subEvent?.category === "presentation" &&
        myBusinessCard &&
        webinar &&
        applicationType === "buyer"
      ) {
        // 내 identity
        const identity = `${myBusinessCard.name} (${t("webinar.presenter")})|${
          myBusinessCard.company
        }`;
        const body = {
          roomSid: room.sid, // roomId
          identity: identity, // 내 identity
        };

        const url =
          process.env.NODE_ENV === "production"
            ? "/video/videoCall/presenterIncludeUserDataTrack"
            : "http://localhost:3001/video/videoCall/presenterIncludeUserDataTrack";

        axios
          .post(url, body)
          .then((result) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [applicationType, myBusinessCard, subEvent, t, webinar]
  );

    // 설명회일때 발표자가 참가자 슨들기 승락하여 해당 참가자의 모든 트랙을 subscribe 하도록 하는 api call (room에 참석한 후 동작되어야함)
    const subscribeHandsUpAcceptUserAllTract = useCallback(
      async(room) => {
        if (
          subEvent?.category === "presentation" &&
          myBusinessCard &&
          webinar 
        ) {
          // 내 identity
          const identity = `${myBusinessCard.name} (${t("webinar.presenter")})|${
            myBusinessCard.company
          }`;
  
          const body = {
            roomSid: room.sid, // roomId
            identity: identity, // 내 identity
          };
          const url =
            process.env.NODE_ENV === "production"
              ? "/video/videoCall/handsUpAcceptTrack"
              : "http://localhost:3001/video/videoCall/handsUpAcceptTrack";
          
          await axios
            .post(url, body)
            .then((result) => {console.log(result,"result"); return true})
            .catch((err) => {
              console.log(err);
              return false
            });
          }else{return false}
      },
      [applicationType, myBusinessCard, subEvent, t, webinar]
    );

  return [subscribeUserOnPresenter, subscribePresenterOnUserDataTrack, subscribeHandsUpAcceptUserAllTract] as const;
}


export const getBlackList = async (roomName : string) => {
  const config = {
    params: {
      roomId: roomName,
    },
  };
  const url =
    process.env.NODE_ENV === "production"
      ? "/video/videoCall/getBlackList"
      : "http://localhost:3001/video/videoCall/getBlackList";

  try {
    const res = await axios.get(url, config);
    return res.data.blackList
  } catch (err) {
    if (err.response) {
      if (err.response.status === 3000) {
        // console.log("다시 접속해주세요.");
        return false;
      }
    }
  }
};
export const addBlackList = async(roomId: string, identity : string) => {

  const body = {
    roomId, // roomId
    identity, // blackList identity
  };
  const url =
    process.env.NODE_ENV === "production"
      ? "/video/videoCall/addBlackList"
      : "http://localhost:3001/video/videoCall/addBlackList";
  
 return await axios
    .post(url, body)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });

}

