import { AppActions } from "../store";
import { getRequest, postRequest, postRequestAndReturn } from "../utils/utils";
import { Dispatch } from "redux";
import { Form } from "../types/models/Form";
import { CounselCreate } from "../types/models/CounselForm";

export const getCounselFormAction = (form: Form): AppActions => ({
  type: "GET_COUNSEL_FORM",
  form: form,
});

export const removeCounselFormAction = (): AppActions => ({
  type: "REMOVE_COUNSEL_FORM",
});

export const setFormContentAction = (content: string): AppActions => ({
  type: "SET_FORM_CONTENT",
  content: content,
});

export const getCounselForm = <Form>(formId: string) => {
  return getRequest(null, "/api/forms/detail", getCounselFormAction, {
    id: formId,
  });
};

export const getSellerPartiForm = (subEventId: number) => {
  return getRequest(null, "/api/forms/getSellerPartiForm", getCounselFormAction, { subEventId: subEventId });
}

export const createCounselForm = (counsel: CounselCreate) => {
  return postRequest(counsel, "/api/meetingSurveys");
};

export const updateCounselForm = (counsel: CounselCreate) => {
  return postRequest(counsel, "/api/meetingSurveys/surveys");
};

export const interpreterUpdateCounselForm = (counsel: CounselCreate) => {
  return postRequestAndReturn("/api/meetingSurveys/surveys", counsel);
};

export const removeCounselForm = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(removeCounselFormAction());
};
