import React, { createContext, ReactNode } from "react";
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
  TwilioError,
  LocalDataTrack,
} from "twilio-video";

import { Callback, ErrorCallback } from "../../types/types";

// hooks
import { SelectedParticipantProvider } from "./hooks/useSelectedParticipant";
import useLocalTracks from "./hooks/useLocalTracks";
import useRoom from "./hooks/useRoom";
import useHandleOnDisconnect from "../../hooks/useHandleOnDisconnect";
import useHandleTrackPublicationFailed from "../../hooks/useHandleTrackPublicationFailed";
import useHandleRoomDisconnectionErrors from "../../hooks/useHandleRoomDisconnectionErros";
import AttachVisibilityHandler from "../AttachVisibilityHandler/AttachVisibilityHandler";
import { StateContextType } from "../AppStateProvider/AppstateProvider";

export interface IVideoContext {
  room: Room;
  localTracks: (LocalAudioTrack | LocalVideoTrack | LocalDataTrack)[];
  isConnecting: boolean;
  setIsConnecting: React.Dispatch<React.SetStateAction<boolean>>;
  connect: (token: string, user?: StateContextType["user"]) => Promise<void>;
  onError: ErrorCallback;
  onDisconnect: Callback;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError?: ErrorCallback;
  onDisconnect?: Callback;
  children: ReactNode;
}

export default function VideoProvider({
  options,
  children,
  onError = () => {},
  onDisconnect = () => {},
}: VideoProviderProps) {
  const onErrorCallback = (error: TwilioError) => {
    console.log(`ERROR: ${error.message}`, error);
    onError(error);
  };

  // 로컬 video, audio track
  const { localTracks, getLocalVideoTrack, getLocalAudioTrack } =
    useLocalTracks();

  // Room info
  const { room, isConnecting, setIsConnecting, connect } = useRoom(
    localTracks,
    onErrorCallback,
    options
  );

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        setIsConnecting,
        onError: onErrorCallback,
        onDisconnect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}
