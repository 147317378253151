import moment from "moment";
import React, { useEffect } from "react";

import { changeImgPathToCloudfrontPath } from "../../utils/utils";
import AdditionalForm from "./AdditionalForm";
import BusinessCardForm from "./BusinessCardForm";
import PartiForm from "./PartiForm";

//ui components
import PxButton from "../../components/Buttons/PxButton";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxGridItem from "../../components/Grid/PxGridItem";
import Body1Typo from "../../components/Typhography/Body1Typo";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import CaptionTypo from "../../components/Typhography/CaptionTypo";
import HeadlineTypo from "../../components/Typhography/HeadlineTypo";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import TitleTypo from "../../components/Typhography/TitleTypo";

//material ui components
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import Step from "@material-ui/core/Step/Step";
import StepLabel from "@material-ui/core/StepLabel/StepLabel";
import Stepper from "@material-ui/core/Stepper/Stepper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SubEventInfo } from "../../types/models/SubEvent";
import { FormData } from "../../types/models/Form";
import BusinessCard from "../../types/models/BusinessCard";
import EventBanner from "./EventBanner";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: 640,
    flexDirection: "column",
    margin: "auto",
  },
  title: {
    textAlign: "center",
  },
  cardRoot: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 20px",
    },
  },
  cardImage: {
    width: 200,
    maxHeight: 145,
    objectFit: "fill",
    [theme.breakpoints.down("xs")]: {
      width: 120,
      maxHeight: 110,
    },
  },
  cardDetails: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
  },
  formRoot: {
    paddingTop: 30,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 10px",
    },
  },
  businessCardRoot: {
    padding: 20,
    margin: "20px 100px 40px 100px",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px 40px 0px",
      padding: 5,
    },
  },
  formBtnContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface FormViewProps {
  subEvent: SubEventInfo;
  steps: string[];
  activeStep: number;
  adFormData?: FormData;
  businessCardList: BusinessCard[];
  businessCardId: number;
  setFormTitle: () => string | undefined;
  handleBack: () => void;
  handleNext: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  submitForm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FormView = ({
  subEvent,
  steps,
  activeStep,
  adFormData,
  businessCardList,
  businessCardId,
  setFormTitle,
  handleBack,
  handleNext,
  submitForm,
}: FormViewProps) => {
  const { t, i18n } = useTranslation("lang", { useSuspense: false });

  // useEffect(() => {
  //   if (subEvent.counselFormOnlyEng == "Y") {
  //     localStorage.setItem("secondLang", i18n.language);
  //     i18n.changeLanguage("en");
  //   }
  // }, [subEvent]);

  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      {/* 참가신청 제목 */}
      <HeadlineTypo className={classes.title}>{setFormTitle()}</HeadlineTypo>
      <PxGridContainer justify="center">
        {/* 참가신청 단계 ui */}
        <PxGridItem xs={12} md={10}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              if (adFormData?.formIds.length === 0 && index === 1) {
                return null;
              } else {
                return (
                  <Step key={label}>
                    <StepLabel>
                      <SubHeadingTypo>{label}</SubHeadingTypo>
                    </StepLabel>
                  </Step>
                );
              }
            })}
          </Stepper>
        </PxGridItem>
        {/*  대이벤트 정보배너 ui */}
        <EventBanner subEvent={subEvent} />
      </PxGridContainer>
      {/* 참가양식 Content */}
      <div className={classes.formRoot}>
        <>
          {activeStep === 0 && (
            <>
              <TitleTypo>{t("companyList.companyInfo")}</TitleTypo>
              {/* 유저 기본정보 */}
              <BusinessCardForm preview={false} />
              {/* 기업정보 or 참가정보 */}
              {/* <TitleTypo>
                {subEvent.category !== "presentation"
                  ? t("form.companyInfo")
                  : t("form.partiInfo")}
              </TitleTypo> */}
              <form>
                <PartiForm preview={false} subEventId={subEvent.id} />
              </form>
            </>
          )}
          {activeStep === 1 && (
            <>
              <TitleTypo>{t("form.additionalInfo")}</TitleTypo>
              <form>
                {adFormData !== null && (
                  <AdditionalForm preview={false} subEventId={subEvent.id} />
                )}
              </form>
            </>
          )}
          {activeStep === 2 && (
            <>
              <TitleTypo>{t("form.review")}</TitleTypo>
              <Card className={classes.businessCardRoot}>
                <CardContent>
                  <PxGridContainer direction="column">
                    <PxGridItem>
                      <SubHeadingTypo>
                        {businessCardList[businessCardId].company}
                      </SubHeadingTypo>
                    </PxGridItem>
                    <PxGridItem className="mt-4">
                      <CaptionTypo>
                        {businessCardList[businessCardId].position}
                      </CaptionTypo>
                      <TitleTypo>
                        {businessCardList[businessCardId].name}
                      </TitleTypo>
                    </PxGridItem>
                    <PxGridItem className="mt-4">
                      <ButtonTypo>{`${businessCardList[businessCardId].email}`}</ButtonTypo>
                    </PxGridItem>
                    <PxGridItem>
                      <ButtonTypo>
                        {businessCardList[businessCardId].countryNumber === ""
                          ? ""
                          : `(${businessCardList[businessCardId].countryNumber})`}
                        {businessCardList[businessCardId].phoneNumber}
                      </ButtonTypo>
                    </PxGridItem>
                  </PxGridContainer>
                </CardContent>
              </Card>
              <form>
                <PartiForm preview={true} />
                {adFormData?.formIds.length !== 0 && (
                  <AdditionalForm preview={true} />
                )}
              </form>
            </>
          )}
        </>
      </div>
      {/* 다음 및 취소 버튼 */}
      <ButtonContainer
        direction="row"
        justify="flex-end"
        spacing={1}
        className="mt-3"
      >
        <PxGridItem>
          <PxButton
            padding="10px 60px"
            backgroundcolor="grey"
            onClick={() => {
              history.push(`/event/detail/${subEvent.mainEvent?.id}`);
            }}
          >
            <ButtonTypo>{t("form.cancel")}</ButtonTypo>
          </PxButton>
        </PxGridItem>
        {activeStep !== 0 && (
          <PxGridItem>
            <PxButton padding="10px 60px" onClick={handleBack}>
              <ButtonTypo>{t("form.back")}</ButtonTypo>
            </PxButton>
          </PxGridItem>
        )}
        <PxGridItem>
          {activeStep !== 2 ? (
            <PxButton
              backgroundcolor="purple"
              padding="10px 60px"
              onClick={handleNext}
            >
              <ButtonTypo>{t("form.next")}</ButtonTypo>
            </PxButton>
          ) : (
            <PxButton
              backgroundcolor="purple"
              padding="10px 60px"
              onClick={submitForm}
            >
              <ButtonTypo>{t("form.done")}</ButtonTypo>
            </PxButton>
          )}
        </PxGridItem>
      </ButtonContainer>
    </div>
  );
};

export default FormView;

const ButtonContainer = styled(PxGridContainer)`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 663px) {
    width: 100%;
  }
`;
