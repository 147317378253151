import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Videocam from "@material-ui/icons/Videocam";
import VideocamOff from "@material-ui/icons/VideocamOff";

import useLocalVideoToggle from "../../hooks/useLocalVideoToggle";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";
import { useAppState } from "../AppStateProvider/hooks/useAppState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
  })
);

export default function ToggleVideoButton(props: {
  disabled?: boolean;
  backgroundColor?: string;
  color?: "black0.6";
}) {
  const classes = useStyles();
  const { selfVideoStop, hostVideoStop, setSelfVideoStop } = useAppState();
  const [isVideoEnabled, stopVideo, playVideo] = useLocalVideoToggle();
  useEffect(() => {
    if (hostVideoStop) {
      stopVideo();
    } else {
      playVideo();
    }
  }, [hostVideoStop]);
  useEffect(() => {
    if (selfVideoStop) {
      stopVideo();
    } else {
      playVideo();
    }
  }, [selfVideoStop]);
  const { t } = useTranslation("lang", { useSuspense: false });

  return (
    <Tooltip
      title={!selfVideoStop ? "Mute Video" : "Unmute Video"}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        className={classes.fab}
        onClick={() => {
          setSelfVideoStop(!selfVideoStop);
          localStorage.setItem(
            "selfVideoStop",
            !selfVideoStop === true ? "true" : "false"
          );
        }}
        disabled={props.disabled}
        style={{
          backgroundColor: props.backgroundColor,
          color: props.color ?? "white",
        }}
      >
        <div>
          {!selfVideoStop ? <Videocam /> : <VideocamOff />}
          <CaptionTypo txtcolor={props.color ?? "white"}>
            {t("videoController.video")}
          </CaptionTypo>
        </div>
      </Fab>
    </Tooltip>
  );
}
