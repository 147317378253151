import React, { useState, useEffect, useCallback } from "react";
import PxGridItem from "../../../components/Grid/PxGridItem";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store";
import { getForm } from "../../../actions/forms";
import CounselDetailBottom from "../../../views/subEvent/content/CounselList/detail/CounselDetailBottom";
import {
  CounselFormAnswer,
  CounselCreate,
} from "../../../types/models/CounselForm";
import { FormData } from "../../../types/models/Form";
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typhography/ButtonTypo";
import {
  updateCounselForm,
  createCounselForm,
} from "../../../actions/counselForms";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import { getMeetingVideo } from "../../../actions/meeting";
import InterpreterCounselDetail from "./InterpreterCounselForm";
import { useTranslation } from "react-i18next";

const CounselForm = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const { user, isSurveyFormEnabled, toggleSurveyFormEnabled } = useAppState();
  const [formId, setFormId] = useState<number>();
  const [counselForm, setCounselForm] = useState<FormData>(); // 상담일지 작성정보 데이터
  const [counselFormAnswers, setCounseFormAnswers] =
    useState<CounselFormAnswer[]>();

  const dispatch = useDispatch();
  const form = useSelector((state: AppState) => state.forms);
  const { id, type } = useSelector(
    (state: AppState) => state.applications.subEventApplication
  ); // subEvent 참가한 application id (내 기업 id)
  const { meetingVideo } = useSelector((state: AppState) => state.meetings); // 영상통화에 참가한 미팅정보
  const { sellerCounselForm, buyerCounselForm } = useSelector(
    (state: AppState) => state.subEvents.subEvent! // 자율양식 form의 id값
  );
  const userType = useSelector(
    (state: AppState) => state.users.subEventBusinessCard?.company // 자율양식 form의 id값
  );

  // 상담일지창을 켤때마다 meeting 정보를 가져옴 ()
  useEffect(() => {
    if (isSurveyFormEnabled && userType != "통역가") {
      dispatch(getMeetingVideo(meetingVideo?.id!, Number(id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyFormEnabled]);

  // meeting 정보에 따라서 상담일지 답변 등록
  useEffect(() => {
    if (meetingVideo !== undefined) {
      if (id! === meetingVideo.meetingApplicant?.id) {
        setCounseFormAnswers(meetingVideo.applicantMeetingSurvey);
      } else {
        setCounseFormAnswers(meetingVideo.acceptorMeetingSurvey);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingVideo]);

  // 상담일지 form을 서버에서 가져옴
  useEffect(() => {
    // 접속한 유저의 type 체크 (ex. seller, buyer, booth etc...)
    if (
      type === "seller" &&
      sellerCounselForm !== undefined &&
      sellerCounselForm !== 0
    ) {
      dispatch(getForm(sellerCounselForm.toString()));
    } else if (
      type === "buyer" &&
      buyerCounselForm !== undefined &&
      buyerCounselForm !== 0
    ) {
      dispatch(getForm(buyerCounselForm.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, sellerCounselForm, buyerCounselForm]);

  // db에서 가져온 상담일지 양식을 Json형식으로 변환 후 state에 저장
  useEffect(() => {
    if (form.id !== undefined) {
      setFormId(form.id);
      const formData = JSON.parse(form.content! as string);
      setCounselForm(formData);
    }
  }, [form]);

  // 저장 onClick method, 작성한 상담일지 저장 api call
  const onSubmit = useCallback(
    async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.preventDefault();
      if (counselForm !== undefined && counselForm.formIds.length > 0) {
        const data: CounselFormAnswer[] = [];
        const checkBoxData: CounselFormAnswer[] = [];
        // 작성 된 자율양식 데이터를 서버로 보내기전 알맞은 형태로 가공
        counselForm!.formIds.forEach((formUuid) => {
          const counselFormAnswer: CounselFormAnswer = {
            id: counselForm!.formData[formUuid].defaultId ?? "",
            title: counselForm!.formData[formUuid].title ?? "",
            type: counselForm!.formData[formUuid].type,
            uuid: formUuid,
            formId: formId!,
          };

          switch (counselForm!.formData[formUuid].type) {
            case "single":
              counselFormAnswer["content"] =
                counselForm!.formData[formUuid].radioContent?.content;
              counselFormAnswer["answerUuid"] =
                counselForm!.formData[formUuid].radioContent?.id;
              data.push(counselFormAnswer);
              break;
            case "many":
              counselForm!.formData[formUuid].checkBoxContent?.forEach(
                ({ id, content }, index) => {
                  const checkBoxForm: CounselFormAnswer = {
                    id: counselForm!.formData[formUuid].defaultId ?? "",
                    title: counselForm!.formData[formUuid].title ?? "",
                    type: counselForm!.formData[formUuid].type,
                    uuid: formUuid,
                    formId: formId!,
                  };
                  checkBoxForm["content"] = content;
                  checkBoxForm["answerUuid"] = id;
                  checkBoxData.push(checkBoxForm);
                }
              );
              break;
            case "file":
              counselFormAnswer["content"] =
                counselForm!.formData[formUuid].fileContent?.fileId;
              data.push(counselFormAnswer);
              break;
            case "star":
              counselFormAnswer["content"] = counselForm!.formData[formUuid]
                .content as string;
              data.push(counselFormAnswer);
              break;
            default:
              counselFormAnswer["content"] = counselForm!.formData[formUuid]
                .content as string;
              data.push(counselFormAnswer);
              break;
          }
        }, []);

        const answerData = data.concat(checkBoxData);

        const counsel: CounselCreate = {
          meetingId: Number(meetingVideo?.id!),
          applicationId: Number(id!),
          meetingSurveys: answerData,
        };

        // 미팅 수락자, 신청자 판단 후 상담일지 답변 업데이트 혹은 생성
        // if (userType != "통역가") {
        if (id === meetingVideo?.meetingAcceptor?.id) {
          if (meetingVideo?.acceptorMeetingSurvey?.length !== 0) {
            await dispatch(updateCounselForm(counsel));
          } else {
            await dispatch(createCounselForm(counsel));
          }
        } else {
          if (meetingVideo?.applicantMeetingSurvey?.length !== 0) {
            await dispatch(updateCounselForm(counsel));
          } else {
            await dispatch(createCounselForm(counsel));
          }
        }
        // }
      }

      toggleSurveyFormEnabled(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [counselForm, dispatch, formId, id, meetingVideo]
  );

  // useEffect(() => {
  //   // 상담일지 화면이 닫힐때 상담일지 저장
  //   if (!isSurveyFormEnabled) {
  //     onSubmit();
  //   }
  // }, [isSurveyFormEnabled, onSubmit]);

  return (
    <PxGridContainer style={{ backgroundColor: "white" }}>
      {user?.type === "interpreter" ? (
        <InterpreterCounselDetail />
      ) : (
        <>
          <CounselDetailBottom
            meetingSurveys={counselFormAnswers}
            applicationId={id!}
            counselForm={counselForm}
            setCounselForm={setCounselForm}
            meetingId={meetingVideo?.id.toString() ?? ""}
          />
          {counselForm && (
            <PxGridItem style={{ flex: "auto", textAlign: "center" }}>
              <PxButton backgroundcolor="purple" onClick={onSubmit}>
                <ButtonTypo>{t("survey.save")}</ButtonTypo>
              </PxButton>
            </PxGridItem>
          )}
        </>
      )}
    </PxGridContainer>
  );
};

export default CounselForm;
