import React, { useEffect, useState } from "react";
import { CounselFormAnswer as SurveyFormAnswer } from "../../../../types/models/CounselForm";
import { FormData } from "../../../../types/models/Form";
import CounselFormItem from "../CounselList/detail/CounselFormItem";

//types

// component

interface SurveyDetailProps {
  surveys?: SurveyFormAnswer[];
  surveyForm?: FormData;
  applicationId?: string;
  setSurveyForm: React.Dispatch<React.SetStateAction<FormData | undefined>>;
  modify: boolean;
}

const SurveyDetail = ({
  surveys,
  surveyForm,
  applicationId,
  setSurveyForm,
  modify,
}: SurveyDetailProps) => {
  const [surveyFormAnswers, setsurveyFormAnswers] = useState<
    SurveyFormAnswer[]
  >();

  useEffect(() => {
    if (surveys !== undefined) {
      setsurveyFormAnswers(surveys);
    }
  }, [surveys]);

  return (
    <>
      {surveyForm?.formIds.map((formId, index) => {
        const surveyFormAnswerData = surveyFormAnswers?.filter(
          (form) => form.uuid === formId
        );
        return (
          <>
            <CounselFormItem
              key={index}
              title={surveyForm.formData[formId].title}
              type={surveyForm.formData[formId].type}
              id={surveyForm.formData[formId].id}
              content={surveyForm.formData[formId].content}
              radioContent={surveyForm.formData[formId].radioContent}
              requireCheck={surveyForm.formData[formId].requireCheck}
              checkBoxContent={surveyForm.formData[formId].checkBoxContent}
              fileContent={surveyForm.formData[formId].fileContent}
              explainCheck={surveyForm.formData[formId].explainCheck}
              explain={surveyForm.formData[formId].explain}
              selectContent={surveyForm.formData[formId].selectContent}
              keywordContent={surveyForm.formData[formId].keywordContent}
              categoryContent={surveyForm.formData[formId].categoryContent}
              preview={modify}
              whtAdditionalPage={false}
              setFormData={setSurveyForm}
              counselFormAnswers={
                surveyFormAnswers?.length === 0
                  ? undefined
                  : surveyFormAnswerData
              }
            />
          </>
        );
      })}
    </>
  );
};

export default SurveyDetail;
