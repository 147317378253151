import React, { useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import SubHeadingTypo from "../../../components/Typhography/SubHeadingTypo";
import { MeetingContext } from "./MeetingDetail";
import { useDispatch, useSelector } from "react-redux";
import { getMeetingVideoAction } from "../../../actions/meeting";
import { AppState } from "../../../store";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    marginTop: 15,
  },
  cardTitleRoot: {
    paddingTop: 5,
    textAlign: "center",
    backgroundColor: "#FEF9E7",
  },
  cardContentRoot: {
    padding: "5px 10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
});

interface MeetingTimeCardProps {
  id: string; // meeting id
  meetingId: string; // meeting uuid
  subEventId: string;
  company1Name: string;
  company1Type: string;
  company2Name: string;
  company2Type: string;
  date: string;
  startTime: string;
  endTime: string;
  zoomLink?: string;
}

const MeetingTimeCard = ({
  id,
  meetingId,
  subEventId,
  company1Name,
  company1Type,
  company2Name,
  company2Type,
  date,
  startTime,
  endTime,
  zoomLink,
}: MeetingTimeCardProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { setVideoDialogOpen } = useContext(MeetingContext);

  const { interpreter } = useSelector((state: AppState) => state.interpreters);

  const cardOnClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // 미팅이 zoomLink로 설정되었을 경우 zoom으로 연결 아니면 pexpo video방으로 연결
    if (zoomLink) {
      window.open(zoomLink, "_blank");
    } else {
      await dispatch(
        getMeetingVideoAction({
          id: Number(id),
          date: date,
          subEventId: Number(subEventId),
          meetingId: meetingId,
          status: "agree",
          startTime: startTime,
          endTime: endTime,
          interpreter: interpreter,
        })
      );
      // setVideoDialogOpen(true);
      history.push("/videoMeeting/device");
    }
  };

  return (
    <Card className={classes.root} onClick={cardOnClick}>
      <CardActionArea>
        <div className={classes.cardTitleRoot}>
          <SubHeadingTypo>
            {startTime} ~ {endTime}
          </SubHeadingTypo>
        </div>
        <CardContent className={classes.cardContentRoot}>
          <SubHeadingTypo>
            {company1Name} ({company1Type})
          </SubHeadingTypo>
          <SubHeadingTypo>·</SubHeadingTypo>
          <SubHeadingTypo>
            {company2Name} ({company2Type})
          </SubHeadingTypo>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MeetingTimeCard;
