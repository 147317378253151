import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import PxGridContainer from "../Grid/PxGridContainer";
import PxSelectBox from "../SelectBox/PxSelectBox";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";

interface props {
  page: number;
  totalPage: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  pageSize?: number;
  onChangeSize?: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  totalElements?: number;
  justify?: "space-between" | "center";
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: "100% !important",

      "& > *": {
        marginTop: theme.spacing(2),
        "&:focus": {
          outline: "none",
        },
      },
      "& .Mui-selected": {
        color: "#4527a0",
        backgroundColor: " rgba(69, 39, 160,0.08)",
        "&:focus": {
          outline: "none",
        },
      },
    },
    selectBox: {
      border: "none",
      // width: 80,
      marginTop: 15,
      "& > *": {
        border: "none",
        padding: 0,
      },
    },
    grid: {
      maxWidth: "100% !important",
    },
  })
);

export default ({
  page,
  totalPage,
  onChange,
  pageSize,
  onChangeSize,
  totalElements,
  justify,
}: props) => {
  const classes = useStyles();
  return (
    <PxGridContainer
      justify={justify ? justify : "space-between"}
      className={classes.grid}
    >
      <Box display="flex" alignItems="center">
        <Pagination
          defaultPage={page + 1}
          page={page + 1}
          count={totalPage <= 0 ? 1 : totalPage}
          onChange={onChange}
          className={classes.root}
          siblingCount={1}
          shape="rounded"
        />
        <Box
          marginTop="15px"
          display="flex"
          alignItems="center"
          color="rgba(0,0,0,0.38)"
        >
          {pageSize &&
            totalElements &&
            `${page === 0 ? 1 : page * pageSize + 1}-${
              page * pageSize + pageSize > totalElements
                ? totalElements
                : page * pageSize + pageSize
            } of ${totalElements}`}
        </Box>
      </Box>
      {pageSize && onChangeSize && (
        <PxSelectBox
          value={pageSize}
          variant="outlined"
          className={classes.selectBox}
          onChange={onChangeSize}
          displayEmpty
          name="countryNumber"
        >
          {pageSizeRage.map((size) => (
            <MenuItem value={size}>{size} rows&nbsp;</MenuItem>
          ))}
        </PxSelectBox>
      )}
    </PxGridContainer>
  );
};

const pageSizeRage = [5, 10, 15, 20];
