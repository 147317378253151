import React from "react";
import styled from "styled-components";
import { Webinar } from "../../../../types/models/Webinar";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, useLocation } from "react-router-dom";

const WebinarCardForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 128px;
  border-radius: 4px;
  border: solid 1px rgba(233, 234, 237, 1);
  cursor: pointer;
`;

const WebinarCardInfo = styled.div`
  padding: 11px 16px;
  height: 40px;
`;

const WebinarCardInfoName = styled.div`
  display: inline-block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.84);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

const WebinarCardInfoTime = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Spoqa Han Sans Neo";
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
`;

const WebinarCardTitle = styled.div<{ bgColor: string; borderColor?: string }>`
  width: 100%;
  height: 40px;
  display: inline-block;
  border-left: solid 3px
    ${(props) =>
      props.borderColor !== undefined
        ? props.borderColor
        : "rgb(" + props.bgColor + ")"};
  background-color: rgba(${(props) => props.bgColor}, 0.08);
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.87);
`;

const WebinarCardPresenter = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const PresenterAvatar = styled(Avatar)`
  margin: 0 10px;
  background-color: #87cefa63;
  width: 30px;
  height: 30px;
`;

interface WebinarTimeCardProps {
  webinarData: Webinar[];
}

const WebinarTimeCard = ({ webinarData }: WebinarTimeCardProps) => {
  const history = useHistory();
  const location = useLocation();

  const handleOnClickMeetingTimeCard = (webinarId?: string) => {
    if (webinarId) {
      history.push(`${location.pathname}/${webinarId}`);
    }
  };

  return (
    <>
      {webinarData.map((w, index) => (
        <WebinarCardForm
          key={`webinarCardForm-${index}`}
          onClick={(e) => {
            handleOnClickMeetingTimeCard(w.id);
          }}
        >
          <WebinarCardInfo>
            <WebinarCardInfoTime>
              {w.startDate.slice(5)} - {w.endDate.slice(5)}
            </WebinarCardInfoTime>
          </WebinarCardInfo>
          <WebinarCardTitle bgColor="252, 178, 30">
            <WebinarCardInfoName>{w.title}</WebinarCardInfoName>
          </WebinarCardTitle>
          <WebinarCardPresenter>
            {w.presenters ? (
              <>
                <PresenterAvatar />{" "}
                <WebinarCardInfoName>
                  {w.presenters[0].name}
                </WebinarCardInfoName>
              </>
            ) : (
              <></>
            )}
          </WebinarCardPresenter>
        </WebinarCardForm>
      ))}
    </>
  );
};

export default WebinarTimeCard;
