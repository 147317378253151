import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Toolbar from "@material-ui/core/Toolbar";
import SubEventHeading from "../../../../components/Typhography/SubEventHeading";
import { makeStyles } from "@material-ui/core/styles";
import { ChatContext } from "../../../../video/components/ChatProvider/ChatProvider";
import { Channel } from "twilio-chat/lib/channel";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatRoomList,
  resetChatRoomListAction,
  setChatRoomsUnreadMessageCountAction,
} from "../../../../actions/chat";
import { useParams } from "react-router-dom";
import { AppState } from "../../../../store";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import CompanyTypo from "../../../../components/Typhography/CompanyTypo";
import Body3Typo from "../../../../components/Typhography/Body3Typo";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import SmsIcon from "@material-ui/icons/Sms";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ChatRoom } from "../../../../types/models/Chat";
import Badge from "@material-ui/core/Badge";
import SyncIcon from "@material-ui/icons/Sync";
import PxIconButton from "../../../../components/Buttons/PxIconButton";

const useStyles = makeStyles((theme) => ({
  titleToolbar: {
    paddingLeft: 24,
  },
  titleTypoRoot: {
    flexGrow: 1,
  },
  loadingProgress: { marginLeft: 20 },
  cardListDiv: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  cardRoot: {
    marginTop: 0,
    width: "100%",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  cardContent: {
    width: "100%",
  },
  badgeRoot: {
    marginRight: 15,
  },
}));

const ChatList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("lang", { useSuspense: false });
  const {
    client,
    setOtherUser,
    setUpChatChannel,
    setChatSidebarOpen,
    chatSidebarOpen,
    setInitialChatClient,
  } = useContext(ChatContext);
  const { id } = useParams<{ id: string }>();

  const myBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );
  const { chatRooms, isLoading } = useSelector(
    (state: AppState) => state.chats
  );

  // twilio에서 내가 참여한 채팅방 uniqueName들을 가져오고 그 데이터로 상대방의 명함정보를 가져온 후 list set
  const getChatRoomListFromTwilio = async () => {
    if (client && myBusinessCard) {
      try {
        const subscribedChannels: Channel[] = (
          await client!.getSubscribedChannels()
        ).items;

        const channelListIds: string[] = [];

        // 일반채팅 리스트 및 해당 서브이벤트의 채팅만 나오도록 필터
        // 원형식: general/subEventId/userBusinessCardId/otherUserbusinessCardId
        const subscribedChannelsInThisSubEvent = subscribedChannels.filter(
          (channel) => {
            const splitedChannelUniqueName = channel.uniqueName.split("/");
            return (
              splitedChannelUniqueName[0] === "general" &&
              splitedChannelUniqueName[1] === id
            );
          }
        );

        // 읽지않은 메세지 및 어떤 명함정보 id값을 가져할지 구분
        // 원형식: general/subEventId/userBusinessCardId/otherUserbusinessCardId
        const unreadMessageCountArray = await Promise.all(
          subscribedChannelsInThisSubEvent.map((channel) => {
            const splitedChannelUniqueName = channel.uniqueName.split("/");
            if (
              splitedChannelUniqueName[2] === myBusinessCard?.id?.toString()
            ) {
              channelListIds.push(splitedChannelUniqueName[3]);
            } else {
              channelListIds.push(splitedChannelUniqueName[2]);
            }
            const unreadMessages = channel.getUnconsumedMessagesCount();
            return unreadMessages;
          })
        );

        // 상대방 명함id list로 채팅방 리스트업을 하기위한  명함정보 데이터를 가져옴
        dispatch(getChatRoomList(channelListIds, unreadMessageCountArray));
      } catch (err) {}
    }
  };

  // 채팅 리스트에 접속했을때 채팅 client가 생성되지 않았을 경우
  // useEffect(() => {
  //   if (client === undefined) {
  //     setInitialChatClient();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // 처음 화면 오픈 시, 채팅창 꺼질때 동작
  useEffect(() => {
    if (client && myBusinessCard) {
      getChatRoomListFromTwilio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, myBusinessCard]);

  // 채팅목록을 나가면 채팅방 redux state 초기화
  useEffect(() => {
    return () => {
      dispatch(resetChatRoomListAction());
    };
  }, [dispatch]);

  const handleCardOnClick = (chatRoom: ChatRoom) => {
    setChatSidebarOpen(false);
    setOtherUser(chatRoom);
    setUpChatChannel(chatRoom);
  };

  return (
    <PxGridContainer direction="column">
      <Toolbar className={classes.titleToolbar} disableGutters>
        <div className={classes.titleTypoRoot}>
          <SubEventHeading>{t("chatList.chat")}</SubEventHeading>
        </div>
        <PxIconButton onClick={getChatRoomListFromTwilio}>
          <SyncIcon />
        </PxIconButton>
      </Toolbar>
      {isLoading === true && (
        <LinearProgress className={classes.loadingProgress} />
      )}
      <div className={classes.cardListDiv}>
        {chatRooms?.map((chatRoom) => (
          <Card
            key={chatRoom.id}
            className={classes.cardRoot}
            onClick={() => {
              handleCardOnClick(chatRoom);
            }}
          >
            <CardContent className={classes.cardContent}>
              <PxGridContainer direction="column">
                <PxGridItem>
                  <CompanyTypo>{chatRoom.company}</CompanyTypo>
                </PxGridItem>
                <PxGridItem>
                  <Body3Typo>{chatRoom.name}</Body3Typo>
                </PxGridItem>
                <PxGridItem>
                  <Body3Typo txtcolor="black0.6">{chatRoom.email}</Body3Typo>
                </PxGridItem>
                <PxGridItem>
                  <Body1Typo txtcolor="black0.6">
                    {`${chatRoom.countryNumber}${chatRoom.phoneNumber}`}
                  </Body1Typo>
                </PxGridItem>
              </PxGridContainer>
            </CardContent>
            <Badge
              badgeContent={chatRoom.unreadMessageCount}
              color="primary"
              classes={{ root: classes.badgeRoot }}
            >
              <SmsIcon style={{ color: "#512da8" }} />
            </Badge>
          </Card>
        ))}
      </div>
    </PxGridContainer>
  );
};

export default ChatList;
