import React, { useRef, useEffect } from "react";
import { IVideoTrack } from "../../types/types";
import { Participant, Track } from "twilio-video";
import styled from "styled-components";
import { useAppState } from "../AppStateProvider/hooks/useAppState";

const Video = styled.video<{ isMultiScreen: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.isMultiScreen ? "cover" : "contain")};
`;
interface VideoTrackProps {
  track: IVideoTrack; // LocalVideoTrack | RemoteVideoTrack
  isLocal?: boolean;
  priority?: Track.Priority | null;
  participant?: Participant;
}

export default function VideoTrack({
  track,
  isLocal,
  priority,
  participant,
}: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const { isMultiScreenEnabled } = useAppState();

  useEffect(() => {
    const el = ref.current;
    el.muted = true;

    if (track.setPriority && priority) {
      track.setPriority(priority);
    }

    track.attach(el);

    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored.
  const isFrontFacing =
    track.mediaStreamTrack.getSettings().facingMode !== "environment";
  const style =
    isLocal && isFrontFacing ? { transform: "rotateY(180deg)" } : {};

  return (
    <Video
      id={participant ? participant?.identity : "video"}
      ref={ref}
      style={style}
      isMultiScreen={isMultiScreenEnabled}
    />
  );
}
