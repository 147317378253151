import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, RouteComponentProps, useParams } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

// icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import PxTable from "../../../../components/Tables/PxTable";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import { useTranslation } from "react-i18next";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import { getManagers } from "../../../../actions/manager";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";

const useStyles = makeStyles((theme) => ({
  managerAddListButton: {
    marginRight: 5,
  },
  arrowBackIcon: {
    cursor: "pointer",
    margin: 23,
    width: 18,
    height: 18,
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  tableRoot: {
    paddingTop: 40,
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
}));

interface RowType {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  used: string;
  date: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const ManagerAddedList: React.FC<RouteComponentProps> = ({ match }) => {
  const classes = useStyles();
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();

  const { id } = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const managers = useSelector((state: AppState) => state.managers.managers);

  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: t("managerAddedList.name"),
        field: "name",
      },
      // {
      //   title: "직함",
      //   field: "position",
      // },
      {
        title: t("managerAddedList.email"),
        field: "email",
      },
      {
        title: t("managerAddedList.phoneNumber"),
        field: "phoneNumber",
      },
      {
        title: t("managerAddedList.date"),
        field: "date",
      },
      {
        title: t("managerAddedList.check"),
        field: "used",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
    ],
    data: [],
  });

  // 담당자 초대 내역 불러오기
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadData = async () => {
    if (id !== undefined) {
      await dispatch(getManagers(param.id, id!));
      dispatch(setTableInfoLoadingAction(false));
    }
  };

  useEffect(() => {
    if (managers.length !== 0) {
      const tableData: RowType[] = managers.map((manager) => ({
        id: manager.id!,
        name: manager.name,
        email: manager.email,
        phoneNumber: manager.phoneNumber,
        date: moment(manager.modifiedDate!).format("YYYY-MM-DD hh:mm"),
        used: manager.used! === "N" ? "X" : "O",
      }));
      setState((prevState) => {
        return { ...prevState, data: tableData };
      });
    }
  }, [managers]);

  return (
    <PxGridContainer direction="row">
      <PxGridItem md={1}>
        <ArrowBackIcon
          className={classes.arrowBackIcon}
          onClick={() => history.goBack()}
        />
      </PxGridItem>
      <PxGridItem md={11} className={classes.tableRoot}>
        <PxTable<RowType>
          title={t("managerAddedList.managerAddedList")}
          components={{
            Toolbar: (props) => {
              return (
                <PxGridContainer direction="column">
                  <MTableToolbar {...props} />
                </PxGridContainer>
              );
            },
          }}
          columns={state.columns}
          data={state.data}
          // material-table 속성: https://material-table.com/#/docs/features/search
          options={{
            toolbar: true,
            actionsColumnIndex: -1,
            pageSize: 10,
            showTitle: true,
            defaultExpanded: false,
            search: true,
            showSelectAllCheckbox: false,
            searchFieldAlignment: "right",
            exportButton: false, // csv 다운
          }}
        />
      </PxGridItem>
    </PxGridContainer>
  );
};

export default ManagerAddedList;
