// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Checkbox,
  DeviceLabels,
  Flex,
  FormField,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  PrimaryButton,
  Select,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import {
  DefaultBrowserBehavior,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";
import styled from "styled-components";
import { getErrorContext } from "../../providers/ErrorProvider";
import routes from "../../constants/routes";
import Card from "../../components/Card";
import Spinner from "../../components/icons/Spinner";
import DevicePermissionPrompt from "../DevicePermissionPrompt";
import RegionSelection from "./RegionSelection";
import Pexpologo from "../../../assets/images/pexpo_signature.png";
import {
  createGetAttendeeCallback,
  fetchMeeting,
  fetchTestMeeting,
  createTestGetAttendeeCallback,
} from "../../utils/api";
import { useAppState } from "../../providers/AppStateProvider";
import { MeetingMode, VideoFiltersCpuUtilization } from "../../types";
import meetingConfig from "../../meetingConfig";
import { useDispatch } from "react-redux";
import { setAlertAction, removeAlertAction } from "../../../actions/alerts";
import uuid from "uuid";
import Alert from "../../../types/models/Alert";

const VIDEO_TRANSFORM_FILTER_OPTIONS = [
  { value: VideoFiltersCpuUtilization.Disabled, label: "Disable Video Filter" },
  {
    value: VideoFiltersCpuUtilization.CPU10Percent,
    label: "Video Filter CPU 10%",
  },
  {
    value: VideoFiltersCpuUtilization.CPU20Percent,
    label: "Video Filter CPU 20%",
  },
  {
    value: VideoFiltersCpuUtilization.CPU40Percent,
    label: "Video Filter CPU 40%",
  },
];

const MeetingForm: React.FC = () => {
  const meetingManager = useMeetingManager();
  const {
    region,
    meetingId,
    localUserName,
    meetingMode,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    isWebAudioEnabled,
    videoTransformCpuUtilization: videoTransformCpuUtilization,
    setJoinInfo,
    isEchoReductionEnabled,
    toggleEchoReduction,
    toggleWebAudio,
    toggleSimulcast,
    togglePriorityBasedPolicy,
    toggleKeepLastFrameWhenPaused,
    setMeetingMode,
    setMeetingId,
    setLocalUserName,
    setRegion,
    setCpuUtilization,
    setIsTest,
  } = useAppState();
  const [meetingErr, setMeetingErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext());
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const alert: Alert = {
    alertType: "warning",
    id: uuid.v4(),
    msg: "이름을 입력해주세요.",
  };

  const handleJoinMeeting = async (e: React.FormEvent) => {
    e.preventDefault();
    setMeetingId(id);
    const attendeeName = localUserName;
    // if(attendeeName)
    console.log(`attendeeName : ${attendeeName}`);

    if (attendeeName == "") {
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });

      return;
    }

    setIsLoading(true);
    meetingManager.getAttendee = createTestGetAttendeeCallback(id);

    try {
      const { JoinInfo } = await fetchTestMeeting(id, attendeeName);
      setJoinInfo(JoinInfo);
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        JoinInfo?.Meeting,
        JoinInfo?.Attendee
      );

      console.log(`joinInfo : `, JoinInfo);
      setRegion(JoinInfo.Meeting.MediaRegion);
      meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers =
        enableSimulcast;
      if (priorityBasedPolicy) {
        meetingSessionConfiguration.videoDownlinkBandwidthPolicy =
          priorityBasedPolicy;
      }
      meetingSessionConfiguration.keepLastFrameWhenPaused =
        keepLastFrameWhenPaused;
      const options = {
        deviceLabels:
          meetingMode === MeetingMode.Spectator
            ? DeviceLabels.None
            : DeviceLabels.AudioAndVideo,
        enableWebAudio: isWebAudioEnabled,
        logLevel: meetingConfig.logLevel,
        postLoggerConfig: meetingConfig.postLogConfig,
        logger: meetingConfig.logger,
      };
      console.log(
        `meetingsession : ${meetingSessionConfiguration}`,
        meetingSessionConfiguration
      );

      await meetingManager.join({
        meetingInfo: JoinInfo.Meeting,
        attendeeInfo: JoinInfo.Attendee,
        deviceLabels:
          meetingMode === MeetingMode.Spectator
            ? DeviceLabels.None
            : DeviceLabels.AudioAndVideo,
      });
      if (meetingMode === MeetingMode.Spectator) {
        await meetingManager.start();
        history.push(`${routes.MEETING}/${meetingId}`);
      } else {
        console.log(`meeting connect success`);
        setMeetingMode(MeetingMode.Attendee);
        setIsTest(true);
        history.push(`/direct/${id}/device`);
        // history.push(`/testMeeting/meeting/${meetingId}`);
      }
    } catch (error) {
      console.log(``);
      updateErrorMessage((error as Error).message);
    }
  };

  const closeError = (): void => {
    updateErrorMessage("");
    setMeetingId("");
    setLocalUserName("");
    setIsLoading(false);
  };

  const headStyle = `
    font-size:2.5rem;
    margin-bottom: 2.5rem;
    text-align: center;
  `;

  const userNameFieldStyle = `
    input{height: 3rem;}
  `;

  const connectBtnStyle = `
    height: 40px;
    width: 200px;
    background-color: #7e57c2;
    &:hover{
      background-color: #7e57c2;
    }
  `;

  const Img = styled.img`
    width: 130px;
    margin-top: 40px;
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4); */
  `;
  return (
    <form style={{ width: "100%" }}>
      <Heading tag="h1" level={4} css={headStyle}>
        Join a meeting
      </Heading>
      <FormField
        field={Input}
        label="Name"
        css={userNameFieldStyle}
        value={localUserName}
        fieldProps={{
          name: "name",
          placeholder: "Enter Your Name",
        }}
        errorText="Please enter a valid name"
        error={nameErr}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          setLocalUserName(e.target.value);
          if (nameErr) {
            setNameErr(false);
          }
        }}
      />

      <Flex
        container
        layout="fill-space-centered"
        flexDirection="column"
        style={{ marginTop: "4.5rem" }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <PrimaryButton
            css={connectBtnStyle}
            label="Continue"
            onClick={handleJoinMeeting}
          />
        )}
        <Img src={Pexpologo} />
      </Flex>
      {errorMessage && (
        <Modal size="md" onClose={closeError}>
          <ModalHeader title={`Meeting ID: ${meetingId}`} />
          <ModalBody>
            <Card
              title="Unable to join meeting"
              description="There was an issue finding that meeting. The meeting may have already ended, or your authorization may have expired."
              smallText={errorMessage}
            />
          </ModalBody>
        </Modal>
      )}
      <DevicePermissionPrompt />
    </form>
  );
};

export default MeetingForm;
