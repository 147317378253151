import React, { useState, useEffect, useContext, Component } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../store";
import {
  applicationMeeting,
  getMeetingTargets,
  meetingTargetsActionStateToInitial,
  getMyMeetings,
} from "../../../../actions/meeting";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import { blue } from "@material-ui/core/colors";
import {
  MyMeetingContext,
  MeetingApplicantDataInterface,
} from "./MeetingDetail";
import { confirmAlert } from "react-confirm-alert";
import { confirmModalOptions } from "../../../../utils/confirmModalOptions";
import Typography from "@material-ui/core/Typography";
import Filter from "./Filter";
import { useTranslation } from "react-i18next";

import { checkBatchimEnding } from "../../../../utils/utils";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../../actions/loading";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

// props interface
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  date: string;
  startTime: string;
  endTime: string;
}

interface AcceptorListType {
  id: string;
  name: string;
  manager: string; // 담당자 이름
  info: ApplicantListInfoType[];
}
interface ApplicantListInfoType {
  title: string;
  content: string;
}
const MeetingTargetsDialog: React.FC<Props> = (props) => {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const param: { id: string; secondId: string } = useParams();
  const { open, setOpen, date, startTime, endTime } = props;
  const { myMeetingCheckList, myMeetingSearch } = useContext(MyMeetingContext);
  const [acceptorList, setAcceptorList] = useState<AcceptorListType[]>([]);
  const application = useSelector(
    (state: AppState) => state.applications.application
  );
  const meetingTargets = useSelector(
    (state: AppState) => state.meetings.meetingTargets
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  // 목록 조회
  useEffect(() => {
    if (open === true && application !== undefined) {
      dispatch(
        getMeetingTargets(
          props.id,
          param.id,
          application.id!,
          application.type!,
          myMeetingSearch.name,
          myMeetingSearch.keyword,
          myMeetingSearch.category
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, myMeetingSearch]);

  // 조회 목록 가공
  useEffect(() => {
    // 신청가능 기업 목록 조회
    if (meetingTargets !== undefined && meetingTargets.length > 0) {
      let meetingTargetsSetting: AcceptorListType[] = [];
      meetingTargets.forEach((meetingTarget) => {
        // 버튼을 숨기더라도 볼 수 있을까봐 미연에 방지
        // 해당시간에 미팅을 '신청' 을 했던지 '받은 내역'이 없으면 출력
        if (myMeetingCheckList[props.id] === undefined) {
          let info: ApplicantListInfoType[] = [];

          meetingTarget.applicationFormAnswers!.forEach((answer) => {
            if (
              answer!.type! !== null &&
              answer.repeatInfo === "N" &&
              answer.content !== undefined
            ) {
              let infoData: ApplicantListInfoType = {
                title: answer!.title!,
                content: answer!.content!,
              };
              info.push(infoData);
            }
          });

          meetingTargetsSetting.push({
            id: meetingTarget.id as string,
            name: meetingTarget.name!,
            manager: meetingTarget.applicants[0].businessCard.name,
            info: info,
          });
        }
      });
      setAcceptorList(meetingTargetsSetting);
    } else {
      setAcceptorList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingTargets]);

  useEffect(() => {
    return () => {
      dispatch(meetingTargetsActionStateToInitial());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = async (applicationId: string) => {
    dispatch(setLoadingAction());
    setOpen(false);
    // 미팅 신청 데이터
    const meetingApplicantData: MeetingApplicantDataInterface = {
      subEventId: param.id, // 서브 이벤트
      applicant: application!.id as string, // 신청자(application id)
      acceptor: applicationId, // 수락자(application id)
      meetingId: props.id, // 미팅시간 key;
      status: "waiting", // 상태 [대기: waiting, 동의: agree, 비동의: disagree]
      date: date,
      startTime: startTime,
      endTime: endTime,
      mailPostToggleAt: "true",
    };

    const result: any = await dispatch(
      applicationMeeting(meetingApplicantData)
    );
    if (result === true) {
      dispatch(getMyMeetings(meetingApplicantData.applicant));
    }
    dispatch(removeLoadingAction());
  };

  const messageComponent = (info: ApplicantListInfoType[]) => {
    return (
      <>
        <Typography color="textSecondary" style={{ padding: 0 }}>
          {info.map((inData: ApplicantListInfoType, index) => {
            let title;
            if (
              inData.title === "기업명" ||
              inData.title === "소속명" ||
              inData.title === "Organization name" ||
              inData.title === "Corporate name"
            ) {
              title = t("meetingDetail.corporateName");
            } else if (
              inData.title === "카테고리" ||
              inData.title === "Category"
            ) {
              title = t("meetingDetail.category");
            } else if (
              inData.title === "관심 카테고리" ||
              inData.title === "Category of interest"
            ) {
              title = t("meetingDetail.interestCategory");
            } else if (
              inData.title === "키워드" ||
              inData.title === "Keywords"
            ) {
              title = t("meetingDetail.keywords");
            } else {
              title = inData.title;
            }
            return (
              <div
                key={`${inData.title}-${index}`}
                style={{ wordBreak: "break-all" }}
              >
                <b>{title}</b>: {inData.content}
              </div>
            );
          })}
        </Typography>
      </>
    );
  };

  const targetGroupName = (type: any) => {
    let returnValue = "";
    if (subEvent !== undefined) {
      returnValue =
        type === "buyer" ? `${subEvent.sgroupName}` : `${subEvent.bgroupName}`;
    }
    return returnValue;
  };

  return application !== undefined &&
    meetingTargets !== undefined &&
    subEvent !== undefined ? (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {t("meetingTargetsDialog.selectDialog", {
            type: targetGroupName(application.type),
          })}
        </DialogTitle>
        <div style={{ padding: "0 24px" }}>
          <Filter />
        </div>
        <List>
          {acceptorList.length > 0 ? (
            acceptorList.map(
              (meetingTarget, targetIndex) =>
                myMeetingCheckList[props.id] === undefined && (
                  <ListItem
                    button
                    onClick={() => {
                      confirmAlert(
                        confirmModalOptions({
                          title: t("meetingTargetsDialog.afterSelect", {
                            type: meetingTarget.name,
                          }),
                          message: messageComponent(meetingTarget.info),
                          click: () =>
                            handleListItemClick(meetingTarget.id as string),
                        })
                      );
                    }}
                    key={`meeting-target-${targetIndex}`}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${meetingTarget.name}(${meetingTarget.manager})`}
                    />
                  </ListItem>
                )
            )
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="해당시간에는 미팅가능한 대상이 없습니다." />
            </ListItem>
          )}
        </List>
      </Dialog>
    </>
  ) : null;
};

export default MeetingTargetsDialog;
