import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChatContext } from "../../../video/components/ChatProvider/ChatProvider";
import SendIcon from "@material-ui/icons/Send";

import "./Input.css";

const Input = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const { sendMessage, chatBlock } = useContext(ChatContext);
  const [text, setText] = useState<string>("");

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
    }
  };

  return (
    <form className="form">
      <input
        className="input"
        type="text"
        placeholder={
          chatBlock ? t("chat.chatPohibited") : t("chat.typeMessage")
        }
        value={text}
        onChange={({ target: { value } }) => setText(value)}
        disabled={chatBlock}
        onKeyPress={onKeyPress}
      />
      <button
        className="sendButton"
        disabled={chatBlock}
        onClick={(e) => {
          e.preventDefault();
          sendMessage(text);
          setText("");
        }}
      >
        <SendIcon />
      </button>
    </form>
  );
};

export default Input;
