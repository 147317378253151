import React from "react";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import TitleTypo from "../../../../components/Typhography/TitleTypo";
import { Application } from "../../../../types/models/Application";

interface CounselDetailTopProps {
  application?: Application;
}

const CounselDetailTop = ({ application }: CounselDetailTopProps) => {
  return (
    <>
      {application && (
        <PxGridContainer
          direction="column"
          alignContent="center"
          style={{ textAlign: "center" }}
        >
          <TitleTypo>{application.name}</TitleTypo>
          <Body1Typo txtcolor="secondary" gutterBottom>
            {application.category}
          </Body1Typo>
          <Body1Typo txtcolor="purple" gutterBottom>
            {application.keyword}
          </Body1Typo>
        </PxGridContainer>
      )}
    </>
  );
};

export default CounselDetailTop;
