import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { AppActions, AppState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { useHistory } from "react-router";
import { CountriesCode, countryPhoneNumberCode } from "../../utils/constants";
import uuid from "uuid";

// actions
import { createUser, getAuthToken, LoginInfo } from "../../actions/users";

// types
import User from "../../types/models/User";

// custom ui comp
import OutlinedTxtField from "../../components/Inputs/OutlinedTxtField";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import PxOutlinedFormControl from "../../components/Forms/PxOutlinedFormControl";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typhography/ButtonTypo";

// mateiral ui comp
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "../../components/Grid/PxGridContainer";
import GridItem from "../../components/Grid/PxGridItem";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
import {
  removeAlertAction,
  setAlert,
  setAlertAction,
} from "../../actions/alerts";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { FormControl, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import PxIconButton from "../../components/Buttons/PxIconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { removeLoadingAction, setLoadingAction } from "../../actions/loading";
import Alert from "../../types/models/Alert";
import { changeImgPathToCloudfrontPath } from "../../utils/utils";
import countries from "../../assets/json/contries.json";
import timeZone from "../../assets/json/timeZone.json";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    padding: "0px 0px 20px 0px",
  },
  inputOnlyEng: {
    imeMode: "disabled",
  },
  countryCodeSelect: {
    display: "flex",
    marginBottom: "20px",
  },
}));

type Props = DispatchProps;

const Register: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const { id, email } = useSelector((state: AppState) => state.users);
  const [user, setUser] = useState<User>({
    email: "",
    name: "",
    password: "",
    position: "",
    role: "USER",
    company: "",
    countryNumber: "82",
    phoneNumber: "",
    countryCode: "KR",
    timeZone: "Asia/Seoul",
    businessCards: [],
  });
  const passwordRegExp = /[A-Za-z0-9\d$@$!%*#?&]{8,}$/;
  const cellPhoneRegExp = new RegExp("^\\d+$"); // 핸드폰 번호 입력 정규식 (숫자만 입력가능)
  const koreanRegExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글확인 정규식
  const engCheck = /[a-z]/; //대문자 불가로 정함 소문자만!
  const numCheck = /[0-9]/;
  const [showPassword, setShowPassword] = useState(false); // 패스워드 보이기 숨기기 상태
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [isCapsLock, setIsCapsLock] = useState(false);
  const alert = (type: "warning" | "success", message: string) => {
    const onAlert: Alert = { alertType: type, id: uuid.v4(), msg: message };
    dispatch(setAlertAction(onAlert));
    setTimeout(() => {
      dispatch(removeAlertAction(onAlert.id));
    });
  };

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const s = String.fromCharCode(event.keyCode);
    if (
      s.toUpperCase() === s &&
      s.toLowerCase() !== s &&
      !event.shiftKey &&
      event.key.toLowerCase() !== event.key
    ) {
      setIsCapsLock(true);
    } else {
      setIsCapsLock(false);
    }
  };

  const handleOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    if ((event.target.name as string) === "phoneNumber") {
      const isValid = cellPhoneRegExp.test(event.target.value as string);
      if (isValid || (event.target.value as string) === "") {
        setUser({
          ...user,
          [event.target.name as string]: event.target.value as string,
        });
      }
    } else if ((event.target.name as string) === "password") {
      const isValid = koreanRegExp.test(event.target.value as string);
      if (!isValid) {
        setUser({
          ...user,
          [event.target.name as string]: event.target.value as string,
        });
      } else {
        alert("warning", "패스워드에 한글은 입력할 수 없습니다.");
      }
    } else if ((event.target.name as string) === "email") {
      setUser({
        ...user,
        [event.target.name as string]: (event.target.value as string).trim(),
      });
    } else {
      setUser({
        ...user,
        [event.target.name as string]: event.target.value as string,
      });
    }
  };

  // 패스워드 보이기/숨기기
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(setLoadingAction());
    // 이메일 형식 체크 (@, . 포함 및 . 뒤에 2자 이상 글자)
    if (
      /^\w+([_0-9a-zA-Z\-][_0-9a-zA-Z\.-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z\--]+){1,2})+$/.test(
        user.email
      ) === false
    ) {
      dispatch(
        setAlert({
          id: "businessCard-blank",
          msg: t("businessCardValidation.emailValid"),
          alertType: "warning",
        })
      );
    } else if (
      user.password &&
      !passwordRegExp.test(user.password) &&
      !user.password.match(engCheck) &&
      !user.password.match(numCheck)
    ) {
      dispatch(
        setAlert({
          id: "businessCard-blank",
          msg: t("regularExpression.password"),
          alertType: "warning",
        })
      );
    } else {
      await props.createUser(user);
    }
    dispatch(removeLoadingAction());
  };

  useEffect(() => {
    if (id !== undefined) {
      props.getAuthToken({ email: email, password: user.password! });

      const path =
        localStorage.getItem("redirectPath") !== null
          ? localStorage.getItem("redirectPath")
          : "/";

      history.push(path!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <>
      <Container maxWidth="xs">
        {subEvent !== undefined &&
          subEvent.mainEvent !== undefined &&
          subEvent?.eventId !== "" && (
            <>
              {/* <TitleTypo style={{ textAlign: "center" }} gutterBottom>
                {subEvent.mainEvent.name}
              </TitleTypo> */}
              <img
                src={
                  subEvent.mainEvent.mainImageFile === null ||
                  subEvent.mainEvent.mainImageFile === undefined
                    ? changeImgPathToCloudfrontPath(subEvent.mainEvent.image)
                    : changeImgPathToCloudfrontPath(
                        subEvent.mainEvent.mainImageFile.filePath!
                      )
                }
                alt={subEvent.mainEvent.name}
                style={{ width: "100%", marginBottom: 20, cursor: "pointer" }}
                onClick={() => {
                  history.push(`/event/detail/${subEvent.mainEvent?.id}`);
                }}
              />
            </>
          )}
        <form onSubmit={onSubmit}>
          <OutlinedTxtField
            label={t("register.email")}
            name="email"
            fullWidth
            value={user.email}
            padding="0px 0px 20px 0px"
            onChange={handleOnChange}
          />

          {/* 비밀번호(패스워드) */}
          <FormControl
            className={classes.inputFormControl}
            variant="outlined"
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t("register.password")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              type={showPassword ? "text" : "password"}
              inputProps={{ className: classes.inputOnlyEng }}
              labelWidth={70}
              value={user.password}
              onKeyDown={handleOnKeyDown}
              onChange={handleOnChange}
              endAdornment={
                <InputAdornment position="end">
                  <PxIconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    style={{ padding: 0, margin: 0 }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </PxIconButton>
                </InputAdornment>
              }
            />
            {isCapsLock && <div>CapsLock 키가 눌려있습니다.</div>}
            {user.password!.length > 0 && !user.password!.match(engCheck) && (
              <div style={{ color: "red" }}>
                {t("register.containLowercaseLetters")}
              </div>
            )}
            {/* {user.password!.length > 0 && !user.password!.match(/[A-Z]/) && (
              <div style={{ color: "red" }}>
                {t("register.containCapitalLetters")}
              </div>
            )} */}
            {user.password!.length > 0 && !user.password!.match(numCheck) && (
              <div style={{ color: "red" }}>{t("register.containNumber")}</div>
            )}
          </FormControl>

          {/* <OutlinedTxtField
            name="password"
            label={t("register.password")}
            fullWidth
            padding="0px 0px 20px 0px"
            onChange={handleOnChange}
          /> */}

          <OutlinedTxtField
            label={t("register.name")}
            name="name"
            fullWidth
            padding="0px 0px 20px 0px"
            onChange={handleOnChange}
          />
          <OutlinedTxtField
            label={t("register.company")}
            name="company"
            fullWidth
            padding="0px 0px 20px 0px"
            onChange={handleOnChange}
          />
          <OutlinedTxtField
            label={t("register.position")}
            name="position"
            fullWidth
            padding="0px 0px 20px 0px"
            onChange={handleOnChange}
          />
          <PxOutlinedFormControl fullWidth className="mb-4">
            <GridContainer direction="row">
              <GridItem xs={3} md={3}>
                <PxSelectBox
                  value={user.countryNumber}
                  onChange={handleOnChange}
                  displayEmpty
                  name="countryNumber"
                  style={{ width: 117 }}
                >
                  <MenuItem value="" disabled>
                    {i18n.language === "ko" ? "국가코드" : "Country Code"}
                  </MenuItem>
                  {countryPhoneNumberCode.map((countryCode) => (
                    <MenuItem value={countryCode.code}>
                      {i18n.language === "ko"
                        ? countryCode.country
                        : countryCode.countryEn}
                    </MenuItem>
                  ))}
                </PxSelectBox>
              </GridItem>
              <GridItem xs={2} md={1}></GridItem>
              <GridItem xs={7} md={8}>
                <OutlinedTxtField
                  type="tel"
                  value={user.phoneNumber}
                  label={t("register.phoneNumber")}
                  fullWidth
                  name="phoneNumber"
                  onChange={handleOnChange}
                />
              </GridItem>
            </GridContainer>
          </PxOutlinedFormControl>

          {/* 나라 */}
          <PxOutlinedFormControl fullWidth>
            <GridContainer direction="row">
              <GridItem fullwidth>
                <PxSelectBox
                  native
                  displayEmpty
                  className={classes.countryCodeSelect}
                  inputProps={{
                    name: "countryCode",
                  }}
                  defaultValue={user.countryCode}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      countryCode: e.target.value as CountriesCode["code"],
                    })
                  }
                >
                  {countries.map((countryCode) => (
                    <option value={countryCode.code}>
                      {i18n.language === "ko"
                        ? countryCode.ko_name
                        : countryCode.en_name}
                    </option>
                  ))}
                </PxSelectBox>
              </GridItem>
            </GridContainer>
          </PxOutlinedFormControl>
          {/* 나라 */}

          {/* 타임존 */}
          <PxOutlinedFormControl fullWidth>
            <GridContainer direction="row">
              <GridItem fullwidth>
                <PxSelectBox
                  native
                  className={classes.countryCodeSelect}
                  displayEmpty
                  inputProps={{
                    name: "timeZone",
                  }}
                  defaultValue={user.countryCode}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      timeZone: e.target.value as string,
                    })
                  }
                >
                  {timeZone[user!.countryCode!].timeZone.map((countryCode) => (
                    <option value={countryCode.zone || countryCode.gmtOffset}>
                      {`(${countryCode.gmtOffset}) ${countryCode.zone}`}
                    </option>
                  ))}
                </PxSelectBox>
              </GridItem>
            </GridContainer>
          </PxOutlinedFormControl>
          {/* 타임존 */}
          <PxButton
            className="my-4 p-2"
            fullWidth
            backgroundcolor="purple"
            type="submit"
          >
            <ButtonTypo className="font-weight-bold">
              {t("register.register")}
            </ButtonTypo>
          </PxButton>
        </form>
      </Container>
    </>
  );
};

interface DispatchProps {
  createUser: (user: User) => void;
  getAuthToken: (loginInfo: LoginInfo) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): DispatchProps => ({
  createUser: bindActionCreators(createUser, dispatch),
  getAuthToken: bindActionCreators(getAuthToken, dispatch),
});

export default connect(null, mapDispatchToProps)(Register);
