import { AdvertisingReduxState } from "../types/models/Advertising";
import AdvertisingActionTypes from "../types/actions/AdvertisingAction";

const advertisingDefaultState: AdvertisingReduxState = {
  advertising: undefined,
  advertisings: [],
};

const advertisingReducer = (
  state = advertisingDefaultState,
  action: AdvertisingActionTypes
): AdvertisingReduxState => {
  switch (action.type) {
    case "GET_ADVERTISINGS":
      return { ...state, advertisings: action.advertisings };
    default:
      return state;
  }
};

export default advertisingReducer;
