import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";
import moment from "moment";

// actions
import { getNotices } from "../../../../actions/notice";

// custom ui comp
import PxTable from "../../../../components/Tables/PxTable";
import { useTranslation } from "react-i18next";
import SubEventHeading from "../../../../components/Typhography/SubEventHeading";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";

interface RowType {
  noticeTitle: string;
  noticeCreatedDate: string;
  noticeUpdatedDate: string;
  noticeentId: string;
  noticeId: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const NoticeList: React.FC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  const notices = useSelector((state: AppState) => state.notices.notices);

  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: t("noticeList.title"),
        field: "noticeTitle",
        cellStyle: {
          width: "calc(100%-120px)",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("noticeList.createdDate"),
        field: "noticeCreatedDate",
        width: 120,
        cellStyle: {
          paddingRight: 0,
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    let tableData: any = [];
    if (notices?.content && Array.isArray(notices?.content) && notices?.content.length > 0) {
      notices.content.map((notice) => {
        const rowData = {
          noticeTitle: notice.title,
          noticeCreatedDate: moment(notice.createdDate).format("YYYY-MM-DD"),
          noticeUpdatedDate: moment(notice.modifiedDate).format("YYYY-MM-DD"),
          noticeId: notice.id,
        };
        tableData.push(rowData);
      });
      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [notices]);

  return (
    <>
      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return <MTableToolbar {...props} />;
          },
        }}
        title={<SubEventHeading>{t("noticeList.notice")}</SubEventHeading>}
        columns={state.columns}
        data={state.data}
        getAction={getNotices}
        totalPage={notices?.totalPages}
        actionData={param.id}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.noticeId}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: true,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default NoticeList;
