import React from "react";
import {
  Switch,
  RouteComponentProps,
  Redirect,
  Route,
  useLocation,
} from "react-router-dom";

// router
import { formRoutes } from "../router/router";

// type
import RouteType from "../types/models/Route";

const Form: React.FC<RouteComponentProps> = ({ match }) => {
  const location = useLocation();
  const pathSplit = location.pathname.split("/");
  let idList = [":subEventId", ":secondId"];
  let idNumber = 0;
  // path 설정 ( 모양: /:id/program/:secondId , 규칙: key는 idList에 지정된 순번대로 사용 )
  let pathEquals = "";
  pathSplit.map((path: string, idx: number) => {
    if (idx > 1 && is_number(path) === false) {
      pathEquals += `/${path}`;
    } else if (idx > 1 && is_number(path) === true) {
      pathEquals += `/${idList[idNumber]}`;
      idNumber++;
    }
  });

  // url 키 여부(숫자) 체크
  function is_number(value: string) {
    const reg = /^(\d)+$/;
    return reg.test(value);
  }

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      if (pathEquals === prop.path) {
        return (
          <Route
            path={`${prop.layout}${prop.path}`}
            component={prop.component}
            key={key}
          />
        );
      }
    });
  };
  return (
    <>
      <Switch>{getRoutes(formRoutes)}</Switch>
    </>
  );
};

export default Form;
