import React, { ReactNode } from "react";
import PexpoLogo from "../../../../../assets/images/pexpo_signature.svg";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  title: string | ReactNode;
  language?: "kr" | "en";
}

export default ({ children, title, language }: Props) => {
  return (
    <div style={{ width: 500, margin: 48 }}>
      <img src={PexpoLogo} style={{ height: 32 }}></img>
      <Title>{title}</Title>
      {children}
      <Footer>
        {language && language === "kr" && (
          <>
            본 메일은 (주))킨터치에서 발송한 메일이며 발신전용 메일입니다.
            문의사항이 있으시다면 info@pexpo.io로 메일을 보내주시기 바랍니다.
          </>
        )}
        {language && language === "en" && (
          <>
            This e-mail was sent from KINTERCH, Ltd. and is only for sending. If
            you have any questions, please email to info@pexpo.io
          </>
        )}
        <p></p>
        ©2020 PEXPO All Rights Reserved.
      </Footer>
    </div>
  );
};

const Title = styled.div`
  width: 500px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  margin: 48px 0 0 0;
`;
const Footer = styled.div`
  width: 480px;
  height: 84px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 48px;
`;

export const MailContent = styled.div`
  width: 480px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.4px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  margin: 0;
`;

export const MailReason = styled.div`
  background-color: #ede7f6;
  padding: 25px 24px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 20px;
`;

export const EventContent = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  border-left: 2px solid #5f4b8b;
  padding-left: 22px;
  color: rgba(0, 0, 0, 0.84);
  margin: 48px 0 64px 0;
`;

export const LinkButton = styled.a`
  background-color: #5f4b8b;
  display: block;
  padding: 13px;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
`;
