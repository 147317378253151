import Alert from "../types/models/Alert";
import AlertActionTypes from "../types/actions/AlertAction";

const alertDefaultState: Alert[] = [];

const userReducer = (
  state = alertDefaultState,
  action: AlertActionTypes
): Alert[] => {
  switch (action.type) {
    case "SET_ALERT":
      return action.alert;
    case "REMOVE_ALERT":
      return state.filter(({ id }) => id !== action.id);
    default:
      return state;
  }
};

export default userReducer;
