import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import useRoomState from "../../hooks/useRoomState";
import ToggleAudioButton from "../ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../ScreenShare/ToggleScreenShareButton";
import EndCallButton from "../EndCallButton/EndCallButton";
// import useIsUserActive from "../../hooks/useIsUserActive";
import Hidden from "@material-ui/core/Hidden";
import ToggleSurveyFormButton from "../ToggleSurveyFormButton/ToggleSurveyFormButton";
import ToggleChatButton from "../ToggleChatButton/ToggleChatButton";
import ToggleFullscreenButton from "../ToggleFullScreenButton/ToggleFullScreenButton";
import { DeviceSelector } from "../DeviceSelector/DeviceSelector";
import ToggleMutiViewButton from "../ToggleMultiViewButton/ToggleMultiViewButton";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import { useTranslation } from "react-i18next";
import ToggleEmojiButton from "../ToggleEmojiButton/ToggleEmojiButton";
import ToggleHandsUpButton from "../ToggleHandsUpButton/ToggleHandsUpbutton";

// import useIsUserActive from './useIsUserActive/useIsUserActive';
// import useRoomState from '../../hooks/useRoomState/useRoomState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      maxHeight: 56,
    },
    container: {
      maxHeight: 56,
      display: "flex",
      position: "absolute",
      right: "50%",
      transform: "translate(50%, 0px)",
      bottom: "50px",
      zIndex: 1,
      opacity: 1,
      visibility: "visible",
      maxWidth: "min-content",
      [theme.breakpoints.down("xs")]: {
        bottom: `${theme.sidebarMobileHeight + 3}px`,
      },
    },
  })
);

export default function ControlsForMany() {
  const classes = useStyles();
  const roomState = useRoomState();
  const { user } = useAppState();
  const isReconnecting = roomState === "reconnecting";

  return (
    <div className={classes.root}>
      <ToggleAudioButton disabled={isReconnecting} />
      <ToggleVideoButton disabled={isReconnecting} />

      <Hidden xsDown={true}>
        <ToggleFullscreenButton />
      </Hidden>

      {roomState !== "disconnected" && (
        <>
          {/* 설명회에서 참가자는 화면공유버튼이 안보이도록 함 */}
          {user?.role !== "none" && (
            <Hidden xsDown={true}>
              <ToggleScreenShareButton disabled={isReconnecting} />
            </Hidden>
          )}
          {/* 설명회가 아닐때 보임 */}
          {user?.eventType !== "presentation" && (
            <>
              <Hidden xsDown={true}>
                <ToggleSurveyFormButton />
              </Hidden>
            </>
          )}
          {/* 설명회 참가자만 보임 */}
          {user?.role !== "presenter" && user?.eventType === "presentation" && (
            <ToggleHandsUpButton />
          )}
          <ToggleEmojiButton />
          <Hidden xsDown={true}>
            <ToggleChatButton />
          </Hidden>
          {/* 설명회 일때만 */}
          {user?.eventType === "presentation" && (
            <Hidden xsDown={true}>
              <ToggleMutiViewButton />
            </Hidden>
          )}

          <DeviceSelector />
          <EndCallButton />
        </>
      )}
    </div>
  );
}
