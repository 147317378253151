import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, Switch, RouteComponentProps, Route } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
import { getSubEvent, removeSubEvent } from "../actions/subEvents";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// router
import { authRoutes } from "../router/router";

// type
import RouteType from "../types/models/Route";

// custom comp
import GridContainer from "../components/Grid/PxGridContainer";
import GridItem from "../components/Grid/PxGridItem";
import PxIconButton from "../components/Buttons/PxIconButton";
import PxMenu from "../components/Menu/PxMenu";

// material comp
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

// images, icons
import PexpoLogo from "../assets/images/pexpo_signature.svg";
import LanguageIcon from "@material-ui/icons/Language";
import { AppState } from "../store";

const StyledForm = styled.form`
  margin: 10px;
`;

const Auth: React.FC<RouteComponentProps> = ({ match }) => {
  const lastLocation = useLastLocation();
  const dispatch = useDispatch();
  const [langIconAnchorEl, setLangIconAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const { t, i18n } = useTranslation("lang", { useSuspense: false });
  const event = useSelector(
    (state: AppState) => state.subEvents.subEvent?.mainEvent
  );

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };
  const handleLanguageIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setLangIconAnchorEl(event.currentTarget);
  };
  ///////////// 로그인, 회원가입 페이지 상단에 이벤트 이미지와 이름을 보여주기 ///////////////

  // 로컬스토리지의 redirectPath로 subEvent 정보를 불러옴
  useEffect(() => {
    if (localStorage.getItem("redirectPath")) {
      const path = localStorage.getItem("redirectPath");
      const subEventId = path?.split("/")[3];
      dispatch(getSubEvent(subEventId!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem("redirectPath");
    // 이전 페이지가 subEvent 신청 페이지일 경우, 이전 페이지의 path를 로컬스토리지에 저장함
    if (
      lastLocation !== undefined &&
      lastLocation?.pathname.includes("/form/subEvent/")
    ) {
      localStorage.setItem("redirectPath", lastLocation.pathname);
    }

    // 로그인, 회원가입 페이지를 나가거나 로그인 성공 시 localStorage와 subEvent redux값을 지움
    return () => {
      localStorage.removeItem("redirectPath");
      dispatch(removeSubEvent());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //////////////////////////////////////////////////////////////////////////////

  return (
    <Container className="pt-3">
      <GridContainer justify="flex-end">
        <PxIconButton onClick={handleLanguageIconClick}>
          <LanguageIcon style={{ fontSize: 24 }} />
        </PxIconButton>
        <PxMenu anchorEl={langIconAnchorEl} setAnchorEl={setLangIconAnchorEl}>
          <MenuItem
            onClick={() => {
              localStorage.setItem("language", "ko");
              i18n.changeLanguage("ko");
              setLangIconAnchorEl(null);
            }}
          >
            <ListItemText primary={t("mainNavBar.korean")} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.setItem("language", "en");
              i18n.changeLanguage("en");
              setLangIconAnchorEl(null);
            }}
          >
            <ListItemText primary={t("mainNavBar.english")} />
          </MenuItem>
        </PxMenu>
      </GridContainer>
      {event?.showLogo !== "N" && (
        <GridContainer justify="center">
          <GridItem>
            <Link to="/">
              <img
                src={PexpoLogo}
                width="130"
                height="33"
                className="d-inline-block align-top"
                alt="Pexpo logo"
              />
            </Link>
          </GridItem>
        </GridContainer>
      )}
      <GridContainer justify="center" className="mt-5">
        <GridItem>
          <StyledForm>
            <Switch>{getRoutes(authRoutes)}</Switch>
          </StyledForm>
        </GridItem>
      </GridContainer>
    </Container>
  );
};

export default Auth;
