import React from "react";
import { Switch, RouteComponentProps, Route, Redirect } from "react-router-dom";
import { Layout } from "../components/Layout/Layout";

// router
import { MyPageRoutes } from "../router/router";

// type
import RouteType from "../types/models/Route";

const MyPage: React.FC<RouteComponentProps> = () => {
  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      switch (prop.path) {
        case "/likes":
        case "/password":
        case "/info":
        case "/approvedEvents":
          return (
            <Route
              path={`${prop.layout}${prop.path}`}
              component={prop.component}
              key={key}
            />
          );

        default:
          return <Redirect to="/" />;
      }
    });
  };

  return (
    <Layout>
      <Switch>{getRoutes(MyPageRoutes)}</Switch>
    </Layout>
  );
};

export default MyPage;
