// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useCallback, useEffect, useState } from "react";
import {
  VideoTileGrid,
  UserActivityProvider,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import {
  createTestGetAttendeeCallback,
  fetchTestMeeting,
} from "../../utils/api";
import {
  DefaultBrowserBehavior,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";

import { StyledLayout, StyledContent } from "./Styled";
import NavigationControl from "../../containers/Navigation/NavigationControl";
import { useNavigation } from "../../providers/NavigationProvider";
import MeetingDetails from "../../containers/MeetingDetails";
import MeetingControls from "../../containers/MeetingControls";
import useMeetingEndRedirect from "../../hooks/useMeetingEndRedirect";
import DynamicMeetingControls from "../../containers/DynamicMeetingControls";
import { MeetingMode, Layout } from "../../types";
import { VideoTileGridProvider } from "../../providers/VideoTileGridProvider";
import { useAppState } from "../../providers/AppStateProvider";
import { attendeeOut } from "../../utils/api";
import uuid from "uuid";
import styled from "styled-components";
import { AppState } from "../../../store";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranscript } from "../../providers/TranscriptProvider";
import { GOOGLE_API_KEY } from "../../../utils/constants";
const googleTranslate = require("google-translate")(GOOGLE_API_KEY);

const Subtitles = styled.div`
  position: absolute;
  bottom: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 20px;
  align-self: center;
`;

const MeetingView = (props: { mode: MeetingMode }) => {
  useMeetingEndRedirect();
  const { showNavbar, showRoster, showChat } = useNavigation();
  const { transcriptOpen, transcriptValue, translateStatus, translateLang } =
    useTranscript();
  const { mode } = props;
  const { layout } = useAppState();
  const [result, setResult] = useState<string>("");

  useEffect(() => {
    translateStatus && transcriptValue.user != "" && transcriptValue.text != ""
      ? googleTranslate.translate(
          transcriptValue.text,
          translateLang.language == "kr" ? "ko" : translateLang.language,
          (err: any, translation: any) => {
            if (err == null) {
              setResult(
                `${transcriptValue.user} : ${translation.translatedText}`
              );
            } else {
              setResult(`${transcriptValue.user} : ${transcriptValue.text}`);
            }
          }
        )
      : setResult(`${transcriptValue.user} : ${transcriptValue.text}`);
  }, [transcriptValue, translateStatus, translateLang]);

  return (
    <UserActivityProvider>
      <VideoTileGridProvider>
        <StyledLayout
          showNav={showNavbar}
          showRoster={showRoster}
          showChat={showChat}
        >
          <StyledContent>
            <VideoTileGrid
              layout={layout === Layout.Gallery ? "standard" : "featured"}
              className="videos"
              noRemoteVideoView={<MeetingDetails />}
            />

            {mode === MeetingMode.Spectator ? (
              <DynamicMeetingControls />
            ) : (
              <>
                {transcriptOpen && (
                  <Subtitles>{result != " : " ? result : null}</Subtitles>
                )}
                <MeetingControls />
              </>
            )}
          </StyledContent>
          <NavigationControl />
        </StyledLayout>
      </VideoTileGridProvider>
    </UserActivityProvider>
  );
};

export default MeetingView;
