import React, { Fragment } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";

import useLocalVideoToggle from "../../hooks/useLocalVideoToggle";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";
import PxMenu from "../../../components/Menu/PxMenu";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import PxIconButton from "../../../components/Buttons/PxIconButton";
import ThumsUpIcon from "../../../assets/icons/thumps-up-icon.png";
import ClappingHandsIcon from "../../../assets/icons/clapping-hands-icon.png";
import GrinningFaceIcon from "../../../assets/icons/grinning-face-icon.png";
import PurpleHeartIcon from "../../../assets/icons/purple-heart-icon.png";
import LightBulbIcon from "../../../assets/icons/light-bulb-icon.png";
import FoldedHandsIcon from "../../../assets/icons/folded-hands-icon.png";
import PensiveFaceIcon from "../../../assets/icons/pensive-face-icon.png";
import FaceWithTearOfJoyIcon from "../../../assets/icons/face-with-tears-of-joy-icon.png";
import FaceWithOpenMouthIcon from "../../../assets/icons/face-with-open-mouth-icon.png";
import useVideoContext from "../../hooks/useVideoContext";
import { LocalDataTrack } from "twilio-video";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import { Emoji } from "../AppStateProvider/AppstateProvider";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
    emojiContainer: {
      padding: 10,
      borderRadius: 33.5,
      backgroundColor: "#171c21",
      borderColor: "black",
    },
  })
);

export default function ToggleEmojiButton(props: {
  disabled?: boolean;
  backgroundColor?: string;
  color?: "black0.6";
}) {
  const classes = useStyles();
  const { t } = useTranslation("lang", { useSuspense: false });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { localTracks } = useVideoContext();
  const { setEmojiMessage } = useAppState();

  const sendEmoji = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const dataTrack = localTracks.find(
      (track) => track.kind === "data"
    ) as LocalDataTrack;
    dataTrack.send(
      JSON.stringify({ type: "emoji", emoji: event.currentTarget.name })
    );
    setEmojiMessage({
      emoji: event.currentTarget.name as Emoji["emoji"],
      num: Math.random(),
    });
  };

  return (
    <>
      <Tooltip
        title="Emoji"
        placement="top"
        PopperProps={{ disablePortal: true }}
      >
        <Fab
          className={classes.fab}
          disabled={props.disabled}
          style={{
            backgroundColor: props.backgroundColor,
            color: props.color ?? "white",
          }}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <div>
            <EmojiEmotionsIcon />
            <CaptionTypo txtcolor={props.color ?? "white"}>
              {t("videoController.emoticon")}
            </CaptionTypo>
          </div>
        </Fab>
      </Tooltip>

      <PxMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        anchorVertical="top"
        anchorhorizontal="center"
        transformVertical="bottom"
        transformHorizontal="center"
        marginBottom={-10}
        className={classes.emojiContainer}
      >
        <PxGridContainer direction="row" spacing={1}>
          <PxIconButton onClick={sendEmoji} name="thumbsUp">
            <img src={ThumsUpIcon} alt="thumps-up" />
          </PxIconButton>
          <PxIconButton onClick={sendEmoji} name="clappingHands">
            <img src={ClappingHandsIcon} alt="thumps-up" />
          </PxIconButton>
          <PxIconButton onClick={sendEmoji} name="grinningFace">
            <img src={GrinningFaceIcon} alt="grinningFace" />
          </PxIconButton>
          <PxIconButton onClick={sendEmoji} name="purpleHeart">
            <img src={PurpleHeartIcon} alt="purpleHeart" />
          </PxIconButton>
          <PxIconButton onClick={sendEmoji} name="lightBulb">
            <img src={LightBulbIcon} alt="lightBulb" />
          </PxIconButton>
          <PxIconButton onClick={sendEmoji} name="foldedHands">
            <img src={FoldedHandsIcon} alt="foldedHands" />
          </PxIconButton>
          <PxIconButton onClick={sendEmoji} name="pensiveFace">
            <img src={PensiveFaceIcon} alt="pensiveFace" />
          </PxIconButton>
          <PxIconButton onClick={sendEmoji} name="faceWithTearOfJoy">
            <img src={FaceWithTearOfJoyIcon} alt="faceWithTearOfJoy" />
          </PxIconButton>
          <PxIconButton onClick={sendEmoji} name="faceWithOpenMouth">
            <img src={FaceWithOpenMouthIcon} alt="faceWithOpenMouth" />
          </PxIconButton>
        </PxGridContainer>
      </PxMenu>
    </>
  );
}
