import { ProductList } from "../types/models/Product";
import ProductActionTypes from "../types/actions/productAction";
import { AppActions } from "../store";
import { getRequest } from "../utils/utils";

export const getProductListAction = (productList: ProductList):AppActions  => ({
    type: "GET_PRODUCT_LIST",
    productList: productList
})

export const setProductSearchWord = (searchWord: string): AppActions => ({
    type: "SET_PRODUCT_SEARCH_WORD",
    productSearchWord: searchWord
})

export const getProductList = (subEventId: number, page: number, searchWord:string, size:number) => {
    return getRequest(null, "/api/formAnswers/getProductList", getProductListAction, { subEventId: subEventId, page: page, searchWord:searchWord, size: size });
}