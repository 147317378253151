import axios from "axios";
import TwilioChat from "twilio-chat";
import { LocalDataTrack } from "twilio-video";

export const getChatClient = async (userId: Number) => {
  const config = {
    params: {
      identity: userId,
    },
  };
  try {
    const url =
      process.env.NODE_ENV === "production"
        ? "/chat/token"
        : "http://localhost:3001/chat/token";

    const res = await axios.get(url, config);
    const chatClient: TwilioChat = await TwilioChat.create(res.data);

    return chatClient;
  } catch (err) {
    console.log("Chat Client Error");
  }
};

export const updateChatClientFriendlyName = async (
  identity: string,
  friendlyName: string
) => {
  try {
    const url =
      process.env.NODE_ENV === "production"
        ? "/chat/updateFriendlyName"
        : "http://localhost:3001/chat/updateFriendlyName";

    const result = await axios.post(url, {
      identity,
      friendlyName,
    });
    return result.data;
  } catch (err) {
    console.log("Chat Client update Error");
  }
};

export const removeChatBlackList = async (
  identity: string,
  roomId:string
) => {
  try{
    const url =  process.env.NODE_ENV === "production"
    ? "/chat/removeChatBlackList"
    : "http://localhost:3001/chat/removeChatBlackList";

    const result = await axios.post(url,{identity,roomId})

    return result.data ;
  }catch(err) {
    console.log(err)
  }
}

export const addChatBlackList = async (identity:string, roomId:string) => {
  try{
    const url =  process.env.NODE_ENV === "production"
    ? "/chat/addBlackList"
    : "http://localhost:3001/chat/addBlackList";

    const reulst =await axios.post(url,{identity,roomId})
    return reulst.data;
  }catch(err) {
    console.log(err);
  }
}

export const getBlackList = async (roomId:string) => {
  const config = {
    params: {
      roomId
    },
  };
  const url =
    process.env.NODE_ENV === "production"
      ? "/chat/getBlackList"
      : "http://localhost:3001/chat/getBlackList";

  try {
    const res = await axios.get(url, config);
    return res.data;
  } catch (err) {
    if (err.response) {
      if (err.response.status === 3000) {
        console.info("다시 접속해주세요.");
      }
    }
  }
};

export const removeBlackList = async (roomId:string,identity: string) => {

    const body = {
      roomId, // roomId
      identity, // blackList identity
    };
    const url =
      process.env.NODE_ENV === "production"
        ? "/chat/removeBlackList"
        : "http://localhost:3001/chat/removeBlackList";

    await axios
      .post(url, body)
      .then((result) => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

};

export const removeBlock = async (identity: string,roomName:string, dataTrack: LocalDataTrack) : Promise<string[]> => {
  const result: any = await removeBlackList(roomName, identity);
  dataTrack.send(
    JSON.stringify({ type: "chatRemoveBlock", chatBlock: identity })
  );
  return result as Promise<string[]>
};


export const removeMsg = async (sid :string, channelSid:string) => {
  try {
    const url =
      process.env.NODE_ENV === "production"
        ? "/chat/removeMsg"
        : "http://localhost:3001/chat/removeMsg";

    const result = await axios.post(url, {
     sid,channelSid
    });
    return result.data;
  } catch (err) {
    console.log("Chat Client update Error");
  }
}