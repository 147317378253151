import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { getFindEmail } from "../../actions/users";
import { setAlertAction } from "../../actions/alerts";
import Alert from "../../types/models/Alert";
import uuid from "uuid";
import { useTranslation } from "react-i18next";

const FindIdForm = styled.div`
  width: 100%;
  min-width: 280px;
  max-width: 640px;
  padding: 24px;
`;

const ButtonBase = styled.button`
  font-size: 18px;
  padding: 0.7em 2em;
  border-radius: 3px;
  border: none;
  // margin: 0 0.32em;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const FindButton = styled(ButtonBase)`
  height: 56px;
  width: 100%;
  background: black;
  color: white;
  margin-top: 58px;
`;

const Title = styled.div`
  height: 36px;
  font-size: 24px;
  font-weight: bold;
  color: #282828;

  cursor: pointer;
`;

const SubTitle = styled.div`
  height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Content = styled.div`
  height: 0;
  transition: height 0.2s;
  transition-timing-function: linear;
  overflow: hidden;
  ${(props: { on: boolean; height: number }) => {
    if (props.on) {
      return `height:${props.height}px`;
    }
  }}
`;

const FindId = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("lang", { useSuspense: false });
  const [email, setEmail] = useState("");
  const [findUserEmail, setFindUserEmail] = useState({
    name: "",
    phoneNumber: "",
  });
  const [hidden, setHidden] = useState(true);
  const [height, setHeight] = useState(300);

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    const name = e.target.name as string;
    let value = e.target.value as string;
    if (name === "phoneNumber") {
      const regExp = /^[0-9]*$/;
      if (!regExp.test(value)) {
        const alert: Alert = {
          alertType: "warning",
          id: uuid.v4(),
          msg: "숫자만 입력 할 수 있습니다.",
        };

        dispatch(setAlertAction(alert));

        return false;
      }
    }
    setFindUserEmail({ ...findUserEmail, [name]: value });
  };

  // 검색
  const submitFindId = async () => {
    const searchId: any = await dispatch(
      getFindEmail(findUserEmail.name, findUserEmail.phoneNumber)
    );
    if (searchId != null) {
      setEmail(emailProcessing(searchId));
      setHeight(80);
    }
  };

  // 이메일 * 처리
  const emailProcessing = (email: string) => {
    const splitEmail: string[] = email.split("@");
    let returnEmail =
      splitEmail[0].substring(0, splitEmail[0].length - 2) +
      "**@" +
      splitEmail[1];
    return returnEmail;
  };

  return (
    <>
      <FindIdForm>
        <Title
          onClick={() => {
            setHidden(!hidden);
          }}
        >
          {t("findUser.forgetEmail")}
        </Title>

        <Content on={hidden} height={height}>
          <SubTitle>
            <span>{t("findUser.enterNameAndNumber")}</span>
          </SubTitle>
          {email === "" ? (
            <>
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label={t("findUser.name")}
                name="name"
                value={findUserEmail.name}
                onChange={handleOnChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="phoneNumber"
                label={t("findUser.phoneNum")}
                name="phoneNumber"
                value={findUserEmail.phoneNumber}
                onChange={handleOnChange}
              />
              <FindButton type="button" onClick={submitFindId}>
                {t("findUser.findEmail")}
              </FindButton>
            </>
          ) : (
            <>
              {t("findUser.email")}: {email}
            </>
          )}
        </Content>
      </FindIdForm>
    </>
  );
};

export default FindId;
