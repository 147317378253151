import React, { useCallback, useEffect, useState } from "react";

import axios, { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import {
  ProductListResponse,
  ProductProperty,
} from "../../../types/models/Form";
import { useHistory } from "react-router-dom";

import { IconButton, OutlinedInput, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import styled from "styled-components";
import { getProductList, setProductSearchWord } from "../../../actions/product";
import Pagination from "../../Pagination/Pagination";
import PexpoLogo from "../../../assets/images/pexpo_logo.png";
import NoResultPage from "../../NoResult/NoResultPage";

interface ProductData {
  content: ContentType[];
  pageable: any;
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  sort: any;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

type ContentType = {
  id: number;
  title: string;
  type: string;
  uuid: string;
  answerUuid: string;
  content: string;
  formId: string;
  applicationId: number;
  companyName: string;
};

const ProductGridContainer = () => {
  const SIZE_PER_PAGE = 16;
  const subEventId = useSelector(
    (state: AppState) => state.subEvents.subEvent?.id
  );
  const { productList, productSearchWord } = useSelector(
    (state: AppState) => state.product
  );
  const history = useHistory();
  const [productData, setProductData] = useState<ProductData>();
  const dispatch = useDispatch();
  const [searchWord, setSearchWord] = useState<string>("");
  const [page, setPage] = useState<number>();

  useEffect(() => {
    if (subEventId !== undefined && productList.number === undefined) {
      setPage(0);
      getProductInfoList(0);
    } else if (
      productList.number !== undefined &&
      productList.number !== page
    ) {
      setPage(productList.number);
      getProductInfoList(productList.number);
    }
  }, [subEventId, productList]);

  const searchOnChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    dispatch(setProductSearchWord(event.target.value as string));
  };

  const searchOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == "Enter" && page !== undefined) {
      getProductInfoList(0);
    }
  };

  const searchOnClick = () => {
    if (page !== undefined) {
      getProductInfoList(0);
    }
  };

  const pagignOnClick = (page: number) => {
    setPage(page);
    getProductInfoList(page);
  };

  const getProductInfoList = (page: number) => {
    if (subEventId !== undefined && page !== undefined) {
      dispatch(
        getProductList(
          Number(subEventId),
          page,
          productSearchWord,
          SIZE_PER_PAGE
        )
      );
    }
  };

  return (
    <>
      <OutlinedInput
        style={{
          width: "600px",
          alignSelf: "center",
          margin: "40px",
          padding: "0",
        }}
        startAdornment={
          <InputAdornment position="start" style={{ cursor: "pointer" }}>
            <IconButton onClick={searchOnClick}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        value={productSearchWord}
        onChange={searchOnChange}
        onKeyPress={searchOnKeyPress}
      />

      {productList.content.length !== 0 ? (
        <GridContainer>
          {productList.content.map((product) => {
            const productItemOnClick = () => {
              history.push({
                pathname: `/subEvent/${subEventId}/attendeeList/${product.applicationId}`,
                state: { isProductPage: true },
              });
            };

            return (
              <ProductItem onClick={productItemOnClick}>
                {/* <ProductImgContainer> */}
                <ProductImg
                  src={
                    product.productImg !== null ? product.productImg : PexpoLogo
                  }
                  style={
                    product.productImg !== null
                      ? { opacity: "10" }
                      : { opacity: "0.5" }
                  }
                />
                {/* </ProductImgContainer> */}
                <ContentContainer>
                  <ProductName>{product.productName}</ProductName>
                  <ProductCompanyName>{product.companyName}</ProductCompanyName>
                </ContentContainer>
              </ProductItem>
            );
          })}
        </GridContainer>
      ) : (
        <NoResultPage />
      )}

      <PaginationContainer>
        <Pagination
          page={productList.number as number}
          totalPage={productList.totalPages}
          pageSize={10}
          totalElements={productList.totalElements}
          onChange={(e, page) => {
            pagignOnClick(page - 1);
          }}
          justify="center"
        />
      </PaginationContainer>
    </>
  );
};

export default ProductGridContainer;

const GridContainer = styled.ul`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(300px, auto);
  /* grid-auto-rows: 300px; */
  gap: 25px;
  padding: 0;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 780px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ProductItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 5px 5px 20px;
  aspect-ratio: 1/1.3;
  min-height: 0;
`;

const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: contain;

  /*  */
  border-radius: 10px;
`;

// const ContentContainer = styled.div`
//   flex: 1;
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   height: 100px;
//   background-color: rgba(116, 19, 195, 0.48);
//   border-radius: 0 0 10px 10px;
//   padding: 10px 5px 5px 15px;
//   z-index: 2;
// `;

const ContentContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: rgba(116, 19, 195, 0.48);
  border-radius: 0 0 10px 10px;
  padding: 5px;
  z-index: 2;
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px;
`;

const ProductName = styled.h1`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-size: 26px;
`;

const ProductCompanyName = styled.h1`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-size: 20px;
`;

const ProductImgContainer = styled.div`
  flex: 3;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
`;
