import { AppActions } from "../store";
import { TableInfo } from "../types/models/TableInfo";

export const setTableInfoAction = (tableInfo: TableInfo): AppActions => ({
  type: "SET_TABLE_INFO",
  tableInfo: tableInfo,
});

export const setTableInfoLoadingAction = (loading: boolean): AppActions => ({
  type: "SET_TABLE_LOADING",
  loading: loading,
});
