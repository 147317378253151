import React from "react";
// import "react-confirm-alert/src/react-confirm-alert.css"; // 배경 닐림

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

let thisTitle: string; // 제목
let thisMessage: string; // 내용
let thisClick: Function; // 확인시 작동 함수

export const confirmModalOptions = ({ title, message, click }: any) => {
  thisTitle = title;
  thisMessage = message;
  thisClick = click;
  return options;
};

const handleOnClick = (onClose: any) => {
  thisClick();
  onClose();
};

const options = {
  childrenElement: () => <div />,
  customUI: ({ onClose }: any) => {
    return (
      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{thisTitle}</DialogTitle>
        {thisMessage !== undefined && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {thisMessage}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {i18n.language === "ko" ? "닫기" : "Close"}
          </Button>
          {thisClick !== undefined && (
            <Button
              onClick={() => handleOnClick(onClose)}
              color="primary"
              autoFocus
            >
              {i18n.language === "ko" ? "확인" : "Confirm"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  },
  closeOnEscape: true,
  closeOnthisClickOutside: true,
  willUnmount: () => {},
  afterClose: () => {},
  onthisClickOutside: () => {},
  onKeypressEscape: () => {},
};
