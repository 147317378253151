import {
  useAudioVideo,
  useRosterState,
} from "amazon-chime-sdk-component-library-react";
import { Transcript, TranscriptEvent } from "amazon-chime-sdk-js";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { startTranscript } from "../utils/api";
import { useAppState } from "./AppStateProvider";
import { GOOGLE_API_KEY } from "../../utils/constants";
const googleTranslate = require("google-translate")(GOOGLE_API_KEY);

interface TranscriptionStreamParams {
  //   LanguageCode:
  //     | "en-US"
  //     | "en-GB"
  //     | "es-US"
  //     | "fr-CA"
  //     | "fr-FR"
  //     | "en-AU"
  //     | "it-IT"
  //     | "de-DE"
  //     | "pt-BR"
  //     | "ja-JP"
  //     | "ko-KR"
  //     | "zh-CN";
  LanguageCode: string;
  ContentIdentificationType?: "PII" | "PHI";
  ContentRedactionType?: "PII";
  EnablePartialResultsStability?: boolean;
  PartialResultsStability?: string;
  PiiEntityTypes?: string;
  LanguageModelName?: string;
  PreferredLanguage?: string;
}

interface TranscriptValue {
  user: string;
  text: string;
}

interface TranscriptionValue {
  transcriptOpen: boolean;
  transcriptValue: TranscriptValue;
  translateLang: TranslateLanguage;
  transcriptStatus: boolean;
  translateStatus: boolean;
  setTranscriptOpen: (state: boolean) => void;
  transcriptControl: (value: string) => void;
  setTranslateLang: (state: TranslateLanguage) => void;
  setTranscriptStatus: (state: boolean) => void;
  setTranslateStatus: (state: boolean) => void;
}

const TranscriptContext = React.createContext<TranscriptionValue>({
  transcriptOpen: false,
  transcriptValue: { user: "", text: "" },
  translateLang: { language: "en" },
  transcriptStatus: false,
  translateStatus: false,
  setTranscriptOpen: (_: boolean) => {},
  transcriptControl: (_: string) => {},
  setTranslateLang: (_: TranslateLanguage) => {},
  setTranscriptStatus: (_: boolean) => {},
  setTranslateStatus: (_: boolean) => {},
});

type Props = {
  children: ReactNode;
};

interface TranslateLanguage {
  language: "kr" | "en";
}

export const useTranscript = (): TranscriptionValue => {
  const state = useContext(TranscriptContext);
  if (!state) {
    throw new Error("useTranscript must be used within TranscriptProvider");
  }

  return state;
};

export const TranscriptProvider = ({ children }: Props) => {
  const { joinInfo } = useAppState();
  const audioVideo = useAudioVideo();
  const { roster } = useRosterState();
  const [transcriptStatus, setTranscriptStatus] = useState<boolean>(false);
  const [translateStatus, setTranslateStatus] = useState<boolean>(false);
  const [transcriptOpen, setTranscriptOpen] = useState<boolean>(false);
  const [translateLang, setTranslateLang] = useState<TranslateLanguage>({
    language: "en",
  });
  const [transcriptValue, setTranscriptValue] = useState<TranscriptValue>({
    user: "",
    text: "",
  });

  let attendees = Object.values(roster);

  const transcriptControl = (languageCode: string) => {
    const transcriptionStreamParam: TranscriptionStreamParams = {
      LanguageCode: languageCode,
    };

    startTranscript(
      transcriptionStreamParam,
      joinInfo?.Meeting.MeetingId as string
    );

    if (!transcriptStatus) {
      audioVideo?.transcriptionController?.subscribeToTranscriptEvent(
        transcriptEventHandler
      );
    }
  };

  useEffect(() => {
    attendees = Object.values(roster);
  }, [roster]);

  useEffect(() => {
    console.log(`change translateStatus`);
  }, [translateStatus]);

  const transcriptEventHandler = (transcriptEvent: TranscriptEvent) => {
    console.log(`event on : `, transcriptEvent);
    if (transcriptEvent instanceof Transcript) {
      for (const result of transcriptEvent.results) {
        const resultId = result.resultId;
        const isPartial = result.isPartial;
        const languageCode = result.languageCode;

        // if (!isPartial) {
        if (result.alternatives[0]?.items.length > 0) {
          const speaker = attendees.find((item) => {
            return (
              item.chimeAttendeeId ==
              result.alternatives[0].items[0].attendee.attendeeId
            );
          });

          setTranscriptValue({
            user: speaker?.name as string,
            text: result.alternatives[0].transcript,
          });
        }
        // }
      }
    }
  };

  const providerValue = {
    transcriptOpen,
    transcriptValue,
    translateLang,
    transcriptStatus,
    translateStatus,
    setTranscriptOpen,
    transcriptControl,
    setTranslateLang,
    setTranscriptStatus,
    setTranslateStatus,
  };

  return (
    <TranscriptContext.Provider value={providerValue}>
      {children}
    </TranscriptContext.Provider>
  );
};
