import {
  Application,
  ApplicantList,
  ApplicationFormAnswers,
  PaginationApplication,
} from "../types/models/Application";
import { AppActions, AppState } from "../store";
import { postRequest, getRequest, getRequestAndReturn } from "../utils/utils";
import BusinessCard from "../types/models/BusinessCard";
import axios from "axios";
import { Dispatch } from "react";
import uuid from "uuid";
import Alert from "../types/models/Alert";
import { removeAlertAction, setAlertAction } from "./alerts";
import { MeetingInfo } from "../types/models/Meeting";

// get action methods
export const createApplicationAction = (
  application: Application
): AppActions => ({
  type: "CREATE_APPLICATION",
  application: application,
});

export const getApplicationAction = (application: Application): AppActions => ({
  type: "GET_APPLICATION",
  application,
});

export const getSubEventApplicationAction = (
  subEventApplication: Application
): AppActions => ({
  type: "GET_SUB_EVENT_APPLICATION",
  subEventApplication,
});

export const removeApplicationAction = (): AppActions => ({
  type: "REMOVE_APPLICATION",
});

export const getApplicationsAction = (
  applications: PaginationApplication
): AppActions => ({
  type: "GET_APPLICATIONS",
  applications: applications,
});

export const getAttendeeListAction = (
  attendeeList: { businessCards: BusinessCard }[]
): AppActions => ({
  type: "GET_ATTENDEE_LIST",
  attendeeList: attendeeList,
});

export const getApplicantListAction = (
  applicantList: ApplicantList[]
): AppActions => ({
  type: "GET_APPLICANT_LIST",
  applicants: applicantList,
});

export const resetApplicationAction = (): AppActions => ({
  type: "REMOVE_APPLICATION",
});

// 이벤트 참가자 목록 초기화
export const removeApplicantsAction = (): AppActions => ({
  type: "REMOVE_APPLICANTS",
});

export const updateApplicationAnsersAction = (
  application: Application
): AppActions => ({
  type: "UPDATE_APPLICATION_ANSWERS",
  application: application,
});

export const removeApplicationMeetingsAction = (): AppActions => ({
  type: "REMOVE_APPLICATION_MEETINGS",
});

// 참가신청 완료 action
export const createApplication = (application: Application) => {
  return postRequest(application, "/api/applications");
};

// 비로그인 참가신청 
export const noneLoginCreateApplication = (application: Application) => {
  return postRequest(application, "/api/applications/noneLoginCreateApplication");
};

// 매니저 삭제 action
export const removeManagersAction = (application: Application): AppActions => ({
  type: "REMOVE_MANAGERS",
  application: application,
});

export const getApplicationMeetingAction = (
  applicationMeeting: MeetingInfo[]
): AppActions => ({
  type: "GET_APPLICATION_MEETING",
  applicationMeeting: applicationMeeting,
});

export const getApplicationMeetingsAction = (
  applicationMeetings: MeetingInfo[]
): AppActions => ({
  type: "GET_APPLICATION_MEETINGS",
  applicationMeetings: applicationMeetings,
});

export const resetApplicationsSateAction = (): AppActions => ({
  type: "RESET_APPLICATIONS_STATE_ACTION",
});

// 이미 참여한 이벤트인지 확인 (수정 후)
export const checkWhtEventAlreadyAttended =
  (subEventId: string, attendeeType: string, history: any) =>
  async (dispatch: Dispatch<AppActions>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: { subEventId: subEventId, attendeeType: attendeeType },
        data: {},
      };
      const result = await axios.get("/api/applicants/checkEvent", config);

      return result.data;
    } catch (err) {
      if (err.response !== undefined) {
        const alert: Alert = {
          alertType: "warning",
          id: uuid.v4(),
          msg: err.response.data.detail,
        };
        dispatch(setAlertAction(alert));
        setTimeout(() => {
          dispatch(removeAlertAction(alert.id));
        });
      }
      history.push("/");
    }
    // return getRequest(null, "/api/applicants/checkEvent", undefined, {
    //   subEventId: subEventId,
    //   attendeeType: attendeeType,
    // });
  };

// 내가 참여한 이벤트의 참여기업 목록
export const getApplications = (
  data: { subEventId: string; type: string },
  page: number = 0,
  pageSize: number = 10,
  searchWord: string = ""
) => {
  return getRequest(null, "/api/applications/", getApplicationsAction, {
    ...data,
    page,
    size: pageSize,
    searchWord,
  });
};

export const getApplication = (applicationId: string) => {
  return getRequest(
    null,
    "/api/applications/meetingDetail",
    getApplicationAction,
    {
      applicationId: applicationId,
    }
  );
};

// 내가 참여한 이벤트의 참여기업 목록 -> 참여기업 선택 -> 참여기업 유저 리스트
export const getAttendeeList = (applicationId: string) => {
  return getRequest(
    null,
    "/api/applicants/attendeeList",
    getAttendeeListAction,
    {
      applicationId: applicationId,
    }
  );
};

export const getApplicantList = (mainEventId: string) => {
  return getRequest(
    null,
    "/api/applicants/applicantsList",
    getApplicantListAction,
    { mainEventId: mainEventId }
  );
};

// 내 참여정보
export const getMeetingApplication = (applicationId: string) => {
  return getRequest(
    null,
    `/api/applications/meetingDetail?applicationId=${applicationId}`,
    getApplicationAction,
    {}
  );
};

// 이벤트 참여 정보
export const getSubEventApplication = (subEventId: string) => {
  return getRequest(
    null,
    `/api/applications/subEventApplication?subEventId=${subEventId}`,
    getSubEventApplicationAction,
    {}
  );
};

// 미팅현황 목록
export const getMeetingApplications = (subEventId: string) => {
  return getRequest(
    null,
    `/api/applications/meeting?subEventId=${subEventId}`,
    getApplicationsAction,
    {}
  );
};

// 미팅신청가능 목록 초기화
export const applicationActionStateToInitial =
  () => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(resetApplicationAction());
  };

// 어플리케이션에 담당자 추가
export const addManagerUser = (authCode: {
  authCode: string;
  userExist: string;
}) => {
  return postRequest(authCode, "/api/applications/manager");
};

// 어플리케이션 담당자 삭제 // ids: applicants ids, id: application id
export const removeManagers = (ids: string[], id: string) => {
  return postRequest(
    { ids, id },
    "/api/applications/managersDelete",
    removeManagersAction
  );
};

export const updateApplicationAnswers = (
  id: string,
  formAnswers: ApplicationFormAnswers[]
) => {
  return postRequest(
    { id, formAnswers },
    "/api/applications/changeApplicationFormAnswers",
    updateApplicationAnsersAction
  );
};

export const getSubEventApplicationKeywords = async (subEventId: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: { subEventId: subEventId },
    data: {},
  };
  try {
    const res = await axios.get(
      "/api/applications/subEventApplicationKeywords",
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCategoryAndKeywordRelatedApplications = (
  data: {
    applicationId: string;
    subEventId: string;
    category: string;
    keyword: string[];
    type: string;
  },
  page: number = 0,
  pageSize: number = 10,
  searchWord: string = ""
) => {
  return postRequest(
    { ...data, page, size: pageSize, searchWord },
    "/api/applications/matchingApplications",
    getApplicationsAction
  );
};

// nextRise applicationList
export const getNextRiseApplicationList = (
  data: {
    applicationId: string;
    subEventId: string;
    category: string;
    keyword: string[];
    type: string;
  },
  page: number = 0,
  pageSize: number = 50,
  searchWord: string = ""
) => {
  return postRequest(
    { ...data, page, size: pageSize, searchWord },
    "/api/applications/nextRiseMatchingApplications",
    getApplicationsAction
  )
}

export const getApplicationMeeting = (data: {
  myApplicationId: string;
  otherApplicationId: string;
}) => {
  return getRequest(
    null,
    `/api/meetings/applicationMeeting`,
    getApplicationMeetingAction,
    data
  );
};

// 선택한 업체(application)의 미팅과 내가 불가시간
export const getApplicationMeetings = (
  applicationId: string,
  myApplicationId: string
) => {
  return getRequest(
    null,
    `/api/meetings/applicationMeetings`,
    getApplicationMeetingsAction,
    { applicationId: applicationId, myApplicationId: myApplicationId }
  );
};

// 선택한 담당자 정보만 불러오기
export const getApplicant = (id: string) => {
  return getRequestAndReturn("/api/applicants", { id: id }, undefined);
};

export const deleteApplicant = async (id: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    params: { id: id },
  };

  const result: boolean = await axios.delete("/api/applicants", config);

  return result;
};

export const getManagerList = (applicationId: string) => {
  return getRequestAndReturn(
    "/api/applicants/managerList",
    { applicationId: applicationId },
    undefined
  );
};
