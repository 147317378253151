import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store";

//************************************************************//
//** 서브이벤트에 접속한 유저의 명칭 및 formId 값을 바로 가져오기 위한 hook *//
//************************************************************//

export default function useSubEventInfo() {
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );

  const [info, setInfo] = useState<{
    applicationId: string;
    groupName: string;
    surveyForm?: number;
  }>();

  useEffect(() => {
    if (subEvent && subEventApplication && subEventApplication.id) {
      if (subEventApplication.type === "buyer") {
        setInfo({
          applicationId: subEventApplication.id,
          groupName: subEvent.bgroupName,
          surveyForm: subEvent.sellerSurveyForm,
        });
      } else if (subEventApplication.type === "seller") {
        setInfo({
          applicationId: subEventApplication.id,
          groupName: subEvent.sgroupName,
          surveyForm: subEvent.sellerSurveyForm,
        });
      }
    }
  }, [subEvent, subEventApplication]);

  return info;
}
