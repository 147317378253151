import { LocalVideoTrack } from "twilio-video";
import { useCallback } from "react";
import useVideoContext from "./useVideoContext";
import { useAppState } from "../components/AppStateProvider/hooks/useAppState";

export default function useLocalVideoToggle() {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
  } = useVideoContext();
  const { hostVideoStop, selfVideoStop } = useAppState();
  const videoTrack = localTracks.find((track) =>
    track.name.includes("camera")
  ) as LocalVideoTrack;

  const stopVideo = useCallback(async () => {
    if (videoTrack) {
      if (localParticipant) {
        const localTrackPublication =
          localParticipant.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant.emit("trackUnpublished", localTrackPublication);
      }

      videoTrack.stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    videoTrack,
    localParticipant,
    getLocalVideoTrack,
    selfVideoStop,
    hostVideoStop,
  ]);

  const playVideo = useCallback(async () => {
    if (!hostVideoStop && !videoTrack && !selfVideoStop) {
      getLocalVideoTrack().then((track: LocalVideoTrack) => {
        if (localParticipant) {
          localParticipant.publishTrack(track, { priority: "low" });
        }
      });
    }
  }, [
    videoTrack,
    localParticipant,
    getLocalVideoTrack,
    selfVideoStop,
    hostVideoStop,
  ]);

  // !! => [undefiend, "", 0] 일 경우 return 'false' else 'true'
  return [!!videoTrack, stopVideo, playVideo] as const;
}
