import { useAudioVideo } from "amazon-chime-sdk-component-library-react";
import { DataMessage, MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import React, { useState, useContext, useEffect, ReactNode } from "react";
import { useAppState } from "./AppStateProvider";
import { v4 } from "uuid";
import { AppState } from "../../store";
import { useSelector } from "react-redux";

export type RealtimeData = {
  uuid: string;
  data: any;
  createdDate: number;
  senderName: string;
};

export interface RealtimeChatValue {
  chatData: RealtimeData[];
  alertCount: number;
  setReadCount: (readCount: number) => void;
  sendChatData: (msg: string) => void;
}

const RealtimeChatContext = React.createContext<RealtimeChatValue>({
  chatData: [],
  alertCount: 0,
  setReadCount: (_: number) => {},
  sendChatData: (_: string) => {},
});

export const useRealtimeChatState = (): RealtimeChatValue => {
  const state = useContext(RealtimeChatContext);
  if (!state) {
  }
  return state;
};

type Props = {
  children: ReactNode;
};

export const RealtimeChatProvider = ({ children }: Props) => {
  const audioVideo = useAudioVideo();
  const [chatData, setChatData] = useState([] as RealtimeData[]);
  const [readCount, setReadCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const { name } = useSelector((state: AppState) => state.users);

  const sendChatData = (text: string) => {
    const msg: RealtimeData = {
      uuid: v4(),
      data: text,
      createdDate: new Date().getTimezoneOffset(),
      senderName: name,
    };
    audioVideo?.realtimeSendDataMessage("CHAT", JSON.stringify(msg));
    setChatData([...chatData, msg]);
  };

  const receiveChatData = (msg: DataMessage) => {
    const data = JSON.parse(msg.text()) as RealtimeData;
    setChatData([...chatData, data]);
  };

  useEffect(() => {
    setAlertCount(chatData.length - readCount);

    console.log(`readCount Change : ${readCount}`);
  }, [readCount, chatData]);

  useEffect(() => {
    audioVideo?.realtimeSubscribeToReceiveDataMessage("CHAT", receiveChatData);
    return () => {
      audioVideo?.realtimeUnsubscribeFromReceiveDataMessage("CHAT");
    };
  });

  const providerValue = {
    chatData,
    alertCount,
    setReadCount,
    sendChatData,
  };

  return (
    <RealtimeChatContext.Provider value={providerValue}>
      {children}
    </RealtimeChatContext.Provider>
  );
};
