import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface HeadlineTypoProps {
  txtcolor?: "rgba(0,0,0,0.87)" | "purple" | "#b6b6b6";
  txtAlign?: "center";
}

type Prop = HeadlineTypoProps & TypographyProps;

const HeadlineTypo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  line-height: noromal;
  letter-spacing: normal;
  margin-bottom: 12px;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }
    return props.txtcolor;
  }};

  text-align: ${(props) => {
    if (props.txtAlign === "center") {
      return "center";
    }
  }};
`;

HeadlineTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default HeadlineTypo;
