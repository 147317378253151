import React, { useMemo } from "react";
import { Switch, RouteComponentProps, Route, Redirect } from "react-router-dom";

// router
import { eventRoutes } from "../router/router";

// type
import RouteType from "../types/models/Route";

// material comp
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { Layout } from "../components/Layout/Layout";
import useWidth from "../video/hooks/useWidth";
import { changeImgPathToCloudfrontPath } from "../utils/utils";

const Event: React.FC<RouteComponentProps> = ({ match }) => {
  const event = useSelector((state: AppState) => state.events.event);
  const width = useWidth();
  const bannerImageStyle = useMemo(
    () => ({
      width: "100%",
      height: 300,
      marginBottom: 30,
    }),
    []
  );
  const mobileBannerImageStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
      marginBottom: 30,
    }),
    []
  );
  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      switch (prop.path) {
        case "/detail":
          return (
            <>
              {event &&
                (width > 599 ? (
                  event.bannerImageFile !== undefined &&
                  event.bannerImageFile !== null && (
                    <img
                      alt="event banner"
                      src={event.bannerImageFile.filePath}
                      style={bannerImageStyle}
                    />
                  )
                ) : event.mainImageFile === null ||
                  event.mainImageFile === undefined ? (
                  <img
                    alt={event.name}
                    src={changeImgPathToCloudfrontPath(event.image)}
                    style={mobileBannerImageStyle}
                  />
                ) : (
                  <img
                    alt={event.name}
                    src={changeImgPathToCloudfrontPath(
                      event.mainImageFile.filePath
                    )}
                    style={mobileBannerImageStyle}
                  />
                ))}
              <Layout>
                <Route
                  path={`${prop.layout}${prop.path}/:id`}
                  component={prop.component}
                  key={prop.name}
                />
              </Layout>
            </>
          );
        case "/list":
          return (
            <Layout>
              <Route
                path={`/event${prop.path}`}
                component={prop.component}
                key={prop.name}
              />
            </Layout>
          );

        default:
          return <Redirect to="/" />;
      }
    });
  };
  return (
    <>
      <Switch>{getRoutes(eventRoutes)}</Switch>
    </>
  );
};

export default Event;
