import File from "../types/models/File";
import { AppActions, AppState } from "../store";
import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Alert from "../types/models/Alert";
import uuid from "uuid";
import Error from "../types/models/Error";
import { setLoadingAction, removeLoadingAction } from "./loading";

// SignedRequest for image Upload to AWS S3
export const createFileAction = (file: File): AppActions => ({
  type: "FILE_UPLOAD",
  file,
});

export const resetFileAction = (): AppActions => ({
  type: "FILE_RESET",
});

// 파일등록
export const getSignedRequest = (
  file: File,
  fileName: string,
  fileType: string,
  folderPath: string,
  fileSize: number
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  // const reg = /(jpg|jpeg|png|gif|bmp)$/;

  if (fileSize > 10 * 1024 * 1024) {
    const alert: Alert = {
      alertType: "warning",
      id: uuid.v4(),
      msg: "파일 사이즈가 10mb를 넘지 않아야합니다.",
    };
    dispatch(setAlertAction(alert));
    return;
  }

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("file", file as any);
  formData.append("fileName", fileName as string);
  formData.append("fileType", fileType as string);
  formData.append("folderPath", folderPath as string);
  formData.append("fileSize", fileSize.toString() as string);

  try {
    dispatch(setLoadingAction());
    const res: AxiosResponse<any> = await axios.post(
      "/api/fileS3",
      formData,
      config
    );
    dispatch(removeLoadingAction());
    return res.data;
  } catch (err) {
    dispatch(removeLoadingAction());
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};

// 파일 리덕스 초기화
export const fileStateToInitial = () => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  dispatch(resetFileAction());
};

const setAlert = (status: number, error: Error): Alert => {
  const alert: Alert = {
    alertType: "warning",
    id: uuid.v4(),
    msg: error.detail,
  };

  if (status === 403) {
    alert.msg = "권한이 없습니다.";
  }

  return alert;
};
