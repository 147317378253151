import User, { EventList, UpdatePwd, UpdateLike } from "../types/models/User";
import { AppActions, AppState } from "../store";
import { postRequest, getRequest, putRequest, setAlert,getPageRequest } from "../utils/utils";
import { Application } from "../types/models/Application";
import BusinessCard from "../types/models/BusinessCard";
import { Dispatch } from "redux";
import axios, { AxiosResponse } from "axios";
import Alert from "../types/models/Alert";
import { setAlertAction, removeAlertAction } from "./alerts";
import uuid from "uuid";
import { EventInfo,  PageEventProps,  Pagination } from "../types/models/Event";
import countries from "../assets/json/contries.json";
import timeZone from "../assets/json/timeZone.json";
import { addCountriesAction, choiceCountry, setCountryAction } from "./country";

export const createUserAction = (user: User): AppActions => ({
  type: "CREATE_USER",
  user,
});

export const getUserAction = (user: User): AppActions => ({
  type: "GET_USER",
  user,
});

export const getAuthTokenAction = (user: User): AppActions => ({
  type: "GET_AUTH_TOKEN",
  user,
});

export const getMyApplicationsAction = (
  applications: Application[]
): AppActions => ({
  type: "GET_MY_APPLICATIONS",
  applications: applications,
});

export const getMyEventListAction = (eventList: EventList[]): AppActions => ({
  type: "GET_MY_EVENT_LIST",
  eventList: eventList,
});

export const getMyApprovedEventListAction = (
  eventList: Pagination
): AppActions => ({
  type: "GET_MY_APPROVED_EVENT_LIST",
  approvedEventList: eventList,
});

export const getMyLikeLIstAction = (eventList: Pagination): AppActions => ({
  type: "GET_MY_LIKE_LIST",
  likeList: eventList,
});

export const getMyLikesIdAction = (likeListId: number[] ):AppActions=> ({
  type:"GET_MY_LIKE_LIST_ID",
  likeListId ,
});

export const getMySubEventBusinessCard = (
  businessCard: BusinessCard
): AppActions => ({
  type: "GET_MY_SUBEVENT_BUSINESSCARD",
  subEventBusinessCard: businessCard,
});

export const resetMySubEventBusinessCard = (): AppActions => ({
  type: "RESET_MY_SUBEVENT_BUSINESSCARD",
});

export const createUser = (user: User) => {
  return postRequest(user, "/api/users", createUserAction);
};

export const getUser = (userId: string) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/users/${userId}`;
  try {
    var res: AxiosResponse<User> = await axios.get(path, config);
    const code = countries.find(data => data.code === res.data.countryCode);
    const countriesTimeZone = {
      [code?.code ?? 'KR']: {
          koName: code?.ko_name ??"대한민국",
          enName: code?.en_name ?? "Korean",
          timezone: res.data.timeZone ?? "Asia/Seoul",
          i18code: code?.code ?? "ko",
      }
    }
    const contry = {
      country: code?.en_name ?? "South Korean",
      thisCountryTz: res.data.timeZone ?? 'Asia/Seoul',
      countryCode: code?.code ?? 'KR',
      city: res.data.timeZone?.split('/')[1] ?? 'Seoul',
      continent:res.data.timeZone?.split('/')[0] ?? 'Asia'
    }
    await dispatch(addCountriesAction(countriesTimeZone, res.data.countryCode ?? "KR"));
    await dispatch(getUserAction(res.data));
    await dispatch(setCountryAction(contry));

    return res.data;
  } catch (err) {
    const alert: Alert = setAlert(err.response.status, err.response.data, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 내가 만든 이벤트 목록 불러오기
export const getMyApplications = () => {
  return getRequest(null, "/api/applications/myList", getMyApplicationsAction);
};

// 내가 참여한 이벤트 목록 불러오기
export const getMyEventList = () => {
  return getRequest(null, "/api/applicants/myEventList", getMyEventListAction);
};

// 내가 참여하고 승인된 이벤트 목록 불러오기
export const getMyApprovedEventList = (page:number=0,data:EventInfo[]=[]) => {
  return getPageRequest(
    data,
    `/api/applicants/myApprovedEventList?page=${page}&size=12`,
    getMyApprovedEventListAction
  );
};

export const removeUser = (): AppActions => ({
  type: "REMOVE_USER",
});

export const updateUserAction = (user: User): AppActions => ({
  type: "UPDATE_USER",
  user: user,
});

export const updateUserPwdAction = (UpdatePwd: UpdatePwd): AppActions => ({
  type: "UPDATE_USER_PWD",
  UpdatePwd: UpdatePwd,
});

export interface LoginInfo {
  email: string;
  password: string;
}
export const getAuthToken = (loginInfo: LoginInfo) => {
  return postRequest(loginInfo, "/api/users/login", getAuthTokenAction);
};

// 아이디 찾기
export const getFindEmail = (name: string, phoneNumber: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/users/findUserEmail?name=${name}&phoneNumber=${phoneNumber}&role=user`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    return res.data;
  } catch (err) {
    const alert: Alert = setAlert(err.response.status, err.response.data, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 비밀번호 변경 코드 이메일 발송
export const postPwdCode = (email: { [key: string]: string }) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  // const {email, name, company, countryNumber, phoneNumber, password, role} = user;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(email);

  try {
    const res: AxiosResponse = await axios.post(
      `/api/users/postPwdCode`,
      body,
      config
    );
    return res.data;
  } catch (err) {
    const error: any = err.response.data;

    let message: string = error.detail;

    const alert: Alert = {
      alertType: "warning",
      id: uuid.v4(),
      msg: message,
    };

    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 비밀번호 코드로 이메일 찾기
export const findEmailByCode = (code: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/users/findEmailByCode?code=${code}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    return res.data;
  } catch (err) {
    const alert: Alert = setAlert(err.response.status, err.response.data, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 비밀번호 변경
export const changePwd = (changeData: any) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(changeData);

  const path = "/api/users/changePwd";
  try {
    var res: AxiosResponse = await axios.put(path, body, config);

    return res.data;
  } catch (err) {
    console.log(err.response.data)
    const alert: Alert = setAlert(err.response.status, err.response.data, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};

export const updateUser = (user: User) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify(user);

    var res: AxiosResponse = await axios.put("/api/users", body, config);

    dispatch(updateUserAction(res.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateUserInfo = (user: User) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify(user);
    console.log(body)
    var res: AxiosResponse = await axios.put("/api/users/update", body, config);
    const code = countries.find(data => data.code === res.data.countryCode);
    const countriesTimeZone = {
      [code?.code ?? 'KR']: {
          koName: code?.ko_name ??"대한민국",
          enName: code?.en_name ?? "Korean",
          timezone: res.data.timeZone ?? "Asia/Seoul",
          i18code: code?.code ?? "ko",
      }
    }
    const contry = {
        country: code?.en_name ?? "South Korean",
        thisCountryTz: user.timeZone ?? "Asia/Seoul",
        countryCode: code?.code ?? "KR",
        city: user.timeZone?.split("/")[1] ?? "Seoul",
        continent: user.timeZone?.split("/")[0] ?? "Asia",
      };
    const key = code?.code
    await dispatch(setCountryAction(contry));
    await dispatch(addCountriesAction(countriesTimeZone, res.data.countryCode ?? "KR"));
    dispatch(updateUserAction(res.data));
    return true;
  } catch (err) {
    return false;
  }
};

export const updatePwd = (changeData: UpdatePwd) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(changeData);

  const path = "/api/users/update/password";
  try {
    const res: AxiosResponse = await axios.put(path, body, config);

    return res.data;
  } catch (err) {
    const alert: Alert = setAlert(err.response.status, err.response.data, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};

export const updateLike = (data: UpdateLike) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(data);
  const path = "/api/likes";

  try {
    const res: AxiosResponse = await axios.post(path, body, config);
    return res.data;
  } catch (err) {
    const alert: Alert = setAlert(err.response.status, err.response.data, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};

export const updateBusinessCard = (businessCard: BusinessCard) => {
  return putRequest(
    businessCard,
    "/api/businessCards",
    getMySubEventBusinessCard
  );
};

export const getMyLikesId = () => {return getRequest(null,"/api/likes/myLikeList",getMyLikesIdAction)}

export const getMyLikeList = (page:number=0,more:boolean=false,events:EventInfo[]=[],last:boolean=false) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const path = `/api/likes/myLikeList?page=${page}&size=12`;
  if(last) return false;

  try {
    const res: AxiosResponse = await axios.post(path, config);
    if(more){
      res.data.content = [...events,...res.data.content]
      dispatch(getMyLikeLIstAction(res.data));
      return res.data
    }else dispatch(getMyLikeLIstAction(res.data));
  } catch (err) {
    console.log(err)
    const alert: Alert = setAlert(err.response.status, err.response.data, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};
