import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store";

// actions
import { getMeetingCounselList } from "../../../../actions/meeting";

// component
import CounselListTable from "./CounseListTable";

export const CounselList = () => {
  const dispatch = useDispatch();
  const { id } = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const { counselMeetings } = useSelector((state: AppState) => state.meetings);

  useEffect(() => {
    if (id !== undefined) dispatch(getMeetingCounselList(Number(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <CounselListTable tableRowData={counselMeetings ?? []} />
    </>
  );
};

export default CounselList;
