import React, { ReactNode } from "react";
import styled from "styled-components";

const Title = styled.div`
  position: relative;
  display: inline-block;
  height: 100%;
  min-width: 100px;
  font-size: 1.5rem; //36px
  justify-content: center;
  font-weight: 500;
  padding-left: 12px;
  color: rgba(0 0 0, 0.87);
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1px 0px;
    width: 4px;
    height: 100%;

    content: "";
    border: 2px solid rgb(81, 45, 168);
    border-radius: 2px;
  }

  @media only screen and (max-width: 599px) {
    font-size: 1.25rem;
  }
`;
interface Props {
  children: ReactNode;
}
const SubEventHeading = ({ children }: Props) => <Title>{children}</Title>;

export default SubEventHeading;
