import React, { useCallback, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";

// bootstrap component
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

// custom Component
import CaptionTypo from "../Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";
import ButtonTypo from "../Typhography/ButtonTypo";

import PexpoLogoSvg from "../../assets/images/pexpo_signature.svg";
import facebook from "../../assets/images/icon-facebook.svg";
import instagram from "../../assets/images/icon-instagram.svg";
import linkedin from "../../assets/images/icon-linkedin.svg";
import naver from "../../assets/images/icon-naver.svg";
import Body1Typo from "../Typhography/Body1Typo";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import Axios from "axios";
import { getRequest, getRequestAndReturn } from "../../utils/utils";

const StyledContainer = styled(Container)`
  position: relative;
  height: 150px;
  right: 0;
  top: 20px;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e9eaed;

  & div.nav {
    justify-content: center;

    & a {
      color: grey;
    }
  }
`;

const SectionBar = styled.span`
  ::before {
    content: "|";
    color: rgba(0, 0, 0, 0.38);
    font-size: 0.875rem;
    font-weight: 500;
    position: absolute;
    transform: translateY(calc(50% - 4px));
  }
`;

const StyleRow = styled(Row)`
  margin: 0;
`;

const SLink = styled(Link)`
  background-color: rgba(0, 0, 0, 0.01);
  width: 100vw;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
`;

const WebPageBtn = styled.button`
  background-color: rgba(0, 0, 0, 0.01);
  width: 100vw;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  &:hover {
    text-decoration: none;
  }
`;

const StyleA = styled.a`
  background-color: rgba(0, 0, 0, 0.01);
  width: 100vw;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
`;

const Manual = styled.div`
  padding: 9px 36px;
  color: rgb(81, 45, 168);
  border: 1px solid rgb(81, 45, 168);
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

const PexpoLogo = styled.img`
  width: 89.6px;
  height: 24px;
  display: inline-block;
  margin-right: 8.4px;
`;

interface FooterItem {
  id: number;
  type: FooterType;
  content: string;
  link: string;
  imgUrl: string | null;
  hide: "PUBLIC" | "PRIVATE" | null;
}

enum FooterType {
  WEB_PAGE = "WEB_PAGE",
  MANUAL = "MANUAL",
  BLOG = "BLOG",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  TERM = "TERM",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  EMAIL_INQUIRY = "EMAIL_INQUIRY",
  COPYRIGHT = "COPYRIGHT",
}

enum FooAttribute {
  CONTENT = "CONTENT",
  LINK = "LINK",
  IMG_URL = "IMG_URL",
  HIDE = "HIDE",
}

interface FooterItemMap {
  [key: string]: FooterItem;
}

const Footer: React.FC = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const pathName = history.location.pathname;
  const event = useSelector((state: AppState) => state.events.event);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const dispatch = useDispatch();
  const [footerItems, setFooterItems] = useState<FooterItem[]>([]);
  const [footerData, setFooterData] = useState<FooterItemMap>();

  useEffect(() => {
    if (event?.id) {
      getFooterItems(event?.id);
    }
  }, [event]);

  const getFooterItems = async (eventId: number) => {
    const res: any = await dispatch(
      getRequestAndReturn(`/api/events/event-footer/${event?.id}`, undefined)
    );

    // const footerItemMap: FooterItemMap = (res as FooterItem[])
    //   .reduce((acc: FooterItemMap, item: FooterItem) => {
    //     acc[item.type] = item;
    //     return acc;
    //   }, {})
    // setFooterData(footerItemMap);
    setFooterItems(res);
  };

  const findTypeItem = useCallback(
    (paramType: FooterType, attribute: FooAttribute) => {
      if (footerItems.length > 0) {
        const findItem: FooterItem | undefined = footerItems.find(
          (item) => item.type === paramType
        );
        switch (attribute) {
          case FooAttribute.CONTENT:
            return findItem?.content != null || findItem?.content != ""
              ? findItem!.content
              : undefined;

          case FooAttribute.LINK:
            return findItem?.link != null && findItem?.link != ""
              ? ensureHttpPrefix(findItem!.link)
              : undefined;

          case FooAttribute.IMG_URL:
            return findItem?.imgUrl != null && findItem.imgUrl !== ""
              ? findItem?.imgUrl
              : undefined;

          case FooAttribute.HIDE:
            return findItem?.hide != null || findItem?.hide != null
              ? findItem.hide
              : undefined;
        }
      } else {
        return undefined;
      }
    },
    [footerItems]
  );

  function ensureHttpPrefix(url: string): string {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  }

  const WebSiteLink = ({
    url,
    children,
  }: {
    url: string;
    children: React.ReactNode;
  }) => {
    if (url === "/") {
      return <SLink to="/">{children}</SLink>;
    } else {
      return <StyleA href={ensureHttpPrefix(url)}>{children}</StyleA>;
    }
  };

  return (
    <StyledContainer fluid="true">
      {pathName.includes("/event/detail/") &&
        event?.showLogo !== "N" &&
        event?.id !== 11387 &&
        event?.id !== 287420 &&
        findTypeItem(FooterType.WEB_PAGE, FooAttribute.HIDE) != "PRIVATE" && (
          <>
            <StyleRow>
              {/* <WebSiteLink
                  url={
                    footerItems.find(
                      (item) => item.type === FooterType.WEB_PAGE
                    ) !== undefined
                      ? footerItems.find(
                          (item) => item.type === FooterType.WEB_PAGE
                        )!.link
                      : "/"
                  }
                > */}
              <WebPageBtn
                onClick={() => {
                  const url =
                    findTypeItem(FooterType.WEB_PAGE, FooAttribute.LINK) !=
                    undefined
                      ? findTypeItem(FooterType.WEB_PAGE, FooAttribute.LINK)
                      : "/";

                  window.open(url);
                }}
              >
                <PexpoLogo
                  src={
                    findTypeItem(FooterType.WEB_PAGE, FooAttribute.IMG_URL) !=
                    undefined
                      ? findTypeItem(FooterType.WEB_PAGE, FooAttribute.IMG_URL)
                      : PexpoLogoSvg
                  }
                  alt="Pexpo logo"
                  style={{ cursor: "pointer" }}
                />
                <ButtonTypo txtcolor="iris">
                  {t("footer.GoToWebsite")}
                </ButtonTypo>
              </WebPageBtn>
              {/* </WebSiteLink> */}
            </StyleRow>
          </>
        )}
      {event?.id !== 11387 &&
        event?.id !== 287420 &&
        findTypeItem(FooterType.MANUAL, FooAttribute.HIDE) != "PRIVATE" && (
          <StyleRow style={{ marginTop: 40 }}>
            <Col>
              <Nav>
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    eventKey="link-2"
                    href={
                      findTypeItem(FooterType.MANUAL, FooAttribute.LINK) !=
                        undefined &&
                      findTypeItem(FooterType.MANUAL, FooAttribute.LINK) !=
                        "" &&
                      findTypeItem(FooterType.MANUAL, FooAttribute.HIDE) !=
                        "PRIVATE"
                        ? findTypeItem(FooterType.MANUAL, FooAttribute.LINK)
                        : t("footer.manualLink")
                    }
                  >
                    <Manual>{t("footer.onlineManual")}</Manual>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </StyleRow>
        )}
      {event?.showLogo !== "N" && footerItems && (
        <StyleRow style={{ marginTop: 32 }}>
          <Col>
            <Nav>
              {findTypeItem(FooterType.BLOG, FooAttribute.HIDE) !=
                "PRIVATE" && (
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    eventKey="link-2"
                    href={
                      findTypeItem(FooterType.BLOG, FooAttribute.LINK) !=
                        undefined &&
                      findTypeItem(FooterType.BLOG, FooAttribute.LINK) != ""
                        ? findTypeItem(FooterType.BLOG, FooAttribute.LINK)
                        : "https://blog.naver.com/pexpo"
                    }
                  >
                    <img src={naver} />
                  </Nav.Link>
                </Nav.Item>
              )}
              {findTypeItem(FooterType.FACEBOOK, FooAttribute.HIDE) !=
                "PRIVATE" && (
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    eventKey="link-2"
                    href={
                      findTypeItem(FooterType.FACEBOOK, FooAttribute.LINK) !=
                        undefined &&
                      findTypeItem(FooterType.FACEBOOK, FooAttribute.LINK) != ""
                        ? findTypeItem(FooterType.FACEBOOK, FooAttribute.LINK)
                        : "https://www.facebook.com/leslie.park.984"
                    }
                  >
                    <img src={facebook} />
                  </Nav.Link>
                </Nav.Item>
              )}
              {findTypeItem(FooterType.INSTAGRAM, FooAttribute.HIDE) !=
                "PRIVATE" && (
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    target="_blank"
                    href={
                      findTypeItem(FooterType.INSTAGRAM, FooAttribute.LINK) !=
                        undefined &&
                      findTypeItem(FooterType.INSTAGRAM, FooAttribute.LINK) !=
                        ""
                        ? findTypeItem(FooterType.INSTAGRAM, FooAttribute.LINK)
                        : "https://www.instagram.com/pexpo_kinterch/?hl=ko"
                    }
                  >
                    <img src={instagram} />
                  </Nav.Link>
                </Nav.Item>
              )}
              {findTypeItem(FooterType.LINKEDIN, FooAttribute.HIDE) !=
                "PRIVATE" && (
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    eventKey="link-2"
                    href={
                      findTypeItem(FooterType.LINKEDIN, FooAttribute.LINK) !=
                        undefined &&
                      findTypeItem(FooterType.LINKEDIN, FooAttribute.LINK) != ""
                        ? findTypeItem(FooterType.LINKEDIN, FooAttribute.LINK)
                        : "https://www.linkedin.com/in/leslie-park-cem-34098954/"
                    }
                  >
                    <img src={linkedin} />
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Col>
        </StyleRow>
      )}
      <StyleRow style={{ marginTop: 20 }}>
        <Col>
          <Nav activeKey="/main">
            <Nav.Item>
              <Nav.Link
                target="_blank"
                eventKey="link-1"
                href={
                  findTypeItem(FooterType.TERM, FooAttribute.LINK) !=
                    undefined &&
                  findTypeItem(FooterType.TERM, FooAttribute.LINK) != ""
                    ? findTypeItem(FooterType.TERM, FooAttribute.LINK)
                    : "https://www.notion.so/PEXPO-043bbec7d9754752b748be6f4d4e3701"
                }
              >
                <Body1Typo>{t("footer.termsOfUse")}</Body1Typo>
              </Nav.Link>
            </Nav.Item>
            <SectionBar />
            <Nav.Item>
              <Nav.Link
                target="_blank"
                eventKey="link-2"
                href={
                  findTypeItem(FooterType.PRIVACY_POLICY, FooAttribute.LINK) !=
                    undefined &&
                  findTypeItem(FooterType.PRIVACY_POLICY, FooAttribute.LINK) !=
                    ""
                    ? findTypeItem(FooterType.PRIVACY_POLICY, FooAttribute.LINK)
                    : "https://www.notion.so/PEXPO-21ad4093a9084c08bc61ffe7c0fc3865"
                }
              >
                <Body1Typo>{t("footer.privacyPolicy")}</Body1Typo>
              </Nav.Link>
            </Nav.Item>
            <SectionBar />
            <Nav.Item>
              <Nav.Link
                eventKey="link-2"
                onClick={() => {
                  window.location.assign(
                    `mailto:${
                      findTypeItem(
                        FooterType.EMAIL_INQUIRY,
                        FooAttribute.LINK
                      ) != undefined
                        ? findTypeItem(
                            FooterType.EMAIL_INQUIRY,
                            FooAttribute.LINK
                          )
                        : "info@pexpo.io"
                    }`
                  );
                }}
              >
                <Body1Typo>{t("footer.emailInquiry")}</Body1Typo>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </StyleRow>
      {event?.showLogo !== "N" && (
        <StyleRow className="text-center text-muted">
          <Col
            className="p-4
        "
          >
            {findTypeItem(FooterType.COPYRIGHT, FooAttribute.HIDE) !=
              undefined &&
              findTypeItem(FooterType.COPYRIGHT, FooAttribute.HIDE) ==
                "PUBLIC" && (
                <CaptionTypo>©2020 Pexpo. All rights reserved.</CaptionTypo>
              )}
          </Col>
        </StyleRow>
      )}
    </StyledContainer>
  );
};

export default Footer;
