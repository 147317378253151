import { makeStyles } from "@material-ui/core/styles";


const drawerWidth = 240;

const subEventStyles = makeStyles((theme) => ({
  breakpoints:{
    display:"flex",
    margin:"auto",
    height:"100%",
  
  },
  root: {
    display: "flex",
    margin: "auto",
   
  },
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    color: "black",
      paddingLeft: (drawerWidth + 80),
      paddingRight:80,

    [theme.breakpoints.down(1280)]: {
      paddingLeft: (drawerWidth + 40),
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-100vw",
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft:"-100vw",
      paddingLeft: 16,
      paddingRight: 16,
    },
    
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    
      paddingLeft: (drawerWidth + 80),
      paddingRight:80,
    
    [theme.breakpoints.down(1280)]: {
      paddingLeft: (drawerWidth + 40),
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-100vw",
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft:"-100vw",
      paddingLeft: 16,
      paddingRight: 16,
    },
    
  },
  menuButton: {
    paddingRight:18,
    paddingLeft:0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,
    display:"flex",
    overflowY:"unset",
    backgroundColor: "rgb(241, 242, 245)",
 
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "1em",
    ...theme.mixins.toolbar,
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
    },
    
  },
  content: {
    flexGrow: 1,
    width: "100%",
    overflowX: "auto",
    height:"100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingTop: 84,
    
      marginLeft:drawerWidth,
      paddingLeft: 80,
      paddingRight:80,
    
    [theme.breakpoints.down("md")]: {
      marginLeft:drawerWidth,
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft:0,
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft:0,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
    },
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default subEventStyles;
