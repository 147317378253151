import React, { useMemo } from "react";
import { Switch, RouteComponentProps, Route } from "react-router-dom";
import styeld from "styled-components";
import { Layout } from "../components/Layout/Layout";

// router
import { EventListRoutes } from "../router/router";

// type
import RouteType from "../types/models/Route";

// material comp
import { useSelector } from "react-redux";
import { AppState } from "../store";

const EventList: React.FC<RouteComponentProps> = ({ match }) => {
  const event = useSelector((state: AppState) => state.events.event);
  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      if (prop.layout + prop.path === "/eventlist/") {
        return (
          <Route
            path={`${prop.layout}${prop.path}`}
            component={prop.component}
            key={key}
          />
        );
      }
    });
  };

  const bannerImageStyle = useMemo(
    () => ({
      width: "100%",
      maxHeight: 300,
      marginBottom: 30,
    }),
    []
  );

  return (
    <Layout>
      {" "}
      <Switch>{getRoutes(EventListRoutes)}</Switch>{" "}
    </Layout>
  );
};

export default EventList;
