import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useParams,
  useHistory,
  matchPath,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";

// actions
import {
  applicationActionStateToInitial,
  getApplication,
  removeApplicationMeetingsAction,
} from "../../../../actions/applications";
import { getForm, removeFormAction } from "../../../../actions/forms";
import {
  removeFormAnswerAction,
  getFormAnswers,
} from "../../../../actions/formAnswers";

// types

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import CheckBox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

// custom ui comp
import ApplicationCompanyInfo from "./ApplicationCompanyInfo";
import ApplicationAdditionalInfo from "./ApplicationAdditionalInfo";
import ApplicationManagerInfo from "./ApplicationManagerInfo";
import { AppState } from "../../../../store";
import { useTranslation } from "react-i18next";
import CaptionTypo from "../../../../components/Typhography/CaptionTypo";
import PageNotFound from "../../../../components/NoResult/PageNotFound";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import ApplicationMeetingInfo from "./ApplicationMeetingInfo";

const PurpleRadio = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.54)",
    "&$checked": {
      color: "rgba(81, 45, 168,1)",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

// 탭 버튼 디자인(기업정보 추가정보 담당자)
const InfoChangeTapButton = styled((props) => {
  const { ...rest } = props;
  return <button {...rest} />;
})`
  width: 80px;
  height: 48px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  background-color: transparent;
  &:not(:last-child) {
    margin-right: 8px;
  }
  ${(props: any) => {
    if (props.variant === true) {
      return `
        background-color: rgba(69, 39, 160,0.08);
        color: rgb(81, 45, 168);
      `;
    } else if (props.variant === false) {
      return `
        color: rgba( 0, 0, 0, 0.6);
        
      `;
    }
  }}
`;

const useStyles = makeStyles((theme) => ({
  arrowBackIcon: {
    cursor: "pointer",
    margin: 12,
    width: 24,
    height: 24,
    marginRight: 20,
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  companyInfoGridItem: {
    width: "100%",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  tab: {
    display: "flex",
    alignItems: "center",
  },
}));

interface LocationState {
  isProductPage: boolean;
}

const ApplicationDetail = () => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { id: string; secondId: string } = useParams();
  const locations = useLocation();
  const isProductPage = (locations.state as LocationState)?.isProductPage;
  const [openMenu, setOpenMenu] = useState<number>(1);
  const [additionalMaxCount, setAdditionalMaxCount] = useState<number>(0); // 추가정보 반복 횟수
  const [answerProcessing, setAnswerProcessing] = useState<any>(); // 답변 가공
  const [axiosRequest, setAxiosRequest] = useState<boolean>(true);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { application, subEventApplication } = useSelector(
    (state: AppState) => state.applications
  );
  const formAnswers = useSelector(
    (state: AppState) => state.formAnswers.formAnswers
  );
  // 자유양식 질문 (셀러, 바이어 참가신청 양식)
  const forms = useSelector((state: AppState) => state.forms);

  const tabMenus =
    application.type === subEventApplication.type
      ? [
          t("companyList.companyInfo"),
          t("companyList.additionalInfo"),
          t("companyList.manager"),
        ]
      : [
          t("companyList.companyInfo"),
          t("companyList.additionalInfo"),
          t("companyList.manager"),
          t("companyList.meetingStatus"),
        ];

  useEffect(() => {
    dispatch(getApplication(param.secondId));
    return () => {
      dispatch(applicationActionStateToInitial());
      dispatch(removeFormAnswerAction());
      dispatch(removeApplicationMeetingsAction());
      dispatch(removeFormAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isProductPage) {
      setOpenMenu(2);
    }
  }, [locations]);

  // useEffect(() => {
  //   if (subEventApplication.id && application.id) {
  //     dispatch(
  //       getApplicationMeeting({
  //         myApplicationId: subEventApplication.id,
  //         otherApplicationId: application.id,
  //       })
  //     );
  //   }
  // }, [application.id, dispatch, subEventApplication.id]);

  useEffect(() => {
    const dispatchData = async (typeForm: string) => {
      const result: any = await dispatch(getForm(typeForm));
      setAxiosRequest(result);
    };
    // 자유양식 질문 조회(셀러, 바이어 참가신청 양식)
    if (subEvent !== undefined && application.id !== undefined) {
      // 주최자가 target scope를 상대방으로 지정했을경우 동일한 타입은 접근 제한
      // if (
      //   subEvent.targetScope === "others" &&
      //   application.type === subEventApplication.type
      //   // && application.id.toString() !== param.secondId.toString()
      // ) {
      //   history.push(`/subEvent/${subEvent.id}/attendeeList`);
      // }

      if (
        application.type === "seller" &&
        subEvent.sellerPartiForm !== undefined
      ) {
        dispatchData(subEvent.sellerPartiForm!.toString());
      } else if (
        application.type === "buyer" &&
        subEvent.buyerPartiForm !== undefined
      ) {
        dispatchData(subEvent.buyerPartiForm!.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent, application]);

  useEffect(() => {
    // 자유양식 답변 조회
    if (application?.id !== undefined) {
      dispatch(getFormAnswers(application?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  useEffect(() => {
    // 추가정보 반복횟수
    let additionalCountCheck = 0;
    let answerContent: any = {};

    if (formAnswers !== undefined) {
      let content: any = [];
      formAnswers.forEach((answer) => {
        const additionOrder: number = answer!.additionOrder as number;
        if (additionalCountCheck < additionOrder)
          additionalCountCheck = additionOrder;
        switch (answer.type) {
          case "keyword":
          case "file":
            content.push({
              additionOrder: additionOrder,
              uuid: answer!.uuid!,
              subId: answer.type,
              type: answer.type,
              title: answer.title!,
              content: answer.content!,
              repeatInfo: answer.repeatInfo,
            });
            break;
          case "product":
            content.push({
              additionOrder: additionOrder,
              uuid: answer!.uuid!,
              subId: answer.type,
              type: answer.type,
              repeatInfo: answer.repeatInfo,
              productName: answer.productName,
              productExplain: answer.productExplain,
              productLink: answer.productLink,
              productImg: answer.productImg,
            });
            break;
          case "category":
          case "single":
          case "many":
            content.push({
              additionOrder: additionOrder,
              uuid: answer!.uuid!,
              subId: answer!.answerUuid,
              type: answer.type,
              title: answer.title!,
              content: answer.content!,
              repeatInfo: answer.repeatInfo,
            });
            break;
          default:
            content.push({
              additionOrder: additionOrder,
              uuid: answer!.uuid!,
              subId: "text",
              type: answer.type,
              title: answer.title!,
              content: answer.content!,
              repeatInfo: answer.repeatInfo,
            });
            break;
        }
      });
      content.forEach((answerData: any) => {
        if (additionalCountCheck < answerData!.additionOrder)
          additionalCountCheck = answerData!.additionOrder;

        if (answerData.type === "product") {
          answerContent[
            `${answerData.repeatInfo}&&${answerData.additionOrder}&&${answerData.uuid}&&${answerData.subId}`
          ] = {
            productName: answerData.productName,
            productExplain: answerData.productExplain,
            productImg: answerData.productImg,
            productLink: answerData.productLink,
            type: answerData.type,
          };
        } else {
          answerContent[
            `${answerData.repeatInfo}&&${answerData.additionOrder}&&${answerData.uuid}&&${answerData.subId}`
          ] = {
            content: answerData.content,
            type: answerData.type,
          };
        }
      });

      setAdditionalMaxCount(additionalCountCheck);
      setAnswerProcessing(answerContent);
    }
  }, [formAnswers]);

  // 탭 버튼
  const infoChangeTapButtonForm = (menuNm: string, idx: number) => {
    let menuExist = true;

    // 추가정보가 없을 경우
    if (menuNm === t("companyList.additionalInfo")) {
      if (!forms.additional) {
        menuExist = false;
      } else if (forms.additional !== "") {
        const additionalParse = JSON.parse(forms!.additional!).formIds;
        if (
          menuNm === t("companyList.additionalInfo") &&
          additionalParse.length < 1
        ) {
          menuExist = false;
        }
      }
    }
    // 미팅현황이 없을 경우
    if (
      menuNm === t("companyList.meetingStatus") &&
      (subEvent?.matching === "N" || subEvent?.meetingAvailable === "N")
    ) {
      menuExist = false;
    }

    return menuExist ? (
      <InfoChangeTapButton
        key={idx}
        variant={openMenu === idx}
        onClick={() => {
          setOpenMenu(idx);
        }}
      >
        {menuNm}
      </InfoChangeTapButton>
    ) : (
      <Fragment key={idx} />
    );
  };

  return application !== undefined &&
    answerProcessing !== undefined &&
    forms !== undefined &&
    application.name ? (
    <>
      <Grid container>
        <PxGridContainer
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item className={classes.tab}>
            <ArrowBackIcon
              className={classes.arrowBackIcon}
              onClick={() => history.goBack()}
            />
            {tabMenus.map((menuNm, idx) =>
              infoChangeTapButtonForm(menuNm, idx + 1)
            )}
          </Grid>
        </PxGridContainer>
        <Grid item className={classes.companyInfoGridItem}>
          <CaptionTypo txtcolor="black0.38" gutterBottom>
            <b>
              {application.type === "seller"
                ? subEvent?.sgroupName
                : subEvent?.bgroupName}
            </b>
          </CaptionTypo>
          {/* <ApplicationMeeting /> */}
          <div>
            {openMenu === 1 ? (
              <>
                <ApplicationCompanyInfo answerProcessing={answerProcessing} />
              </>
            ) : openMenu === 2 ? (
              <ApplicationAdditionalInfo
                answerProcessing={answerProcessing}
                additionalMaxCount={additionalMaxCount}
              />
            ) : openMenu === 3 ? (
              <ApplicationManagerInfo />
            ) : openMenu === 4 ? (
              <ApplicationMeetingInfo />
            ) : null}
          </div>
        </Grid>
      </Grid>
    </>
  ) : null;
};

export default ApplicationDetail;
