import React, { useContext } from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

// types
import { SubEventInfo } from "../../types/models/SubEvent";

// context
import { SubEventContext } from "../../components/Providers/SubEventProvider/SubEventProvider";

// material
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface NavbarProps {
  navBarStyle: Record<"appBar" | "appBarShift" | "menuButton" | "hide", string>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  screenWidth: "xs" | "sm" | "md" | "lg" | "xl";
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  eventNameBox: {
    paddingTop: 15,
    paddingBottom: 15,
    color: "white",
  },
}));

const Navbar = ({ navBarStyle, open, setOpen, screenWidth }: NavbarProps) => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();
  const history = useHistory();
  const { selectedUrl } = useContext(SubEventContext);
  const { interpreter } = useSelector((state: AppState) => state.interpreters);

  const includeName = (urlName: string) => {
    return selectedUrl.includes(urlName);
  };
  const setNavbarLeftLinkName = () => {
    switch (true) {
      case includeName("attendeeList"): {
        return t("subEventNavBar.attendeeList");
      }
      case includeName("companyList"): {
        return "기업목록";
      }
      case includeName("meetingList"): {
        return t("subEventNavBar.meetingList");
      }
      case includeName("counselList"): {
        return t("subEventNavBar.counselList");
      }
      case includeName("speakerList"): {
        return t("subEventNavBar.speakerList");
      }
      case includeName("programList"): {
        return t("subEventNavBar.programList");
      }
      case includeName("digitalMapList"): {
        return t("subEventNavBar.digitalMapList");
      }
      case includeName("managerList"): {
        return t("subEventNavBar.managerList");
      }
      case includeName("noticeList"): {
        return t("subEventNavBar.noticeList");
      }
      case includeName("eventInfo"): {
        return t("subEventNavBar.eventInfo");
      }
      case includeName("participantInfo"): {
        return t("subEventSideBar.participantInfo");
      }
    }
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(navBarStyle.appBar, {
        [navBarStyle.appBarShift]: open,
      })}
      style={{ backgroundColor: "rgba(95,75,139,1.0)" }}
    >
      <Toolbar className={classes.root}>
        {screenWidth !== "xs" && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            className={clsx(navBarStyle.menuButton, open && navBarStyle.hide)}
            style={{ color: "white" }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box className={classes.eventNameBox}>
          <SubHeadingTypo fontweight="600" style={{ color: "white" }}>
            {(interpreter?.subEventId as SubEventInfo) === undefined
              ? ""
              : `${(interpreter?.subEventId as SubEventInfo).mainEvent?.name}(${
                  (interpreter?.subEventId as SubEventInfo).category
                })`}
          </SubHeadingTypo>
        </Box>
        {/* <SubHeadingTypo>{setNavbarLeftLinkName()}</SubHeadingTypo> */}
        {screenWidth === "xs" && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            className={clsx(navBarStyle.menuButton, open && navBarStyle.hide)}
            style={{ color: "white" }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
