import { getRequest } from "../utils/utils";
import { Interpreter } from "../types/models/Interpreter";
import { AppActions } from "../store";

export const getInterpretersAction = (
  interpreters: Interpreter[]
): AppActions => ({
  type: "GET_INTERPRETERS",
  interpreters: interpreters,
});

export const getInterpreterAction = (interpreter: Interpreter): AppActions => ({
  type: "GET_INTERPRETER",
  interpreter: interpreter,
});

export const removeInterpreterAction = (): AppActions => ({
  type: "REMOVE_INTERPRETER",
});

export const removeInterpretersAction = (): AppActions => ({
  type: "REMOVE_INTERPRETERS",
});

export const getInterpreters = (userId: string) => {
  return getRequest(null, "/api/interpreters/user", getInterpretersAction, {
    userId: userId,
  });
};

export const getInterpreter = (userId: string, subEventId: string) => {
  return getRequest(null, "/api/interpreters/userCheck", getInterpreterAction, {
    userId: userId,
    subEventId: subEventId,
  });
};

// export const getInterpreter = async()
