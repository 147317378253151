import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material ui comp
import { GridProps } from "@material-ui/core/Grid";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

import Body1Typo from "../../Typhography/Body1Typo";
import CardContent from "@material-ui/core/CardContent";
import SubHeadingTypo from "../../Typhography/SubHeadingTypo";
import EmptyLikeIcon from "../../../assets/icons/favoriteIcon.png";
import LikeIcon from "@material-ui/icons/Favorite";

import { parallaxCalculation } from "../../../utils/momentTz";
import { AppState } from "../../../store";

// types
import { EventInfo } from "../../../types/models/Event";
import { getMyLikesId } from "../../../actions/users";
import { changeImgPathToCloudfrontPath } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

interface PxGridContainerProps {
  width: Breakpoint;
  parent?: boolean;
}

interface CardImgProps {
  img: string;
}

type Prop = GridProps & PxGridContainerProps;

const Container = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(4, 1fr);

  gap: 20px;
  @media only screen and (max-width: 1279px) {
    gap: 16px;
  }
  @media only screen and (max-width: 1023px) {
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 599px) {
    gap: 8px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media only screen and (max-width: 360px) {
    gap: 8px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

interface Favorite {
  Favorite: string;
}

const Icon = styled.div<Favorite>`
  width: 24px;
  height: 24px;
  background-image: url(${(props) => props.Favorite});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    cursor: pointer;
  }
`;

const SLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const FavoriteBtn = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  flex-direction: column;
  margin-bottom: 20px;
  &:hover {
    cursor: "pointer";
  }
`;
const ImgContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 62.5%;
  border: none;
  border-radius: 4px;
`;
const CardImg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  border: none;
  border-radius: 4px;
  content: "";
`;

interface CardContainerProps {
  eventList?: EventInfo[] | undefined;
}

const CardContainer = ({ eventList }: CardContainerProps) => {
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.token) {
      dispatch(getMyLikesId());
    }
  }, []);
  const likeList = useSelector((state: AppState) => state.users!.likeListId);

  let like: Array<number> = [];
  if (likeList && likeList.length >= 0) {
    for (const value of likeList!) {
      like.push(value);
    }
  }

  const setEventName = useCallback(
    (ev) => {
      if (i18n.language === "ko") {
        return ev.name;
      } else if (i18n.language !== "ko" && ev.nameEn) {
        return ev.nameEn;
      } else {
        return ev.name;
      }
    },
    [i18n.language]
  );

  return (
    <Container>
      {Array.isArray(eventList) &&
        eventList.length > 0 &&
        eventList.map((ev, index) => (
          <Item key={`main-event-list-${index}`}>
            <SLink to={`/event/detail/${ev.id}`}>
              <ImgContainer>
                <CardImg>
                  {/* styled component에 img 넣으면 사파리에서 이미지 안보임 */}
                  {ev.mainImageFile === null ||
                  ev.mainImageFile === undefined ? (
                    <img
                      alt={ev.name}
                      src={changeImgPathToCloudfrontPath(ev.image)}
                      style={{
                        display: "absolute",
                        width: "100%",
                        height: "100%",
                        borderRadius: 4,
                      }}
                    />
                  ) : (
                    <img
                      alt={ev.name}
                      src={changeImgPathToCloudfrontPath(
                        ev.mainImageFile.filePath
                      )}
                      style={{
                        display: "absolute",
                        width: "100%",
                        height: "100%",
                        borderRadius: 4,
                      }}
                    />
                  )}
                </CardImg>
              </ImgContainer>
              <FavoriteBtn>
                {like.includes(ev.id!) ? (
                  <LikeIcon style={{ color: "rgb(81, 45, 168)" }} />
                ) : (
                  <Icon Favorite={EmptyLikeIcon} />
                )}
                {/* 버튼 누르면 보라색 하트 나오게 하기  */}
              </FavoriteBtn>

              <CardContent className="px-0 py-2">
                <Body1Typo txtcolor="black0.6">
                  {parallaxCalculation(
                    ev.startDate,
                    "",
                    countries![country!.countryCode!] as any,
                    "YYYY. MM. DD"
                  )}
                  &nbsp;-&nbsp;
                  {parallaxCalculation(
                    ev.endDate,
                    "",
                    countries![country!.countryCode!] as any,
                    "MM. DD"
                  )}
                  {/* <CountryTimeForm
												startDate={ev.startDate}
												endDate={ev.endDate}
												hostCountryCode={ev.countryCode!}
											/> */}
                </Body1Typo>
                <SubHeadingTypo txtOverflow={true} fontweight="500">
                  {setEventName(ev)}
                </SubHeadingTypo>
              </CardContent>
            </SLink>
          </Item>
        ))}
    </Container>
  );
};

export default CardContainer;
