import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  LocalAudioTrack,
  LocalParticipant,
  LocalTrack,
  Participant,
  RemoteParticipant,
} from "twilio-video";
import { newAlert } from "../../../../actions/alerts";
import { AppState } from "../../../../store";
import { addBlackList } from "../../../hooks/useSubscriptionUpdate";
import { Emoji, HandsUp } from "../../AppStateProvider/AppstateProvider";
import { useAppState } from "../../AppStateProvider/hooks/useAppState";
import { ChatContext } from "../../ChatProvider/ChatProvider";

interface DataReciver {
  type:
    | "emoji"
    | "handsUp"
    | "handsDown"
    | "acceptHands"
    | "finishHandsUp"
    | "getOutRoom"
    | "mute"
    | "videoStop"
    | "chatBlock"
    | "chatRemoveBlock"
    | "removeMsg";

  emoji?: Emoji["emoji"];
  handsUp?: HandsUp;
  handsDown?: Array<string>;
  acceptHands?: HandsUp;
  finishHandsUp?: string;
  getOutRoom?: string;
  mute?: { user: string; isMute: boolean };
  videoStop?: { user: string; isVideoStop: boolean };
  chatBlock?: string;
  removeMsg?: string;
}
export default function () {
  const {
    setEmojiMessage,
    setHandsUp,
    setWaitingForRequest,
    setAcceptHands,
    setBlackList,
    blackList,
    setHostVideoStop,
    setHostMute,
  } = useAppState();
  const { setChatBlock, client, setMessage } = useContext(ChatContext);
  const history = useHistory();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const webinar = useSelector((state: AppState) => state.webinars.webinar);
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();

  const messageManager = useCallback(
    async (
      message: DataReciver,
      localTracks: LocalTrack[],
      localParticipant: LocalParticipant,
      participants: Map<Participant.SID, RemoteParticipant>
    ) => {
      switch (message.type) {
        case "emoji":
          const emoji = message.emoji!;
          setEmojiMessage({ emoji, num: Math.random() });
          break;

        case "handsUp":
          setHandsUp((handsUp) => [...handsUp, message.handsUp!]);
          break;

        case "handsDown":
          const dataId = localTracks.find((data) => data.kind === "data")?.id;
          if (message.handsDown?.find((data) => data === dataId)) {
            setWaitingForRequest(false);
            dispatch(newAlert(t("messageManager.refusedHands"), "info"));
          }
          break;

        case "acceptHands":
          //손든 참가자
          if (localParticipant.sid === message.acceptHands!.participant.sid) {
            (localTracks.find(
              (data) => data.kind === "audio"
            ) as LocalAudioTrack).enable();
            setAcceptHands(localParticipant);
            setWaitingForRequest(false);
            dispatch(newAlert(t("messageManager.acceptedHands"), "info"));
            //다른 참가자들
          } else {
            setAcceptHands(
              Array.from(participants!.values()!).find(
                (data) => data.sid === message.acceptHands!.participant.sid
              )!
            );
          }
          break;

        case "finishHandsUp":
          setAcceptHands(undefined);
          if (localParticipant.sid === message.finishHandsUp) {
            setWaitingForRequest(false);
            // 손흘들었던 참가자 오디오 끄기
            (localTracks.find(
              (data) => data.kind === "audio"
            ) as LocalAudioTrack).disable();
            dispatch(
              newAlert(t("messageManager.terminatedConnection"), "info")
            );
          }
          break;

        case "getOutRoom":
          // 비디오 서버에 참가자 이름 추가 하고 강퇴
          if (
            message.getOutRoom &&
            subEvent &&
            webinar &&
            message.getOutRoom === localParticipant.identity
          ) {
            const roomId = `${subEvent.id}|${webinar.id}`;

            const result = await addBlackList(
              roomId,
              localParticipant.identity
            );
            if (result) {
              setBlackList([...blackList, message.getOutRoom]);
              history.goBack();
              dispatch(
                newAlert(t("messageManager.sentOutByPresenter"), "info")
              );
            }
          }
          break;

        case "mute":
          if (message.mute?.user === localParticipant.identity) {
            setHostMute(message.mute.isMute);
            dispatch(
              newAlert(
                message.mute.isMute
                  ? t("messageManager.mute")
                  : t("messageManager.unmute"),
                "info"
              )
            );
          }
          break;

        case "videoStop":
          if (message.videoStop?.user === localParticipant.identity) {
            setHostVideoStop(message.videoStop.isVideoStop);
            dispatch(
              newAlert(
                message.videoStop.isVideoStop
                  ? t("messageManager.videoStop")
                  : t("messageManager.videoPlay"),
                "info"
              )
            );
          }
          break;

        case "chatBlock":
          if (
            message.chatBlock &&
            message.chatBlock === client?.user.friendlyName
          ) {
            setChatBlock(true);
            dispatch(newAlert(t("messageManager.chattingProhibited"), "info"));
          }
          break;

        case "chatRemoveBlock":
          if (
            message.chatBlock &&
            message.chatBlock === client?.user.friendlyName
          ) {
            setChatBlock(false);
            dispatch(
              newAlert(t("messageManager.chatRestrictionReleased"), "info")
            );
          }
          break;

        case "removeMsg":
          setMessage((data) => ({
            ...data,
            messages: data.messages.map((msg) => {
              return msg.author.sid === message.removeMsg
                ? { ...msg, text: "삭제된 메시지입니다" }
                : msg;
            }),
          }));
          break;
      }
    },
    [subEvent, webinar]
  );

  return [messageManager];
}
