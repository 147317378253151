import React from "react";
import { Participant } from "twilio-video";
import MainEmojiView from "./MainEmojiView";
import MainHandsUpView from "./MainHandsUpView";
import styled from "styled-components";
import useHeight from "../../hooks/useHeight";

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  acceptHands?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
}

export default function MainParticipantInfo({
  children,
  acceptHands,
}: MainParticipantInfoProps) {
  // const isVideoEnabled = Boolean(videoPublication);
  // const videoTrack = useTrack(screenSharePublication || videoPublication);
  const height = useHeight();

  return (
    <Div acceptHands={acceptHands!} height={height}>
      <MainEmojiView />
      <MainHandsUpView />
      {children}
    </Div>
  );
}
const Div = styled.div<{ acceptHands: boolean; height: number }>`
  width: 100%;
  position: relative;
  height: calc(100% - 56px);
  background-color: #000000;
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(20%, auto));
  @media only screen and (max-width: 599px) {
    padding-bottom: ${(props) => (props.acceptHands ? "0px" : "50px")};
    height: ${(props) => `calc(${props.height}px - 202px)`};
  }
`;
