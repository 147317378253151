import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { getMeeting } from "../../../../../actions/meeting";

//types
import { AppState } from "../../../../../store";
import { Application } from "../../../../../types/models/Application";

//component
import TitleTypo from "../../../../../components/Typhography/TitleTypo";
import PxGridContainer from "../../../../../components/Grid/PxGridContainer";
import Body1Typo from "../../../../../components/Typhography/Body1Typo";

interface CounselDetailTopProps {
  meetingId: string;
  applicationId?: string;
}

const CounselDetailTop = ({
  meetingId,
  applicationId,
}: CounselDetailTopProps) => {
  const dispatch = useDispatch();
  const { meeting } = useSelector((state: AppState) => state.meetings);

  const [application, setApplication] = useState<Application>(null!);

  // meeting 정보 redux로 불러옴
  useEffect(() => {
    dispatch(getMeeting(meetingId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // meeting 값과 applicationId 값을 비교하여 신청자이자 수락자인지 구분 후 상대의 application 값을 state로 저장
  useEffect(() => {
    if (meeting?.id !== "") {
      if (applicationId === meeting?.meetingAcceptor?.id) {
        setApplication(meeting?.meetingApplicant!);
      } else {
        setApplication(meeting?.meetingAcceptor!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting]);

  return (
    <>
      {application !== null && (
        <PxGridContainer
          direction="column"
          alignContent="center"
          style={{ textAlign: "center" }}
        >
          <TitleTypo>{application.name}</TitleTypo>
          <Body1Typo txtcolor="secondary" gutterBottom>
            {application.category}
          </Body1Typo>
          <Body1Typo txtcolor="purple" gutterBottom>
            {application.keyword}
          </Body1Typo>
        </PxGridContainer>
      )}
    </>
  );
};

export default CounselDetailTop;
