import Box from "@material-ui/core/Box";
import React, { useState } from "react";
import styled from "styled-components";
import PxButton from "../Buttons/PxButton";
import PxContainer from "../Containers/PxContainer";
import PxGridContainer from "../Grid/PxGridContainer";
import Body1Typo from "../Typhography/Body1Typo";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import i18n, { useTranslation } from "react-i18next";
export default () => {
  const [tipNumber, setTipNumber] = useState<number>(0);
  const [view, setView] = useState(
    JSON.parse(window.localStorage.getItem("subEventFirst") || "false")
  );
  const [t] = useTranslation("lang", { useSuspense: false });

  const onClickNext = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (tipNumber + 1 === tips.length) {
      window.localStorage.setItem("subEventFirst", JSON.stringify(true));
      setView(true);
    } else {
      setTipNumber(tipNumber + 1);
    }
  };

  const tips = [
    {
      path: t("tips.participants_img"),
      title: t("subEventSideBar.participant"),
      content: t("tips.participants"),
    },
    {
      path: t("tips.chat_img"),
      title: t("subEventSideBar.chat"),
      content: t("tips.chat"),
    },
    {
      path: t("tips.manager_img"),
      title: t("subEventSideBar.manager"),
      content: t("tips.manager"),
    },
  ];

  return !view ? (
    <Contianer>
      <PxContainer
        style={{ minHeight: "526px", width: 343, backgroundColor: "white" }}
      >
        <img width="343px" height="266px" src={tips[tipNumber].path} />
        <PxGridContainer
          justify="space-between"
          direction="column"
          style={{ height: 260, padding: "19px 16px 20px" }}
        >
          <Box>
            <Body1Typo fontSize="21px" fontWeight="500">
              {tips[tipNumber].title}
            </Body1Typo>
            <Body1Typo fontSize="16px" txtcolor="black0.6">
              {tips[tipNumber].content}
            </Body1Typo>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <div>
              {tipNumber > 0 && (
                <PxButton
                  backgroundcolor="transparent"
                  boxShadow={false}
                  padding="12px"
                  onClick={() => setTipNumber(tipNumber - 1)}
                >
                  <ArrowBackIcon style={{ fontSize: 16 }} />
                  &nbsp;{t("common.back")}
                </PxButton>
              )}
            </div>
            <PxButton
              backgroundcolor="bluePurple"
              txtColor="white"
              onClick={onClickNext}
            >
              {tipNumber + 1 === tips.length ? (
                <>{t("common.close")}</>
              ) : (
                <>
                  {t("common.next")}&nbsp;
                  <ArrowForwardIcon style={{ fontSize: 16 }} />
                </>
              )}
            </PxButton>
          </Box>
        </PxGridContainer>
      </PxContainer>
    </Contianer>
  ) : null;
};

const Contianer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100%;
  z-index: 1210;
  background-color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.div<{ img: string }>`
  width: 343px;
  height: 266px;
  background-image: ${(props) => props.img};
`;
