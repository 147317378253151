import React, { useCallback, useEffect, useState } from "react";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  convertEtcToGmt,
  parallaxCalculation,
} from "../../../../utils/momentTz";
import { getWebinars } from "../../../../actions/webinar";
import { useParams } from "react-router-dom";
import { Webinar as WebinarType } from "../../../../types/models/Webinar";
import moment from "moment";
import WebinarTimeCard from "./WebinarTimeCard";

const groupBy = require("json-groupby");

const Title = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
  height: 36px;
  font-size: 24px;
  font-weight: 500;
  padding-left: 12px;
  color: rgba(0 0 0, 0.87);
  &::before {
    position: absolute;
    left: 0;
    width: 4px;
    height: 28px;
    transform: translate(0%, 20%);
    content: "";
    border: 2px solid rgb(81, 45, 168);
    border-radius: 2px;
  }
`;

const CountryTime = styled.div`
  width: 100%;
  height: 18px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
`;

const WebinarTimeCardRoot = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const WebinarTimeTableRoot = styled.div`
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
  &::-webkit-scrollbar-y {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  /* -ms-overflow-style: none; */
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(238px, 1fr));
`;

const WebinarDate = styled.div`
  max-width: 240px;
  height: 24px;
  font-size: 15px;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.6);
`;

const useStyles = makeStyles(() => ({
  gridRoot: {
    marginTop: 10,
  },
}));

export interface WebinarDataStateType {
  [date: string]: WebinarType[];
}

const Webinar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation("lang", { useSuspense: false });

  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const webinars = useSelector((state: AppState) => state.webinars.webinars);

  const [webinarData, setWebinarData] = useState<WebinarDataStateType>();

  useEffect(() => {
    dispatch(getWebinars(id));
  }, [dispatch, id]);

  const setNewWebinarData = useCallback(() => {
    if (subEvent?.mainEvent && country && countries) {
      const countryCode = subEvent.mainEvent.countryCode;

      const newWebinarData = webinars.map((w) => {
        // 국가에 맞는 시작시간
        const startTime = parallaxCalculation(
          w.startDate,
          countries[countryCode as string] as any,
          countries[country.countryCode!] as any,
          "YYYY-MM-DD HH:mm"
        );

        // 국가에 맞는 끝시간
        const endTime = parallaxCalculation(
          w.endDate,
          countries[countryCode as string] as any,
          countries[country.countryCode!] as any,
          "YYYY-MM-DD HH:mm"
        );

        return {
          ...w,
          newStartDate: moment(startTime).format("YYYY-MM-DD"),
          startDate: startTime,
          endDate: endTime,
        };
      });

      const groupedWebinarData: WebinarDataStateType = groupBy(newWebinarData, [
        "newStartDate",
      ]);

      setWebinarData(groupedWebinarData);
    }
  }, [countries, country, subEvent, webinars]);

  useEffect(() => {
    if (webinars.length > 0) {
      setNewWebinarData();
    }
  }, [setNewWebinarData, webinars]);

  // 서브 이벤트 기준국가에 따른 시간대 출력
  const exposureTime = useCallback(() => {
    if (countries && country?.countryCode) {
      const thisCountryTime = countries[country.countryCode].timezone;
      return thisCountryTime.indexOf("Etc/GMT") > -1
        ? "GMT" + convertEtcToGmt(thisCountryTime)
        : thisCountryTime;
    }
  }, [countries, country]);

  return (
    <PxGridContainer className={classes.gridRoot}>
      <Title>{t("webinar.webinar")}</Title>
      <CountryTime>
        {t("meetingDetail.connectionCountry")}: {country ? country.country : ""}
        &nbsp;&nbsp; • &nbsp;&nbsp;&nbsp;
        {t("meetingDetail.connectionTime")}: {exposureTime()}
      </CountryTime>
      <WebinarTimeTableRoot>
        {webinarData &&
          Object.entries(webinarData).map(([key, value], index) => (
            <div key={`webinar-timecard-${index}`}>
              <WebinarDate>{key}</WebinarDate>
              <WebinarTimeCardRoot>
                <WebinarTimeCard webinarData={value} />
              </WebinarTimeCardRoot>
            </div>
          ))}
      </WebinarTimeTableRoot>
    </PxGridContainer>
  );
};

export default Webinar;
