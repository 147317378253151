import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import React, { useState } from "react";
import PxButton from "../../../../components/Buttons/PxButton";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import CaptionTypo from "../../../../components/Typhography/CaptionTypo";
import { WebinarQA } from "../../../../types/models/Webinar";
import { useDispatch } from "react-redux";
import { deleteWebinarQA } from "../../../../actions/webinar";
import { useTranslation } from "react-i18next";
import User from "../../../../types/models/User";
import WebainarTextAera from "./WebinarTextAera";

interface Props {
  webinarAnswer: WebinarQA;
  date: string;
  presenter: number;
  isPrivate: "Y" | "N";
  user: User;
  writer: string;
}

const Answer = ({
  webinarAnswer,
  date,
  presenter,
  user,
  writer,
  isPrivate,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("lang", { useSuspense: false });
  const [modifyToggle, setModifyToggle] = useState<boolean>(false);
  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    dispatch(deleteWebinarQA(webinarAnswer.id, webinarAnswer.questionOrAnswer));
  };
  return (
    <>
      <Box
        padding="20px 10px 20px 40px"
        style={{ backgroundColor: "rgba(69, 39, 160,0.05)" }}
      >
        <PxGridContainer alignItems="center" justify="space-between">
          <Box display="flex" alignItems="baseline">
            <Body1Typo fontWeight="blod" fontSize="18px">
              A:&nbsp;
            </Body1Typo>
            <Body1Typo fontWeight="600">
              {webinarAnswer.userName}(
              {presenter === user.id
                ? t("webinar.presenter")
                : t("webinar.host")}
              )
            </Body1Typo>
          </Box>
          <CaptionTypo txtcolor="black0.38">
            {date}&nbsp;
            {webinarAnswer.createdDate === webinarAnswer.modifiedDate
              ? ""
              : `(${t("webinar.edited")})`}
          </CaptionTypo>
        </PxGridContainer>

        <Body1Typo>
          {presenter === user.id ||
          user.id === parseFloat(writer) ||
          webinarAnswer.isPrivate === "N" ? (
            webinarAnswer.content
              .split(/(?:\r\n|\r|\n)/g)
              .map((t) => <div>{t}</div>)
          ) : (
            <div>비밀글 입니다.</div>
          )}
        </Body1Typo>
        {user.id &&
          webinarAnswer.userId &&
          parseFloat(webinarAnswer.userId) === user.id && (
            <PxGridContainer alignItems="center" justify="flex-end">
              {isPrivate === "Y" && (
                <Body1Typo
                  fontSize="12px"
                  fontWeight="700"
                  txtcolor="purple700"
                  style={{ marginTop: 8, marginRight: 10 }}
                >
                  {" "}
                  비밀글
                </Body1Typo>
              )}
              <PxButton
                borderColor="silver"
                boxShadow={false}
                backgroundcolor="grey"
                padding="3px"
                onClick={() => setModifyToggle(!modifyToggle)}
              >
                {t("common.modify")}
              </PxButton>
              <PxButton
                boxShadow={false}
                borderColor="bluePurple"
                backgroundcolor="transparent"
                padding="3px"
                hover={false}
                onClick={handleDelete}
              >
                {t("common.delete")}
              </PxButton>
            </PxGridContainer>
          )}
      </Box>
      {modifyToggle && (
        <Box
          padding="0px 20px 20px 20px"
          style={{ backgroundColor: "rgba(69, 39, 160,0.05)" }}
        >
          <WebainarTextAera
            id={webinarAnswer.id}
            pastText={webinarAnswer.content}
            setModifyToggle={setModifyToggle}
            isPrivate={isPrivate}
            questionId={webinarAnswer.questionId}
          />
        </Box>
      )}
      <Divider />
    </>
  );
};

export default Answer;
