import React, { useEffect, useState } from "react";

// component
import MainParticipantInfo from "./MainParticipantInfo";
import ParticipantTracks from "../Participant/ParticipantTracks";

import useMainSpeaker from "../../hooks/useMainSpeaker";

import styled from "styled-components";
import PxOutlinedFormControl from "../../../components/Forms/PxOutlinedFormControl";
import PxDropDown from "../../../components/Menu/PxDropDown";
import { Chip, makeStyles, MenuItem } from "@material-ui/core";
import Clock from "../../../components/Clock/Clock";
import { choiceCountry } from "../../../actions/country";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import { useTranslation } from "react-i18next";
import HeadlineTypo from "../../../components/Typhography/HeadlineTypo";

export default function MainParticipantForMany() {
  const classes = useStyles();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const { user, acceptHands } = useAppState();
  const mainParticipant = useMainSpeaker();
  const dispatch = useDispatch();
  const [countryName, setCountryName] = useState<string>("");
  const { country, countries } = useSelector(
    (state: AppState) => state.countries!
  );
  const { PRESENTER_LANG } = useAppState();
  // 국가변경
  const changeCountry = async (e: React.MouseEvent) => {
    const key: string = e.currentTarget.getAttribute("value") as string;
    const getCountry = countries![key];
    setCountryName(e.currentTarget.id);
    dispatch(choiceCountry(key, getCountry.koName, getCountry.timezone));
  };
  // 국가 변경 시 날짜변경
  useEffect(() => {    
    setCountryName(i18n.language === "ko" ? countries![country!.countryCode!].koName : countries![country!.countryCode!].enName);
  }, [country]);

  const videoPriority = "high";
  // mainParticipant === selectedParticipant ||
  // mainParticipant === screenShareParticipant
  //   ? "high"
  //   : null;

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <div>
      <Nav>
        {user?.eventType === "presentation" && (
          <Live
            style={{
              backgroundColor: PRESENTER_LANG.some((data) =>
                mainParticipant.identity.includes(data)
              )
                ? "rgb(255, 91, 76)"
                : "#000000",
              color: PRESENTER_LANG.some((data) =>
                mainParticipant.identity.includes(data)
              )
                ? "white"
                : "#c7c8cb",
            }}
          >
            LIVE
          </Live>
        )}
        <PxOutlinedFormControl
          style={{ marginRight: 20, transform: "translateY(3px)" }}
        >
          {/* <InputLabel id="demo-simple-select-outlined-label">
              접속국가
            </InputLabel> */}
          <PxDropDown txtColor="white" label={countryName}>
            {Object.keys(countries!).map((countryMap) => (
              <MenuItem
                value={countryMap}
                id={countries![countryMap].enName}
                onClick={changeCountry}
              >
                {i18n.language === "ko" ? countries![countryMap].koName : countries![countryMap].enName}                
              </MenuItem>
            ))}
          </PxDropDown>
        </PxOutlinedFormControl>
        <Chip className={classes.chip} variant="outlined" label={<Clock />} />
      </Nav>

      {user?.eventType === "presentation" &&
      !PRESENTER_LANG.some((data) =>
        mainParticipant.identity.includes(data)
      ) ? (
        <div className={classes.noPresenterDiv}>
          <HeadlineTypo txtcolor="#b6b6b6">
            {t("video.webinarWaiting")}
          </HeadlineTypo>
        </div>
      ) : (
        <>
          <MainParticipantInfo
            participant={mainParticipant}
            acceptHands={acceptHands !== undefined}
          >
            <ParticipantTracks
              participant={mainParticipant}
              disableAudio
              enableScreenShare
              videoPriority={videoPriority}
            />
            {/* 방송요청 승락했을경우 참가자 화면 송출부분 */}
            {acceptHands !== undefined && (
              // <MainFinishHandsUp presenter={user?.role === "presenter"}>
              <ParticipantTracks
                participant={acceptHands}
                disableAudio
                // enableScreenShare
                // videoPriority={videoPriority}
              />
              // </MainFinishHandsUp>
            )}
          </MainParticipantInfo>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chip: {
    float: "right",
    padding: 7,
    minWidth: 100,
    fontSize: "1rem",
    borderColor: "rgb(75, 76, 78)",
    color: "white",
    fontFamily: "NotoSansCJKkr",
    "& span": {
      padding: 0,
      transform: "translate(0%, 10%)",
    },
  },
  noPresenterDiv: {
    height: `calc(100% - ${theme.mainNavBarHeight}px)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
  },
}));

const Nav = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #000000;
  padding: 16px;
`;
const Live = styled.div`
  border-radius: 4px;
  padding: 1px 8px 3px;
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
`;
