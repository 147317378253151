import React from "react";
import Helmet, { HelmetProps } from "react-helmet";

interface PxHelmetProps extends HelmetProps {
  title: string;
  img: string;
  url?: string;
  description?: string;
}

const PxHelmet = ({ title, img, url, description }: PxHelmetProps) => (
  <Helmet>
    <title>{`${title} - pexpo`}</title>
    <meta property="og:site_name" content="PEXPO" />
    <meta property="og:title" content={title} />
    <meta property="og:image" content={img} />
    <meta property="og:site_name" content="" />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={url || window.location.href}></meta>
    <meta property="og:type" content="website"></meta>
  </Helmet>
);

export default PxHelmet;
