import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import styled from "styled-components";
import {
  MeetingData,
  MeetingTimeInfo,
  SubEventInfo,
} from "../../../../types/models/SubEvent";
import find from "lodash/find";
import ApplicationMeetingCard from "./ApplicationMeetingCard";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { parallaxCalculation } from "../../../../utils/momentTz";
import { CountryApiInfo, CountryInfo } from "../../../../types/models/Country";

const MeetingForm = styled.div`
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar-y {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  /* -ms-overflow-style: none; */
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(14.88rem, 15rem));
`;

const MeetingFormData = styled.div`
  font-size: 0.9375rem;
  letter-spacing: -0.4px;
  max-width: 15rem;
  min-width: 15rem;
  color: rgba(0, 0, 0, 0.6);
`;

const MeetingFormTime = styled.div`
  display: grid;
  grid-gap: 8px;
  width: 100%;
`;

const ApplicationMeetingTable = ({
  subEvent,
  date,
}: {
  subEvent: SubEventInfo;
  date: moment.Moment;
}) => {
  const applicationMeetings = useSelector(
    (state: AppState) => state.applications.applicationMeetings
  );
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  const [meetingTimeData, setMeetingTimeData] = useState<MeetingData[]>([]);
  const [redefinedMeetingTimeData, setRedefinedMeetingTimeData] = useState<
    MeetingData[]
  >([]);

  // 미팅가능 시간 테이블 JSON string -> JSON
  useEffect(() => {
    if (subEvent) {
      const countryCode = subEvent.mainEvent?.countryCode;
      const subEventStartDate = parallaxCalculation(
        `${subEvent.subEventStartDate.split("T")[0]}T${
          subEvent.matchStartTime
        }`,
        countries![countryCode! as string] as any,
        countries![country!.countryCode!] as any
      );

      console.log(`subEventStartDate : `, subEventStartDate);

      //현지시간
      let localStartOfWeek = date
        .clone()
        .startOf("week")
        .isSameOrAfter(subEventStartDate)
        ? date.clone().startOf("week")
        : moment(subEventStartDate); // subEventStartDate(현지날짜 변경되어야함) 보다 전날이면 안됨

      const endOfWeek = date
        .clone()
        .endOf("week")
        .isSameOrBefore(subEvent.subEventEndDate)
        ? date.clone().endOf("week")
        : moment(subEvent.subEventEndDate); // subEventEndDate(현지날짜 변경되엉야함) 보다 이후면 안됨
      let serverTimeStartOfWeek = moment(
        parallaxCalculation(
          localStartOfWeek.format("YYYY-MM-DDTHH:ss"),
          countries![country!.countryCode!] as any,
          ""
        )
      );
      let newTimeMeeting: MeetingData = {};
      let newTimeMeetingArr: MeetingData[] = [];
      for (
        let d =
          endOfWeek.diff(localStartOfWeek, "days") + 1 >= 8
            ? 7
            : endOfWeek.diff(localStartOfWeek, "days") + 1;
        d > 0;
        d--
      ) {
        const newTImes = [
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(-1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            countries!,
            countryCode!,
            country!,
            subEvent.impossibleDateAndTime
          ),
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            countries!,
            countryCode!,
            country!,
            subEvent.impossibleDateAndTime
          ),
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            countries!,
            countryCode!,
            country!,
            subEvent.impossibleDateAndTime
          ),
        ];

        newTimeMeeting = {
          date: serverTimeStartOfWeek.add(-1, "days").format("YYYY-MM-DD"), //한국시간
          showDate: localStartOfWeek.format("YYYY-MM-DD"), // 현지시간
          time: newTImes.filter(
            (d) => d.showDate == localStartOfWeek.format("YYYY-MM-DD")
          ),
        };
        localStartOfWeek.add(1, "days");
        serverTimeStartOfWeek.add(1, "days");
        newTimeMeetingArr.push(newTimeMeeting);
      }
      setMeetingTimeData(newTimeMeetingArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent, date]);

  useEffect(() => {
    if (meetingTimeData.length !== 0) {
      const newMeetingTimeData: MeetingData[] = cloneDeep(meetingTimeData);

      console.log(`meetingTime data : `, meetingTimeData);

      if (
        applicationMeetings &&
        subEventApplication.id &&
        applicationMeetings.length !== 0
      ) {
        applicationMeetings.forEach((meeting) => {
          // 현재 이벤트의 미팅시간 데이터와 상대업체의 미팅 데이터의 미팅날짜로 매칭되는 미팅시간을 찾음
          const matchedMeetingTimeDate: MeetingData | undefined = find(
            newMeetingTimeData,
            {
              date: meeting.date,
            }
          );

          if (matchedMeetingTimeDate) {
            matchedMeetingTimeDate.time?.forEach((time) => {
              if (meeting.startTime === time.startTime) {
                if (meeting.status === "impossible") {
                  // 상대방의 불가시간 및 나의 불가시간 체크
                  time.impossible = "Y";
                } else if (meeting.status === "agree") {
                  // 상대방의 미팅체결 시간 체크
                  if (
                    meeting.meetingAcceptor?.id === subEventApplication.id || // 나와 체결된 미팅
                    meeting.meetingApplicant?.id === subEventApplication.id
                  ) {
                    time.matchedMeeting = meeting;
                  } else {
                    // 내가 아닌 다른 업체와 체결된 미팅
                    time.impossible = "Y";
                  }
                } else if (meeting.status === "waiting") {
                  // 상대방의 미팅대기중인 미팅
                  if (meeting.meetingApplicant?.id === subEventApplication.id) {
                    // 내가 미팅을 신청했을때
                    time.requestedMeeting = meeting;
                  } else if (
                    // 내가 미팅을 수락해야 할때
                    meeting.meetingAcceptor?.id === subEventApplication.id
                  ) {
                    time.receivedMeeting = meeting;
                  }
                }
              }
            });
          }
        });
      }

      setRedefinedMeetingTimeData(newMeetingTimeData);
    }
  }, [applicationMeetings, meetingTimeData, subEventApplication.id]);

  return (
    <>
      <MeetingForm>
        {redefinedMeetingTimeData.map((meetingTimeData) => (
          <MeetingFormData key={meetingTimeData.date}>
            {meetingTimeData.showDate}
            {/* {meetingTimeData.date} (GMT+9) */}
            <MeetingFormTime>
              {meetingTimeData.time?.map((meetingTime, index) => (
                <ApplicationMeetingCard
                  key={index}
                  meetingDate={meetingTimeData.date}
                  meetingTime={meetingTime}
                />
              ))}
            </MeetingFormTime>
          </MeetingFormData>
        ))}
      </MeetingForm>
    </>
  );
};

export default ApplicationMeetingTable;

function timeArrGenerator(
  subEventStartDate: string,
  subEventEndDate: string,
  startDate: string,
  startTime: string,
  reqTime: number,
  breakTime: number,
  numPerDay: number,
  countries: CountryInfo,
  countryCode: string,
  country: CountryApiInfo,
  impossibleDateAndTime: string | undefined
): MeetingTimeInfo[] {
  // timecard 만들어주는 함수 -----
  // 서브이벤트 시작날
  // 서브이벤트 시작시간
  // 서브이벤트 끝나는날
  // 미팅 소요시간
  // 미팅 쉬는시간
  // 미팅 횟수
  // --------------------------
  // subEvent , countrycode 변경될때마다 업데이트

  let meetingTimeList: MeetingTimeInfo[] = []; // 필요데이터 {시작시간, 종료시간, 불가여부}
  // 날짜별 매칭시간 기준 값
  let impossibleStartTime = moment(`${startDate.split("T")[0]} ${startTime}`);

  if (impossibleStartTime.isBefore(subEventStartDate.split("T")[0]))
    return meetingTimeList;
  if (
    impossibleStartTime.isAfter(`${subEventEndDate.split("T")[0]}T${startTime}`)
  )
    return meetingTimeList;
  const disabledDateAndTime = impossibleDateAndTime
    ? JSON.parse(impossibleDateAndTime)
    : {}; //{년 : {월 : [day_time]}}

  let localStartTime = moment(
    parallaxCalculation(
      impossibleStartTime.format("YYYY-MM-DDTHH:mm:ss"),
      countries![countryCode as string] as any,
      countries![country!.countryCode!] as any
    )
  );
  // 날짜별 매칭시간 조회 (시작시간, 종료시간, 불가여부)
  Array.from({ length: numPerDay || 0 }, (x, i) => {
    meetingTimeList.push({
      startTime: impossibleStartTime.format("HH:mm"),
      impossibleAt: checkDisabledTime(impossibleStartTime, disabledDateAndTime), // 주최자 지정 불가시간
      endTime: impossibleStartTime.add(reqTime, "m").format("HH:mm"),
      date: impossibleStartTime.format("YYYY-MM-DD"),
      showDate: localStartTime.format("YYYY-MM-DD"),
      showStartTime: localStartTime.format("HH:mm"),
      showEndTime: localStartTime.add(reqTime, "m").format("HH:mm"),
    });
    localStartTime.add(breakTime, "m");
    impossibleStartTime = impossibleStartTime.add(breakTime, "m");
  });

  return meetingTimeList;
}

function checkDisabledTime(
  serverTime: moment.Moment,
  disabledDateAndTime: any
): "N" | "Y" {
  const [year, month, day] = serverTime.format("YYYY-MM-DD").split("-");
  if (
    disabledDateAndTime.hasOwnProperty(year) &&
    disabledDateAndTime[year].hasOwnProperty(month) &&
    disabledDateAndTime[year][month].length > 0 &&
    disabledDateAndTime[year][month].some(
      (d: string) => d == `${day}_${serverTime.format("HH:mm")}`
    )
  ) {
    return "Y";
  }
  return "N";
}
