import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Grid, { GridProps } from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

interface PxGridContainerProps {
  width: Breakpoint;
  parent?: boolean;
}

type Prop = GridProps & PxGridContainerProps;

const PxGridContainer: React.FC<Prop> = styled((props: Prop) => {
  const { width, parent, ...rest } = props;
  return <Grid container {...rest} />;
})`
  max-width: 1280px;

  ${(props) => {
    if (props.parent === true) {
      if (props.width === "sm") {
        return css`
          padding-left: 24px;
          padding-right: 24px;
        `;
      } else if (props.width === "xs") {
        return css`
          padding-left: 16px;
          padding-right: 16px;
        `;
      } else {
        // 기존 left: 203px, right: 203px
        return css`
          padding-left: 80px;
          padding-right: 80px;
        `;
      }
    } else {
      return css`
        padding: 0;
      `;
    }
  }}
`;

PxGridContainer.defaultProps = {
  parent: false,
};

export default withWidth()(PxGridContainer);
