import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import TitleTypo from "../../../components/Typhography/TitleTypo";
import { makeStyles } from "@material-ui/core/styles";
import SubHeadingTypo from "../../../components/Typhography/SubHeadingTypo";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    paddingLeft: 24,
    maxWidth: 1000,
  },
  titleTypo: {
    marginBottom: 20,
  },
  contentDiv: {
    marginBottom: 10,
  },
}));

const MyInfo = () => {
  const classes = useStyles();
  const { interpreter } = useSelector((state: AppState) => state.interpreters);

  return (
    <PxGridContainer className={classes.root} direction="column">
      <TitleTypo className={classes.titleTypo}>등록정보</TitleTypo>
      <div className={classes.contentDiv}>
        <SubHeadingTypo fontweight="bold">이름</SubHeadingTypo>
        <SubHeadingTypo>{interpreter?.name}</SubHeadingTypo>
      </div>
      <div className={classes.contentDiv}>
        <SubHeadingTypo fontweight="bold">이메일</SubHeadingTypo>
        <SubHeadingTypo>{interpreter?.email}</SubHeadingTypo>
      </div>
      <div className={classes.contentDiv}>
        <SubHeadingTypo fontweight="bold">전화번호</SubHeadingTypo>
        <SubHeadingTypo>{interpreter?.phoneNumber}</SubHeadingTypo>
      </div>
    </PxGridContainer>
  );
};

export default MyInfo;
