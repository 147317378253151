import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import styled from "styled-components";
import {
  CountriesCode,
  countryPhoneNumberCode,
} from "../../../../utils/constants";
import { useMyInfoContext } from "./useMyInfoContext";
import { makeStyles } from "@material-ui/core/styles";

import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import PxOutlinedTxtField from "../../../../components/Inputs/PxOutlinedTxtField";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import MenuItem from "@material-ui/core/MenuItem";
import countries from "../../../../assets/json/contries.json";
import timeZone from "../../../../assets/json/timeZone.json";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "1rem !important",
  },
  countryNumSelectBox: {
    width: "15%",
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    "& div": {
      padding: 12,
    },
  },
  phoneNumTxtField: {
    width: "85%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
}));

const InfoTitleTypo = styled(SubHeadingTypo)`
  font-weight: 500;
  font-size: 15px;
`;

const MyInfoMe = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("lang", { useSuspense: false });
  const { myInfoModify, myInfo, setMyInfo, setCountryAndTime, countryAndTime } =
    useMyInfoContext();

  const myBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );

  useEffect(() => {
    if (myBusinessCard !== undefined) {
      setMyInfo(myBusinessCard);
    }
  }, [myBusinessCard, setMyInfo]);

  const onChangeTextField = useCallback(
    (e: React.ChangeEvent<{ value: unknown; name?: string | undefined }>) => {
      e.preventDefault();
      // e.persist();

      setMyInfo({
        ...myInfo,
        [e.target.name as string]: e.target.value,
      });
    },
    [setMyInfo, myInfo]
  );

  return (
    <PxGridContainer direction="column" className={classes.root} spacing={3}>
      {/* 이름 */}
      <PxGridItem>
        <InfoTitleTypo>{t("myInfo.name")}</InfoTitleTypo>
        <PxOutlinedTxtField
          name="name"
          fullWidth
          inputpadding={12}
          value={myInfo.name}
          disabled={!myInfoModify}
          onChange={onChangeTextField}
        />
      </PxGridItem>

      {/* 회사이름 */}
      <PxGridItem>
        <InfoTitleTypo>{t("myInfo.companyName")}</InfoTitleTypo>
        <PxOutlinedTxtField
          name="company"
          fullWidth
          inputpadding={12}
          value={myInfo.company}
          disabled={!myInfoModify}
          onChange={onChangeTextField}
        />
      </PxGridItem>

      {/* 직책 */}
      <PxGridItem>
        <InfoTitleTypo>{t("myInfo.position")}</InfoTitleTypo>
        <PxOutlinedTxtField
          name="position"
          fullWidth
          inputpadding={12}
          disabled={!myInfoModify}
          value={myInfo.position}
          onChange={onChangeTextField}
        />
      </PxGridItem>

      {/* 이메일 */}
      <PxGridItem>
        <InfoTitleTypo>{t("myInfo.email")}</InfoTitleTypo>
        <PxOutlinedTxtField
          name="email"
          fullWidth
          inputpadding={12}
          disabled={!myInfoModify}
          value={myInfo.email}
          onChange={onChangeTextField}
        />
      </PxGridItem>

      {/* 전화번호 */}
      <PxGridItem>
        <InfoTitleTypo>{t("myInfo.phoneNumber")}</InfoTitleTypo>
        <div>
          <PxSelectBox
            className={classes.countryNumSelectBox}
            variant="outlined"
            displayEmpty
            name="countryNumber"
            disabled={!myInfoModify}
            value={myInfo.countryNumber}
            onChange={onChangeTextField}
          >
            <MenuItem value="" disabled>
              {i18n.language === "ko" ? "국가코드" : "Country Code"}
            </MenuItem>
            {countryPhoneNumberCode.map((countryCode) => (
              <MenuItem value={countryCode.code}>
                {i18n.language === "ko"
                  ? countryCode.country
                  : countryCode.countryEn}
              </MenuItem>
            ))}
          </PxSelectBox>
          <PxOutlinedTxtField
            className={classes.phoneNumTxtField}
            name="phoneNumber"
            inputpadding={12}
            disabled={!myInfoModify}
            value={myInfo.phoneNumber}
            onChange={onChangeTextField}
          />
        </div>
      </PxGridItem>
      {/* 국가코드 */}
      <PxGridItem>
        <InfoTitleTypo>{t("myInfo.nation/region")}</InfoTitleTypo>
        <PxSelectBox
          fullWidth
          variant="outlined"
          displayEmpty
          disabled={!myInfoModify}
          inputProps={{
            // className={classes.countryNumSelectBox}
            name: "countryNumber",
          }}
          value={countryAndTime.countryCode}
          onChange={(e) => {
            setCountryAndTime({
              ...countryAndTime,
              countryCode: e.target.value as CountriesCode["code"],
              timeZone:
                timeZone[e.target.value as CountriesCode["code"]].timeZone[0]
                  .zone,
            });
          }}
        >
          {countries.map((countryCode) => (
            <MenuItem value={countryCode.code}>
              {i18n.language === "ko"
                ? countryCode.ko_name
                : countryCode.en_name}
            </MenuItem>
          ))}
        </PxSelectBox>
      </PxGridItem>
      <PxGridItem>
        <InfoTitleTypo>{t("myInfo.timeZone")}</InfoTitleTypo>
        <PxSelectBox
          fullWidth
          variant="outlined"
          displayEmpty
          disabled={!myInfoModify}
          inputProps={{
            // className={classes.countryNumSelectBox}
            name: "countryNumber",
          }}
          value={countryAndTime.timeZone}
          onChange={(e) =>
            setCountryAndTime({
              ...countryAndTime,
              timeZone: e.target.value as string,
            })
          }
        >
          {timeZone[countryAndTime!.countryCode!].timeZone.map(
            (countryCode) => (
              <MenuItem value={countryCode.zone || countryCode.gmtOffset}>
                {`(${countryCode.gmtOffset}) ${countryCode.zone}`}
              </MenuItem>
            )
          )}
        </PxSelectBox>
      </PxGridItem>
    </PxGridContainer>
  );
};

export default MyInfoMe;
