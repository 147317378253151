import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//material ui icon
import Hourglass from "../../../../assets/icons/hourglass-bottom.svg";
import {
  Block as BlockIcon,
  CheckCircle as CheckCircleIcon,
  PanoramaSharp,
} from "@material-ui/icons/";

import MeetingTargetsDialog from "./MeetingTargetsDialog";
import WaitingAcceptMeetingTargetsDialog from "./WaitingAcceptMeetingTargetsDialog";
import {
  MyMeetingContext,
  MyMeetingCheckListType,
  MeetingApplicantDataInterface,
} from "./MeetingDetail";
import styled from "styled-components";
import {
  applicationMeeting,
  removeUserImpossibleMeeting,
  cancelApplicationMeeting,
  getMyMeetings,
  changeMeetingState,
  // getMeetingVideoAction,
  getMeetingVideo,
} from "../../../../actions/meeting";
import { ChangeMeetingStateInterface } from "../../../../types/models/Meeting";

import { confirmAlert } from "react-confirm-alert";
import { confirmModalOptions } from "../../../../utils/confirmModalOptions";
import { AppState } from "../../../../store";
import { useTranslation } from "react-i18next";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../../actions/loading";
import AcceptCancelMeetingDialog from "./AcceptCancelMeetingDialog";
import { isMobile } from "../../../../utils/utils";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import {
  createGetAttendeeCallback,
  fetchMeeting,
} from "../../../../videoMeeting/utils/api";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { setChatRoomsUnreadMessageCountAction } from "../../../../actions/chat";

const MeetingCardForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 128px;
  border-radius: 4px;
  max-width: 15rem;
  border: solid 1px rgba(233, 234, 237, 1);
`;

const MeetingCardInfo = styled.div`
  position: relative;
  padding: 11px 16px;
  height: 40px;
`;

const MeetingCardInfoName = styled.div`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.84);
  display: inline-block;
  white-space: nowrap;
  max-width: 92%;
  // 스크롤바 숨기기
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none;  /*Firefox */
  /* &::-webkit-scrollbar {
    display: none;
  } */
`;

const MeetingCardInfoTime = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
`;

const MeetingStatusTypo = styled.span`
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
`;
const MeetingCardActionButton = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & svg {
    cursor: pointer;
  }
`;

interface BgColor {
  bgColor: string;
  borderColor?: string;
}

const MeetingStatus = styled.div<BgColor>`
  width: 100%;
  min-height: 40px;
  display: inline-block;
  white-space: nowrap;
  border-left: solid 3px
    ${(props) =>
      props.borderColor !== undefined
        ? props.borderColor
        : "rgb(" + props.bgColor + ")"};
  background-color: rgba(${(props) => props.bgColor}, 0.08);
  padding: 8px 16px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  img {
    vertical-align: bottom;
  }
`;

interface ButtonItemColor {
  iris?: boolean;
}

const ActionButtonItem = styled.button<ButtonItemColor>`
  border: none;
  background-color: transparent;
  color: ${(props) =>
    props.iris ? "rgba(126, 87, 194,1)" : "rgba(0, 0, 0, 0.6)"};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  &:disabled {
    color: grey;
  }
  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }
`;

const StatusIcon = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #fba01c;
  margin-right: 5px;
  margin-bottom: 3px;
  z-index: -1;
  position: relative;
  mask: url(${Hourglass}) no-repeat 50% 50%;
`;

const ApplicationIcon = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #795548;
  margin-right: 5px;
  margin-bottom: 3px;
  z-index: -1;
  position: relative;
  mask: url(${Hourglass}) no-repeat 50% 50%;
`;

interface Props {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
  showDate: string;
  showStartTime: string;
  showEndTime: string;
  impossibleAt: string;
  meetingStatus: string;
  dayChecked: string[];
}

const MeetingTimeCard: React.FC<Props> = (props) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const {
    id,
    date,
    startTime,
    endTime,
    impossibleAt,
    showStartTime,
    showEndTime,
    meetingStatus,
    dayChecked,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  // 팝업 변수
  const [open, setOpen] = React.useState(false);
  // video dialog open dialog open state
  const {
    myMeetingCheckList,
    setVideoDialogOpen,
    secondId,
    disableStatus,
    disableLiftStatus,
    selectedList,
  } = useContext(MyMeetingContext);
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  ); // id: applicationId(기업ID)
  const country = useSelector((state: AppState) => state.countries.country);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { application } = useSelector((state: AppState) => state.applications);
  const [acceptCancelDialogOpen, setAcceptCancelDialogOpen] =
    useState<boolean>(false); // 미팅체결 -> 취소 다이얼로그 open state
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [dayCheck, setDayCheck] = useState<boolean>(false);

  // MeetingDetail 가공할때 같이 안한이유: 몇건 요청이 있는지 파악하기위함
  // 카드 명칭(value: 1.ㅇㅇ에게 미팅 신청중, 2.ㅇ개의 미팅요청이 있습니다)
  const meetingTitle = (list: MyMeetingCheckListType[]) => {
    let returnVal: any = <></>;
    // 본인이 신청했을경우
    if (list[0].applicant === secondId && list[0].status === "waiting") {
      returnVal = (
        <MeetingCardInfoName
          onClick={() =>
            history.push(
              `/subEvent/${param.id}/attendeeList/${list[0].acceptor}`
            )
          }
        >
          {/* {t("meetingDetail.apply")}{" "} */}
          <MeetingStatusTypo>
            {list[0].acceptorName}&nbsp;{t("meetingDetail.applyingForMeeting")}
          </MeetingStatusTypo>
        </MeetingCardInfoName>
      );
    } else if (
      list[0].applicant === secondId &&
      list[0].status === "impossible"
    ) {
      // 불가시간 설정
      returnVal = (
        <MeetingCardInfoName>
          {/* {t("meetingDetail.noMeeting")} */}
        </MeetingCardInfoName>
      );
    } else if (list[0].acceptor === secondId && list[0].status === "waiting") {
      // 요청건
      returnVal = (
        <MeetingCardInfoName>
          <MeetingStatusTypo>
            [&nbsp;{list.length}&nbsp;]
            {/* {t("meetingDetail.meetingRequest")} */}
            &nbsp;
            {t("meetingDetail.pendingAcceptance")}
          </MeetingStatusTypo>
        </MeetingCardInfoName>
      );
    } else if (list[0].status === "agree") {
      // 미팅 성사
      let targetName: string = "";
      let targetId: string = "";
      let targetManagerName: string = "";

      if (list[0].acceptor === secondId) {
        targetName = list[0].applicantName;
        targetId = list[0].applicant;
        targetManagerName = list[0].applicantManagerName;
      } else {
        targetName = list[0].acceptorName;
        targetId = list[0].acceptor;
        targetManagerName = list[0].acceptorManagerName;
      }

      // http://localhost:3000/subEvent/13/application/63
      returnVal = (
        <MeetingCardInfoName
          onClick={() =>
            history.push(`/subEvent/${param.id}/attendeeList/${targetId}`)
          }
        >
          {/* [{t("meetingDetail.conclusion")}]{" "} */}
          <MeetingStatusTypo>
            {targetName + ` (${targetManagerName})`}
          </MeetingStatusTypo>
        </MeetingCardInfoName>
      );
    }
    return returnVal;
  };

  // 미팅 거부 시간 추가
  const addMeetingRejectionTime = async () => {
    dispatch(setLoadingAction());
    const meetingApplicantData: MeetingApplicantDataInterface = {
      subEventId: param.id, // 서브 이벤트
      applicant: secondId, // 신청자(application id)
      acceptor: "", // 수락자(application id)
      meetingId: props.id, // 미팅시간 key;
      status: "impossible", // 상태 [대기: waiting, 동의: agree, 비동의: disagree, 불가설정: impossible]
      date: date,
      startTime: startTime,
      endTime: endTime,
    };

    const result: any = await dispatch(
      applicationMeeting(meetingApplicantData)
    );
    if (result === true) {
      dispatch(getMyMeetings(secondId));
    }
    dispatch(removeLoadingAction());
  };

  // 미팅 거부 시간 제거
  const removeUserImpossibleMeetingTime = async (meetingsId: string) => {
    dispatch(setLoadingAction());
    const result: any = await dispatch(removeUserImpossibleMeeting(meetingsId));
    if (result === true) {
      dispatch(getMyMeetings(secondId));
    }
    dispatch(removeLoadingAction());
  };

  // 미팅 신청 취소
  const cancelApplicationMeetingTime = async (meetingsId: string) => {
    dispatch(setLoadingAction());
    const result: any = await dispatch(cancelApplicationMeeting(meetingsId));
    if (result === true) {
      dispatch(getMyMeetings(secondId));
    }
    dispatch(removeLoadingAction());
  };

  // 미팅카드 출력 여부
  const openYn = () => {
    let openYn: boolean = false;

    if (country === undefined) {
      // 접속 국가 데이터 체크
      openYn = false;
    } else if (meetingStatus === "") {
      // 미팅상태 선택 안함
      openYn = true;
    } else if (myMeetingCheckList[id] !== undefined) {
      const meeting = myMeetingCheckList[id][0];

      if (
        // 미팅 신청중
        meeting.status === "waiting" &&
        meetingStatus === "waitingApplicant" &&
        meeting.applicant === secondId
      ) {
        openYn = true;
      } else if (
        // 미팅 수락 대기중
        meeting.status === "waiting" &&
        meetingStatus === "waitingAcceptor" &&
        meeting.acceptor === secondId
      ) {
        openYn = true;
      } else if (meeting.status! === meetingStatus) {
        // 대기 or 불가시간
        openYn = true;
      }
    }
    return openYn;
  };

  // 미팅 영상통화 시작 버튼
  const handelMeetingVideoOnClick = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      dispatch(setLoadingAction());
      localStorage.removeItem("selfVideoStop");
      localStorage.removeItem("selfAudioStop");
      const meetingInfo: any = await dispatch(
        getMeetingVideo(
          Number(myMeetingCheckList[id][0].id),
          Number(subEventApplication.id)
        )
      );
      dispatch(removeLoadingAction());

      // buyer or seller 중 한명의 application에 zoomLink가 들어있을 경우 줌링크로 연결 아닐경우 pexpo video로 이동
      if (
        meetingInfo &&
        meetingInfo.meetingApplicant &&
        meetingInfo.meetingApplicant.zoomLinkId &&
        meetingInfo.meetingApplicant.zoomLinkId !== null
      ) {
        const zoomLink = window.open(
          meetingInfo.meetingApplicant.zoomLinkId.link,
          isMobile ? "_self" : "_blank"
        );
        if (!zoomLink) {
          alert(t("common.popUpBlocked"));
        }
      } else if (
        meetingInfo &&
        meetingInfo.meetingAcceptor &&
        meetingInfo.meetingAcceptor.zoomLinkId &&
        meetingInfo.meetingAcceptor.zoomLinkId !== null
      ) {
        const zoomLink = window.open(
          meetingInfo.meetingAcceptor.zoomLinkId.link,
          isMobile ? "_self" : "_blank"
        );
        if (!zoomLink) {
          alert(t("common.popUpBlocked"));
        }
      } else {
        // setVideoDialogOpen(true);
        meetingStart();
      }
    },
    [subEventApplication, dispatch, id, myMeetingCheckList, setVideoDialogOpen]
  );

  const meetingStart = async () => {
    history.push("/videoMeeting/device");
  };

  /**
   *    오프라인 미팅 체결 클릭 이벤트
   *
   *    상대 정보 페이지로 이동
   */
  const offlineMeetingClick = async () => {
    const partnerAppId =
      myMeetingCheckList[id][0].acceptor === application.id
        ? myMeetingCheckList[id][0].acceptor
        : myMeetingCheckList[id][0].applicant;
    history.push(`/subEvent/${param.id}/attendeeList/${partnerAppId}`);
  };

  const meetingSelectHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const checkStatus: boolean = event.target.checked;

    if (disableStatus) {
      multiImpossibleCheck(checkStatus);
    } else {
      multiDisableLiftHandler(checkStatus);
    }
  };

  const multiImpossibleCheck = (checkStatus: boolean) => {
    const meetingApplicantData: MeetingApplicantDataInterface = {
      subEventId: param.id, // 서브 이벤트
      applicant: secondId, // 신청자(application id)
      acceptor: "", // 수락자(application id)
      meetingId: props.id, // 미팅시간 key;
      status: "impossible", // 상태 [대기: waiting, 동의: agree, 비동의: disagree, 불가설정: impossible]
      date: date,
      startTime: startTime,
      endTime: endTime,
    };

    if (checkStatus) {
      (selectedList as MeetingApplicantDataInterface[]).push(
        meetingApplicantData
      );
    } else {
      const idx: number = (
        selectedList as MeetingApplicantDataInterface[]
      ).findIndex((item) => item.meetingId == props.id);

      if (idx > -1) {
        selectedList.splice(idx, 1);
      }
    }
    setIsChecked(!isChecked);
  };

  const multiDisableLiftHandler = (checkStatus: boolean) => {
    if (
      myMeetingCheckList[id] &&
      myMeetingCheckList[id][0].status == "impossible"
    ) {
      if (checkStatus) {
        (selectedList as number[]).push(Number(myMeetingCheckList[id][0].id));
      } else {
        const idx: number = (selectedList as number[]).findIndex(
          (item) => item == Number(myMeetingCheckList[id][0].id)
        );
        if (idx > -1) {
          selectedList.splice(idx, 1);
        }
      }
      setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    const isDayIncluded = dayChecked.includes(date);
    const action = disableStatus
      ? multiImpossibleCheck
      : multiDisableLiftHandler;

    if (isDayIncluded) {
      if (!isChecked) {
        const commonParam: boolean = true;
        action(commonParam);
        setDayCheck(commonParam);
        setIsChecked(commonParam);
      } else {
        if (!dayCheck) {
          setDayCheck(true);
        }
      }
    } else {
      if (dayCheck && isChecked) {
        const commonParam: boolean = false;
        action(commonParam);
        setDayCheck(commonParam);
        setIsChecked(commonParam);
      }
    }
  }, [dayChecked]);

  useEffect(() => {
    if (selectedList.length == 0) {
      setIsChecked(false);
      setDayCheck(false);
    }
  }, [selectedList]);

  return openYn() ? (
    <>
      <MeetingCardForm>
        {/* 카드 내용 */}
        <MeetingCardInfo>
          <MeetingCardInfoTime>
            <span>
              {showStartTime} - {showEndTime}
            </span>
            {disableStatus && myMeetingCheckList[id] == null && (
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={meetingSelectHandler}
                />
              </label>
            )}

            {myMeetingCheckList[id] &&
              disableLiftStatus &&
              myMeetingCheckList[id][0].status == "impossible" && (
                <label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={meetingSelectHandler}
                  />
                </label>
              )}

            {/* <Tooltip
              title={`(${country?.country}) ${showHostMeetingTime}`}
              placement="bottom-end"
            >
              <span
                style={{ fontSize: "12px", color: "gray", cursor: "default" }}
              >
                {t(`eventDetail.hostTime`)}
              </span>
            </Tooltip> */}
          </MeetingCardInfoTime>
        </MeetingCardInfo>
        {/* 버튼 */}
        {/* 주최자가 미팅 불가 */}
        {impossibleAt === "Y" ? (
          <>
            <MeetingStatus bgColor="0, 0, 0" borderColor="#c7c8cb">
              <BlockIcon
                style={{
                  color: "rgba(0, 0, 0, 0.38)",
                  verticalAlign: "bottom",
                  fontSize: "1.188rem",
                }}
              />
              {/* 주최자 매칭불가 */}
              &nbsp;&nbsp;{t("meetingDetail.hostBlock")}
            </MeetingStatus>
            <MeetingCardActionButton>
              {/* {t("meetingDetail.noMeeting")} */}
            </MeetingCardActionButton>
          </>
        ) : myMeetingCheckList[id] !== undefined &&
          myMeetingCheckList[id][0].applicant === secondId &&
          myMeetingCheckList[id][0].status === "impossible" ? (
          <>
            <MeetingStatus bgColor="0, 0, 0" borderColor="#c7c8cb">
              <BlockIcon
                style={{
                  color: "rgba(0, 0, 0, 0.38)",
                  verticalAlign: "bottom",
                  fontSize: "1.188rem",
                }}
              />
              {/* 기업명칭 조건: 주최자 불가시간이 아니면서 매칭이 됏을경우 (참가자 매칭불가)*/}
              {impossibleAt === "N" &&
                myMeetingCheckList[id] !== undefined &&
                meetingTitle(myMeetingCheckList[id])}
              &nbsp;&nbsp;{t("meetingDetail.selfBlock")}
            </MeetingStatus>

            <MeetingCardActionButton>
              {/* 미팅 거부 제거*/}
              <ActionButtonItem
                disabled={subEvent?.meetingAvailable === "N" ? true : false}
                onClick={() => {
                  confirmAlert(
                    confirmModalOptions({
                      title: t("meetingDetail.cancelNoMeetingSeeting?"),
                      click: () =>
                        removeUserImpossibleMeetingTime(
                          myMeetingCheckList[id][0].id
                        ),
                    })
                  );
                }}
              >
                {t("meetingDetail.release")}
              </ActionButtonItem>
            </MeetingCardActionButton>
          </>
        ) : myMeetingCheckList[id] !== undefined &&
          myMeetingCheckList[id][0].applicant === secondId &&
          myMeetingCheckList[id][0].status === "waiting" ? (
          <>
            <MeetingStatus bgColor="121, 85, 72">
              <ApplicationIcon />
              {/* 기업명칭 조건: 주최자 불가시간이 아니면서 매칭이 됏을경우 (응답대기중)*/}
              {impossibleAt === "N" &&
                myMeetingCheckList[id] !== undefined &&
                meetingTitle(myMeetingCheckList[id])}
            </MeetingStatus>
            <MeetingCardActionButton>
              {/* 미팅 신청중, 미팅 신청 취소 버튼 */}
              <ActionButtonItem
                disabled={subEvent?.meetingAvailable === "N" ? true : false}
                onClick={() => {
                  confirmAlert(
                    confirmModalOptions({
                      title: t("meetingDetail.cancelRequestingMeeting"),
                      click: () =>
                        cancelApplicationMeetingTime(
                          myMeetingCheckList[id][0].id
                        ),
                    })
                  );
                }}
              >
                {t("meetingDetail.cancel")}
              </ActionButtonItem>
            </MeetingCardActionButton>
          </>
        ) : myMeetingCheckList[id] !== undefined &&
          myMeetingCheckList[id][0].acceptor === secondId &&
          myMeetingCheckList[id][0].status === "waiting" ? (
          <>
            <MeetingStatus bgColor="252, 178, 30">
              <StatusIcon />
              {/* 기업명칭 조건: 주최자 불가시간이 아니면서 매칭이 됏을경우 (수락대기중) */}
              {impossibleAt === "N" &&
                myMeetingCheckList[id] !== undefined &&
                meetingTitle(myMeetingCheckList[id])}
            </MeetingStatus>
            <MeetingCardActionButton>
              {/* 미팅 수락대기중, 미팅 요청자 리스트 open 버튼 */}
              <ActionButtonItem
                disabled={subEvent?.meetingAvailable === "N" ? true : false}
                iris={true}
                onClick={() => setOpen(true)}
              >
                {t("meetingDetail.listView")}
              </ActionButtonItem>
              {open === true && (
                <WaitingAcceptMeetingTargetsDialog
                  open={open}
                  setOpen={setOpen}
                  id={id}
                  date={date}
                  startTime={startTime}
                  endTime={endTime}
                />
              )}
            </MeetingCardActionButton>
          </>
        ) : myMeetingCheckList[id] !== undefined &&
          myMeetingCheckList[id][0].status === "agree" ? (
          <>
            {/* matching */}
            <MeetingStatus bgColor="69, 39, 160">
              {/* 기업명칭 조건: 주최자 불가시간이 아니면서 매칭이 됏을경우 */}
              <CheckCircleIcon
                style={{
                  color: "rgba(69, 39, 160,1)",
                  fontSize: "1.188rem",
                  verticalAlign: "baseline",
                }}
              />
              &nbsp;&nbsp;
              {impossibleAt === "N" &&
                myMeetingCheckList[id] !== undefined &&
                meetingTitle(myMeetingCheckList[id])}
            </MeetingStatus>
            <MeetingCardActionButton>
              {/* online 미팅 체결 버튼 */}
              {/* 영상통화 시작 버튼(meetingId로 룸 만들기)*/}
              {/* offline 미팅 체결 버튼 */}
              {myMeetingCheckList[id][0].meetingType == "online" ? (
                <ActionButtonItem
                  // disabled={date < nowTimeToTimeZone}
                  // iris={date >= nowTimeToTimeZone}
                  iris={true}
                  onClick={handelMeetingVideoOnClick}
                >
                  {t("meetingDetail.videoConnecting")}
                </ActionButtonItem>
              ) : (
                <ActionButtonItem iris={true} onClick={offlineMeetingClick}>
                  {t("meetingDetail.meetingConclusion")}
                </ActionButtonItem>
              )}
              {/* 미팅 성사 -> 취소 */}
              <ActionButtonItem
                disabled={subEvent?.meetingAvailable === "N" ? true : false}
                onClick={() => {
                  setAcceptCancelDialogOpen(true);
                }}
              >
                &nbsp;&nbsp;{t("meetingDetail.cancel")}
              </ActionButtonItem>
            </MeetingCardActionButton>
            <AcceptCancelMeetingDialog
              open={acceptCancelDialogOpen}
              setOpen={setAcceptCancelDialogOpen}
              subEventId={param.id}
              acceptorId={secondId}
              meetingId={props.id}
              meetingsId={myMeetingCheckList[id][0].id}
              applicationId={secondId}
            />
          </>
        ) : (
          <>
            <MeetingStatus bgColor="0, 181, 223">
              {t("meetingDetail.empty")}
            </MeetingStatus>
            <MeetingCardActionButton>
              <ActionButtonItem
                disabled={subEvent?.meetingAvailable === "N" ? true : false}
                iris={true}
                onClick={() => setOpen(true)}
              >
                {t("meetingDetail.apply")}
              </ActionButtonItem>

              {open === true && (
                <MeetingTargetsDialog
                  open={open}
                  setOpen={setOpen}
                  id={id}
                  date={date}
                  startTime={startTime}
                  endTime={endTime}
                />
              )}
              {/* 미팅 거부시간 지정 */}
              {/* <ActionButtonItem
                disabled={subEvent?.meetingAvailable === "N" ? true : false}
                onClick={() => {
                  confirmAlert(
                    confirmModalOptions({
                      title: t("meetingDetail.noMeetingSetting?"),
                      click: addMeetingRejectionTime,
                    })
                  );
                }}
              >
                {t("meetingDetail.block")}
              </ActionButtonItem> */}
            </MeetingCardActionButton>
          </>
        )}
      </MeetingCardForm>
    </>
  ) : null;
};

export default MeetingTimeCard;
