import React, { useState, useEffect, useContext } from "react";
import {
  useParams,
  RouteProps,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { interpreterRoutes } from "../router/router";

// material ui
import Navbar from "../views/interpreterSubEvent/Navbar";
import Sidebar from "../views/interpreterSubEvent/Sidebar";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import CssBaseline from "@material-ui/core/CssBaseline";

// type
import RouteType from "../types/models/Route";

// jss styles
import subEventStyles from "../assets/jss/subEventStyles";
import { AppState } from "../store";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import SubEventProvider from "../components/Providers/SubEventProvider/SubEventProvider";
import {
  getInterpreter,
  removeInterpreterAction,
} from "../actions/interpreter";
import { ChatContext } from "../video/components/ChatProvider/ChatProvider";
import { getSubEventApplication } from "../actions/applications";
import { getSubEvent } from "../actions/subEvents";

interface InterpreterSubEventProps {
  width: Breakpoint;
}

const InterpreterSubEvent: React.FC<InterpreterSubEventProps & RouteProps> = (
  props
) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.users);
  const interpreter = useSelector((state: AppState) => state.interpreters);
  const { width } = props;
  const classes = subEventStyles();
  const { id } = useParams<{ id: string }>(); // id => subEventId
  const [open, setOpen] = useState<boolean>(true);
  const location = useLocation();

  const pathSplit = location.pathname.split("/");

  useEffect(() => {
    if (user.id !== undefined) {
      dispatch(getInterpreter(user.id.toString(), id));
    }
  }, [user.id, id, dispatch]);
  // url을 직접쳐서 서브이벤트 페이지에 접근했을때의 validation
  useEffect(() => {
    if (interpreter === undefined) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interpreter]);

  // layout unmount 시 interpreter state, chat state 초기화
  // useEffect(() => {
  //   return () => {
  //     dispatch(removeInterpreterAction());
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  let idList = [":id", ":secondId"];
  let idNumber = 0;
  // path 설정 ( 모양: /:id/program/:secondId , 규칙: key는 idList에 지정된 순번대로 사용 )
  let pathEquals = "";
  pathSplit.map((path: string, idx: number) => {
    if (idx > 1 && is_number(path) === false) {
      pathEquals += `/${path}`;
    } else if (idx > 1 && is_number(path) === true) {
      pathEquals += `/${idList[idNumber]}`;
      idNumber++;
    }
  });

  // url 키 여부(숫자) 체크
  function is_number(value: string) {
    const reg = /^(\d)+$/;
    return reg.test(value);
  }

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      if (prop.path === pathEquals) {
        return (
          <PrivateRoute
            path={`/interpreter${pathEquals}`}
            component={prop.component}
            key={key}
            isAuthenticated={user.isAuthenticated!}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SubEventProvider>
        <Navbar
          open={open}
          setOpen={setOpen}
          navBarStyle={classes}
          screenWidth={width}
        />
        <Sidebar
          open={open}
          setOpen={setOpen}
          sideBarStyles={classes}
          screenWidth={width}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>{getRoutes(interpreterRoutes)}</Switch>
        </main>
      </SubEventProvider>
    </div>
  );
};

export default withWidth()(InterpreterSubEvent);
