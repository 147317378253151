import React, { ReactNode, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import styled from "styled-components";

// *************************************************************************************************//
// ********** 커스텀 드랍다운 메뉴 사용 시 children 값에 Material MenuItem component 를 넣어주면 됨 ********** //
// *************************************************************************************************//

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const MeettingStatusBtn = styled.button<{
  txtColor?: string;
  anchorEl: null | HTMLElement;
}>`
  height: 36px;
  background-color: ${(props) =>
    props.anchorEl === null ? "transparent" : "rgba(0, 0, 0, 0.08)"};
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  padding-left: 8px;
  padding-right: 20px;
  margin-left: 8px;
  color: ${(props) => props.txtColor ?? "rgba(0,0,0,0.6)"};
  text-align: inherit;
  transform: translate(0%, -10%);
  &:focus {
    outline: none;
  }
  &::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    top: calc(50% - 3px);
    right: 4px;
    border-top: 5px solid
      ${(props) =>
        props.txtColor === "white" ? "#6a6c6e" : "rgba(0, 0, 0, 0.87)"};
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
`;
interface PxMenuProps {
  children: ReactNode;
  label: string | undefined;
  txtColor?: string;
}
const PxDropDown = styled((props: PxMenuProps) => {
  const { label, children, txtColor } = props;

  const handleClose = () => {
    setMeetingAnchorEl(null);
  };

  useEffect(() => {
    handleClose();
  }, [label]);
  const [
    meetingAnchorEl,
    setMeetingAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const handleMeetingClick = (event: React.MouseEvent<HTMLElement>) => {
    setMeetingAnchorEl(event.currentTarget);
  };

  return (
    <>
      <MeettingStatusBtn
        txtColor={txtColor}
        anchorEl={meetingAnchorEl}
        onClick={handleMeetingClick}
      >
        {label}
      </MeettingStatusBtn>
      <StyledMenu
        id="Pexpo-login-Menu"
        anchorEl={meetingAnchorEl}
        open={Boolean(meetingAnchorEl)}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </>
  );
})`
  color: white;
`;

export default PxDropDown;
