import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

//custom ui
import PxTextButton from "../../components/Buttons/PxTextButton";
import PxOutlinedTxtField from "../../components/Inputs/PxOutlinedTxtField";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import TitleTypo from "../../components/Typhography/TitleTypo";
import PxOutlinedFormControl from "../../components/Forms/PxOutlinedFormControl";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxGridItem from "../../components/Grid/PxGridItem";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import PxButton from "../../components/Buttons/PxButton";

//material ui
import { TypographyProps } from "@material-ui/core/Typography";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";

//utils , redux
import { CountriesCode, countryPhoneNumberCode } from "../../utils/constants";
import { removeLoading, setLoading } from "../../actions/loading";
import { updateUserInfo } from "../../actions/users";
import Alert from "../../types/models/Alert";
import { removeAlertAction, setAlertAction } from "../../actions/alerts";
import { AppState } from "../../store";

import countries from "../../assets/json/contries.json";
import timeZone from "../../assets/json/timeZone.json";

import uuid from "uuid";

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  titleTypo: {
    display: "inline-block",
    verticalAlign: "text-top",
  },
  managerAddInfoGridItem: {
    maxWidth: 552,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  countryCodeSelect: {
    color: "rgba(0,0,0,0.6)",
    display: "flex",
  },
  countryCodeInput: {
    color: "rgba(0,0,0,0.6)",
    padding: 10,
  },
  phoneNumberInput: {
    paddingLeft: 4,
  },
  input: {
    color: "rgba(0,0,0,0.6)",
    padding: 10,
  },
}));

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  width: 100%;
  /* height: calc(100vh - 330px); */
  min-height: 635px;
  max-height: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 328px;
  height: 100%;
  flex-direction: column;
`;

const Email = styled.div`
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  background-color: #f6f7fa;
  color: rgba(0, 0, 0, 0.38);
  margin-bottom: 20px;
`;

type TypoProp = TypographyProps;

const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 20px 0 8px 0;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
`;

interface UserType {
  company: string;
  position: string;
  countryNumber: string;
  email: string;
  name: string;
  phoneNumber: string;
  countryCode: CountriesCode["code"];
  timeZone: string;
  id: number | undefined;
}

const MyPage = () => {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.users);
  const cellPhoneRegExp = new RegExp("^\\d+$"); // 핸드폰 번호 입력 정규식 (숫자만 입력가능)

  const [userInfo, setUserInfo] = useState<UserType>({
    company: "",
    email: "",
    countryNumber: "",
    phoneNumber: "",
    position: "",
    name: "",
    countryCode: "KR",
    timeZone: "Asia/Seoul",
    id: 0,
  });

  useEffect(() => {
    if (user !== undefined) {
      setUserInfo({
        company: user.company,
        email: user.email,
        countryNumber: user.countryNumber,
        phoneNumber: user.phoneNumber,
        position: user.position,
        name: user.name,
        countryCode: user.countryCode ?? "KR",
        timeZone: user.timeZone ?? "Asia/Seoul",
        id: user.id,
      });
    }
  }, [user]);

  const alert = (type: "warning" | "success", message: string) => {
    const onAlert: Alert = { alertType: type, id: uuid.v4(), msg: message };
    dispatch(setAlertAction(onAlert));
    setTimeout(() => {
      dispatch(removeAlertAction(onAlert.id));
    });
  };

  const submitBtn = async () => {
    if (userInfo.name === "") {
      alert("warning", t("regularExpression.nameEmpty"));
      return;
    } else if (userInfo.company === "") {
      alert("warning", t("regularExpression.companyEmpty"));
      return;
    } else if (userInfo.company.length > 45) {
      alert("warning", t("regularExpression.company"));
      return;
    } else if (userInfo.phoneNumber === "") {
      alert("warning", t("regularExpression.phoneNumberEmpty"));
      return;
    } else if (userInfo.phoneNumber.length > 20) {
      alert("warning", t("regularExpression.phoneNumber"));
      return;
    } else {
      dispatch(setLoading());
      const result: any = await dispatch(updateUserInfo(userInfo));
      dispatch(removeLoading());
      if (result === true) {
        alert("success", t("changePassword.complete"));
        const language = userInfo.countryNumber == "82" ? "ko" : "en";
        i18n.changeLanguage(language);
      }
    }
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Container>
      <Content>
        <TitleTypo fontSize={24} fontweight="500">
          {t("myPage.myPage")}
        </TitleTypo>

        <SubTitle>{t("myPage.email")}</SubTitle>
        <Email>{userInfo.email}</Email>

        <PxTextButton
          padding="13px 0 15px 0"
          onClick={() => {
            history.push(`/mypage/password`);
          }}
        >
          {t("myPage.changePassword")}
        </PxTextButton>

        {/* 이름 */}
        <MuiThemeProvider theme={theme}>
          <SubTitle>{t("myPage.name")}</SubTitle>
          <PxOutlinedTxtField
            name="name"
            inputProps={{
              className: classes.input,
            }}
            value={userInfo.name}
            onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
            fullWidth
            inputpadding={10}
          />
          {/* 기업명 */}
          <SubTitle>{t("myPage.company")}</SubTitle>
          <PxOutlinedTxtField
            name="company"
            inputProps={{
              className: classes.input,
            }}
            value={userInfo.company}
            onChange={(e) =>
              setUserInfo({ ...userInfo, company: e.target.value })
            }
            fullWidth
            // onChange={handleOnChange}
            inputpadding={10}
            placeholder="소속명을 입력해주세요"
          />
          {/* 직함 */}
          <SubTitle>{t("myPage.position")}</SubTitle>
          <PxOutlinedTxtField
            name="position"
            inputProps={{
              className: classes.input,
            }}
            onChange={(e) =>
              setUserInfo({ ...userInfo, position: e.target.value })
            }
            fullWidth
            value={userInfo.position}
            inputpadding={10}
          />

          {/* 휴대번호 */}
          <SubTitle>{t("myPage.phoneNumber")}</SubTitle>
          <PxOutlinedFormControl fullWidth>
            <PxGridContainer direction="row">
              <PxGridItem xs={4} md={4}>
                <PxSelectBox
                  native
                  className={classes.countryCodeSelect}
                  displayEmpty
                  inputProps={{
                    className: classes.countryCodeInput,
                    name: "countryNumber",
                  }}
                  defaultValue={userInfo.countryNumber}
                  onChange={(e) => {
                    console.log(`country Number : `, e.target.value);
                    setUserInfo({
                      ...userInfo,
                      countryNumber: e.target.value as string,
                    });
                  }}
                >
                  <option value="" disabled>
                    {i18n.language === "ko" ? "국가코드" : "Country Code"}
                  </option>
                  {countryPhoneNumberCode.map((countryCode) => (
                    <option value={countryCode.code}>
                      {i18n.language === "ko"
                        ? countryCode.country
                        : countryCode.countryEn}
                    </option>
                  ))}
                </PxSelectBox>
              </PxGridItem>
              <PxGridItem md={8} xs={8} className={classes.phoneNumberInput}>
                <PxOutlinedTxtField
                  name="phoneNumber"
                  fullWidth
                  inputProps={{
                    className: classes.input,
                  }}
                  inputpadding={10}
                  value={userInfo.phoneNumber}
                  onChange={(e) => {
                    const isValid = cellPhoneRegExp.test(
                      e.target.value as string
                    );
                    if (isValid || (e.target.value as string) === "") {
                      setUserInfo({ ...userInfo, phoneNumber: e.target.value });
                    }
                  }}
                  placeholder={t("mangerAdd.typePhoneNumber")}
                />
              </PxGridItem>
            </PxGridContainer>
          </PxOutlinedFormControl>
          {/* 휴대번호 */}

          {/* 나라 */}
          <SubTitle>{t("myPage.nation/region")}</SubTitle>
          <PxOutlinedFormControl fullWidth>
            <PxGridContainer direction="row">
              <PxGridItem fullwidth>
                <PxSelectBox
                  native
                  className={classes.countryCodeSelect}
                  displayEmpty
                  inputProps={{
                    className: classes.countryCodeInput,
                    name: "countryNumber",
                  }}
                  value={userInfo.countryCode}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      countryCode: e.target.value as CountriesCode["code"],
                      timeZone:
                        timeZone[e.target.value as CountriesCode["code"]]
                          .timeZone[0].zone,
                    })
                  }
                >
                  {countries.map((countryCode) => (
                    <option value={countryCode.code}>
                      {i18n.language === "ko"
                        ? countryCode.ko_name
                        : countryCode.en_name}
                    </option>
                  ))}
                </PxSelectBox>
              </PxGridItem>
            </PxGridContainer>
          </PxOutlinedFormControl>
          {/* 나라 */}

          {/* 타임존 */}
          <SubTitle>{t("myPage.timezone")}</SubTitle>
          <PxOutlinedFormControl fullWidth>
            <PxGridContainer direction="row">
              <PxGridItem fullwidth>
                <PxSelectBox
                  native
                  className={classes.countryCodeSelect}
                  displayEmpty
                  inputProps={{
                    className: classes.countryCodeInput,
                    name: "countryNumber",
                  }}
                  value={userInfo.timeZone}
                  onChange={(e) => {
                    setUserInfo({
                      ...userInfo,
                      timeZone: e.target.value as string,
                    });
                  }}
                >
                  {timeZone[userInfo!.countryCode!].timeZone.map(
                    (countryCode) => (
                      <option value={countryCode.zone || countryCode.gmtOffset}>
                        {`(${countryCode.gmtOffset}) ${countryCode.zone}`}
                      </option>
                    )
                  )}
                </PxSelectBox>
              </PxGridItem>
            </PxGridContainer>
          </PxOutlinedFormControl>
          {/* 타임존 */}
        </MuiThemeProvider>
        <PxButton
          fullWidth
          backgroundcolor="bluePurple"
          txtColor="white"
          marginTop={30}
          onClick={() => submitBtn()}
        >
          {t("myPage.save")}
        </PxButton>
        {/* <Scession>{t("myPage.secession")}</Scession> */}
      </Content>
    </Container>
  );
};

export default MyPage;
