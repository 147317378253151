import axios from "axios";

const setAuthToken = (token?: string, userId?: string) => {
  if (token && userId) {
    axios.defaults.headers.common["Authorization"] = token;
    localStorage.setItem("token", token);
    localStorage.setItem("userId", userId);
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const deleteAuthToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  delete axios.defaults.headers.common["Authorization"];
};

export default setAuthToken;
