import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Emoji } from "../AppStateProvider/AppstateProvider";
import { useAppState } from "../AppStateProvider/hooks/useAppState";

//icons
import ThumsUpIcon from "../../../assets/icons/thumps-up-icon.png";
import ClappingHandsIcon from "../../../assets/icons/clapping-hands-icon.png";
import GrinningFaceIcon from "../../../assets/icons/grinning-face-icon.png";
import PurpleHeartIcon from "../../../assets/icons/purple-heart-icon.png";
import LightBulbIcon from "../../../assets/icons/light-bulb-icon.png";
import FoldedHandsIcon from "../../../assets/icons/folded-hands-icon.png";
import PensiveFaceIcon from "../../../assets/icons/pensive-face-icon.png";
import FaceWithTearOfJoyIcon from "../../../assets/icons/face-with-tears-of-joy-icon.png";
import FaceWithOpenMouthIcon from "../../../assets/icons/face-with-open-mouth-icon.png";

const IconContainer = styled.div`
  position: absolute;
  width: 100px;
  height: 300px;
  bottom: 20px;
  right: 20px;
  z-index: 10;
`;
const emojiIcons = {
  thumbsUp: ThumsUpIcon,
  clappingHands: ClappingHandsIcon,
  grinningFace: GrinningFaceIcon,
  purpleHeart: PurpleHeartIcon,
  lightBulb: LightBulbIcon,
  foldedHands: FoldedHandsIcon,
  pensiveFace: PensiveFaceIcon,
  faceWithTearOfJoy: FaceWithTearOfJoyIcon,
  faceWithOpenMouth: FaceWithOpenMouthIcon,
  "": "",
};

export default () => {
  const iconsRef = useRef<HTMLDivElement>(null);
  const { emojiMessage } = useAppState();

  useEffect(() => {
    function NewObj(emoji: Emoji) {
      const obj = document.createElement("img");
      const id: string = emoji.emoji + emoji.num;
      obj.src = emojiIcons[emoji.emoji];
      obj.alt = emoji.emoji;
      obj.className = "emojiEffect"; //index.css
      obj.setAttribute(
        "style",
        `position:absolute; right:${emoji.num * 100}%;`
      );
      obj.id = id;
      iconsRef.current?.appendChild(obj);
      setTimeout(async () => await removeEmoji(id), 2000);
    }

    function removeEmoji(id: string) {
      iconsRef.current?.removeChild(document.getElementById(id)!);
    }

    NewObj(emojiMessage);
  }, [emojiMessage]);

  return <IconContainer ref={iconsRef} />;
};
