import { ControlBarButton } from "amazon-chime-sdk-component-library-react";
import TranscriptIcon from "@material-ui/icons/Subtitles";
import TranscriptionOffIcon from "@material-ui/icons/SubtitlesOutlined";

import React, { useState } from "react";
import { useAppState } from "../../providers/AppStateProvider";
import { useTranscript } from "../../providers/TranscriptProvider";

const TranscriptionControl: React.FC = () => {
  const [transcription, setTranscription] = useState<boolean>(false);
  const [transcriptLang, setTranscriptLang] = useState<string>("kr");
  const {
    setTranscriptOpen,
    transcriptControl,
    setTranslateLang,
    setTranscriptStatus,
    transcriptOpen,
    translateLang,
    transcriptStatus,
  } = useTranscript();

  const transcriptOnClick = () => {
    if (!transcriptStatus) {
      transcriptControl("ko-KR");
      setTranscriptStatus(true);
    }
    setTranscription(!transcription);
    setTranscriptOpen(!transcription);
  };

  const translateToKorean = () => {
    console.log(`ko onClick`);
    if (!transcriptStatus || (transcriptStatus && transcriptLang == "en")) {
      transcriptControl("ko-KR");
      setTranscriptLang("kr");
      setTranscriptStatus(true);
    }
    // if (translateLang.language != "ko") {
    //   setTranslateLang({ language: "ko" });
    // }
  };

  const translateToEnglish = () => {
    console.log(`translateLang : ${translateLang.language}`);
    if (transcriptLang == "kr") {
      transcriptControl("en-US");
      setTranscriptLang("en");
    }
    // if (translateLang.language != "en") {
    //   setTranslateLang({ language: "en" });
    // }
  };

  return (
    <>
      <ControlBarButton
        icon={transcription ? <TranscriptIcon /> : <TranscriptionOffIcon />}
        label={transcriptOpen ? transcriptLang : "Subtitle"}
        popOver={[
          {
            onClick: () => translateToKorean(),
            children: <span>한국어</span>,
          },
          {
            onClick: () => translateToEnglish(),
            children: <span>영어</span>,
          },
        ]}
        onClick={transcriptOnClick}
      ></ControlBarButton>
    </>
  );
};

export default TranscriptionControl;
