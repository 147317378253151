import Box from "@material-ui/core/Box";
import React, { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
import PxButton from "../../components/Buttons/PxButton";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxGridItem from "../../components/Grid/PxGridItem";
import { FormContext } from "../../components/Providers/FormProvider/FormProvider";
import Body1Typo from "../../components/Typhography/Body1Typo";
import EventBanner from "./EventBanner";

const ItemTitle = ({ children }: { children: ReactNode }) => (
  <Body1Typo
    fontSize="17px"
    fontWeight="500"
    txtcolor="purple"
    marginBottom="4px"
  >
    {children}
  </Body1Typo>
);

const FormResult = () => {
  const { subEvent, resultInfo } = useContext(FormContext);
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  if (!resultInfo.email) history.replace("/");
  return (
    <Container>
      <Body1Typo fontSize="36px" fontWeight="500">
        {t("formResult.title")}
      </Body1Typo>
      <Box height="13px" />
      <Body1Typo fontSize="21px" txtcolor="purple" txtAlign="center">
        {t("formResult.desc")}
      </Body1Typo>
      <Box marginY="49px" width="100%">
        {subEvent && <EventBanner subEvent={subEvent} />}
      </Box>

      <PxGridContainer>
        <PxGridItem>
          <ItemTitle>{t("formResult.loginEmail")}</ItemTitle>
        </PxGridItem>
        <EmailBox>{resultInfo.email}</EmailBox>
      </PxGridContainer>

      {resultInfo.temporaryPW && (
        <>
          {" "}
          <PxGridContainer>
            <PxGridItem>
              <ItemTitle>{t("formResult.temporaryPassword")}</ItemTitle>
            </PxGridItem>
            <TemporaryPassword>
              {resultInfo.temporaryPW.trim()}
            </TemporaryPassword>
          </PxGridContainer>
          <PxButton
            backgroundcolor="purple"
            fullWidth
            boxShadow={false}
            txtColor="white"
            onClick={() =>
              history.replace(`/auth/changePwd/${resultInfo.temporaryCode}`)
            }
          >
            {t("myPage.changePassword")}
          </PxButton>
        </>
      )}
      <PxButton
        backgroundcolor="transparent"
        fullWidth
        hover={false}
        txtColor="purple"
        onClick={() => history.push("/")}
      >
        {t("eventDetail.homePage")}
      </PxButton>
    </Container>
  );
};

const Container = styled.main`
  width: 100%;
  max-width: 640px;
  display: flex;
  margin: 80px auto;

  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmailBox = styled.div`
  width: 100%;
  padding: 19px 0 16px 0;
  margin-bottom: 40px;
  text-align: center;
  border: 1px solid #5f4a8b;
  border-radius: 2px;
  font-size: 17px;
`;

const TemporaryPassword = styled.div`
  width: 100%;
  padding: 18px 0 17px 0;
  text-align: center;
  background-color: rgba(95, 74, 139, 0.05);
  border-radius: 2px;
  margin-bottom: 8px;
  font-size: 17px;
`;

export default FormResult;
