import React, { useContext, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// context
import { SubEventContext } from "../../components/Providers/SubEventProvider/SubEventProvider";

// material ui
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";

// icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PeopleIcon from "@material-ui/icons/People";
import TodayIcon from "@material-ui/icons/Today";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import { Notes as NotesIcon } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import DuoIcon from "@material-ui/icons/Duo";
import NoteIcon from "@material-ui/icons/Note";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import ListAltIcon from "@material-ui/icons/ListAlt";

import { AppState } from "../../store";
import BusinessCard from "../../types/models/BusinessCard";
import { Applicants } from "../../types/models/Application";
import { getMySubEventBusinessCard } from "../../actions/users";

import styled from "styled-components";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import Body1Typo from "../../components/Typhography/Body1Typo";
import Avatar from "@material-ui/core/Avatar";
import { deepPurple } from "@material-ui/core/colors";
import { NotificationContext } from "./NotificationProvider";
import { getSellerPartiForm } from "../../actions/counselForms";
import { FormData } from "../../types/models/Form";

interface SidebarProps {
  screenWidth: "xs" | "sm" | "md" | "lg" | "xl";
  sideBarStyles: Record<"drawer" | "drawerPaper" | "drawerHeader", string>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MenuItemProps {
  name: string;
  menuName: string;
  icon: JSX.Element;
  marginTop?: number;
  onClick?:
    | ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void)
    | undefined;
}

const useStyles = makeStyles((theme) => ({
  notificationAvatar: {
    width: 20,
    height: 20,
    fontSize: "0.30rem",
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiMenuItem: {
      // selected:{
      //   backgroundColor: "rgba(69, 39, 160,0.08)",
      //   borderLeft: "solid 3px rgba(81, 45, 168,1)",
      //   "& .MuiListItemIcon-root , & .MuiListItemText-root": {
      //     color: "rgba(81, 45, 168,1)",
      //   },
      //   color:"rgba(81, 45, 168,1)",
      // },
      root: {
        height: 44,
        fontSize: "0.875em",
        fontWeight: 500,
        color: "rgba(0,0,0,0.6)",
        "& .MuiListItemIcon-root": {
          height: 18,
          width: 18,
        },
        "&$selected": {
          backgroundColor: "rgba(69, 39, 160,0.08)",
          borderLeft: "solid 3px rgba(81, 45, 168,1)",
          "& .MuiListItemIcon-root , & .MuiListItemText-root": {
            color: "rgba(81, 45, 168,1)",
            fontWeight: 600,
          },
          color: "rgba(81, 45, 168,1)",
          fontWeight: 600,
        },
      },
    },
    MuiSvgIcon: {
      root: {
        width: " 1.25rem",
        height: " 1.25rem",
      },
    },
    MuiListItemText: {
      root: {
        "&$selected": {
          color: "rgba(81, 45, 168,1)",
        },
      },
    },
  },
});

const Division = styled.div`
  width: 67px;
  height: 27px;
  padding: 4.5px;
  word-break: nowrap;
  white-space: pre;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: rgb(81, 45, 168);
  border-radius: 4px;
  color: #ffffff;
  font-size: 0.75rem;
`;

const MainSidebar = styled.div`
  width: 240px;
  height: 100vh;
`;

const Background = styled.div<{ open: boolean }>`
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  display: ${(props) => (props.open ? "block" : "none")};
  background-color: #000000;
  z-index: 13;
  position: absolute;
`;

const TitleIcon = styled.span`
  width: 36px;
`;

const CustomMenuItem = ({
  name,
  menuName,
  icon,
  onClick,
  marginTop,
}: MenuItemProps) => {
  const { meetingNoti, unReadChatMessageNoti } =
    useContext(NotificationContext);
  const { selectedUrl } = useContext(SubEventContext);
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <MenuItem
        style={{ marginTop: marginTop }}
        button
        key={name}
        onClick={onClick}
        selected={selectedUrl.includes(name)}
      >
        <TitleIcon>{icon !== undefined ? icon : <InboxIcon />}</TitleIcon>
        <div style={{ flexGrow: 1 }}>{menuName}</div>
        {name === "meeting" &&
          meetingNoti !== 0 &&
          meetingNoti !== undefined && (
            <Avatar className={classes.notificationAvatar}>
              {meetingNoti}
            </Avatar>
          )}
        {name === "chatList" &&
          unReadChatMessageNoti !== 0 &&
          unReadChatMessageNoti !== undefined && (
            <Avatar className={classes.notificationAvatar}>
              {unReadChatMessageNoti}
            </Avatar>
          )}
      </MenuItem>
    </MuiThemeProvider>
  );
};

const Sidebar = ({
  sideBarStyles,
  open,
  setOpen,
  screenWidth,
}: SidebarProps) => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const classes = sideBarStyles;
  const history = useHistory();
  const { subEventId } = useContext(SubEventContext);

  const users = useSelector((state: AppState) => state.users);
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const subEventBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );
  const { sellerCounselForm } = useSelector(
    (state: AppState) => state.counselForms
  );

  const [myBusinessCard, setMyBusinessCard] = useState<BusinessCard>();
  const [backroundOpen, setBackgroundOpen] = useState<boolean>(true);
  const [productListItem, setProductListItem] = useState<boolean>(false);

  useEffect(() => {
    if (sellerCounselForm != null && sellerCounselForm.additional != null) {
      const sellerFormData = JSON.parse(
        sellerCounselForm.additional as string
      ) as FormData;
      sellerFormData.formIds.map((formId) => {
        if (sellerFormData.formData[formId].type === "product") {
          setProductListItem(true);
        }
      });
    }
  }, [sellerCounselForm]);

  useEffect(() => {
    dispatch(getSellerPartiForm(Number(subEventId)));
  }, []);

  useEffect(() => {
    if (screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl") {
      setBackgroundOpen(false);
    }
    if (screenWidth === "sm" || screenWidth === "xs") {
      if (open) {
        setBackgroundOpen(true);
      } else {
        setBackgroundOpen(false);
      }
    }
  }, [open, screenWidth]);

  //모바일 사이드메뉴 다른 페이지 클릭시 사이드메뉴 false
  useEffect(() => {
    if (screenWidth === "xs" || screenWidth === "sm") {
      setOpen(false);
    }
  }, [history.location.pathname, screenWidth, setOpen]);

  // subEventApplication redux state에서 명함리스트를 가져온 후 명함리스트와 로그인한 user의 id값을 비교하여 내 명함정보를 가져옴
  useEffect(() => {
    if (subEventApplication.id !== undefined && subEventApplication.id !== "") {
      const applicants: Applicants[] = subEventApplication.applicants;
      const applicant: Applicants[] = applicants.filter(
        (applicant) => applicant.businessCard.user?.id === users.id
      );

      dispatch(getMySubEventBusinessCard(applicant[0].businessCard));
      setMyBusinessCard(applicant[0].businessCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEventApplication]);

  useEffect(() => {
    if (subEventBusinessCard !== undefined) {
      setMyBusinessCard(subEventBusinessCard);
    }
  }, [subEventBusinessCard]);

  // 상담일지 양식이 등록되어 있지 않을경우, 상담일지 메뉴가 안보이도록 설정 (보임: true, 안보임: false)
  const showCounselMenu = useCallback(() => {
    if (subEventApplication.id && subEvent) {
      if (
        // (
        // subEventApplication.type === "seller" &&
        // subEvent.sellerCounselForm !== 0) ||
        subEventApplication.type === "buyer" &&
        subEvent.sellerCounselForm !== 0
      ) {
        return true;
      }
    }

    return false;
  }, [subEventApplication, subEvent]);

  // 사후설문 양식이 등록외 있지 않을경우, 사후설문 메뉴가 안보이도록 설정 (보임: true, 안보임: false)
  const showSurveyMenu = useCallback(() => {
    if (subEventApplication.id && subEvent) {
      if (
        (subEventApplication.type === "seller" && subEvent.sellerSurveyForm) ||
        (subEventApplication.type === "buyer" && subEvent.sellerSurveyForm)
      ) {
        return true;
      }
    }
    return false;
  }, [subEvent, subEventApplication.id, subEventApplication.type]);

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        // anchor={screenWidth === "sm" || screenWidth=== "xs" ? "right" : "left"}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <MainSidebar>
          <div className={classes.drawerHeader}>
            <PxGridContainer direction="row" alignItems="center">
              {/* <Box
              border={1}
              borderRadius={5}
              borderColor="rgba(95,75,139,1.0)"
              style={{
                backgroundColor: "rgba(95,75,139,1.0)",
                textAlign: "center",
              }}
            >
              <SubHeadingTypo
                className="mr-2"
                style={{ width: "100%", color: "white" }}
              >
                {subEventApplication.type}
              </SubHeadingTypo>
            </Box> */}
              <Division>
                {subEventApplication.type === "seller"
                  ? subEvent?.sgroupName
                  : subEvent?.bgroupName}
              </Division>
              <PxButton
                backgroundcolor="transparent"
                padding="0px 2px 0px 2px"
                endIconMargin="0"
                border={false}
                hover={false}
                onClick={() => {
                  history.push(`/subEvent/${subEventId}/myInfo`);
                }}
              >
                <ButtonTypo txtcolor="bluePurple">
                  {t("subEventSideBar.modify")}
                </ButtonTypo>
              </PxButton>
            </PxGridContainer>
            {screenWidth === "xs" || screenWidth === "sm" ? (
              <IconButton onClick={() => setOpen(false)} style={{ padding: 0 }}>
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              ""
            )}
          </div>
          <PxGridContainer
            direction="column"
            className="mt-3 mb-4 "
            style={{ paddingLeft: 32 }}
          >
            <SubHeadingTypo fontweight="700">
              {" "}
              {myBusinessCard?.name}{" "}
            </SubHeadingTypo>
            <Body1Typo> {myBusinessCard?.company} </Body1Typo>
            <Body1Typo txtcolor="black0.6">
              {" "}
              {myBusinessCard?.position}{" "}
            </Body1Typo>

            {/* <PxGridItem container className="mt-3" justify="center">
            <PxButton backgroundcolor="grey" boxShadow={false} className="mr-2">
              <ButtonTypo>연락처</ButtonTypo>
            </PxButton>
            <PxButton backgroundcolor="grey" boxShadow={false}>
              <ButtonTypo>쪽지함</ButtonTypo>
            </PxButton>
          </PxGridItem> */}
          </PxGridContainer>
          <MenuList>
            {subEventApplication.approval === "approval" && (
              <>
                <CustomMenuItem
                  name="attendeeList"
                  menuName={t("subEventSideBar.participant")}
                  icon={<PeopleIcon />}
                  onClick={() => {
                    history.push(`/subEvent/${subEventId}/attendeeList`);
                  }}
                />
                {productListItem && (
                  <CustomMenuItem
                    name="product"
                    menuName={t("subEventSideBar.productList")}
                    icon={<ListAltIcon />}
                    onClick={() => {
                      history.push(`/subEvent/${subEventId}/product`);
                    }}
                  />
                )}
                <CustomMenuItem
                  name="chatList"
                  menuName={t("subEventSideBar.chat")}
                  icon={<QuestionAnswerIcon />}
                  onClick={() => {
                    history.push(`/subEvent/${subEventId}/chatList`);
                  }}
                />

                {/* 미팅현황 */}
                {subEvent?.matching === "Y" && (
                  <CustomMenuItem
                    name="meeting"
                    menuName={t("subEventSideBar.meetingList")}
                    icon={<TodayIcon />}
                    onClick={() => {
                      history.push(`/subEvent/${subEventId}/meeting`);
                    }}
                  />
                )}

                {subEvent?.category === "presentation" && (
                  <CustomMenuItem
                    name="webinar"
                    menuName={t("subEventSideBar.webinar")}
                    icon={<DuoIcon />}
                    onClick={() => {
                      history.push(`/subEvent/${subEventId}/webinar`);
                    }}
                  />
                )}

                {showCounselMenu() && (
                  <CustomMenuItem
                    name="counselList"
                    menuName={t("subEventSideBar.consultation")}
                    icon={<PlaylistAddCheckIcon />}
                    onClick={() => {
                      history.push(`/subEvent/${subEventId}/counselList`);
                    }}
                  />
                )}

                {showSurveyMenu() && (
                  <CustomMenuItem
                    name="survey"
                    menuName={t("subEventSideBar.survey")}
                    icon={<NoteIcon />}
                    onClick={() => {
                      history.push(`/subEvent/${subEventId}/survey`);
                    }}
                  />
                )}

                {/* <CustomMenuItem
            name="speakerList"
            menuName={t("subEventSideBar.speaker")}
            icon={<RecordVoiceOverIcon />}
            onClick={() => {
              history.push(`/subEvent/${subEventId}/speakerList`);
            }}
          />
          <CustomMenuItem
            name="programList"
            menuName={t("subEventSideBar.program")}
            icon={<ListIcon />}
            onClick={() => {
              history.push(`/subEvent/${subEventId}/programList`);
            }}
          />
          <CustomMenuItem
            name="digitalMapList"
            menuName={t("subEventSideBar.digitalMap")}
            icon={<MapIcon />}
            onClick={() => {
              history.push(`/subEvent/${subEventId}/digitalMapList`);
            }}
          /> */}
                <CustomMenuItem
                  name="managerList"
                  menuName={t("subEventSideBar.manager")}
                  icon={<PersonAddIcon />}
                  onClick={() => {
                    history.push(`/subEvent/${subEventId}/managerList`);
                  }}
                />
                <CustomMenuItem
                  name="noticeList"
                  menuName={t("subEventSideBar.notice")}
                  icon={<NotificationImportantIcon />}
                  onClick={() => {
                    history.push(`/subEvent/${subEventId}/noticeList`);
                  }}
                />
                <CustomMenuItem
                  name="mailList"
                  menuName={t("subEventSideBar.mailing")}
                  icon={<EmailIcon />}
                  onClick={() => {
                    history.push(`/subEvent/${subEventId}/mailList`);
                  }}
                />
                {/* 이벤트 개요 */}
                <CustomMenuItem
                  name="eventInfo"
                  menuName={t("subEventSideBar.eventInfo")}
                  icon={<NotesIcon />}
                  onClick={() => {
                    if (subEvent?.mainEvent) {
                      window.open(
                        `/event/detail/${subEvent.mainEvent.id}`,
                        "_blank"
                      );
                    }
                  }}
                />
                {/* 홈페이지 바로가기 */}
                {subEvent?.mainEvent?.homePage && (
                  <CustomMenuItem
                    name="eventInfo"
                    menuName={t("subEventSideBar.homePage")}
                    icon={<HomeIcon />}
                    onClick={() => {
                      if (subEvent?.mainEvent) {
                        window.open(subEvent.mainEvent.homePage, "_blank");
                      }
                    }}
                  />
                )}
              </>
            )}
            <CustomMenuItem
              marginTop={20}
              name="home"
              menuName={t("subEventSideBar.home")}
              icon={<ExitToAppIcon style={{ color: "rgba(0,0,0,0.38)" }} />}
              onClick={() => {
                if (subEvent?.mainEvent?.id) {
                  history.push(`/event/detail/${subEvent?.mainEvent?.id}`);
                } else {
                  history.push("/");
                }
              }}
            />
          </MenuList>
        </MainSidebar>
      </Drawer>
      <Background
        open={backroundOpen}
        onClick={() => setOpen(false)}
      ></Background>
    </>
  );
};

export default Sidebar;
