import { isMobile } from "../../../utils";
import EventEmitter from "events";
import Video, {
  ConnectOptions,
  LocalTrack,
  Room,
} from "twilio-video";
import { useCallback, useEffect, useRef, useState } from "react";

// types
import { Callback } from "../../../types/types";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import useSubscriptionUpdate from "../../../hooks/useSubscriptionUpdate";
import { StateContextType } from "../../AppStateProvider/AppstateProvider";
import { useAppState } from "../../AppStateProvider/hooks/useAppState";
import useMessageManager from "./useMessageManager";
// @ts-ignore
window.TwilioVideo = Video;



export default function useRoom(
  localTracks: LocalTrack[],
  onError: Callback,
  options?: ConnectOptions
) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(true);
  const localTracksRef = useRef<LocalTrack[]>([]);
  const [
    subscribeUserOnPresenter,
    subscribePresenterOnUserDataTrack,
  ] = useSubscriptionUpdate();
  const {setRoomSId ,setSelfVideoStop,setSelfMute} = useAppState();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [messageManager] = useMessageManager(); 

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  const connect = useCallback(
    (
      token,
      user: StateContextType["user"],
      videoInfo?: { userIdentity: string; roomName: string }
    ) => {
      let newOption = { ...options};
      //  newOption = {...newOption,preferredVideoCodecs: [{codec: "VP8", simulcast: true }]}
   
      // 설명회일 경우 영상통화 다른 사용자 track의 자동 subScription을 끔
      // if (subEvent?.category === "presentation") {
      //   newOption = { ...options, automaticSubscription: false };
      // }

      return Video.connect(token, {
        ...newOption,
        tracks: [],
      }).then(
        (newRoom) => {
          setRoom(newRoom);
          setRoomSId(newRoom.sid);
          // 설명회일때 모든 참석자는 발표자의 track만 subscribe 하도록 하는 api call (room에 참석한 후 동작되어야함)
          // 참석자들만 동작 (참석자가 발표자의 track subscription)
          // subscribeUserOnPresenter(newRoom);
          // 설명회일때 발표자는 user의 dataTrack만 subscribe 하도록 하는 api call (room에 참석한 후 동작되어야함)
          // subscribePresenterOnUserDataTrack(newRoom);

          // room disconnect 시 event setting
          const disconnect = () => newRoom.disconnect();
          
          newRoom.once("disconnected", () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener("beforeunload", disconnect);

            if (isMobile) {
              window.removeEventListener("pagehide", disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;
          localTracksRef.current.forEach((track) => {
            const videoStop = localStorage.getItem("selfVideoStop");
            if( !videoStop || videoStop === "false" ){
              newRoom.localParticipant.publishTrack(track, {
                priority: track.kind === "video" ? "low" : "standard",
              });
            }else{
              setSelfVideoStop(true);
            }
            
            if(track.kind === "audio" && localStorage.getItem("selfAudioStop") === "true"){
              track.enable();
              setSelfMute(true);
            }
            
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            // if (user?.eventType === "presentation") {
            //   if (user.type === "buyer") {
            //     // 발표자 (발표자는 모든 track publish)
            //     newRoom.localParticipant.publishTrack(track, {
            //       priority: track.kind === "video" ? "low" : "standard",
            //     });
            //   } else {
            //     // 참가자 (참가자는 data track만 publish)
            //     if (track.kind === "data") {
            //       newRoom.localParticipant.publishTrack(track);
            //     }
            //     // newRoom.localParticipant.publishTrack(track, {
            //     //   priority: track.kind === "video" ? "low" : "standard",
            //     // });
            //   }
            // } else {newRoom.localParticipant.publishTrack(track, {
            //     priority: track.kind === "video" ? "low" : "standard",
            //   });
            //   newRoom.localParticipant.publishTrack(track, {
            //     priority: track.kind === "video" ? "low" : "standard",
            //   });
            // }
          });
          newRoom.on("trackSubscribed", (track) => {
            track.on("message", async (message: any) => {
              const obj = JSON.parse(message);
              messageManager(obj, localTracksRef.current,newRoom.localParticipant, newRoom.participants);
             
            });
          });

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener("beforeunload", disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener("pagehide", disconnect);
          }
        },
        (error) => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [
      options,
      subEvent,
      subscribeUserOnPresenter,
      subscribePresenterOnUserDataTrack,
      onError,
    ]
  );

  return { room, isConnecting, setIsConnecting, connect };
}