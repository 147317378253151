import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { Form } from "../../../../types/models/Form";
import { useParams } from "react-router-dom";

import styled from "styled-components";
import { TypographyProps } from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import ShowFile from "../../../../components/File/ShowFile";
import TranslatedFormItem from "../../../form/TranslatedFormItem";
import Translate from "../../../../utils/Translate";
import { withStyles } from "@material-ui/core/styles";

const PurpleRadio = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.54)",
    "&$checked": {
      color: "rgba(81, 45, 168,1)",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

type Props = paramProps;

interface paramProps {
  answerProcessing: any;
}
const ApplicationCompanyInfo: React.FC<Props> = (props) => {
  const params = useParams<{ id: string }>(); // subEvent id
  const { answerProcessing } = props;

  const [answerData, setAnswerData] = useState<any>();
  const [questionData, setQuestionData] = useState<Form>();
  // const formAnswers = useSelector(
  //   (state: AppState) => state.formAnswers.formAnswers
  // );

  // 자유양식 질문 (셀러, 바이어 참가신청 양식)
  const forms = useSelector((state: AppState) => state.forms);

  useEffect(() => {
    if (forms.content !== "" && forms.content !== undefined) {
      const data: Form = {
        id: forms.id,
        type: forms.type,
        content: "",
        subEventId: parseInt(params.id),
      };
      setQuestionData({
        ...data,
        formData: JSON.parse(forms.content! as string),
        additionalFormData: JSON.parse(forms.additional!),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  useEffect(() => {
    if (answerProcessing !== null) setAnswerData(answerProcessing);
  }, [answerProcessing]);

  const answerForm = (formId: string, question: any) => {
    let content = <></>;
    if (question.secretCheck === "N") {
      switch (question.type) {
        case "keyword":
          answerFormKeyword(formId);
          break;
        case "category":
          content = answerFormCategory(formId);
          break;
        case "single":
          content = answerFormRadio(formId);
          break;
        case "many":
          content = answerFormCheckBox(formId);
          break;
        case "file":
          content = answerFormFile(formId);
          break;
        default:
          content = answerFormText(formId);
          break;
      }
    }

    return content;
  };

  // url에 http 또는 https가 없을 경우 추가해서 리턴해줌 (임시)
  const urlCheckUpdate = (url: string) => {
    if (!url.includes("http") || !url.includes("https")) {
      return "http://" + url;
    } else {
      return url;
    }
  };

  // 카테고리 폼
  const answerFormCategory = (formId: string) => {
    const categoryValueList = questionData!.formData?.formData[formId]!;
    return (
      <>
        <SubTitle>
          <TranslatedFormItem
            content={categoryValueList.title!}
            type="string"
            link={false}
            onlyEng={false}
          />{" "}
        </SubTitle>
        <div>
          {/* {categoryValueList.explain ? categoryValueList.explain : null} */}
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            // onChange={handleChange}
          >
            {categoryValueList.selectContent?.formIds.map(
              (formValueId: string, idx: number) => {
                const categoryId = `N&&0&&${formId}&&${formValueId}`;
                const categoryData =
                  categoryValueList.selectContent?.formData[formValueId]!;
                const checked =
                  categoryData.explain === answerData[categoryId]?.content;

                return (
                  checked && (
                    <FormControlLabel
                      key={formValueId + idx}
                      checked={checked}
                      value="disabled"
                      disabled={!checked}
                      control={<PurpleRadio />}
                      label={
                        <TranslatedFormItem
                          content={categoryData.explain!}
                          type="string"
                          link={false}
                          onlyEng={false}
                        />
                      }
                    />
                  )
                );
              }
            )}
          </RadioGroup>
        </FormControl>
      </>
    );
  };

  // 키워드 폼
  const answerFormKeyword = (formId: string) => {
    const keywordValueList = questionData!.formData?.formData[formId]!;
    const keywordSplitValue: string[] =
      answerData[`N&&0&&${formId}&&keyword`] !== undefined
        ? answerData[`N&&0&&${formId}&&keyword`].content!.split(",")
        : [];
    return keywordSplitValue.length > 0 ? (
      <>
        <SubTitle>
          <TranslatedFormItem
            content={keywordValueList.title!}
            type="string"
            link={false}
            onlyEng={false}
          />
        </SubTitle>
        {/* <div>{keywordValueList.explain ? keywordValueList.explain : null}</div> */}
        {keywordSplitValue.length > 0 &&
          keywordSplitValue.map((value: string, idx: number) => (
            <Chip
              key={`keyword-${idx}`}
              label={value}
              style={{ margin: "5px" }}
            />
          ))}
      </>
    ) : (
      <></>
    );
  };

  // 단순 문구 폼
  const answerFormText = (formId: string) => {
    const textValueList = questionData!.formData?.formData[formId]!;
    const textId = `N&&0&&${formId}&&text`;
    const textType = answerData[textId]?.type;

    return (
      <>
        {
          <>
            <SubTitle>
              <TranslatedFormItem
                id={formId}
                fontsize={10}
                type="string"
                content={textValueList.title!}
                link={false}
                onlyEng={false}
              />
            </SubTitle>
            <div>
              {/* {textValueList.explain ? (
            <TranslatedFormItem
              id={formId}
              fontSize={10}
              type="string"
              content={textValueList.explain!}
              link={false}
              onlyEng={false}
            />
          ) : null} */}
            </div>
            <OutlinedTxtField
              multiline={textType === "long"}
              value={
                answerData[textId] !== undefined
                  ? answerData[textId]?.content
                  : ""
              }
              inputProps={{
                style: { cursor: textType === "url" ? "pointer" : "default" },
              }}
              onClick={() => {
                textType === "url" &&
                  window.open(urlCheckUpdate(answerData[textId]?.content));
              }}
              style={{ border: "none" }}
              fullWidth
              disabled
            />
          </>
        }
      </>
    );
  };

  // 라디오 폼
  const answerFormRadio = (formId: string) => {
    const radioValueList = questionData!.formData?.formData[formId]!;

    return (
      <>
        <>
          <SubTitle>
            <TranslatedFormItem
              type="string"
              content={radioValueList.title!}
              link={false}
              onlyEng={false}
            />{" "}
          </SubTitle>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              // onChange={handleChange}
            >
              {radioValueList.selectContent?.formIds.map(
                (formValueId: string, idx: number) => {
                  const radioId = `N&&0&&${formId}&&${formValueId}`;
                  const radioData =
                    radioValueList.selectContent?.formData[formValueId]!;
                  const checked =
                    radioData.explain === answerData[radioId]?.content;

                  return (
                    checked && (
                      <FormControlLabel
                        key={formValueId + idx}
                        checked={checked}
                        value="disabled"
                        disabled={!checked}
                        control={<Radio color="default" />}
                        label={
                          <TranslatedFormItem
                            content={radioData.explain!}
                            type="string"
                            link={false}
                            onlyEng={false}
                          />
                        }
                      />
                    )
                  );
                }
              )}
            </RadioGroup>
          </FormControl>
        </>
      </>
    );
  };

  // 체크박스 폼
  const answerFormCheckBox = (formId: string) => {
    const checkboxData = questionData!.formData?.formData[formId];
    const checkboxValueList = checkboxData!.selectContent;

    return (
      <>
        <SubTitle>
          <TranslatedFormItem
            type="string"
            content={questionData!.formData?.formData[formId].title!}
            link={false}
            onlyEng={false}
          />
        </SubTitle>
        {/* <div>{checkboxData!.explain ? checkboxData!.explain : null}</div> */}
        {checkboxValueList?.formIds.map((formValueId: string, idx: number) => {
          const checkboxId = `N&&0&&${formId}&&${formValueId}`;
          const checkboxData = checkboxValueList.formData[formValueId]!;
          const checked =
            answerData[checkboxId]?.content === checkboxData.explain!;

          return (
            checked && (
              <FormControlLabel
                key={formValueId + idx}
                control={<Checkbox />}
                checked={checked}
                disabled={!checked}
                label={
                  <TranslatedFormItem
                    content={checkboxData.explain!}
                    type="string"
                    link={false}
                    onlyEng={false}
                  />
                }
              />
            )
          );
        })}
      </>
    );
  };

  // 파일 폼
  const answerFormFile = (formId: string) => {
    const fileData = questionData!.formData?.formData[formId];
    const fileId = `N&&0&&${formId}&&file`;
    return (
      <>
        <SubTitle>
          <TranslatedFormItem
            type="string"
            content={questionData!.formData?.formData[formId]?.title!}
            link={false}
            onlyEng={false}
          />
        </SubTitle>
        {/* <div>{fileData!.explain ? fileData!.explain : null}</div> */}
        <div>
          <ShowFile fileId={answerData[fileId]?.content} />
        </div>
      </>
    );
  };

  return questionData !== undefined ? (
    <>
      {questionData.formData!.formIds!.map((formId) => (
        <>{answerForm(formId, questionData.formData!.formData[formId]!)}</>
      ))}
    </>
  ) : null;

  // return questionData !== undefined &&
  //   formAnswers !== undefined &&
  //   answerData !== undefined ? (
  //   <>
  //     {questionData.formData!.formIds!.map((formId) => (
  //       <>{answerForm(formId, questionData.formData!.formData[formId]!)}</>
  //     ))}
  //   </>
  // ) : null;
};
export default ApplicationCompanyInfo;
