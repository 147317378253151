import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface CaptionTypoProps {
  txtcolor?: "rgba(0,0,0,0.87)" | "purple" | "white" | "black0.38" | "black0.6";
  margin?: string;
}

type Prop = CaptionTypoProps & TypographyProps;

const CaptionTypo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 0.75rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }
    if (props.txtcolor === "black0.38") {
      return "rgba(0, 0, 0, 0.38)";
    }
    if (props.txtcolor === "black0.6") {
      return "rgba(0, 0, 0, 0.6)";
    }
    return props.txtcolor;
  }};

  ${(props) => {
    if (props.margin !== undefined) {
      return css`
        margin: ${props.margin};
      `;
    }
  }}
`;

CaptionTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default CaptionTypo;
