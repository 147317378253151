import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface Body2TypoProps {
  txtcolor?: "rgba(0,0,0,0.87)" | "purple" | "azure" | "black0.6";
}

type Prop = Body2TypoProps & TypographyProps;

const Body2Typo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.4px;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }
    if (props.txtcolor === "azure") {
      return "#0091ea";
    }
    if (props.txtcolor === "black0.6") {
      return "rgba(0,0,0,0.6)";
    }
    return props.txtcolor;
  }};
`;

Body2Typo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default Body2Typo;
