// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect } from "react";
import {
  ControlBar,
  AudioInputVFControl,
  AudioInputControl,
  ContentShareControl,
  AudioOutputControl,
  ControlBarButton,
  useUserActivityState,
  Dots,
  VideoInputControl,
  useAudioVideo,
  Cog,
} from "amazon-chime-sdk-component-library-react";

import { TranscriptionController } from "amazon-chime-sdk-js";

import EndMeetingControl from "../EndMeetingControl";
import { useNavigation } from "../../providers/NavigationProvider";
import { StyledControls } from "./Styled";
import { useAppState } from "../../providers/AppStateProvider";
import { VideoFiltersCpuUtilization } from "../../types";
import VideoInputTransformControl from "../../components/MeetingControls/VideoInputTransformControl";
import ReorderIcon from "@material-ui/icons/ReorderOutlined";

import styled from "styled-components";
import { TranscriptEvent } from "amazon-chime-sdk-js";
import axios from "axios";
import { startTranscript } from "../../utils/api";
import TranscriptionControl from "../TranscriptionControl/TranscriptionControl";
import TranslateControl from "../TranscriptionControl/TranslateControl";

interface TranscriptionStreamParams {
  LanguageCode:
    | "en-US"
    | "en-GB"
    | "es-US"
    | "fr-CA"
    | "fr-FR"
    | "en-AU"
    | "it-IT"
    | "de-DE"
    | "pt-BR"
    | "ja-JP"
    | "ko-KR"
    | "zh-CN";
  ContentIdentificationType?: "PII" | "PHI";
  ContentRedactionType?: "PII";
  EnablePartialResultsStability?: boolean;
  PartialResultsStability?: string;
  PiiEntityTypes?: string;
  LanguageModelName?: string;
  IdentifyLanguage?: boolean;
  PreferredLanguage?: string;
}

const ControllSelfItem = styled.button`
  margin: 0 0.625rem;
  display: grid;
  grid-template-rows: 1.5rem 1rem;
  justify-items: center;
  align-items: center;
  grid-template-columns: 2.5rem minmax(0.5rem, auto);
`;

const controllStyle = `
button:hover{
  background-color: #5f4b8b;
}
`;

const MeetingControls: React.FC = () => {
  const { toggleNavbar, closeRoster, showRoster } = useNavigation();
  const { isUserActive } = useUserActivityState();
  const { isWebAudioEnabled, videoTransformCpuUtilization, joinInfo } =
    useAppState();
  const audioVideo = useAudioVideo();
  const videoTransformsEnabled =
    videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;

  const handleToggle = (): void => {
    if (showRoster) {
      closeRoster();
    }
    toggleNavbar();
  };

  return (
    <StyledControls className="controls" active={!!isUserActive}>
      <ControlBar
        className="controls-menu"
        layout="undocked-horizontal"
        css={controllStyle}
        showLabels
      >
        <ControlBarButton
          className="mobile-toggle"
          icon={<Dots />}
          onClick={handleToggle}
          label="Menu"
        />
        {isWebAudioEnabled ? (
          <AudioInputVFControl
            voiceFocusOnLabel="Voice Focus Enabled"
            voiceFocusOffLabel="Enable Voice Focus"
          />
        ) : (
          <AudioInputControl />
        )}
        {videoTransformsEnabled ? (
          <VideoInputTransformControl />
        ) : (
          <VideoInputControl />
        )}
        <ContentShareControl label="Share" />
        <AudioOutputControl />
        <TranscriptionControl />
        <TranslateControl />
        <EndMeetingControl />
      </ControlBar>
    </StyledControls>
  );
};

export default MeetingControls;
