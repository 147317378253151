import { Dispatch } from "redux";
import uuid from "uuid";
import {
  removeAlertAction,
  setAlert,
  setAlertAction,
} from "../../actions/alerts";
import Alert from "../../types/models/Alert";

interface AlertProps {
  type: "success" | "error" | "warning" | "info" | "default";
  message: string;
  dispatch: Dispatch;
}

export const alert = ({ type, message, dispatch }: AlertProps) => {
  const onAlert: Alert = { alertType: type, msg: message, id: uuid.v4() };
  dispatch(setAlertAction(onAlert));
  setTimeout(() => {
    dispatch(removeAlertAction(onAlert.id));
  });
};
