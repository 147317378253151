import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../store";
import {
  changeMeetingState,
  getWaitingAcceptMeetingTargets,
  waitingAcceptMeetingTargetsActionStateToInitial,
  getMyMeetings,
} from "../../../../actions/meeting";
import { useParams } from "react-router-dom";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { MyMeetingContext } from "./MeetingDetail";

import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ChangeMeetingStateInterface } from "../../../../types/models/Meeting";
import { confirmAlert } from "react-confirm-alert";
import { confirmModalOptions } from "../../../../utils/confirmModalOptions";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../../actions/loading";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import InputLabel from "@material-ui/core/InputLabel";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";
import PxOutlinedTxtField from "../../../../components/Inputs/PxOutlinedTxtField";
import Box from "@material-ui/core/Box";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";

const ExpansionPanelForm = styled(ExpansionPanel)`
  padding: 0;
  margin: 0;
  &::before {
    border: 0;
    height: 0;
  }
`;

// props interface
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  date: string;
  startTime: string;
  endTime: string;
}

interface ApplicantListType {
  id: string;
  name: string;
  info: ApplicantListInfoType[];
  managerName?: string;
}
interface ApplicantListInfoType {
  title: string;
  content: string;
}

const WaitingAcceptMeetingTarget: React.FC<Props> = (props) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const param: { id: string } = useParams();
  const { open, setOpen } = props;
  const { secondId } = useContext(MyMeetingContext);
  const [applicantList, setApplicantList] = useState<ApplicantListType[]>([]);
  const [meetingsId, setMeetingsId] = React.useState<string>("");
  const [rejectConfirmAlertOpen, setRejectConfirmAlertOpen] =
    useState<boolean>(false);
  const radioGroupRef = useRef<HTMLElement>(null);
  const [emptyListMessage, setEmptyListMessage] = useState<string>();

  const application = useSelector(
    (state: AppState) => state.applications.application
  );
  const waitingAcceptMeetingTargets = useSelector(
    (state: AppState) => state.meetings.waitingAcceptMeetingTargets
  );

  const rejectReasons: { [key: string]: string } = {
    A: t("meetingRejectReason.A"),
    B: t("meetingRejectReason.B"),
    C: t("meetingRejectReason.C"),
    D: t("meetingRejectReason.D"),
  };
  const [selectedReason, setSelectedReason] = useState<string>("A");
  const [inputReason, setInputReason] = useState<string>("");

  // 목록 조회
  useEffect(() => {
    if (
      open === true &&
      application !== undefined &&
      application.id !== undefined
    ) {
      setEmptyListMessage(t("meetingDetail.waitingMessage"));
      dispatch(getWaitingAcceptMeetingTargets(props.id, application.id));
      setTimeout(() => {
        setEmptyListMessage(t("meetingDetail.noMeetingThisTime"));
      }, 1500);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      dispatch(waitingAcceptMeetingTargetsActionStateToInitial());
    };
  }, []);

  // 조회 목록 가공
  useEffect(() => {
    // 신청가능 기업 목록 조회
    if (
      waitingAcceptMeetingTargets !== undefined &&
      waitingAcceptMeetingTargets.length > 0
    ) {
      let waitingAcceptMeetingTargetsSetting: ApplicantListType[] = [];
      waitingAcceptMeetingTargets.forEach((waitingAcceptMeetingTarget) => {
        let info: ApplicantListInfoType[] = [];

        // 질문별 답변 컬럼다름 질문추가하고 답변 변경 할것
        waitingAcceptMeetingTarget.applicationFormAnswers!.forEach(
          (answer: any, index) => {
            if (
              answer!.type! !== null &&
              answer.repeatInfo === "N" &&
              answer.content !== undefined
            ) {
              let infoData: ApplicantListInfoType = {
                title: answer!.title!,
                content: answer!.content!,
              };
              info.push(infoData);
            }
          }
        );

        waitingAcceptMeetingTargetsSetting.push({
          id: waitingAcceptMeetingTarget.id as string,
          name: waitingAcceptMeetingTarget.applicantName as string,
          info: info,
          managerName: waitingAcceptMeetingTarget.applicantManagerName,
        });
      });

      setApplicantList(waitingAcceptMeetingTargetsSetting);
    }
  }, [waitingAcceptMeetingTargets]);

  // 미팅요청자 리스트 라디오박스 선택
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeetingsId((event.target as HTMLInputElement).value);
  };

  const onStateChange = async (status: "agree" | "disagree") => {
    if (meetingsId === "") {
      alert("대상자를 선택해주세요.");
      return;
    }

    setOpen(false);
    dispatch(setLoadingAction());
    // 미팅 신청 데이터
    const changeMeetingStateData: ChangeMeetingStateInterface = {
      subEventId: param.id,
      acceptorId: secondId, // 기업 pk;
      meetingId: props.id, // 미팅시간 key;
      meetingsId: meetingsId, // 미팅 pk;
      status: status, // 상태 [대기: waiting, 동의: agree, 비동의: disagree]
      mailPostToggleAt: "true",
      reason: selectedReason === "D" ? inputReason : selectedReason, // 거절 or 취소 사유
    };

    const result: any = await dispatch(
      changeMeetingState(changeMeetingStateData)
    );
    if (result === true) {
      dispatch(getMyMeetings(secondId));
    }
    dispatch(removeLoadingAction());
  };

  const onCloseRejectConfirmDialog = () => {
    setRejectConfirmAlertOpen(false);
  };

  const onChangeInputReasonText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputReason(e.target.value as string);
  };

  return application !== undefined &&
    waitingAcceptMeetingTargets !== undefined ? (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        // onEntering={() => console.log("handleEntering")}
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">
          {t("meetingDetail.waitingListMeetingAcceptance")}
        </DialogTitle>
        {applicantList.length > 0 ? (
          <DialogContent dividers>
            <RadioGroup
              ref={radioGroupRef}
              aria-label="ringtone"
              value={meetingsId}
              onChange={handleChange}
            >
              {applicantList.map(
                (applicant: ApplicantListType, targetIndex) => (
                  <>
                    <ExpansionPanelForm elevation={0}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                        style={{ padding: 0, margin: "0" }}
                      >
                        <FormControlLabel
                          aria-label="Acknowledge"
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          control={<Radio />}
                          label={applicant.name + `(${applicant.managerName})`}
                          value={applicant.id.toString()}
                          style={{ padding: 0, margin: "0", height: "10px" }}
                        />
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{ padding: 0 }}>
                        <Typography
                          color="textSecondary"
                          style={{ paddingLeft: 40 }}
                        >
                          {applicant!.info!.map(
                            (inData: ApplicantListInfoType) => (
                              <div>
                                {inData.title}: {inData.content}
                              </div>
                            )
                          )}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanelForm>
                  </>
                )
              )}
            </RadioGroup>
          </DialogContent>
        ) : (
          <ListItem>
            <ListItemText primary={emptyListMessage} />
          </ListItem>
        )}

        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            {t("meetingDetail.close")}
          </Button>
          {applicantList.length > 0 && (
            <>
              <Button
                onClick={() => {
                  setRejectConfirmAlertOpen(true);
                  // confirmAlert(
                  //   confirmModalOptions({
                  //     title: t("meetingDetail.reject?"),
                  //     click: () => onStateChange("disagree"),
                  //   })
                  // );
                }}
                color="primary"
              >
                {t("meetingDetail.reject")}
              </Button>
              <Button
                onClick={() => {
                  confirmAlert(
                    confirmModalOptions({
                      title: t("meetingDetail.accept?"),
                      click: () => onStateChange("agree"),
                    })
                  );
                }}
                color="primary"
              >
                {t("meetingDetail.accept")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {/* 거절확인 및 사유선택 다이얼로그 */}
      <Dialog
        open={rejectConfirmAlertOpen}
        maxWidth="xs"
        fullWidth
        onClose={onCloseRejectConfirmDialog}
      >
        <DialogTitle id="confirmation-dialog-title">
          {t("meetingDetail.rejectedReason")}
        </DialogTitle>
        <DialogContent>
          <PxOutlinedFormControl fullWidth>
            <PxSelectBox
              name="rejectReason"
              value={selectedReason}
              onChange={(e: any) => {
                setSelectedReason(e.target.value);
              }}
              displayEmpty
            >
              {Object.entries(rejectReasons).map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
            </PxSelectBox>
          </PxOutlinedFormControl>
          {selectedReason === "D" && (
            <>
              <Box height={10} />
              <PxOutlinedTxtField
                name="reason"
                value={inputReason}
                fullWidth
                placeholder="사유를 입력해주세요"
                inputpadding={15}
                onChange={onChangeInputReasonText}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <PxButton
            backgroundcolor="white87"
            onClick={onCloseRejectConfirmDialog}
          >
            <ButtonTypo>취소</ButtonTypo>
          </PxButton>
          <PxButton
            backgroundcolor="purple"
            onClick={() => {
              onStateChange("disagree");
              setRejectConfirmAlertOpen(false);
            }}
          >
            <ButtonTypo>확인</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default WaitingAcceptMeetingTarget;
