import { createMuiTheme } from "@material-ui/core";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    couselFormWidth: number;
    controlBarHeight: number;
    mainNavBarHeight: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    couselFormWidth?: number;
    sidebarMobileHeight?: number;
    controlBarHeight?: number;
    mainNavBarHeight?: number;
  }
}

export default createMuiTheme({
  sidebarWidth: 260,
  couselFormWidth: 500,
  sidebarMobileHeight: 90,
  controlBarHeight: 70,
  mainNavBarHeight: 56,

});
