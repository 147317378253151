import React, { Fragment } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import PxMenu from "../../../components/Menu/PxMenu";
import PxGridContainer from "../../../components/Grid/PxGridContainer";

import { useAppState } from "../AppStateProvider/hooks/useAppState";
import Body1Typo from "../../../components/Typhography/Body1Typo";
import Fab from "@material-ui/core/Fab";
import BlockIcon from "@material-ui/icons/Block";
import axios from "axios";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import MoodIcon from "@material-ui/icons/Mood";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
    emojiContainer: {
      padding: 10,
      borderRadius: 5,
      backgroundColor: "#171c21",
      borderColor: "black",
    },
  })
);

export default function ToggleEmojiButton(props: {
  disabled?: boolean;
  backgroundColor?: string;
  color?: "black0.6";
}) {
  const classes = useStyles();
  const { t } = useTranslation("lang", { useSuspense: false });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user, blackList, setBlackList } = useAppState();

  const removeBlackList = async (identity: string) => {
    if (
      user?.roomName &&
      user.role === "presenter" &&
      user.eventType === "presentation"
    ) {
      const body = {
        roomId: user.roomName, // roomId
        identity: identity, // blackList identity
      };
      const url =
        process.env.NODE_ENV === "production"
          ? "/video/videoCall/removeBlackList"
          : "http://localhost:3001/video/videoCall/removeBlackList";

      await axios
        .post(url, body)
        .then((result) => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
      setBlackList(blackList.filter((data) => data !== identity));
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Fab
        className={classes.fab}
        disabled={props.disabled}
        style={{
          backgroundColor: props.backgroundColor,
          color: props.color ?? "rgb(169, 170, 173)",
        }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <div>
          {blackList && blackList.length > 0 ? <MoodBadIcon /> : <MoodIcon />}
        </div>
      </Fab>
      <PxMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        anchorVertical="bottom"
        anchorhorizontal="center"
        transformVertical="top"
        transformHorizontal="center"
        marginBottom={-10}
        className={classes.emojiContainer}
      >
        <PxGridContainer direction="column" spacing={1}>
          {blackList && blackList.length > 0 ? (
            blackList.map((data) => (
              <Body1Typo txtcolor="white" style={{ margin: "0px 5px" }}>
                {data}{" "}
                <BlockIcon
                  style={{ cursor: "pointer", marginLeft: 5 }}
                  onClick={() => removeBlackList(data)}
                />
              </Body1Typo>
            ))
          ) : (
            <Body1Typo txtcolor="white">내보낸 참가자가 없습니다.</Body1Typo>
          )}
        </PxGridContainer>
      </PxMenu>
    </Fragment>
  );
}
