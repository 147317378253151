import React, { useEffect } from "react";
import HomeTopView from "./top/HomeTopView";
import HomeBottomView from "./bottom/HomeBottomView";
import { Layout } from "../../components/Layout/Layout";

import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { getAdvertisings } from "../../actions/advertising";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

const Container = styled.div`
  min-width: 360px;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  @media only screen and (min-width: 1280px) {
    width: 100%;
    min-width: 360px;
  }
`;

const Home: React.FC = (props) => {
  return (
    <Container>
      <HomeTopView />
      <Layout>
        <HomeBottomView />
      </Layout>
    </Container>
  );
};

export default Home;
