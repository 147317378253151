import ChatActionTypes from "../types/actions/ChatActions";
import { ChatRoom } from "../types/models/Chat";

interface ChatReduxState {
  chatRooms?: ChatRoom[];
  isLoading: boolean;
}

const chatDefaultState: ChatReduxState = {
  isLoading: true,
};

const chatReducer = (
  state = chatDefaultState,
  action: ChatActionTypes
): ChatReduxState => {
  switch (action.type) {
    case "GET_CHAT_ROOMS":
      return {
        ...chatDefaultState,
        chatRooms: action.chatRooms,
        isLoading: false,
      };
    case "SET_CHAT_ROOMS_UNREAD_MESSAGE_COUNT":
      return {
        ...state,
        chatRooms: action.chatRooms,
      };
    case "RESET_CHAT_ROOMS":
      return { ...chatDefaultState, chatRooms: undefined, isLoading: true };
    default:
      return state;
  }
};

export default chatReducer;
