import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert, getRequest } from "../utils/utils";

// types
import { SubEventInfo } from "../types/models/SubEvent";

export const getSubEventsAction = (subEvents: SubEventInfo[]): AppActions => ({
  type: "GET_SUB_EVENTS",
  subEvents,
});

export const getSubEventAction = (subEvent: SubEventInfo): AppActions => ({
  type: "GET_SUB_EVENT",
  subEvent,
});

export const getSubEvent = (subEventId: string) => {
  return getRequest(null, `/api/subEvents/detail`, getSubEventAction, {
    id: subEventId,
  });
};

export const getSubEvents = <SubEvents>(eventId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/subEvents?eventId=${eventId}`;
  try {
    const res: AxiosResponse = await axios.get(path, config);
    dispatch(getSubEventsAction(res.data));
  } catch (err) {
    if (err.response) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  }
};

export const removeSubEvent = (): AppActions => ({
  type: "REMOVE_SUB_EVENT",
});

export const removeSubEvents = (): AppActions => ({
  type: "REMOVE_SUB_EVENTS",
});
