import WebinarActionTypes from "../types/actions/WebinarAction";
import { Webinar, WebinarQA } from "../types/models/Webinar";

interface WebinarReduxState {
  webinar?: Webinar;
  webinars: Webinar[];
  webinarQA:Pagination;
}

export interface Pagination{
  content:WebinarQA[];
  pageable?: any,
  last?: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean
  },
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

const webinarDefaultState: WebinarReduxState = {
  webinars: [],
  webinarQA:  {
  content:[],
  pageable: {},
  last: false,
  totalElements: 0,
  totalPages: 0,
  size: 1,
  number: 0,
  sort: {
      sorted: false,
      unsorted: true,
      empty: true
  },
  numberOfElements: 1,
  first: true,
  empty: false
  },
};

const webinarReducer = (
  state = webinarDefaultState,
  action: WebinarActionTypes
): WebinarReduxState => {
  switch (action.type) {
    case "GET_WEBINARS":
      return { ...state, webinars: action.webinars };
    case "GET_WEBINAR":
      return { ...state, webinar: action.webinar };
    case "RESET_WEBINAR":
      return { ...state, webinar: undefined };
    case "CREATE_WEBINARQA":
      const createQA = action.webinarQA.questionOrAnswer === "Q" ? {...state.webinarQA ,content: state.webinarQA.number === 0 ? [action.webinarQA,...state.webinarQA.content] : [...state.webinarQA.content]} 
      : {...state.webinarQA, content: state.webinarQA.content.map((w) => w.id === action.webinarQA.questionId ? {...w,answers:[action.webinarQA,...w.answers!]} : w)}
      return {...state, webinarQA:createQA}
    case "GET_WEBINARQA":
      return {...state,webinarQA:action.webinarQA}
    case "MODIFY_WEBINARQA" :
      const webianrQA = action.webinarQA.questionOrAnswer === "Q" ? 
      {...state.webinarQA, content: state.webinarQA.content.map(w => w.id === action.webinarQA.id ? {...w,content:action.webinarQA.content,modifiedDate:action.webinarQA.modifiedDate,isPrivate:action.webinarQA.isPrivate} : w)} 
      :
      {...state.webinarQA, content: state.webinarQA.content.map(w => {return {...w , answers : w.answers?.map(a => a.id === action.webinarQA.id ? {...a,content:action.webinarQA.content,modifiedDate:action.webinarQA.modifiedDate , isPrivate:action.webinarQA.isPrivate} : a)}})}
      ;
      return {...state,webinarQA:webianrQA}
    case "DELETE_WEBINARQA" :
      const DELETE = action.delete.qa === "Q" ? {...state.webinarQA, content:state.webinarQA.content.filter(w => w.id !== action.delete.id)} :  {...state.webinarQA, content:state.webinarQA.content.map(w => {return {...w,answers:w.answers?.filter(a => a.id !== action.delete.id)}})};
      return{...state,webinarQA:DELETE}
    default:
      return state;
  }
};

export default webinarReducer;
