import React, { Fragment, useCallback, useContext } from "react";
import { AppState } from "../../../../store";
import { useSelector } from "react-redux";

import Card from "@material-ui/core/Card";
import { makeStyles, CardContent } from "@material-ui/core";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../components/Grid/PxGridItem";

import CompanyTypo from "../../../../components/Typhography/CompanyTypo";
import { ChatContext } from "../../../../video/components/ChatProvider/ChatProvider";
import Body3Typo from "../../../../components/Typhography/Body3Typo";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import SmsIcon from "@material-ui/icons/Sms";
import { Applicants } from "../../../../types/models/Application";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardRoot: {
    marginTop: 20,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px 40px 0px",
      padding: 5,
    },
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  cardContent: {
    width: "100%",
  },
}));

const ApplicationManagerInfo = () => {
  const classes = useStyles();
  const {
    chatSidebarOpen,
    setOtherUser,
    setUpChatChannel,
    setChatSidebarOpen,
  } = useContext(ChatContext);
  const application = useSelector(
    (state: AppState) => state.applications.application
  );

  // 담당자 이메일주소 아이디 끝 3자리 ***로 replace
  const replaceEmailString = useCallback((email: string) => {
    const splitEmail = email.split("@");
    const id = splitEmail[0].replace(/.{3}$/, "***");
    return id + "@" + splitEmail[1];
  }, []);

  // 담당자 핸드폰번호 끝 4자리 ****로 replace
  const replacePhoneString = useCallback((phoneNumber: string) => {
    const newPhoneNumber = phoneNumber.replace(/.{4}$/, "****");
    return newPhoneNumber;
  }, []);

  const handleCardOnClick = (applicant: Applicants) => {
    // 채팅방 중복 접속 불가
    if (chatSidebarOpen) {
      setChatSidebarOpen(false);
    } else {
      setChatSidebarOpen(true);
    }
    setOtherUser(applicant.businessCard);
    setUpChatChannel(applicant.businessCard);
  };

  return application !== undefined ? (
    <>
      {application!.applicants!.map((applicant, index) => {
        return (
          <Fragment key={index}>
            {applicant.businessCard !== undefined && (
              <>
                <Card
                  className={classes.cardRoot}
                  onClick={() => {
                    handleCardOnClick(applicant);
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <PxGridContainer direction="column">
                      <PxGridItem>
                        <CompanyTypo>
                          {applicant.businessCard.company}
                        </CompanyTypo>
                      </PxGridItem>
                      <PxGridItem>
                        <Body3Typo>{applicant.businessCard.name}</Body3Typo>
                      </PxGridItem>
                      <PxGridItem>
                        <Body1Typo txtcolor="black0.6">{`${replaceEmailString(
                          applicant.businessCard.email
                        )}`}</Body1Typo>
                      </PxGridItem>
                      <PxGridItem>
                        <Body1Typo txtcolor="black0.6">
                          {`(${
                            applicant.businessCard.countryNumber
                          })${replacePhoneString(
                            applicant.businessCard.phoneNumber
                          )}`}
                        </Body1Typo>
                      </PxGridItem>
                    </PxGridContainer>
                  </CardContent>
                  <SmsIcon style={{ color: "#512da8" }} />
                </Card>
              </>
            )}
          </Fragment>
        );
      })}
    </>
  ) : null;
};
export default ApplicationManagerInfo;
