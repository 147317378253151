import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "./alerts";
import Mail, { MailInfo, PaginationMail } from "../types/models/Mail";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { putRequest, setAlert } from "../utils/utils";

export const createMailAction = (mail: MailInfo): AppActions => ({
  type: "CREATE_MAIL",
  mail,
});

export const getMailsAction = (mails: PaginationMail): AppActions => ({
  type: "GET_MAILS",
  mails,
});

export const getMailAction = (mail: MailInfo): AppActions => ({
  type: "GET_MAIL",
  mail,
});

export const createMail = (mail: MailInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };
  const body = JSON.stringify(mail);

  const path = `/api/mails`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};
export const getSubEventMails = <Mail>({subEventId, userId }:{subEventId:string, userId:number},page :number = 0,pageSize=10, searchWord:string = "") => async (
dispatch: Dispatch<AppActions>,

  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/mails/toUserMails?subEventId=${subEventId}&userId=${userId}&page=${page}&size=${pageSize}&searchWord=${searchWord}`;
  try {
    let res: AxiosResponse<PaginationMail> = await axios.get(path, config) ;
    dispatch(getMailsAction(res.data));
    return res.data
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getMail = <Mail>(mailId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/mails/detail?mailId=${mailId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMailAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const updateMailOpened = (mailId : number) => {
  return putRequest([{mailId,status:"Y"}] , `/api/mails/opened`)
}

