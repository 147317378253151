import React, { useEffect, useState } from "react";

//types
import { FormData } from "../../../../../types/models/Form";
import { CounselFormAnswer } from "../../../../../types/models/CounselForm";

// component
import CounselFormItem from "./CounselFormItem";

interface CounselDetailBottomProps {
  meetingSurveys?: CounselFormAnswer[];
  counselForm?: FormData;
  meetingId: string;
  applicationId: string;
  setCounselForm: React.Dispatch<React.SetStateAction<FormData | undefined>>;
}

const CounselDetailBottom = ({
  meetingSurveys,
  counselForm,
  meetingId,
  applicationId,
  setCounselForm,
}: CounselDetailBottomProps) => {
  const [counselFormAnswers, setCounselFormAnswers] = useState<
    CounselFormAnswer[]
  >();

  useEffect(() => {
    if (meetingSurveys !== undefined) {
      setCounselFormAnswers(meetingSurveys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingSurveys]);

  return (
    <>
      {counselForm?.formIds.map((formId, index) => {
        const counselFormAnswerData = counselFormAnswers?.filter(
          (form) => form.uuid === formId
        );
        return (
          <>
            <CounselFormItem
              key={index}
              title={counselForm.formData[formId].title}
              type={counselForm.formData[formId].type}
              id={counselForm.formData[formId].id}
              content={counselForm.formData[formId].content}
              radioContent={counselForm.formData[formId].radioContent}
              requireCheck={counselForm.formData[formId].requireCheck}
              checkBoxContent={counselForm.formData[formId].checkBoxContent}
              fileContent={counselForm.formData[formId].fileContent}
              explainCheck={counselForm.formData[formId].explainCheck}
              explain={counselForm.formData[formId].explain}
              selectContent={counselForm.formData[formId].selectContent}
              keywordContent={counselForm.formData[formId].keywordContent}
              categoryContent={counselForm.formData[formId].categoryContent}
              preview={false}
              whtAdditionalPage={false}
              setFormData={setCounselForm}
              counselFormAnswers={
                counselFormAnswerData?.length === 0
                  ? undefined
                  : counselFormAnswerData
              }
            />
          </>
        );
      })}
    </>
  );
};

export default CounselDetailBottom;
