import React from "react";
import styled from "styled-components";

const PrivacyForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const IFrameBox = styled.iframe`
  width: 100%;
  height: 100vh;
  border: 1px solid #ccc;
  max-width: 595.4pt;
`;

const Privacy = () => {
  return (
    <PrivacyForm>
      <IFrameBox src="https://docs.google.com/document/d/e/2PACX-1vTLqmX8bH8JxAA4UPmi_Oi5pax1AMPLCFa0bKSVqduMy6dW6UyF0_g0SR0fxjBjujVOTBaR_RrP8MJ9/pub?embedded=true"></IFrameBox>
    </PrivacyForm>
  );
};

export default Privacy;
