import Home from "../views/home/Home";
import Route from "../types/models/Route";
import Auth from "../layouts/Auth";
import Event from "../layouts/Event";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import FindUser from "../views/auth/FindUser";
import ChangePwd from "../views/auth/ChangePwd";
import EventDetail from "../components/Event/EventDetail";
import Form from "../layouts/Form";
import TermsLayout from "../layouts/Terms";
import LayoutMyPage from "../layouts/MyPage";

import ChatList from "../views/subEvent/content/ChatList/ChatList";
import CounselListParent from "../views/subEvent/content/CounselList/CounselList";
import SpeakerList from "../views/subEvent/content/SpeakerList";
import DigitalMapList from "../views/subEvent/content/DigitalMapList";
import ManagerList from "../views/subEvent/content/ManagerList/ManagerList";
import NoticeList from "../views/subEvent/content/NoticeList/NoticeList";
import EventInfo from "../views/subEvent/content/EventInfo";
import ProgramList from "../views/subEvent/content/ProgramList";
import MeetingDetail from "../views/subEvent/content/Meeting/MeetingDetail";
import CounselDetail from "../views/subEvent/content/CounselList/detail/CounselDetail";
import InterpreterCounselDetail from "../views/interpreterSubEvent/content/CounselList/CounselDetail";
import ApplicationList from "../views/subEvent/content/CompanyList/ApplicationList";
import ApplicationDetail from "../views/subEvent/content/CompanyList/ApplicationDetail";
import NoticeDetail from "../views/subEvent/content/NoticeList/NoticeDetail";
import InterpreterMeetingDetail from "../views/interpreterSubEvent/content/MeetingDetail";
import InterpreterEventInfo from "../views/interpreterSubEvent/content/EventInfo";
import MyInfo from "../views/interpreterSubEvent/content/myInfo";
import ManagerAdd from "../views/subEvent/content/ManagerList/ManagerAdd";
import ManagerAddedList from "../views/subEvent/content/ManagerList/ManagerAddedList";
import SubEventMyInfo from "../views/subEvent/content/myInfo/MyInfo";
import Terms from "../components/Terms/Terms";
import Privacy from "../components/Terms/Privacy";
import EventList from "../components/Event/EventList";

import Search from "../views/search/Search";
import blank from "../layouts/EventList";
import Likes from "../views/myPage/Likes";
import MyPage from "../views/myPage/MyPage";
import Password from "../views/myPage/Password";
import ApprovedEvents from "../views/myPage/ApprovedEvents";
import Webinar from "../views/subEvent/content/Webinar/Webinar";
import WebinarDetail from "../views/subEvent/content/Webinar/WebinarDetail";
import Manual from "../components/Manual/Manual";
import CounselList from "../views/interpreterSubEvent/content/CounselList/CounselList";
import Survey from "../views/subEvent/content/Survey/Survey";
import MailList from "../views/subEvent/content/mailList/MailList";
import MailDetail from "../views/subEvent/content/mailList/MailDetail";
import ManagerDetail from "../views/subEvent/content/ManagerList/ManagerDetail";
import ApplicationForm from "../views/form/ApplicatonForm";
import FormResult from "../views/form/FormResult";
import ParticipantInfo from "../views/interpreterSubEvent/content/ParticipantCompany/ParticipantInfo";
import ProductList from "../views/subEvent/content/ProductList/ProductList";
import EventHompage from "../views/EventHompage";

export const mainRoutes: Route[] = [
  { path: "", name: "HomePage", component: Home, layout: "/" },
  { path: "", name: "AuthPage", component: Auth, layout: "/auth" },
  { path: "", name: "EventPage", component: Event, layout: "/event" },
  { path: "", name: "FormPage", component: Form, layout: "/form" },
  { path: "", name: "TermsPage", component: TermsLayout, layout: "/terms" },
  { path: "", name: "mypage", component: LayoutMyPage, layout: "/mypage" },
  { path: "", name: "List", component: EventList, layout: "/list" },
  {
    path: "",
    name: "Search",
    component: Search,
    layout: "/search_result/:word",
  },
  { path: "", name: "blankPage", component: blank, layout: "" },
];

export const authRoutes: Route[] = [
  {
    path: "/login",
    name: "LoginPage",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/findUser",
    name: "FindUserPage",
    component: FindUser,
    layout: "/auth",
  },
  {
    path: "/changePwd/:id",
    name: "ChangePwdPage",
    component: ChangePwd,
    layout: "/auth",
  },
];

export const eventRoutes: Route[] = [
  {
    path: "/detail",
    name: "EventDetailPage",
    component: EventDetail,
    layout: "/event",
  },
];

export const subEventRoutes: Route[] = [
  {
    path: "/:id/attendeeList",
    name: "AttendeeListPage",
    component: ApplicationList,
    layout: "/subEvent",
  },
  {
    path: "/:id/myInfo",
    name: "MyInfoPage",
    component: SubEventMyInfo,
    layout: "/subEvent",
  },
  {
    path: "/:id/attendeeList/:secondId",
    name: "ApplicationDetailPage",
    component: ApplicationDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/counselList/:secondId",
    name: "CounseLDetailPage",
    component: CounselDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/chatList",
    name: "ChatListPage",
    component: ChatList,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar",
    name: "WebinarPage",
    component: Webinar,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar/:secondId",
    name: "WebinarDetailPage",
    component: WebinarDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/meeting",
    name: "MeetingDetailPage",
    component: MeetingDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/counselList",
    name: "CounselListPage",
    component: CounselListParent,
    layout: "/subEvent",
  },
  {
    path: "/:id/survey",
    name: "SurveyPage",
    component: Survey,
    layout: "/subEvent",
  },
  {
    path: "/:id/speakerList",
    name: "SpeakerListPage",
    component: SpeakerList,
    layout: "/subEvent",
  },
  {
    path: "/:id/programList",
    name: "ProgramListPage",
    component: ProgramList,
    layout: "/subEvent",
  },
  {
    path: "/:id/digitalMapList",
    name: "DigitalMapListPage",
    component: DigitalMapList,
    layout: "/subEvent",
  },
  {
    path: "/:id/managerList",
    name: "ManagerList",
    component: ManagerList,
    layout: "/subEvent",
  },
  {
    path: "/:id/managerList/add",
    name: "ManagerAdd",
    component: ManagerAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/managerList/:secondId",
    name: "ManagerDetail",
    component: ManagerDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/managerList/addedList",
    name: "ManagerAddedList",
    component: ManagerAddedList,
    layout: "/subEvent",
  },
  {
    path: "/:id/noticeList",
    name: "NoticeList",
    component: NoticeList,
    layout: "/subEvent",
  },
  {
    path: "/:id/noticeList/:secondId",
    name: "NoticeDetailPage",
    component: NoticeDetail,
    layout: "subEvent",
  },
  {
    path: "/:id/eventInfo",
    name: "EventInfo",
    component: EventInfo,
    layout: "/subEvent",
  },
  {
    path: "/:id/mailList",
    name: "MailListPage",
    component: MailList,
    layout: "/subEvent",
  },
  {
    path: "/:id/mailList/:secondId",
    name: "MailDetailPage",
    component: MailDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/product",
    name: "ProductList",
    component: ProductList,
    layout: "/subEvent"
  }
];

export const interpreterRoutes: Route[] = [
  {
    path: "/:id/myInfo",
    name: "MyInfoPage",
    component: MyInfo,
    layout: "/interpreter",
  },
  {
    path: "/:id/meeting",
    name: "MeetingDetailPage",
    component: InterpreterMeetingDetail,
    layout: "/interpreter",
  },
  {
    path: "/:id/noticeList",
    name: "NoticeList",
    component: NoticeList,
    layout: "/interpreter",
  },
  {
    path: "/:id/noticeList/:secondId",
    name: "NoticeDetailPage",
    component: NoticeDetail,
    layout: "interpreter",
  },
  {
    path: "/:id/eventInfo",
    name: "EventInfo",
    component: InterpreterEventInfo,
    layout: "/interpreter",
  },
  {
    path: "/:id/counselList",
    name: "CounseList",
    component: CounselList,
    layout: "/interpreter",
  },
  {
    path: "/:id/counselList/:secondId",
    name: "CounseLDetailPage",
    component: InterpreterCounselDetail,
    layout: "/interpreter",
  },
  {
    path: "/:id/participantsList/:secondId",
    name: "ParticipantsInfoDetailPage",
    component: ApplicationDetail,
    // component: ParticipantInfo,
    layout: "/interpreter"
  }
  ,
  {
    path: "/:id/participantsList",
    name: "ParticipantsInfoList",
    component: ParticipantInfo,
    layout: "/interpreter"
  },

];

export const HomepageRoutes: Route[] = [
  {
    path: "/:eventId",
    name: "EventHomePage",
    component: EventHompage,
    layout: "/homepage"
  }
]

export const MyPageRoutes: Route[] = [
  {
    path: "/info",
    name: "mypage",
    component: MyPage,
    layout: "/mypage",
  },
  {
    path: "/password",
    name: "password",
    component: Password,
    layout: "/mypage",
  },
  {
    path: "/likes",
    name: "likes",
    component: Likes,
    layout: "/mypage",
  },
  {
    path: "/approvedEvents",
    name: "likes",
    component: ApprovedEvents,
    layout: "/mypage",
  },
];

export const termsRoutes: Route[] = [
  {
    path: "/terms",
    name: "TermsPage",
    component: Terms,
    layout: "/terms",
  },
  {
    path: "/privacy",
    name: "PrivacyPage",
    component: Privacy,
    layout: "/terms",
  },
  {
    path: "/manual/:page",
    name: "Manual",
    component: Manual,
    layout: "/terms",
  },
];

export const EventListRoutes: Route[] = [];

export const formRoutes: Route[] = [
  {
    path: "/subEvent/:subEventId/result",
    name: "Result",
    component: FormResult,
    layout: "/form",
  },
  {
    path: "/subEvent/:subEventId/buyerPartiForm",
    name: "ApplicationFrom",
    component: ApplicationForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:subEventId/sellerPartiForm",
    name: "ApplicationFrom",
    component: ApplicationForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:subEventId/visitorPartiForm",
    name: "ApplicationFrom",
    component: ApplicationForm,
    layout: "/form",
  }, {
    path: "/subEvent/:subEventId/boothPartiForm",
    name: "ApplicationFrom",
    component: ApplicationForm,
    layout: "/form",
  },
];
