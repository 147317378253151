export interface meetingRoute {
    path: string,
}

export const meetingRoutes: meetingRoute[] = [
    {
        path: "/direct/:id"
    },
    {
        path: "/direct/:id/device",
    },
    {
        path: "/videoMeeting/device",
    },
    {
        path: "/direct/:id/meeting",
    },
    {
        path: "/videoMeeting/meeting/:id"
    }
]

