import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// types
import { AutonoForm, FormData } from "../../../../../types/models/Form";
import { CounselFormAnswer } from "../../../../../types/models/CounselForm";

// material comp
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import PxGridContainer from "../../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../../components/Grid/PxGridItem";
import PxOutlinedTxtField from "../../../../../components/Inputs/PxOutlinedTxtField";
import FormGroup from "@material-ui/core/FormGroup";

// context
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";

// utils
import TranslatedFormItem from "../../../../form/TranslatedFormItem";
import ButtonTypo from "../../../../../components/Typhography/ButtonTypo";
import PxButton from "../../../../../components/Buttons/PxButton";
import { Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getSignedRequest } from "../../../../../actions/files";
import { useTranslation } from "react-i18next";
import ShowFile from "../../../../../components/File/ShowFile";

interface FormItemProps {
  preview: boolean;
  whtAdditionalPage: boolean;
  setFormData: React.Dispatch<React.SetStateAction<FormData | undefined>>;
  counselFormAnswers?: CounselFormAnswer[];
}

type Props = AutonoForm & FormItemProps;

const CounselFormItem: React.FC<Props> = (props) => {
  const {
    id,
    type,
    title,
    explain,
    content,
    explainCheck,
    // secretCheck,
    requireCheck,
    // index,
    selectContent,
    preview,
    setFormData,
    counselFormAnswers,
  } = props;
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });

  const [radioValue, setRadioValue] = useState("");
  const [textContent, setTextContent] = useState(content);
  const [ratingValue, setRatingValue] = useState<number | null>(null);
  const [fileNameValue, setFileNameValue] = useState<string | undefined>();

  const [checkBoxValue, setCheckBoxValue] = useState<{
    [key: string]: boolean;
  }>({});

  // 이미 작성한 상담일지가 있으면 불러온 후 해당 form의 value에 값을 넣어줌
  useEffect(() => {
    if (counselFormAnswers !== undefined) {
      switch (counselFormAnswers[0].type) {
        case "single":
          setRadioValue(
            `${counselFormAnswers[0].answerUuid}[S]${counselFormAnswers[0].content}`
          );

          // 기본 formData의 값을 불러온 데이터로 변경
          setFormData((prevState) => {
            Object.assign(prevState!.formData[id], {
              defaultId: counselFormAnswers[0].id,
              radioContent: {
                id: counselFormAnswers[0].answerUuid,
                content: counselFormAnswers[0].content,
              },
            });
            return prevState;
          });
          break;
        case "many":
          const newCheckBoxValue: { [key: string]: boolean } = {};
          counselFormAnswers.forEach((counselForm) => {
            Object.assign(newCheckBoxValue, {
              [`${counselForm.answerUuid}[S]${counselForm.content}`]: true,
            });
          });
          setCheckBoxValue(newCheckBoxValue);

          // 기본 formData의 값을 불러온 데이터로 변경
          const checkBoxContent: AutonoForm["checkBoxContent"] = [];

          Object.entries(newCheckBoxValue).forEach((value) => {
            if (value[1] === true) {
              const splitCheckBoxValue = value[0].split("[S]");
              checkBoxContent?.push({
                id: splitCheckBoxValue[0],
                content: splitCheckBoxValue[1],
              });
            }
          });

          setFormData((prevState) => {
            Object.assign(prevState!.formData[id], {
              defaultId: counselFormAnswers[0].id,
              checkBoxContent: checkBoxContent,
            });
            return prevState;
          });
          break;
        case "file":
          console.log(`counselFormAnswers : `, counselFormAnswers);
          // console.log(`file content : `,file)
          setFileNameValue(counselFormAnswers[0].content);
          setFormData((prevState) => {
            Object.assign(prevState!.formData[id], {
              fileContent: counselFormAnswers[0].id,
            });
            return prevState;
          });
          break;
        case "star":
          setRatingValue(Number(counselFormAnswers[0].content));
          setFormData((prevState) => {
            Object.assign(prevState!.formData[id], {
              defaultId: counselFormAnswers[0].id,
              content: counselFormAnswers[0].content,
            });
            return prevState;
          });
          break;
        default:
          setTextContent(counselFormAnswers[0].content);

          // 기본 formData의 값을 불러온 데이터로 변경
          setFormData((prevState) => {
            Object.assign(prevState!.formData[id], {
              defaultId: counselFormAnswers[0].id,
              content: counselFormAnswers[0].content,
            });
            return prevState;
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counselFormAnswers]);

  const handleInputOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    event.persist();
    setTextContent(event.target.value as string);
    setFormData((prevState) => {
      Object.assign(prevState!.formData[id], {
        content: event.target.value as string,
      });
      return prevState;
    });
  };

  const handleRadioOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setRadioValue((event.target as HTMLInputElement).value);
    const splitRadioValue = event.target.value.split("[S]");
    if (type === "single") {
      setFormData((prevState) => {
        Object.assign(prevState!.formData[id], {
          radioContent: {
            id: splitRadioValue[0],
            content: splitRadioValue[1],
          },
        });
        return prevState;
      });
    } else {
      setFormData((prevState) => {
        Object.assign(prevState!.formData[id], {
          categoryContent: {
            id: splitRadioValue[0],
            content: splitRadioValue[1],
          },
        });
        return prevState;
      });
    }
  };

  const handleCheckBoxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    setCheckBoxValue((prevCheckBoxValue) => {
      Object.assign(prevCheckBoxValue, {
        [`${event.target.name}`]: !checkBoxValue[`${event.target.name}`],
      });
      return { ...prevCheckBoxValue };
    });

    // formData checkBoxContent value 변경 로직
    let checkBoxContent: AutonoForm["checkBoxContent"] = [];

    const checked = checkBoxValue;
    Object.entries(checked).forEach((value) => {
      if (value[1] === true) {
        const splitCheckBoxValue = value[0].split("[S]");
        checkBoxContent?.push({
          id: splitCheckBoxValue[0],
          content: splitCheckBoxValue[1],
        });
      }
    });
    setFormData((prevState) => {
      Object.assign(prevState!.formData[id], {
        checkBoxContent: checkBoxContent,
      });
      return prevState;
    });
  };

  const handleRatingOnChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | null
  ) => {
    event.preventDefault();
    setRatingValue(newValue);
    setFormData((prevState) => {
      Object.assign(prevState!.formData[id], {
        content: newValue?.toString(),
      });
      return prevState;
    });
  };

  const handleFileDelete = () => {
    setFileNameValue(undefined);
    setFormData((prevState) => {
      Object.assign(prevState!.formData[id], {
        fileContent: {
          fileId: undefined,
          name: undefined,
          url: undefined,
        },
      });
      console.log(`fileContent : `, prevState!.formData[id]);
      return prevState;
    });
    console.log(`delete : `, fileNameValue);
  };

  const handleFileAdd = async (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = `event/${params.id}/`;
    const fileSize = file.size;

    const result: any = await dispatch(
      getSignedRequest(file, fileName, fileType, folderPath, fileSize)
    );
    if (result !== false && result !== undefined) {
      setFileNameValue(result.fileName);
      setFormData((prevState) => {
        Object.assign(prevState!.formData[id], {
          fileContent: {
            fileId: result.id,
            name: result.fileName,
            url: result.filePath,
          },
        });
        console.log(`prevState : `, prevState);
        return prevState;
      });
    }
  };

  return (
    <PxGridContainer className="py-3" direction="column">
      <PxGridItem>
        <TranslatedFormItem
          type="input"
          content={`${title} ${requireCheck === "Y" ? " (필수)" : ""}`}
          // ${freeFormQuestionText(title!, type!)}
          name={id}
          fullWidth
          fontsize={15}
          readOnly={true}
          fontweight="bold"
          onlyEng={false}
          link={true}
        />
      </PxGridItem>
      {explainCheck === "Y" && (
        <PxGridItem>
          <TranslatedFormItem
            type="string"
            content={explain!}
            name={id}
            fullWidth
            fontsize={14}
            fontweight="bold"
            readOnly
            link={true}
            onlyEng={false}
          />
        </PxGridItem>
      )}

      {(type === "long" || type === "short" || type === "url") && (
        <PxGridItem>
          <PxOutlinedTxtField
            name={id}
            value={textContent}
            disabled={preview}
            inputpadding={type === "long" ? 0 : 10}
            multiline={type === "long" ? true : false}
            rows={2}
            fullWidth
            placeholder=""
            fontsize={14}
            onChange={handleInputOnChange}
            resizeVertical={true}
          />
        </PxGridItem>
      )}

      {type === "single" && (
        <PxGridItem>
          <FormControl>
            {/* radio button value는  */}
            <RadioGroup value={radioValue} onChange={handleRadioOnChange}>
              {selectContent!.formIds.map((formId, index) => (
                <TranslatedFormItem
                  type="radio"
                  content={selectContent!.formData[formId].explain!}
                  key={index}
                  disabled={preview}
                  value={`${formId}[S]${selectContent!.formData[formId]
                    .explain!}`}
                  link={true}
                  onlyEng={false}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </PxGridItem>
      )}

      {type === "many" && (
        <PxGridItem>
          <FormControl>
            <FormGroup>
              {selectContent!.formIds.map((formId, index) => (
                <>
                  <TranslatedFormItem
                    type="checkbox"
                    content={selectContent!.formData[formId].explain!}
                    key={index}
                    disabled={preview}
                    onlyEng={false}
                    checked={
                      Object.keys(checkBoxValue).length === 0
                        ? false
                        : checkBoxValue[
                            `${formId}[S]${selectContent!.formData[formId]
                              .explain!}`
                          ]
                    }
                    name={`${formId}[S]${selectContent!.formData[formId]
                      .explain!}`}
                    onChange={handleCheckBoxOnChange}
                    link={true}
                  />
                </>
              ))}
            </FormGroup>
          </FormControl>
        </PxGridItem>
      )}

      {type === "star" && (
        <PxGridItem>
          <Box
            borderColor="grey.500"
            borderRadius={5}
            border={1}
            style={{ textAlign: "center" }}
          >
            <Rating
              size="large"
              disabled={preview}
              className="mt-1"
              value={ratingValue}
              onChange={handleRatingOnChange}
            />
          </Box>
        </PxGridItem>
      )}
      {type === "file" && (
        <PxGridItem>
          {fileNameValue != undefined || fileNameValue != null ? (
            <>
              {isNaN(Number(fileNameValue)) ? (
                <ButtonTypo gutterBottom>{fileNameValue}</ButtonTypo>
              ) : (
                <ShowFile fileId={Number(fileNameValue)} />
              )}
              {preview === false && (
                <PxButton backgroundcolor="grey" onClick={handleFileDelete}>
                  <ButtonTypo>{t("form.delete")}</ButtonTypo>
                </PxButton>
              )}
            </>
          ) : (
            <Button
              variant="contained"
              component="label"
              style={{
                boxShadow: "none",
                backgroundColor: "#eee",
                padding: "9px 16px",
              }}
            >
              {t("form.add")}
              <input
                type="file"
                style={{ display: "none" }}
                name="file1"
                onChange={handleFileAdd}
              />
            </Button>
          )}
        </PxGridItem>
      )}
    </PxGridContainer>
  );
};

export default CounselFormItem;
