import { AppActions, AppState } from "../store";
import axios, { AxiosResponse } from "axios";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert } from "../utils/utils";

// types
import { EventInfo, Pagination } from "../types/models/Event";
import { Dispatch } from "react";

export const getEventsAllAction = (events: Pagination): AppActions => ({
  type: "GET_EVENTS",
  events,
});

export const getEventAction = (event: EventInfo): AppActions => ({
  type: "GET_EVENT",
  event,
});

export const removeEvent = (): AppActions => ({
  type: "REMOVE_EVENT",
});

export const getEventsAll = (
  page: number = 0,
  more: boolean = false,
  events: EventInfo[] = []
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/events/getAll?page=${page}&size=12`;
  try {
    var res: AxiosResponse = await axios.post(path, config);
    if (more) {
      res.data.content = [...events, ...res.data.content];
      dispatch(getEventsAllAction(res.data));
      return res.data;
    } else {
      dispatch(getEventsAllAction(res.data));
    }
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getEvent = <Event>(id: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/events/detail?id=${id}`;
  try {
    const res: AxiosResponse = await axios.get(path, config);
    dispatch(getEventAction(res.data));
    return true;
  } catch (err) {
    if (err.response !== undefined) {
      const error: Error = err.response.data;
      Object.assign(error, { detail: err.response.data.message });
      const alert: Alert = setAlert(err.response.status, error, path);

      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  }
};
