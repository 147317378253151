import React from "react";
import BaseTemplate, {
  EventContent,
  LinkButton,
  MailContent,
  MailReason,
} from "./BaseTemplate";

interface MeetingMailProp {
  language: "kr" | "en";
  userName: string;
  title: string;
  mailContent: string;
  eventName: string;
  subEventName: string;
  eventStart: string;
  eventEnd: string;
  applicant: string;
  acceptor: string;
  meetingStart: string;
  meetingEnd: string;
  link: string;
  reason?: string;
}

export default ({
  language,
  userName,
  title,
  mailContent,
  eventName,
  subEventName,
  eventStart,
  eventEnd,
  applicant,
  acceptor,
  meetingStart,
  meetingEnd,
  link,
  reason,
}: MeetingMailProp) => {
  return (
    <BaseTemplate title={title} language="en">
      {language === "en" ? (
        <>
          <MailContent>
            {" "}
            Hello, {userName}
            <br />
            {mailContent}
          </MailContent>
          <EventContent>
            Event Name:&nbsp; {eventName}&nbsp;({subEventName})<br />
            Period:&nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            Applicant:&nbsp;{applicant}
            <br />
            Acceptor:&nbsp;{acceptor}
            <br />
            Meeting time:&nbsp;{meetingStart}(GMT+9) ~&nbsp;{meetingEnd}(GMT+9)
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            Go to event{" "}
          </LinkButton>
        </>
      ) : (
        <>
          <MailContent> {userName}님 안녕하세요.</MailContent>
          {reason && <MailReason>{reason}</MailReason>}
          <EventContent>
            이벤트 명:&nbsp; {eventName}&nbsp;({subEventName})<br />
            이벤트 기간:&nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            요청자:&nbsp;{applicant}
            <br />
            대상자:&nbsp;{acceptor}
            <br />
            미팅일시:&nbsp;{meetingStart}(GMT+9) ~&nbsp;{meetingEnd}(GMT+9)
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            이벤트 바로가기
          </LinkButton>
        </>
      )}
    </BaseTemplate>
  );
};
