import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import uuid from "uuid";
import { useDispatch, useSelector } from "react-redux";

//custom ui
import TitleTypo from "../../components/Typhography/TitleTypo";
import PxButton from "../../components/Buttons/PxButton";
import { useHistory } from "react-router-dom";
import ButtonTypo from "../../components/Typhography/ButtonTypo";

//materai ui , icons
import {
  Visibility,
  VisibilityOff,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";

//redux
import { removeUser, updatePwd } from "../../actions/users";
import { removeLoading, setLoading } from "../../actions/loading";
import { setAlertAction, removeAlertAction } from "../../actions/alerts";
import Alert from "../../types/models/Alert";
import { AppState } from "../../store";
import { deleteAuthToken } from "../../utils/setAuthToken";
import { removeRoutePathAction } from "../../actions/routePath";

// interface
interface PasswordProps {
  password: string;
  showPassword: boolean;
}

interface UpdateProps {
  id: number | undefined;
  password: string;
  newPassword: string;
  country: string;
}

const Password = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.users);
  const country = useSelector((state: AppState) => state.countries.country);
  const [isCapsLock, setIsCapsLock] = useState({
    password: false,
    newPassword: false,
  });
  const [userPassword, setUserPassword] = useState<UpdateProps>({
    id: 0,
    password: "",
    newPassword: "",
    country: "",
  });
  const passwordRegExp = /[A-Za-z0-9\d$@$!%*#?&]{8,}$/;
  const koreanRegExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글확인 정규식
  const engCheck = /[a-z]/; //소문자만 입력 (대문자 불가)
  const numCheck = /[0-9]/;
  useEffect(() => {
    if (user !== undefined) {
      setUserPassword({
        ...userPassword,
        id: user.id,
        country: `${country?.continent}/${country?.city}`,
      });
    }
  }, [user]);

  const [values, setValues] = React.useState<PasswordProps>({
    password: "",
    showPassword: false,
  });
  const [newValues, setNewValue] = useState<PasswordProps>({
    password: "",
    showPassword: false,
  });
  // 패스워드 보이기/숨기기
  const ShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const ShowNewPassword = () => {
    setNewValue({ ...newValues, showPassword: !newValues.showPassword });
  };

  const alert = (type: "warning" | "success", message: string) => {
    const onAlert: Alert = { alertType: type, id: uuid.v4(), msg: message };
    dispatch(setAlertAction(onAlert));
    setTimeout(() => {
      dispatch(removeAlertAction(onAlert.id));
    });
  };

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const s = String.fromCharCode(event.keyCode);
    if (
      s.toUpperCase() === s &&
      s.toLowerCase() !== s &&
      !event.shiftKey &&
      event.key.toLowerCase() !== event.key
    ) {
      setIsCapsLock({ ...isCapsLock, [event.currentTarget.name]: true });
    } else {
      setIsCapsLock({ ...isCapsLock, [event.currentTarget.name]: false });
    }
  };

  const sumbitBtn = async () => {
    if (userPassword.password === "") {
      alert("warning", t("regularExpression.passwordEmpty"));
    } else if (
      !passwordRegExp.test(userPassword.newPassword) ||
      !userPassword.newPassword.match(engCheck) ||
      !userPassword.newPassword.match(numCheck)
    ) {
      alert("warning", t("regularExpression.password"));
    } else {
      dispatch(setLoading());
      const result: any = await dispatch(updatePwd(userPassword));
      dispatch(removeLoading());
      if (result === true) {
        alert("success", t("changePassword.passwordChanged"));
        deleteAuthToken();
        dispatch(removeUser());
        dispatch(removeRoutePathAction());
        history.push("/auth/login");
        window.location.reload(false);
      }
    }
  };

  return (
    <Container>
      <Content>
        <Title>
          <Icon>
            <ArrowBackIcon
              style={{ fontSize: 24 }}
              onClick={() => history.goBack()}
            />
          </Icon>
          &nbsp;&nbsp; | &nbsp;&nbsp;
          <TitleTypo fontSize={20} fontweight="500">
            {t("changePassword.changePassword")}
          </TitleTypo>
        </Title>

        <FormControl fullWidth variant="outlined" style={{ marginTop: 20 }}>
          <InputLabel
            htmlFor="outlined-adornment-password"
            style={{ backgroundColor: "white" }}
          >
            {t("changePassword.password")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={userPassword.password}
            name="password"
            onKeyDown={handleOnKeyDown}
            onChange={(e) => {
              const isValid = koreanRegExp.test(e.target.value as string);
              if (!isValid) {
                setUserPassword({ ...userPassword, password: e.target.value });
              } else {
                alert("warning", "패스워드에 한글은 입력할 수 없습니다.");
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={ShowPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        {isCapsLock.newPassword && <div>CapsLock 키가 눌려있습니다</div>}

        <FormControl fullWidth variant="outlined" style={{ marginTop: 20 }}>
          <InputLabel
            htmlFor="outlined-adornment-password"
            style={{ backgroundColor: "white" }}
          >
            {t("changePassword.newPassword")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={newValues.showPassword ? "text" : "password"}
            style={{ imeMode: "disabled" }}
            value={userPassword.newPassword}
            name="newPassword"
            onKeyDown={handleOnKeyDown}
            onChange={(e) => {
              const isValid = koreanRegExp.test(e.target.value as string);
              if (!isValid) {
                setUserPassword({
                  ...userPassword,
                  newPassword: e.target.value,
                });
              } else {
                alert("warning", "패스워드에 한글은 입력할 수 없습니다.");
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={ShowNewPassword}
                  edge="end"
                >
                  {newValues.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        {isCapsLock.newPassword && <div>CapsLock 키가 눌려있습니다</div>}
        {userPassword.newPassword!.length > 0 &&
          !userPassword.newPassword!.match(engCheck) && (
            <div style={{ color: "red" }}>
              {t("register.containLowercaseLetters")}
            </div>
          )}
        {userPassword.newPassword!.length > 0 &&
          !userPassword.newPassword!.match(numCheck) && (
            <div style={{ color: "red" }}>{t("register.containNumber")}</div>
          )}

        <BtnContainer>
          <PxButton
            padding="12px 47px 15px 47px"
            hover={false}
            backgroundcolor="transparent"
            onClick={() => {
              history.goBack();
            }}
          >
            <ButtonTypo>{t("changePassword.cancel")}</ButtonTypo>
          </PxButton>

          <PxButton
            boxShadow={false}
            padding="12px 47px 15px 47px"
            backgroundcolor="bluePurple"
            onClick={() => sumbitBtn()}
          >
            <ButtonTypo txtcolor="white">
              {t("changePassword.complete")}
            </ButtonTypo>
          </PxButton>
        </BtnContainer>
      </Content>
    </Container>
  );
};

export default Password;

const Container = styled.div`
  margin-top: 28px;
  width: 100%;
  height: calc(100vh - 330px);
  min-height: 350px;
  max-height: 100vh !important;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 400px) {
    height: 100%;
  }
`;

const Content = styled.div`
  width: 328px;
  height: 100%;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;

  color: rgba(0, 0, 0, 0.38);
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
`;

const Icon = styled.div`
  content: "";
  height: 24px;
  &:hover {
    cursor: pointer;
  }
`;
