import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import ViewModuleIcon from "@material-ui/icons/ViewModule";

import { useAppState } from "../AppStateProvider/hooks/useAppState";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { ChatContext } from "../ChatProvider/ChatProvider";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      animationName: "$blinker",
      animationDuration: "1s",
      animationIterationCount: "5",

      boxShadow: "none",
      backgroundColor: "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
  })
);

export default function ToggleMultiViewButton(props: {
  disabled?: boolean;
  backgroundColor?: string;
}) {
  const classes = useStyles();
  const {
    toggleMultiScreenEnabled,
    isMultiScreenEnabled,
    toggleSurveyFormEnabled,
  } = useAppState();
  const { setVideoChatSidebarOpen } = useContext(ChatContext);
  const [t] = useTranslation("lang", { useSuspense: false });

  return (
    <Tooltip
      title="7 ✕ 7"
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        className={classes.fab}
        onClick={() => {
          setVideoChatSidebarOpen(false);
          toggleSurveyFormEnabled(false);
          toggleMultiScreenEnabled(!isMultiScreenEnabled);
        }}
        disabled={props.disabled}
        style={isMultiScreenEnabled ? { color: "#bb86fc" } : {}}
      >
        <div>
          <ViewModuleIcon />
          <CaptionTypo txtcolor="white">
            {t("videoController.multiView")}
          </CaptionTypo>
        </div>
      </Fab>
    </Tooltip>
  );
}
