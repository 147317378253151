import React, { useCallback } from "react";
import MyInfoAdditional from "./MyInfoAdditional";
import MyInfoContent from "./MyInfoContent";
import MyInfoMe from "./MyInfoMe";
import { useMyInfoContext } from "./useMyInfoContext";

const MyInfoContentView = () => {
  const { activeStep } = useMyInfoContext();

  const setActiveComponent = useCallback(() => {
    if (activeStep === 0) {
      return <MyInfoContent />;
    } else if (activeStep === 1) {
      return <MyInfoAdditional />;
    } else {
      return <MyInfoMe />;
    }
  }, [activeStep]);

  return setActiveComponent();
};

export default MyInfoContentView;
