import React, { useCallback, useEffect } from "react";
import { MeetingData, SubEventInfo } from "../../../../types/models/SubEvent";
import { MyMeetingCheckListKeyType } from "./MeetingDetail";
import * as XLSX from "xlsx-js-style";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import moment from "moment";
import uuid from "uuid";
import { CountryApiInfo, CountryInfo } from "../../../../types/models/Country";
import { parallaxCalculation } from "../../../../utils/momentTz";
import { MeetingInfo } from "../../../../types/models/Meeting";
import BusinessCard from "../../../../types/models/BusinessCard";
import { useTranslation } from "react-i18next";

interface Props {
  meetingSchedule: MeetingData[];
  myMeetingList: MyMeetingCheckListKeyType;
}

type DateType = {
  [time: string]: {
    startTime: string;
    endTime: string;
  };
};

interface TimeListProps {
  id: string;
  startTime: string;
  endTime: String;
  impossibleAt: string;
}

interface CreateFormReturnType {
  excelData: Array<Object>;
  merges: Array<XLSX.Range>;
}

const DownloadMeetingSchedule: React.FC<Props> = ({
  meetingSchedule,
  myMeetingList,
}) => {
  const application = useSelector(
    (state: AppState) => state.applications.application
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { subEventApplication } = useSelector(
    (state: AppState) => state.applications
  );
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  const meetings = useSelector((state: AppState) => state.meetings.meetings);
  const { t, i18n } = useTranslation("lang", { useSuspense: false });

  const createMeetingTimeForm = useCallback(
    async (subEvent) => {
      const timeList: TimeListProps[] = meetingTimeSetting(subEvent);
      const meetingDataJson: Array<string> = [];

      const subDateCount = moment(
        currentCountryTime(subEvent, "subEventEndDate", countries!, country!),
        "YYYY-MM-DD"
      ).diff(
        moment(
          currentCountryTime(
            subEvent,
            "subEventStartDate",
            countries!,
            country!
          ),
          "YYYY-MM-DD"
        ),
        "day"
      );

      // 미팅날짜 기준 값
      let subEventDate = moment(
        currentCountryTime(subEvent, "subEventStartDate", countries!, country!)
      );

      Array.from({ length: subDateCount + 1 }, (x, i) => {
        const subEventDateString: string = subEventDate.format("YYYY-MM-DD");
        meetingDataJson.push(subEventDateString);
        subEventDate = subEventDate.add(1, "days");
      });

      //셀병합(번호, 기업이름)
      let marges = [
        //s = 병합시작점 ,e : 병합개수, r:row,  c:cell
        { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      ];

      let data = [];
      let refinedData: any = new Object();
      refinedData["기업이름"] = "";
      for (let i = 0; i < subDateCount + 1; i++) {
        //같은날짜 셀병합

        const marge = {
          s: { r: 0, c: 2 + i * timeList.length - 1 },
          e: { r: 0, c: 2 + timeList.length - 2 + i * timeList.length },
        };
        marges.push(marge);

        // 미팅 날짜별, 시간 별 입력
        // 날짜 시간별 키값 ex) 첫번째 시간일때 refinedData[2020-02-10], 첫번째 시간이 아닐때 refinedData[2020-02-1010:30]
        for (let j = 0; j < timeList.length; j++) {
          j === 0
            ? (refinedData[
                `${meetingDataJson[i]} (GMT+9)`
              ] = `${timeList[j].startTime}`)
            : (refinedData[
                `${meetingDataJson[i]}` + `${timeList[j].startTime}`
              ] = timeList[j].startTime);
        }
      }

      data.push(refinedData);

      const dataResult = await meetingDataConvert(meetings!, timeList);

      return { excelData: [...data, ...dataResult], merges: marges };
    },
    [moment, subEvent, meetings]
  );

  const meetingDataConvert = (
    meetings: MeetingInfo[],
    timeList: TimeListProps[]
  ) => {
    const userBusinessCard: BusinessCard =
      subEventApplication.applicants[0].businessCard;

    let refinedData: any = new Object();
    refinedData[
      `기업이름`
    ] = `${userBusinessCard.company}(${userBusinessCard.name})`;
    meetings.forEach((data, i) => {
      if (
        data.meetingAcceptor &&
        data.meetingApplicant &&
        data.status === "agree"
      ) {
        timeList[0].startTime === data.startTime
          ? (refinedData[`${data.date} (GMT+9)`] =
              data.acceptorName === subEventApplication.name
                ? `${data.meetingApplicant?.name} (${data.meetingApplicant?.managerName})\r\n` +
                  `${data.meetingApplicant?.applicants}\r\n` +
                  `${data.meetingApplicant?.email}`
                : `${data.meetingAcceptor.name} (${data.meetingAcceptor.managerName})\r\n` +
                  `${data.meetingAcceptor.applicants}\r\n` +
                  `${data.meetingAcceptor.email}`)
          : (refinedData[`${data.date}${data.startTime}`] =
              data.acceptorName === subEventApplication.name
                ? `${data.meetingApplicant?.name} (${data.meetingApplicant?.managerName})\r\n` +
                  `${data.meetingApplicant?.applicants}\r\n` +
                  `${data.meetingApplicant?.email}`
                : `${data.meetingAcceptor.name} (${data.meetingAcceptor.managerName})\r\n` +
                  `${data.meetingAcceptor.applicants}\r\n` +
                  `${data.meetingAcceptor.email}`);
      }
    });

    return [refinedData];
  };

  const currentCountryTime = (
    subEvent: SubEventInfo,
    date:
      | "subEventStartDate"
      | "subEventEndDate"
      | "mainStartDate"
      | "mainEndDate",
    countries: CountryInfo,
    country: CountryApiInfo
  ) => {
    const countryCode = subEvent?.mainEvent?.countryCode ?? "KR";
    // const { country, countries } = selector((state: AppState) => state.countries);
    let time;
    if (date === "mainStartDate") {
      time = subEvent!.mainEvent!.startDate;
    } else if (date === "mainEndDate") {
      time = subEvent!.mainEvent!.endDate;
    } else {
      time = subEvent[date];
    }
    const subEventStartTime = parallaxCalculation(
      time,
      countries![countryCode! as string] as any,
      countries![country!.countryCode!] as any,
      "YYYY-MM-DD"
    );
    return subEventStartTime as string;
  };

  const meetingTimeSetting = (subEvent: SubEventInfo) => {
    const matchReqTime = subEvent.matchReqTime as number;
    const matchBreakTime = subEvent.matchBreakTime as number;
    const matchNumPerDay = subEvent.matchNumPerDay as number;

    let meetingTimeList: TimeListProps[] = []; // 필요데이터 {시작시간, 종료시간, 불가여부}

    // 날짜별 매칭시간 기준 값
    let impossibleStartTime = moment(
      `2020-01-01 ${subEvent.matchStartTime}:00`
    );

    // 날짜별 매칭시간 조회 (시작시간, 종료시간, 불가여부)
    Array.from({ length: matchNumPerDay }, (x, i) => {
      const startTime = impossibleStartTime.format("HH:mm");
      const endTime = impossibleStartTime
        .add(matchReqTime, "m")
        .format("HH:mm");

      meetingTimeList.push({
        id: uuid.v4(),
        startTime: startTime,
        endTime: endTime,
        impossibleAt: "N",
      });

      impossibleStartTime = impossibleStartTime.add(matchBreakTime, "m");
    });

    return meetingTimeList;
  };

  const downloadExcel = async () => {
    const excelData: CreateFormReturnType = await createMeetingTimeForm(
      subEvent
    );

    const dataWs = XLSX.utils.json_to_sheet(excelData.excelData);
    const today = moment();

    const fileName = `${subEvent?.mainEvent?.name}_${
      subEventApplication.applicants[0].businessCard.name
    }_(${today.format("MMDDHHmm")}).xlsx`;

    const sheetName =
      i18n.language == "en" ? `Meeting Schedule` : `미팅 일정표`;

    if (excelData.merges && excelData.excelData.length > 0) {
      dataWs["!merges"] = excelData.merges;
    }

    //
    const dataWorkSheetCol = [];
    for (const key in dataWs) {
      if (typeof dataWs[key] != "object") continue;

      dataWs[key].s = {
        width: 24,
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
      };
      dataWorkSheetCol.push({ width: 24 });
    }

    dataWs["!cols"] = dataWorkSheetCol;

    // 워크북 생성 및 시트 추가
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dataWs, sheetName);

    // 파일 다운로드
    XLSX.writeFile(wb, fileName);
  };

  return (
    <PxButton backgroundcolor="purple" onClick={() => downloadExcel()}>
      <ButtonTypo>{t("meetingDetail.meetingScheduleDownload")}</ButtonTypo>
    </PxButton>
  );
};

export default DownloadMeetingSchedule;
