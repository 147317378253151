import FormAnswerActionType from "../types/actions/FormAnswerAction";
import FormAnswer from "../types/models/FormAnswer";

const formAnswerDefaultState: FormAnswer = {
  formAnswers: undefined,
};

const formAnswerReducer = (
  state = formAnswerDefaultState,
  action: FormAnswerActionType
): FormAnswer => {
  switch (action.type) {
    case "GET_FORM_ANSWERS":
      return {
        ...state,
        formAnswers: action.formAnswers,
      };
    case "GET_SURVEY_FORM_ANSWERS":
      return {
        ...state,
        surveyAnswers: action.surveyFormAnswers,
      };
    case "REMOVE_FORM_ANSWER":
      return formAnswerDefaultState;
    default:
      return state;
  }
};

export default formAnswerReducer;
