import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { getSubEvents } from "../actions/subEvents";
import { AppState } from "../store";
import moment from "moment";
import { getApplicantList } from "../actions/applications";
import _ from "lodash";

type TemplateContentType = {
  templateId: number;
  templateName: string;
  htmlContent: string;
  cssContent: string;
};

type ApplicationInfoType = {
  subEventId: string;
  sgroupName: string;
  bgroupName: string;
  sellerRegStartDate: string;
  sellerRegEndDate: string;
  buyerRegStartDate: string;
  buyerRegEndDate: string;
};

type ParticipateStateType = "approval" | "waiting" | "reject" | "applicant";

enum ParticipateType {
  SELLER,
  BUYER,
}

interface ParticipantContainer {
  backgroundColor?: string;
}

interface CardTitleProps {
  color?: string;
}

const EventHompage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventId, templateName } = useParams<{
    eventId: string;
    templateName: string;
  }>();
  const { subEvents } = useSelector((state: AppState) => state.subEvents);
  const { isAuthenticated } = useSelector((state: AppState) => state.users);
  const { applicants } = useSelector((state: AppState) => state.applications);
  const [html, setHtml] = useState<string>();
  const [css, setCss] = useState<string>();
  const [applicationInfo, setApplicationInfo] = useState<ApplicationInfoType>();
  const [applicantsList, setApplicantsList] = useState<{
    [key: number]: { approval: string; type: string; reason?: string };
  }>([]);

  // 초기 홈페이지 html css 데이터 요청
  useEffect(() => {
    homepageDataSet();
  }, [templateName]);

  // 홈페이지  html css 요청 함수
  const homepageDataSet = useCallback(async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };
    const res = await Axios.get<TemplateContentType>(
      `/api/template/event/${eventId}/template-name/${templateName}`,
      config
    );
    setHtml(res.data.htmlContent);
    setCss(res.data.cssContent);

    dispatch(getSubEvents(eventId));
  }, [templateName]);

  // 페이지 이동 이벤트 부여
  useEffect(() => {
    if (html == null) return;
    const elements = document.querySelectorAll("[destination-url]");

    elements.forEach((element) => {
      element.addEventListener("click", (e: Event) => {
        e.preventDefault();
        const currentTarget = e.currentTarget as HTMLElement;
        const destination = currentTarget.getAttribute("destination-url");

        if (destination) {
          history.push(`/homepage/${eventId}/${destination}`);
          //   window.location.href = `/homepage/${eventId}/${destination}`;
        }
      });
    });

    const moveSectionEl = document.querySelectorAll("[move-section]");
    moveSectionEl.forEach((element) => {
      element.addEventListener("click", (e: Event) => {
        e.preventDefault();
        const currentTarget = e.currentTarget as HTMLElement;
        const sectionValue = currentTarget.getAttribute("move-section");

        const moveTarget = document.getElementById(sectionValue as string);
        if (moveTarget) moveTarget.scrollIntoView({ behavior: "smooth" });

        // history.push(`#${sectionValue}`);
      });
    });
  }, [html]);

  // css 스타일 적용 이벤트
  useEffect(() => {
    if (css == null) return;
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = css;
    document.getElementsByTagName("head")[0].appendChild(style);

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://cdn.jsdelivr.net/npm/tailwindcss@^2/dist/tailwind.min.css";

    document.head.appendChild(link);

    return () => {
      document.getElementsByTagName("head")[0].removeChild(style);
      document.getElementsByTagName("head")[0].removeChild(link);
    };
  }, [css]);

  // subevent 버튼 데이터 가공
  useEffect(() => {
    if (
      subEvents == null ||
      (subEvents && subEvents?.length == 0) ||
      (subEvents.length > 0 &&
        subEvents[0].mainEvent &&
        subEvents[0].mainEvent.id != Number(eventId))
    )
      return;

    const subEvent = subEvents[0];
    const applicationData: ApplicationInfoType = {
      subEventId: subEvent.id as string,
      sgroupName: subEvent.sgroupName,
      bgroupName: subEvent.bgroupName,
      sellerRegStartDate: formatDate(subEvent.sellRegStartDate as string),
      sellerRegEndDate: formatDate(subEvent.sellRegEndDate as string),
      buyerRegStartDate: formatDate(subEvent.buyRegStartDate as string),
      buyerRegEndDate: formatDate(subEvent.buyRegEndDate as string),
    };

    setApplicationInfo(applicationData);
  }, [subEvents]);

  // 서브이벤트 날짜 데이터 변환 이벤트
  const formatDate = (dateData: string): string => {
    const date = moment(dateData);
    return date.format(`YYYY-MM-DD`);
  };

  const participantStateTitle = useCallback(
    (state: ParticipateStateType, appType?: ParticipateType) => {
      switch (state) {
        case "approval":
          return "승인 되었습니다.";
        case "waiting":
          return "승인 대기 중입니다.";
        case "reject":
          return "거절 되었습니다.";
        case "applicant":
          if (appType === ParticipateType.SELLER) {
            return `${applicationInfo?.sgroupName} 참가 신청`;
          } else {
            return `${applicationInfo?.bgroupName} 참가 신청`;
          }
      }
    },
    [applicationInfo]
  );

  // 서브이벤트 참여 버튼 클릭 이벤트
  const participateOnClick = (
    subEventId: string,
    participateType: ParticipateType,
    approvalStatus: ParticipateStateType
  ) => {
    let path;
    switch (approvalStatus) {
      case "approval":
        path = `/subEvent/${subEventId}/attendeeList`;
        break;
      case "applicant":
        if (participateType === ParticipateType.SELLER)
          return `/form/subEvent/${subEventId}/sellerPartiForm`;
        else if (participateType === ParticipateType.BUYER)
          return `/form/subEvent/${subEventId}/buyerPartiForm`;
        break;
      case "reject":
        path = `/subEvent/${subEventId}/myInfo`;
        break;
    }

    if (path != null) {
      history.push(path);
    }
  };

  // 서브이벤트 참여 버튼 컴포넌트
  const HompageApplyCard = (
    applicationInfo: ApplicationInfoType,
    subEventId: number
  ) => {
    if (_.has(applicantsList, subEventId)) {
      switch (applicantsList[subEventId].approval) {
        case "approval":
          return (
            <BottomContainer>
              <EventCardContainer>
                {applicantsList[subEventId].type == "seller" && (
                  <GroupCardContainer
                    onClick={() =>
                      participateOnClick(
                        `${subEventId}`,
                        ParticipateType.SELLER,
                        "approval"
                      )
                    }
                  >
                    <GroupTitle>
                      {participantStateTitle(
                        "approval",
                        ParticipateType.SELLER
                      )}
                    </GroupTitle>
                    <PeriodTitle>접수 기간</PeriodTitle>
                    <GroupPeriod>
                      시작 : {applicationInfo.sellerRegStartDate}
                    </GroupPeriod>
                    <GroupPeriod>
                      마감 : {applicationInfo.sellerRegEndDate}
                    </GroupPeriod>
                  </GroupCardContainer>
                )}
                {applicantsList[subEventId].type == "buyer" && (
                  <GroupCardContainer
                    onClick={() =>
                      participateOnClick(
                        `${subEventId}`,
                        ParticipateType.BUYER,
                        "approval"
                      )
                    }
                  >
                    <GroupTitle>
                      {participantStateTitle("approval", ParticipateType.BUYER)}
                    </GroupTitle>
                    <PeriodTitle>접수 기간</PeriodTitle>
                    <GroupPeriod>
                      시작 : {applicationInfo.buyerRegStartDate}
                    </GroupPeriod>
                    <GroupPeriod>
                      마감 : {applicationInfo.buyerRegEndDate}
                    </GroupPeriod>
                  </GroupCardContainer>
                )}
              </EventCardContainer>
            </BottomContainer>
          );
        case "waiting":
          return (
            <BottomContainer>
              <EventCardContainer>
                {applicantsList[subEventId].type == "seller" && (
                  <GroupCardContainer
                    backgroundColor="#ede7f6"
                    onClick={() =>
                      participateOnClick(
                        `${subEventId}`,
                        ParticipateType.SELLER,
                        "applicant"
                      )
                    }
                  >
                    <GroupTitle>{participantStateTitle("waiting")}</GroupTitle>
                    <PeriodTitle>접수 기간</PeriodTitle>
                    <GroupPeriod>
                      시작 : {applicationInfo.sellerRegStartDate}
                    </GroupPeriod>
                    <GroupPeriod>
                      마감 : {applicationInfo.sellerRegEndDate}
                    </GroupPeriod>
                  </GroupCardContainer>
                )}
                {applicantsList[subEventId].type == "buyer" && (
                  <GroupCardContainer
                    backgroundColor="#ede7f6"
                    onClick={() =>
                      participateOnClick(
                        `${subEventId}`,
                        ParticipateType.BUYER,
                        "applicant"
                      )
                    }
                  >
                    <GroupTitle color="#512da8">
                      {applicationInfo.bgroupName} 참가신청
                    </GroupTitle>
                    <PeriodTitle>접수 기간</PeriodTitle>
                    <GroupPeriod>
                      시작 : {applicationInfo.buyerRegStartDate}
                    </GroupPeriod>
                    <GroupPeriod>
                      마감 : {applicationInfo.buyerRegEndDate}
                    </GroupPeriod>
                  </GroupCardContainer>
                )}
              </EventCardContainer>
            </BottomContainer>
          );
        case "reject":
          return (
            <BottomContainer>
              <EventCardContainer>
                {applicantsList[subEventId].type == "seller" && (
                  <GroupCardContainer
                    backgroundColor="#ede7f6"
                    onClick={() =>
                      participateOnClick(
                        `${subEventId}`,
                        ParticipateType.SELLER,
                        "reject"
                      )
                    }
                  >
                    <GroupTitle color="#512da8">
                      {participantStateTitle("reject")}
                    </GroupTitle>
                    <PeriodTitle>접수 기간</PeriodTitle>
                    <GroupPeriod>
                      시작 : {applicationInfo.sellerRegStartDate}
                    </GroupPeriod>
                    <GroupPeriod>
                      마감 : {applicationInfo.sellerRegEndDate}
                    </GroupPeriod>
                  </GroupCardContainer>
                )}
                {applicantsList[subEventId].type == "buyer" && (
                  <GroupCardContainer
                    backgroundColor="#ede7f6"
                    onClick={() =>
                      participateOnClick(
                        `${subEventId}`,
                        ParticipateType.BUYER,
                        "reject"
                      )
                    }
                  >
                    <GroupTitle color="#512da8">
                      {participantStateTitle("reject")}
                    </GroupTitle>
                    <PeriodTitle>접수 기간</PeriodTitle>
                    <GroupPeriod>
                      시작 : {applicationInfo.buyerRegStartDate}
                    </GroupPeriod>
                    <GroupPeriod>
                      마감 : {applicationInfo.buyerRegEndDate}
                    </GroupPeriod>
                  </GroupCardContainer>
                )}
              </EventCardContainer>
            </BottomContainer>
          );
      }
    } else {
      return (
        <BottomContainer>
          <EventCardContainer>
            <GroupCardContainer
              onClick={() => {
                participateOnClick(
                  `${subEventId}`,
                  ParticipateType.SELLER,
                  "applicant"
                );
              }}
            >
              <GroupTitle>{applicationInfo.sgroupName} 참가신청</GroupTitle>
              <PeriodTitle>접수 기간</PeriodTitle>
              <GroupPeriod>
                시작 : {applicationInfo.sellerRegStartDate}
              </GroupPeriod>
              <GroupPeriod>
                마감 : {applicationInfo.sellerRegEndDate}
              </GroupPeriod>
            </GroupCardContainer>
            <GroupCardContainer
              onClick={() => {
                participateOnClick(
                  `${subEventId}`,
                  ParticipateType.BUYER,
                  "applicant"
                );
              }}
            >
              <GroupTitle>{applicationInfo.bgroupName} 참가신청</GroupTitle>
              <PeriodTitle>접수 기간</PeriodTitle>
              <GroupPeriod>
                시작 : {applicationInfo.buyerRegStartDate}
              </GroupPeriod>
              <GroupPeriod>
                마감 : {applicationInfo.buyerRegEndDate}
              </GroupPeriod>
            </GroupCardContainer>
          </EventCardContainer>
        </BottomContainer>
      );
    }
  };

  // 로그인 여부 확인 로그인 유저이면 이벤트 참여 리스트 가져옴
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getApplicantList(eventId));
    }
  }, [isAuthenticated]);

  // applicantsList 데이터 가공
  useEffect(() => {
    if (applicants && applicants.length !== 0 && Array.isArray(applicants)) {
      const data: { [key: number]: { approval: string; type: string } } = {};
      applicants.forEach((applicant) => {
        Object.assign(data, {
          [applicant.subEventId.id]: {
            approval: applicant.applicationId.approval,
            type: applicant.applicationId.type,
            reason: applicant.applicationId.reason ?? "",
          },
        });
      });

      setApplicantsList(data);
    }
  }, [applicants]);

  return (
    <>
      {html && (
        <>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <>
            {applicationInfo &&
              subEvents &&
              applicantsList &&
              HompageApplyCard(applicationInfo, Number(subEvents[0].id))}
          </>
        </>
      )}
    </>
  );
};

export default EventHompage;

const BottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 120px;
  /* border-top: 1px solid; */
  background-color: rgba(0, 0, 0, 0.05);
`;

const EventCardContainer = styled.div`
  display: flex;
  /* border: 0.5px solid; */
  border-radius: 8px;
  width: 25rem;
  justify-content: space-around;
  align-items: center;
`;

const GroupCardContainer = styled.div<ParticipantContainer>`
  display: flex;
  flex-direction: column;
  width: 12rem;
  justify-content: column;
  padding: 10px;
  border-radius: 8px;
  background: ${(props) => props.backgroundColor || "#512da8"};
  height: 95%;
  cursor: pointer;
  z-index: 10;
`;

const GroupTitle = styled.h2<CardTitleProps>`
  font-size: 1.2rem;
  color: ${(props) => props.color || "white"};
`;

const GroupPeriod = styled.h3`
  font-size: 0.8rem;
  color: white;
`;

const PeriodTitle = styled.h3`
  font-size: 0.8rem;
  color: white;
`;
