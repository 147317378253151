import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PxButton from "../../../components/Buttons/PxButton";
import Body1Typo from "../../../components/Typhography/Body1Typo";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import CloseIcon from "@material-ui/icons/Close";
import useVideoContext from "../../hooks/useVideoContext";
import { LocalDataTrack } from "twilio-video";
import { HandsUp } from "../AppStateProvider/AppstateProvider";
import { useTranslation } from "react-i18next";
import useParticipants from "../../hooks/useParticipants";

export default () => {
  const {
    handsUp,
    setHandsUp,
    setAcceptHands,
    acceptHands,
    setWaitingForRequest,
    user,
  } = useAppState();
  const [viewList, setViewList] = useState<Boolean>(false);
  const { localTracks } = useVideoContext();
  const participants = useParticipants();
  const [t] = useTranslation("lang", { useSuspense: false });
  const {
    room: { localParticipant },
  } = useVideoContext();

  //참가자 손흔들기중 나갔을때
  useEffect(() => {
    if (
      participants &&
      acceptHands &&
      !participants.find((data) => data.sid === acceptHands?.sid) &&
      user?.role === "presenter"
    ) {
      sendFinishAcceptHands();
    }
  }, [participants]);

  //목록에서 손내리기 버튼
  const sendHandsDown = (userTrack: HandsUp) => {
    const dataTrack = localTracks.find(
      (track) => track.kind === "data"
    ) as LocalDataTrack;
    dataTrack.send(
      JSON.stringify({ type: "handsDown", handsDown: [userTrack.dataTrack.id] })
    );
    setHandsUp(
      handsUp.filter((e) => e.dataTrack.id !== userTrack.dataTrack.id)
    );
  };

  const sendHandsDownAll = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const dataTrack = localTracks.find(
      (track) => track.kind === "data"
    ) as LocalDataTrack;
    let ids: Array<string> = [];
    handsUp.forEach((data) => ids.push(data.dataTrack.id));
    dataTrack.send(JSON.stringify({ type: "handsDown", handsDown: ids }));
    setHandsUp([]);
  };

  //같이 방송하기
  const sendAcceptHands = (data: HandsUp) => {
    const dataTrack = localTracks.find(
      (track) => track.kind === "data"
    ) as LocalDataTrack;
    dataTrack.send(JSON.stringify({ type: "acceptHands", acceptHands: data }));
    setViewList(false);
    setHandsUp(handsUp.filter((e) => e.dataTrack.id !== data.dataTrack.id));
    setAcceptHands(
      Array.from(participants.values()!).find(
        (user) => user.sid === data.participant.sid
      )!
    );
  };

  //방송 연결 끝내기
  const sendFinishAcceptHands = () => {
    const dataTrack = localTracks.find(
      (track) => track.kind === "data"
    ) as LocalDataTrack;
    setAcceptHands(undefined);
    dataTrack.send(
      JSON.stringify({ type: "finishHandsUp", finishHandsUp: acceptHands?.sid })
    );
    if (acceptHands?.sid === localParticipant.sid) {
      setWaitingForRequest(false);
    }
  };

  return (
    <>
      {handsUp && handsUp.length > 0 && viewList && (
        <Container
          onClick={(e) => {
            setViewList(false);
          }}
        >
          <Box>
            <BoxNav>
              <Body1Typo txtcolor="white">함께 방송하기</Body1Typo>
              <PxButton
                txtColor="gray"
                backgroundcolor="transparent"
                hover={false}
                onClick={sendHandsDownAll}
              >
                전체 손내리기
              </PxButton>
            </BoxNav>
            {handsUp.map((data) => (
              <Item
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  sendAcceptHands(data);
                }}
              >
                <Body1Typo txtcolor="white">
                  {data.participant.identity}
                </Body1Typo>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    sendHandsDown(data);
                  }}
                />
              </Item>
            ))}
          </Box>
        </Container>
      )}

      <AlertContaienr>
        {acceptHands &&
          (user!.role === "presenter" ||
            localParticipant.sid === acceptHands!.sid) && (
            <AlertBox>
              <PxButton
                padding="0px"
                hover={false}
                backgroundcolor="transparent"
                onClick={() => sendFinishAcceptHands()}
                txtColor="lighterPurple"
              >
                연결 종료
              </PxButton>
              )
            </AlertBox>
          )}
        {handsUp &&
          handsUp.length > 0 &&
          !viewList &&
          user?.role === "presenter" && (
            <AlertBox>
              <>
                <Body1Typo txtcolor="white">
                  방송 요청을 한 사람이 있습니다.
                </Body1Typo>
                <PxButton
                  hover={false}
                  backgroundcolor="transparent"
                  onClick={() => setViewList(true)}
                  txtColor="lighterPurple"
                >
                  목록보기
                </PxButton>
              </>
            </AlertBox>
          )}
      </AlertContaienr>
    </>
  );
};
const AlertContaienr = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  z-index: 10;
`;

const AlertBox = styled.div`
  border-radius: 4px;
  max-width: 344px;
  background-color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  padding: 8px;
`;

const Container = styled.div`
  position: absolute;
  z-index: 10;
  width: calc(100vw + 260px);
  height: calc(100vh + 112px);
  left: -260px;
  top: -56px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 599px) {
    width: 100vw;
    height: 100vh;
    left: 0px;
  }
`;

const Box = styled.div`
  position: absolute;
  left: calc(50vw - 196px);
  flex-direction: column;
  display: flex;
  padding: 0px 16px;
  width: 392px;
  max-height: 480px;
  border-radius: 4px;
  background-color: rgb(18, 18, 18);
  transform: translateY(-50%);
`;

const Item = styled.div`
  width: 360px;
  height: 72px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const BoxNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
