import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Mic from "@material-ui/icons/Mic";
import MicOff from "@material-ui/icons/MicOff";
import Tooltip from "@material-ui/core/Tooltip";

import useLocalAudioToggle from "../../hooks/useLocalAudioToggle";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";
import { useAppState } from "../AppStateProvider/hooks/useAppState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
  })
);

export default function ToggleAudioButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { setSelfMute, selfMute, hostMute } = useAppState();
  const { t } = useTranslation("lang", { useSuspense: false });
  useEffect(() => {
    toggleAudioEnabled();
  }, [selfMute, hostMute]);

  const mute = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSelfMute(!selfMute);
  };

  return (
    <Tooltip
      title={selfMute ? "Mute Audio" : "Unmute Audio"}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        className={classes.fab}
        onClick={mute}
        disabled={props.disabled}
        data-cy-audio-toggle
      >
        <div>
          {selfMute ? <MicOff /> : <Mic />}
          <CaptionTypo txtcolor="white">
            {t("videoController.audio")}
          </CaptionTypo>
        </div>
      </Fab>
    </Tooltip>
  );
}
