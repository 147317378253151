import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Column } from "material-table";
import { useDispatch, useSelector } from "react-redux";

// types
import { MeetingInfo } from "../../../../types/models/Meeting";
import { AppState } from "../../../../store";

// custom Material-table comp
import PxTable from "../../../../components/Tables/PxTable";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import { useTranslation } from "react-i18next";
import SubEventHeading from "../../../../components/Typhography/SubEventHeading";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Body3Typo from "../../../../components/Typhography/Body3Typo";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
      },
      root: {
        boxShadow: "none",
      },
    },
    MuiToolbar: {
      root: {
        width: "100%",
        paddingLeft: 0,
        border: "none",
        boxShadow: "none",
        "& .MTableToolbar-title-494": {
          width: "100%",
        },
        "& .MTableToolbar-spacer-492": {
          display: "none",
        },
      },
      gutters: {
        paddingLeft: 0,
      },
      regular: {
        paddingLeft: 0,
      },
    },
    MuiTableCell: {
      root: {
        height: 72,
        paddingLeft: 8,
        borderBottom: "none",
      },
    },
  },
});

interface RowType {
  id: string; // application id
  subEventId: string; // 서브이벤트 id
  meetingStartTime: string; // 미팅 시작 시간
  meetingEndTime: string; // 미팅 끝나는 시간
  meetingDate: string; // 미팅 체결 날짜
  name: string; // 기업명
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

interface CounselListTableProps {
  tableRowData: MeetingInfo[];
}

export default function CounselListTable(props: CounselListTableProps) {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const [tableData, setTableData] = React.useState<TableStateType>({
    columns: [
      {
        field: "name",
        headerStyle: {
          paddingLeft: 0,
        },
        cellStyle: {
          paddingLeft: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        render: (rowData) => (
          <PxGridContainer direction="column">
            <Body3Typo fontWeight={600}>{rowData.name}</Body3Typo>
            <PxGridContainer direction="row" spacing={1}>
              <PxGridItem>
                <Body1Typo txtcolor="black0.6">
                  {rowData.meetingDate}{" "}
                </Body1Typo>
              </PxGridItem>
              •
              <PxGridItem>
                <Body1Typo txtcolor="black0.6">
                  {rowData.meetingStartTime}
                </Body1Typo>
              </PxGridItem>
            </PxGridContainer>
          </PxGridContainer>
        ),
      },
    ],
    data: [],
  });
  const { id } = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const dispatch = useDispatch();

  // 참가업체 리스트 data를 table에 알맞은 데이터로 가공
  useEffect(() => {
    setTableData({
      columns: tableData.columns,
      data: props.tableRowData.map((meeting) => ({
        id: meeting.id!,
        subEventId: meeting.subEventId!,
        meetingEndTime: meeting.endTime!,
        meetingStartTime: meeting.startTime!,
        meetingDate: meeting.date!,
        name:
          id !== meeting.acceptor
            ? meeting.acceptorName!
            : meeting.applicantName!, // or meeting.applicantName
      })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(setTableInfoLoadingAction(false));
  }, [props.tableRowData]);

  const tableRowOnClick = (
    event: React.MouseEvent<Element, MouseEvent> | undefined,
    selectedRow: RowType | undefined
  ) => {
    event?.preventDefault();
    history.push(
      `/subEvent/${selectedRow?.subEventId}/counselList/${selectedRow?.id}`
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <PxTable<RowType>
        title={
          <SubEventHeading>{t("subEventNavBar.counselList")}</SubEventHeading>
        }
        columns={tableData.columns}
        data={tableData.data}
        onRowClick={tableRowOnClick}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: true,
          header: false,
          defaultExpanded: false,
          searchFieldAlignment: "right",
          search: true,
          toolbar: true,
          rowStyle: {
            borderBottom: "none",
          },
        }}
      />
    </MuiThemeProvider>
  );
}
