import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import MaterialTable, { Column, MaterialTableProps } from "material-table";

// Icons
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider } from "@material-ui/core/styles";
import NoResultPage from "../NoResult/NoResultPage";
import CancelIcon from "@material-ui/icons/Cancel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { useLocation } from "react-router-dom";
import {
  setTableInfoAction,
  setTableInfoLoadingAction,
} from "../../actions/tableInfo";
import { debounce } from "lodash";
import Pagination from "../Pagination/Pagination";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import { isMobile } from "../../utils/utils";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { theme } from "../PxBreakpoint/PxBreakpoint";

const tableIcons = {
  Add: forwardRef<any>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<any>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<any>((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef<any>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef<any>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<any>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<any>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<any>((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef<any>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<any>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef<any>((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<any>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<any>((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef<any>((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef<any>((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};
export const customTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 0,
        [theme.breakpoints.down("lg")]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.down(600)]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.up(600)]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.down("md")]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.down("sm")]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
        },
      },
    },
  },
});

interface PxTableProps {
  getAction?: any;
  totalPage?: number;
  actionData?: any;
  totalElements?: number;
}

const PxTable = <T extends object>(
  props: MaterialTableProps<T> & PxTableProps
) => {
  const {
    columns,
    data,
    actions,
    options,
    isLoading,
    totalPage,
    actionData,
    title,
    getAction,
    totalElements,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const tableInfo = useSelector((state: AppState) => state.tableInfo); // 리덕스에 저장한 테이블 정보
  const [page, setPage] = useState<number>(0); // initial page number
  const [pageSize, setPageSize] = useState<number>(
    actionData && actionData.pageSize !== undefined ? 50 : 10
  ); // intial page Size
  const [search, setSearch] = useState<string>("");
  const [tableColumns, setTableColumns] = useState<Column<T>[]>(columns);
  const [selectRow, setSelectRow] = useState<boolean>(false);
  const tableRef = useRef<any>(null);
  // 테이블의 default setting (pageSize, pageNumber, default sorting)을 설정해줌
  useEffect(() => {
    if (tableInfo.name === location.pathname) {
      setPage(tableInfo.pageNum);
      if (!actionData.pageSize) setPageSize(tableInfo.pageSize);
      if (tableInfo.sorting !== undefined) {
        const newTableColumns = [...columns];
        newTableColumns[tableInfo.sorting.coulumnId].defaultSort =
          tableInfo.sorting.columnDirection;
        setTableColumns(newTableColumns);
      }
    }
  }, [tableInfo, location.pathname]);

  useEffect(() => {
    // page size 변경시 테이블 row수 변경
    if (tableRef && tableRef.current) {
      tableRef.current.dataManager.changePageSize(pageSize);
    }
  }, [pageSize]);

  const onChangeData = useCallback(
    async (page: number, pageSize: number, search: string) => {
      // 검색할때 , 페이지 사이즈 변경시 page = 0
      dispatch(
        setTableInfoAction({
          name: location.pathname,
          pageNum: page,
          pageSize: pageSize,
        })
      );

      if (getAction) {
        let result;
        dispatch(setTableInfoLoadingAction(true));

        if (options?.search) {
          // actionData = subEventId, type(seller,buyer)같은 정보
          result = actionData
            ? await dispatch(getAction(actionData, page, pageSize, search))
            : await dispatch(getAction(page, pageSize, search));
        } else {
          result = actionData
            ? await dispatch(getAction(actionData, page, pageSize))
            : await dispatch(getAction(page, pageSize));
        }
        (!result || result.content.length <= 0) &&
          dispatch(setTableInfoLoadingAction(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionData]
  );

  useEffect(() => {
    // delayedQuery(0, pageSize, search);
    if (actionData) {
      const pageNum =
        tableInfo.name !== location.pathname ||
        (actionData.type && actionData.type !== tableInfo?.type) ||
        (search && search !== tableInfo.search)
          ? 0
          : tableInfo.pageNum;
      const searchWord = tableInfo.search ? tableInfo.search : search;

      if (actionData.pageSize) {
        setPageSize(actionData.pageSize);
      }
      onChangeData(pageNum, pageSize, searchWord);
    } else {
      return delayedQuery.cancel;
    }
  }, [actionData]);

  const delayedQuery = useRef(debounce(onChangeData, 1000)).current;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTableInfoLoadingAction(true));
    setSearch(event.target.value);
    delayedQuery(0, pageSize, event.currentTarget.value);
    return delayedQuery.cancel;
  };

  return (
    <MuiThemeProvider theme={customTheme}>
      <StyledDiv footer={getAction && totalPage !== undefined ? false : true}>
        {getAction && options?.search && !selectRow ? (
          <TableHeader>
            <>
              {title}
              <Box />
            </>
            <SearchBox>
              <SearchInput
                id="searchRef"
                value={search}
                onChange={onChange}
                placeholder="Search"
              />

              <CancelIcon
                style={{ width: 18, cursor: "pointer" }}
                onClick={() => {
                  setSearch("");
                  onChangeData(0, pageSize, "");
                }}
              />
            </SearchBox>
          </TableHeader>
        ) : null}
        <MaterialTable
          tableRef={tableRef}
          localization={{
            toolbar: {
              nRowsSelected: "{0} item(s) selected",
            },
            body: {
              emptyDataSourceMessage: tableInfo.isLoading ? (
                <CircularProgress style={{ color: "rgb(95, 75, 139)" }} />
              ) : (
                <NoResultPage />
              ),
            },
          }}
          onSelectionChange={(data) => {
            if (data.length !== 0) {
              setSelectRow(true);
            } else {
              setSelectRow(false);
            }
          }}
          icons={tableIcons}
          title={title}
          columns={tableColumns}
          data={tableInfo.isLoading ? [] : data}
          actions={actions}
          options={{
            ...options,
            pageSize,
            pageSizeOptions: [5, 10, 15, 20],
            toolbar: getAction && options?.search ? selectRow : options?.search,
          }}
          {...rest}
        />
        {getAction && totalPage ? (
          <>
            <Pagination
              page={page}
              totalPage={totalPage}
              onChange={(e, page) => onChangeData(page - 1, pageSize, search)}
              pageSize={pageSize}
              totalElements={totalElements}
              onChangeSize={(e) => {
                setPageSize(e.target.value as number);
                onChangeData(0, e.target.value as number, search);
              }}
            />
            <Divider style={{ marginTop: 16 }} />
          </>
        ) : null}
      </StyledDiv>
    </MuiThemeProvider>
  );
};

export default PxTable;

const StyledDiv = styled.div<{ footer: boolean }>`
  & button:focus {
    outline: none !important;
  }
  & .MuiPaper-elevation2 {
    box-shadow: none;
  }
  width: 100%;
  & th.MuiTableCell-root {
    width: 0 !important;
  }
  & .MTableToolbar-searchField {
    width: 200px !important;
  }
  & table > tfoot > tr > td > div {
    display: ${(props) => (props.footer ? "" : "none")};
  }
`;

const TableHeader = styled.div`
  width: 100%;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 599px) {
    flex-direction: column;
    align-items: start;
    margin: 0px 0px 20px;
  }
`;

const SearchBox = styled.div`
  width: 240px;
  padding: 7px 12px 9px 16px;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 599px) {
    width: 100%;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  font-size: 14px;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
`;
