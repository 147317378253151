import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { getMyLikeList } from "../../actions/users";

// types
import { EventInfo } from "../../types/models/Event";

// material ui custom comp
import GridContainer from "../../components/Grid/PxGridContainer";
import GridItem from "../../components/Grid/PxGridItem";
import PxButton from "../../components/Buttons/PxButton";
import HeadlineTypo from "../../components/Typhography/HeadlineTypo";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import CardGridContainer from "../../components/Grid/CardGrid/CardGridContainer";
import { AppState } from "../../store";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
`;

const Empty = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;

const EventList = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const [page, setPage] = useState({ last: false, page: 0 });
  const [eventArr, setEventArr] = useState<EventInfo[]>([]);
  const history = useHistory();

  if (!localStorage.token) {
    history.push("/auth/login");
  }
  const eventList = useSelector((state: AppState) => state.users.likeList);
  useEffect(() => {
    if (eventList!.number === 0 && eventList!.content!.length <= 0) {
      dispatch(getMyLikeList());
    }
  }, [history, dispatch]);

  useEffect(() => {
    setEventArr([...eventList!.content!]);
    setPage({ page: eventList!.number!, last: eventList!.last! });
  }, [eventList]);

  const addEvent = async (data: EventInfo[]) => {
    const result: any = await dispatch(
      getMyLikeList(page.page + 1, true, data)
    );
    if (result) {
      setEventArr([...result.content!]);
      setPage({
        page: (page.page + 1) as number,
        last: result.last as boolean,
      });
    }
  };

  const moreEventBtn = async (
    e: React.MouseEvent<HTMLButtonElement> | React.FC
  ) => {
    addEvent(eventArr);
  };

  return (
    <Container>
      <GridContainer
        justify="space-between"
        alignItems="baseline"
        // parent={true}
      >
        <GridItem>
          <GridContainer direction="row">
            <GridItem className="pb-1">
              <HeadlineTypo>{t("likeList.likeList")}</HeadlineTypo>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Contents>
          {(eventList && eventArr.length <= 0) || eventList === undefined ? (
            <Empty>
              {t("likeList.emptyList")}
              <br />
            </Empty>
          ) : (
            <CardGridContainer eventList={eventArr}></CardGridContainer>
          )}
        </Contents>
      </GridContainer>

      <GridContainer justify="center" className="p-4">
        {!page.last && (
          <PxButton
            backgroundcolor="transparent"
            border={false}
            onClick={moreEventBtn}
          >
            <ButtonTypo txtcolor={"purple"}>{t("home.more")}</ButtonTypo>
          </PxButton>
        )}
      </GridContainer>
    </Container>
  );
};

export default EventList;
