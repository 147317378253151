import React, { useEffect, useState, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "../../../../store";
import throttle from "lodash/throttle";
import findIndex from "lodash/findIndex";
import axios from "axios";

// types

// @material-ui
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Autocomplete from "@material-ui/lab/Autocomplete";

// custom ui comp
import styled from "styled-components";

// icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PxOutlinedTxtField from "../../../../components/Inputs/PxOutlinedTxtField";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import TitleTypo from "../../../../components/Typhography/TitleTypo";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import MenuItem from "@material-ui/core/MenuItem";
import { countryPhoneNumberCode } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import { createManager } from "../../../../actions/manager";
import Manager from "../../../../types/models/Manager";
import PxLoadingButton from "../../../../components/Buttons/PxLoadingButton";
import PxBackDrop from "../../../../components/BackDrop/PxBackDrop";
import { setLoading, removeLoading } from "../../../../actions/loading";

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  arrowBackIcon: {
    cursor: "pointer",
    margin: 12,
    width: 24,
    height: 24,
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  titleTypo: {
    display: "inline-block",
    verticalAlign: "text-top",
  },
  managerAddInfoGridItem: {
    maxWidth: 552,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  countryCodeSelect: {
    display: "flex",
  },
  countryCodeInput: {
    padding: 10,
  },
  phoneNumberInput: {
    paddingLeft: 10,
  },
}));

// User type for autocomplete User list
interface UserType {
  id: string;
  company: string;
  position: string;
  countryNumber: string;
  email: string;
  name: string;
  phoneNumber: string;
}

// subtitle style
type TypoProp = TypographyProps;

const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ManagerAdd = () => {
  const classes = useStyles();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const { applicants, id } = useSelector(
    (state: AppState) => state.applications.subEventApplication
  ); // 담당자로 등록된 유저인지 확인용

  // 선택된 유저id
  const [userInfo, setUserInfo] = useState<UserType>({
    id: "",
    company: "",
    email: "",
    countryNumber: "82",
    phoneNumber: "",
    position: "",
    name: "",
  });

  // user search autocomplete input
  const [userEmailInput, setUserEmailInput] = useState<string>("");
  // 검색한 user 리스트
  const [userList, setUserList] = useState<UserType[]>([]);

  const cellPhoneRegExp = new RegExp("^\\d+$"); // 핸드폰 번호 입력 정규식 (숫자만 입력가능)

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    let name: string = e.target.name as string;
    let value: string = e.target.value as string;
    if (name === "phoneNumber") {
      const isValid = cellPhoneRegExp.test(value);
      if (isValid || value === "") {
        setUserInfo({ ...userInfo, [name]: value });
      }
    } else {
      setUserInfo({ ...userInfo, [name]: value });
    }
  };

  const handelUserEmailIdOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();
    setUserEmailInput(e.target.value as string);
    setUserInfo({ ...userInfo, email: e.target.value as string });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setLoading());
    const data: Manager = {
      name: userInfo.name,
      company: applicants[0].businessCard.company,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      position: userInfo.position,
      countryNumber: userInfo.countryNumber,
      applicationId: id!,
      subEventId: params.id,
    };

    const result: any = await dispatch(createManager(data));
    dispatch(removeLoading());
    if (result) {
      history.goBack();
    }
  };

  const getUserList = React.useMemo(
    () =>
      throttle(async (input: string) => {
        const result = await axios.get("/api/users/list", {
          params: { email: input },
        });

        if (result.data.length !== 0) {
          setUserInfo(result.data[0]);
        }
        // const userListData: UserType[] = result.data.map((user: UserType) => {
        //   return {
        //     id: user.id,
        //     name: user.name,
        //     position: user.position ?? "",
        //     email: user.email,
        //     company: user.company,
        //     countryNumber: user.countryNumber,
        //     phoneNumber: user.phoneNumber,
        //   };
        // });

        // const check: { id: string }[] = applicants.map((applicant) => ({
        //   id: applicant.businessCard[0].user!.id!.toString(),
        // }));

        // 이미 등록된 담당자 리스트 제거
        // const data = userListData.filter((user) => {
        //   if (findIndex(check, { id: user.id! }) === -1) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // });

        // setUserList(data);
      }, 1500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (
      userEmailInput.length > 2 &&
      /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/.test(
        userEmailInput
      ) === true
    ) {
      getUserList(userEmailInput);
    }
  }, [userEmailInput, getUserList]);

  return (
    <>
      <PxBackDrop />
      <PxGridItem marginTop={20}>
        <ArrowBackIcon
          className={classes.arrowBackIcon}
          onClick={() => history.goBack()}
        />
        &nbsp;&nbsp; | &nbsp;&nbsp;
        <TitleTypo className={classes.titleTypo} gutterBottom>
          {t("managerAdd.adding")}
        </TitleTypo>
      </PxGridItem>
      <MuiThemeProvider theme={theme}>
        <PxGridItem className={classes.managerAddInfoGridItem}>
          {/* 타이틀 */}

          <form onSubmit={onSubmit}>
            {/* 이메일 */}
            <SubTitle>{t("managerAdd.email")}</SubTitle>
            <PxOutlinedTxtField
              name="email"
              value={userEmailInput}
              fullWidth
              onChange={handelUserEmailIdOnChange}
              inputpadding={10}
            />

            {/* Autocomplete 이메일 검색 */}
            {/* <Autocomplete
            options={userList}
            getOptionLabel={(option) => option.email}
            getOptionSelected={(option, value) => {
              return option.email === value.email;
            }}
            onChange={(event, value) => {
              if (value !== null) {
                setUserInfo(value);
              }
            }}
            renderInput={(params) => (
              <PxOutlinedTxtField
                {...params}
                name="email"
                value={userEmailInput}
                fullWidth
                onChange={handelUserEmailIdOnChange}
                placeholder="유저 이메일을 입력 후 목록에서 담당자로 지정할 사람을 선택해주세요."
                InputProps={{
                  ...params.InputProps,
                  style: { padding: 3 },
                }}
                inputProps={{
                  ...params.inputProps,
                  style: { paddingLeft: 10 },
                }}
              />
            )}
          /> */}

            {/* 이름 */}
            <SubTitle>{t("managerAdd.name")}</SubTitle>
            <PxOutlinedTxtField
              name="name"
              value={userInfo.name}
              fullWidth
              onChange={handleOnChange}
              inputpadding={10}
            />
            {/* 기업명 */}
            {/* <SubTitle>기업명</SubTitle>
          <PxOutlinedTxtField            
            name="company"
            value={
              applicants.length !== 0
                ? applicants[0].businessCard[0].company
                : ""
            }
            fullWidth
            // onChange={handleOnChange}
            inputpadding={10}
            placeholder="기업명을 입력해주세요"
          /> */}
            {/* 직함 */}
            {/* <SubTitle>{t("managerAdd.position")}</SubTitle>
            <PxOutlinedTxtField
              name="position"
              value={userInfo.position}
              fullWidth
              onChange={handleOnChange}
              inputpadding={10}
            /> */}

            {/* 휴대번호 */}
            <SubTitle>{t("managerAdd.phoneNumber")}</SubTitle>
            <PxOutlinedFormControl fullWidth>
              <PxGridContainer direction="row">
                <PxGridItem xs={3} md={3}>
                  <PxSelectBox
                    native
                    className={classes.countryCodeSelect}
                    value={userInfo.countryNumber}
                    onChange={handleOnChange}
                    displayEmpty
                    inputProps={{
                      className: classes.countryCodeInput,
                      name: "countryNumber",
                    }}
                  >
                    <option value="" disabled>
                      {i18n.language === "ko" ? "국가코드" : "Country Code"}
                    </option>
                    {countryPhoneNumberCode.map((countryCode) => (
                      <option value={countryCode.code}>
                        {i18n.language === "ko"
                          ? countryCode.country
                          : countryCode.countryEn}
                      </option>
                    ))}
                  </PxSelectBox>
                </PxGridItem>
                <PxGridItem md={9} xs={9} className={classes.phoneNumberInput}>
                  <PxOutlinedTxtField
                    name="phoneNumber"
                    value={userInfo.phoneNumber}
                    fullWidth
                    inputpadding={10}
                    onChange={handleOnChange}
                    placeholder={t("mangerAdd.typePhoneNumber")}
                  />
                </PxGridItem>
              </PxGridContainer>
            </PxOutlinedFormControl>
            {/* 등록버튼 */}
            <Grid
              container
              alignContent="space-between"
              spacing={1}
              style={{ justifyContent: "flex-end", marginTop: "32px" }}
            >
              <Grid item>
                <PxButton
                  padding="12px 47px 15px 47px"
                  border={false}
                  backgroundcolor="transparent"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ButtonTypo>{t("managerAdd.cancel")}</ButtonTypo>
                </PxButton>
              </Grid>
              <Grid item>
                <PxButton
                  boxShadow={false}
                  padding="12px 47px 15px 47px"
                  backgroundcolor="bluePurple"
                  type="submit"
                >
                  <ButtonTypo txtcolor="white">
                    {t("managerAdd.save")}
                  </ButtonTypo>
                </PxButton>
              </Grid>
            </Grid>
          </form>
        </PxGridItem>
      </MuiThemeProvider>
    </>
  );
};

export default ManagerAdd;
