import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { AppState, AppActions } from "./store";
import setAuthToken, { deleteAuthToken } from "./utils/setAuthToken";
import { ThunkDispatch } from "redux-thunk";
import { getUser } from "./actions/users";
import { bindActionCreators } from "redux";
import { LastLocationProvider } from "react-router-last-location";
import CacheBuster from "./CacheBuster";
import TagManager from "react-gtm-module";

// types
import User from "./types/models/User";

// layouts
import Video from "./video";
import Main from "./layouts/Main";
import SubEvent from "./layouts/SubEvent";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Manager from "./views/auth/manager/Manager";
import InterpreterSubEvent from "./layouts/InterpreterSubEvent";
import { setCountry } from "./actions/country";
import { connectionCountry } from "./utils/momentTz";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./components/Scrolls/ScrollToTop";
import PxBreakpoints from "./components/PxBreakpoint/PxBreakpoint";
import ChatProvider from "./video/components/ChatProvider/ChatProvider";

import EventHomepage from "./views/EventHompage";
import ChimeVideo from "./videoMeeting/App";
import { DeviceSetup } from "./videoMeeting/views";
import jwtDecode, { JwtPayload } from "jwt-decode";

// 로그인 정보를 불러오기 위해 localStorage값에 저장된 token값과 userId값 확인 // 담당자초대 화면에서는 예외
if (localStorage.token && !window.location.pathname.includes("managerRegit")) {
  setAuthToken(localStorage.token, localStorage.userId);
}

type Props = reduxStateProps & DispatchProps;

const App: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { getUser } = props;
  const { i18n } = useTranslation("lang", { useSuspense: false });
  const { isAuthenticated } = useSelector((state: AppState) => state.users);
  const history = useHistory();

  // local storage 값이 있으면 유저정보를 불러옴
  useEffect(() => {
    if (localStorage.token) {
      // 담당자초대 화면에서는 예외
      if (!window.location.pathname.includes("managerRegit")) {
        isTokenExpired(localStorage.token);
        getLoggedUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser]);

  const isTokenExpired = (token: string) => {
    try {
      const decodedToken = jwtDecode<JwtPayload>(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken && decodedToken.exp && decodedToken.exp < currentTime) {
        deleteAuthToken();
        window.location.href = "/auth/login";
      }
    } catch (error) {
      // console.log(` error : `, error);
      window.location.href = "/auth/login";
    }
  };

  const getLoggedUser = async () => {
    const result: any = await getUser(localStorage.userId);
    // token expired 경우 혹은 다른 에러 발생 시, token 삭제 후 refresh
    if (result === false) {
      deleteAuthToken();
      window.location.reload();
    } else {
      defaultLanguageSet(result?.countryNumber);
    }
  };

  // 접속시 기본 언어 설정
  const defaultLanguageSet = async (code?: number) => {
    const lang = new URLSearchParams(window.location.search).get("lang");
    let defaultLanguage = "ko";
    if (lang) {
      defaultLanguage = lang === "ko" ? "ko" : "en";
    } else if (!lang && code) {
      defaultLanguage = code === 82 ? "ko" : "en";
    }
    localStorage.setItem("language", defaultLanguage); // }

    i18n.changeLanguage(defaultLanguage);
  };

  useEffect(() => {
    defaultLanguageSet();
    dispatch(setCountry());
    // google tag manager setting
    gtmHeaderSetting();
    // languageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gtmHeaderSetting = () => {
    const tagManagerArgs = {
      gtmId: "GTM-MZKTQR3T",
    };

    TagManager.initialize(tagManagerArgs);
  };

  return (
    <CacheBuster>
      <BrowserRouter basename="/">
        <LastLocationProvider>
          <ScrollToTop />
          <PxBreakpoints>
            <ChatProvider>
              <Switch>
                <Route
                  path="/videoMeeting"
                  component={ChimeVideo}
                  isAuthenticated={!isAuthenticated}
                />
                <Route
                  path="/direct/device"
                  component={DeviceSetup}
                  isAuthenticated={!isAuthenticated}
                />
                <Route path="/direct/:id" component={ChimeVideo} />
                <Route path="/managerRegit/:id" component={Manager} />
                <Route
                  path="/homepage/:eventId/:templateName"
                  component={EventHomepage}
                />
                <PrivateRoute
                  path="/video"
                  component={Video}
                  isAuthenticated={isAuthenticated!}
                />

                <PrivateRoute
                  path="/subEvent/:id"
                  render={(props) => <SubEvent {...props} />}
                  isAuthenticated={isAuthenticated!}
                />
                <PrivateRoute
                  path="/interpreter/:id"
                  render={(props) => <InterpreterSubEvent {...props} />}
                  isAuthenticated={isAuthenticated!}
                />
                <Route path="/" render={(props) => <Main {...props} />} />
              </Switch>
            </ChatProvider>
          </PxBreakpoints>
        </LastLocationProvider>
      </BrowserRouter>
    </CacheBuster>
  );
};

interface reduxStateProps {
  users: User;
}

const mapStateToProps = (state: AppState) => ({
  users: state.users,
});

interface DispatchProps {
  getUser: (userId: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): DispatchProps => ({
  getUser: bindActionCreators(getUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
