import { Interpreter } from "../types/models/Interpreter";
import InterpreterActionTypes from "../types/actions/InterpreterAction";

export interface InterpreterReduxStateType {
  interpreters: Interpreter[];
  interpreter?: Interpreter;
}

const interpreterDefaultState: InterpreterReduxStateType = {
  interpreters: [],
};

const interpreterReducer = (
  state = interpreterDefaultState,
  action: InterpreterActionTypes
): InterpreterReduxStateType => {
  switch (action.type) {
    case "GET_INTERPRETER":
      return { ...state, interpreter: action.interpreter };
    case "GET_INTERPRETERS":
      return { ...state, interpreters: action.interpreters };
    case "REMOVE_INTERPRETER":
      return { ...state, interpreter: undefined };
    case "REMOVE_INTERPRETERS":
      return { ...state, interpreters: [] };
    default:
      return state;
  }
};

export default interpreterReducer;
