import React, { MouseEvent, useEffect, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Participant } from "twilio-video";
import styled from "styled-components";
import PinIcon from "../../../assets/icons/pinIcon.svg";
import ButtonTypo from "../../../components/Typhography/ButtonTypo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      // height: `${(theme.sidebarWidth * 9) / 16}px`,
      overflow: "hidden",
      cursor: "pointer",
      "& video": {
        filter: "none",
      },
      "& svg": {
        stroke: "black",
        strokeWidth: "0.8px",
      },
      [theme.breakpoints.down("xs")]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: "3px",
        fontSize: "10px",
      },
    },
    isVideoSwitchedOff: {
      "& video": {
        filter: "blur(4px) grayscale(1) brightness(0.5)",
      },
    },
    infoContainer: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      padding: "0.4em",
      width: "100%",
      background: "transparent",
    },
    hideVideo: {
      background: "black",
    },
    identity: {
      background: "rgba(0, 0, 0, 0.7)",
      padding: "0.1em 0.3em",
      margin: 0,
      display: "flex",
      alignItems: "center",
    },
    infoRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    participantsScreenInfoRow: {
      // background: "rgba(0, 0, 0, 0.7)",
    },
    screenShareIcon: {
      color: "floralwhite",
    },
    videoCamOffIcon: {
      color: "floralwhite",
    },
    pinIcon: {
      width: 18,
      height: 18,
      transform: "translateY(2px)",
    },
  })
);

const Container = styled.div`
  width: 100%;
  padding: 13px 8px;
  background-color: rgba(0, 0, 0, 0.87);
  display: flex;
  margin-bottom: 5px;
  max-height: 138px;
`;
const IconItem = styled.div`
  position: absolute;
  right: 16px;
  color: white;
  transform: translateY(-6px);
`;

interface ParticipantInfoProps {
  participant: Participant;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  isSelected: boolean;
  children?: React.ReactNode;
}

export default function ParticipantInfoForManyName({
  participant,
  isSelected,
  onClick,
  children,
}: ParticipantInfoProps) {
  const classes = useStyles();
  const [pariticipantName, setPariticipantName] = useState<string>("");

  useEffect(() => {
    if (participant !== undefined) {
      const userName = participant.identity.split("|")[0];
      setPariticipantName(userName);
    }
  }, [participant]);

  return (
    <div
      onClick={onClick}
      className={clsx(classes.container, {
        [classes.isVideoSwitchedOff]: false,
      })}
      data-cy-participant={participant.identity}
    >
      <Container>
        <ButtonTypo txtcolor="white">{pariticipantName}</ButtonTypo>
        <IconItem>
          {isSelected && (
            <img src={PinIcon} alt="" className={classes.pinIcon} />
          )}
        </IconItem>
      </Container>
      {children}
    </div>
  );
}
