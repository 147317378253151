import React, { useState, useEffect, useCallback } from "react";

import { GOOGLE_API_KEY } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import PxCheckBox from "../../components/Checkboxes/PxCheckBox";
import PxRadioButton from "../../components/Radios/PxRadioButton";

import { categoryAndCheckboxSelectTextToLinkAndText } from "../../utils/utils";

const googleTranslate = require("google-translate")(GOOGLE_API_KEY);

interface TranslatedFormItemProps {
  type: "input" | "textField" | "radio" | "checkbox" | "string";
  content: string;
  [propsName: string]: {};
  link: boolean;
  onlyEng: boolean;

  // readonly?: boolean;
}

const TranslatedFormItem = ({
  type,
  content,
  link,
  onlyEng,
  ...rest
}: TranslatedFormItemProps) => {
  const [text, setText] = useState<string>("");
  const [t, i18n] = useTranslation("lang", { useSuspense: false });

  useEffect(() => {
    // 영어만 입력가능 할때
    if (content !== "" && onlyEng) {
      googleTranslate.translate(content, "en", (err: any, translation: any) => {
        if (err == null) {
          setText(translation.translatedText);
        } else {
          setText(content);
        }
      });
    } else if (content !== "" && i18n.language === "cn") {
      googleTranslate.translate(
        content,
        "zh-cn",
        (err: any, translation: any) => {
          if (err == null) {
            setText(translation.translatedText);
          } else {
            setText(content);
          }
        }
      );
    } else if (content !== "" && i18n.language !== "ko") {
      googleTranslate.translate(
        content,
        i18n.language,
        (err: any, translation: any) => {
          if (err == null) {
            setText(translation.translatedText);
          } else {
            setText(content);
          }
        }
      );
    } else {
      setText(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, i18n.language]);

  const changeCategoryAndCheckboxSelectTextToLink = useCallback(
    (text: string) => {
      const linkTextStyle: React.CSSProperties = {
        textDecoration: "underline",
        fontWeight: "bold",
        color: "blue",
        cursor: "pointer",
      };

      // 신청서 양식에서 type의 input: 질문, string: 설명
      const generalTextStyle: React.CSSProperties = {
        whiteSpace: "pre-line",
        fontWeight: type === "input" ? 500 : "normal",
        fontSize: type === "input" ? 15 : 14,
      };

      const splitedText = categoryAndCheckboxSelectTextToLinkAndText(
        text !== undefined ? text : ""
      );

      let resultText;

      if (typeof splitedText === "string") {
        resultText = splitedText;
      } else {
        if (link) {
          resultText = (
            <>
              {splitedText.priorText}
              <span
                style={linkTextStyle}
                onClick={(event) => {
                  window.open(splitedText.link, "_blank");
                }}
              >
                {splitedText.linkText}
              </span>
              {splitedText.endText}
            </>
          );
        } else {
          resultText =
            splitedText.priorText + splitedText.linkText + splitedText.endText;
        }
      }

      return <div style={generalTextStyle}>{resultText}</div>;
    },
    [link, type]
  );

  return (
    <>
      {type === "string" && changeCategoryAndCheckboxSelectTextToLink(text)}
      {type === "checkbox" && (
        <PxCheckBox
          label={changeCategoryAndCheckboxSelectTextToLink(text)}
          {...rest}
        />
      )}
      {
        type === "input" && changeCategoryAndCheckboxSelectTextToLink(text)
        // <PxInputBase value={text} {...rest} />
      }
      {type === "radio" && (
        <PxRadioButton
          label={changeCategoryAndCheckboxSelectTextToLink(text)}
          {...rest}
        />
      )}
    </>
  );
};

export default TranslatedFormItem;
