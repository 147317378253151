import { AppActions } from "../store";
import { ChatRoom } from "../types/models/Chat";
import { postRequest } from "../utils/utils";

export const getChatRoomListAction = (chatRooms: ChatRoom[]): AppActions => {
  return {
    type: "GET_CHAT_ROOMS",
    chatRooms: chatRooms,
  };
};

export const resetChatRoomListAction = (): AppActions => {
  return {
    type: "RESET_CHAT_ROOMS",
  };
};

export const setChatRoomsUnreadMessageCountAction = (
  chatRooms: ChatRoom[]
): AppActions => {
  return {
    type: "SET_CHAT_ROOMS_UNREAD_MESSAGE_COUNT",
    chatRooms: chatRooms,
  };
};

export const getChatRoomList = (
  ids: string[],
  unreadMessageCounts: number[]
) => {
  return postRequest(
    { ids: ids, unreadMessageCounts: unreadMessageCounts },
    "/api/businessCards/chatRooms",
    getChatRoomListAction
  );
};
