import React, { useEffect, useState, createContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import groupBy from "lodash/groupBy";
import { sortBy } from "lodash";

// types
import { AppState } from "../../../store";
import { MeetingInfo } from "../../../types/models/Meeting";

// comp
import Video from "../../../video";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../components/Grid/PxGridItem";
import MeetingTimeCard from "./MeetingTimeCard";
import { makeStyles } from "@material-ui/core/styles";
import PxCalendar from "../../../components/PxCalendar/PxCalendar";
import moment from "moment";
import { parallaxCalculation } from "../../../utils/momentTz";

interface MeetingContextType {
  videoDialogOpen: boolean;
  setVideoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MeetingContext = createContext<MeetingContextType>(null!);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  gridItemContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
  },
}));

const MeetingDetail = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string; secondId: string }>();
  const [meetingTimeLength, setMeetingTimeLength] = useState<any>();
  const [date, setDate] = useState<moment.Moment>(() => moment());
  const [selectableDate, setSelectableDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [meetingData, setMeetingData] = useState<
    { [date: string]: MeetingInfo[] } | undefined
  >();
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  // video Call dialog
  const [videoDialogOpen, setVideoDialogOpen] = useState<boolean>(false);

  const { interpreter } = useSelector((state: AppState) => state.interpreters);

  //달력선택 기간 설정
  useEffect(() => {
    if (subEvent) {
      const countryCode = subEvent!.mainEvent?.countryCode;
      const countMinute =
        subEvent.matchNumPerDay! *
          (subEvent.matchReqTime! + subEvent.matchBreakTime!) -
        subEvent.matchBreakTime!;
      // 미팅시작 기준 날짜
      const defaultDate = `${subEvent!.subEventEndDate.split("T")[0]}T${
        subEvent.matchStartTime
      }:00`;
      //미팅 마지막 시간 계산
      const endTimeCalculation = moment(defaultDate).add(countMinute, "m");

      const startDate = parallaxCalculation(
        `${subEvent!.subEventStartDate.split("T")[0]}T${
          subEvent.matchStartTime
        }:00`,
        countries![countryCode! as string] as any,
        countries![country!.countryCode!] as any,
        "YYYY-MM-DD"
      );

      // 국가 시간 변경시 마지막 미팅시간이 다음날로 넘어갈 경우가 있음
      // 마지막 미팅시간 구해서 계산해줘야함
      const endDate = parallaxCalculation(
        endTimeCalculation.format("YYYY-MM-DDTHH:mm"),
        countries![countryCode! as string] as any,
        countries![country!.countryCode!] as any,
        "YYYY-MM-DD"
      );
      setSelectableDate({ startDate, endDate });
    }
  }, [subEvent]);
  useEffect(() => {
    if (interpreter && interpreter.meetingIds) {
      // 주 첫번째일 주 마지막일 가져와서
      // meetingIds.filter (시작일 마지막일 안에 있는 값들만 가져오기)
      const serverDate = moment(
        parallaxCalculation(
          date.format("YYYY-MM-DD"),
          countries![country!.countryCode!] as any,
          "",
          "YYYY-MM-DD"
        )
      );
      const startWeekDate = serverDate.clone().startOf("week");
      const endWeekDate = serverDate.clone().endOf("week");
      const meetingIds = interpreter.meetingIds.filter((meetingId) =>
        moment(meetingId.date).isBetween(
          startWeekDate,
          endWeekDate,
          undefined,
          "[]"
        )
      );

      const meetingsGroupByDate = groupBy(meetingIds, "date");
      setMeetingTimeLength(12 / Object.keys(meetingsGroupByDate).length); // griditem 사이즈 지정을 위한 length 지정
      setMeetingData(meetingsGroupByDate); // groupby 형태 ex) {2020-08-05: Array(1), 2020-08-06: Array(1), 2020-08-07: Array(1)}
    }
  }, [interpreter, date]);

  return (
    <>
      <div>
        <PxCalendar
          date={date}
          setDate={setDate}
          meetings={interpreter?.meetingIds}
          // selectableStartDate={selectableDate.startDate}
          // selectableEndDate={selectableDate.endDate}
        />
      </div>
      <PxGridContainer className={classes.root} direction="row">
        {videoDialogOpen === true ? (
          <Video
            videoDialogOpen={videoDialogOpen}
            setVideoDialogOpen={setVideoDialogOpen}
          />
        ) : null}

        <MeetingContext.Provider
          value={{
            videoDialogOpen: videoDialogOpen,
            setVideoDialogOpen: setVideoDialogOpen,
          }}
        >
          {meetingData !== undefined &&
            // console.log(`meetingData : `,meetingData);
            Object.keys(meetingData)
              .sort()
              .map((key, index) => {
                return (
                  <PxGridItem md={meetingTimeLength}>
                    <div className={classes.gridItemContent}>
                      {key}
                      {meetingData[key]
                        .sort((a, b) =>
                          a.startTime!.localeCompare(b.startTime!)
                        )
                        .map((meeting, index) => {
                          return (
                            <MeetingTimeCard
                              key={`meetingCard-${index}`}
                              id={meeting.id!}
                              date={meeting.date!}
                              meetingId={meeting.meetingId!}
                              subEventId={id}
                              company1Name={meeting.applicantName!}
                              company1Type={meeting.applicantType!}
                              company2Name={meeting.acceptorName!}
                              company2Type={meeting.acceptorType!}
                              startTime={meeting.startTime!}
                              endTime={meeting.endTime!}
                              zoomLink={meeting.meetingZoomLink?.link}
                            />
                          );
                        })}
                    </div>
                  </PxGridItem>
                );
              })}
        </MeetingContext.Provider>
      </PxGridContainer>
    </>
  );
};

export default MeetingDetail;
