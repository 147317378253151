import React, { useEffect, useMemo } from "react";
import { AppState } from "../../../store";
import { useSelector } from "react-redux";

import styled from "styled-components";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import Typography from "@material-ui/core/Typography";
import { SubEventInfo } from "../../../types/models/SubEvent";

const RootDiv = styled.div`
  img {
    max-width: 100%;
  }
`;

const EventInfo = () => {
  const interpreter = useSelector(
    (state: AppState) => state.interpreters.interpreter
  );

  const rootStyles = useMemo(() => {
    return {
      marginTop: 20,
      paddingLeft: 24,
      maxWidth: 1000,
    };
  }, []);

  return interpreter?.subEventId !== undefined ? (
    <PxGridContainer direction="column" style={rootStyles}>
      <Typography variant="h6" gutterBottom>
        이벤트 개요
      </Typography>
      <RootDiv
        dangerouslySetInnerHTML={{
          __html: (interpreter.subEventId! as SubEventInfo).mainEvent!.content,
        }}
      />
    </PxGridContainer>
  ) : null;
};

export default EventInfo;
