import React, { useState } from "react";
import styled from "styled-components";
import touchDirection from "../../assets/icons/touch-direction.svg";
import Body1Typo from "../Typhography/Body1Typo";
import Tip from "../../assets/icons/tip.svg";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

const TipIcon = styled.div`
  &::before {
    content: " ";
    background-image: url(${Tip});
    background-color: transparent;
    width: 30px;
    height: 30px;
    position: absolute;
    transform: translate(-100%, -70%);
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: -47px;
  height: 0px;
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 55px);
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export default () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const [view, setView] = useState(
    JSON.parse(window.localStorage.getItem("meetingTip") || "false")
  );
  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    window.localStorage.setItem("meetingTip", JSON.stringify(true));
    setView(true);
  };

  return !view ? (
    <Sticky>
      <Container onClick={onClick}>
        <CloseBtn>
          <CloseIcon style={{ color: "white", fontSize: "1.8rem" }} />
        </CloseBtn>
        <img src={touchDirection} />
        <Box height="19px" />
        <Body1Typo txtcolor="white">
          <TipIcon />
          {t("tips.meeting1")}
        </Body1Typo>
        <Body1Typo txtcolor="white">{t("tips.meeting2")}</Body1Typo>
      </Container>
    </Sticky>
  ) : null;
};
