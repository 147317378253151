import React from "react";
import { AppState } from "../../../store";
import { useSelector } from "react-redux";

import styled from "styled-components";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import Typography from "@material-ui/core/Typography";
import { isMobile } from "../../../utils/utils";

const RootDiv = styled.div`
  img {
    max-width: 100%;
  }
`;

const EventInfo = () => {
  const { mainEvent } = useSelector(
    (state: AppState) => state.subEvents.subEvent!
  );

  return mainEvent !== undefined ? (
    <PxGridContainer
      direction="column"
      style={{
        marginTop: 20,
        paddingLeft: isMobile ? 0 : 24,
        maxWidth: 1000,
      }}
    >
      <Typography variant="h6" gutterBottom>
        이벤트 개요
      </Typography>
      <RootDiv dangerouslySetInnerHTML={{ __html: mainEvent.content }} />
    </PxGridContainer>
  ) : null;
};

export default EventInfo;
