import { useCallback } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { parallaxCalculation } from "../utils/momentTz";

export default function useCalcTime() {
  const countryCode = useSelector(
    (state: AppState) => state.subEvents.subEvent?.mainEvent?.countryCode
  );
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );

  const calcTimeByTimezone = useCallback(
    (time: string, format: "YYYY-MM-DD HH:mm" | "YYYY-MM-DD") => {
      if (countries && country) {
        const calculatedTime = parallaxCalculation(
          time,
          countries[countryCode as string] as any,
          countries[country.countryCode!] as any,
          format
        );

        return calculatedTime;
      }
    },
    [countries, country, countryCode]
  );

  return [calcTimeByTimezone] as const;
}
