import React, { useEffect, useState, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { AppState } from "../../../store";
import moment from "moment";

import ManagerRegister from "./ManagerRegister";
import ManagerLogin from "./ManagerLogin";

// actions
import { getManager } from "../../../actions/manager";

// logo image
import PexpoLogo from "../../../assets/images/logo.png";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import SubHeadingTypo from "../../../components/Typhography/SubHeadingTypo";
import Body1Typo from "../../../components/Typhography/Body1Typo";
import { getSubEvent } from "../../../actions/subEvents";
import { changeImgPathToCloudfrontPath } from "../../../utils/utils";

interface ManagerContextType {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

export const ManagerContext = createContext<ManagerContextType>(null!);

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      margin: "0px 20px",
    },
  },
  cardImage: {
    width: 200,
    maxHeight: 125,
    objectFit: "fill",
    [theme.breakpoints.down("xs")]: {
      width: 120,
      maxHeight: 110,
    },
  },
  cardDetails: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

const Manager: React.FC = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>(); // id: subEventId

  const [activeStep, setActiveStep] = useState<number>(null!);

  const manager = useSelector((state: AppState) => state.managers.manager);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  useEffect(() => {
    dispatch(getManager(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (manager.userExist === "N") {
      setActiveStep(0);
    } else {
      setActiveStep(1);
    }
    if (manager.subEventId !== "") {
      dispatch(getSubEvent(manager.subEventId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager]);

  return (
    <ManagerContext.Provider
      value={{
        setActiveStep: setActiveStep,
      }}
    >
      <Container maxWidth="xs">
        {/* 로고 */}
        <PxGridContainer justify="center">
          <div style={{ padding: "30px 0" }}>
            <Link to="/">
              <img
                src={PexpoLogo}
                width="100"
                height="33"
                className="d-inline-block align-top"
                alt="Pexpo logo"
              />
            </Link>
          </div>
          {/* 이벤트 배너 */}
          <Card className={classes.cardRoot}>
            <CardMedia
              component="img"
              className={classes.cardImage}
              image={
                subEvent?.mainEvent?.mainImageFile === null ||
                subEvent?.mainEvent?.mainImageFile === undefined
                  ? changeImgPathToCloudfrontPath(subEvent?.mainEvent?.image)
                  : changeImgPathToCloudfrontPath(
                      subEvent?.mainEvent?.mainImageFile.filePath
                    )
              }
            />
            <div className={classes.cardDetails}>
              <CardContent>
                <SubHeadingTypo fontweight="bold">
                  {subEvent?.mainEvent?.name}
                </SubHeadingTypo>
                <Body1Typo txtcolor="secondary">
                  {`${moment(subEvent?.mainEvent?.startDate).format(
                    "yyyy-MM-DD"
                  )} ~ ${moment(subEvent?.mainEvent?.endDate).format(
                    "yyyy-MM-DD"
                  )}`}
                </Body1Typo>
              </CardContent>
            </div>
          </Card>
          {activeStep === 0 && <ManagerRegister />}
          {activeStep === 1 && <ManagerLogin />}
        </PxGridContainer>
      </Container>
    </ManagerContext.Provider>
  );
};

export default Manager;
