import React from "react";

import styled from "styled-components";
import mainBannerVideo from "../../../assets/images/main-banner.mp4";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PxButton from "../../../components/Buttons/PxButton";
import { Box } from "@material-ui/core";

const TopContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(81, 65, 153, 0.6);
  @media only screen and (max-width: 599px) {
    height: 200px;
  }
`;

const MainVideo = styled.video`
  height: 100%;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  @media only screen and (max-width: 700px) {
    font-size: 1.625em;
    line-height: 1.23;
  }
`;

const MainText = styled.p`
  width: 100%;
  min-width: 257px;
  /* padding-top: ${localStorage.token ? "154px" : "114px"}; */
  margin-bottom: 0px;
  font-size: 2.75rem;
  text-align: center;
  color: #ffffff;
  font-family: "Source Serif Pro" !important;
  word-break: keep-all;
  @media only screen and (max-width: 599px) {
    font-size: 1.75rem;
    line-height: 1.23;
  }
`;

const SubText = styled.p`
  width: 100%;
  min-width: 244px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.5rem;
  text-align: center;
  word-break: keep-all;
  @media only screen and (max-width: 599px) {
    font-size: 0.9375rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  @media only screen and (max-width: 599px) {
    display: none;
  }
`;

const HomeTopView = () => {
  const { t, i18n } = useTranslation("lang", { useSuspense: false });
  const history = useHistory();

  return (
    <>
      <TopContainer>
        <MainVideo
          width="100%"
          height="auto"
          playsInline
          autoPlay
          loop
          muted
          controls={false}
        >
          <source src={mainBannerVideo} type="video/mp4" />
        </MainVideo>
        <div>
          <MainText>Flexible and Powerful features</MainText>
          <SubText>{t("home.mainText")}</SubText>
          {!localStorage.token && (
            <ButtonContainer>
              <Box width={153}>
                <PxButton
                  fullWidth
                  backgroundcolor="white87"
                  txtColor="bluePurple"
                  boxShadow={false}
                  onClick={() => history.push("/auth/login")}
                >
                  {t("login.login")}
                </PxButton>
              </Box>
              <Box width={8} />
              <Box width={153}>
                <PxButton
                  fullWidth
                  boxShadow={false}
                  txtColor="white87"
                  backgroundcolor="transparent"
                  border={true}
                  onClick={() => history.push("/auth/register")}
                >
                  {t("home.signUp")}
                </PxButton>
              </Box>
            </ButtonContainer>
          )}
        </div>
      </TopContainer>
      {/* {advertisings.length === 0 ? (
            <TopContainer />
          ) : (
            <Carousel
              autoPlay
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
            >
              {advertisings.map((advertising, index) => {
                return (
                  <div
                    key={index}
                    className={classes.imageDiv}
                    onClick={() => {
                      if (advertisings.length !== 0) {
                        history.push(
                          `/event/detail/${advertisings[index].event.id}`
                        );
                      }
                    }}
                  >
                    <ImgContainer
                    // alt={`pexpo advertising ${index}`}
                    // src={advertising.event.mainImageFile.filePath}
                    />
                  </div>
                );
              })}
            </Carousel>
          )} */}

      {/* <Hidden xsDown> */}
      {/* <GridItem md={4} xs={12} style={isMobile ? { marginTop: 5 } : {}}>
          <TabView />
        </GridItem> */}
      {/* </Hidden> */}
    </>
  );
};

export default HomeTopView;
