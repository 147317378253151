import React from "react";
import styled from "styled-components";

import Select, { SelectProps } from "@material-ui/core/Select";

interface PxSelectBoxProps extends SelectProps {}

const PxSelectBox: React.FC<PxSelectBoxProps> = styled(
  (props: PxSelectBoxProps) => {
    const { ...rest } = props;
    return <Select {...rest} />;
  }
)``;

export default PxSelectBox;
