import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppState } from "../../store";
import { getMySubEventBusinessCard } from "../../actions/users";

// context
import { SubEventContext } from "../../components/Providers/SubEventProvider/SubEventProvider";

// material ui
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

// icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PeopleIcon from "@material-ui/icons/People";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HomeIcon from "@material-ui/icons/Home";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import BusinessIcon from "@material-ui/icons/Business";

interface SidebarProps {
  screenWidth: "xs" | "sm" | "md" | "lg" | "xl";
  sideBarStyles: Record<"drawer" | "drawerPaper" | "drawerHeader", string>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MenuItemProps {
  name: string;
  menuName: string;
  icon: JSX.Element;
  onClick?:
    | ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void)
    | undefined;
}

const CustomMenuItem = ({ name, menuName, icon, onClick }: MenuItemProps) => {
  const { selectedUrl } = useContext(SubEventContext);

  return (
    <>
      <MenuItem
        button
        key={name}
        onClick={onClick}
        selected={selectedUrl.includes(name)}
      >
        <ListItemIcon>{icon !== undefined ? icon : <InboxIcon />}</ListItemIcon>
        <ListItemText>
          <SubHeadingTypo>{menuName}</SubHeadingTypo>
        </ListItemText>
      </MenuItem>
    </>
  );
};

const Sidebar = ({
  sideBarStyles,
  open,
  setOpen,
  screenWidth,
}: SidebarProps) => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const classes = sideBarStyles;
  const dispatch = useDispatch();
  const history = useHistory();
  const { interpreter } = useSelector((state: AppState) => state.interpreters);
  const { subEventId } = useContext(SubEventContext);

  // 통역사도 users.subEventBusinessCard 에 명함정보가 들어가도록 해줌
  // 채팅, 영상통화 시 사용 됨
  useEffect(() => {
    if (interpreter) {
      dispatch(
        getMySubEventBusinessCard({
          id: interpreter.userId.id,
          name: interpreter.name,
          email: interpreter.email,
          company: t("interpreterSubEvent.interpreter"),
          countryNumber: "",
          phoneNumber: interpreter.phoneNumber,
        })
      );
    }
  }, [dispatch, interpreter, t]);

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor={screenWidth === "xs" ? "right" : "left"}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <PxGridContainer direction="row" alignItems="center">
            <SubHeadingTypo className="mr-2">
              {t("interpreterSubEvent.interpreter")}
            </SubHeadingTypo>
            <PxButton
              padding="0px 2px 0px 2px"
              endIcon={<ChevronRightIcon />}
              endIconMargin="0"
              border={true}
              onClick={() => {
                history.push(`/interpreter/${subEventId}/myInfo`);
              }}
            >
              <ButtonTypo>{t("myInfo.me")}</ButtonTypo>
            </PxButton>
          </PxGridContainer>
          <IconButton onClick={() => setOpen(false)}>
            {screenWidth !== "xs" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <PxGridContainer
          direction="column"
          alignItems="center"
          className="mt-3 mb-3"
          style={{ textAlign: "center" }}
        >
          <SubHeadingTypo txtcolor="purple" fontweight="bold">
            {interpreter?.name}
          </SubHeadingTypo>
        </PxGridContainer>
        <MenuList>
          <CustomMenuItem
            name="participantsList"
            menuName={t("subEventSideBar.participantInfo")}
            icon={<BusinessIcon />}
            onClick={() => {
              history.push(`/interpreter/${subEventId}/participantsList`);
            }}
          />
          <CustomMenuItem
            name="meeting"
            menuName="미팅현황" //{t("subEventSideBar.participant")}
            icon={<PeopleIcon />}
            onClick={() => {
              history.push(`/interpreter/${subEventId}/meeting`);
            }}
          />
          <CustomMenuItem
            name="counselList"
            menuName={t("subEventSideBar.consultation")}
            icon={<PlaylistAddCheckIcon />}
            onClick={() => {
              history.push(`/interpreter/${subEventId}/counselList`);
            }}
          />

          <CustomMenuItem
            name="noticeList"
            menuName={t("subEventSideBar.notice")}
            icon={<NotificationImportantIcon />}
            onClick={() => {
              history.push(`/interpreter/${subEventId}/noticeList`);
            }}
          />

          <CustomMenuItem
            name="eventInfo"
            menuName={t("subEventSideBar.eventInfo")}
            icon={<AssignmentIcon />}
            onClick={() => {
              history.push(`/interpreter/${subEventId}/eventInfo`);
            }}
          />

          <CustomMenuItem
            name="home"
            menuName={t("subEventSideBar.home")}
            icon={<HomeIcon />}
            onClick={() => {
              history.push("/");
            }}
          />
        </MenuList>
      </Drawer>
    </>
  );
};

export default Sidebar;
