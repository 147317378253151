import React from "react";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import fscreen from "fscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import useFullScreenToggle from "../../hooks/useFullScreenToggle";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
  })
);

export default function ToggleFullscreenButton() {
  const classes = useStyles();
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle();
  const { t } = useTranslation("lang", { useSuspense: false });

  return fscreen.fullscreenEnabled ? (
    <Tooltip
      title={!isFullScreen ? "Full Screen" : "General Screen"}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={classes.fab} onClick={toggleFullScreen}>
        <div>
          {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          <CaptionTypo txtcolor="white">
            {t("videoController.fullScreen")}
          </CaptionTypo>
        </div>
      </Fab>
    </Tooltip>
  ) : null;
}
