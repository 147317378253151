import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Checkbox,
  DeviceLabels,
  Flex,
  FormField,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  PrimaryButton,
  Select,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import {
  DefaultBrowserBehavior,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";

import { createGetAttendeeCallback, fetchMeeting } from "../../utils/api";
import { useAppState } from "../../providers/AppStateProvider";
import { getErrorContext } from "../../providers/ErrorProvider";
import meetingConfig from "../../meetingConfig";
import { MeetingMode, VideoFiltersCpuUtilization } from "../../types";
import routes from "../../constants/routes";

import { StyledWrapper, StyledAudioGroup, StyledVideoGroup } from "./Styled";
import MicrophoneDevices from "./MicrophoneDevices";
import SpeakerDevices from "./SpeakerDevices";
import CameraDevices from "./CameraDevices";

const DeviceSelection = () => {
  return (
    <StyledWrapper>
      <StyledAudioGroup>
        <MicrophoneDevices />
        <SpeakerDevices />
      </StyledAudioGroup>
      <StyledVideoGroup>
        <CameraDevices />
      </StyledVideoGroup>
    </StyledWrapper>
  );
};

export default DeviceSelection;
