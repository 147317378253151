import CounselFormActionType from "../types/actions/CounselFormAction";
import { CounselForm } from "../types/models/CounselForm";

const counselFormDefaultState: CounselForm = {
  sellerCounselForm: undefined,
  buyerCounselForm: undefined,
};

const counselFormReducer = (
  state = counselFormDefaultState,
  action: CounselFormActionType
): CounselForm => {
  switch (action.type) {
    case "GET_COUNSEL_FORM":
      return { ...state, sellerCounselForm: action.form, buyerCounselForm: action.form };
    case "REMOVE_COUNSEL_FORM":
      return counselFormDefaultState;
    default:
      return {...state};
  }
};

export default counselFormReducer;
