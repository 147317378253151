import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const momentTZ = require("moment-timezone");

const Clock = () => {
  const { country } = useSelector((state: AppState) => state.countries!);

  const [timeString, setTimeString] = useState<string>("");

  const updateClock = useCallback(() => {
    const nowTimeToTimeZone = momentTZ.tz(moment(), country?.thisCountryTz);
    //HH <- 24:00 ,  hh <- 12:00
    setTimeString(nowTimeToTimeZone.format("HH:mm:ss"));
  }, [country]);

  useEffect(() => {
    const intervalId = setInterval(() => updateClock(), 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [updateClock]);

  // 처음 불러오는 시간 (Timezone 적용)
  useEffect(() => {
    const nowTimeToTimeZone = momentTZ.tz(moment(), country?.thisCountryTz);
    //HH <- 24:00 ,  hh <- 12:00
    setTimeString(nowTimeToTimeZone.format("HH:mm:ss"));
  }, [country]);
  return <>{timeString}</>;
};

export default Clock;
