import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getEvent, removeEvent } from "../../actions/events";
import { AppState } from "../../store";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// actions
import {
  getApplicantList,
  removeApplicantsAction,
} from "../../actions/applications";

// custom comp
import SubEventApplyCard from "./SubEventApplyCard";
import PxGoogleMap from "../../components/Map/PxGoogleMap";
import PageNotFound from "../NoResult/PageNotFound";

// custom material ui comp
import GridItem from "../../components/Grid/PxGridItem";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxGridItem from "../../components/Grid/PxGridItem";
import SubHeadingTypo from "../Typhography/SubHeadingTypo";
import PxButton from "../Buttons/PxButton";
import ButtonTypo from "../Typhography/ButtonTypo";
import TitleTypo from "../Typhography/TitleTypo";
import PxBreakpoints from "../PxBreakpoint/PxBreakpoint";
import { Grid } from "@material-ui/core";

// icons
import RoomIcon from "@material-ui/icons/Room";
import Body1Typo from "../Typhography/Body1Typo";
import Body3Typo from "../Typhography/Body3Typo";
import ShowFile from "../File/ShowFile";
import { isMobile } from "../../utils/utils";
import { removeSubEvents, getSubEvents } from "../../actions/subEvents";
import SuperHeadTypo from "../Typhography/SuperHeadTypo";
import { makeStyles } from "@material-ui/core/styles";
import CountryTimeForm from "../../views/form/CountryTimeForm";
import CloseIcon from "@material-ui/icons/Close";
import PxHelmet from "../Helmet/PxHelmet";
import PexpoLogo from "../../assets/images/pexpo_signature.svg";

const EventDetail = () => {
  const classes = useStyles();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const param: { id: string } = useParams(); // id: eventId
  const [waitingDialog, setWaitingDialog] = useState(false);
  const [closeBtn, setCloseBtn] = useState(false);
  const [axiosResponse, setAxiosResponse] = useState(true);

  const eventDetail = useSelector((state: AppState) => state.events.event);
  const subEvents = useSelector((state: AppState) => state.subEvents.subEvents);

  const { isAuthenticated } = useSelector((state: AppState) => state.users);
  const { applicants } = useSelector((state: AppState) => state.applications);

  const location = useLocation();

  // subEventId : {approval: 승인여부, type: 셀러 or 바이어}
  const [applicantsList, setApplicantsList] = useState<{
    [key: number]: { approval: string; type: string; reason?: string };
  }>([]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getApplicantList(param.id));
    }
  }, [dispatch, isAuthenticated, param.id]);

  useEffect(() => {
    const dataRequest = async () => {
      const result: any = await dispatch(getEvent(param.id));
      dispatch(getSubEvents(param.id));
      setAxiosResponse(result);
    };
    dataRequest();
  }, [param.id]);

  // 이벤트 페이지 나갈 시 리덕스 state 정리
  useEffect(() => {
    return () => {
      // dispatch(removeSubEvents());
      // dispatch(removeEvent());
      dispatch(removeApplicantsAction());
    };
  }, [dispatch]);

  // 전체 subEvent 참여자 목록을 mainEventId 값을 통해서 가져온 후 subEventId 값으로 리스트 map
  // 밑에서 참여한 applicantList state값으로 이벤트에 참여한 것인지 아닌지 판단 후 ui를 변경
  useEffect(() => {
    if (applicants && applicants.length !== 0 && Array.isArray(applicants)) {
      const data: { [key: number]: { approval: string; type: string } } = {};
      applicants.forEach((applicant) => {
        Object.assign(data, {
          [applicant.subEventId.id]: {
            approval: applicant.applicationId.approval,
            type: applicant.applicationId.type,
            reason: applicant.applicationId.reason ?? "",
          },
        });
      });

      setApplicantsList(data);
    }
  }, [applicants]);

  // 이벤트 디테일 페이지에서 쿼리 스트링으로 언어 감지후 변경
  useEffect(() => {
    languageChange();
  }, []);

  useEffect(() => {
    if (Object.keys(applicantsList).length !== 0 && waitingDialog === false) {
      let showOrNot = false;
      for (let i = 0; i < Object.keys(applicantsList).length; i++) {
        const key = Object.keys(applicantsList)[i];
        if (applicantsList[key as any].approval === "waiting") {
          showOrNot = true;
          break;
        }
      }
      setWaitingDialog(showOrNot);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantsList]);

  // url 주소에 쿼리스 트링 값이 있고 그 값이 있으면 값에 따라 언어를 변경해줌
  const languageChange = () => {
    const lang = new URLSearchParams(location.search).get("lang");
    if (lang) {
      if (lang === "ko") {
        i18n.changeLanguage("ko");
        localStorage.setItem("language", "ko");
      } else {
        i18n.changeLanguage("en");
        localStorage.setItem("language", "en");
      }
    }
  };

  const setEventName = useCallback(
    (ev) => {
      if (i18n.language === "ko") {
        return ev.name;
      } else if (i18n.language !== "ko" && ev.nameEn) {
        return ev.nameEn;
      } else {
        return ev.name;
      }
    },
    [i18n.language]
  );

  const setEventContent = useCallback(
    (ev) => {
      if (i18n.language === "ko") {
        return ev.content;
      } else if (i18n.language !== "ko" && ev.nameEn) {
        return ev.contentEn;
      } else {
        return ev.content;
      }
    },
    [i18n.language]
  );

  return eventDetail !== undefined &&
    eventDetail.name &&
    eventDetail.startDate ? (
    <PxBreakpoints>
      <PxHelmet
        title={eventDetail.name}
        img={eventDetail.mainImageFile?.pathname ?? eventDetail.image}
        url={window.location.href}
      />
      {/* 참가신청 완료 시 대기 Dialog (추후 수정해서 사용할 수 있으니 지우면 안됨) */}
      {/* <PxDialog open={waitingDialog} onClose={waitingDialogOnClose}>
        <DialogTitle id="responsive-dialog-title">
          {t("eventDetail.applyDone")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.waitingDialogContentText}>
            {i18n.language === "ko"
              ? waitingDialogContentKor
              : waitingDialogContentEn}
          </DialogContentText>
        </DialogContent>
        <PxButton
          onClick={waitingDialogOnClose}
          backgroundcolor="purple"
          className={classes.waitingDialogConfirmBtn}
        >
          {t("eventDetail.confirm")}
        </PxButton>
      </PxDialog> */}

      <EventTitleContainer direction="column" alignContent="center">
        {eventDetail.logoImageFile != null && (
          <PexpoLogoImage
            src={eventDetail.logoImageFile.filePath}
            className="d-inline-block align-top"
            alt="Event logo"
            style={{
              cursor: "pointer",
              width: "auto",
              height: "50px",
              objectFit: "contain",
            }}
          />
        )}
        <SuperHeadTypo fontSize="28px" lineHeight={1.15} fontweight="bold">
          {setEventName(eventDetail)}
        </SuperHeadTypo>
        <SubHeadingTypo txtcolor="black0.6" marginTop="4px">
          {eventDetail.startDate !== "" && (
            <CountryTimeForm
              startDate={eventDetail.startDate}
              endDate={eventDetail.endDate}
              hostCountryCode={eventDetail.countryCode!}
            />
          )}
        </SubHeadingTypo>
        <SubHeadingTypo txtcolor="black0.6" style={{ fontSize: "14px" }}>
          {eventDetail.categories
            ?.split("^&")
            .map((category) => t(`categories.${category}`))
            .join(" | ")}
        </SubHeadingTypo>
      </EventTitleContainer>

      <PxGridContainer style={{ marginBottom: 80 }}>
        <Grid container>
          <Grid item md={8} sm={12}>
            {/* 개요 */}
            <SectionDiv>
              <TitleTypo fontSize={21} fontweight="400" gutterBottom>
                {t("eventDetail.summary")}
              </TitleTypo>
              <EventContentDiv
                dangerouslySetInnerHTML={{
                  __html: setEventContent(eventDetail),
                }}
              />

              {/* 첨부파일 */}
              {(eventDetail.atchFile1 !== -1 ||
                eventDetail.atchFile2 !== -1 ||
                eventDetail.atchFile3 !== -1 ||
                eventDetail.atchFile4 !== -1 ||
                eventDetail.atchFile5 !== -1) &&
                // <SectionDiv>
                // <TitleTypo gutterBottom>{t("eventDetail.file")}</TitleTypo>
                Array.from({ length: 5 }, (unknown, i) => {
                  return (eventDetail as any)[`atchFile${i + 1}`] !== -1 ? (
                    <ShowFile
                      fileId={Number((eventDetail as any)[`atchFile${i + 1}`])}
                    />
                  ) : null;
                })}
            </SectionDiv>

            {/* 지도 */}
            {eventDetail.location !== "" &&
              eventDetail.location !== undefined && (
                <SectionDiv>
                  <GridItem marginBottom={8}>
                    <TitleTypo fontSize={21} gutterBottom fontweight="400">
                      {t("eventDetail.location")}
                    </TitleTypo>
                    {eventDetail.lat !== "" && eventDetail.lng !== "" && (
                      <PxGoogleMap
                        containerElement={<div style={{ height: `240px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        lat={Number(eventDetail.lat)}
                        lng={Number(eventDetail.lng)}
                        marker={true}
                      />
                    )}
                  </GridItem>
                  <GridItem className="mb-4">
                    {/* 위치 */}
                    <Body1Typo
                      fontSize="0.9375rem"
                      underLine={true}
                      txtcolor="black0.6"
                      gutterBottom
                    >
                      {eventDetail.location}
                    </Body1Typo>
                    <PxGridContainer direction="row" alignItems="center">
                      <RoomIcon style={{ color: "rgba(0,0,0,0.6)" }} />
                      {/* 상세주소 */}
                      <Body1Typo
                        marginLeft="4px"
                        fontSize="1rem"
                        txtcolor="black0.6"
                      >
                        {eventDetail.locationDetail}
                      </Body1Typo>
                    </PxGridContainer>
                  </GridItem>
                </SectionDiv>
              )}

            {/* 담당자 / 문의 */}
            {eventDetail.managers?.length !== 0 && (
              <SectionDiv>
                <TitleTypo fontSize={21} fontweight="400" gutterBottom>
                  {t("eventDetail.manager")}
                </TitleTypo>
                {Array.isArray(eventDetail.managers) && (
                  <>
                    {eventDetail.managers.map((manage: any, index) => (
                      <GridItem className="mb-3 mt-3" key={index}>
                        <PxGridContainer>
                          {/* <PersonIcon /> */}
                          <Body3Typo>{manage.name}</Body3Typo>
                        </PxGridContainer>

                        <PxGridContainer direction="row" alignItems="center">
                          {/* <PhoneIcon className="mr-2" fontSize="small" /> */}
                          <Body1Typo
                            txtcolor="black0.6"
                            onClick={() => {
                              if (isMobile)
                                window.location.href = `tel:${manage.countryNumber}${manage.phone}`;
                            }}
                            style={isMobile ? { cursor: "pointer" } : {}}
                          >
                            ({manage.countryNumber}) {manage.phone}
                          </Body1Typo>
                          <Body1Typo txtcolor="black0.6">
                            {" "}
                            &nbsp;&nbsp; | &nbsp;&nbsp;
                          </Body1Typo>
                          <Body1Typo
                            txtcolor="azure"
                            fontSize="0.9375rem"
                            onClick={() => {
                              window.location.href = `mailto:${manage.email}`;
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {manage.email}
                          </Body1Typo>
                        </PxGridContainer>
                      </GridItem>
                    ))}
                  </>
                )}
              </SectionDiv>
            )}
          </Grid>

          {/* 이벤트 참가신청 버튼 */}
          {subEvents!.length > 0 && (
            <Grid item md={4}>
              <Mobile close={closeBtn}>
                <CloseButton
                  onClick={() =>
                    closeBtn ? setCloseBtn(false) : setCloseBtn(true)
                  }
                >
                  <CloseIcon />
                </CloseButton>
                <div className={classes.applyCardContainer}>
                  {/* 참가신청 container */}
                  <SubEventApplyCard
                    eventId={param.id}
                    applicantsList={applicantsList}
                  />
                  {/*홈페이지로 가기 버튼 */}
                  {eventDetail.homePage !== "" && (
                    <PxGridItem
                      fullwidth
                      className="my-4"
                      style={{
                        border: "1px solid rgba(0,0,0,0.2)",
                        display: "flex",
                        alignItems: "center",
                        height: 48,
                      }}
                    >
                      <PxButton
                        borderRadius={false}
                        fullWidth
                        hover={false}
                        borderColor="silver"
                        backgroundcolor="transparent"
                        border={true}
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          window.open(eventDetail.homePage);
                        }}
                      >
                        <ButtonTypo txtcolor="rgba(0,0,0,0.6)">
                          {t("eventDetail.homePage")}
                        </ButtonTypo>
                      </PxButton>
                    </PxGridItem>
                  )}
                </div>
              </Mobile>
            </Grid>
          )}
        </Grid>
        <OpenButton
          onClick={() => (closeBtn ? setCloseBtn(false) : setCloseBtn(true))}
        >
          {t("eventDetail.apply")}
        </OpenButton>
      </PxGridContainer>
    </PxBreakpoints>
  ) : !axiosResponse ? (
    <PageNotFound />
  ) : null;
};

export default EventDetail;

const EventContentDiv = styled.div`
  img {
    max-width: 100%;
  }
  line-height: 1.75;
  color: #616161;
  margin-bottom: 20px;
`;

const SectionDiv = styled.div`
  &:not(:first-child) {
    margin-top: 50px;
  }
`;

interface isMobileBtn {
  close: boolean;
}

const Mobile = styled.div<isMobileBtn>`
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 1023px) {
    width: 100vw;
    height: 100vh;
    padding: 64px 16px 0 16px;
    display: ${(props) => (props.close ? "block" : "none")};
    position: fixed;
    background-color: white;
    top: 0px;
    left: 0px;
    z-index: 1030;
  }
`;

const CloseButton = styled.button`
  display: none;
  @media only screen and (max-width: 1023px) {
    display: block;
    width: 60px;
    height: 64px;
    position: absolute;
    background-color: transparent;
    border: none;
    top: 0px;
    right: 0px;
  }
`;

const OpenButton = styled.button`
  display: none;

  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: 56px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #512da8;
    color: #ffffff;
    border: none;
    bottom: 0px;
    right: 0px;
    z-index: 1;
  }
`;

const useStyles = makeStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
  },
  imageContainer: {
    position: "relative",
    paddingBottom: "56.2%",
  },
  image: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  homePageButton: {
    borderRadius: 50,
  },
  applyCardContainer: {
    position: "sticky",
    paddingLeft: 20,
  },
  mobileApplyCardContainer: {
    paddingTop: 0,
    paddingBottom: 15,
  },
  waitingDialogContentText: {
    whiteSpace: "pre-line",
  },
  waitingDialogConfirmBtn: {
    color: "white",
  },
}));

const PexpoLogoImage = styled.img`
  position: absolute;
  top: 20px;
  left: 0;
  cursor: pointer;
  height: 30px;
  width: 112px;
  /*  width="79"
		height="26" */
`;

const EventTitleContainer = styled(PxGridContainer)`
  text-align: center;
  padding: 80px 0 64px;
  position: relative;
  @media (max-width: 900px) {
    padding: 120px 0px 64px;
  }
`;
