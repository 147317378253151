import { AppActions } from "../store";
import { FormAnswerInfo } from "../types/models/FormAnswer";
import { Dispatch } from "redux";
import { getRequest, postRequest, putRequest } from "../utils/utils";
import { CounselFormAnswer, SurveyCreate } from "../types/models/CounselForm";

export const getFormAnswersAction = (
  formAnswers: FormAnswerInfo[]
): AppActions => ({
  type: "GET_FORM_ANSWERS",
  formAnswers: formAnswers,
});

export const getSurveyFormAnswersAction = (
  formAnswers: CounselFormAnswer[]
): AppActions => ({
  type: "GET_SURVEY_FORM_ANSWERS",
  surveyFormAnswers: formAnswers,
});

export const removeFormAnswerAction = (): AppActions => ({
  type: "REMOVE_FORM_ANSWER",
});

export const getFormAnswers = (applicantionId: string) => {
  return getRequest(null, "/api/formAnswers", getFormAnswersAction, {
    applicantionId: applicantionId,
  });
};

export const removeFormAnswer = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(removeFormAnswerAction());
};

export const createSurveyFormAnswer = (survey: SurveyCreate) => {
  return postRequest(survey, "/api/postSurveys", getSurveyFormAnswersAction);
};

export const updateSurveyFormAnswer = (survey: SurveyCreate) => {
  return putRequest(survey, "/api/postSurveys", getSurveyFormAnswersAction);
};

export const getSurveyAnswer = (applicationId: string) => {
  return getRequest(
    null,
    `/api/postSurveys/${applicationId}`,
    getSurveyFormAnswersAction
  );
};
