import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "./Manual.css";
import { useHistory, useParams } from "react-router-dom";

import { Layout } from "../Layout/Layout";
import Pexpologo from "../../assets/images/mainCarousel-large.png";
import { List as ListIcon, Close as CloseIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const Header = styled.h1`
  font-size: 2.125rem;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
  &:not(:first-child) {
    margin-top: 60px;
  }
`;

const Header2 = styled.div`
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
  margin-top: 60px;
  margin-bottom: 8px;
`;

const Header3 = styled.div`
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
  margin-top: 60px;
  margin-bottom: 8px;
`;

const Tr1 = styled.tr`
  border-bottom: solid #999999 1px;
  border-top: solid #999999 1px;
  vertical-align: top;
  background-color: #f3f3f3;
  padding: 5pt 5pt 5pt 5pt;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const Contents = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  max-width: 840px;
  margin-left: 92px;
  margin-right: 44px;
  @media only screen and (max-width: 1279px) {
    margin-left: 42px;
  }
  @media only screen and (max-width: 1023px) {
    margin-left: 0px;
    margin-right: 0px;
    max-width: 974px;
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
`;

const Sidebar = styled.div<{ openSidebar: boolean }>`
  position: sticky;
  position: -webkit-sticky; /* 사파리 브라우저 지원 */
  height: calc(100vh - 64px);
  top: 64px;
  width: 200px;
  overflow-y: scroll;
  @media only screen and (max-width: 1023px) {
    display: ${(props) => (props.openSidebar ? "block" : "none")};
    position: fixed;
    width: 100%;
    height: calc(100% - 64px);
    top: 64px;
    left: 0px;
    z-index: 1;
    text-align: center;
    background-color: white;
  }
`;

const MobildIndexButton = styled.button`
  display: none;
  border: none;
  border-radius: 50%;
  background-color: #512da8;
  width: 50px;
  height: 50px;
  z-index: 2;
  position: fixed;
  bottom: 30px;
  right: 30px;
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 1023px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Bold = styled.span`
  font-weight: 700;
`;

interface IndexHeaderProps {
  index: boolean;
  subHeader: boolean;
}

const IndexHeader = styled.div<IndexHeaderProps>`
  font-size: ${(props) => (props.subHeader ? "0.8rem" : "1.1rem")};
  padding-left: ${(props) => (props.subHeader ? "8px" : "0px")};
  font-weight: 600;
  margin-bottom: 16px;
  color: ${(props) =>
    props.index ? " rgb(81, 45, 168);" : "rgba(0, 0, 0, 0.6)"};
  &:hover {
    cursor: pointer;
  }
`;

const SidebarTop = styled.div`
  margin-top: 40px;
  font-size: 1.25rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 28px;
`;

const Image = styled.img`
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
`;

const Video = styled.video`
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
`;

interface IndexProps {
  index: number;
  text: string;
  y: number | undefined;
  header: string;
  page?: string;
}
/*
  return 
  모바일 목차 on off 버튼
  목차 리스트 
    header
    header2 / 3
  본문
    styled 태그 
    -Header
    -Header2
    -Header3
    -Bold
    -Video
    -Image
    나머지는  

*/
const Manual = () => {
  const history = useHistory();
  const { page }: { page: string } = useParams();
  if (
    page !== ("participateEvent" || "createEvent" || "manageEvent" || undefined)
  ) {
  }
  const files = [
      "",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/1.connect_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/2.interface%40connect-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/3.participant-list_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/4.send-chat_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/5.send-contact_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/6.chatting_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/7.request-meeting_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/8.cancel-meeting_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/9.confirm-meeting_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/10.arrange-by-host_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/11.set-meeting-block_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/12.connect-video_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/13.filter-meeting-status_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/14.online-meeting-tools@connect-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/15.add-to-person_connect-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event/16.person-history_connect-kr.mp4",
    ],
    files_en = [
      "",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/1.connect_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/2.interface%40connect-en.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/3.participant-list_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/4.send-chat_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/5.send-contact_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/6.chatting_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/7.request-meeting_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/8.cancel-meeting_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/9.confirm-meeting_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/10.arrange-by-host_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/11.set-meeting-block_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/12.connect-video_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/13.filter-meeting-status_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/14.online-meeting-tools@connect-en.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/15.add-to-person_connect-en.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/participate_event_en/16.person-history_connect-en.mp4",
    ],
    makeEventFiles = [
      "",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/1.make-event_make-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/2.place-set@make-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/3.website-set_make-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/4.inquire-set_make-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/5.event-list-details_make-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/6.make-sub-event_make-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/7.match-making@make-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/8.exposure-set@make-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/9.set-meeting-block_make-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/10.make-form_make-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/11.make-form@make-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/12.default-form@make-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/13.forms-toolbar@make-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/14.forms-option@make-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/15.product-information@make-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/make_event/16.request-confirm_make-event-kr.mp4",
    ],
    managedEventFiles = [
      "",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/1.manage-event%40manage-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/2.company-list_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/3.confirm-company_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/4.meeting-status_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/5.meeting-status@manage-event-kr.png",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/6.filter-meeting_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/7.set-timezone-meeting_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/8.arrange-meeting_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/9.cancel-meeting_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/10.set-meeting-block_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/11.cancel-meeting-block_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/12.matching-log-list_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/13.matching-history-list_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/14.meeting-note_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/15.translator_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/16.register-translator_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/17.speaker_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/18.program_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/19.add-to-speaker_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/20.notice_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/21.mail_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/22.sms_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/23.exel_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/24.monitor_manage-event-kr.mp4",
      "https://pexpo2.s3.ap-northeast-2.amazonaws.com/menual/managed_event/25.set-timezone-monitor_manage-event-kr.mp4",
    ];
  const [index, setIndex] = useState<Array<IndexProps>>([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [nowIndex, setNowIndex] = useState(0);
  const [nowSubIndex, setNowSubIndex] = useState(1);
  const [headerName, setHeaderName] = useState("");
  const { i18n } = useTranslation("lang", { useSuspense: false });
  const elements: HTMLCollectionOf<Element> = document.getElementsByClassName(
    "index"
  );

  const Scrolling = () => {
    setScrollHeight(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", Scrolling);
  }, []);

  const updateIndexY = () => {
    setIndex([]);
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "index"
    );
    // setIndex((index) => [...index,{index: 0 , text: "이벤트 참가하기" , y:0,}])
    for (const i in elements) {
      const data: IndexProps = {
        index: parseInt(i),
        text: elements[i].innerHTML?.split("<span></span")[0].trim(),
        y:
          window.pageYOffset +
          elements[i].firstElementChild?.getBoundingClientRect().y!,
        header: elements[i].className?.split(" ")[0],
        page:
          elements[i].className?.split(" ")[4] &&
          elements[i].className?.split(" ")[4],
      };
      setIndex((index) => [...index, data]);
    }
  };

  const setOpen = (e: React.MouseEvent) => {
    if (openSidebar) {
      setOpenSidebar(false);
    } else {
      setOpenSidebar(true);
    }
  };

  //목차 생성
  useEffect(() => {
    updateIndexY();
    setHeaderName(elements[0].className?.split(" ")[0]);

    //화면에 보일때 이미지, 동영상 로드
    const imageObserver = new IntersectionObserver(
      (entries, imgObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target as
              | HTMLImageElement
              | HTMLVideoElement;
            lazyImage.src = lazyImage!.getAttribute("data-src")!;
            lazyImage!.classList.remove(".lazy");
            imgObserver.unobserve(lazyImage);
          }
        });
      },
      {
        threshold: 0.01,
        root: null,
      }
    );
    const arr = document.querySelectorAll(".lazy");
    arr.forEach((v) => {
      imageObserver.observe(v);
    });
  }, [page, i18n.language]);

  //목차에 맞게 하이라이트
  useEffect(() => {
    const num: number = index?.findIndex(
      (data: IndexProps) =>
        data.y! >= scrollHeight + 50 && data.y! <= scrollHeight + 400
    );

    if (num !== -1 && index[num]!.header === headerName) {
      setNowIndex(num);
      setNowSubIndex(num + 1);
    } else if (num != -1 && index[num]!.header !== headerName) {
      setNowSubIndex(num);
      document.getElementById("sidebar")?.scrollTo({
        top: document.getElementById(`${num}`)!.offsetTop - 100,
      });

      if (nowSubIndex < nowIndex) {
        setNowIndex(
          index
            ?.filter(
              (data: IndexProps) =>
                data.header === headerName && data.index < nowSubIndex
            )
            .reverse()[0].index
        );
      }
    }
  }, [scrollHeight]);

  //영상, 이미지 로드 끝났을대 다시  Y값 수정하기 (영상 세로 크기때문에 스크롤 길이 늘어남)
  useEffect(() => {
    updateIndexY();
  }, [document.body.scrollHeight]);

  const scrollToIndex = (data: number) => {
    window.scrollTo({ top: index[data]!.y! - 100 });
    setOpenSidebar(false);
  };

  return (
    <Layout>
      {/* 모바일 목차 on off 버튼 */}
      <MobildIndexButton onClick={setOpen}>
        {openSidebar ? (
          <CloseIcon style={{ color: "white" }} />
        ) : (
          <ListIcon style={{ color: "white" }} />
        )}{" "}
      </MobildIndexButton>
      <Container>
        {/* 목차 */}
        <Sidebar id="sidebar" openSidebar={openSidebar}>
          <SidebarTop>{i18n.language === "ko" ? "목차" : "Index"}</SidebarTop>
          {index.map((data: IndexProps, index) =>
            data.header === headerName ? (
              <IndexHeader
                key={index}
                id={`${data.index}`}
                subHeader={false}
                index={page === data.page}
                onClick={() => {
                  history.replace(`/terms/manual/${data.page}`);
                  setOpenSidebar(false);
                }}
              >
                {data.text}
              </IndexHeader>
            ) : (
              <IndexHeader
                key={index}
                id={`${data.index}`}
                subHeader={true}
                index={nowSubIndex === data.index}
                onClick={() => scrollToIndex(data.index)}
              >
                {data.text}
              </IndexHeader>
            )
          )}
        </Sidebar>
        {/* 본문 */}
        {i18n.language === "ko" ? (
          <Contents>
            <Header
              style={{
                display: page === "participateEvent" ? "block" : "none",
              }}
              className="header index participateEvent"
              dir="ltr"
            >
              온라인 미팅 기능 <span></span>
            </Header>
            {page === "participateEvent" && (
              <>
                <Header2 className="header2 index" dir="ltr">
                  참가 신청하기 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    개요 화면에서 오른쪽의 참가신청을 선택하면, 참가신청서
                    화면으로 이동합니다. 참가신청을 완료하면 안내 이메일이
                    발송되며 주최사 승인 후, 접속페이지로 이동합니다.
                  </span>
                </p>
                <Header2 className="header2 index" dir="ltr">
                  이벤트 접속하기 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    주최사가 확정을 완료한 참가자만 접속을 할 수 있습니다. 확정
                    여부는 개요 화면의 참가신청에서 확인할 수 있습니다. 주최사가
                    확정을 완료하면 참가자에게 안내 메일이 발송됩니다.
                  </span>
                </p>

                <p dir="ltr">
                  <span className="span">
                    개요 화면에서 주최사가 확정한 참가신청을 클릭하면 접속
                    화면으로 이동합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[1]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  인터페이스 <span></span>
                </Header2>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[2]}
                  ></Image>
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>참가 구분</Bold>
                      <span className="span">. 참가 신청시 선택한</span>

                      <span className="span">
                        &nbsp;참가자 유형을 출력합니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>수정하기</Bold>
                      <span className="span">
                        . 참가신청서 화면으로 이동하는 버튼입니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>내 정보</Bold>
                      <span className="span">
                        . 참가신청서에 입력된 이름과 소속을 출력합니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>전용메뉴</Bold>
                      <span className="span">
                        . 활성화된 메뉴를 불러옵니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>상단 바</Bold>
                      <span className="span">
                        . 이벤트 이름과 하위 이벤트 이름을 출력합니다.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header2 className="header2 index" dir="ltr">
                  참가자 목록 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    참가자 목록을 불러오며 클릭시 상세화면으로 이동합니다.
                    상세화면에서는 기업정보, 담당자 등의 정보를 열람할 수
                    있습니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[3]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  채팅하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    담당자 탭에서 담당자 클릭시 채팅 창이 오른쪽에 밀려
                    나옵니다. 하단의 입력 창에서 채팅을 할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[4]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  연락처 보내기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    채팅 창의 내 정보 보내기 버튼 클릭시 이메일과 전화번호를
                    띄웁니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[5]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  채팅 목록 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    참가자 목록에서 담당자와 1회 이상 채팅한 기록이 있는 경우
                    채팅 목록에 불러옵니다.
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    * 온라인 화상 미팅에서 주고 받은 채팅 기록은 해당되지
                    않습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[6]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  미팅현황 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    주최사가 정한 미팅일정을 불러오며 참가자는 설정된 날짜와
                    시간에 미팅할 수 있습니다.
                  </span>
                </p>
                <Header3 className="header3 index" dir="ltr">
                  미팅 신청하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    비어 있는 미팅일정 카드에 미팅 신청 버튼을 클릭합니다. 미팅
                    신청 창 아래에 미팅 가능한 참가자 목록을 불러오며 클릭시
                    확인 창이 나옵니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[7]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>

                <p dir="ltr">
                  <span className="span">
                    미팅을 신청한 참가자와 미팅신청을 받은 참가자에게는 안내
                    메일이 발송됩니다.
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    *미팅 신청은 다른 날짜와 시간에 중복으로 신청할 수
                    있습니다.&nbsp;
                  </span>
                </p>
                <Header3 className="header3 index" dir="ltr">
                  미팅 취소하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    신청했거나 이미 체결된 미팅일정은 취소할 수 있습니다.
                    미팅일정 카드의 취소 버튼을 클릭하면 해당 미팅일정은
                    취소됩니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[8]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>

                <p dir="ltr">
                  <span className="span">
                    취소 안내 메일은 신청한 참가자와 신청을 받은 참가자에게
                    발송됩니다.
                  </span>
                </p>
                <Header3 className="header3 index" dir="ltr">
                  미팅 수락하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    같은 날짜와 시간에 여러명의 참가자로부터 미팅 신청이 들어올
                    수 있습니다. 몇 명의 참가자로부터 신청을 받았는지 숫자를
                    출력하며 목록보기 버튼을 클릭할 수 있습니다. 클릭시 미팅수락
                    대기자 창이 뜨며 기업정보를 불러옵니다.&nbsp;
                  </span>
                </p>

                <p dir="ltr">
                  <span className="span">
                    미팅을 원하는 참가자를 선택하고 수락을 누르면 체결상태로
                    변경되며 체결 안내 메일이 미팅을 신청한 참가자와 수락한
                    참가자 모두에게 발송됩니다.
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    *선택받지 못한 참가자는 거절되었다는 안내메일이 발송됩니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[9]}
                    autoPlay={true}
                    loop={true}
                  ></Video>
                </p>
                <Header3 className="header3 index" dir="ltr">
                  주최자 주선 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    주최자가 주선한 미팅은 참가자가 수락하지 않아도 체결상태로
                    생성됩니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[10]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>

                <p dir="ltr">
                  <span className="span">
                    미팅이 생성되면 자동으로 발송되는 체결안내 메일은 언제
                    발송할지 주최자가 정할 수 있고 자동발송 기능을 끄거나 켤
                    수도 있습니다.
                  </span>
                </p>
                <Header3 className="header3 index" dir="ltr">
                  미팅불가 설정하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">비어 있는 미팅일정의 &lsquo;</span>
                  <Bold>미팅불가</Bold>
                  <span className="span">
                    &rsquo; 버튼을 클릭하여 미팅불가 상태로 변경합니다. 미팅불가
                    상태에서는 다른 참가자들이 해당 일시에 미팅을 신청할 수
                    없습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[11]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  화상 연결하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    체결된 미팅은 참가자가 서로 화상연결을 통해 온라인 미팅을
                    진행할 수 있습니다. 미팅일정 카드의 화상연결 버튼을 클릭시
                    마이크와 카메라 권한을 요청하는 창이 최초 1회 발생하며
                    참여하기 버튼 클릭시 온라인 미팅 화면으로 이동합니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[12]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  미팅상태 필터링하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    4가지 미팅상태에 따라 미팅일정을 분류할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[13]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>신청중</Bold>
                      <span className="span">
                        . 해당 기업이 매칭 신청을 보낸 상태입니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>수락대기중</Bold>
                      <span className="span">
                        . 해당 기업이 다른 기업으로부터 받은 매칭 신청을 수락
                        대기중인 상태입니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>미팅체결</Bold>
                      <span className="span">
                        . 해당 기업이 매칭 신청을 보내거나 받은 뒤 수락이 완료된
                        상태입니다.
                      </span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        * 주최사가 주선한 일정도 포함됩니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>불가시간설정</Bold>
                      <span className="span">
                        . 해당 기업이 불가한 미팅일정입니다.
                      </span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        *주최사가 지정한 미팅불가 일시의 미팅일정 카드는
                        해당되지 않습니다.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index" dir="ltr">
                  온라인 미팅 기능 <span></span>
                </Header3>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[14]}
                  ></Image>
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">마이크 끄고 / 켜기&nbsp;</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">카메라 끄고 / 켜기</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">화면 공유하기 켜기 / 끄기</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        상담일지 작성하기 켜기 / 끄기
                      </span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        주최사가 상담일지 양식을 만든 경우에만 활성화 됩니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">채팅 켜기 / 끄기</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">온라인 미팅 종료</span>
                    </p>
                  </li>
                </ol>
                <Header2 className="header2 index" dir="ltr">
                  담당자 목록 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    직원들 명부를 불러옵니다. 이벤트에 참가신청이 완료된
                    사용자는 직원을 추가할 수 있습니다. 추가한 직원은 기존
                    사용자와 같은 권한을 가지게 되며 참가한 이벤트에 모든 기능을
                    사용할 수 있습니다.&nbsp;
                  </span>
                </p>
                <Header3 className="header3 index" dir="ltr">
                  담당자 추가하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    상단의 추가하기 버튼 클릭시 해당 화면으로 이동합니다. 추가할
                    직원의 정보를 입력하고 저장 버튼을 클릭하면 안내 메일이
                    발송됩니다. 안내 메일을 받은 참가자는 비밀번호를 생성하고
                    이용할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[15]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  담당자 내역보기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    추가한 직원이 안내 메일을 확인하고 접속했는지 여부를 확인할
                    수도 있습니다. 상단의 내역보기 버튼 클릭시 해당 화면으로
                    이동합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={files[16]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  통역 접속하기 <span></span>
                </Header2>
                <p dir="ltr" style={{ marginBottom: 200 }}>
                  <span className="span">상단의 &lsquo;</span>
                  <Bold>My 버튼</Bold>
                  <span className="span">
                    &rsquo; 클릭시 통역사로 지정된 이벤트 목록을 볼 수 있으며
                    클릭시 해당 화면으로 이동합니다. 통역사는 이벤트 접속시
                    지정된 온라인 화상 미팅에 참가할 수 있습니다.
                  </span>
                </p>
              </>
            )}
            <Header
              style={{
                display: page === "createEvent" ? "block" : "none",
              }}
              className="header index createEvent"
              dir="ltr"
            >
              이벤트 만들기 <span></span>
            </Header>
            {page === "createEvent" && (
              <>
                <p dir="ltr">
                  <span className="span">상단의 &lsquo;</span>
                  <Bold>My 버튼</Bold>
                  <span className="span">
                    &rsquo; 클릭시 나오는 메뉴의 &lsquo;
                  </span>
                  <Bold>새로운 이벤트 개설하기</Bold>
                  <span className="span">&apos; 또는 &lsquo;</span>
                  <Bold>host.pexpo.io</Bold>
                  <span className="span">
                    &rsquo;를 통해 주최사 화면으로 이동할 수 있습니다. 기획 중인
                    이벤트를 등록하기 위해서 아래의 과정이 필요합니다.
                  </span>
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>이벤트 개설</Bold>
                      <span className="span">
                        . 이벤트 개요 화면에 출력되는 항목들을 차례대로 입력하고
                        선택 또는 첨부합니다.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>하위이벤트 개설</Bold>
                      <span className="span">
                        . 이벤트 구분, 참가그룹 명칭 노출범위, 신청 기간 등을
                        설정합니다.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>양식 만들기</Bold>
                      <span className="span">
                        . 참가그룹별 참가신청 양식, 상담일지 양식 등을 만듭니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>승인요청과 승인완료.</Bold>
                      <span className="span">&nbsp;</span>
                    </p>
                  </li>
                </ol>
                <Header2 className="header2 index" dir="ltr">
                  이벤트 개설하기 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    상단의 이벤트 개설 버튼을 클릭하면 해당 화면으로 이동합니다.
                    이벤트가 승인된 이후에도 주최지와 기간을 제외한 나머지
                    항목들은 수정할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[1]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  장소 여부 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    오프라인 이벤트인지 온라인 이벤트인지 설정합니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[2]}
                  ></Image>
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>Google Place</Bold>
                      <span className="span">
                        . 국내외 사용자들을 위한 Google Place의 지도보기가 기본
                        서비스로 제공되며 주소 입력시 자동완성 기능을
                        지원합니다.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>직접 입력</Bold>
                      <span className="span">
                        . 부득이하게 Google Place에서 주소가 검색되지 않는 경우
                        수동입력 버튼을 클릭하여 주소를 직접 입력할 수 있습니다.
                        다만 사용자에게 지도보기 서비스는 제공되지 않습니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>체크박스 해제</Bold>
                      <span className="span">
                        . 온라인 전용 이벤트인 경우, 장소 여부의 체크박스 해제
                        시 단계를 건너 뛸 수 있습니다.&nbsp;
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index" dir="ltr">
                  웹사이트 여부 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    입력한 웹사이트 주소를 개요화면에 &ldquo;
                  </span>
                  <Bold>바로가기</Bold>
                  <span className="span">
                    &rdquo; 버튼으로 생성할 수 있습니다. 이벤트를 위한 전용
                    홈페이지가 없는 경우에는 체크박스를 해제하여 끌 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[3]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  문의 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    최소 1명의 담당자가 필요하며 최대 3명까지 추가할 수
                    있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[4]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  이벤트 목록 상세 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    이벤트 목록 중 하나의 이벤트를 클릭하면 상세 화면으로
                    이동합니다. 이벤트 목록 상세화면에서는 새로운 하위 이벤트를
                    개설하거나 하위 이벤트를 수정할 수 있으며 개요 화면에
                    출력되는 정보도 수정할 수 있습니다. 이벤트를 등록하기
                    위해서는 최소 한개 이상의 이벤트를 필요로 합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[5]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  하위 이벤트 개설하기 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    이벤트 목록 상세 화면에서 오른쪽에 위치한 &lsquo;
                  </span>
                  <Bold>하위 이벤트 개설하기</Bold>
                  <span className="span">
                    &rsquo; 버튼을 클릭하면 해당 화면으로 이동합니다. 이벤트
                    구분, 참가그룹 명칭 노출범위, 신청 기간 등을 설정할 수
                    있습니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[6]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>

                <p dir="ltr">
                  <span className="span">
                    개설된 하위 이벤트는 하위 이벤트 목록의 설정 버튼 클릭시
                    수정을 할 수 있으며 이벤트가 승인된 이후에는 하위 이벤트
                    기간과 미팅 시작시각, 미팅 소요시간, 휴식시간, 일일 미팅
                    횟수를 제외한 나머지 항목만 수정할 수 있습니다.
                  </span>
                </p>

                <p dir="ltr">
                  <span className="span">
                    하나의 이벤트에는 복수개의 하위 이벤트를 생성할 수 있으며
                    참가자는 각각 신청하여 이용할 수 있습니다.
                  </span>
                </p>

                <Header3 className="header3 index" dir="ltr">
                  매치메이킹 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    참가자간 서로 미팅을 신청할 수 있고 주최사가 참가자들의
                    미팅을 주선할 수 있습니다. 수락된 미팅은 화상 연결을 통해
                    온라인 미팅으로도 진행 할 수 있습니다. 참가자들의 화상
                    연결은 주최사가 접속했는지 여부를 모니터링 기능을 통해
                    확인할 수 있고 주최사도 참가자들의 화상 미팅에 참여할 수
                    있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[7]}
                  />
                </p>

                <p dir="ltr">
                  <span className="span">
                    매칭 카테고리와 키워드를 연산하여 가장 연관성이 높은 기업과
                    매칭을 할 수 있도록 해줍니다. 매칭 카테고리는 참가그룹A의
                    카테고리로 사용됩니다.
                  </span>
                </p>
                <Header3 className="header3 index" dir="ltr">
                  참가기업 노출범위 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    같은 참가그룹내 기업들의 공개 여부입니다. &lsquo;
                  </span>
                  <Bold>전체</Bold>
                  <span className="span">
                    &rsquo; 선택 시 참가그룹A와 참가그룹B의 기업은 참가그룹A와
                    참가그룹B의 모든 기업정보를 볼 수 있습니다. &lsquo;
                  </span>
                  <Bold>상대기업</Bold>
                  <span className="span">
                    &rsquo;으로 선택 시 참가그룹A와 B는 속하지 않은 참가그룹 A와
                    B의 기업들만 볼 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[8]}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  미팅 불가시간 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    미팅 시작 시각, 미팅 소요 시간, 휴식시간, 일일 미팅 횟수를
                    선택하면 &lsquo;미팅 예상 종료 시각&rsquo; 하단에 전체
                    미팅카드가 생성됩니다. 이벤트 기간내 점심시간 등 공통적으로
                    사용 안 할 미팅시간을 정합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[9]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  양식 만들기 <span></span>
                </Header2>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[10]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <p dir="ltr">
                  <span className="span">생성된 이벤트에 &lsquo;</span>
                  <Bold>양식 만들기</Bold>
                  <span className="span">
                    &rsquo; 버튼을 클릭하면 참가그룹별 참가신청 양식, 상담일지
                    양식(, 사후설문 양식)을 만들 수 있는 화면으로 이동합니다.
                    참가신청 양식은 만들지 않으면 &lsquo;승인 요청&apos;을 할 수
                    없으며 이벤트 승인시 모든 양식은 수정은 불가합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[11]}
                  />
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>참가신청</Bold>
                      <span className="span">
                        . 온라인 등록을 위해서 필수로 만들어야 하는 양식입니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>상담일지</Bold>
                      <span className="span">
                        . 취합이 용이한 온라인 상담일지 입니다. 필수가 아닌
                        양식입니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>사후설문</Bold>
                      <span className="span">
                        . 현재 지원하지 않는 양식입니다. (업데이트 예정)
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index" dir="ltr">
                  기본 항목 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    기업명, 카테고리, 키워드는 삭제할 수 없는 항목으로 주최사는
                    중복으로 구성할 필요가 없습니다. 기업명과 카테고리는
                    참가자가 반드시 입력하거나 선택해야 하는 필수 항목입니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[12]}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  항목의 툴바 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">&lsquo;</span>
                  <Bold>항목 추가하기</Bold>
                  <span className="span">
                    &rsquo; 버튼을 클릭하거나 생성된 질문을 클릭하면 질문이
                    확대되어 툴바를 불러올 수 있습니다. 툴바에는 다음과 같은
                    기능을 제공합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[13]}
                  />
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        드래그시 항목의 순서를 위 또는 아래로 이동할 수
                        있습니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        6~7가지 항목의 종류를 선택할 수 있습니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">항목을 제거합니다.</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        확대된 항목의 아래 순서에 복사합니다.
                      </span>
                    </p>
                  </li>
                </ol>

                <div dir="ltr" style={{ marginLeft: "0pt" }}>
                  <table className="table">
                    <tbody>
                      <Tr1>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">단일선택</span>
                          </p>
                        </td>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">
                              제목과 보기 내용을 작성할 수 있고 선택 보기의
                              개수는 늘리거나 줄일 수 있습니다. 참가자가 선택할
                              수 있는 보기의 개수는 1개로 제한됩니다.
                            </span>
                          </p>
                        </td>
                      </Tr1>
                      <tr style={{ height: "0pt;" }}>
                        <td className="td2">
                          <p dir="ltr">
                            <span className="span">복수선택</span>
                          </p>
                        </td>
                        <td className="td2">
                          <p dir="ltr">
                            <span className="span">
                              제목과 보기 내용을 작성할 수 있고 보기의 개수는
                              늘리거나 줄일 수 있습니다. 참가자가 선택할 수 있는
                              보기의 개수에 제한이 없습니다.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "0pt;" }}>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">단문입력</span>
                          </p>
                        </td>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">
                              제목을 작성합니다. 응답 칸에 120자 이하의 단문을
                              입력 가능할 수 있습니다.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "0pt;" }}>
                        <td className="td2">
                          <p dir="ltr">
                            <span className="span">장문입력</span>
                          </p>
                        </td>
                        <td className="td2">
                          <p dir="ltr">
                            <span className="span">
                              제목을 작성합니다. 참가자는 응답 칸에 1,200자
                              이하의 장문을 입력할 수 있습니다.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "0pt;" }}>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">파일첨부</span>
                          </p>
                        </td>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">
                              파일을 받기 위한 질문 제목을 작성합니다. 참가자는
                              jpg, png, pdf 등의 파일을 업로드 할 수 있습니다.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "0pt;" }}>
                        <td className="td2">
                          <p dir="ltr">
                            <span className="span">URL 링크</span>
                          </p>
                        </td>
                        <td className="td2">
                          <p dir="ltr">
                            <span className="span">
                              URL 주소를 입력받기 위한 질문 제목을 작성합니다.
                            </span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Header3 className="header3 index" dir="ltr">
                  항목의 옵션 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    각각의 항목에는 다음과 같은 옵션이 제공되며 주최사는 사용
                    또는 사용 안 함을 선택할 수 있습니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[14]}
                  />
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        제목 밑에 설명을 입력할 수 있게 해줍니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        주최사와 신청자만 볼 수 있게 해줍니다. 다른 참가자에게
                        공개되지 않습니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        참가자는 필수 질문을 건너뛰고 다음 단계로 이동할 수
                        없습니다.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index" dir="ltr">
                  추가정보(상품정보) <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    제품명, 제품가격, 최소구매수량, 제품 사진 등 참가자가
                    반복해서 입력할 질문을 구성할 수 있는 화면입니다. 해당사항이
                    없으면 건너뛸 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    <Image
                      className="lazy"
                      src={Pexpologo}
                      data-src={makeEventFiles[15]}
                    />
                  </span>
                </p>
                <Header2 className="header2 index" dir="ltr">
                  승인요청하기 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    이벤트 개설, 하위 이벤트 개설, 양식 만들기 단계를 모두
                    완료하였다면 &lsquo;승인요청&rsquo; 버튼을 클릭하면 담당자의
                    검토가 이루어지며 개선되면 사항이 있는 경우 개별적으로
                    연락을 드립니다. 승인 완료시에는 안내 이메일이
                    발송됩니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[16]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
              </>
            )}
            <Header
              style={{
                display: page === "manageEvent" ? "block" : "none",
              }}
              className="header index manageEvent"
              dir="ltr"
            >
              이벤트 관리하기 <span></span>
            </Header>
            {page === "manageEvent" && (
              <>
                <p dir="ltr">
                  <span className="span">
                    이벤트 목록 상세화면에 하위 이벤트를 클릭시 이벤트
                    관리모드에 접속할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[1]}
                  />
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>탑바</Bold>
                      <span className="span">
                        . 이벤트 제목과 하위 이벤트 구분, 전체 이벤트 기간, 하위
                        이벤트 기간입니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>전용메뉴</Bold>
                      <span className="span">
                        . 하위 이벤트 설정으로 활성화된 메뉴입니다.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header2 className="header2 index" dir="ltr">
                  참가기업 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    참가 신청한 모든 참가자를 볼 수 있습니다. 참가자 클릭시
                    상세화면으로 이동하며 정보도 수정할 수 있습니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[2]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>

                <p dir="ltr">
                  <span className="span">목록의 오른쪽 상단의 &lsquo;</span>
                  <Bold>내려받기</Bold>
                  <span className="span">
                    &rsquo; 버튼 클릭시 화면에 보이는 참가자를 CSV파일로
                    내려받습니다.
                  </span>
                </p>
                <Header3 className="header3 index" dir="ltr">
                  참가자 확정하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    확정되지 않은 참가자는 참가자 목록을 볼수 없고, 모든 기능이
                    제한됩니다. 주최사가 확정 버튼 클릭시 참가자에게 안내메일이
                    발송됩니다. 확정 버튼은 목록과 상세화면에 존재합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[3]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  미팅현황 <span></span>
                </Header2>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[4]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <p dir="ltr">
                  <span className="span">
                    참가기업 메뉴에서 확정된 참가자만 불러옵니다. 클릭시 해당
                    참가기업의 상세 화면으로 이동하며 선택된 기업의 미팅일정을
                    조정할 수 있습니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[5]}
                  />
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>전체 매칭(TIGHT)</Bold>
                      <span className="span">
                        . 참가그룹간 매칭 카테고리와 키워드가 일치한 참가자들을
                        자동 주선합니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>전체 매칭</Bold>
                      <span className="span">
                        . 참가그룹간 매칭 카테고리만 일치한 참가자들을 자동
                        주선합니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>전체 매칭 취소</Bold>
                      <span className="span">
                        . 모든 참가자의 매칭을 취소합니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>메일발송 기능 스위치</Bold>
                      <span className="span">
                        . 스위치가 On 상태일때, 주최사가 주선하거나 취소한
                        결과를 참가자 메일로 발송합니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>전체 체결 메일 발송</Bold>
                      <span className="span">
                        . 모든 참가자의 매칭이 체결된 현황 안내 메일을 일괄
                        발송합니다.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index" dir="ltr">
                  미팅상태 필터링하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    4가지 미팅상태에 따라 미팅일정을 분류할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[6]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>신청중</Bold>
                      <span className="span">
                        . 해당 기업이 매칭 신청을 보낸 상태입니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>수락대기중</Bold>
                      <span className="span">
                        . 해당 기업이 다른 기업으로부터 받은 매칭 신청을 수락
                        대기중인 상태입니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>미팅체결.</Bold>
                      <span className="span">
                        &nbsp;해당 기업이 매칭 신청을 보내거나 받은 뒤 수락이
                        완료된 상태입니다.
                      </span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        * 주최사가 주선한 일정도 포함됩니다.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <Bold>불가시간설정</Bold>
                      <span className="span">
                        . 해당 기업이 불가한 미팅일정입니다.
                      </span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        *주최사가 하위 이벤트 설정때 불가한 시간은 해당되지
                        않습니다.
                      </span>
                    </p>
                  </li>
                </ol>

                <Header3 className="header3 index" dir="ltr">
                  시간대 변경하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    접속국가를 클릭하여 화면에 출력되는 시간을 선택한 국가의
                    시간대로 변경할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[7]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  미팅 주선하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">비어 있는 미팅일정의 &lsquo;</span>
                  <Bold>+</Bold>
                  <span className="span">
                    &rsquo; 버튼을 클릭하여 참가기업을 선택합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[8]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  체결된 미팅 취소하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    체결된 미팅일정의 &lsquo;/&rsquo; 버튼을 클릭하여
                    취소합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[9]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  미팅불가 설정하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    비어 있는 미팅일정의 &lsquo;/&rsquo; 버튼을 클릭하여
                    미팅불가 상태로 변경합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[10]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  미팅불가 취소하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    미팅불가 일정의 &lsquo;/&rsquo; 버튼을 클릭하여 미팅불가
                    상태를 취소합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[11]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  매칭기록 <span></span>
                </Header2>
                <Header3
                  className="header3 index"
                  style={{ marginTop: 30 }}
                  dir="ltr"
                >
                  체결된 모든 매칭 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    MATCHING 탭에서 참가기업간 체결된 모든 미팅일정을 일자별,
                    시간순으로 열람할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    * 참가자가 스스로 매칭불가 처리한 미팅일정 카드는 미팅상태를
                    &lsquo;
                  </span>
                  <Bold>impossible</Bold>
                  <span className="span">&rsquo;이라고 출력합니다.</span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[12]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  주고받은 매칭내역 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    HISTORY 탭을 선택시 참가자가 신청하고 수락한 매칭 외 취소한
                    내역과 주최사가 주선하거나 자동매칭한 기록까지 모두 열람할
                    수 있습니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[13]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  상담일지 내역 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    상담일지 내역에서 기업별로 미작성된 건과 작성된 건 수를 볼
                    수 있고 내려받기 버튼 클릭시 CSV파일로 내려받을 수 있습니다.
                    상담일지는 매칭이 체결되면 참가자가 작성할 수
                    있습니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[14]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  통역사 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    주최사가 통역사로 지정한 사용자 목록을 불러옵니다. 주최사는
                    통역이 필요한 화상상담에 통역사를 배치할 수 있으며 주최사가
                    지정한 통역사는 화상미팅에 참가할 수 있습니다. 선택한
                    통역사를 클릭하면 상세화면으로 이동하며 통역과 관련된 내용을
                    수정할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[15]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  통역사 등록하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    오른쪽 상단에 통역사 등록 버튼을 클릭시 해당 화면으로
                    이동합니다. 통역사를 등록하기 위해서는 사용자
                    계정(회원가입)이 필요합니다. 유저ID에 통역사로 등록할 사용자
                    email을 입력하면 하단에 자동완성되어 등록할 수 있는 계정을
                    불러옵니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[16]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>

                <p dir="ltr">
                  <span className="span">
                    미팅기업명(다중선택)에는 매칭이 체결된 참가기업을 입력시
                    자동완성되어 목록을 불러옵니다. 이어서 배치할 미팅일정을
                    체크하시고 오른쪽 하단의 등록 버튼 클릭시 배치가
                    완료됩니다.&nbsp;
                  </span>
                </p>
                <Header2 className="header2 index" dir="ltr">
                  스피커 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    주최사가 등록한 스피커 목록을 불러옵니다. 스피커는 사용자
                    계정이 필요없으며 오른쪽 상단의 스피커 추가 버튼 클릭시 해당
                    화면으로 이동할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[17]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  프로그램 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    주최사가 등록한 프로그램 목록을 불러옵니다. 프로그램은
                    기조연설 및 초청강연 등 다양하게 구성할 수 있습니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[18]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header3 className="header3 index" dir="ltr">
                  프로그램에 스피커 추가하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    스피커가 1명 이상 등록된 경우에만 스피커를 배치할 수
                    있습니다. 오른쪽 상단의 &lsquo;
                  </span>
                  <Bold>프로그램 추가</Bold>
                  <span className="span">
                    &rsquo; 버튼 클릭시 해당 화면으로 이동하며 하단의 스피커
                    항목에서 스피커 목록을 불러옵니다. 불러온 스피커 목록 중 한
                    명을 선택 하고 &lsquo;
                  </span>
                  <Bold>프로그램 등록</Bold>
                  <span className="span">
                    &rsquo; 버튼 클릭시 스피커 등록이 완료됩니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[19]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  공지사항 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    이벤트 참가한 사람들이 메뉴에서 볼 수 있도록 공지사항을
                    작성할 수 있습니다. 새로운 공지사항은 오른쪽 상단의 &lsquo;
                  </span>
                  <Bold>공지사항 추가</Bold>
                  <span className="span">
                    &rsquo; 버튼 클릭시 해당 화면으로 이동하여 작성할 수
                    있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[20]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  메일 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    주최사가 발송한 메일 목록을 불러옵니다. 매칭결과 안내 메일
                    등 자동으로 발송이 완료된 메일도 확인할 수 있습니다. 오른쪽
                    상단의 &lsquo;
                  </span>
                  <Bold>메일 발송</Bold>
                  <span className="span">
                    &rsquo; 버튼 클릭시 해당 화면으로 이동하며 받는사람을
                    선택하여 발송할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[21]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  SMS <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    주최사가 발송한 SMS 목록을 불러옵니다. 오른쪽 상단의 &lsquo;
                  </span>
                  <Bold>SMS 발송</Bold>
                  <span className="span">
                    &rsquo; 버튼 클릭시 해당 화면으로 이동하며 받는사람을
                    선택하여 발송할 수 있습니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[22]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  데이터 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    이벤트에 등록된 참가기업과 미팅현황, 상담일지 데이터를 각각
                    내려받을 수 있습니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[23]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  모니터링 <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    온라인 상담시 모든 참가자의 미팅현황을 모아둔 화면으로
                    참가자들의 화상이 연결되어 있는지 확인할 수 있습니다.
                    화상미팅에 접속한 참가자는 초록색 불이 들어오고 접속하지
                    않은 참가자는 빨간색 불이 들어옵니다.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[24]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>

                <p dir="ltr">
                  <span className="span">
                    주최사는 미팅일정 클릭시 화상미팅에 접속할 수 있습니다.
                    마이크와 카메라 권한을 허용하셔야 이용할 수 있습니다.&nbsp;
                  </span>
                </p>
                <Header3 className="header3 index" dir="ltr">
                  시간대 변경하기 <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    상단의 국가명 클릭시 변경 가능한 국가와 도시를 불러오고
                    선택함으로 시간대를 변경할 수 있습니다. 오른쪽에는 변경된
                    시간대의 현재 시간을 표시합니다.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[25]}
                    autoPlay={true}
                    loop={true}
                  />
                </p>
              </>
            )}
          </Contents>
        ) : (
          <Contents>
            <Header className="header index participateEvent" dir="ltr">
              Online meeting functions
            </Header>
            {page === "participateEvent" && (
              <>
                <Header2 className="header2 index" dir="ltr">
                  Apply to participate <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    Selecting the application on the right of the overview
                    screen will take you to the event application screen. Once
                    you have completed your registration, you will receive an
                    e-mail and will be waiting for the organizer&rsquo;s
                    approval.
                  </span>
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Connect event <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    Only participants confirmed by the organizer are allowed to
                    access. Confirmation can be found on the overview screen in
                    the application. Once the organizer completes the
                    confirmation, an e-mail is sent to the participants.
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    On the overview screen, click the confirmed application to
                    enter the connected page.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[1]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Interface <span></span>
                </Header2>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={files_en[2]}
                  />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        <Bold>classify.</Bold> shows selected participating
                        type..
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        <Bold>modify.</Bold> a button move to the application
                        screen.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        <Bold>my info.</Bold> shows a name and corporate typed
                        in the application.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        <Bold>connected page.</Bold> shows an active menu.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        <Bold>top bar.</Bold> shows event name and type.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header2 className="header2 index" dir="ltr">
                  Participant list <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    It shows the participant list and navigates the details when
                    clicked.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[3]}
                  />
                </p>
                <Header3 className="header3 index">
                  Chatting<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Click the person in charge in the contact tab, the chat
                    window is pushed to the right, you can type the text at the
                    bottom of the input window.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[4]}
                  />
                </p>
                <Header3 className="header3 index">
                  Send a contact<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Click the &ldquo;send my info&rdquo; button in the chat
                    window to send my email and phone number.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[5]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Chatting list <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    It shows the chat list, if a chat history with person more
                    than once in the participant list, *chat history in the
                    online video meeting is not applicable.&rdquo;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[6]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Meeting status <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    Show a meeting schedule set by the organizer and allow
                    participants to meet on the date and time set.
                  </span>
                </p>
                <Header3 className="header3 index">
                  Request meeting<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;request&rdquo; button on the blank meeting
                    schedule card. the card brings up a participant list for the
                    meeting available and comes to the confirmation window when
                    clicking the list.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[7]}
                  />
                </p>
                <p>
                  <br />
                </p>
                <p dir="ltr">
                  <span className="span">
                    E-mail will be sent both the applicant and recipient of the
                    meeting
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    &ldquo;you can apply for the meeting request on a different
                    date and time in duplicately&rdquo;&nbsp;
                  </span>
                </p>
                <Header3 className="header3 index">
                  Cancel meeting<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    It cancels a meeting that you have requested or already
                    concluded, click &ldquo;cancel&rdquo; button on the meeting
                    schedule card to cancel the meeting.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[8]}
                  />
                </p>
                <p dir="ltr">
                  <span className="span">
                    Cancellation of e-mail is sent to both the applicant and
                    recipient of the meeting.
                  </span>
                </p>
                <Header3 className="header3 index">
                  Accept meeting<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Meeting request can be received from multiple participants
                    on the same date and time.. You can click &ldquo;list&rdquo;
                    button to see a number of participants who have received the
                    requested meeting. When clicked, bring up a window for the
                    list of a waiting accept meeting.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    Select the participant you want to meet and click
                    &ldquo;accept&rdquo; to change the concluded status and
                    e-mails are sent &nbsp;to both the participants who
                    requested and accepted it.
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    E-mail are sent to unselected participants saying they have
                    declined
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[9]}
                  />
                </p>
                <p>
                  <br />
                </p>
                <Header3 className="header3 index">
                  Arrange meeting by organizer
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Meetings arranged by the organizer are generated in the
                    concluded status without the participant&apos;s
                    acceptance.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[10]}
                  />
                </p>
                <p dir="ltr">
                  <span className="span">
                    When a meeting is generated, the organizer can determine the
                    time of concluded e-mail and also can switch the auto-send
                    feature..
                  </span>
                </p>
                <Header3 className="header3 index">
                  Set meeting unavailable
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;meeting unavailable&rdquo; button in the blank
                    meeting schedule card to change the status. Participants
                    cannot request a meeting at this status.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[11]}
                  />
                </p>
                <Header3 className="header3 index">
                  Connect video meeting
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    a concluded meeting allows participants to conduct a video
                    meeting. When clicking &ldquo;video connection&rdquo; button
                    on the meeting schedule card, the mic. and camera
                    permissions are requested for the first time and clicking
                    &ldquo;participate&rdquo; button will take you to the video
                    meeting screen.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[12]}
                  />
                </p>
                <Header3 className="header3 index">
                  Filter meeting status
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Classify the meeting schedule according to the 4 meeting
                    status.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[13]}
                  />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        requesting. the corporate has sent a meeting request.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        awaiting acceptance. the corporate is waiting to accept
                        the request from another corporate.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        meeting concluded. the accepted meeting has been
                        completed after the corporate has sent or received a
                        request for the meeting.
                      </span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        * Including the schedules arranged by the organizer.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        set meeting unavailable. The meeting schedule is not
                        allowed for the corporate.
                      </span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        *the meeting unavailable designated by the organizer is
                        not applicable.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index">
                  Online meeting functions
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={files_en[14]}
                  />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">Microphone. on/off&nbsp;</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">Camera on/off</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">Share on/off</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">Meeting note on/off</span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        activated only when the organizer has set a form of
                        meeting note.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">Chatting on/off</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">Meeting closed</span>
                    </p>
                  </li>
                </ol>
                <Header2 className="header2 index" dir="ltr">
                  Invite colleague <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    It shows the list of colleagues. Users who have completed
                    their application for the event can be added a colleague.
                    added one will have the same privileges as the existing user
                    and will be able to use all features for the event&nbsp;
                  </span>
                </p>
                <Header3 className="header3 index">
                  Add colleague<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;add&rdquo; button on the top to navigate to the
                    next page, type the information of the colleague and save
                    it. the e-mail will be sent to the person and the person who
                    received e-mail can use the event with generating password
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[15]}
                  />
                </p>
                <Header3 className="header3 index">
                  Colleague list<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;history view&rdquo; button at the top to
                    navigate to the next page. check the status of the colleague
                    you added,&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={files_en[16]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Connect interpreter <span></span>
                </Header2>
                <p dir="ltr" style={{ marginBottom: 220 }}>
                  <span className="span">
                    Click &ldquo;MY&rdquo; button at the right top to see a list
                    of events designated as interpreter and interpreters can
                    participate in designated online video meetings when event
                    connected.
                  </span>
                </p>
              </>
            )}
            <Header
              style={{
                display: page === "createEvent" ? "block" : "none",
              }}
              className="header index createEvent"
              dir="ltr"
            >
              Make event
            </Header>
            {page === "createEvent" && (
              <>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;MY&rdquo; button to navigate to the &ldquo;make
                    new event&rdquo; or go to &ldquo;host.pexpo.io&rdquo; on the
                    web. The following process is required to create the event
                    you are planning
                  </span>
                </p>

                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Open event. type, select and attach items that are shown
                        on the event overview&nbsp;
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Open sub-event. set event classify, participating group
                        name, exposure range, registration period etc.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Create form. make a form of registration and meeting
                        note for each participating group.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Request approval and complete.&nbsp;
                      </span>
                    </p>
                  </li>
                </ol>
                <Header2 className="header2 index" dir="ltr">
                  Make event <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;open event&rdquo; button at the top to navigate
                    to the next page. The rest of the items &nbsp;except for the
                    place and period can be modified even after the event is
                    approved
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={makeEventFiles[1]}
                  />
                </p>
                <Header3 className="header3 index">
                  Check place<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    set whether it&rsquo;s an offline or online event&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[2]}
                  />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Google Place. Map of Google place for uses are provided
                        basically, supports auto -complete function when typing
                        the address&nbsp;
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Direct typing. &nbsp;If you can&apos;t find an address
                        in Google place, type the address manually &nbsp;by
                        clicking the &ldquo;direct typing&rdquo; button, but map
                        viewing service is not provided&nbsp;
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Uncheck the box. in case of &nbsp;an online event only,
                        can skip the steps when uncheck &nbsp;the box for
                        location
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index">
                  Check website<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Create &ldquo;shortcut&rdquo; button for the website address
                    on the overview screen. if you don&apos;t have a dedicated
                    website, you can release the checkbox to close the function.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={makeEventFiles[3]}
                  />
                </p>
                <Header3 className="header3 index">
                  Inquiry<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    at least one person is required and up to there can be
                    added.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={makeEventFiles[4]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Event list details <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    Click one event in the event list to navigate to the detail
                    page. the detail screen allows to make a new sub-event or
                    modify sub-event and information in the overview screen, at
                    least one event is required to register an event.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={makeEventFiles[5]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Make sub event <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;open sub-event&rdquo; button on the right in
                    the detail screen to navigate to the next page. set the
                    event classification, exposure range of participating group
                    and registration period&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={makeEventFiles[6]}
                  />
                </p>
                <p dir="ltr">
                  <span className="span">
                    Opened sub-event can be modified when clicking
                    &ldquo;set&rdquo; button in the sub-event list, after the
                    event is approved, you can modify registration period,
                    participating group name and exposure range.
                  </span>
                </p>

                <p dir="ltr">
                  <span className="span">
                    A single event can generate multiple sub-events and each
                    participant can register and use the event respectively
                  </span>
                </p>
                <Header3 className="header3 index">
                  Matchmaking<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Participants can request meetings with each other, and
                    organizer can arrange participant&rsquo;s meetings. You can
                    also proceed to an online meeting through a video connection
                    in the concluded meeting. The participants&apos; video
                    connections can be checked through the monitoring function.
                    The organizer can also join the participants&apos; video
                    meetings via video connection.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[7]}
                  />
                </p>
                <p dir="ltr">
                  <span className="span">
                    It provides a matching list of &nbsp;the most relevant
                    corporate by calculating matching categories and keywords.
                    Matching categories are used as categories for participating
                    group A.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <Header3 className="header3 index">
                  Participant exposure range
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    option to open the participating group. when selecting
                    &ldquo;all&rdquo;, corporate in Group A and Group B will be
                    able to view all information in Group A and Group B&lsquo;,
                    when selecting &ldquo;the other side&rdquo; corporate in
                    Group A and Group B are visible to participating group A and
                    group B that do not belong to.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[8]}
                  />
                </p>
                <Header3 className="header3 index">
                  Meeting unavailable<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Setting &ldquo;meeting start time&rdquo;, &ldquo;meeting
                    duration&rdquo; &ldquo; rest time&rdquo; and &ldquo; number
                    of daily meeting&rdquo; will generate a meeting card at the
                    bottom of &ldquo;estimate close time&rdquo;. Set a common
                    non-meeting time such as lunch time during the event period.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={makeEventFiles[9]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Make registering form <span></span>
                </Header2>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={makeEventFiles[10]}
                  />
                </p>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;create form&rdquo; button to navigate to the
                    next page where you can create a form of registration,
                    meeting note and, (survey) for each participating group. if
                    you don&rsquo;t create a form, you can&rsquo;t go to
                    &ldquo;approval request&rdquo; and all forms can&apos;t be
                    modified when the event is approved.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[11]}
                  />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Registration. This is a form that must be made for
                        online registration
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Meeting note. This is a form that collects easier,
                        it&rsquo;s optional.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Post-survey. This is a form that collects surveys.
                        it&rsquo;s not available(updated soon)&nbsp;
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index">
                  Default question<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    &ldquo;corporate name&rdquo; &ldquo;category&rdquo; &ldquo;
                    keyword&rdquo; can&rsquo;t be deleted and the organizer
                    doesn&rsquo;t need to duplicate them in the registration
                    form. &ldquo;corporate name&rdquo; &ldquo;category&rdquo;
                    are mandatory items.
                  </span>
                </p>
                <p dir="ltr">
                  <span>
                    1Image fault-form@make-event-kr.png + shadow + border
                  </span>
                </p>
                <Header3 className="header3 index">
                  Item tool bar<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    click &ldquo;add question&rdquo; or &ldquo;generated
                    questions&rdquo; to show a toolbar. the toolbar provides the
                    following features&lsquo;
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[13]}
                  />
                </p>
                <p>
                  <br />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        you can move the order of questions up or down when
                        dragged.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        you can choose 6~7 types of questions.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">you can delete questions.</span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        you can copy the expanded question to the order below..
                      </span>
                    </p>
                  </li>
                </ol>
                <p>
                  <br />
                </p>
                <div dir="ltr">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">Single select</span>
                          </p>
                        </td>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">
                              type question title and selections, you can
                              control the number of choices. Participants are
                              limited to select one .
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">
                            <span className="span">Multiple select</span>
                          </p>
                        </td>
                        <td>
                          <p dir="ltr">
                            <span className="span">
                              type question title and selections, you can
                              control the number of choices. there is no limit
                              to select in the list .
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">Short text type</span>
                          </p>
                        </td>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">
                              type the question title. short text is up to 120
                              characters can be entered in the box
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">
                            <span className="span">Long text type</span>
                          </p>
                        </td>
                        <td>
                          <p dir="ltr">
                            <span className="span">
                              type the question title. participants can typer no
                              more than 1,200 characters in the box.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">File attach</span>
                          </p>
                        </td>
                        <td className="td1">
                          <p dir="ltr">
                            <span className="span">
                              type the question title to get the file,
                              participants can upload file such as jpg. png. pdf
                              etc.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">
                            <span className="span">URL link</span>
                          </p>
                        </td>
                        <td>
                          <p dir="ltr">
                            <span className="span">
                              type the question title to get the address, URL
                              needs to add &ldquo;http://&rdquo;
                            </span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Header3 className="header3 index">
                  Items option<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Each question has the following options and the organizer
                    can choose to enable or disable&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[14]}
                  />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        It allows to type a description under the title..
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        only allows organizer and applicants to see it,
                        it&apos;s not open to other participants.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        participants can&rsquo;t skip the required questions and
                        move to the next step.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index">
                  Additional information(product information)
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    It allows participants to configure items repeatedly such as
                    product name, price, MOQ, product photo etc. you can skip it
                    if you don&rsquo;t have.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={makeEventFiles[15]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Request approval <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    Once you completed the steps like a make an event, make a
                    sub-event, and create a form , click &ldquo;approval
                    request&rdquo; button to review it by administer and if any
                    improvement, we&rsquo;ll contact you personally. an
                    &nbsp;email will be sent to you when approved.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={makeEventFiles[16]}
                  />
                </p>
              </>
            )}
            <Header
              style={{ display: page === "mamageEvent" ? "block" : "none" }}
              className="header index manageEvent"
              dir="ltr"
            >
              Manage event
            </Header>
            {page === "manageEvent" && (
              <>
                <p dir="ltr">
                  <span className="span">
                    Access event management mode by clicking on a sub-event in
                    the event detail screen.
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[1]}
                  />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Top bar. Event title and sub-event classification and
                        period of entire and sub-event.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Connected menu. activated menu with sub-event setting.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header2 className="header2 index" dir="ltr">
                  Participating corporate <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    See all participants who have registered. click participant
                    list to navigate to the detail screen and the information
                    can be modified
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[2]}
                  />
                </p>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;download&rdquo; button at the top right of the
                    list, the participants on the screen are downloaded on CSV
                    file.
                  </span>
                </p>
                <Header3 className="header3 index">
                  Confirm participant<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    non-confirmed participants will not be able to see the
                    participant list and all functions will be restricted. an
                    e-mail will be sent to participants when the organizer
                    confirmed. The confirm button is located in the list and
                    detail screen.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[3]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Meeting status <span></span>
                </Header2>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[4]}
                  />
                </p>
                <p dir="ltr">
                  <span className="span">
                    Only confirmed participants are shown in the participating
                    list. &nbsp;when clicking &ldquo;meeting status&rdquo; to
                    navigate to the participant corporate details screen and
                    organizer can reschedule the meeting for selected
                    corporate..&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Image
                    className="lazy"
                    src={Pexpologo}
                    data-src={managedEventFiles[5]}
                  />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Full matching(TIGHT). Automatically arrange participants
                        whose matching categories and keywords between
                        participating groups.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Full matching. &nbsp;The categories should be correct,
                        but keywords are automatically matched first in the
                        overlapping order.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        cancel full matching. Cancel all participant&rsquo;s
                        matching.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        mail switch. When switched on, send participant e-mails
                        with results concluded or cancelled by the organizer.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        mail sending. Send mail of concluded schedule to all
                        participants.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index">
                  Filter meeting status
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Classify the meeting schedule according to the 4 meeting
                    status.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[6]}
                  />
                </p>
                <p>
                  <br />
                </p>
                <ol>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Requesting. The corporate has sent a matching request.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Awaiting. The corporate is waiting to accept the
                        matching request from another corporate
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Meeting concluded. The corporate has been accepted after
                        sending or receiving a matching request.
                      </span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        * Includes schedules arranged by the organizer.
                      </span>
                    </p>
                  </li>
                  <li dir="ltr" className="list">
                    <p dir="ltr">
                      <span className="span">
                        Set unavailable. the meeting schedule is not allowed for
                        the corporate..
                      </span>
                      <span className="span">
                        <br />
                      </span>
                      <span className="span">
                        *unavailable time by the organizer is not applicable.
                      </span>
                    </p>
                  </li>
                </ol>
                <Header3 className="header3 index">
                  Set timezone <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    click &ldquo;global time&rdquo; to change the timezone to
                    the selected country.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[7]}
                  />
                </p>
                <Header3 className="header3 index">
                  Request meeting<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    select a participating corporate by clicking &ldquo;+&rdquo;
                    button in the empty meeting schedule card
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[8]}
                  />
                </p>
                <Header3 className="header3 index">
                  Cancel meeting<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    cancel the concluded meeting schedule by clicking
                    &ldquo;/&rdquo; button
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[9]}
                  />
                </p>
                <Header3 className="header3 index">
                  Set meeting unavailable
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    change meeting unavailable status by clicking
                    &ldquo;/&rdquo; button in the empty meeting schedule card.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[10]}
                  />
                </p>
                <Header3 className="header3 index">
                  Cancel meeting unavailable
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    cancel meeting unavailable status by clicking
                    &ldquo;/&rdquo; button in the meeting unavailable schedule.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[11]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Matching history <span></span>
                </Header2>
                <Header3 className="header3 index">
                  View all concluded matching
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    It shows all meeting schedules concluded between
                    participating corporates by date and time on the MATCHING
                    tap.
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    * the meeting schedule card that participant sets the
                    meeting unavailable by himself is shown
                    &ldquo;impossible&rdquo;&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[12]}
                  />
                </p>
                <Header3 className="header3 index">
                  View matching history
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    View all the meeting histories both participant and
                    organizer have set, even auto matching histories on the
                    history tab.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[13]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Meeting note history <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    view all meeting note that participating corporate wrote and
                    can download them on CSV file by clicking
                    &ldquo;download&rdquo; button.
                  </span>
                </p>
                <p dir="ltr">
                  <span className="span">
                    The meeting note will be conducted by participants when
                    meeting is concluded.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[14]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Interpreter <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    It shows the list of interpreters designated by organizer,
                    Interpreters are registered on sign up, the organizer may
                    assign an interpreter to video meetings that require,
                    clicking selected interpreter to navigate to the next detail
                    page and &nbsp;allows to modify the information.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[15]}
                  />
                </p>
                <Header3 className="header3 index">
                  Register interpreter
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Click &ldquo;register interpreter&rdquo; button at the top
                    right to navigate to the next page. A user account is
                    &nbsp;required to register as an interpreter. enter the user
                    email on user ID, the account will be automatically
                    completed and can be registered at the bottom.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[16]}
                  />
                </p>
                <p>
                  <br />
                </p>
                <p dir="ltr">
                  <span className="span">
                    Meeting corporate name(multi-select) automatically completes
                    when entering a concluded participating corporate in the box
                    and will show the list. check the meeting schedule to place
                    &nbsp;them and click &ldquo;registration&rdquo; button at
                    the bottom right to complete the deployment.&nbsp;
                  </span>
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Speaker <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    It shows the list of speakers that the organizer has
                    registered. speaker doesn&rsquo;t need user account and
                    click &ldquo;add speaker&rdquo; button at the top right to
                    navigate to the next page.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[17]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Program <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    It shows the list of programs that the organizer has
                    registered, the program can be placed in various ways
                    including keynote speeches and invitational lectures.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[18]}
                  />
                </p>
                <Header3 className="header3 index">
                  Add speaker on program
                  <span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    place speakers on the program. clicking the&rdquo;add
                    program&rdquo; button at the top right will take you the
                    next page and show the list of speaker, once selecting one
                    of the speakers in the list and click the &ldquo;register
                    program&rdquo; button to complete the speaker registration.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[19]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Notice <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    Create a notice so that participants can see from the
                    connect page. A new notice is generated when clicking
                    &ldquo;add notice&rdquo; button at the top right.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[20]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Mailing <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    It shows the list of mailing to be sent by the organizer.
                    you can check the mail that has been auto-sent, such as the
                    matching result notice mail.Click &ldquo; Mail send&rdquo;
                    button at the top &nbsp;right to navigate &nbsp;to the next
                    page and is able to select the participants to send mail..
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[21]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  SMS <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    It shows the SMS list to be sent by the organizer. Click
                    &ldquo;SMS send&rdquo; button at the top right to navigate
                    to the next page and is able to select the participants to
                    send SMS.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[22]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Data <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    Download and use the participant list, meeting &nbsp;status
                    and meeting note respectively as excel.&nbsp;
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[23]}
                  />
                </p>
                <Header2 className="header2 index" dir="ltr">
                  Monitoring <span></span>
                </Header2>
                <p dir="ltr">
                  <span className="span">
                    During an online video meeting, you can verify the status of
                    participant&rsquo;s online that videos are connected.
                    Participants who are connected to a video meeting will see a
                    green light, and those who are not connected will see a red
                    light.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[24]}
                  />
                </p>
                <p>
                  <br />
                </p>
                <p dir="ltr">
                  <span className="span">
                    The organizer can access the video meeting by clicking the
                    meeting schedule card. You need to allow microphone and
                    camera permission to use it.
                  </span>
                </p>
                <Header3 className="header3 index">
                  Set timezone<span></span>
                </Header3>
                <p dir="ltr">
                  <span className="span">
                    Click the country name at the top, you can change the time
                    zone by selecting the country &nbsp;and city. You will see
                    the current time in the changed time zone on the right.
                  </span>
                </p>
                <p dir="ltr">
                  <Video
                    playsInline
                    className="lazy"
                    src={Pexpologo}
                    autoPlay={true}
                    loop={true}
                    data-src={managedEventFiles[25]}
                  />
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
              </>
            )}
          </Contents>
        )}
      </Container>
    </Layout>
  );
};

export default Manual;
