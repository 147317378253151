import React, { useContext, useEffect } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";

// components
import MainFooter from "./components/NavBar/MainFooter";

// hooks
import useRoomState from "./hooks/useRoomState";
import Room from "./components/Room/Room";
import { useAppState } from "./components/AppStateProvider/hooks/useAppState";
import useVideoContext from "./hooks/useVideoContext";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, removeLoadingAction } from "../actions/loading";
import useHeight from "./hooks/useHeight";
import { ChatContext } from "./components/ChatProvider/ChatProvider";
import { AppState } from "../store";
import { useHistory } from "react-router-dom";
import useFullScreenToggle from "./hooks/useFullScreenToggle";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "auto",
});

const Main = styled("main")({
  overflow: "hidden",
});

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getToken, isFetching } = useAppState();
  const { setUpVideoChatChannel, client, chatStateReset, setChatSidebarOpen } =
    useContext(ChatContext);
  const { isConnecting, setIsConnecting, connect, localTracks } =
    useVideoContext();
  const roomState = useRoomState(); // room에 연결된 상태 확인
  const height = useHeight();
  const [isFullScreen] = useFullScreenToggle();

  const myBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );
  const interpreter = useSelector(
    (state: AppState) => state.interpreters.interpreter
  );

  // 비디오가 켜지면 기존 일반 켜져있는 채팅창을 끔
  // 비디오방을 나가면 기존 채팅방의 리스너를 및 메세지를 지움
  useEffect(() => {
    setChatSidebarOpen(false);
    return () => {
      chatStateReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetching === false) {
      let time = 0;
      if (localTracks.length < 3) time = 3000;
      // mount 시 영상통화 토큰을 받아옴
      // 명함정보가 없을 시 goback
      // 비디오룸에 연결 시 접속한 유저의 정보를 같이 넘겨줌 (useRoomContext에서 어떤 유저인가에 따라 publish할 data가 달라짐)
      setTimeout(() => {
        if (myBusinessCard !== undefined) {
          getToken().then((data: any) => connect(data.token, data.user));
          if (client) {
            setUpVideoChatChannel(client);
          }
        } else if (interpreter) {
          getToken().then((data: any) => connect(data.token, data.user));
          if (client) {
            setUpVideoChatChannel(client);
          }
        } else {
          setIsConnecting(false);
          history.goBack();
        }
      }, [time]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  // loading indicator from video call connection
  useEffect(() => {
    if (isConnecting) {
      dispatch(setLoading());
    } else {
      dispatch(removeLoadingAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnecting]);

  return (
    <Container style={{ height: isFullScreen ? "100vh" : height }}>
      <Main
        style={{
          height: isFullScreen
            ? `calc(100vh - 56px)`
            : `calc(${height}px - 56px)`,
        }}
      >
        {roomState !== "disconnected" && <Room />}
      </Main>
      <MainFooter />
    </Container>
  );

  // return (
  //   <div className={classes.root}>
  //     <MainNavBar />
  //     <main className={classes.main}>
  //       {roomState === "connected" && <Room />}
  //     </main>
  //     <Controls />
  //   </div>
  // );
};

export default App;
