import styled, { css } from "styled-components";

interface StyleProps {
  padding?: string;
  width?: string;
  height?: string;
}

const TextButton = styled.button<StyleProps>`
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  color: rgba(126, 87, 194, 1);
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  height: ${(props) => (props.height !== undefined ? props.height : "")};
  width: ${(props) => (props.width !== undefined ? props.width : "")};
  padding: ${(props) => (props.padding !== undefined ? props.padding : "0px")};
`;

export default TextButton;
