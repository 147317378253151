import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface SubHeadingTypoProps {
  fontweight?: string;
  txtcolor?:
    | "rgba(0,0,0,0.87)"
    | "purple"
    | "bluePurple"
    | "black0.38"
    | "white"
    | "black0.6";
  marginTop?: string;
  upperCase?: boolean;
  txtOverflow?: boolean;
}

type Prop = SubHeadingTypoProps & TypographyProps;

const SubHeadingTypo: React.FC<Prop> = styled((props: Prop) => {
  const { fontweight, txtcolor, txtOverflow, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 1.063rem;
  font-weight: ${(props) => {
    return props.fontweight === undefined ? "normal" : props.fontweight;
  }};
  font-style: normal;

  line-height: 1.6;
  letter-spacing: -0.4px;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }
    if (props.txtcolor === "bluePurple") {
      return "#512da8";
    }
    if (props.txtcolor === "white") {
      return "#ffffff";
    }
    if (props.txtcolor === "black0.38") {
      return "rgba(0, 0, 0, 0.38)";
    }
    if (props.txtcolor === "black0.6") {
      return "rgba(0,0,0,0.6)";
    }
    return props.txtcolor;
  }};
  margin-top: ${(props) =>
    props.marginTop === undefined ? "0px" : props.marginTop};
  text-transform: ${(props) => (props.upperCase ? "uppercase" : "none")};

  ${(props) => {
    if (props.txtOverflow) {
      return css`
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* 라인수 */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        line-height: 1.2rem;
        height: 1.2rem;
      `;
    }
  }}
`;

SubHeadingTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default SubHeadingTypo;
