import { useCallback, useEffect, useState } from "react";
import Video, {
  LocalVideoTrack,
  LocalAudioTrack,
  CreateLocalTrackOptions,
  LocalDataTrack,
} from "twilio-video";
import { ensureMediaPermissions } from "../../../utils";
import { useAppState } from "../../AppStateProvider/hooks/useAppState";

export function useLocalAudioTrack() {
  const [track, setTrack] = useState<LocalAudioTrack>();
  const { setActiveSinkId } = useAppState();

  const getLocalAudioTrack = useCallback(async (deviceId?: string) => {
    // 로컬 오디오 track 설정
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return ensureMediaPermissions().then(() =>
      Video.createLocalAudioTrack(options).then((newTrack) => {
        setTrack(newTrack);
        return newTrack;
      })
    );
  }, []);

  useEffect(() => {
    // default audio input 세팅
    const setLocalAudioTrack = async () => {
      await ensureMediaPermissions();

      const devices: MediaDeviceInfo[] =
        await navigator.mediaDevices.enumerateDevices();

      const audioInputDeviceId = localStorage.getItem("audioInputDeviceId");
      const audioOutputDeviceId = localStorage.getItem("audioOutputDeviceId");

      const isAudioOutputDevices = devices.filter(
        (device) => device.kind === "audiooutput"
      );
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );

      const isAudioOutputDeviceExist = isAudioOutputDevices.findIndex(
        (device) => device.deviceId === audioOutputDeviceId
      );

      if (isAudioOutputDeviceExist !== -1) {
        setActiveSinkId(audioOutputDeviceId!);
      }
      
      const isAudioInputDeviceExist = audioInputDevices.findIndex(
        (device) => device.deviceId === audioInputDeviceId
      );

      getLocalAudioTrack(
        isAudioInputDeviceExist !== -1 ? audioInputDeviceId! : undefined
      );
    };

    setLocalAudioTrack();
  }, [getLocalAudioTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on("stopped", handleStopped);
      return () => {
        track.off("stopped", handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalAudioTrack] as const;
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState<LocalVideoTrack>();

  const getLocalVideoTrack = useCallback(
    (newOptions?: CreateLocalTrackOptions) => {
      // 로컬 비디오 track 설정
      const options: CreateLocalTrackOptions = {
        frameRate: 24,
        height: 720,
        width: 1280,
        name: `camera-${Date.now()}`,
        ...newOptions,
      };

      return ensureMediaPermissions().then(() =>
        Video.createLocalVideoTrack(options).then((newTrack) => {
          setTrack(newTrack);
          return newTrack;
        })
      );
    },
    []
  );

  useEffect(() => {
    // We get a new local video track when the app loads.
    getLocalVideoTrack();
  }, [getLocalVideoTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on("stopped", handleStopped);
      return () => {
        track.off("stopped", handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalVideoTrack] as const;
}

const useLocalDataTrack = () => {
  const [localDataTrack, setLocalDataTrack] =
    useState<LocalDataTrack | undefined>();

  useEffect(() => {
    if (localDataTrack === undefined) {
      const newTrack = new LocalDataTrack();
      setLocalDataTrack(newTrack);
    }
  }, [localDataTrack]);

  return [localDataTrack] as const;
};

export default function useLocalTracks() {
  const [audioTrack, getLocalAudioTrack] = useLocalAudioTrack();
  const [videoTrack, getLocalVideoTrack] = useLocalVideoTrack();
  const [localDataTrack] = useLocalDataTrack();

  const localTracks = [audioTrack, videoTrack, localDataTrack].filter(
    (track) => track !== undefined
  ) as (LocalAudioTrack | LocalVideoTrack | LocalDataTrack)[];

  return { localTracks, getLocalVideoTrack, getLocalAudioTrack };
}
