import React, { createContext, useCallback, useEffect, useState } from "react";
import { Client } from "twilio-chat";
import { Channel } from "twilio-chat/lib/channel";
import { Message } from "twilio-chat/lib/message";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { useTranslation } from "react-i18next";
import BusinessCard from "../../../types/models/BusinessCard";
import { ChatRoom } from "../../../types/models/Chat";
import { setAlertAction } from "../../../actions/alerts";
import {
  getBlackList,
  getChatClient,
  updateChatClientFriendlyName,
} from "./ChatApi";
import useWidth from "../../hooks/useWidth";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../actions/loading";

interface MessageState {
  error: any;
  isLoading: boolean;
  messages: {
    text: string;
    author: { id: string; name: string; sid: string };
  }[];
}

export interface ChatContextType {
  client?: Client;
  chatChannel?: Channel;
  messages: MessageState;
  otherUser?: BusinessCard | ChatRoom; // 대화상대의 명함정보
  chatAlert: boolean;
  chatSidebarOpen: boolean;
  chatBlock: boolean;
  blackList: Array<string>;
  sendMessage: (text: string) => void;
  setUpVideoChatChannel: (client: Client) => void;
  setUpChatChannel: (
    otherUserBusinessCardInfo: BusinessCard | ChatRoom | undefined
  ) => void;
  setOtherUser: React.Dispatch<
    React.SetStateAction<BusinessCard | ChatRoom | undefined>
  >;
  setChatSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  chatStateReset: () => void;
  setInitialChatClient: () => Promise<Client | undefined>;
  videoChatSidebarOpen: boolean;
  setVideoChatSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setChatBlock: React.Dispatch<React.SetStateAction<boolean>>;
  setBlackList: React.Dispatch<React.SetStateAction<Array<string>>>;
  setMessage: React.Dispatch<React.SetStateAction<MessageState>>;
}

export const ChatContext = createContext<ChatContextType>(null!);

export default function ChatProvider(props: React.PropsWithChildren<{}>) {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const [chatSidebarOpen, setChatSidebarOpen] = useState<boolean>(false); // 일반 이벤트 참가자들끼리의 채팅 view open 여부
  const [videoChatSidebarOpen, setVideoChatSidebarOpen] =
    useState<boolean>(false); // 비디오룸 채팅방에서의 채팅 view open 여부
  const [chatAlert, setChatAlert] = useState<boolean>(false);
  const [client, setClient] = useState<Client>();
  const [chatChannel, setChatChannel] = useState<Channel>();
  const [otherUser, setOtherUser] = useState<
    BusinessCard | ChatRoom | undefined
  >();
  const [chatBlock, setChatBlock] = useState<boolean>(false);
  const [BlackList, setBlackList] = useState<Array<string>>([]);
  const [messages, setMessages] = useState<MessageState>({
    error: null,
    isLoading: true,
    messages: [],
  });
  const interpreter = useSelector(
    (state: AppState) => state.interpreters.interpreter
  );
  const myBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );
  const isAuthenticated = useSelector(
    (state: AppState) => state.users.isAuthenticated
  );
  const meetingVideo = useSelector(
    (state: AppState) => state.meetings.meetingVideo
  );

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const webinar = useSelector((state: AppState) => state.webinars.webinar);
  const { id: userId } = useSelector((state: AppState) => state.users);
  const width = useWidth();

  useEffect(() => {
    if (width <= 600) {
      setVideoChatSidebarOpen(false);
    }
  }, [width]);
  // 유저의 auth 정보가 없으면 client 및 리스너 등 모두 제거
  useEffect(() => {
    if (isAuthenticated === false) {
      setClient(undefined);
      chatStateReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // 채팅관련 모든 값 초기화
  const chatStateReset = () => {
    // client?.shutdown();
    client?.removeAllListeners();
    chatChannel?.removeAllListeners();
    setChatChannel(undefined);
    setMessages({
      error: null,
      isLoading: true,
      messages: [],
    });
    setChatSidebarOpen(false);
  };

  // twilio 채널로 부터 오는 메세지들을 Message타입으로 변환
  const twilioMessageToMessage = (message: Message) => {
    return {
      text: message.body,
      author: {
        id: (message.attributes as any).id ?? message.author,
        name: (message.attributes as any).from ?? message.author,
        sid: message.sid,
      },
    };
  };

  // 비디오 채팅방 채널을 생성하거나 참가
  const setUpVideoChatChannel = useCallback(
    async (client: Client) => {
      if (client !== undefined) {
        let channelName;
        if (webinar) {
          channelName = `webinar/${webinar.id}`;
          const result = await getBlackList(
            `${webinar.subEventId}|${webinar.id}`
          );
          setBlackList(result);
        } else {
          channelName = `meeting/${meetingVideo?.subEventId}/${meetingVideo?.id}`;
        }
        try {
          const ch = await client.getChannelByUniqueName(channelName);

          ch.join()
            .catch((error) => {
              // member already exist error
              if (error.code === 50404) {
                setChatChannel(ch);
              }
            })
            .then(() => {
              setChatChannel(ch);
            });
        } catch (err) {
          if (err.body && err.body.code === 50300) {
            const newChannel = await client.createChannel({
              uniqueName: channelName,
            });
            newChannel.join().then(() => {
              setChatChannel(newChannel);
            });
          }
        }
      }
    },
    [meetingVideo, webinar]
  );

  // 일반(서브이벤트 채팅) 채팅방 채널을 생성하거나 참가
  const setUpChatChannel = useCallback(
    async (otherUserBusinessCardInfo?: BusinessCard | ChatRoom) => {
      if (
        client !== undefined &&
        myBusinessCard !== undefined &&
        chatSidebarOpen === false // 다른 채팅창이 켜져있을 경우 닫아야 해당 채팅방의 listener가 지워짐
      ) {
        setChatSidebarOpen(true);

        let channelName = `general/${subEvent?.id}/${myBusinessCard?.id}/${otherUserBusinessCardInfo?.id}`;

        const channel = await client.getSubscribedChannels();
        // 참여한 채팅방 목록에서 들어갈 채팅방이 이미 존재하는지 확인
        channel.items.forEach((channel) => {
          if (
            channel.uniqueName ===
            `general/${subEvent?.id}/${myBusinessCard?.id}/${otherUserBusinessCardInfo?.id}`
          ) {
            channelName = `general/${subEvent?.id}/${myBusinessCard?.id}/${otherUserBusinessCardInfo?.id}`;
          } else if (
            channel.uniqueName ===
            `general/${subEvent?.id}/${otherUserBusinessCardInfo?.id}/${myBusinessCard?.id}`
          ) {
            channelName = `general/${subEvent?.id}/${otherUserBusinessCardInfo?.id}/${myBusinessCard?.id}`;
          }
        });

        try {
          const ch = await client.getChannelByUniqueName(channelName);

          ch.join()
            .catch((error) => {
              // member already exist error
              if (error.code === 50404) {
                setChatChannel(ch);
              }
            })
            .then(() => {
              setChatChannel(ch);
            });
        } catch (err) {
          // Channel not found (채널없으면 새로운 채널을 만듬)
          if (err.body !== undefined) {
            if (err.body.code === 50300) {
              const newChannel = await client.createChannel({
                uniqueName: channelName,
              });
              if (otherUserBusinessCardInfo) {
                // 만든채널에 참여 후 대화신청 할 사람을 초대
                newChannel.join().then((channel) => {
                  channel
                    .invite(
                      (
                        otherUserBusinessCardInfo as BusinessCard
                      ).user!.id!.toString()
                      // `${otherUserBusinessCardInfo.name}/${otherUserBusinessCardInfo.company}`
                    )
                    .then(() => setChatChannel(channel))
                    .catch((error) => {
                      // 채널초대 할 유저가 없는 경우(아직 한번도 서브이벤트 페이지에 접속하지 않은 경우)
                      if (error.code === 50200) {
                        dispatch(
                          setAlertAction({
                            id: "noUserAlert",
                            alertType: "warning",
                            msg: t("chatSidebar.noUserErrorAlert"),
                          })
                        );
                        newChannel.removeAllListeners();
                        setChatChannel(undefined);
                        setChatSidebarOpen(false);
                        newChannel.delete();
                      }
                    });
                });
              }
            }
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, myBusinessCard, subEvent, chatSidebarOpen]
  );

  // client가 생성되면 채널입장 이벤트 EventEmitter On, 채널초대 EventEmitter On
  useEffect(() => {
    if (client !== undefined) {
      // client.on("channelJoined", (channel: Channel) => {
      //   console.log("channel joined! ", channel.sid);
      //   // setChatChannel(channel);
      // });

      client.on("channelInvited", (channel: Channel) => {
        channel.join();
      });
    }
  }, [client]);

  // 기존 채팅내용을 message state 값에 추가
  const messagesLoaded = (
    msgs: {
      text: string;
      author: {
        id: string;
        name: string;
        sid: string;
      };
    }[]
  ) => {
    setMessages({
      ...messages,
      isLoading: false,
      messages: msgs,
    });
  };

  // 채팅 메세지를 받아오면 message state 값에 추가
  const messagesAdded = (msg: {
    text: string;
    author: {
      id: string;
      name: string;
      sid: string;
    };
  }) => {
    setMessages((prevState) => {
      return {
        ...prevState,
        messages: [...prevState.messages, msg],
      };
    });
  };

  // channel에 들어오는 메세지와 기존 메세지를 setMessage
  useEffect(() => {
    if (chatChannel !== undefined) {
      // setMessages({ ...messages, isLoading: false });
      chatChannel.getMessages(undefined, 2);
      chatChannel.getMessages().then((messagePage) => {
        // 가장 마지막에 가져온 메세지의 index로 읽은 메세지 처리
        if (messagePage.items.length > 0) {
          chatChannel.updateLastConsumedMessageIndex(
            messagePage.items[messagePage.items.length - 1].index
          );
        }
        const pastMessages = messagePage.items.map(twilioMessageToMessage);
        messagesLoaded(pastMessages);
      });

      chatChannel!.on("messageAdded", (message: Message) => {
        ///////////// 비디오방에서의 채팅만 해당 ////////////////////
        // 채팅버튼 애니메이션 동작하기위한 chatAlert state 변경 조건문
        // 새로운 메세지가 나의 메세지가 아니면 setChatAlert를 true로 변경 뒤 3초 후 다시 false로 변경
        // 통역사는 해당안됨
        if (
          myBusinessCard &&
          (message.author as string) !==
            `${myBusinessCard.name}/${myBusinessCard.company}`
        ) {
          setChatAlert(true);
          setTimeout(() => {
            setChatAlert(false);
          }, [3000]);
        }
        ////////////////////////////////////////////////////

        // 받은 메세지는 모두 읽은 메세지로 처리
        chatChannel.updateLastConsumedMessageIndex(message.index);

        messagesAdded(twilioMessageToMessage(message));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatChannel, interpreter]);

  // chatClient 생성
  const setInitialChatClient = useCallback(async () => {
    if (isAuthenticated && userId && client === undefined) {
      const chatClient = await getChatClient(userId);
      setClient(chatClient);
      return chatClient;
    }
  }, [isAuthenticated, client, userId]);

  // 명함정보와 채팅client 정보가 있으면, 명함정보로 chat client의 friendlyName을 설정해줌 (통역과 일반 유저의 friendlyName 세팅이 다름)
  // 추후 friendlyName을 통해 message를 전송함
  useEffect(() => {
    const updateChatFriendlyName = async () => {
      if (client) {
        // 서브이벤트 접속 시 friendly name 업데이트
        if (myBusinessCard) {
          let friendlyName = "";
          if (myBusinessCard.company === t("interpreterSubEvent.interpreter")) {
            friendlyName = `${myBusinessCard.name} (${t(
              "interpreterSubEvent.interpreter"
            )})`;
          } else {
            friendlyName = `${myBusinessCard.name}|${myBusinessCard.company}`;
          }

          // 비디오방 접속 후 다시 서브이벤트 페이지에 왔을때 로딩인디케이터가 또 보이는 것 방지
          if (friendlyName !== client.user.friendlyName) {
            dispatch(setLoadingAction());
            await updateChatClientFriendlyName(
              client.user.identity,
              friendlyName
            );
            dispatch(removeLoadingAction());
          }
        }
      } else if (isAuthenticated && userId && client === undefined) {
        // 처음 사이트 로그인 시 chatClient를 생성 or 불러옴
        // 일반 로그인 시 로딩스크린 x, 링크를 통해 바로 서브이벤트 페이지 이동 시 로딩스크린 o
        if (myBusinessCard) {
          dispatch(setLoadingAction());
          await setInitialChatClient();
          dispatch(removeLoadingAction());
        } else {
          setInitialChatClient();
        }
      }
    };

    updateChatFriendlyName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, client, userId, myBusinessCard]);

  // 참여한 twilio channel에 message 전송
  const sendMessage = (message: string) => {
    if (myBusinessCard) {
      chatChannel?.sendMessage(message, {
        from: client?.user.friendlyName,
        id: myBusinessCard?.id,
      });
    }
  };

  return (
    <ChatContext.Provider
      value={{
        client: client,
        chatChannel: chatChannel,
        messages: messages,
        otherUser: otherUser,
        chatAlert: chatAlert,
        chatSidebarOpen: chatSidebarOpen,
        videoChatSidebarOpen: videoChatSidebarOpen,
        chatBlock: chatBlock,
        blackList: BlackList,
        setVideoChatSidebarOpen: setVideoChatSidebarOpen,
        sendMessage: sendMessage,
        setUpVideoChatChannel: setUpVideoChatChannel,
        setUpChatChannel: setUpChatChannel,
        setOtherUser: setOtherUser,
        setChatSidebarOpen: setChatSidebarOpen,
        chatStateReset: chatStateReset,
        setInitialChatClient: setInitialChatClient,
        setChatBlock: setChatBlock,
        setBlackList: setBlackList,
        setMessage: setMessages,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
}
