import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMyLikeList, removeUser, updateLike } from "../../actions/users";
import PxCopyToCip from "../CopyToClip/PxCopyToClip";

// bootstrap ui component
import Navbar from "react-bootstrap/Navbar";

// material ui /custom comp
import GridContainer from "../Grid/PxGridContainer";
import CustomIconButton from "../Buttons/PxIconButton";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LanguageIcon from "@material-ui/icons/Language";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SearchIcon from "@material-ui/icons/Search";
import FlagIcon from "@material-ui/icons/Flag";
import PersonIcon from "@material-ui/icons/Person";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TranslateIcon from "@material-ui/icons/Translate";
import {
  Close as CloseIcon,
  Share as ShareIcon,
  Favorite as LikeIcon,
} from "@material-ui/icons";

// images
import PexpoLogoSvg from "../../assets/images/pexpo_signature.svg";
import GridItem from "../Grid/PxGridItem";
import { deleteAuthToken } from "../../utils/setAuthToken";
import PxMenu from "../Menu/PxMenu";
import { removeRoutePathAction } from "../../actions/routePath";
import { useTranslation } from "react-i18next";
import { choiceCountry } from "../../actions/country";
import { AppState } from "../../store";
import { makeStyles } from "@material-ui/core/styles";
import { alert } from "../../components/Alerts/PxAlert2";
import KakaoButton from "../Share/KakaoButton";
import FacebookButton from "../Share/FacebookButton";
// styled-component
const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 0 50px 0 50px;
  display: flex;
  flex-direction: column;
  justify: space-between;
  @media only screen and (max-width: 1279px) {
    padding: 0 24px 0 24px;
  }
  @media only screen and (max-width: 599px) {
    padding: 0 16px 0 16px;
  }
`;

const StyledNavbar = styled(Navbar)<{ search: Boolean }>`
  position: sticky;
  width: 100vw;
  max-width: 100%;
  min-width: 360px;
  top: 0;
  box-shadow: ${(props) =>
    props.search ? " 0 2px 4px 0 rgba(0, 0, 0, 0.3)" : ""};
  border-bottom: ${(props) =>
    props.borderBottom ? "none" : "1px solid #e9eaed"};
  padding: 0;
  height: 64px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  & .nav-item {
    padding-right: 2em;
  }
`;

const PexpoLogoImage = styled.img`
  cursor: pointer;
  height: 30px;
  width: 112px;
  /*  width="79"
		height="26" */
`;

const CustomLogoImage = styled.img`
  cursor: pointer;
  height: 60px;
  width: 110px;
  object-fit: contain;
`;

const SearchBox = styled.input`
  width: 100%;
  height: 40px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 16px;
  border: none;
  &::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
`;

const useStyles = makeStyles((theme) => ({
  MainIcon: {
    fontSzie: "24px",
  },
  SubIcon: {
    fontSzie: "18px",
    maxWidth: "18px",
    minWidth: "18px",
  },
  MenuItem: {
    "& .MuiTypography-body1": {},
    width: "244px",
  },
}));

interface MainNavbarProps {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogTitle: React.Dispatch<React.SetStateAction<string>>;
}

const MainNavbar = ({ setDialogOpen, setDialogTitle }: MainNavbarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation("lang", { useSuspense: false });
  const pathName = history.location.pathname;
  const dispatch = useDispatch();
  const [search, setSearchBtn] = useState<Boolean>(false);
  const [likeBtn, setLikeBtn] = useState<boolean>(false);
  const event = useSelector(
    (state: AppState) => state.subEvents.subEvent?.mainEvent
  );
  // const event = useSelector((state: AppState) => state.events.event);
  const [searchWord, setSearchWord] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [langIconAnchorEl, setLangIconAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [countryIconAnchorEl, setCountryIconAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const countries = useSelector(
    (state: AppState) => state.countries.countries!
  );
  const likeList = useSelector(
    (state: AppState) => state.users.likeList?.content
  );

  const handleLoginIconClick = (event: React.MouseEvent<HTMLElement>) => {
    if (localStorage.token) {
      setAnchorEl(event.currentTarget);
    } else {
      history.push("/auth/login");
    }
  };

  useEffect(() => {
    if (pathName.includes("/event/detail/") && localStorage.token) {
      dispatch(getMyLikeList());
    }
  }, [dispatch, pathName]);

  const handleLanguageIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setLangIconAnchorEl(event.currentTarget);
  };

  const handleCountryIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setCountryIconAnchorEl(event.currentTarget);
  };

  const changeCountry = async (key: string) => {
    const country = countries[key];
    await dispatch(
      choiceCountry(
        key,
        i18n.language === "en" ? country.enName : country.koName,
        country.timezone
      )
    );
  };

  useEffect(() => {
    if (pathName.includes("/event/detail") && Array.isArray(likeList)) {
      if (
        likeList.find((data) => data.id === parseInt(pathName.substr(14))) !==
        undefined
      ) {
        setLikeBtn(true);
      } else {
        setLikeBtn(false);
      }
    }
  }, [likeList, pathName]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchBtn(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  const { id } = useSelector((status: AppState) => status.users);

  const clickLike = async (e: React.MouseEvent) => {
    if (localStorage.token) {
      const result: any = await dispatch(
        updateLike({ userId: id, eventId: parseInt(pathName.substr(14)) })
      );
      if (result) dispatch(getMyLikeList());
    } else {
      history.push("/auth/login");
    }
  };

  interface AlertProps {
    type: "success" | "error" | "warning" | "info" | "default";
    message: string;
  }

  const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchWord.trim().length <= 0) {
      alert({ type: "warning", message: "검색어를 입력해주세요.", dispatch });
    } else {
      history.push(`/search_result/${searchWord} `);
    }
  };

  return countries !== undefined ? (
    <StyledNavbar expand="md" sticky="top" search={search}>
      {!pathName.includes("/auth") && (
        <Container>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {!search ? (
              <>
                <GridItem>
                  {pathName.includes("/event/detail") ? (
                    //event detail 페이지 일때 로고 대신 저장, 공유 버튼으로 변경
                    <>
                      <CustomIconButton
                        padding={5}
                        margin={5}
                        // onClick={handleLanguageIconClick}
                        onClick={clickLike}
                      >
                        {likeBtn === true ? (
                          <LikeIcon
                            style={{ fontSize: 24, color: "rgb(81, 45, 168)" }}
                          />
                        ) : (
                          <LikeIcon
                            style={{ fontSize: 24, color: "#c7c8cb" }}
                          />
                        )}
                      </CustomIconButton>
                      <PxCopyToCip
                        text={`${window.location.href}${
                          i18n.language !== "ko" ? "?lang=en" : ""
                        }`}
                      >
                        <CustomIconButton padding={5} margin={5}>
                          <ShareIcon style={{ fontSize: 24 }} />
                        </CustomIconButton>
                      </PxCopyToCip>
                      {/* {event && event.name && (
                        <>
                          <KakaoButton
                            title={event.name}
                            description={
                              event.categories
                                ?.split("^&")
                                .map((category: string) =>
                                  t(`categories.${category}`)
                                )
                                .join(" | ") || ""
                            }
                            link={window.location.href}
                            imageUrl={
                              event.mainImageFile?.filePath ?? event.image
                            }
                          />
                          <FacebookButton />
                        </>
                      )} */}
                    </>
                  ) : event?.showLogo !== "N" ? (
                    event?.logoImageFile &&
                    event.logoImageFile.filePath != "" ? (
                      <CustomLogoImage
                        src={event.logoImageFile.filePath}
                        onClick={() => history.push("/")}
                      />
                    ) : (
                      <PexpoLogoImage
                        src={PexpoLogoSvg}
                        className="d-inline-block align-top"
                        alt="Pexpo logo"
                        onClick={() => {
                          if (event != null) {
                            history.push(`/event/detail/${event?.id}`);
                          } else {
                            history.push(`/`);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </GridItem>

                <GridItem>
                  <CustomIconButton
                    padding={5}
                    margin={5}
                    onClick={handleLanguageIconClick}
                  >
                    <LanguageIcon style={{ fontSize: 24 }} />
                  </CustomIconButton>
                  {/* 언어 변경 */}
                  <PxMenu
                    anchorEl={langIconAnchorEl}
                    setAnchorEl={setLangIconAnchorEl}
                  >
                    <MenuItem
                      onClick={() => {
                        localStorage.setItem("language", "ko");
                        i18n.changeLanguage("ko");
                        setLangIconAnchorEl(null);
                      }}
                    >
                      <ListItemText primary={t("mainNavBar.korean")} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        localStorage.setItem("language", "en");
                        i18n.changeLanguage("en");
                        setLangIconAnchorEl(null);
                      }}
                    >
                      <ListItemText primary={t("mainNavBar.english")} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        localStorage.setItem("language", "cn");
                        i18n.changeLanguage("cn");
                        setLangIconAnchorEl(null);
                      }}
                    >
                      <ListItemText primary={t("mainNavBar.chinese")} />
                    </MenuItem>
                  </PxMenu>

                  <CustomIconButton
                    padding={5}
                    margin={5}
                    onClick={handleCountryIconClick}
                  >
                    <AccessTimeIcon style={{ fontSize: 24 }} />
                  </CustomIconButton>
                  <PxMenu
                    anchorEl={countryIconAnchorEl}
                    setAnchorEl={setCountryIconAnchorEl}
                  >
                    {Object.keys(countries).map((key) => (
                      <MenuItem
                        key={key}
                        onClick={() => {
                          changeCountry(key);
                        }}
                      >
                        <ListItemText
                          primary={
                            i18n.language === "ko"
                              ? countries[key].koName
                              : countries[key].enName
                          }
                        />
                      </MenuItem>
                    ))}
                  </PxMenu>

                  <CustomIconButton
                    padding={5}
                    margin={5}
                    onClick={handleLoginIconClick}
                  >
                    <AccountCircleIcon
                      style={{
                        color: localStorage.token ? "#512da8" : "",
                        fontSize: 24,
                      }}
                    />
                  </CustomIconButton>
                  <PxMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
                    <MenuItem
                      className={classes.MenuItem}
                      onClick={() => {
                        setAnchorEl(null);
                        history.push("/mypage/likes");
                      }}
                    >
                      <ListItemText primary={t("mainNavBar.favorites")} />
                      <ListItemIcon className={classes.SubIcon}>
                        <LikeIcon style={{ fontSize: 18 }} />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      onClick={() => {
                        setAnchorEl(null);
                        history.push("/mypage/approvedEvents");
                        // setDialogTitle("mainNavBar.myEvent");
                        // setDialogOpen(true);
                      }}
                    >
                      <ListItemText primary={t("mainNavBar.myEvent")} />
                      <ListItemIcon className={classes.SubIcon}>
                        <FlagIcon style={{ fontSize: 18 }} />
                      </ListItemIcon>
                    </MenuItem>

                    <MenuItem
                      className={classes.MenuItem}
                      onClick={() => {
                        setDialogTitle("mainNavBar.interpreter");
                        setDialogOpen(true);
                      }}
                    >
                      <ListItemText primary={t("mainNavBar.interpreter")} />
                      <ListItemIcon className={classes.SubIcon}>
                        <TranslateIcon style={{ fontSize: 18 }} />
                      </ListItemIcon>
                    </MenuItem>

                    <MenuItem
                      className={classes.MenuItem}
                      onClick={() => {
                        setAnchorEl(null);
                        history.push("/mypage/info");
                      }}
                    >
                      <ListItemText primary={t("mainNavBar.myPage")} />
                      <ListItemIcon className={classes.SubIcon}>
                        <PersonIcon style={{ fontSize: 18 }} />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      onClick={() => {
                        window.open("https://host.pexpo.io");
                      }}
                    >
                      <ListItemText primary={t("mainNavBar.makeNewEvent")} />
                      <ListItemIcon className={classes.SubIcon}>
                        <PostAddIcon style={{ fontSize: 18 }} />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      className={classes.MenuItem}
                      onClick={() => {
                        if (localStorage.token) {
                          deleteAuthToken();
                          dispatch(removeUser());
                          dispatch(removeRoutePathAction());
                          history.push("/");
                          window.location.reload();
                        }
                      }}
                    >
                      <ListItemText primary={t("mainNavBar.logOut")} />
                      <ListItemIcon className={classes.SubIcon}>
                        <ExitToAppIcon style={{ fontSize: 18 }} />
                      </ListItemIcon>
                    </MenuItem>
                  </PxMenu>
                  {!pathName.includes("/event/detail") && (
                    <CustomIconButton
                      margin={5}
                      padding={2}
                      onClick={() => setSearchBtn(true)}
                    >
                      <SearchIcon style={{ fontSize: 24 }} />
                    </CustomIconButton>
                  )}
                </GridItem>
              </>
            ) : (
              // 검색창 클릭
              <>
                <GridItem width="calc(100% - 50px)">
                  <form onSubmit={onSearchSubmit}>
                    <SearchBox
                      type="text"
                      autoFocus
                      ref={searchRef}
                      onChange={(e) => setSearchWord(e.target.value)}
                      placeholder={t("meetingDetail.search")}
                    />
                  </form>
                </GridItem>

                <GridItem>
                  <CustomIconButton
                    onClick={() => {
                      setSearchBtn(false);
                    }}
                  >
                    <CloseIcon style={{ fontSize: 24 }}></CloseIcon>
                  </CustomIconButton>
                </GridItem>
              </>
            )}
          </GridContainer>
        </Container>
      )}
    </StyledNavbar>
  ) : null;
};

export default MainNavbar;
