import { useContext } from "react";
import { MyInfoContext } from "./MyInfoProvider";

export function useMyInfoContext() {
  const context = useContext(MyInfoContext);
  if (!context) {
    throw new Error("useMyInfoContext must be used within the MyInfoProvider");
  }
  return context;
}
