import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ChatContext } from "../../video/components/ChatProvider/ChatProvider";
import useHeight from "../../video/hooks/useHeight";
import { isMobile } from "../../utils/utils";
import Chat from "../chat/Chat";

// material ui
import Drawer from "@material-ui/core/Drawer";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import CaptionTypo from "../../components/Typhography/CaptionTypo";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import CompanyTypo from "../../components/Typhography/CompanyTypo";
import PxIconButton from "../../components/Buttons/PxIconButton";
import PxGridItem from "../../components/Grid/PxGridItem";

// icons
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const useStyles = makeStyles((theme) => ({
  chatTitle: {
    height: 64,
    width: "100%",
    padding: "13px 0px 11px 16px !important",
    borderBottomStyle: "solid",
    borderWidth: 1,
    borderColor: "#e9eaed",
  },
  sendEmailAndPwdBtn: {
    width: "100%",
    height: 76,
    textAlign: "center",
    "& button": {
      margin: "20px 16px !important",
    },
  },
  mainDiv: {
    width: 360,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const ChatSidebar = () => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();
  const {
    chatSidebarOpen,
    setChatSidebarOpen,
    sendMessage,
    chatStateReset,
    messages,
    otherUser,
  } = useContext(ChatContext);
  const height = useHeight();

  const myBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );

  // 채팅 사이드바가 닫히면 채팅방(chatChannel)에 bind된 리스너를 다 없애고 chatChannel을 undefined로 만들어줌
  useEffect(() => {
    if (chatSidebarOpen === false) {
      chatStateReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSidebarOpen]);

  const drawerHandleOnClose = () => {
    setChatSidebarOpen(false);
  };

  const handleSendMyInfoOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    sendMessage(
      `#myInfo/${myBusinessCard?.company}/${myBusinessCard?.name}/${myBusinessCard?.position}/${myBusinessCard?.email}/${myBusinessCard?.phoneNumber}`
    );
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor={isMobile ? "bottom" : "right"}
        open={chatSidebarOpen}
        onClose={drawerHandleOnClose}
      >
        <div className={classes.mainDiv} style={{ height: height }}>
          <div>
            {/* 채팅 타이틀바 */}
            <PxGridContainer
              className={classes.chatTitle}
              direction="row"
              justify="space-between"
            >
              <PxGridItem>
                <CompanyTypo>{otherUser?.company}</CompanyTypo>
                <PxGridContainer alignItems="center">
                  <SubHeadingTypo>{otherUser?.name}</SubHeadingTypo>
                  <CaptionTypo margin="0px 0px 0px 4px">
                    {otherUser?.position}
                  </CaptionTypo>
                </PxGridContainer>
              </PxGridItem>
              <PxGridItem>
                <PxIconButton onClick={drawerHandleOnClose}>
                  <CloseIcon />
                </PxIconButton>
              </PxGridItem>
            </PxGridContainer>

            {/* 이메일/전화번호 보내기 버튼 */}
            {messages.isLoading === false && (
              <div className={classes.sendEmailAndPwdBtn}>
                <PxButton
                  padding="9px 84px 9px"
                  backgroundcolor="black"
                  onClick={handleSendMyInfoOnClick}
                >
                  <ButtonTypo txtcolor="white">
                    {t("chatSidebar.sendMyInfo")}
                  </ButtonTypo>
                </PxButton>
              </div>
            )}
          </div>

          <Chat contentHeight="calc(100% - 140px)" />
        </div>
      </Drawer>
    </>
  );
};

export default ChatSidebar;
