import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormContext } from "../../components/Providers/FormProvider/FormProvider";
import { countryPhoneNumberCode } from "../../utils/constants";

// material
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import { AppState } from "../../store";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxOutlinedTxtField from "../../components/Inputs/PxOutlinedTxtField";
import PxGridItem from "../../components/Grid/PxGridItem";
import TitleTypo from "../../components/Typhography/TitleTypo";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import PxInputBase from "../../components/Inputs/PxInputBase";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import CaptionTypo from "../../components/Typhography/CaptionTypo";
import styled from "styled-components";
import { newAlert } from "../../actions/alerts";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardRoot: {
    padding: 20,
    margin: "20px 100px 40px 100px",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px 40px 0px",
      padding: 5,
    },
  },
  selectPadding: {
    "& > div": {
      padding: "8.5px",
      fontSize: "14px",
    },
  },
}));

interface BusinessCardFormProps {
  preview: boolean;
}

const BusinessCardForm = ({ preview }: BusinessCardFormProps) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const {
    businessCardId,
    setBusinessCardId,
    businessCardList,
    setBusinessCardList,
    subEvent,
    checkOnlyEngReturnString,
  } = useContext(FormContext);
  const { businessCards } = useSelector((state: AppState) => state.users); // 명함 redux state
  // const pattern_special = /[~!@\#$%<>^&*\()\-=+_\’]/gi,
  const handleSelectBoxChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setBusinessCardId(event.target.value as number);
  };

  const handleBusinessCardTextOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newBusinessCardList = [...businessCardList];
    const value = checkOnlyEngReturnString(event.target.value as string);
    // 영어만 입력

    // 전화번호 validation (only number)
    if (event.target.name === "phoneNumber") {
      if (RegExp("^\\d+$").test(value) || (value as string) === "") {
        (newBusinessCardList[businessCardList.length - 1] as any)[
          event.target.name
        ] = value;
        setBusinessCardList(newBusinessCardList);
      }
    } else {
      (newBusinessCardList[businessCardList.length - 1] as any)[
        event.target.name
      ] = value;
      setBusinessCardList(newBusinessCardList);
    }
  };

  const handleCountryCodeSelectBoxOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    const newBusinessCardList = [...businessCardList];
    newBusinessCardList[businessCardList.length - 1].countryNumber = event
      .target.value as string;
    setBusinessCardList(newBusinessCardList);
  };

  // redux state default 명함 data set(회원가입 시 첫번째로 만들어지는 명함)
  useEffect(() => {
    // 처음 mount 됐을때만 실행 시키기 위한 조건

    if (businessCards!.length >= businessCardList.length) {
      setBusinessCardId(
        businessCardList.length + (businessCards?.length ?? 0) - 1
      );
      setBusinessCardList((prevState) => {
        const newList = [...prevState, ...businessCards!];
        return newList;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessCards, subEvent]);

  useEffect(() => {
    console.log(`businessCards : `, businessCards);
  }, [businessCards]);
  return (
    <div>
      {/* {preview === false && (
        <FormControl className={classes.formControl} fullWidth>
          <PxSelectBox
            value={businessCardId}
            onChange={handleSelectBoxChange}
            name="businessCard"
            className={classes.selectEmpty}
            inputProps={{ "aria-label": "businessCard" }}
          >
            {businessCardList.length !== 0 &&
              businessCardList.map((businessCard, idx) =>
                idx === businessCardList.length - 1 ? (
                  <MenuItem value={idx}>
                    {t("businessCard.enterDirectly")}
                  </MenuItem>
                ) : (
                  <MenuItem
                    value={idx}
                  >{`${businessCard.name}(${businessCard.company})`}</MenuItem>
                )
              )}
          </PxSelectBox>
        </FormControl>
      )} */}
      {/* {businessCardList.length !== 0 &&
      businessCardId !== businessCardList.length - 1 ? (
        <PxGridContainer direction="column">
          <PxGridItem>
            <SubHeadingTypo>
              {businessCardList[businessCardId].company}
            </SubHeadingTypo>
          </PxGridItem>
          <PxGridItem className="mt-4">
            <CaptionTypo>
              {businessCardList[businessCardId].position ?? ""}
            </CaptionTypo>
            <TitleTypo>{businessCardList[businessCardId].name}</TitleTypo>
          </PxGridItem>
          <PxGridItem className="mt-4">
            <ButtonTypo>
              {`${businessCardList[businessCardId].email}`}
            </ButtonTypo>
          </PxGridItem>
          <PxGridItem>
            <ButtonTypo>
              {`(${businessCardList[businessCardId].countryNumber})${businessCardList[businessCardId].phoneNumber}`}
            </ButtonTypo>
          </PxGridItem>
        </PxGridContainer>
      ) : */}

      <form>
        <PxGridContainer direction="column">
          <PxGridItem>
            <Title>{t("businessCard.email")}</Title>
            <PxOutlinedTxtField
              fullWidth
              value={businessCardList[businessCardList.length - 1]?.email || ""}
              name="email"
              fontsize={14}
              inputpadding={10}
              placeholder={t("businessCard.email")}
              onChange={handleBusinessCardTextOnChange}
            />
          </PxGridItem>

          <PxGridItem className="mt-4">
            <Title>{t("businessCard.name")}</Title>
            <PxOutlinedTxtField
              fullWidth
              value={businessCardList[businessCardList.length - 1]?.name || ""}
              name="name"
              fontsize={14}
              inputpadding={10}
              placeholder={t("businessCard.name")}
              onChange={handleBusinessCardTextOnChange}
            />
          </PxGridItem>
          <PxGridItem className="mt-4">
            <Title>{t("businessCard.phoneNumber")}</Title>
            <PxGridContainer direction="row" wrap="nowrap" alignItems="center">
              <PxGridItem>
                <PxSelectBox
                  className={classes.selectPadding}
                  variant="outlined"
                  value={
                    businessCardList[businessCardList.length - 1]
                      ?.countryNumber || 0
                  }
                  onChange={handleCountryCodeSelectBoxOnChange}
                  displayEmpty
                  name="countryNumber"
                >
                  <MenuItem value="" disabled>
                    {t("businessCard.countryNumber")}
                  </MenuItem>
                  {countryPhoneNumberCode.map((countryCode) => (
                    <MenuItem value={countryCode.code}>
                      {i18n.language === "ko"
                        ? countryCode.country
                        : countryCode.countryEn}
                    </MenuItem>
                  ))}
                </PxSelectBox>
              </PxGridItem>
              <PxGridItem className="ml-1" fullwidth>
                <PxOutlinedTxtField
                  fullWidth
                  value={
                    businessCardList[businessCardList.length - 1]
                      ?.phoneNumber || ""
                  }
                  name="phoneNumber"
                  fontsize={14}
                  inputpadding={10}
                  placeholder={t("businessCard.phoneNumber")}
                  onChange={handleBusinessCardTextOnChange}
                />
              </PxGridItem>
            </PxGridContainer>
          </PxGridItem>
          <PxGridItem className="mt-4">
            <Title>{t("businessCard.companyName")}</Title>
            <PxOutlinedTxtField
              fullWidth
              value={
                businessCardList[businessCardList.length - 1]?.company || ""
              }
              name="company"
              fontsize={14}
              inputpadding={10}
              placeholder={t("businessCard.companyName")}
              onChange={handleBusinessCardTextOnChange}
            />
          </PxGridItem>
          <PxGridItem className="mt-4">
            <Title>{t("businessCard.position")}</Title>
            <PxOutlinedTxtField
              fullWidth
              value={
                businessCardList[businessCardList.length - 1]?.position || ""
              }
              name="position"
              fontsize={14}
              inputpadding={10}
              placeholder={t("businessCard.position")}
              onChange={handleBusinessCardTextOnChange}
            />
          </PxGridItem>
        </PxGridContainer>
      </form>
    </div>
  );
};

export default BusinessCardForm;

const Title = styled.div`
  white-space: pre-line;
  font-weight: 500;
  font-size: 15;
`;
