import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import FileSaver from "file-saver";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import PxButton from "../Buttons/PxButton";
import ButtonTypo from "../Typhography/ButtonTypo";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { changeImgPathToCloudfrontPath } from "../../utils/utils";

interface ShowFileProps {
  fileId: number;
}

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    position: "relative",
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "56.2%",
    },
  },
  image: {
    maxWidth: "100%",
    // height: 253.125,
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
  },
  fileIcon: {
    width: 16,
    height: 16,
    transform: "translateY(-1px)",
  },
}));

const ShowFile = ({ fileId }: ShowFileProps) => {
  const classes = useStyles();
  const [fileState, setFileState] = useState<any>();
  useEffect(() => {
    getFile(fileId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFile = async (id: number) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const res = await axios.get(`/api/fileS3/getFile?id=${id}`, config);

    setFileState(res.data);
  };

  const imgChk = useCallback((fileType: string, filePath: string) => {
    var reg = /(jpg|jpeg|png|gif|bmp)$/;

    if (fileType.match(reg)) {
      return (
        <div className={classes.imageContainer}>
          <img
            src={changeImgPathToCloudfrontPath(filePath)}
            className={classes.image}
          />
        </div>
      );
    }
  }, []);

  return fileState !== undefined ? (
    <Grid item sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
      {imgChk(fileState.fileType, fileState.filePath)}
      <PxButton
        backgroundcolor="pale-grey"
        boxShadow={false}
        borderRadius={false}
        onClick={() => {
          FileSaver.saveAs(fileState.filePath, fileState.fileName);
        }}
      >
        <ButtonTypo>
          <AttachFileIcon className={classes.fileIcon} /> {fileState.fileName}
        </ButtonTypo>
      </PxButton>
    </Grid>
  ) : null;
};

export default ShowFile;
