import React from "react";
import styled from "styled-components";

// material ui comp
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";

interface PxIconButtonProps {
  padding?: number;
  margin?: number;
}

type Prop = IconButtonProps & PxIconButtonProps;

const PxIconButton: React.FC<Prop> = styled((props: Prop) => {
  return <IconButton {...props} />;
})`
  padding: ${(props) => `${props.padding}px`};
  outline: none !important;
  margin: ${(props) => `${props.margin}px`};
`;

PxIconButton.defaultProps = {
  padding: 10,
};

export default PxIconButton;
