import React, { useContext } from "react";
import styled from "styled-components";
import { MeetingTimeInfo } from "../../../../types/models/SubEvent";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Hourglass from "../../../../assets/icons/hourglass-bottom.svg";
import { ApplicationMeetingContext } from "./ApplicationMeetingContextProvider";
import { useTranslation } from "react-i18next";

interface ApplicationMeetingCardProps {
  meetingTime: MeetingTimeInfo;
  meetingDate?: string;
}

const ApplicationMeetingCard = ({
  meetingTime,
  meetingDate,
}: ApplicationMeetingCardProps) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const {
    setMeetingRequestCancelDialogOpen,
    setMeetingApproveOrRejectDialogOpen,
    setMeetingAcceptConfirmDialogOpen,
    setSelectedMeeting,
  } = useContext(ApplicationMeetingContext);

  // 응답대기중인 미팅 취소
  const onClickCancelRequestMeeting = () => {
    if (meetingDate) {
      setSelectedMeeting({
        meetingData: meetingTime.requestedMeeting,
        meetingDateAndTime: {
          date: meetingDate,
          time: `${meetingTime.startTime}~${meetingTime.endTime}`,
          showDate: meetingTime.showDate!,
          showTime: `${meetingTime.showStartTime} ~ ${meetingTime.showEndTime}`,
        },
      });
      setMeetingRequestCancelDialogOpen(true);
    }
  };

  // 수락대기중인 미팅 수락 또는 거절 or 체결된 미팅 취소 (isConfirmed 값 true)
  const onClickWaitingMeetingAcceptOrReject = (
    status: "agree" | "disagree",
    isConfirmed?: boolean
  ) => {
    if (meetingDate) {
      // 체결된 미팅
      if (isConfirmed) {
        setSelectedMeeting({
          meetingData: meetingTime.matchedMeeting,
          status: status,
          isConfirmed: true,
          meetingDateAndTime: {
            date: meetingDate,
            time: `${meetingTime.startTime}~${meetingTime.endTime}`,
            showDate: meetingTime.showDate!,
            showTime: `${meetingTime.showStartTime} ~ ${meetingTime.showEndTime}`,
          },
        });
      } else {
        // 수락대기중인 미팅
        setSelectedMeeting({
          meetingData: meetingTime.receivedMeeting,
          status: status,
          meetingDateAndTime: {
            date: meetingDate,
            time: `${meetingTime.startTime}~${meetingTime.endTime}`,
            showDate: meetingTime.showDate!,
            showTime: `${meetingTime.showStartTime} ~ ${meetingTime.showEndTime}`,
          },
        });
      }
      setMeetingApproveOrRejectDialogOpen(true);
    }
  };

  // 미팅신청 dialog
  const onClickMeetingRequest = () => {
    if (meetingDate) {
      setSelectedMeeting({
        meetingDateAndTime: {
          date: meetingDate,
          time: `${meetingTime.startTime}~${meetingTime.endTime}`,
          showDate: meetingTime.showDate!,
          showTime: `${meetingTime.showStartTime} ~ ${meetingTime.showEndTime}`,
        },
      });
      setMeetingAcceptConfirmDialogOpen(true);
    }
  };

  return (
    <MeetingCardForm>
      <MeetingCardInfo>
        <MeetingCardInfoTime>
          <span>
            {meetingTime.showStartTime} - {meetingTime.showEndTime}
            {/* {meetingTime.startTime} - {meetingTime.endTime} */}
          </span>
        </MeetingCardInfoTime>
      </MeetingCardInfo>

      {/* 상대업체의 미팅이 내가 아닌 다른 업체와 체결된 상태 or 주최자 미팅불가 시간 설정 (미팅불가) */}
      {(meetingTime.impossible === "Y" || meetingTime.impossibleAt === "Y") && (
        <MeetingStatus bgColor="0, 0, 0" borderColor="#c7c8cb">
          <BlockIcon
            style={{
              color: "rgba(0, 0, 0, 0.38)",
              verticalAlign: "bottom",
              fontSize: "1.188rem",
              marginRight: 5,
            }}
          />
          {/* 미팅불가 */}
          {t("applicationMeetingCard.meetingImpossible")}
        </MeetingStatus>
      )}
      {/* 상대업체와 내가 미팅체결 (미팅체결)*/}
      {meetingTime.matchedMeeting && (
        <>
          <MeetingStatus bgColor="69, 39, 160">
            <CheckCircle
              style={{
                color: "rgba(69, 39, 160,1)",
                verticalAlign: "bottom",
                fontSize: "1.188rem",
                marginRight: 5,
              }}
            />
            {/* 미팅체결 */}
            {t("applicationMeetingCard.meetingConfirmed")}
          </MeetingStatus>
          <MeetingCardActionButton>
            <ActionButtonItem
              onClick={() => {
                onClickWaitingMeetingAcceptOrReject("disagree", true);
              }}
            >
              {/* 취소 */}
              {t("applicationMeetingCard.cancel")}
            </ActionButtonItem>
          </MeetingCardActionButton>
        </>
      )}
      {/* 상대업체가 나에게 미팅을 요청한 상태 (수락대기중)*/}
      {meetingTime.receivedMeeting && (
        <>
          <MeetingStatus bgColor="252, 178, 30">
            <StatusIcon />
            {/* 수락대기중 */} {t("applicationMeetingCard.meetingWaiting")}
          </MeetingStatus>
          <MeetingCardActionButton>
            <ActionButtonItem
              iris
              onClick={() => {
                onClickWaitingMeetingAcceptOrReject("agree");
              }}
            >
              {/* 수락 */}
              {t("applicationMeetingCard.accept")}
            </ActionButtonItem>
            <ActionButtonItem
              onClick={() => {
                onClickWaitingMeetingAcceptOrReject("disagree");
              }}
            >
              {/* 거절 */}
              {t("applicationMeetingCard.reject")}
            </ActionButtonItem>
          </MeetingCardActionButton>
        </>
      )}

      {/* 상대업체에게 내가 미팅을 요청한 상태 (응답대기중)*/}
      {meetingTime.requestedMeeting && (
        <>
          <MeetingStatus bgColor="121, 85, 72">
            <ApplicationIcon />
            {/* 응답대기중 */}
            {t("applicationMeetingCard.waitingResponse")}
          </MeetingStatus>
          <MeetingCardActionButton>
            <ActionButtonItem onClick={onClickCancelRequestMeeting}>
              {/* 취소 */}
              {t("applicationMeetingCard.cancel")}
            </ActionButtonItem>
          </MeetingCardActionButton>
        </>
      )}

      {/* 상대업체에게 미팅신청 가능 (비어있음) */}
      {meetingTime.impossible === undefined &&
        meetingTime.impossibleAt === "N" &&
        meetingTime.matchedMeeting === undefined &&
        meetingTime.receivedMeeting === undefined &&
        meetingTime.requestedMeeting === undefined && (
          <>
            <MeetingStatus bgColor="0, 181, 223">
              {/* 비어있음 */}
              {t("applicationMeetingCard.empty")}
            </MeetingStatus>
            <MeetingCardActionButton>
              <ActionButtonItem onClick={onClickMeetingRequest}>
                {/* 미팅신청 */}
                {t("applicationMeetingCard.meetingRequest")}
              </ActionButtonItem>
            </MeetingCardActionButton>
          </>
        )}
    </MeetingCardForm>
  );
};

export default ApplicationMeetingCard;

const MeetingCardForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 128px;
  border-radius: 4px;
  max-width: 15rem;
  border: solid 1px rgba(233, 234, 237, 1);
`;

const MeetingCardInfo = styled.div`
  padding: 11px 16px;
  height: 40px;
`;

const MeetingCardInfoTime = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
`;

const MeetingStatus = styled.div<{
  bgColor: string;
  borderColor?: string;
}>`
  width: 100%;
  min-height: 40px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-left: solid 3px
    ${(props) =>
      props.borderColor !== undefined
        ? props.borderColor
        : "rgb(" + props.bgColor + ")"};
  background-color: rgba(${(props) => props.bgColor}, 0.08);
  padding: 8px 16px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  img {
    vertical-align: bottom;
  }
`;

const MeetingCardActionButton = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & svg {
    cursor: pointer;
  }
`;

const ActionButtonItem = styled.button<{
  iris?: boolean;
}>`
  border: none;
  background-color: transparent;
  color: ${(props) =>
    props.iris ? "rgba(126, 87, 194,1)" : "rgba(0, 0, 0, 0.6)"};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }
`;

const StatusIcon = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #fba01c;
  margin-right: 5px;
  vertical-align: text-bottom;
  mask: url(${Hourglass}) no-repeat 50% 50%;
`;

const ApplicationIcon = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #795548;
  margin-right: 5px;
  vertical-align: text-bottom;
  mask: url(${Hourglass}) no-repeat 50% 50%;
`;
