import { useState, useEffect } from "react";
import { useAppState } from "../components/AppStateProvider/hooks/useAppState";

interface UserAudio {
  url: string;
}

const useAudio = ({ url }: UserAudio) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const { activeSinkId } = useAppState();

  const toggle = () => {
    setPlaying(!playing);
  };

  // 오디오 출력 디바이스 변경 시 동작
  useEffect(() => {
    audio.setSinkId?.(activeSinkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSinkId]);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [playing, toggle] as const;
};

export default useAudio;
