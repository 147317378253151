import React, { useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import useVideoContext from "../../hooks/useVideoContext";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ParticipantStripForMany from "../Participant/PartcipantStripForMany";
import MainParticipantForMany from "../MainParticipant/MainParticipantForMany";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import TitleTypo from "../../../components/Typhography/TitleTypo";
import CounselForm from "../CounselForm/CounselForm";
import { useTranslation } from "react-i18next";
import ChatForm from "../ChatForm/ChatForm";
import { ChatContext } from "../ChatProvider/ChatProvider";
import moment from "moment";
import { AppState } from "../../../store";
import { useSelector } from "react-redux";
import { parallaxCalculation } from "../../../utils/momentTz";
import PxSlideDialog from "../../../components/Dialog/PxSlideDialog";
import { DialogContent, DialogContentText } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import PxButton from "../../../components/Buttons/PxButton";
import MultiScreen from "../MultiScreen/MultiScreen";
import CloseIcon from "@material-ui/icons/Close";
import PxIconButton from "../../../components/Buttons/PxIconButton";

const useStyles = makeStyles((theme) =>
  createStyles({
    containerWithParticipants: {
      position: "relative",
      height: "100%",
      display: "grid",
      gridTemplateColumns: `${theme.sidebarWidth}px 1fr`,
      gridTemplateAreas: '". participantList"',
      gridTemplateRows: "100%",
      [theme.breakpoints.down("xs")]: {
        gridTemplateAreas: '"participantList" "."',
        gridTemplateColumns: `auto`,
        gridTemplateRows: "90px",
      },
    },
    containerWithCounselForm: {
      position: "relative",
      height: "100%",
      display: "grid",
      gridTemplateColumns: `1fr ${theme.couselFormWidth}px`,
      gridTemplateAreas: '". participantList"',
      gridTemplateRows: "100%",
      [theme.breakpoints.down("xs")]: {
        gridTemplateAreas: '"participantList" "."',
        gridTemplateColumns: `auto`,
        gridTemplateRows: "90px",
      },
    },
    counselFormDiv: {
      padding: 15,
      overflow: "scroll",
    },
    partiForManyDiv: {
      backgroundColor: "rgb(40, 41, 42)",
      overflowY: "auto",
      [theme.breakpoints.down("xs")]: {
        overflowY: "initial",
        overflowX: "auto",
      },
    },
    partiForManyDivNone: {
      display: "none",
    },
    serveyTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

const Room = () => {
  const classes = useStyles();
  const [t] = useTranslation("lang", { useSuspense: false });
  const { room } = useVideoContext();
  const {
    isSurveyFormEnabled,
    isMultiScreenEnabled,
    toggleSurveyFormEnabled,
  } = useAppState();
  const { chatChannel, videoChatSidebarOpen } = useContext(ChatContext);

  const [showAlert, setShowAlert] = useState<boolean>(false); // 미팅 끝나기 3분전 알림 Alert

  const meetingVideo = useSelector(
    (state: AppState) => state.meetings.meetingVideo
  );
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  useEffect(() => {
    return () => {
      try {
        room.disconnect();
      } catch (err) {
        console.log(err);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (chatChannel !== undefined) {
        chatChannel.leave();
        chatChannel.removeAllListeners();
      }
    };
  }, [chatChannel]);

  // 미팅 끝나기 3분 전 알림
  useEffect(() => {
    if (meetingVideo !== undefined && subEvent !== undefined) {
      // subEvent에서 설정된 주최지 countryCode
      const countryCode = subEvent.mainEvent?.countryCode;

      // 현재 날짜
      const nowDate = moment().format("YYYY-MM-DD HH:mm:ss");

      // 국가에 맞는 종료시간 조회
      const countryEndTime: string = parallaxCalculation(
        `${meetingVideo.date}T${meetingVideo.endTime}`,
        countries![countryCode as string] as any,
        countries![country!.countryCode!] as any,
        "YYYY-MM-DD HH:mm"
      );

      const splittedNowTime = nowDate.split(" "); // 현재 날짜, 시간 [0] : 날짜 YYYY-MM-DD, 시간: HH:mm
      const splittedEndTime = countryEndTime.split(" "); // 미팅이 끝나는 날짜, 시간 [0] : 날짜 YYYY-MM-DD, 시간: HH:mm

      // 종료시간의 날짜와 현재날짜가 같을때만 동작
      if (moment(splittedEndTime[0]).isSame(splittedNowTime[0])) {
        const momentCountryEndTime = moment(countryEndTime);
        const momentNowLocalTime = moment(nowDate);

        // 현재시간과 미팅이 끝나는 시간의 간격(초)
        const diffSec = momentCountryEndTime.diff(
          momentNowLocalTime,
          "seconds"
        );

        // 시간간격에서 3분을 뺸 시간 => 3분전 알림을 위해
        const threeMinBehindSec = diffSec - 180;

        // 미팅이 끝나는 시간이 현재시간 이후여야 동작
        if (threeMinBehindSec > 0) {
          setTimeout(() => {
            setShowAlert(true);
          }, [threeMinBehindSec * 1000]); // s -> ms 로 변경
        }
      }
    }
  }, [countries, country, meetingVideo, subEvent]);

  // unmount 시 setTimeOut clear
  useEffect(() => {
    return () => {
      clearTimeout();
    };
  }, []);

  // 미팅종료 알림 3분전 alert close
  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const handleServeyOpen = useCallback(() => {
    toggleSurveyFormEnabled(false);
  }, []);

  return (
    <>
      {/* 미팅종료 3분전 알림 dialog */}
      <PxSlideDialog open={showAlert} onClose={handleAlertClose}>
        <DialogContent>
          <DialogContentText>
            <TitleTypo>{t("video.meetingEndAlertDialog")}</TitleTypo>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PxButton
            onClick={handleAlertClose}
            backgroundcolor="purple"
            style={{ color: "white" }}
          >
            {t("video.confirm")}
          </PxButton>
        </DialogActions>
      </PxSlideDialog>
      {isMultiScreenEnabled ? (
        <MultiScreen />
      ) : (
        <div
          className={clsx(classes.containerWithParticipants, {
            [classes.containerWithCounselForm]:
              isSurveyFormEnabled || videoChatSidebarOpen,
          })}
        >
          <div
            className={
              videoChatSidebarOpen === false && isSurveyFormEnabled === false
                ? classes.partiForManyDiv
                : classes.partiForManyDivNone
            }
          >
            <ParticipantStripForMany />
          </div>

          <MainParticipantForMany />
          {/* {isSurveyFormEnabled === true && ( */}
          <div
            className={classes.counselFormDiv}
            style={{ display: isSurveyFormEnabled ? "block" : "none" }}
          >
            <div className={classes.serveyTitle}>
              <TitleTypo style={{ textAlign: "left" }} gutterBottom>
                {t("video.consultation")}
              </TitleTypo>
              <PxIconButton onClick={handleServeyOpen}>
                <CloseIcon />
              </PxIconButton>
            </div>

            <CounselForm />
          </div>
          {/* )} */}
          {videoChatSidebarOpen === true && <ChatForm />}
        </div>
      )}
    </>
  );

  // 1:1 통화전용
  // return (
  //   <>
  //     <ParticipantStrip />
  //   </>
  // );
};

export default Room;
