import React, { useState, useEffect, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { AppState } from "../../../../store";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { MenuItem } from "@material-ui/core";

//Icon
import Hourglass from "../../../../assets/icons/hourglass-bottom.svg";
import {
  CheckCircle as CheckCircleIcon,
  Block as BlockIcon,
} from "@material-ui/icons/";

// custom ui comp
import MeetingTimeForm from "./MeetingTimeForm";

import styled from "styled-components";
import { MeetingData } from "../../../../types/models/SubEvent";
import PxDropDown from "../../../../components/Menu/PxDropDown";
import {
  getMeetingApplication,
  applicationActionStateToInitial,
} from "../../../../actions/applications";
import {
  getApplicantCancelMeetingHistory,
  getApplicantMeetingHistory,
  getMyMeetings,
  impossibleMeetingList,
  meetingsActionStateToInitial,
  removeImpossibleMeetingList,
} from "../../../../actions/meeting";
import { locationParamData } from "../../../../utils/utils";
import {
  convertEtcToGmt,
  parallaxCalculation,
} from "../../../../utils/momentTz";

// video
import Video from "../../../../video";
import { useTranslation } from "react-i18next";
import PxBreakpoints from "../../../../components/PxBreakpoint/PxBreakpoint";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import PxDialog from "../../../../components/Dialog/PxDialog";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { MeetingHistory } from "../../../../types/models/Meeting";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CaptionTypo from "../../../../components/Typhography/CaptionTypo";
import Container from "@material-ui/core/Container";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import Box from "@material-ui/core/Box";
import MeetingTips from "../../../../components/Tips/MeetingTips";
import PxCalendar from "../../../../components/PxCalendar/PxCalendar";
import moment from "moment";
import { removeLoading, setLoading } from "../../../../actions/loading";
import DownloadMeetingSchedule from "./DownloadMeetingSchedule";

const CompanyName = styled.div`
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -1px;
  color: rgba(0, 0, 0, 0.87);
`;
const CompanyType = styled.div`
  margin-bottom: 32px;
  height: 24px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.6);
`;

const DisableContainer = styled.div`
  display: flex;
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
  height: 36px;
  font-size: 24px;
  font-weight: 500;
  padding-left: 12px;
  color: rgba(0 0 0, 0.87);
  &::before {
    position: absolute;
    left: 0;
    width: 4px;
    height: 28px;
    transform: translate(0%, 20%);
    content: "";
    border: 2px solid rgb(81, 45, 168);
    border-radius: 2px;
  }
`;

const HourIcon = styled.div`
  float: right;
  width: 16px;
  height: 16px;
  font-weight: 500;
  margin-top: 8px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #fba01c;
  vertical-align: text-bottom;
  mask: url(${Hourglass}) no-repeat 50% 50%;
`;
const ApplicationIcon = styled.div`
  float: right;
  width: 16px;
  height: 16px;
  font-weight: 500;
  margin-top: 8px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #795548;
  vertical-align: text-bottom;
  mask: url(${Hourglass}) no-repeat 50% 50%;
`;
const CountryTime = styled.div`
  width: 100%;
  height: 18px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 500;
  padding-top: 4px;
`;

const useStyles = makeStyles((theme) => ({
  listRoot: {
    minWidth: 360,
  },
  meetingListItemAvatar: {
    alignSelf: "center",
  },
}));

// 미팅신청 param interface
export interface MeetingApplicantDataInterface {
  subEventId: string;
  applicant: string;
  acceptor: string;
  meetingId: string;
  status: "waiting" | "agree" | "disagree" | "impossible"; // 상태 [대기: waiting, 동의: agree, 비동의: disagree]
  date: string;
  startTime: string;
  endTime: string;
  mailPostToggleAt?: string;
}

export interface RemoveImpossibleList {
  meetingIds: number[];
}

// createContext 타입
interface MyMeetingContextType {
  myMeetingCheckList: MyMeetingCheckListKeyType;
  myMeetingSearch: MyMeetingSearchType;
  videoDialogOpen: boolean;
  setVideoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  secondId: string;
  disableStatus: boolean;
  disableLiftStatus: boolean;
  selectedList: MeetingApplicantDataInterface[] | number[];
  // setImpossibleCheckList: React.Dispatch<
  //   React.SetStateAction<MeetingApplicantDataInterface[]>
  // >;
}

// 미팅 데이터 가공([key]: date), 목록에서 내가 신청했는지 파악하기 위해 필요
export interface MyMeetingCheckListKeyType {
  [key: string]: MyMeetingCheckListType[];
}

// 미팅 데이터 가공 데이터 타입([key]: data) => data
export interface MyMeetingCheckListType {
  id: string;
  acceptor: string;
  acceptorName: string;
  applicant: string;
  applicantName: string;
  status: string;
  applicantManagerName: string;
  acceptorManagerName: string;
  meetingType: "online" | "offline";
}
export interface MyMeetingSearchType {
  name: string;
  keyword: string;
  category: string;
}
export const MyMeetingContext = createContext<MyMeetingContextType>(null!);

enum SelectMode {
  IMPOSSIBLE,
  DISABLELIFT,
}

const MeetingDetail = () => {
  const classes = useStyles();
  const [t] = useTranslation("lang", { useSuspense: false });
  const meettingStatus = [
    { value: "", label: t("meetingDetail.meetingStatus") },

    {
      value: "waitingApplicant",
      label: t("meetingDetail.applyingForMeeting"),
      icon: <ApplicationIcon style={{ marginTop: 3 }} />,
    },
    {
      value: "waitingAcceptor",
      label: t("meetingDetail.pendingAcceptance"),
      icon: <HourIcon style={{ marginTop: 3 }} />,
    },
    {
      value: "agree",
      label: t("meetingDetail.meetingConclusion"),
      icon: (
        <CheckCircleIcon
          style={{
            color: "rgba(69, 39, 160,1)",
            fontSize: 18,
            float: "right",
            marginTop: 3,
          }}
        />
      ),
    },
    {
      value: "impossible",
      label: t("meetingDetail.nonTime"),
      icon: (
        <BlockIcon
          style={{
            color: "rgba(0, 0, 0,0.38)",
            fontSize: 18,
            float: "right",
            marginTop: 3,
          }}
        />
      ),
    },
    {
      value: "reject",
      label: "거절된 미팅",
    },
    {
      value: "cancel",
      label: "취소된 미팅",
    },
  ];
  const dispatch = useDispatch();
  const location = useLocation();
  const [meetingStatusLabel, setMeetingStatusLabel] = useState<string>(
    t("meetingDetail.meetingStatus")
  ); // 대기: waiting, 동의: agree, 불가시간: impossible
  const [meetingStatusValue, setMeetingStatusValue] = useState<string>("");
  const { name, id } = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const meetings = useSelector((state: AppState) => state.meetings.meetings);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  const [selectableDate, setSelectableDate] = useState({
    startDate: "",
    endDate: "",
  });
  const countryCode = useSelector(
    (state: AppState) => state.countries.countries!
  );
  const [meetingData, setMeetingData] = useState<MeetingData>();
  const [date, setDate] = useState<moment.Moment>(() => moment());

  // 미팅목록별 카드 ui변경을 위함
  const [myMeetingCheckList, setMyMeetingCheckList] = useState({});

  // 검색 변수
  const [searchData, setSearchData] = useState<MyMeetingSearchType>({
    name: "",
    keyword: "",
    category: "",
  });

  // video Call dialog
  const [videoDialogOpen, setVideoDialogOpen] = useState<boolean>(false);

  // 거절된 미팅리스트 dialog
  const [rejectedMeetingListDialogOpen, setRejectedMeetingListDialogOpen] =
    useState<boolean>(false);
  const [rejectedMeetingList, setRejectedMeetingList] = useState<
    MeetingHistory[]
  >([]);

  // 취소된 미팅리스트 dialog
  const [cancelMeetingListDialogOpen, setCancelMeetingListDialogOpen] =
    useState<boolean>(false);
  const [cancelMeetingList, setCanelMeetingList] = useState<MeetingHistory[]>(
    []
  );

  const [disableStatus, setDisableStatus] = useState<boolean>(false);
  const [disableLiftStatus, setDisableLiftStatus] = useState<boolean>(false);

  const [selectedList, setSelectedList] = useState<
    MeetingApplicantDataInterface[] | number[]
  >([]);

  const [meetingSchedule, setMeetingSchedule] = useState<MeetingData[]>([]);

  // let selectedList: MeetingApplicantDataInterface[] | number[] = [];

  useEffect(() => {
    if (subEvent !== undefined && subEvent.meetingData !== "") {
      let parsingData = subEvent.meetingData!;
      const array: MeetingData = JSON.parse(parsingData);
      setMeetingData(array);
    }
    if (subEvent) {
      !date.isBetween(
        subEvent.subEventStartDate,
        subEvent.subEventEndDate,
        undefined,
        "[]"
      ) &&
        setDate(
          moment(
            `${subEvent.subEventStartDate.split("T")[0]}T${
              subEvent.matchStartTime
            }`
          )
        );
      //달력선태기간 설정 ----------------------
      const countryCode = subEvent!.mainEvent?.countryCode;
      const countMinute =
        subEvent.matchNumPerDay! *
          (subEvent.matchReqTime! + subEvent.matchBreakTime!) -
        subEvent.matchBreakTime!;
      // 미팅시작 기준 날짜
      const defaultDate = `${subEvent!.subEventEndDate.split("T")[0]}T${
        subEvent.matchStartTime
      }:00`;
      //미팅 마지막 시간 계산
      const endTimeCalculation = moment(defaultDate).add(countMinute, "m");

      const startDate = parallaxCalculation(
        `${subEvent!.subEventStartDate.split("T")[0]}T${
          subEvent.matchStartTime
        }:00`,
        countries![countryCode! as string] as any,
        countries![country!.countryCode!] as any,
        "YYYY-MM-DD"
      );

      // 국가 시간 변경시 마지막 미팅시간이 다음날로 넘어갈 경우가 있음
      // 마지막 미팅시간 구해서 계산해줘야함
      const endDate = parallaxCalculation(
        endTimeCalculation.format("YYYY-MM-DDTHH:mm"),
        countries![countryCode! as string] as any,
        countries![country!.countryCode!] as any,
        "YYYY-MM-DD"
      );
      setSelectableDate({ startDate, endDate });
      //------------------------------------------
    }
  }, [subEvent]);

  // 기업정보, 미팅 리스트 조회
  useEffect(() => {
    if (id !== undefined && id !== "") {
      dispatch(getMeetingApplication(id));
      dispatch(getMyMeetings(id));
    }

    return () => {
      dispatch(applicationActionStateToInitial);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // 검색 필터
  useEffect(() => {
    const locationSearchData = locationParamData(location.search);

    if (Object.keys(locationSearchData).length !== 0) {
      setSearchData(locationSearchData as any);
    }
  }, [location]);

  // 내 미팅목록 가공(미팅 카드, 리스트별 구분을 위함)
  useEffect(() => {
    let myMeetingCardSetting: MyMeetingCheckListKeyType = {};

    if (meetings !== undefined && meetings.length > 0) {
      meetings.map((meeting) => {
        const acceptorType = meeting.meetingAcceptor?.attendeeType;
        const applicantType = meeting.meetingApplicant?.attendeeType;

        let MyMeetingContextData: MyMeetingCheckListType = {
          id: meeting.id as string,
          acceptor: meeting.acceptor as string,
          acceptorName: meeting.acceptorName as string,
          applicant: meeting.applicant as string,
          applicantName: meeting.applicantName as string,
          status: meeting.status as string,
          applicantManagerName: meeting.applicantManagerName as string,
          acceptorManagerName: meeting.acceptorManagerName as string,
          meetingType:
            acceptorType === "online" || applicantType === "online"
              ? "online"
              : "offline",
        };
        const key = `${meeting.date}${meeting.startTime}${meeting.endTime}`;
        if (
          Object.keys(myMeetingCardSetting).length !== 0 &&
          myMeetingCardSetting[key] !== undefined
        ) {
          // 등록 내역이 있을경우
          myMeetingCardSetting[key].push(MyMeetingContextData);
        } else {
          // 첫 등록일 경우
          Object.assign(myMeetingCardSetting, {
            [key]: [MyMeetingContextData],
          });
        }
      });
    }

    setMyMeetingCheckList(myMeetingCardSetting);
  }, [meetings]);

  // 노출 기준 시간
  const exposureTime = () => {
    const thisCountryTime =
      countryCode![country!.countryCode!] === undefined
        ? "Asia/Seoul(GMT+9)"
        : countryCode![country!.countryCode!].timezone;
    return thisCountryTime.indexOf("Etc/GMT") > -1
      ? "GMT" + convertEtcToGmt(thisCountryTime)
      : thisCountryTime;
  };

  const handleRejectedMeetingListDialogOnClose = () => {
    setRejectedMeetingListDialogOpen(false);
  };

  const handleCancelMeetingListDialogOnClose = () => {
    setCancelMeetingListDialogOpen(false);
  };

  const onClickRejectedMeetingListOpenButton = async () => {
    setRejectedMeetingListDialogOpen(true);
    if (id) {
      const rejectedMeetingList: any = await dispatch(
        getApplicantMeetingHistory(id)
      );
      setRejectedMeetingList(rejectedMeetingList);
    }
  };

  const onClickCancelMeetingListOpenButton = async () => {
    setCancelMeetingListDialogOpen(true);
    if (id) {
      const cancelMeetingList: any = await dispatch(
        getApplicantCancelMeetingHistory(id)
      );

      setCanelMeetingList(cancelMeetingList);
    }
  };

  const impossibleListDecison = async () => {
    dispatch(setLoading());
    const result: any = await dispatch(
      impossibleMeetingList(selectedList as MeetingApplicantDataInterface[])
    );
    if (result === true) {
      dispatch(getMyMeetings(id as string));
    }
    selectModeCancel(SelectMode.IMPOSSIBLE);
    dispatch(removeLoading());
  };

  const removeImpossibleList = async () => {
    dispatch(setLoading());
    const data: RemoveImpossibleList = {
      meetingIds: selectedList as number[],
    };
    const result: any = await dispatch(removeImpossibleMeetingList(data));
    if (result === true) {
      dispatch(getMyMeetings(id as string));
    }
    selectModeCancel(SelectMode.DISABLELIFT);
    dispatch(removeLoading());
  };

  const selectModeCancel = (mode: SelectMode) => {
    setSelectedList([]);
    mode == SelectMode.IMPOSSIBLE
      ? setDisableStatus(false)
      : setDisableLiftStatus(false);
  };

  const meetingStatusClick = (label: string, value: string) => {
    if (value == "cancel") onClickCancelMeetingListOpenButton();
    else if (value == "reject") onClickRejectedMeetingListOpenButton();
    else {
      setMeetingStatusLabel(label);
      setMeetingStatusValue(value);
    }
  };

  return meetingData !== undefined && id !== undefined && id !== "" ? (
    <PxBreakpoints>
      <Grid container style={{ marginTop: 10 }}>
        {videoDialogOpen === true ? (
          <Video
            videoDialogOpen={videoDialogOpen}
            setVideoDialogOpen={setVideoDialogOpen}
          />
        ) : null}
        <Grid item style={{ width: "100%" }}>
          <Title>
            {t("subEventNavBar.meetingList")}
            <PxDropDown label={meetingStatusLabel}>
              {meettingStatus.map((option) => (
                <MenuItem
                  style={{ display: "inherit" }}
                  key={option.value}
                  value={option.value}
                  onClick={() => {
                    // setMeetingStatusLabel(option.label);
                    // setMeetingStatusValue(option.value);
                    meetingStatusClick(option.label, option.value);
                  }}
                >
                  {option.label}&ensp;&ensp;{option.icon}
                </MenuItem>
              ))}
            </PxDropDown>
            <Box marginLeft="20px" display="inline" />
            <PxCalendar
              date={date}
              setDate={setDate}
              selectableStartDate={selectableDate.startDate}
              selectableEndDate={selectableDate.endDate}
              meetings={meetings}
            />
          </Title>

          <CompanyName>
            <CountryTime>
              {t("meetingDetail.connectionCountry")}: {country!.country}{" "}
              &nbsp;&nbsp; • &nbsp;&nbsp;&nbsp;
              {t("meetingDetail.connectionTime")}: {exposureTime()}
            </CountryTime>
          </CompanyName>

          {/* 거절된 미팅리스트 다이얼로그 */}
          <PxDialog
            open={rejectedMeetingListDialogOpen}
            onClose={handleRejectedMeetingListDialogOnClose}
          >
            <List
              className={classes.listRoot}
              subheader={
                <ListSubheader>
                  {t("meetingDetail.rejectedMeetingList")}
                </ListSubheader>
              }
            >
              {rejectedMeetingList.length === 0 && (
                <Container>
                  <Body1Typo>
                    {t("meetingDetail.NoneRejectedMeetingList")}
                  </Body1Typo>
                </Container>
              )}
              {rejectedMeetingList.length > 0 &&
                rejectedMeetingList.map((meeting) => (
                  <>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar className={classes.meetingListItemAvatar}>
                        <Avatar alt={meeting.acceptorName} src="" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={meeting.acceptorName}
                        secondary={
                          <React.Fragment>
                            <CaptionTypo txtcolor="rgba(0,0,0,0.87)">
                              {t("common.date")}: {meeting.date} <br />
                              {t("common.time")}: {meeting.startTime} ~{" "}
                              {meeting.endTime}
                            </CaptionTypo>
                            <CaptionTypo>
                              {t("meetingDetail.rejectedReason")}:{" "}
                              {meeting.reason !== "A" &&
                              meeting.reason !== "B" &&
                              meeting.reason !== "C"
                                ? meeting.reason
                                : t(`meetingRejectReason.${meeting.reason}`)}
                            </CaptionTypo>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                ))}
            </List>
          </PxDialog>

          {/* 취소된 미팅리스트 다이얼로그 */}
          <PxDialog
            open={cancelMeetingListDialogOpen}
            onClose={handleCancelMeetingListDialogOnClose}
          >
            <List
              className={classes.listRoot}
              subheader={
                <ListSubheader>
                  {t("meetingDetail.canceledMeetingList")}
                </ListSubheader>
              }
            >
              {cancelMeetingList.length === 0 && (
                <Container>
                  <Body1Typo>
                    {t("meetingDetail.NoneCanceledMeetingList")}
                  </Body1Typo>
                </Container>
              )}
              {cancelMeetingList.length > 0 &&
                cancelMeetingList.map((meeting) => (
                  <>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar className={classes.meetingListItemAvatar}>
                        <Avatar alt={meeting.acceptorName} src="" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          name === meeting.acceptorName
                            ? meeting.applicantName
                            : meeting.acceptorName
                        }
                        secondary={
                          <React.Fragment>
                            <CaptionTypo txtcolor="rgba(0,0,0,0.87)">
                              {t("common.date")}: {meeting.date} <br />
                              {t("common.time")}: {meeting.startTime} ~{" "}
                              {meeting.endTime}
                            </CaptionTypo>
                            <CaptionTypo>
                              {t("meetingDetail.canceledReason")}:{" "}
                              {meeting.reason !== "A" &&
                              meeting.reason !== "B" &&
                              meeting.reason !== "C"
                                ? meeting.reason
                                : t(`meetingRejectReason.${meeting.reason}`)}
                            </CaptionTypo>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                ))}
            </List>
          </PxDialog>

          <MenuContainer>
            <DownloadMeetingSchedule
              meetingSchedule={meetingSchedule}
              myMeetingList={myMeetingCheckList}
            />

            {/* 매칭불가 설정 */}
            {disableStatus || disableLiftStatus ? (
              <DisableContainer>
                <PxButton
                  disableElevation
                  borderColor="silver"
                  border
                  padding="5px 15px"
                  style={{ display: "block", marginLeft: "auto" }}
                  onClick={() => {
                    const mode = disableStatus
                      ? SelectMode.IMPOSSIBLE
                      : SelectMode.DISABLELIFT;
                    selectModeCancel(mode);
                  }}
                >
                  <ButtonTypo>{t("meetingDetail.cancel")}</ButtonTypo>
                </PxButton>
                <>
                  {disableStatus && (
                    <PxButton
                      disableElevation
                      borderColor="silver"
                      border
                      padding="5px 15px"
                      style={{ display: "block", marginLeft: "10px" }}
                      onClick={() => {
                        impossibleListDecison();
                      }}
                    >
                      {/* 매칭불가설정 완료 */}
                      <ButtonTypo>
                        {t("meetingDetail.doneMeetingDisable")}
                      </ButtonTypo>
                    </PxButton>
                  )}
                  {disableLiftStatus && (
                    <PxButton
                      disableElevation
                      borderColor="silver"
                      border
                      padding="5px 15px"
                      style={{ display: "block", marginLeft: "10px" }}
                      onClick={() => {
                        removeImpossibleList();
                      }}
                    >
                      {/* 매칭불가해제 완료 */}
                      <ButtonTypo>
                        {t("meetingDetail.doneUnsetMeetingDsable")}
                      </ButtonTypo>
                    </PxButton>
                  )}
                </>
              </DisableContainer>
            ) : (
              <DisableContainer>
                <PxButton
                  disableElevation
                  borderColor="silver"
                  border
                  padding="5px 15px"
                  style={{ display: "block", marginLeft: "auto" }}
                  onClick={() => {
                    setDisableStatus(true);
                  }}
                >
                  <ButtonTypo>{t("meetingDetail.matchingDisabled")}</ButtonTypo>
                </PxButton>
                <PxButton
                  disableElevation
                  borderColor="silver"
                  border
                  padding="5px 15px"
                  style={{ display: "block", marginLeft: "10px" }}
                  onClick={() => {
                    setDisableLiftStatus(true);
                  }}
                >
                  {/* 매칭불가해제 설정 */}
                  <ButtonTypo>
                    {t("meetingDetail.unsetMeetingDisable")}
                  </ButtonTypo>
                </PxButton>
              </DisableContainer>
            )}
          </MenuContainer>

          {/* <CompanyType>
          {application.type === "seller"
            ? subEvent?.sgroupName
            : subEvent?.bgroupName}
        </CompanyType> */}
          {/* <Filter /> */}
          <MeetingTips />
          <MyMeetingContext.Provider
            value={{
              // 미팅 마지막 시간
              myMeetingCheckList: myMeetingCheckList,
              myMeetingSearch: searchData,
              videoDialogOpen: videoDialogOpen,
              setVideoDialogOpen: setVideoDialogOpen,
              secondId: id!,
              disableStatus: disableStatus,
              disableLiftStatus: disableLiftStatus,
              selectedList: selectedList,
              // setImpossibleCheckList: setImpossibleCheckList,
            }}
          >
            <MeetingTimeForm
              meetingStatus={meetingStatusValue}
              date={date}
              meetingSchedule={setMeetingSchedule}
            />
          </MyMeetingContext.Provider>
        </Grid>
      </Grid>
    </PxBreakpoints>
  ) : null;
};

export default MeetingDetail;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
