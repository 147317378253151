import { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

const ScrollToTop = ({ location }: RouteComponentProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default withRouter(ScrollToTop);
