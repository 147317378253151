import React, { useContext } from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

// context
import { SubEventContext } from "../../components/Providers/SubEventProvider/SubEventProvider";

// material
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import PxHelmet from "../../components/Helmet/PxHelmet";

interface NavbarProps {
  navBarStyle: Record<"appBar" | "appBarShift" | "menuButton" | "hide", string>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  screenWidth: "xs" | "sm" | "md" | "lg" | "xl";
}

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: 18,
    width: 24,
    fontSize: 24,
    height: 24,
    color: "white",
  },
  title: {
    flexGrow: 1,
  },
  eventNameBox: {
    width: "100%",
    paddingTop: 15,
    paddingBottom: 15,
    color: "white",
    maxHeight: 90,
  },
  eventName: {
    width: "calc(100vw - 442px)",
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 362px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 90px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 74px)",
    },
    color: "white",
    wordBreak: "keep-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "normal",
    WebkitLineClamp: 2,
    maxHeight: "3.2em",
    whiteSpace: "normal",
  },
}));

const SubEventType = styled.span`
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1em;
  margin-left: 4px;
  text-transform: uppercase;
`;

const Navbar = ({ navBarStyle, open, setOpen, screenWidth }: NavbarProps) => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();
  const history = useHistory();
  const { selectedUrl } = useContext(SubEventContext);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  const includeName = (urlName: string) => {
    return selectedUrl.includes(urlName);
  };
  const setNavbarLeftLinkName = () => {
    switch (true) {
      case includeName("attendeeList"): {
        return t("subEventNavBar.attendeeList");
      }
      case includeName("companyList"): {
        return "기업목록";
      }
      case includeName("counselList"): {
        return t("subEventNavBar.counselList");
      }
      case includeName("speakerList"): {
        return t("subEventNavBar.speakerList");
      }
      case includeName("programList"): {
        return t("subEventNavBar.programList");
      }
      case includeName("digitalMapList"): {
        return t("subEventNavBar.digitalMapList");
      }
      case includeName("managerList"): {
        return t("subEventNavBar.managerList");
      }
      case includeName("noticeList"): {
        return t("subEventNavBar.noticeList");
      }
      case includeName("eventInfo"): {
        return t("subEventNavBar.eventInfo");
      }
    }
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(navBarStyle.appBar, {
        [navBarStyle.appBarShift]: open,
      })}
      style={{ backgroundColor: "rgba(95,75,139,1.0)" }}
    >
      <Toolbar className={classes.root}>
        {screenWidth === "xs" || screenWidth === "sm" ? (
          <MenuIcon
            style={{ color: "white" }}
            className={clsx(classes.menuButton, open && navBarStyle.hide)}
            onClick={() => setOpen(true)}
          />
        ) : (
          ""
        )}
        <Box className={classes.eventNameBox}>
          <SubHeadingTypo
            fontweight="600"
            style={{ color: "white" }}
            className={classes.eventName}
          >
            {subEvent?.mainEvent === undefined ? (
              ""
            ) : (
              <>
                <PxHelmet
                  title={subEvent?.mainEvent?.name}
                  img={
                    subEvent.mainEvent.image ||
                    subEvent.mainEvent.mainImageFile.filePath
                  }
                  description={subEvent.mainEvent.categories
                    ?.split("^&")
                    .map((category) => t(`categories.${category}`))
                    .join(" | ")}
                />
                {`${subEvent.mainEvent?.name} `}
                <SubEventType>
                  {subEvent.category === "presentation"
                    ? "Conference"
                    : subEvent.category}
                </SubEventType>
              </>
            )}
          </SubHeadingTypo>
        </Box>
        {/* <SubHeadingTypo>{setNavbarLeftLinkName()}</SubHeadingTypo> */}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
