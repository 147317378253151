import React, { useCallback, useContext } from "react";
import PxButton from "../../../components/Buttons/PxButton";
import Chat from "../../../views/chat/Chat";
import { ChatContext } from "../ChatProvider/ChatProvider";
import { makeStyles } from "@material-ui/core/styles";
import ButtonTypo from "../../../components/Typhography/ButtonTypo";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import ToggleChatBlackList from "../ToggleChatButton/ToggleChatBlackList";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import PxIconButton from "../../../components/Buttons/PxIconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  sendEmailAndPwdBtn: {
    width: "100%",
    height: 76,
    textAlign: "center",
    "& button": {
      margin: "20px 16px !important",
    },
  },
  sideOpen: {
    width: "100%",

    height: 76,
    textAlign: "right",
    "& button": {
      margin: "20px 16px !important",
    },
  },
}));

const ChatForm = () => {
  const classes = useStyles();
  const [t] = useTranslation("lang", { useSuspense: false });
  const {
    sendMessage,
    messages,
    chatBlock,
    setVideoChatSidebarOpen,
  } = useContext(ChatContext);
  const { user } = useAppState();
  const myBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );

  const handleSendMyInfoOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    sendMessage(
      `#myInfo/${myBusinessCard?.company}/${myBusinessCard?.name}/${
        myBusinessCard?.position ?? ""
      }/${myBusinessCard?.email}/${myBusinessCard?.phoneNumber}`
    );
  };

  const handleChatSideOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setVideoChatSidebarOpen((open) => !open);
    },
    []
  );

  return (
    <div>
      {/* 이메일/전화번호 보내기 버튼 */}
      {!messages.isLoading === false ? (
        <div className={classes.sendEmailAndPwdBtn}>
          <PxButton
            padding="9px 84px 9px"
            backgroundcolor="black"
            onClick={handleSendMyInfoOnClick}
            disabled={chatBlock}
          >
            <ButtonTypo txtcolor="white">
              {t("chatSidebar.sendMyInfo")}
            </ButtonTypo>
          </PxButton>
          {/* 영상회의 채팅금지 목록 */}
          {user?.role === "presenter" && <ToggleChatBlackList />}
        </div>
      ) : (
        <div className={classes.sideOpen}>
          <PxIconButton onClick={handleChatSideOpen}>
            <CloseIcon />
          </PxIconButton>
        </div>
      )}
      <Chat contentHeight="calc(100% - 76px)" />
    </div>
  );
};

export default ChatForm;
