import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import axios, { AxiosResponse } from "axios";
import { removeAlertAction } from "../../actions/alerts";
import { setAlert } from "../../utils/utils";

// types
import { EventInfo } from "../../types/models/Event";
import Alert from "../../types/models/Alert";

// material ui custom comp
import GridContainer from "../../components/Grid/PxGridContainer";
import GridItem from "../../components/Grid/PxGridItem";
import Body1Typo from "../../components/Typhography/Body1Typo";
import PxButton from "../../components/Buttons/PxButton";
import HeadlineTypo from "../../components/Typhography/HeadlineTypo";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import CardGridContainer from "../../components/Grid/CardGrid/CardGridContainer";
import { Layout } from "../../components/Layout/Layout";
import NoResultPage from "../../components/NoResult/NoResultPage";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 25px;
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.p`
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 0.625em;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
`;

const Empty = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;

const EventList = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const params: { word: string } = useParams();
  const [searchResult, setSearchResult] = useState<EventInfo[]>([]);
  const [page, setPage] = useState({ page: 0, last: false });
  const [eventArr, setEventArr] = useState<EventInfo[]>([]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {},
  };

  const Search_query = async (word: string, page: number = 0) => {
    const path = `/api/events/getSearch?word=${word}&page=${page}&size=12&approval=${"Y"}`;
    try {
      const res: AxiosResponse = await axios.get(path, config);
      if (res.data.first) {
        setSearchResult(res.data.content);
        setPage({ page: res.data.number, last: res.data.last });
      } else {
        setSearchResult([...searchResult, ...res.data.content]);
        setPage({ page: res.data.number, last: res.data.last });
      }
    } catch (err) {
      const alert: Alert = setAlert(
        err.response.status,
        err.response.data,
        path
      );
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  };

  useEffect(() => {
    Search_query(params.word.trim());
  }, [params.word]);

  const addEvent = async (data: EventInfo[]) => {
    const result: any = await Search_query(params.word.trim(), page.page + 1);

    if (result) {
      setEventArr([...result.content!]);
      setPage({
        page: (page.page + 1) as number,
        last: result.last as boolean,
      });
    }
  };

  const moreEventBtn = async (
    e: React.MouseEvent<HTMLButtonElement> | React.FC
  ) => {
    addEvent(eventArr);
  };

  return (
    <Layout>
      <Container>
        <Title>{t("search.searchResult")}</Title>

        <GridContainer
          justify="space-between"
          alignItems="baseline"
          // parent={true}
        >
          <GridItem>
            <GridContainer direction="row">
              <GridItem className="pb-1">
                <HeadlineTypo>{`${params.word}`}</HeadlineTypo>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <Contents>
            {(searchResult && searchResult.length <= 0) ||
            searchResult === undefined ? (
              <NoResultPage />
            ) : (
              <CardGridContainer eventList={searchResult}></CardGridContainer>
            )}
          </Contents>
        </GridContainer>

        <GridContainer justify="center" className="p-4">
          {!page.last && (
            <PxButton
              backgroundcolor="transparent"
              border={false}
              onClick={moreEventBtn}
            >
              <ButtonTypo txtcolor={"purple"}>{t("home.more")}</ButtonTypo>
            </PxButton>
          )}
        </GridContainer>
      </Container>
    </Layout>
  );
};

export default EventList;
