import User from "../types/models/User";
import UserActionTypes from "../types/actions/userAction";

const userDefaultState: User = {
  name: "",
  email: "",
  company: "",
  position: "",
  countryNumber: "",
  phoneNumber: "",
  businessCards: [],
  isAuthenticated: false,
  eventList: [],
  likeList:{
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
    
  },
  likeListId:[],
  approvedEventList: { 
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
    
  },
};

const userReducer = (
  state = userDefaultState,
  action: UserActionTypes
): User => {
  switch (action.type) {
    case "CREATE_USER":
      return action.user;
    case "GET_USER":
      return { ...state, ...action.user, isAuthenticated: true };
    case "GET_MY_EVENT_LIST":
      return { ...state, eventList: [...action.eventList] };
    case "GET_MY_APPROVED_EVENT_LIST":
      return { ...state, approvedEventList: action.approvedEventList};
    case "GET_MY_LIKE_LIST":
      return { ...state, likeList: action.likeList };
      case "GET_MY_LIKE_LIST_ID":
      return { ...state, likeListId: action.likeListId };
    // case "GET_MY_APPLICATIONS":
    //   return { ...state, applications: { applications: action.applications } };
    case "GET_AUTH_TOKEN":
      return { ...state, isAuthenticated: true };
    case "GET_MY_SUBEVENT_BUSINESSCARD":
      return { ...state, subEventBusinessCard: action.subEventBusinessCard };
    case "UPDATE_USER":
      return { ...state, ...action.user };
    case "UPDATE_USER_PWD":
      return { ...state, ...action.UpdatePwd };
    case "RESET_MY_SUBEVENT_BUSINESSCARD":
      return { ...state, subEventBusinessCard: undefined };
    case "REMOVE_USER":
      return userDefaultState;
    default:
      return state;
  }
};

export default userReducer;
