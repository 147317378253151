// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect, useState } from "react";
import {
  DeviceLabels,
  Heading,
} from "amazon-chime-sdk-component-library-react";
import MeetingJoinDetails from "../../containers/MeetingJoinDetails";
import { StyledLayout } from "./Styled";
import DeviceSelection from "../../components/DeviceSelection";
import DevicePermissionPrompt from "../../containers/DevicePermissionPrompt";
import { useAppState } from "../../providers/AppStateProvider";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import {
  createGetAttendeeCallback,
  createTestGetAttendeeCallback,
  fetchMeeting,
  fetchTestMeeting,
} from "../../utils/api";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { MeetingMode } from "../../types";
import { AppState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../actions/loading";
import { setAlertAction, removeAlertAction } from "../../../actions/alerts";
import { useHistory, useParams } from "react-router-dom";

import uuid from "uuid";

const DeviceSetup: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const meetingManager = useMeetingManager();
  const { subEventBusinessCard, company } = useSelector(
    (state: AppState) => state.users
  );
  const subEventInfo = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const meeting = useSelector((state: AppState) => state.meetings.meetingVideo);

  const {
    region,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    isWebAudioEnabled,
    videoTransformCpuUtilization: videoTransformCpuUtilization,
    setJoinInfo,
    isEchoReductionEnabled,
    isTest,
    setMeetingMode,
    setMeetingId,
    setLocalUserName,
    setRegion,
  } = useAppState();

  useEffect(() => {
    if (!isTest) {
      createMeetingSession();
    }
  }, []);

  const createMeetingSession = async () => {
    dispatch(setLoadingAction());

    const meetingRoom = `${meeting?.subEventId}|${meeting?.id}` as string;

    const enterType =
      subEventInfo.type == undefined ? "interpreter" : subEventInfo.type;

    if (meetingRoom == undefined || enterType == undefined) {
      dispatch(removeLoadingAction);
      dispatch(
        setAlertAction({
          id: "warning",
          msg: "비정상적인 접속입니다.",
          alertType: "warning",
        })
      );
      setTimeout(() => {
        dispatch(removeAlertAction);
      });
      history.goBack();
    }

    const attendeeName: string =
      `<${enterType}>${subEventBusinessCard?.name}|${subEventInfo.name}` as string;
    setLocalUserName(attendeeName);

    setMeetingId(meetingRoom);

    meetingManager.getAttendee = createGetAttendeeCallback(meetingRoom);

    try {
      const { JoinInfo } = await fetchMeeting(
        // subEventId as string,
        meetingRoom,
        attendeeName,
        region,
        isEchoReductionEnabled
      );

      dispatch(removeLoadingAction());
      setJoinInfo(JoinInfo);

      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        JoinInfo.Meeting,
        JoinInfo.Attendee
      );

      setRegion(JoinInfo.Meeting.MediaRegion);

      meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers =
        enableSimulcast;

      if (priorityBasedPolicy) {
        meetingSessionConfiguration.videoDownlinkBandwidthPolicy =
          priorityBasedPolicy;
      }

      meetingSessionConfiguration.keepLastFrameWhenPaused =
        keepLastFrameWhenPaused;

      await meetingManager.join({
        meetingInfo: JoinInfo.Meeting,
        attendeeInfo: JoinInfo.Attendee,
        deviceLabels: DeviceLabels.AudioAndVideo,
      });

      setMeetingMode(MeetingMode.Attendee);
    } catch (error) {
      dispatch(removeLoadingAction());
      console.log(`device error : ${error}`, error);
    }
  };

  return (
    <StyledLayout>
      <Heading tag="h1" level={3} css="align-self: flex-start">
        Device settings
      </Heading>
      <DeviceSelection />
      <MeetingJoinDetails />
      <DevicePermissionPrompt />
    </StyledLayout>
  );
};

export default DeviceSetup;
