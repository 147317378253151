import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import ScreenShare from "@material-ui/icons/ScreenShare";
import VideocamOff from "@material-ui/icons/VideocamOff";

import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import usePublications from "../../hooks/usePublications";
import useTrack from "../../hooks/useTrack";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
// import PinIcon from "../PinIcon/PinIcon";
import PinIcon from "../../../assets/icons/pinIcon.svg";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff";
import BandwidthWarning from "../BandwidthWarning/BandwidthWarning";
import useParticipantNetworkQualityLevel from "../../hooks/useParticipantNetworkQualityLevel";
import NetworkQualityLevel from "../NewtorkQualityLevel/NetworkQualityLevel";

// import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
// import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
// import useTrack from '../../hooks/useTrack/useTrack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      backgroundColor: "#4b4c4e",
      alignItems: "center",
      // minHeight: `${(theme.sidebarWidth * 9) / 16}px`,
      maxHeight: 138,
      height: "100%",
      marginBottom: "5px",
      overflow: "hidden",
      cursor: "pointer",
      "& video": {
        filter: "none",
      },
      "& svg": {
        stroke: "black",
        strokeWidth: "0.8px",
      },
      [theme.breakpoints.down("xs")]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: "3px",
        fontSize: "10px",
      },
    },
    isVideoSwitchedOff: {
      "& video": {
        filter: "blur(4px) grayscale(1) brightness(0.5)",
      },
    },
    isMultiScreen: {
      paddingTop: "calc(9 / 16 * 50%)",
      paddingBottom: "calc(9.1 / 16 * 50%)",
    },
    infoContainer: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      padding: "0.4em",
      width: "100%",
      background: "transparent",
    },
    hideVideo: {
      background: "#4b4c4e",
    },
    identity: {
      background: "rgba(0, 0, 0, 0.7)",
      padding: "0.1em 0.3em",
      margin: 0,
      display: "flex",
      alignItems: "center",
    },
    infoRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    participantsScreenInfoRow: {
      // background: "rgba(0, 0, 0, 0.7)",
    },
    screenShareIcon: {
      color: "floralwhite",
      stroke: "none",
      width: 20,
      height: 20,
      marginLeft: 5,
    },
    videoCamOffIcon: {
      color: "rgb(111 111 111)",
      stroke: "none",
      width: 20,
      height: 20,
    },
    pinIcon: {
      width: 20,
      height: 20,
      float: "right",
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  isSelected: boolean;
  isMultiScreen?: boolean;
}

export default function ParticipantInfoForMany({
  participant,
  isSelected,
  onClick,
  children,
  isMultiScreen,
}: ParticipantInfoProps) {
  const classes = useStyles();

  const [pariticipantName, setPariticipantName] = useState<string>("");

  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const videoPublication = publications.find((p) =>
    p.trackName.includes("camera")
  );

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  const isVideoEnabled = Boolean(videoPublication);

  const isScreenShareEnabled = publications.find((p) =>
    p.trackName.includes("screen")
  );

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack;

  useEffect(() => {
    if (participant) {
      const userName = participant.identity.split("|")[0];
      setPariticipantName(userName);
    }
  }, [participant, videoPublication]);

  return (
    <div
      onClick={onClick}
      className={clsx(classes.container, {
        [classes.isVideoSwitchedOff]: false,
        [classes.isMultiScreen]: !isMultiScreen,
      })}
      data-cy-participant={participant.identity}
    >
      <div
        className={clsx(classes.infoContainer, {
          [classes.hideVideo]: !isVideoEnabled,
        })}
      >
        <div className={classes.infoRow}>
          <CaptionTypo className={classes.identity} txtcolor="white">
            {pariticipantName}
          </CaptionTypo>
        </div>
        <div>
          <NetworkQualityLevel qualityLevel={networkQualityLevel} />
          {isScreenShareEnabled && (
            <ScreenShare className={classes.screenShareIcon} />
          )}
          <AudioLevelIndicator
            size={20}
            audioTrack={audioTrack}
            background="white"
          />
          {!isVideoEnabled && (
            <VideocamOff className={classes.videoCamOffIcon} />
          )}
          {isSelected && (
            <img src={PinIcon} alt="" className={classes.pinIcon} />
          )}
        </div>
      </div>
      {/* {isVideoSwitchedOff && <BandwidthWarning />} */}
      {children}
    </div>
  );
}
