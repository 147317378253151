import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getMail, updateMailOpened } from "../../../../actions/mail";
import { AppState } from "../../../../store";

//

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp

import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import MeetingMail from "./MailTemplate/MeetingMail";
import MeetingConcluded from "./MailTemplate/MeetingConcluded";
import EventApplyRejectedMail from "./MailTemplate/EventApplyRejectedMail";
import EventApplyApproved from "./MailTemplate/EventApplyApproved";
import EventApply from "./MailTemplate/EventApply";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DDayEventMail from "./MailTemplate/DDayEventMail";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const MailDetail = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { secondId: string } = useParams();
  const mail = useSelector((state: AppState) => state.mails.mail);
  const classes = useStyles();
  useEffect(() => {
    dispatch(getMail(param.secondId));
  }, []);

  useEffect(() => {
    if (!mail || !mail?.opened || mail.opened === "Y" || !mail.id) return;
    dispatch(updateMailOpened(parseInt(mail.id)));
  }, [mail]);

  const getContent = (content: string) => {
    const item = content.split("^&");
    const type = item[0];
    const language = type.split("_")[1] as "kr" | "en";
    switch (type) {
      case "meetingRequest_kr":
      case "meetingRequest_en":
        return (
          <MeetingMail
            language={language}
            title={item[2]}
            userName={item[3]}
            mailContent={item[4]}
            eventName={item[5]}
            subEventName={item[6]}
            eventStart={item[7]}
            eventEnd={item[8]}
            applicant={item[9]}
            acceptor={item[10]}
            meetingStart={item[11]}
            meetingEnd={item[12]}
            link={item[13]}
            reason={item[14]}
          />
        );
      case "meetingRejected_kr":
      case "meetingRejected_en":
      case "meetingCancelled_en":
      case "meetingCancelled_kr":
        return (
          <MeetingMail
            language={language}
            title={item[1]}
            userName={item[3]}
            mailContent={""}
            eventName={item[4]}
            subEventName={item[5]}
            eventStart={item[6]}
            eventEnd={item[7]}
            applicant={item[8]}
            acceptor={item[9]}
            meetingStart={item[10]}
            meetingEnd={item[11]}
            link={item[12]}
            reason={item[13]}
          />
        );
      case "newMeeting_kr":
      case "newMeeting_en":
        return (
          <MeetingConcluded
            language={language}
            title={item[2]}
            userName={item[3]}
            mailContent={item[4]}
            eventName={item[5]}
            subEventName={item[6]}
            eventStart={item[7]}
            eventEnd={item[8]}
            applicant={item[9]}
            acceptor={item[10]}
            meetingStart={item[11]}
            meetingEnd={item[12]}
            googleCalenderUrl={item[13]}
            link={item[14]}
          />
        );

      case "eventApply_kr":
      case "eventApply_en":
        return (
          <EventApply
            language={language}
            userName={item[1]}
            eventName={item[2]}
            eventStart={item[3]}
            eventEnd={item[4]}
            subEventName={item[5]}
            subEventStart={item[6]}
            subEventEnd={item[7]}
            date={item[8]}
            link={item[9]}
          />
        );
      case "eventApplyApproved_kr":
      case "eventApplyApproved_en":
        return (
          <EventApplyApproved
            language={language}
            userName={item[1]}
            eventName={item[2]}
            eventStart={item[3]}
            eventEnd={item[4]}
            date={item[5]}
            approvedDate={item[6]}
            link={item[7]}
          />
        );
      case "eventApplyRejected_kr":
      case "eventApplyRejected_en":
        return (
          <EventApplyRejectedMail
            language={language}
            userName={item[1]}
            eventName={item[2]}
            eventStart={item[3]}
            eventEnd={item[4]}
            date={item[5]}
            rejectDate={item[6]}
            reason={item[7]}
            link={item[8]}
          />
        );
      case "eventDday_kr":
      case "eventDday_en":
        return (
          <DDayEventMail
            language={language}
            userName={item[1]}
            eventName={item[2]}
            eventStart={item[3]}
            eventEnd={item[4]}
            link={item[5]}
          />
        );
      default:
        return (
          <div
            className={classes.mailContent}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        );
    }
  };
  return mail !== undefined && mail.id !== undefined ? (
    <Grid container style={{ marginBottom: "50px" }}>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px", width: "100%" }}>
        <SubTitle style={{ marginTop: "0px" }}>
          {t("mailList.recipient")}
        </SubTitle>
        <OutlinedTxtField value={mail.toMail} fullWidth disabled />

        <SubTitle>{t("mailList.sentDate")}</SubTitle>
        <OutlinedTxtField
          value={`${moment(mail.createdDate).format("YYYY-MM-DD")}`}
          fullWidth
          disabled
        />

        <SubTitle>{t("mailList.title")}</SubTitle>
        <OutlinedTxtField value={mail.title} fullWidth disabled />

        <SubTitle>{t("mailList.content")}</SubTitle>
        <Grid
          style={{
            border: "1px solid #ccc",
            padding: "10.5px 14px",
            overflow: "scroll",
          }}
        >
          {getContent(mail.content)}
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default MailDetail;

const useStyles = makeStyles((theme) => ({
  mailContent: {
    maxHeight: 800,
    minHeight: 500,
    "& img": {
      maxWidth: "100%",
    },
  },
}));
