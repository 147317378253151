import React from "react";
import BaseTemplate, { EventContent, LinkButton, MailContent } from "./BaseTemplate";

interface MeetingMailProp {
  language: "kr" | "en";
  userName: string;
  eventName: string;
  eventStart: string;
  eventEnd: string;
  date: string;
  subEventName: string;
  subEventStart: string;
  subEventEnd: string;
  link: string;
}

export default ({
  language,
  userName,
  eventName,
  eventStart,
  eventEnd,
  subEventName,
  subEventStart,
  subEventEnd,
  date,
  link,
}: MeetingMailProp) => {
  return (
    <BaseTemplate
      title={language === "en" ? "Participating application notice" : "참가신청 확정 안내"}
      language={language}
    >
      {language === "en" ? (
        <>
          <MailContent>
            Hello, {userName}
            <br />
            Thank you for participating in the event.
          </MailContent>
          <EventContent>
            Event Name:&nbsp; {eventName}
            <br />
            Period:
            <br />
            &nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            Sub-event name:&nbsp; {subEventName}
            <br />
            Sub-event period :&nbsp;{subEventStart}(GMT+9) ~&nbsp;{subEventEnd}(GMT+9)
            <br />
            Participating application:&nbsp;{date}(GMT+9)
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            Go to event{" "}
          </LinkButton>
        </>
      ) : (
        <>
          <MailContent>
            {userName}님 안녕하세요
            <br />
            회원님은 참가신청한 이벤트에 점속하여 전용메뉴를 사용할 수 있습니다.
          </MailContent>
          <EventContent>
            이벤트 명:&nbsp; {eventName}
            <br />
            이벤트 기간:
            <br />
            &nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            서브이벤트 명:&nbsp;{subEventName}
            <br />
            서브이벤트 일시 :&nbsp;{subEventStart}(GMT+9) ~&nbsp;{subEventEnd}(GMT+9)
            <br />
            참가신청 일시:&nbsp;{date}(GMT+9)
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            이벤트 바로가기
          </LinkButton>
        </>
      )}
    </BaseTemplate>
  );
};
