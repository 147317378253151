import React, { Fragment, useContext, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import PxMenu from "../../../components/Menu/PxMenu";
import PxGridContainer from "../../../components/Grid/PxGridContainer";

import { useAppState } from "../AppStateProvider/hooks/useAppState";
import Body1Typo from "../../../components/Typhography/Body1Typo";
import Fab from "@material-ui/core/Fab";

import useVideoContext from "../../hooks/useVideoContext";
import { LocalDataTrack } from "twilio-video";
import {
  addChatBlackList,
  removeBlock,
  removeMsg,
} from "../ChatProvider/ChatApi";
import PxButton from "../../../components/Buttons/PxButton";
import { ChatContext } from "../ChatProvider/ChatProvider";
import BlockIcon from "@material-ui/icons/Block";

export default function ToggleUserNameButton(props: {
  identity: string;
  index: number;
  sid?: string;
}) {
  const { identity, sid } = props;
  const classes = useStyles();
  const { t } = useTranslation("lang", { useSuspense: false });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useAppState();
  const { localTracks } = useVideoContext();
  const { setBlackList, blackList, chatChannel, setMessage } = useContext(
    ChatContext
  );
  const dataTrack = localTracks.find(
    (data) => data.kind === "data"
  ) as LocalDataTrack;

  const sendBlackList = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const result = await addChatBlackList(identity, user!.roomName);
    setBlackList(result);
    dataTrack.send(JSON.stringify({ type: "chatBlock", chatBlock: identity }));
    setAnchorEl(null);
  };

  const hideMsg = (sid: string) => {
    if (chatChannel) {
      removeMsg(sid, chatChannel.sid);
      setMessage((data) => ({
        ...data,
        messages: data.messages.map((msg) => {
          return msg.author.sid === sid
            ? { ...msg, text: "삭제된 메시지입니다." }
            : msg;
        }),
      }));
      dataTrack.send(JSON.stringify({ type: "removeMsg", removeMsg: sid }));
      setAnchorEl(null);
    }
  };

  return user?.role === "presenter" ? (
    <Fragment>
      <Fab
        className={classes.fab}
        style={{
          color: "rgb(169, 170, 173)",
        }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <div>
          <p className="sentText pl-10">
            {identity.split("|")[0]}
            {blackList?.some((data) => data === identity) && (
              <BlockIcon style={{ width: 15 }} />
            )}
          </p>
        </div>
      </Fab>
      <PxMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        anchorVertical="bottom"
        anchorhorizontal="center"
        transformVertical="top"
        transformHorizontal="center"
        marginBottom={-10}
        className={classes.emojiContainer}
      >
        <PxGridContainer direction="column" spacing={1}>
          {blackList && blackList.some((data) => data === identity) ? (
            <PxButton
              backgroundcolor="transparent"
              onClick={async () => {
                setBlackList(
                  await removeBlock(identity, user.roomName, dataTrack)
                );
                setAnchorEl(null);
              }}
            >
              <Body1Typo txtcolor="white">
                {t("chat.chatBlockRelesae")}
              </Body1Typo>
            </PxButton>
          ) : (
            <PxButton backgroundcolor="transparent" onClick={sendBlackList}>
              <Body1Typo txtcolor="white">{t("chat.chatBlock")}</Body1Typo>
            </PxButton>
          )}

          <PxButton
            backgroundcolor="transparent"
            onClick={() => {
              if (sid) hideMsg(sid);
            }}
          >
            <Body1Typo txtcolor="white">{t("chat.deleteMsg")}</Body1Typo>
          </PxButton>
        </PxGridContainer>
      </PxMenu>
    </Fragment>
  ) : (
    <p className="sentText pl-10">{identity.split("|")[0]}</p>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      textTransform: "none",
      width: "inherit",
      height: "inherit",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
    emojiContainer: {
      padding: 10,
      borderRadius: 5,
      backgroundColor: "#171c21",
      borderColor: "black",
    },
  })
);
