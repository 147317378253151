import React, { createContext, ReactNode, useEffect, useState } from "react";
import packageJson from "../package.json";
//@ts-ignore
global.appVersion = packageJson.version;

const checkVersionIsSameOrNot = () => {
  let shouldRefresh = false;
  if (localStorage.getItem("version") === null) {
    localStorage.setItem("version", packageJson.version);
    shouldRefresh = true;
  } else {
    if (localStorage.getItem("version")! === packageJson.version) {
      shouldRefresh = false;
    } else {
      shouldRefresh = true;
    }
  }
  return shouldRefresh;
};

export interface CacheBusterContextTypes {
  loading: boolean;
  isLatestVersion: boolean;
  refreshCacheAndReload: () => void;
}

const CacheBusterContext = createContext<CacheBusterContextTypes>(null!);

interface CacheBusterProps {
  children: ReactNode;
}

const CacheBuster = ({ children }: CacheBusterProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isLatestVersion, setIsLatestVersion] = useState<boolean>(false);

  const refreshCacheAndReload = () => {
    // console.log("Clearing cache and hard reloading");
    if (caches) {
      // 서비스워커 캐쉬 delete
      caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
    }
    localStorage.setItem("version", packageJson.version);
    window.location.reload(true);
  };

  useEffect(() => {
    console.log(checkVersionIsSameOrNot(), "version check");
    if (checkVersionIsSameOrNot()) {
      // console.log(
      //   `We have a new version - ${packageJson.version}. Should force refresh`
      // );
      setLoading(false);
      setIsLatestVersion(false);
      refreshCacheAndReload();
    } else {
      // console.log(
      //   `You already have the latest version - ${packageJson.version}. No cache refresh needed.`
      // );
      setLoading(false);
      setIsLatestVersion(true);
    }
    //   });
  }, []);

  return (
    <CacheBusterContext.Provider
      value={{
        loading: loading,
        isLatestVersion: isLatestVersion,
        refreshCacheAndReload: refreshCacheAndReload,
      }}
    >
      {children}
    </CacheBusterContext.Provider>
  );
};

export default CacheBuster;
