import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../../actions/loading";
import { changeMeetingState, getMyMeetings } from "../../../../actions/meeting";
import PxButton from "../../../../components/Buttons/PxButton";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import PxOutlinedTxtField from "../../../../components/Inputs/PxOutlinedTxtField";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import { ChangeMeetingStateInterface } from "../../../../types/models/Meeting";
import { MyMeetingContext } from "./MeetingDetail";

interface AcceptCancelMeetingDialog {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subEventId: string;
  acceptorId: string; // 기업 pk
  meetingId: string; // 미팅시간 pk
  meetingsId: string; // 미팅 pk
  applicationId: string; // 내 기업 pk
}

const AcceptCancelMeetingDialog = ({
  open,
  setOpen,
  subEventId,
  acceptorId,
  meetingId,
  meetingsId,
  applicationId,
}: AcceptCancelMeetingDialog) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();

  const rejectReasons: { [key: string]: string } = {
    A: t("meetingRejectReason.A"),
    B: t("meetingRejectReason.B"),
    C: t("meetingRejectReason.C"),
    D: t("meetingRejectReason.D"),
  };
  const [selectedReason, setSelectedReason] = useState<string>("A");
  const [inputReason, setInputReason] = useState<string>("");

  // 미팅 성사 -> 취소
  const onStateChangeDisagree = async () => {
    setOpen(false);
    dispatch(setLoadingAction());
    // 미팅 신청 데이터
    const changeMeetingStateData: ChangeMeetingStateInterface = {
      subEventId: subEventId,
      acceptorId: acceptorId, // 기업 pk;
      meetingId: meetingId, // 미팅시간 key;
      meetingsId: meetingsId, // 미팅 pk;
      status: "disagree", // 상태 [대기: waiting, 동의: agree, 비동의: disagree]
      mailPostToggleAt: "true",
      reason: selectedReason === "D" ? inputReason : selectedReason, // 거절 or 취소 사유
    };
    const result: any = await dispatch(
      changeMeetingState(changeMeetingStateData)
    );
    if (result === true) {
      dispatch(getMyMeetings(applicationId));
    }
    dispatch(removeLoadingAction());
  };

  const onChangeInputReasonText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputReason(e.target.value as string);
  };

  const onCloseAcceptCancelMeetingDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onCloseAcceptCancelMeetingDialog}
    >
      <DialogTitle id="confirmation-dialog-title">
        {t("meetingDetail.canceledReason")}
      </DialogTitle>
      <DialogContent>
        <PxOutlinedFormControl fullWidth>
          <PxSelectBox
            name="rejectReason"
            value={selectedReason}
            onChange={(e: any) => {
              setSelectedReason(e.target.value);
            }}
            displayEmpty
          >
            {Object.entries(rejectReasons).map(([key, value]) => (
              <MenuItem value={key}>{value}</MenuItem>
            ))}
          </PxSelectBox>
        </PxOutlinedFormControl>
        {selectedReason === "D" && (
          <>
            <Box height={10} />
            <PxOutlinedTxtField
              name="reason"
              value={inputReason}
              fullWidth
              placeholder="사유를 입력해주세요"
              inputpadding={15}
              onChange={onChangeInputReasonText}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <PxButton
          backgroundcolor="white87"
          onClick={onCloseAcceptCancelMeetingDialog}
        >
          <ButtonTypo>취소</ButtonTypo>
        </PxButton>
        <PxButton backgroundcolor="purple" onClick={onStateChangeDisagree}>
          <ButtonTypo>확인</ButtonTypo>
        </PxButton>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptCancelMeetingDialog;
