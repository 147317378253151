import React, { Fragment, useContext, useEffect } from "react";

// components
import FormItem from "./FormItem";

// material ui
import Divider from "@material-ui/core/Divider";

// context
import { FormContext } from "../../components/Providers/FormProvider/FormProvider";
import { useLocation } from "react-router-dom";

interface PartiFormProps {
  preview: boolean; // 미리보기 화면을 보여줄지 입력화면을 보여줄지 정해주는 boolean value
  subEventId?: string; // form이 속한 subEvent의 id 값
}

const PartiForm = ({ preview, subEventId }: PartiFormProps) => {
  const { formData, setFormData, checkOnlyEngReturnString } =
    useContext(FormContext);
  const location = useLocation();

  useEffect(() => {
    if (
      formData != null &&
      (subEventId == "5896" || subEventId == "248393") &&
      location.pathname.includes("buyerPartiForm")
    ) {
      formData.formIds.forEach((formId) => {
        if (formData.formData[formId].title == "카테고리") {
          setFormData((prevState) => {
            Object.assign(prevState.formData[formId], {
              radioContent: {
                id: prevState.formData[formId].selectContent!.formIds[0],
                content:
                  prevState.formData[formId].selectContent?.formData[
                    prevState.formData[formId].selectContent!.formIds[0]
                  ].explain,
              },
            });
            return prevState;
          });
        }
      });
    }
  }, [formData]);

  return (
    <Fragment>
      {formData?.formIds.length !== 0
        ? formData?.formIds.map((formId, index) => {
            return formData.formData[formId].title === "Organization name" ||
              ((subEventId == "5896" || subEventId == "248393") &&
                formData.formData[formId].title === "카테고리" &&
                location.pathname.includes("buyerPartiForm")) ||
              formData.formData[formId].title === "소속명" ? null : (
              <>
                <FormItem
                  key={index}
                  title={formData.formData[formId].title}
                  type={formData.formData[formId].type}
                  id={formData.formData[formId].id}
                  content={formData.formData[formId].content}
                  radioContent={formData.formData[formId].radioContent}
                  requireCheck={formData.formData[formId].requireCheck}
                  checkBoxContent={formData.formData[formId].checkBoxContent}
                  fileContent={formData.formData[formId].fileContent}
                  explainCheck={formData.formData[formId].explainCheck}
                  explain={formData.formData[formId].explain}
                  selectContent={formData.formData[formId].selectContent}
                  keywordContent={formData.formData[formId].keywordContent}
                  categoryContent={formData.formData[formId].categoryContent}
                  preview={preview}
                  subEventId={subEventId}
                  whtAdditionalPage={false}
                  checkOnlyEngReturnString={checkOnlyEngReturnString}
                  index={index}
                  keywordOptions={formData.formData[formId].keywordOptions}
                />
                <Divider light style={{ width: "100%" }} />
              </>
            );
          })
        : null}
    </Fragment>
  );
};

export default PartiForm;
