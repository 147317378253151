import React, { useContext } from "react";

import ScrollToBottom from "react-scroll-to-bottom";
import { ChatContext } from "../../../video/components/ChatProvider/ChatProvider";
import Message from "./Message";

import "./Messages.css";

const Messages = () => {
  const { messages } = useContext(ChatContext);

  return (
    <ScrollToBottom className="messages">
      {messages.messages.map((message, i) => (
        <div key={i}>
          <Message text={message.text} author={message.author} index={i} />
        </div>
      ))}
    </ScrollToBottom>
  );
};

export default Messages;
