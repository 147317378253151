import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { countryPhoneNumberCode } from "../../../utils/constants";
import { ManagerContext } from "./Manager";
import { useTranslation } from "react-i18next";

// actions
import { createUser, getAuthToken } from "../../../actions/users";
import { setAlert } from "../../../actions/alerts";
import { addManagerUser } from "../../../actions/applications";

// types
import { AppState } from "../../../store";
import User from "../../../types/models/User";

// custom ui comp
import OutlinedTxtField from "../../../components/Inputs/OutlinedTxtField";
import PxSelectBox from "../../../components/SelectBox/PxSelectBox";
import PxOutlinedFormControl from "../../../components/Forms/PxOutlinedFormControl";
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typhography/ButtonTypo";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "../../../components/Grid/PxGridContainer";
import GridItem from "../../../components/Grid/PxGridItem";

const ManagerRegister: React.FC = (props) => {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const { setActiveStep } = useContext(ManagerContext);

  const dispatch = useDispatch();
  const manager = useSelector((state: AppState) => state.managers.manager);
  const { id, email, userId, isAuthenticated } = useSelector(
    (state: AppState) => state.users
  );

  const [user, setUser] = useState<User>({
    email: "",
    name: "",
    password: "",
    role: "USER",
    company: "",
    position: "",
    countryNumber: "82",
    phoneNumber: "",
    businessCards: [],
  });
  const cellPhoneRegExp = new RegExp("^\\d+$"); // 핸드폰 번호 입력 정규식 (숫자만 입력가능)

  const handleOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    if ((event.target.name as string) === "phoneNumber") {
      const isValid = cellPhoneRegExp.test(event.target.value as string);
      if (isValid || (event.target.value as string) === "") {
        setUser({
          ...user,
          [event.target.name as string]: event.target.value as string,
        });
      }
    } else {
      setUser({
        ...user,
        [event.target.name as string]: event.target.value as string,
      });
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // 이메일 형식 체크 (@, . 포함 및 . 뒤에 2자 이상 글자)
    if (
      /^\w+([_0-9a-zA-Z\-][_0-9a-zA-Z\.-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z\--]+){1,2})+$/.test(
        user.email
      ) === false
    ) {
      dispatch(
        setAlert({
          id: "businessCard-blank",
          msg: t("businessCardValidation.emailValid"),
          alertType: "warning",
        })
      );
    } else {
      dispatch(createUser(user));
    }
  };

  const addManagerCall = async () => {
    const result: any = await dispatch(
      addManagerUser({ authCode: manager.code!, userExist: "N" })
    );
    if (result) {
      history.push(`/subEvent/${manager.subEventId}/attendeeList`);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (manager.email !== "") {
      setUser({
        email: manager.email,
        name: manager.name,
        role: "USER",
        password: "",
        position: manager.position,
        company: manager.company,
        countryNumber: manager.countryNumber,
        phoneNumber: manager.phoneNumber,
        businessCards: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getAuthToken({ email: email, password: user.password! }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (
      userId !== undefined &&
      manager.code !== undefined &&
      isAuthenticated === true
    ) {
      addManagerCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, manager.code, isAuthenticated]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <OutlinedTxtField
          label={t("register.email")}
          name="email"
          fullWidth
          value={user.email}
          padding="0px 0px 20px 0px"
          // onChange={handleOnChange}
        />
        <OutlinedTxtField
          name="password"
          label={t("register.password")}
          fullWidth
          padding="0px 0px 20px 0px"
          onChange={handleOnChange}
        />
        <OutlinedTxtField
          label={t("register.name")}
          name="name"
          fullWidth
          value={user.name}
          padding="0px 0px 20px 0px"
          onChange={handleOnChange}
        />
        <OutlinedTxtField
          label={t("register.company")}
          name="company"
          fullWidth
          value={user.company}
          disabled
          padding="0px 0px 20px 0px"
          onChange={handleOnChange}
        />
        {/* <OutlinedTxtField
            label="직업"
            fullWidth
            padding="0px 0px 20px 0px"
            onChange={handleOnChange}
          /> */}
        <PxOutlinedFormControl fullWidth className="mb-4">
          <GridContainer direction="row">
            <GridItem xs={3} md={3}>
              <PxSelectBox
                value={user.countryNumber}
                onChange={handleOnChange}
                displayEmpty
                name="countryNumber"
                style={{ width: 117 }}
              >
                <MenuItem value="" disabled>
                  {i18n.language === "ko" ? "국가코드" : "Country Code"}
                </MenuItem>
                {countryPhoneNumberCode.map((countryCode) => (
                  <MenuItem value={countryCode.code}>
                    {i18n.language === "ko"
                      ? countryCode.country
                      : countryCode.countryEn}
                  </MenuItem>
                ))}
              </PxSelectBox>
            </GridItem>
            <GridItem xs={2} md={1}></GridItem>
            <GridItem xs={7} md={8}>
              <OutlinedTxtField
                label={t("register.phoneNumber")}
                fullWidth
                value={user.phoneNumber}
                name="phoneNumber"
                onChange={handleOnChange}
              />
            </GridItem>
          </GridContainer>
        </PxOutlinedFormControl>
        <PxButton
          className="my-4 p-2"
          fullWidth
          backgroundcolor="purple"
          type="submit"
        >
          <ButtonTypo className="font-weight-bold">
            <ButtonTypo>{t("register.attendEvent")}</ButtonTypo>
          </ButtonTypo>
        </PxButton>
      </form>
    </>
  );
};

export default ManagerRegister;
