import React from "react";
import { LocalVideoTrack } from "twilio-video";
import VideoTrack from "../VideoTrack/VideoTrack";
import useVideoContext from "../../hooks/useVideoContext";

// icons
import VideocamOff from "@material-ui/icons/VideocamOff";

// utils
import { isMobile } from "../../utils";

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const style = {
    backgroundColor: "grey",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  // useLocalTrack에서 "camera-${Date.now()}"이름으로 track 생성
  const videoTrack = localTracks.find((track) =>
    track.name.includes("camera")
  ) as LocalVideoTrack;

  return (
    <div
      style={
        isMobile
          ? {
              ...style,
              width: "300px",
              height: "200px",
            }
          : { ...style, width: "400px", height: "250px" }
      }
    >
      {videoTrack ? (
        <VideoTrack track={videoTrack} isLocal priority="low" />
      ) : (
        <VideocamOff />
      )}
    </div>
  );
}
