import React, { useContext, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSignedRequest } from "../../actions/files";

import industryImg from "../../assets/images/next-rise-industry.jpg";

// types
import { AutonoForm, ProductProperty } from "../../types/models/Form";

// material comp
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxGridItem from "../../components/Grid/PxGridItem";
import PxOutlinedTxtField from "../../components/Inputs/PxOutlinedTxtField";
import FormGroup from "@material-ui/core/FormGroup";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import CloseIcon from "@material-ui/icons/Close";

// context
import { FormContext } from "../../components/Providers/FormProvider/FormProvider";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PxButton from "../../components/Buttons/PxButton";

import TranslatedFormItem from "./TranslatedFormItem";
import { getSubEventApplicationKeywords } from "../../actions/applications";
import { AppState } from "../../store";
import { stringify } from "querystring";
import styled from "styled-components";
import PdfView from "../../utils/pdfView";
import { useLocation } from "react-router-dom";
import Alert from "../../types/models/Alert";
import { setAlert } from "../../actions/alerts";

interface FormItemProps {
  preview: boolean;
  subEventId?: string;
  whtAdditionalPage: boolean;
  checkOnlyEngReturnString: (value: string) => string;
  index?: number;
}

type Props = AutonoForm & FormItemProps;

const FormItem: React.FC<Props> = (props) => {
  const {
    id,
    type,
    title,
    explain,
    content,
    explainCheck,
    fileContent,
    radioContent,
    checkBoxContent,
    keywordContent,
    categoryContent,
    // secretCheck,
    requireCheck,
    selectContent,
    preview,
    subEventId,
    whtAdditionalPage,
    index,
    keywordOptions,
    checkOnlyEngReturnString,
  } = props;
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  // const files = useSelector<AppState>((state) => state.files) as File;
  const {
    formData,
    setFormData,
    setAdFormData,
    businessCardId,
    businessCardList,
  } = useContext(FormContext);
  const [radioValue, setRadioValue] = useState("");
  const [textContent, setTextContent] = useState(content);
  const [keywordData, setKeywordData] = useState<string[]>([]);
  const [fileNameValue, setFileNameValue] = useState<string | undefined>();
  const [filePreviewModal, setFilePreviewModal] = useState<boolean>(false);
  const [filePreviewData, setFilePreviewData] = useState<string>();
  const [industryModal, setIndustryModal] = useState<boolean>(false);
  const [checkBoxValue, setCheckBoxValue] = useState<{
    [key: string]: boolean;
  }>({});
  // 키워드필드의 input 값
  const [keywordInputValue, setKeywordInputValue] = useState<string>("");
  // 상품 value
  const [productInfo, setProductInfo] = useState<ProductProperty>({
    title: "",
    explain: "",
    productImg: { fileId: 0, name: "", url: "" },
    link: "",
  });
  const location = useLocation();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  // 자율양식의 기업명은 명함정보의 기업명과 동일하게 작성함
  useEffect(() => {
    if (index === 0 && subEvent?.category !== "presentation") {
      setTextContent(businessCardList[businessCardId].company);
      setFormData((prevState) => {
        Object.assign(prevState.formData[id], {
          content: businessCardList[businessCardId].company,
        });
        return prevState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, businessCardList, businessCardId, subEvent]);

  // 다음 페이지에서 이전 페이지로 돌아왔을때 추가한 파일이름 값을 유지시켜주기 위함
  useEffect(() => {
    if (type === "file") {
      if (fileContent !== undefined) {
        setFileNameValue(fileContent.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileContent]);

  useEffect(() => {
    if (type === "product") {
      if (productInfo.productImg.name !== "") {
        setFileNameValue(productInfo.productImg.name);
      }

      !whtAdditionalPage
        ? setFormData((prevState) => {
            Object.assign(prevState.formData[id], { content: productInfo });
            return prevState;
          })
        : setAdFormData((preveState) => {
            Object.assign(preveState.formData[id], { content: productInfo });
            return preveState;
          });
    }
  }, [productInfo, whtAdditionalPage]);

  // 다음 페이지에서 이전 페이지로 돌아왔을때 라디오 버튼의 값을 유지시켜주기 위함
  useEffect(() => {
    if (type === "single") {
      if (radioContent !== undefined) {
        setRadioValue(radioContent.id + "[S]" + radioContent.content);
      }
    }
    if (type === "category") {
      if (categoryContent !== undefined) {
        setRadioValue(categoryContent.id + "[S]" + categoryContent.content);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioContent, categoryContent]);

  // 다음 페이지에서 이전 페이지로 돌아왔을때 체크박스의 값을 유지시켜주기 위함
  useEffect(() => {
    if (type === "many") {
      let defaultSet = {};
      let checkBoxContentText = "";
      if (checkBoxContent !== undefined) {
        checkBoxContent.forEach((value: any) => {
          checkBoxContentText += value.id + "[S]" + value.content + "&&";
        });
      }

      selectContent!.formIds.forEach((formId) => {
        const key = `${formId}[S]${selectContent!.formData[formId].explain!}`;
        Object.assign(defaultSet, {
          [key]: checkBoxContentText.indexOf(key) > -1 ? true : false,
        });
      });
      setCheckBoxValue(defaultSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 서브이벤트 참가신청 시 다른사람이 먼저 등록한 키워드 값 or 호스트가 설정한 값을 option으로 보여주기 위함
  useEffect(() => {
    if (type === "keyword" && subEvent?.id && keywordOptions) {
      const getSubEventKeywords = async () => {
        if (subEvent?.id) {
          if (keywordOptions) {
            setKeywordData(keywordOptions);
          } else {
            const keywords: string[] = await getSubEventApplicationKeywords(
              subEvent.id
            );
            setKeywordData(keywords);
          }
        }
      };

      getSubEventKeywords();
    } else if (type === "product" && content != (undefined || "")) {
      setProductInfo(content as ProductProperty);
    }
  }, [subEvent, type, keywordOptions]);

  const handleInputOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    event.persist();
    // 영어입력만
    const value = checkOnlyEngReturnString(event.target.value as string);
    setTextContent(value);
    !whtAdditionalPage
      ? setFormData((prevState) => {
          Object.assign(prevState.formData[id], {
            content: value,
          });
          return prevState;
        })
      : setAdFormData((prevState) => {
          Object.assign(prevState.formData[id], {
            content: value,
          });
          return prevState;
        });
  };

  const productInfoOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>,
    category: "title" | "explain" | "productImg" | "link"
  ) => {
    const value = checkOnlyEngReturnString(event.target.value as string);

    switch (category) {
      case "title":
        setProductInfo({ ...productInfo, title: value });
        break;
      case "explain":
        setProductInfo({ ...productInfo, explain: value });
        break;
      case "link":
        setProductInfo({ ...productInfo, link: value });
        break;
    }
  };

  useCallback(() => {
    !whtAdditionalPage
      ? setFormData((prevState) => {
          Object.assign(prevState.formData[id], { content: productInfo });
          return prevState;
        })
      : setAdFormData((preveState) => {
          Object.assign(preveState.formData[id], { content: productInfo });
          return preveState;
        });
  }, [productInfo, whtAdditionalPage]);

  const handleRadioOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setRadioValue((event.target as HTMLInputElement).value);
    const splitRadioValue = event.target.value.split("[S]");

    console.log(`type : `, type);

    if (type === "single") {
      !whtAdditionalPage
        ? setFormData((prevState) => {
            Object.assign(prevState.formData[id], {
              radioContent: {
                id: splitRadioValue[0],
                content: splitRadioValue[1],
              },
            });
            return prevState;
          })
        : setAdFormData((prevState) => {
            Object.assign(prevState.formData[id], {
              radioContent: {
                id: splitRadioValue[0],
                content: splitRadioValue[1],
              },
            });
            return prevState;
          });
    } else {
      setFormData((prevState) => {
        Object.assign(prevState.formData[id], {
          categoryContent: {
            id: splitRadioValue[0],
            content: splitRadioValue[1],
          },
        });
        return prevState;
      });
    }
  };

  const handleCheckBoxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    setCheckBoxValue((prevCheckBoxValue) => {
      Object.assign(prevCheckBoxValue, {
        [`${event.target.name}`]: !checkBoxValue[`${event.target.name}`],
      });
      return { ...prevCheckBoxValue };
    });

    // formData checkBoxContent value 변경 로직
    let checkBoxContent: AutonoForm["checkBoxContent"] = [];

    const checked = checkBoxValue;
    Object.entries(checked).forEach((value) => {
      if (value[1] === true) {
        const splitCheckBoxValue = value[0].split("[S]");
        checkBoxContent?.push({
          id: splitCheckBoxValue[0],
          content: splitCheckBoxValue[1],
        });
      }
    });
    !whtAdditionalPage
      ? setFormData((prevState) => {
          Object.assign(prevState.formData[id], {
            checkBoxContent: checkBoxContent,
          });
          return prevState;
        })
      : setAdFormData((prevState) => {
          Object.assign(prevState.formData[id], {
            checkBoxContent: checkBoxContent,
          });
          return prevState;
        });
  };

  // 첨부파일 등록
  const handleFileAdd = async (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = `event/${subEventId}/`;
    const fileSize = file.size;

    const result: any = await dispatch(
      getSignedRequest(file, fileName, fileType, folderPath, fileSize)
    );
    if (result !== false && result !== undefined) {
      setFileNameValue(result.fileName);

      if (type !== "product") {
        !whtAdditionalPage
          ? setFormData((prevState) => {
              Object.assign(prevState.formData[id], {
                fileContent: {
                  fileId: result.id,
                  name: result.fileName,
                  url: result.filePath,
                },
              });
              return prevState;
            })
          : setAdFormData((prevState) => {
              Object.assign(prevState.formData[id], {
                fileContent: {
                  fileId: result.id,
                  name: result.fileName,
                  url: result.filePath,
                },
              });
              return prevState;
            });
      } else {
        setProductInfo({
          ...productInfo,
          productImg: {
            fileId: result.id,
            name: result.fileName,
            url: result.filePath,
          },
        });
        !whtAdditionalPage
          ? setFormData((prevState) => {
              Object.assign(prevState.formData[id], { content: productInfo });
              return prevState;
            })
          : setAdFormData((prevState) => {
              Object.assign(prevState.formData[id], { content: productInfo });
              return prevState;
            });
      }
    }
  };

  const handleFileDelete = () => {
    setFileNameValue(undefined);
    if (type !== "product") {
      !whtAdditionalPage
        ? setFormData((prevState) => {
            Object.assign(prevState.formData[id], {
              fileContent: {
                fileId: undefined,
                name: undefined,
                url: undefined,
              },
            });
            return prevState;
          })
        : setAdFormData((prevState) => {
            Object.assign(prevState.formData[id], {
              fileContent: {
                fileId: undefined,
                name: undefined,
                url: undefined,
              },
            });
            return prevState;
          });
    } else {
      setProductInfo({
        ...productInfo,
        productImg: { fileId: 0, name: "", url: "" },
      });
      !whtAdditionalPage
        ? setFormData((prevState) => {
            Object.assign(prevState.formData[id], { content: productInfo });
            return prevState;
          })
        : setAdFormData((prevState) => {
            Object.assign(prevState.formData[id], { content: productInfo });
            return prevState;
          });
    }
  };

  // 미리보기 버튼 클릭 이벤트
  const handleFilePreview = () => {
    setFilePreviewModal(true);
  };

  return (
    <PxGridContainer className="py-3" direction="column">
      <PxGridItem>
        <TranslatedFormItem
          type="input"
          content={`${title}  ${requireCheck === "Y" ? " (required) " : ""} `}
          // ${freeFormQuestionText(title!, type!)}
          name={id}
          fullWidth
          fontsize={15}
          readOnly={true}
          fontweight="bold"
          link={true}
          // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
          onlyEng={false}
        />
      </PxGridItem>
      {explainCheck === "Y" && (
        <PxGridItem>
          <TranslatedFormItem
            type="string"
            content={explain!}
            name={id}
            fullWidth
            fontsize={14}
            fontweight="bold"
            readOnly
            // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
            onlyEng={false}
            link={true}
          />
        </PxGridItem>
      )}
      {(type === "long" || type === "short" || type === "url") && (
        <PxGridItem>
          <PxOutlinedTxtField
            name={id}
            value={textContent!}
            disabled={preview}
            inputpadding={type === "long" ? 0 : 10}
            multiline={type === "long" ? true : false}
            rows={2}
            rowsMax={type === "long" ? 30 : 2}
            fullWidth
            placeholder={t("form.pleaseEnter")}
            fontsize={14}
            onChange={handleInputOnChange}
          />
        </PxGridItem>
      )}
      {type === "keyword" && (
        <PxGridItem>
          <Autocomplete
            multiple
            options={keywordData.map((keyword) => keyword)}
            disabled={preview}
            id="keyword-list"
            freeSolo={
              keywordOptions && keywordOptions.length > 0 ? false : true
            }
            disableCloseOnSelect={true}
            onChange={(event, value) => {
              setFormData((prevState) => {
                Object.assign(prevState.formData[id], {
                  keywordContent: value.toString(),
                });
                return prevState;
              });

              setKeywordInputValue("");
            }}
            onInputChange={(event, value) => {
              if (value.length <= 20)
                if (/\s/.test(value) === false) {
                  setKeywordInputValue(
                    checkOnlyEngReturnString(value).toLocaleLowerCase()
                  );
                }
            }}
            inputValue={keywordInputValue}
            defaultValue={
              keywordContent !== undefined ? [keywordContent] : undefined
            }
            renderTags={(value: string[], getTagProps) => {
              return value.map((option: string, index: number) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderInput={(params) => (
              <PxOutlinedTxtField
                {...params}
                inputProps={{ ...params.inputProps, style: { padding: 0 } }}
                fullWidth
                disabled={preview}
                placeholder={
                  keywordOptions && keywordOptions.length > 0
                    ? t("form.pleaseSelectKeywords")
                    : t("form.pleaseKeywords")
                }
                fontsize={14}
              />
            )}
          />
        </PxGridItem>
      )}
      {(type === "single" || type === "category") && (
        <PxGridItem>
          <FormControl>
            {/* radio button value는  */}
            <RadioGroup value={radioValue} onChange={handleRadioOnChange}>
              {selectContent!.formIds.map((formId, index) => (
                <TranslatedFormItem
                  type="radio"
                  content={selectContent!.formData[formId].explain!}
                  key={index}
                  disabled={preview}
                  // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
                  onlyEng={false}
                  value={`${formId}[S]${selectContent!.formData[formId]
                    .explain!}`}
                  link={true}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </PxGridItem>
      )}
      {type === "many" && Object.keys(checkBoxValue).length !== 0 ? (
        <PxGridItem>
          <FormControl>
            <FormGroup>
              {selectContent!.formIds.map((formId, index) => {
                return (
                  <>
                    <TranslatedFormItem
                      type="checkbox"
                      content={selectContent!.formData[formId].explain!}
                      key={index}
                      disabled={preview}
                      // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
                      onlyEng={false}
                      checked={
                        Object.keys(checkBoxValue).length === 0
                          ? false
                          : checkBoxValue[
                              `${formId}[S]${selectContent!.formData[formId]
                                .explain!}`
                            ]
                      }
                      name={`${formId}[S]${selectContent!.formData[formId]
                        .explain!}`}
                      onChange={handleCheckBoxOnChange}
                      link={true}
                    />
                  </>
                );
              })}
            </FormGroup>
          </FormControl>
        </PxGridItem>
      ) : null}
      {type === "file" && (
        <PxGridItem>
          {fileNameValue !== undefined ? (
            <>
              <ButtonTypo gutterBottom>{fileNameValue}</ButtonTypo>
              {/* {preview && fileContent !== undefined && (
                <img src={fileContent.url} />
              )} */}
              {/* {preview === true &&
                subEvent &&
                isNextRiseEvent(`${subEvent.id}`) &&
                fileContent !== undefined && (
                  <PxButton
                    backgroundcolor="purple"
                    onClick={() => setFilePreviewModal(true)}
                  >
                    <ButtonTypo>미리보기</ButtonTypo>
                  </PxButton>
                )} */}
              {/* {filePreviewModal && fileContent && (
                <ImgFileModalContainer>
                  <ModalCloseContainer>
                    <button
                      onClick={() => setFilePreviewModal(false)}
                      style={{ backgroundColor: "white", border: "none" }}
                    >
                      <CloseIcon />
                    </button>
                  </ModalCloseContainer>
                  {fileContent.url.slice(-3) === "pdf" ? (
                    <PdfView fileUrl={fileContent.url} />
                  ) : (
                    <img src={fileContent.url} />
                  )}

                  {/* <img
                    src={fileContent?.url}
                    style={{
                      width: "100%",
                      height: "calc(100% - 27px)",
                      objectFit: "contain",
                    }}
                  /> 
                </ImgFileModalContainer>
              )} */}
              {preview === false && (
                <PxButton backgroundcolor="grey" onClick={handleFileDelete}>
                  <ButtonTypo>{t("form.delete")}</ButtonTypo>
                </PxButton>
              )}
            </>
          ) : (
            <Button
              variant="contained"
              component="label"
              style={{
                boxShadow: "none",
                backgroundColor: "#eee",
                padding: "9px 16px",
              }}
            >
              {t("form.add")}
              <input
                type="file"
                style={{ display: "none" }}
                name="file1"
                onChange={handleFileAdd}
              />
            </Button>
          )}
        </PxGridItem>
      )}
      {type === "product" && productInfo && (
        <PxGridItem
          style={{
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "7px",
            padding: "10px",
          }}
        >
          <TranslatedFormItem
            type="input"
            name="productName"
            content="제품명"
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
            onlyEng={false}
          />
          <TranslatedFormItem
            type="string"
            name="productName"
            content="제품명을 입력해주세요"
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
            onlyEng={false}
          />
          <PxOutlinedTxtField
            value={productInfo.title == null ? "" : productInfo.title}
            inputpadding={10}
            multiline={false}
            disabled={preview}
            rows={2}
            fullWidth
            placeholder=""
            fontsize={14}
            onChange={(e) => productInfoOnChange(e, "title")}
            margin={"dense"}
          />
          <TranslatedFormItem
            type="input"
            name="productName"
            content="제품 설명"
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
            onlyEng={false}
          />
          <TranslatedFormItem
            type="string"
            name="productName"
            content="제품 설명을 입력해주세요."
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
            onlyEng={false}
          />
          <PxOutlinedTxtField
            value={productInfo.explain}
            inputpadding={0}
            multiline={true}
            disabled={preview}
            rows={2}
            fullWidth
            placeholder=""
            fontsize={14}
            onChange={(e) => productInfoOnChange(e, "explain")}
            margin={"dense"}
          />
          <TranslatedFormItem
            type="input"
            name="productName"
            content="제품 이미지"
            fullWidth
            link={false}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
            onlyEng={false}
          />
          <TranslatedFormItem
            type="string"
            name="productName"
            content="제품 이미지(사이즈 600*600, 용량 10mb 이하)를 등록해주세요."
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
            onlyEng={false}
          />
          {fileNameValue !== undefined ? (
            <>
              <ButtonTypo gutterBottom>{fileNameValue}</ButtonTypo>
              {preview === false && (
                <PxButton backgroundcolor="grey" onClick={handleFileDelete}>
                  <ButtonTypo>{t("form.delete")}</ButtonTypo>
                </PxButton>
              )}
              {/* {preview && fileContent && (
                <ImgFileModalContainer>
                  <ModalCloseContainer>
                    <button onClick={()=> }>
                      <CloseIcon />
                      </button>
                  </ModalCloseContainer>
                  <img src={fileContent.url} />
                </ImgFileModalContainer>
              )} */}
            </>
          ) : (
            <Button
              variant="contained"
              component="label"
              style={{
                boxShadow: "none",
                backgroundColor: "#eee",
                padding: "9px 16px",
                margin: "5px 0",
              }}
            >
              {t("form.add")}
              <input
                type="file"
                style={{ display: "none" }}
                name="file1"
                onChange={handleFileAdd}
              />
            </Button>
          )}
          <TranslatedFormItem
            type="input"
            name="productName"
            content="제품 링크"
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
            onlyEng={false}
          />
          <TranslatedFormItem
            type="string"
            name="productName"
            content="제품 링크를 입력해주세요."
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            // onlyEng={subEvent?.counselFormOnlyEng == "Y"}
            onlyEng={false}
          />
          <PxOutlinedTxtField
            value={productInfo.link}
            inputpadding={10}
            multiline={false}
            disabled={preview}
            rows={2}
            fullWidth
            placeholder=""
            fontsize={14}
            onChange={(e) => productInfoOnChange(e, "link")}
            margin={"dense"}
          />
        </PxGridItem>
      )}
    </PxGridContainer>
  );
};

export default FormItem;
