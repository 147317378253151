import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Grid, { GridProps } from "@material-ui/core/Grid";

interface PxGridItemProps extends GridProps {
  // styledProps: StyledProps;
  fullwidth?: boolean;
  width?: string;
  marginBottom?: number;
  marginTop?: number;
  paddingLeft?: number;
  position?: string;
}

const PxGridItem: React.FC<PxGridItemProps> = styled(
  (props: PxGridItemProps) => {
    return <Grid item {...props} />;
  }
)`
  ${(props) => {
    if (props.paddingLeft !== undefined) {
      return css`
        padding-left: ${props.paddingLeft}px;
      `;
    }
    if (props.marginTop !== undefined) {
      return css`
        margin-top: ${props.marginTop}px;
      `;
    }
    if (props.marginBottom !== undefined) {
      return css`
        margin-bottom: ${props.marginBottom}px;
      `;
    }
    if (props.fullwidth !== undefined) {
      return css`
        width: 100%;
      `;
    }
    if (props.width !== undefined) {
      return css`
        width: ${props.width};
      `;
    }
    if (props.position !== undefined) {
      return css`
        position: ${props.position};
      `;
    }
  }}
`;

export default PxGridItem;

// material custom component 만들기 방법2
// const CustomGrid = styled(Grid)``;

// const PxGridItem: React.FC<PxGridItemProps & GridProps> = (props) => {
//   return <CustomGrid item {...props} />;
// };
