import React from "react";
import { Route, useHistory } from "react-router-dom";
import { ConnectOptions } from "twilio-video";
import { isMobile } from "./utils";

// theme
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

// components
import VideoProvider from "./components/VideoProvider/VideoProvider";
import LocalVideoPreview from "./components/LocalVideoPreview/LocalVideoPreview";
import CustomDialog from "./components/CustomDialog/CustomDialog";
import PxGridContainer from "../components/Grid/PxGridContainer";
import PxButton from "../components/Buttons/PxButton";
import ButtonTypo from "../components/Typhography/ButtonTypo";
import AppStateProvider from "./components/AppStateProvider/AppstateProvider";
import { useAppState } from "./components/AppStateProvider/hooks/useAppState";
import LocalAudioLevelIndicator from "./components/LocalAudioLevelIndicator/LocalAudioLevelIndicator";
import App from "./App";
import ToggleVideoButton from "./components/ToggleVideoButton/ToggleVideoButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../store";
import ToggleSpeakerTestButton from "./components/ToggleSpeakerTestButton/ToggleSpeakerTestButton";
import { SettingBeforMeeting } from "./components/SettingBeforeMeeting/SettingBeforeMeeting";

let connectionOptions: ConnectOptions;

if (!isMobile) {
  connectionOptions = {
    audio: true,
    video: { height: 720, frameRate: 24, width: 1280 },
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    bandwidthProfile: {
      video: {
        mode: "collaboration", // collaboration : 회의, // presentation: 설명회
        trackSwitchOffMode: "predicted", // 네트워크 상태에 따라 비디오를 자동으로 끔
        dominantSpeakerPriority: "standard",
        maxTracks: 10,
        renderDimensions: {
          high: { height: 1080, width: 1920 },
          standard: { height: 720, width: 1280 },
          low: { height: 176, width: 144 }, //{ height: 90, width: 160 }, // collaboration
        },
      },
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 1 },
    // Comment this line if you are playing music.
    maxAudioBitrate: 16000,
    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. This has no effect if you are
    // using Peer-to-Peer Rooms.
    preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
  };
} else {
  connectionOptions = {
    audio: true,
    video: { height: 480, frameRate: 24, width: 640 },
    bandwidthProfile: {
      video: {
        mode: "collaboration",
        trackSwitchOffMode: "predicted",
        dominantSpeakerPriority: "standard",
        maxTracks: 5,
      },
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 1 },
    maxAudioBitrate: 16000,
    preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
  };
}

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile && connectionOptions?.bandwidthProfile?.video) {
  connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
}

interface VideoProps {
  videoDialogOpen: boolean;
  setVideoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Video = ({ videoDialogOpen, setVideoDialogOpen }: VideoProps) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const { error, setError } = useAppState();
  const { meetingVideo } = useSelector((state: AppState) => state.meetings);
  const webinar = useSelector((state: AppState) => state.webinars.webinar);
  const handleOnClickVideoEnter = () => {
    setVideoDialogOpen(false);

    // 어떤 비디오룸인지 구분 (웨비나 or 미팅)
    if (history.location.pathname.includes("webinar")) {
      history.push(`/video/${webinar!.id}`);
    } else {
      history.push(`/video/${meetingVideo!.id}`);
    }
  };

  return (
    // VideoProvider에서 가진 value => room,localTracks,isConnecting,onError,onDisconnect, getLocalVideoTrack, getLocalAudioTrack, connect
    <VideoProvider onError={setError} options={connectionOptions}>
      <CustomDialog
        open={videoDialogOpen}
        title={t("video.preview")}
        onClose={() => {
          setVideoDialogOpen(false);
        }}
      >
        <PxGridContainer
          width="xs"
          container
          direction="column"
          alignItems="center"
        >
          <LocalVideoPreview />
          <PxGridContainer direction="row" justify="center" alignItems="center">
            <LocalAudioLevelIndicator
              backgroundColor="transparent"
              shadow="none"
            />
            <ToggleVideoButton backgroundColor="transparent" color="black0.6" />
            <ToggleSpeakerTestButton
              backgroundColor="transparent"
              color="black0.6"
            />
            <SettingBeforMeeting />
          </PxGridContainer>
          <PxButton
            fullWidth
            backgroundcolor="purple"
            onClick={handleOnClickVideoEnter}
          >
            <ButtonTypo>{t("video.participate")}</ButtonTypo>
          </PxButton>
        </PxGridContainer>
      </CustomDialog>
      <Route exact path="/video/:id" component={App} />
    </VideoProvider>
  );
};

const Main = ({ videoDialogOpen, setVideoDialogOpen }: VideoProps) => {
  return (
    <MuiThemeProvider theme={theme}>
      <AppStateProvider>
        <Video
          videoDialogOpen={videoDialogOpen}
          setVideoDialogOpen={setVideoDialogOpen}
        />
      </AppStateProvider>
    </MuiThemeProvider>
  );
};

export default Main;
