import React from "react";
import styled from "styled-components";
import useHeight from "../../hooks/useHeight";
import { useTheme } from "@material-ui/core/styles";
import useParticipants from "../../hooks/useParticipants";
import Participant from "../Participant/Participant";
import useSelectedParticipant from "../VideoProvider/hooks/useSelectedParticipant";
// import useVideoContext from "../../hooks/useVideoContext";
// import PxGridItem from "../../../components/Grid/PxGridItem";
// import Grid from "@material-ui/core/Grid";

const RootGridDiv = styled.div<{ height: number }>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: ${(props) => `repeat(7, ${props.height / 7}px)`};
  column-gap: 1px;
  row-gap: 1px;
  div {
    &:last-child {
      position: relative !important;
    }
  }
`;

const MultiScreen = () => {
  const theme = useTheme();
  const height = useHeight(); // screen height (viewport)
  const participants = useParticipants();
  const [
    selectedParticipant,
    setSelectedParticipant,
  ] = useSelectedParticipant();

  //   const {
  //     room: { localParticipant },
  //   } = useVideoContext();

  // 다른 grid test
  //   return (
  //     <Grid
  //       container
  //       direction="row"
  //       style={{ height: height - theme.mainNavBarHeight - 6 }}
  //       justify="center"
  //       alignItems="center"
  //     >
  //       <PxGridItem md={"auto"}>
  //         <Participant
  //           participant={localParticipant}
  //           isSelected={selectedParticipant === localParticipant}
  //           onClick={() => setSelectedParticipant(localParticipant)}
  //         />
  //       </PxGridItem>

  //       {/* 같은방에 있는 나를 제외한 참여자들 */}
  //       {participants.map((participant) => (
  //         <PxGridItem md={participants.length}>
  //           <Participant
  //             key={participant.sid}
  //             participant={participant}
  //             isSelected={selectedParticipant === participant}
  //             onClick={() => setSelectedParticipant(participant)}
  //           />
  //         </PxGridItem>
  //       ))}
  //     </Grid>
  //   );

  return (
    <RootGridDiv height={height - theme.mainNavBarHeight - 6}>
      {/* 로컬 비디오 */}
      {/* <Participant
        participant={localParticipant}
        isSelected={selectedParticipant === localParticipant}
        onClick={() => setSelectedParticipant(localParticipant)}
      /> */}

      {/* 같은방에 있는 나를 제외한 참여자들 */}
      {participants.map((participant) => (
        <Participant
          isMultiScreen={true}
          key={participant.sid}
          participant={participant}
          isSelected={selectedParticipant === participant}
          onClick={() => setSelectedParticipant(participant)}
        />
      ))}

      {/* 그리드 비어있는 칸 */}
      {Array(49 - participants.length)
        .fill(0)
        .map((e) => (
          <div style={{ backgroundColor: "black" }}></div>
        ))}
    </RootGridDiv>
  );
};

export default MultiScreen;
