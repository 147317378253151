import File from "../types/models/File";
import FileActionTypes from "../types/actions/FileAction";

const fileDefaultState: File = {
  file: {},
  fileName: "",
  fileType: "",
  folderPath: "",
  filePath: "",
  gubun: "",
  id: "",
};

const fileReducer = (
  state = fileDefaultState,
  action: FileActionTypes
): File => {
  switch (action.type) {
    case "FILE_UPLOAD":
      return action.file;
    case "FILE_RESET":
      return fileDefaultState;
    default:
      return state;
  }
};

export default fileReducer;
