import { AppActions, AppState } from "../store";
import Alert from "../types/models/Alert";
import { Dispatch } from "react";
import uuid from "uuid";

export const setAlertAction = (alert: Alert): AppActions => ({
  type: "SET_ALERT",
  alert: [alert],
});

export const removeAlertAction = (id: string): AppActions => ({
  type: "REMOVE_ALERT",
  id: id,
});

export const setAlert = (alert: Alert) => (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  dispatch(setAlertAction(alert));

  // setTimeout(() => {
  //   dispatch(removeAlertAction(alert.id));
  // });
};

export const newAlert = (msg: string, alertType: "success" | "error" | "warning" | "info" | "default" = "warning") => 
(dispatch: Dispatch<AppActions>,
  getState: () => AppState) => {
  let alert: Alert = {
    alertType,
    id: uuid.v4(),
    msg,
  };
  dispatch(setAlertAction(alert));
  setTimeout(() => {
    dispatch(removeAlertAction(alert.id));
  });
};