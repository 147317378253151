import React from "react";
import ParticipantTracks from "./ParticipantTracks";
import { LocalDataTrack, Participant as IParticipant } from "twilio-video";
import ParticipantInfoForMany from "./ParticipantInfoForMany";
import PxMenu from "../../../components/Menu/PxMenu";
import MenuItem from "@material-ui/core/MenuItem";
import useSelectedParticipant from "../VideoProvider/hooks/useSelectedParticipant";
import { useTheme } from "@material-ui/core/styles";
import ParticipantInfoForManyName from "./ParticipantInfoForManyName";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import useVideoContext from "../../hooks/useVideoContext";
import { useTranslation } from "react-i18next";

interface ParticipantProps {
  participant: IParticipant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  onClick: () => void;
  isSelected: boolean;
  listMode?: boolean;
  isLocal?: boolean;
  isMultiScreen?: boolean;
}

export default function Participant({
  participant,
  disableAudio,
  enableScreenShare,
  onClick,
  isSelected,
  listMode,
  isMultiScreen,
}: ParticipantProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation("lang", { useSuspense: false });
  const [, setSelectedParticipant] = useSelectedParticipant();
  const {
    user,
    PRESENTER_LANG,
    setBlackList,
    blackList,
    videoStopList,
    setVideoStopList,
    unMuteList,
    setUnMuteList,
  } = useAppState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { localTracks } = useVideoContext();

  // to check if audio track is enabled or not
  const dataTrack = localTracks.find(
    (track) => track.kind === "data"
  ) as LocalDataTrack;

  const onClickPipButton = (elemId: string) => {
    // 사이드바에서 로컬비디오 화면을 가져옴
    const videoElem = document.getElementById(elemId);
    //@ts-ignore
    if (videoElem && !document.pictureInPictureElement) {
      //@ts-ignore
      videoElem.requestPictureInPicture().catch((error: any) => {
        // Video failed to enter Picture-in-Picture mode.
      });
    } else {
      //@ts-ignore
      document.exitPictureInPicture().catch((error: any) => {
        // Video failed to leave Picture-in-Picture mode.
      });
    }
  };
  // 웹은 선택시 mainParticipant, pip 선택메뉴가 나오고, 모바일은 선택 시 mainParticipant가 바로 선택됨
  const onClickParticipant = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // 설명회 일때
    if (user?.eventType === "presentation") {
      // 접속유저가 일반 참가자일떄는 호스트의 화면만 select 가능
      if (user?.role === "none") {
        if (PRESENTER_LANG.some((data) => participant.identity.includes(data)))
          setSelectedParticipant(participant);
      } else {
        // 접속유저가 발표자일떄는 모든 사용자의 화면 select 가능
        listMode
          ? setSelectedParticipant(participant)
          : matches
          ? setAnchorEl(e.currentTarget)
          : onClick();
      }
    } else {
      // 설명회가 아닌 다른 이벤트
      listMode
        ? setSelectedParticipant(participant)
        : matches
        ? setAnchorEl(e.currentTarget)
        : onClick();
    }
  };

  const getOutRoom = (identity: string) => {
    dataTrack.send(
      JSON.stringify({ type: "getOutRoom", getOutRoom: identity })
    );
    setBlackList([...blackList, identity]);
  };

  const sendMute = (identity: string) => {
    //음소거 해제된 사람 삭제 후 true
    let isMute: boolean;
    if (unMuteList.some((user) => user === identity)) {
      setUnMuteList(unMuteList.filter((user) => user !== identity));
      isMute = true;
      //음소거 해제 리스트에 추가
    } else {
      setUnMuteList([...unMuteList, identity]);
      isMute = false;
    }
    dataTrack.send(
      JSON.stringify({
        type: "mute",
        mute: { user: identity, isMute },
      })
    );
  };

  const sendVideoStop = (identity: string) => {
    const isVideoStop = videoStopList?.find((id) => id === identity);
    if (isVideoStop) {
      setVideoStopList(videoStopList.filter((id) => id !== identity));
    } else {
      setVideoStopList([...videoStopList, identity]);
    }
    dataTrack.send(
      JSON.stringify({
        type: "videoStop",
        videoStop: { user: identity, isVideoStop: !isVideoStop },
      })
    );
  };

  return (
    <>
      {/* 화면안보이고 이름(참가type)만 보이기 */}
      {listMode === true ? (
        <ParticipantInfoForManyName
          participant={participant}
          onClick={onClickParticipant}
          isSelected={isSelected}
        >
          <div>
            <ParticipantTracks
              isNameList={listMode}
              participant={participant}
              disableAudio={disableAudio}
              enableScreenShare={enableScreenShare}
            />
          </div>
        </ParticipantInfoForManyName>
      ) : (
        <ParticipantInfoForMany
          isMultiScreen={isMultiScreen}
          participant={participant}
          onClick={onClickParticipant}
          isSelected={isSelected}
        >
          <div>
            <ParticipantTracks
              participant={participant}
              disableAudio={disableAudio}
              enableScreenShare={enableScreenShare}
              videoPriority="low"
            />
          </div>
        </ParticipantInfoForMany>
      )}
      {/* 선택지 메뉴 1.선택(mainParticipant) 2.pip모드 */}
      <PxMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        anchorVertical="center"
        anchorhorizontal="right"
      >
        {user?.eventType !== "presentation" && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setSelectedParticipant(participant);
            }}
          >
            {t("videoController.select")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onClickPipButton(participant.identity);
          }}
        >
          {t("videoController.pip")}
        </MenuItem>
        {user?.role === "presenter" &&
          !PRESENTER_LANG.some((data) =>
            participant.identity.includes(data)
          ) && (
            <>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  sendMute(participant.identity);
                }}
              >
                {unMuteList.some((user) => user === participant.identity)
                  ? t("videoController.mute")
                  : t("videoController.unmute")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  getOutRoom(participant.identity);
                }}
              >
                {t("videoController.kick")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  sendVideoStop(participant.identity);
                }}
              >
                {videoStopList?.some((id) => id === participant.identity)
                  ? t("videoController.videoPlay")
                  : t("videoController.videoStop")}
              </MenuItem>
            </>
          )}
      </PxMenu>
    </>
  );
}
