import FormActionType from "../types/actions/FormAction";
import { Form } from "../types/models/Form";

const formDefaultState: Form = {
  id: undefined,
  type: undefined,
  subEventId: undefined,
  createdDate: undefined,
  modifiedDate: undefined,
  content: "",
  additional: "",
};

const formReducer = (
  state = formDefaultState,
  action: FormActionType
): Form => {
  switch (action.type) {
    case "GET_FORM":
      return { ...state, ...action.forms };
    case "REMOVE_FORM":
      return formDefaultState;
    case "SET_FORM_CONTENT":
      return { ...state, content: action.content };
    default:
      return state;
  }
};

export default formReducer;
