import React from "react";
import styled from "styled-components";

const TermsForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const IFrameBox = styled.iframe`
  width: 100%;
  height: 100vh;
  border: 1px solid #ccc;
  max-width: 595.4pt;
`;

const Terms = () => {
  return (
    <TermsForm>
      <IFrameBox src="https://docs.google.com/document/d/e/2PACX-1vQqjdHWXm8LtEB_iLPo9hbhfadHcoF1J5iKhlyD42M7bBOyTdQh5OY2nl2VxWemDZl_yLPMhPTzgFwF/pub?embedded=true"></IFrameBox>
    </TermsForm>
  );
};

export default Terms;
