import React from "react";
import { ControlBarButton } from "amazon-chime-sdk-component-library-react";
import TranslateIcon from "@material-ui/icons/Translate";
import { useTranscript } from "../../providers/TranscriptProvider";

const TranslateControl = () => {
  const {
    translateStatus,
    translateLang,
    setTranslateStatus,
    setTranslateLang,
  } = useTranscript();

  const translateOnclick = () => {
    console.log(`before translate : ${translateStatus}`);
    translateStatus ? setTranslateStatus(false) : setTranslateStatus(true);
    console.log(`after translate : ${translateStatus}`);
  };

  const translateToKorean = (language: string) => {
    console.log(`translateToKorean onClick`);
    console.log(`language : ${language}`);
    language == "kr"
      ? setTranslateLang({ language: "kr" })
      : setTranslateLang({ language: "en" });
    // console.log(`after language : ${}`)
  };

  return (
    <>
      <ControlBarButton
        className="translate"
        icon={<TranslateIcon />}
        label={translateStatus ? translateLang.language : "Translate"}
        onClick={() => translateOnclick()}
        isSelected={translateStatus}
        popOver={[
          {
            onClick: () => translateToKorean("kr"),
            children: <span>한국어</span>,
          },
          {
            onClick: () => translateToKorean("en"),
            children: <span>영어</span>,
          },
        ]}
      ></ControlBarButton>
    </>
  );
};

export default TranslateControl;
