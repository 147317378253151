import React, { createContext, useState, ReactNode, useEffect } from "react";
import { withRouter, RouteComponentProps, useParams } from "react-router-dom";

export interface SubEventContextType {
  selectedUrl: string;
  setSelectedUrl: React.Dispatch<React.SetStateAction<string>>;
  subEventId: string;
  secondId: string;
}

export const SubEventContext = createContext<SubEventContextType>(null!);

interface SubEVentProviderProps {
  children: ReactNode;
}

type Props = SubEVentProviderProps & RouteComponentProps;

const SubEventProvider = ({ children, location }: Props) => {
  const [selectedUrl, setSelectedUrl] = useState<string>("");
  const { id, secondId } = useParams<{ id: string; secondId: string }>(); // id: subEvent id

  useEffect(() => {
    setSelectedUrl(location.pathname);
  }, [location]);

  return (
    <SubEventContext.Provider
      value={{
        selectedUrl: selectedUrl,
        setSelectedUrl: setSelectedUrl,
        subEventId: id,
        secondId: secondId,
      }}
    >
      {children}
    </SubEventContext.Provider>
  );
};

export default withRouter(SubEventProvider);
