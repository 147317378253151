import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CounselCreate,
  CounselFormAnswer,
} from "../../../../types/models/CounselForm";
import { AppState } from "../../../../store";
import { FormData } from "../../../../types/models/Form";
import PxContainer from "../../../../components/Containers/PxContainer";
import { interpreterUpdateCounselForm } from "../../../../actions/counselForms";
import { getForm } from "../../../../actions/forms";
import {
  getMeeting,
  resetMeetingAction,
  updateAcceptorMeetingSurvey,
  updateApplicantMeetingSurvey,
} from "../../../../actions/meeting";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import { SubEventInfo } from "../../../../types/models/SubEvent";
import { Application } from "../../../../types/models/Application";
import CounselDetailTop from "./CounselDetailTop";
import CounselDetailBottom from "./CounselDetailBottom";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import { setAlertAction } from "../../../../actions/alerts";

const useStyles = makeStyles((theme) => ({
  tabButton: {
    width: "100%",
  },
  formDiv: {
    paddingTop: 30,
  },
}));

const CounselDetail = () => {
  const classes = useStyles();
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, secondId } = useParams<{ id: string; secondId: string }>(); // id: 서브이벤트id, secondId: 미팅id

  const [counselFormAnswers, setCounseFormAnswers] =
    useState<CounselFormAnswer[]>();
  const [formId, setFormId] = useState<number>();
  const [counselForm, setCounselForm] = useState<FormData>();
  const [tabValue, setTabValue] = useState<string>("buyer");
  const [selectedApplication, setSelectedApplication] = useState<
    Application | undefined
  >(); // 선택된 기업 (seller or buyer)
  const [gubun, setGubun] = useState<"acceptor" | "applicant" | undefined>(); // 업데이트할 기업의 상담일지가 수락자(acceptor)인지 신청자(applicant)인지 구분하기 위한 state

  const subEvent = useSelector(
    (state: AppState) =>
      state.interpreters.interpreter?.subEventId as SubEventInfo // 자율양식 form의 id값
  );

  const form = useSelector((state: AppState) => state.forms);

  const meeting = useSelector((state: AppState) => state.meetings.meeting);

  useEffect(() => {
    if (history.location.pathname.includes("video")) {
      dispatch(getMeeting(id));
    } else {
      dispatch(getMeeting(secondId));
    }

    return () => {
      dispatch(resetMeetingAction());
    };
  }, [dispatch, history.location.pathname, id, secondId]);

  useEffect(() => {
    if (meeting) {
      setCounseFormAnswers(meeting.meetingSurveys);
      // setSelectedApplication(meeting.meetingAcceptor);
    }
  }, [meeting]);

  // useEffect(() => {
  //   if (meeting) {
  //     if (tabValue === "buyer") {
  //       if (meeting.meetingApplicant?.type === "buyer") {
  //         setCounseFormAnswers(meeting.applicantMeetingSurvey);
  //         setSelectedApplication(meeting.meetingApplicant);
  //         setGubun("applicant");
  //       } else {
  //         setCounseFormAnswers(meeting.acceptorMeetingSurvey);
  //         setSelectedApplication(meeting.meetingAcceptor);
  //         setGubun("acceptor");
  //       }
  //     } else {
  //       if (meeting.meetingApplicant?.type === "seller") {
  //         setCounseFormAnswers(meeting.applicantMeetingSurvey);
  //         setSelectedApplication(meeting.meetingApplicant);
  //         setGubun("applicant");
  //       } else {
  //         // setCounseFormAnswers(meeting.acceptorMeetingSurvey);
  //         setSelectedApplication(meeting.meetingAcceptor);
  //         setGubun("acceptor");
  //       }
  //     }
  //   }
  // }, [meeting, tabValue]);

  // 상담일지 form을 서버에서 가져옴
  useEffect(() => {
    console.log(`subEventCounselForm : `, subEvent.sellerCounselForm);
    // 선택한 tab에 따라 form을 가져옴
    if (subEvent && tabValue === "seller" && subEvent.sellerCounselForm) {
      dispatch(getForm(subEvent.sellerCounselForm.toString()));
    } else if (subEvent && tabValue === "buyer" && subEvent.sellerCounselForm) {
      dispatch(getForm(subEvent.sellerCounselForm.toString()));
    }
  }, [subEvent, tabValue, dispatch]);

  // db에서 가져온 상담일지 양식을 Json형식으로 변환 후 state에 저장
  useEffect(() => {
    if (form.id) {
      setFormId(form.id);
      const formData = JSON.parse(form.content! as string);
      setCounselForm(formData);
    }
  }, [form]);

  // 저장 onClick method, 작성한 상담일지 저장 api call
  const onSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const data: CounselFormAnswer[] = [];
    const checkBoxData: CounselFormAnswer[] = [];
    // 작성 된 자율양식 데이터를 서버로 보내기전 알맞은 형태로 가공
    counselForm!.formIds.forEach((formUuid) => {
      const counselFormAnswer: CounselFormAnswer = {
        id: counselForm!.formData[formUuid].defaultId ?? "",
        title: counselForm!.formData[formUuid].title ?? "",
        type: counselForm!.formData[formUuid].type,
        uuid: formUuid,
        formId: formId!,
      };

      switch (counselForm!.formData[formUuid].type) {
        case "single":
          counselFormAnswer["content"] =
            counselForm!.formData[formUuid].radioContent?.content;
          counselFormAnswer["answerUuid"] =
            counselForm!.formData[formUuid].radioContent?.id;
          data.push(counselFormAnswer);
          break;
        case "many":
          counselForm!.formData[formUuid].checkBoxContent?.forEach(
            ({ id, content }, index) => {
              const checkBoxForm: CounselFormAnswer = {
                id: counselForm!.formData[formUuid].defaultId ?? "",
                title: counselForm!.formData[formUuid].title ?? "",
                type: counselForm!.formData[formUuid].type,
                uuid: formUuid,
                formId: formId!,
              };
              checkBoxForm["content"] = content;
              checkBoxForm["answerUuid"] = id;
              checkBoxData.push(checkBoxForm);
            }
          );
          break;
        case "file":
          counselFormAnswer["content"] =
            counselForm!.formData[formUuid].fileContent?.fileId;
          data.push(counselFormAnswer);
          break;
        case "star":
          counselFormAnswer["content"] = counselForm!.formData[formUuid]
            .content as string;
          data.push(counselFormAnswer);
          break;
        default:
          counselFormAnswer["content"] = counselForm!.formData[formUuid]
            .content as string;
          data.push(counselFormAnswer);
          break;
      }
    });

    const answerData = data.concat(checkBoxData);

    let counsel: CounselCreate | undefined = undefined;
    if (meeting) {
      counsel = {
        meetingId: Number(meeting.id),
        meetingSurveys: answerData,
      };
    }

    if (counsel) {
      const result: any = await dispatch(interpreterUpdateCounselForm(counsel));

      // 업데이트 할 유저가 수락자(acceptor) or 신청자(applicant)에 따라 redux state 값 변경
      if (gubun === "acceptor") {
        dispatch(updateAcceptorMeetingSurvey(result as any));
      } else if (gubun === "applicant") {
        dispatch(updateApplicantMeetingSurvey(result as any));
      }

      if (result) {
        dispatch(
          setAlertAction({
            id: "success",
            msg: t("interpreterCounselForm.formSaveSuccessAlert"),
            alertType: "success",
          })
        );
      }
    }
  };

  return (
    <PxContainer maxWidth="sm">
      <div className={classes.formDiv}>
        <CounselDetailTop application={selectedApplication} />
        <CounselDetailBottom
          meetingSurveys={counselFormAnswers}
          counselForm={counselForm}
          setCounselForm={setCounselForm}
        />
        <PxButton
          backgroundcolor="purple"
          padding="10px 30px 10px 30px"
          style={{ float: "right" }}
          onClick={onSubmit}
        >
          <ButtonTypo>{t("common.save")}</ButtonTypo>
        </PxButton>
        <PxButton
          className="mr-2"
          backgroundcolor="grey"
          padding="10px 30px 10px 30px"
          style={{ float: "right" }}
          onClick={() => {
            history.goBack();
          }}
        >
          <ButtonTypo>{t("common.cancel")}</ButtonTypo>
        </PxButton>
      </div>
    </PxContainer>
  );
};

export default CounselDetail;
