import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import uuid from "uuid";

// bs comp
import CardActions from "@material-ui/core/CardActions";

// custom material ui comp
import GridItem from "../../components/Grid/PxGridItem";

import { AppState } from "../../store";
import TitleTypo from "../Typhography/TitleTypo";
import SubHeadingTypo from "../Typhography/SubHeadingTypo";
import PxGridContainer from "../Grid/PxGridContainer";
import CaptionTypo from "../Typhography/CaptionTypo";
import Body1Typo from "../Typhography/Body1Typo";
import { SubEventInfo } from "../../types/models/SubEvent";
import { makeStyles } from "@material-ui/core/styles";
import { setAlertAction } from "../../actions/alerts";
import { parallaxCalculation } from "../../utils/momentTz";

const useStyles = makeStyles((theme) => ({
  internalApplyCard: {
    width: "100%",
    padding: "0px 5px",
    cursor: "pointer",
  },
  cardDivider: {
    width: "100%",
    margin: "auto",
    marginBottom: 10,
  },
}));
interface BgColor {
  bgColor?: string;
  border?: string;
  borderTop?: string;
  boxShadow?: string;
}
const Card = styled.div<BgColor>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border: ${(props) => props.border ?? undefined};
  box-shadow: ${(props) => props.boxShadow ?? undefined};
  ${(props) => {
    if (props.borderTop !== undefined) {
      return css`
        border-top: ${props.borderTop};
      `;
    }
  }}

  background-color: ${(props) => props.bgColor};
`;

interface SubEventApplyCardProps {
  eventId: string;
  applicantsList: {
    [key: number]: { approval: string; type: string; reason?: string };
  };
}

const SubEventApplyCard = ({
  eventId,
  applicantsList,
}: SubEventApplyCardProps) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const event = useSelector((state: AppState) => state.events.event);
  const subEvents = useSelector((state: AppState) => state.subEvents.subEvents);
  const { country, countries } = useSelector(
    (state: AppState) => state.countries!
  );

  const participateOnClick = async (subEventId: string, routePath: string) => {
    history.push(routePath);
  };

  const checkEventDate = useCallback(
    (subEv: SubEventInfo, type: string) => {
      // const date = moment();
      // const endDate = moment(subEv.sellRegEndDate);
      // const check = date.isAfter(endDate);
      const nowDate = new Date(moment().format()).toISOString();
      const endDate =
        type === "seller" ? subEv.sellRegEndDate : subEv.buyRegEndDate;
      const startDate =
        type === "seller" ? subEv.sellRegStartDate : subEv.buyRegStartDate;
      const betweenEndDate = parallaxCalculation(
        endDate!,
        countries!["KR"] as any,
        countries!["IS"] as any
      );
      const betweenStartDate = parallaxCalculation(
        startDate!,
        countries!["KR"] as any,
        countries!["IS"] as any
      );
      const timeDiff = moment(nowDate, "YYYY-MM-DD").isBetween(
        moment(betweenStartDate, "YYYY-MM-DD"),
        betweenEndDate,
        "day",
        "[]"
      );

      return timeDiff;
    },
    [countries]
  );

  const checkEventAttendedAndDrawUi = (
    subEventId: any,
    subEv: SubEventInfo
  ) => {
    if (_.has(applicantsList, subEventId)) {
      if (applicantsList[subEventId].approval === "approval") {
        //승인됐을경우
        return (
          <Card
            bgColor="#512da8"
            className={classes.internalApplyCard}
            onClick={() => {
              if (subEv.matching === "Y") {
                history.push(`/subEvent/${subEventId}/attendeeList`);
              } else if (subEv.category === "presentation") {
                history.push(`/subEvent/${subEventId}/webinar`);
              } else {
                history.push(`/subEvent/${subEventId}`);
              }
            }}
          >
            <>
              <PxGridContainer direction="row">
                <SubHeadingTypo txtcolor="white">
                  {applicantsList[subEventId].type === "seller"
                    ? `${subEv.sgroupName}`
                    : `${subEv.bgroupName}`}
                </SubHeadingTypo>
              </PxGridContainer>
              <div>
                <Body1Typo txtcolor="white0.56">
                  {parallaxCalculation(
                    applicantsList[subEventId].type === "seller"
                      ? subEv.sellRegStartDate!
                      : subEv.buyRegStartDate!,
                    "",
                    countries![country!.countryCode!] as any,
                    "YYYY. MM. DD HH:mm"
                  )}
                  &nbsp;-&nbsp;
                  {parallaxCalculation(
                    applicantsList[subEventId].type === "seller"
                      ? subEv.sellRegEndDate!
                      : subEv.buyRegEndDate!,
                    "",
                    countries![country!.countryCode!] as any,
                    "MM. DD HH:mm"
                  )}
                </Body1Typo>
                <CaptionTypo margin="10px 0 0 0" txtcolor="white">
                  {t("eventApplyCard.registered")}
                </CaptionTypo>
              </div>
            </>
          </Card>
        );
      } else if (applicantsList[subEventId].approval === "waiting") {
        //신청중인경우
        return (
          <Card
            bgColor="#ede7f6"
            className={classes.internalApplyCard}
            style={{ cursor: "default" }}
          >
            <>
              <PxGridContainer direction="row">
                <SubHeadingTypo txtcolor="bluePurple">
                  {applicantsList[subEventId].type === "seller"
                    ? `${subEv.sgroupName}`
                    : `${subEv.bgroupName}`}
                </SubHeadingTypo>
              </PxGridContainer>
              <div>
                <Body1Typo txtcolor="black0.6">
                  {parallaxCalculation(
                    applicantsList[subEventId].type === "seller"
                      ? subEv.sellRegStartDate!
                      : subEv.buyRegStartDate!,
                    "",
                    countries![country!.countryCode!] as any,
                    "YYYY-MM-DD HH:mm"
                  )}
                  ~{" "}
                  {parallaxCalculation(
                    applicantsList[subEventId].type === "seller"
                      ? subEv.sellRegEndDate!
                      : subEv.buyRegEndDate!,
                    "",
                    countries![country!.countryCode!] as any,
                    "MM-DD HH:mm"
                  )}
                </Body1Typo>

                <CaptionTypo margin="10px 0 0 0" txtcolor="rgba(0,0,0,0.87)">
                  {t("eventApplyCard.waiting")}
                </CaptionTypo>
              </div>
            </>
          </Card>
        );
      } else if (applicantsList[subEventId].approval === "reject") {
        //거절인 경우
        return (
          <Card
            bgColor="#dfd9d9"
            className={classes.internalApplyCard}
            onClick={() => {
              history.push(`/subEvent/${subEventId}/myInfo`);
            }}
          >
            <>
              <PxGridContainer direction="row">
                <SubHeadingTypo txtcolor="bluePurple">
                  {applicantsList[subEventId].type === "seller"
                    ? `${subEv.sgroupName}`
                    : `${subEv.bgroupName}`}
                </SubHeadingTypo>
              </PxGridContainer>
              <div>
                <Body1Typo txtcolor="black0.6">
                  {parallaxCalculation(
                    applicantsList[subEventId].type === "seller"
                      ? subEv.sellRegStartDate!
                      : subEv.buyRegStartDate!,
                    "",
                    countries![country!.countryCode!] as any,
                    "YYYY-MM-DD HH:mm"
                  )}
                  ~{" "}
                  {parallaxCalculation(
                    applicantsList[subEventId].type === "seller"
                      ? subEv.sellRegEndDate!
                      : subEv.buyRegEndDate!,
                    "",
                    countries![country!.countryCode!] as any,
                    "MM-DD HH:mm"
                  )}
                </Body1Typo>

                <CaptionTypo margin="10px 0 0 0" txtcolor="rgba(0,0,0,0.87)">
                  거절사유: {applicantsList[subEventId].reason}
                </CaptionTypo>
              </div>
            </>
          </Card>
        );
      }
    } else {
      //신청전
      return (
        <PxGridContainer spacing={1}>
          {/* 셀러 seller */}
          <Card
            boxShadow={
              checkEventDate(subEv, "seller")
                ? "0px 0px 8px rgba(0,0,0,0.1)"
                : undefined
            }
            border={
              checkEventDate(subEv, "seller")
                ? "1px solid rgba(0,0,0,0.2)"
                : undefined
            }
            bgColor={checkEventDate(subEv, "seller") ? "" : "rgb(255,255,255)"}
            className={classes.internalApplyCard}
            onClick={() => {
              if (checkEventDate(subEv, "seller") === true) {
                participateOnClick(
                  subEventId,
                  `/form/subEvent/${subEventId}/sellerPartiForm`
                );
              } else {
                dispatch(
                  setAlertAction({
                    id: uuid.v4(),
                    msg: `${subEv.sgroupName} ${t(
                      "eventApplyCard.regitUnavailable"
                    )}`,
                    alertType: "warning",
                  })
                );
              }
            }}
          >
            <>
              <PxGridContainer direction="row">
                <SubHeadingTypo
                  txtcolor={
                    checkEventDate(subEv, "seller") ? "bluePurple" : "black0.38"
                  }
                >
                  {subEv.sgroupName}
                </SubHeadingTypo>
              </PxGridContainer>
              <div>
                <Body1Typo
                  txtcolor={
                    checkEventDate(subEv, "seller") ? "black0.6" : "black0.38"
                  }
                >
                  {parallaxCalculation(
                    subEv.sellRegStartDate!,
                    "",
                    countries![country!.countryCode!] as any,
                    "YYYY-MM-DD HH:mm"
                  )}
                  ~{" "}
                  {parallaxCalculation(
                    subEv!.sellRegEndDate!,
                    "",
                    countries![country!.countryCode!] as any,
                    "MM-DD HH:mm"
                  )}
                </Body1Typo>
                <CaptionTypo
                  margin="10px 0 0 0"
                  txtcolor={
                    checkEventDate(subEv, "seller")
                      ? "rgba(0,0,0,0.87)"
                      : "black0.38"
                  }
                >
                  {checkEventDate(subEv, "seller")
                    ? t("eventApplyCard.available")
                    : ""}
                </CaptionTypo>
              </div>
            </>
          </Card>
          {/* 바이어 buyer */}
          {event?.id != 11387 && event?.id != 287420 && (
            <Card
              boxShadow={
                checkEventDate(subEv, "buyer")
                  ? "0px 0px 8px rgba(0,0,0,0.1)"
                  : undefined
              }
              border={
                checkEventDate(subEv, "buyer")
                  ? "1px solid rgba(0,0,0,0.2)"
                  : undefined
              }
              bgColor={checkEventDate(subEv, "buyer") ? "" : "rgb(255,255,255)"}
              className={classes.internalApplyCard}
              style={{ marginTop: 5 }}
              onClick={() => {
                if (checkEventDate(subEv, "buyer") === true) {
                  participateOnClick(
                    subEventId,
                    `/form/subEvent/${subEventId}/buyerPartiForm`
                  );
                } else {
                  dispatch(
                    setAlertAction({
                      id: uuid.v4(),
                      msg: `${subEv.bgroupName} ${t(
                        "eventApplyCard.regitUnavailable"
                      )}`,
                      alertType: "warning",
                    })
                  );
                }
              }}
            >
              <>
                <PxGridContainer direction="row">
                  <SubHeadingTypo
                    txtcolor={
                      checkEventDate(subEv, "buyer")
                        ? "bluePurple"
                        : "black0.38"
                    }
                  >
                    {subEv.bgroupName}
                  </SubHeadingTypo>
                </PxGridContainer>
                <div>
                  <Body1Typo
                    txtcolor={
                      checkEventDate(subEv, "buyer") ? "black0.6" : "black0.38"
                    }
                  >
                    {parallaxCalculation(
                      subEv!.buyRegStartDate!,
                      "",
                      countries![country!.countryCode!] as any,
                      "YYYY-MM-DD HH:mm"
                    )}
                    ~{" "}
                    {parallaxCalculation(
                      subEv!.buyRegEndDate!,
                      "",
                      countries![country!.countryCode!] as any,
                      "MM-DD HH:mm"
                    )}
                  </Body1Typo>
                  <CaptionTypo
                    margin="10px 0 0 0"
                    txtcolor={
                      checkEventDate(subEv, "buyer")
                        ? "rgba(0,0,0,0.87)"
                        : "black0.38"
                    }
                  >
                    {checkEventDate(subEv, "buyer")
                      ? t("eventApplyCard.available")
                      : ""}
                  </CaptionTypo>
                </div>
              </>
            </Card>
          )}
        </PxGridContainer>
      );
    }
  };

  return subEvents!.length > 0 ? (
    <>
      <TitleTypo gutterBottom fontSize={21} fontweight="400">
        {t("eventDetail.apply")}
      </TitleTypo>
      <PxGridContainer direction="column" spacing={1}>
        {subEvents &&
          Array.isArray(subEvents) &&
          subEvents.map((subEv: any, index: number) => (
            <GridItem key={index}>
              <Card
                borderTop="4px solid rgba(0,0,0,0.1)"
                bgColor="rgba(0,0,0,0.01)"
              >
                <>
                  <SubHeadingTypo
                    fontweight="500"
                    upperCase={true}
                    marginTop="4px"
                  >
                    {subEv.category === "presentation"
                      ? "Conference"
                      : subEv.category}
                  </SubHeadingTypo>
                  <Body1Typo txtcolor="black0.6" marginBottom="16px">
                    {parallaxCalculation(
                      subEv.subEventStartDate,
                      "",
                      countries![country!.countryCode!] as any,
                      "YYYY. MM. DD HH:mm"
                    )}
                    &nbsp;-&nbsp;
                    {parallaxCalculation(
                      subEv.subEventEndDate,
                      "",
                      countries![country!.countryCode!] as any,
                      "MM. DD HH:mm"
                    )}
                  </Body1Typo>
                </>
                <CardActions
                  style={{ justifyContent: "space-around", padding: 4 }}
                >
                  {checkEventAttendedAndDrawUi(subEv.id, subEv)}
                </CardActions>
              </Card>
            </GridItem>
          ))}
      </PxGridContainer>
    </>
  ) : (
    <></>
  );
};

export default SubEventApplyCard;
