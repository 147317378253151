import React from "react";
import BaseTemplate, { EventContent, LinkButton, MailContent } from "./BaseTemplate";
import styled from "styled-components";

interface MeetingMailProp {
  language: "kr" | "en";
  userName: string;
  eventName: string;
  eventStart: string;
  eventEnd: string;
  date: string;
  approvedDate: string;
  link: string;
}

export default ({
  language,
  userName,
  eventName,
  eventStart,
  eventEnd,
  date,
  approvedDate,
  link,
}: MeetingMailProp) => {
  return (
    <BaseTemplate
      title={
        language === "en"
          ? "Confirmation of participating application notice"
          : "참가신청 확정 안내"
      }
      language={language}
    >
      {language === "en" ? (
        <>
          <MailContent>
            Hello, {userName}
            <br />
            You can access the event and use the private menu.
          </MailContent>
          <EventContent>
            Event Name:&nbsp; {eventName}
            <br />
            Period:
            <br />
            &nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            Participating application:&nbsp;{date}(GMT+9)
            <br />
            Host Confirmation:&nbsp;{approvedDate}
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            Go to event{" "}
          </LinkButton>
        </>
      ) : (
        <>
          <MailContent>
            {userName}님 안녕하세요
            <br />
            회원님은 참가신청한 이벤트에 점속하여 전용메뉴를 사용할 수 있습니다.
          </MailContent>
          <EventContent>
            이벤트 명:&nbsp; {eventName}
            <br />
            이벤트 기간:
            <br />
            &nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            참가신청 일시:&nbsp;{date}(GMT+9)
            <br />
            주최자 확정:&nbsp;{approvedDate}
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            이벤트 바로가기
          </LinkButton>
        </>
      )}
    </BaseTemplate>
  );
};

const ReasonBox = styled.div`
  background-color: #f5f5f5;
  padding: 16px 24px;
  margin-bottom: 50px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
`;
