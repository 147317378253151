import React from "react";
import { LocalAudioTrack } from "twilio-video";

// material ui
import Fab from "@material-ui/core/Fab";

// components
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";

// hooks
import useVideoContext from "../../hooks/useVideoContext";
import useLocalAudioToggle from "../../hooks/useLocalAudioToggle";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";
import { useAppState } from "../AppStateProvider/hooks/useAppState";

interface LocalAudioLevelIndicatorProps {
  disabled?: boolean;
  backgroundColor?: string; // background color
  color?: string; // audio indicator color
  shadow?: string;
}

export default function LocalAudioLevelIndicator(
  props: LocalAudioLevelIndicatorProps
) {
  const { localTracks } = useVideoContext();
  const [, toggleAudioEnabled] = useLocalAudioToggle(); // local Audio Track turn on/off
  const { t } = useTranslation("lang", { useSuspense: false });
  const { setSelfMute, selfMute } = useAppState();

  const audioTrack = localTracks.find(
    (track) => track.kind === "audio"
  ) as LocalAudioTrack;

  return (
    <Fab
      onClick={() => {
        toggleAudioEnabled();
        localStorage.setItem(
          "selfAudioStop",
          !selfMute === true ? "true" : "false"
        );
        setSelfMute(!selfMute);
      }}
      disabled={props.disabled}
      style={{
        color: props.color,
        backgroundColor: props.backgroundColor,
        margin: 8,
        boxShadow: "none",
      }}
    >
      <div>
        <AudioLevelIndicator
          size={25}
          audioTrack={audioTrack}
          background="#e7e7e7"
        />
        <CaptionTypo txtcolor="black0.6">
          {t("videoController.audio")}
        </CaptionTypo>
      </div>
    </Fab>
  );
}
