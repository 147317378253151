import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";

import CounselListTable from "./CounselListTable";

export const CounselList = () => {
  const interpreter = useSelector(
    (state: AppState) => state.interpreters.interpreter
  );

  return (
    <>
      <CounselListTable tableRowData={interpreter?.meetingIds ?? []} />
    </>
  );
};

export default CounselList;
