import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";

import useVideoContext from "../../hooks/useVideoContext";
import { useHistory } from "react-router-dom";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
        color: "#bb86fc",
      },
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const history = useHistory();
  const { room } = useVideoContext();
  const [t] = useTranslation("lang", { useSuspense: false });

  return (
    <Tooltip
      title={"End Call"}
      onClick={() => {
        if (room.state !== undefined) {
          room.disconnect();
        }
        history.goBack();
      }}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={classes.fab}>
        <div>
          <PhoneDisabledIcon />
          <CaptionTypo txtcolor="white">
            {t("videoController.exit")}
          </CaptionTypo>
        </div>
      </Fab>
    </Tooltip>
  );
}
