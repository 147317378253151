import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/core/styles";

// actions
import { getInterpreters } from "../actions/interpreter";

// router
import { mainRoutes } from "../router/router";

// custom components
import Header from "../components/Navbars/MainNavbar";
import Footer from "../components/Footer/Footer";
import PxContainer from "../components/Containers/PxContainer";
import PxDialog from "../components/Dialog/PxDialog";
import BreackPoints from "../components/PxBreakpoint/PxBreakpoint";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

// type
import RouteType from "../types/models/Route";
import FormProvider from "../components/Providers/FormProvider/FormProvider";
import { useTranslation } from "react-i18next";
import SubHeadingTypo from "../components/Typhography/SubHeadingTypo";
import { getMyEventList } from "../actions/users";
import { AppState } from "../store";
import Body1Typo from "../components/Typhography/Body1Typo";

const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  position: relative;
  min-width: 360px;
  height: 100%;
`;

const StyledContainer = styled(PxContainer)`
  max-width: 1980px;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    listRoot: {
      width: "100%",
      minWidth: 360,
      maxWidth: 500,
      minHeight: 400,
      backgroundColor: "white",
      [theme.breakpoints.down("xs")]: {
        minWidth: 360,
        minHeight: 350,
      },
    },
    listSubHeader: {
      backgroundColor: "#5f4b8b",
      padding: 15,
    },
  })
);

interface MainProps {}

const Main: React.FC<MainProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { eventList, id } = useSelector((state: AppState) => state.users);
  const { interpreters } = useSelector((state: AppState) => state.interpreters);
  const { t, i18n } = useTranslation("lang", { useSuspense: false });
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  useEffect(() => {
    // 참가신청 영어만 입력 후 원래 언어로 변경 -> formview에서 언어변경됨
    if (location.pathname.includes("form")) return;
    const secondLang = window.localStorage.getItem("secondLang");
    if (secondLang) {
      localStorage.removeItem("secondLang");
      i18n.changeLanguage(secondLang);
    }
  }, [location.pathname]);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (dialogTitle === "mainNavBar.myEvent") {
      dispatch(getMyEventList());
    }
    if (dialogTitle === "mainNavBar.interpreter") {
      dispatch(getInterpreters(id!.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogTitle, dispatch]);

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      switch (prop.layout) {
        case "/":
          return (
            <Route
              exact
              path={prop.layout + prop.path}
              render={(props) => <prop.component {...props} />}
              key={key}
            />
          );
        case "/search_result/:word":
        case "/mypage":
        case "/auth":
        case "/event":
        case "/terms":
        case "/list":
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => (
                <StyledContainer>
                  <prop.component {...props} />{" "}
                </StyledContainer>
              )}
              key={key}
            />
          );
        case "/form":
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => (
                <StyledContainer>
                  <FormProvider>
                    <prop.component {...props} />
                  </FormProvider>
                </StyledContainer>
              )}
              key={key}
            />
          );
        default:
          return <Redirect to="/" key={key} />;
      }
    });
  };

  return (
    <>
      <Container>
        {!history.location.pathname.includes("/auth") && (
          <Header
            setDialogOpen={setDialogOpen}
            setDialogTitle={setDialogTitle}
          />
        )}

        {/* <Chat /> */}

        <PxDialog open={dialogOpen} onClose={handleDialogClose}>
          <List
            className={classes.listRoot}
            subheader={
              <ListSubheader className={classes.listSubHeader}>
                <SubHeadingTypo fontweight="500" style={{ color: "white" }}>
                  {t(dialogTitle)}
                </SubHeadingTypo>
              </ListSubheader>
            }
          >
            <div style={{ paddingTop: 10 }}>
              {/* 참가한 이벤트 메뉴를 눌렀을때 나오는 dialog */}
              {dialogTitle === "mainNavBar.myEvent" &&
                eventList !== undefined &&
                eventList.map((event) => {
                  return (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          history.push(`/event/detail/${event.eventId}`);
                          setDialogOpen(false);
                        }}
                      >
                        <ListItemText>
                          {event.eventName}({event.subEventType})
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <Body1Typo txtcolor="secondary">
                            {event.approval === "waiting"
                              ? t("mainNavBar.waiting")
                              : ""}
                          </Body1Typo>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider component="li" />
                    </>
                  );
                })}
              {/* 통역 메뉴를 눌렀을때 나오는 dialog */}
              {dialogTitle === "mainNavBar.interpreter" &&
                interpreters !== undefined &&
                interpreters.map((interpreter) => {
                  return (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          history.push(
                            `/interpreter/${interpreter.subEventId}/participantsList`
                          );
                          setDialogOpen(false);
                        }}
                      >
                        <ListItemText>
                          {interpreter.mainEventName}({interpreter.subEventName}
                          )
                        </ListItemText>
                      </ListItem>
                      <Divider component="li" />
                    </>
                  );
                })}
            </div>
          </List>
        </PxDialog>
        <BreackPoints>
          <Switch>{getRoutes(mainRoutes)}</Switch>
        </BreackPoints>
        <Footer />
      </Container>
    </>
  );
};

export default Main;
