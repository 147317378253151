import React, { ReactNode } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

interface PxDialogProps {
  title?: string;
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  className?: string;
}

const PxDialog = ({
  open,
  children,
  title,
  onClose,
  className,
}: PxDialogProps) => {
  return (
    <Dialog className={className} open={open} maxWidth="xs" onClose={onClose}>
      {title !== undefined && (
        <DialogTitle style={{ textAlign: "center", padding: "10px 24px" }}>
          {title}
        </DialogTitle>
      )}
      {children}
    </Dialog>
  );
};

export default PxDialog;
