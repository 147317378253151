import useVideoContext from "../hooks/useVideoContext";
import useParticipants from "../hooks/useParticipants";
import useScreenShareParticipant from "../hooks/useScreenShareParticipant";
import useSelectedParticipant from "../components/VideoProvider/hooks/useSelectedParticipant";
import { useEffect, useState } from "react";
import { useAppState } from "../components/AppStateProvider/hooks/useAppState";
import { useTranslation } from "react-i18next";
import { LocalParticipant, RemoteParticipant } from "twilio-video";

export default function useMainSpeaker() {
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const { user, PRESENTER_LANG } = useAppState();
  const participants = useParticipants();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const role = ["발표자", "Presenter"];

  const [t] = useTranslation("lang", { useSuspense: false });

  const [mainSpeaker, setMainSpeaker] = useState<
    RemoteParticipant | LocalParticipant
  >(); // mainSpeaker 참가자

  // 설명회와 일반미팅의 경우 처음 선택될 mainSpeaker가 다름
  // 설명회 => 발표자, 일반미팅 => 상대참가자
  // 설명회에서 발표자는 자신의 화면(localParticipant)만 보면 됨
  useEffect(() => {
    if (user?.eventType === "presentation") {
      if (user.type === "seller") {
        // 참가자
        const presenter = participants.filter((p) =>
          PRESENTER_LANG.some((data) => p.identity.includes(data))
        );
        // setSelectedParticipant(presenter[0]);
        setMainSpeaker(presenter[0]);
      } else {
        // 발표자
        setMainSpeaker(localParticipant);
      }
    } else {
      // 일반미팅
      setMainSpeaker(participants[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localParticipant, participants, t, user]);

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return (
    selectedParticipant ||
    screenShareParticipant ||
    mainSpeaker ||
    localParticipant
  );
}
