import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Body1Typo from "../Typhography/Body1Typo";
import Body3Typo from "../Typhography/Body3Typo";
import WarningIcon from "@material-ui/icons/Warning";
import PxButton from "../Buttons/PxButton";
import { useHistory } from "react-router-dom";
import SuperHeadTypo from "../Typhography/SuperHeadTypo";
export default () => {
  const history = useHistory();
  const { t } = useTranslation("lang", { useSuspense: false });
  return (
    <Container>
      <Content>
        <WarningIcon
          style={{
            color: "rgb(81, 45, 168)",
            width: "3rem",
            height: "3rem",
            marginBottom: 20,
          }}
        />
        <SuperHeadTypo>{t("noResult.pageNotFound")}</SuperHeadTypo>
        <Body1Typo txtcolor="black0.6">
          {t("noResult.cannotBeFound")}{" "}
        </Body1Typo>
        <PxButton
          style={{ fontSize: "1rem" }}
          padding="20px 0px"
          onClick={() => history.goBack()}
          hover={false}
          txtColor="bluePurple"
        >
          {t("noResult.backToWebsite")}
        </PxButton>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px 0px;
`;

const Content = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &:first-child {
    margin-bottom: 20px !important;
  }
`;
