import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import useAudio from "../../hooks/useAudio";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";

const soundFile = require("../../../assets/sounds/test.mp3");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
  })
);

export default function ToggleSpeakerTestButton(props: {
  disabled?: boolean;
  backgroundColor?: string;
  color?: "black0.6";
}) {
  const [t] = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();  
  const [playing, toggle] = useAudio({
    url: soundFile,
  });

  return (
    <Tooltip
      title={"Speaker test"}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        className={classes.fab}
        onClick={toggle}
        disabled={props.disabled}
        style={{
          backgroundColor: props.backgroundColor,
          color: props.color ?? "white",
        }}
      >
        <div>
          {playing ? <StopIcon /> : <PlayArrowIcon />}
          <CaptionTypo txtcolor={props.color ?? "white"}>{t('videoController.speaker')}</CaptionTypo>
        </div>
      </Fab>
    </Tooltip>
  );
}
