import {
  ChatBubble,
  ChatBubbleContainer,
  EditableChatBubble,
  Flex,
  formatTime,
  RosterHeader,
} from "amazon-chime-sdk-component-library-react";
import React, { useEffect, useState, useRef } from "react";
import { useAppState } from "../providers/AppStateProvider";
import {
  RealtimeChatValue,
  RealtimeData,
  useRealtimeChatState,
} from "../providers/ChatProvider";
import styled from "styled-components";
import { AppState } from "../../store";
import { useSelector } from "react-redux";
import { values } from "lodash";
import ChatEditor from "../components/Chat/ChatEditor";
import { useNavigation } from "../providers/NavigationProvider";

const Chat = () => {
  const { localUserName } = useAppState();
  const { chatData, setReadCount, sendChatData } = useRealtimeChatState();
  const { name } = useSelector((state: AppState) => state.users);
  const { closeChat } = useNavigation();

  const containerStyles = `
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 1rem;
        height: 100px;
        border-top: 1px solid;
    `;

  const bubbleStyle = `
        width: 100%;
        margin: 1rem;
    `;

  const flexStyles = `
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 30rem;
        height: 100%;
        background-color: #f0f1f2;
        border: 1px solid;
    `;
  const InputCover = styled.div`
    width: 100%;
  `;
  const ChatList = styled.div`
    width: 100%;
    height: calc(100vh - 165px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column-reverse;
  `;

  useEffect(() => {
    setReadCount(chatData.length);
  }, [, chatData]);

  const ChatItem = (chatValue: RealtimeData) => {
    const senderName =
      name == chatValue.senderName ? "Me" : chatValue.senderName;
    const variant = name == chatValue.senderName ? "incoming" : "outgoing";
    const messageTime = formatTime(String(chatValue.createdDate));
    return (
      <ChatBubbleContainer>
        <ChatBubble
          variant={variant}
          senderName={senderName}
          showTail={true}
          css={bubbleStyle}
        >
          {chatValue.data}
        </ChatBubble>
      </ChatBubbleContainer>
    );
  };

  return (
    <>
      <Flex className="chat" layout="fill-space-centered" css={flexStyles}>
        <RosterHeader title="Chat" onClose={closeChat} css="width:100%;" />
        <ChatList>
          {chatData &&
            [...chatData].reverse().map((data, idx) => {
              return <ChatItem key={idx} {...data} />;
            })}
        </ChatList>

        <Flex layout="fill-space-centered" css={containerStyles}>
          {/* <EditableChatBubble
            variant="outgoing"
            senderName={localUserName}
            placeholder="메세지를 작성해주세요"
            content={inputMsg}
            showTail={true}
            css={bubbleStyle}
            showName={false}
            save={save}
            cancel={cancel}
          /> */}
          <ChatEditor />
        </Flex>
      </Flex>
    </>
  );
};

export default Chat;
