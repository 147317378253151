import { AppActions } from "../store";
import { Dispatch } from "react";

export const setLoadingAction = (): AppActions => ({
  type: "SET_LOADING",
});

export const removeLoadingAction = (): AppActions => ({
  type: "REMOVE_LOADING",
});

export const setLoading = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(setLoadingAction());
};

export const removeLoading = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(removeLoadingAction());
};
