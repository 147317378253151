import React, { useState } from "react";
import { parallaxCalculation } from "../../utils/momentTz";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../utils/utils";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import RoomIcon from "@material-ui/icons/Room";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { theme } from "../../components/PxBreakpoint/PxBreakpoint";

interface CountryTimeFormProps {
  startDate: string;
  endDate: string;
  hostCountryCode: string; // 주최지코드
}

const CountryTimeForm = ({
  startDate,
  endDate,
  hostCountryCode,
}: CountryTimeFormProps) => {
  const classes = useStyles();
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  const { t, i18n } = useTranslation("lang", { useSuspense: false });
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setOpen(true);
  };

  const hostName = (hostCountryCode: string) => {
    let returnValue = "";
    if (countries !== undefined) {
      if (countries[hostCountryCode] !== undefined) {
        returnValue =
          i18n.language === "en"
            ? countries[hostCountryCode].enName
            : countries[hostCountryCode].koName;
      } else {
        returnValue =
          i18n.language === "en"
            ? countries["KR"].enName
            : countries["KR"].koName;
      }
    }
    return returnValue;
  };

  return countries !== undefined && country !== undefined ? (
    <div className={classes.container}>
      <span className={classes.date}>
        {`${parallaxCalculation(
          startDate,
          "",
          countries[country.countryCode!] as any,
          "YYYY. MM. DD HH:mm"
        )} - ${parallaxCalculation(
          endDate,
          "",
          countries[country.countryCode!] as any,
          "YYYY. MM. DD HH:mm"
        )}`}
      </span>

      {isMobile ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            title={`(${hostName(hostCountryCode)}) ${`${parallaxCalculation(
              startDate,
              "",
              countries[hostCountryCode] as any,
              "YYYY. MM. DD HH:mm"
            )} - ${parallaxCalculation(
              endDate,
              "",
              countries[hostCountryCode] as any,
              "YYYY. MM. DD HH:mm"
            )}`}`}
            placement="bottom-end"
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClick={handleTooltipOpen}
          >
            <span
              style={{
                fontSize: "12px",
                color: "gray",
                cursor: "default",
                textDecoration: "underline",
              }}
            >
              {t(`eventDetail.hostTime`)}
            </span>
          </Tooltip>
        </ClickAwayListener>
      ) : (
        <Tooltip
          title={`(${hostName(hostCountryCode)}) ${`${parallaxCalculation(
            startDate,
            "",
            countries[hostCountryCode] as any,
            "YYYY. MM. DD HH:mm"
          )} - ${parallaxCalculation(
            endDate,
            "",
            countries[hostCountryCode] as any,
            "YYYY. MM. DD HH:mm"
          )}`}`}
          placement="bottom-end"
        >
          <span
            style={{
              fontSize: "15px",
              color: "rgba(0,0,0,0.6)",
              cursor: "default",
              textDecoration: "underline",
              textUnderlinePosition: "under",
            }}
          >
            {t(`eventDetail.hostTime`)}
          </span>
        </Tooltip>
      )}
    </div>
  ) : null;
};
export default CountryTimeForm;

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  date: {
    marginRight: "10px",
    fontSize: 17,
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
}));
