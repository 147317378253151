import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { Form, ProductProperty } from "../../../../types/models/Form";
import { useParams } from "react-router-dom";

import styled from "styled-components";
import { TypographyProps } from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Radio from "@material-ui/core/Radio";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import ShowFile from "../../../../components/File/ShowFile";
import TranslatedFormItem from "../../../form/TranslatedFormItem";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

type Props = paramProps;

interface paramProps {
  answerProcessing: any;
  additionalMaxCount: number;
}
const ApplicationAdditionalInfo: React.FC<Props> = (props) => {
  const params = useParams<{ id: string }>(); // subEvent id
  const { answerProcessing, additionalMaxCount } = props;

  const [answerData, setAnswerData] = useState<any>();
  const [questionData, setQuestionData] = useState<Form>();
  const formAnswers = useSelector(
    (state: AppState) => state.formAnswers.formAnswers
  );

  // 자유양식 질문 (셀러, 바이어 참가신청 양식)
  const forms = useSelector((state: AppState) => state.forms);

  useEffect(() => {
    if (forms.additional !== "" && forms.additional !== undefined) {
      const data: Form = {
        id: forms.id,
        type: forms.type,
        content: "",
        subEventId: parseInt(params.id),
      };
      setQuestionData({
        ...data,
        formData: JSON.parse(forms.additional!),
        additionalFormData: JSON.parse(forms.additional!),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);
  useEffect(() => {
    if (answerProcessing !== null) setAnswerData(answerProcessing);
  }, [answerProcessing]);

  const answerForm = (
    formId: string,
    additionOrderCount: string,
    question: any
  ) => {
    let content = <></>;
    if (question.secretCheck === "N") {
      switch (question.type) {
        case "single":
          content = answerFormRadio(formId, additionOrderCount);
          break;
        case "many":
          content = answerFormCheckBox(formId, additionOrderCount);
          break;
        case "file":
          content = answerFormFile(formId, additionOrderCount);
          break;
        case "product":
          content = answerProduct(formId, additionOrderCount);
          break;
        default:
          content = answerFormText(formId, additionOrderCount);
          break;
      }
    }

    return content;
  };

  const urlCheckUpdate = (url: string) => {
    if (!url.includes("http") || !url.includes("https")) {
      return "http://" + url;
    } else {
      return url;
    }
  };

  const answerProduct = (formId: string, additionOrderCount: string) => {
    const productId = `Y&&${additionOrderCount}&&${formId}&&product`;

    console.log(`product Answer : `, answerData[productId]);

    // const productInfo = JSON.parse(
    //   answerData[productId]?.content
    // ) as ProductProperty;

    const productName = answerData[productId].productName;
    const productExplain = answerData[productId].productExplain;
    const productImg = answerData[productId].productImg;
    const productLink = answerData[productId].productLink;

    const Container = styled.div`
      border: 1px solid #6c757d;
      border-radius: 7px;
      padding: 10px;
    `;

    return (
      <Container>
        <TranslatedFormItem
          type="input"
          name="productName"
          content="제품명"
          fullWidth
          link={true}
          fontsize={15}
          readOnly={true}
          fontweight="bold"
          onlyEng={false}
        />
        <OutlinedTxtField
          multiline={false}
          value={productName}
          inputProps={{
            style: { cursor: "default" },
          }}
          rows={2}
          fullWidth
        />
        <TranslatedFormItem
          type="input"
          name="productName"
          content="제품 설명"
          fullWidth
          link={true}
          fontsize={15}
          readOnly={true}
          fontweight="bold"
          onlyEng={false}
        />
        <OutlinedTxtField
          multiline={true}
          value={productExplain}
          inputProps={{
            style: { cursor: "default" },
          }}
          rows={10}
          fullWidth
        />
        <TranslatedFormItem
          type="input"
          name="productName"
          content="제품 이미지"
          fullWidth
          link={true}
          fontsize={15}
          readOnly={true}
          fontweight="bold"
          onlyEng={false}
        />
        {productImg !== 0 && <ShowFile fileId={productImg} />}
        <TranslatedFormItem
          type="input"
          name="productName"
          content="제품 링크"
          fullWidth
          link={true}
          fontsize={15}
          readOnly={true}
          fontweight="bold"
          onlyEng={false}
        />
        <OutlinedTxtField
          multiline={false}
          value={productLink}
          inputProps={{
            style: { cursor: "pointer" },
          }}
          rows={2}
          fullWidth
          onClick={() => {
            const urlValue = urlCheckUpdate(productLink);
            window.open(urlValue);
          }}
        />
      </Container>
    );
  };

  // 단순 문구 폼
  const answerFormText = (formId: string, additionOrderCount: string) => {
    const textValueList = questionData!.additionalFormData?.formData[formId]!;
    const textId = `Y&&${additionOrderCount}&&${formId}&&text`;
    const textType = answerData[textId]?.type;

    return (
      <Fragment>
        <SubTitle>
          <TranslatedFormItem
            content={textValueList.title!}
            type="string"
            link={false}
            onlyEng={false}
          />{" "}
        </SubTitle>
        <div>
          {textValueList.explain ? (
            <TranslatedFormItem
              content={textValueList.explain!}
              type="string"
              link={false}
              onlyEng={false}
            />
          ) : null}
        </div>
        <OutlinedTxtField
          multiline={textType === "long"}
          value={
            answerData[textId] !== undefined ? answerData[textId]?.content : ""
          }
          inputProps={{
            style: { cursor: textType === "url" ? "pointer" : "default" },
          }}
          onClick={() => {
            const urlValue = urlCheckUpdate(answerData[textId].content);
            textType === "url" && window.open(urlValue);
          }}
          fullWidth
          disabled
        />
      </Fragment>
    );
  };

  // 라디오 폼
  const answerFormRadio = (formId: string, additionOrderCount: string) => {
    const radioValueList = questionData!.additionalFormData?.formData[formId]!;

    return (
      <Fragment>
        <SubTitle>
          <TranslatedFormItem
            content={radioValueList.title!}
            type="string"
            link={false}
            onlyEng={false}
          />
        </SubTitle>
        <div>{radioValueList.explain ? radioValueList.explain : null}</div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            // onChange={handleChange}
          >
            {radioValueList.selectContent?.formIds.map(
              (formValueId: string, idx: number) => {
                const radioId = `Y&&${additionOrderCount}&&${formId}&&${formValueId}`;
                const radioData =
                  radioValueList.selectContent?.formData[formValueId]!;
                const checked =
                  radioData.explain === answerData[radioId]?.content;
                return (
                  checked && (
                    <FormControlLabel
                      key={formValueId + idx}
                      checked={checked}
                      value="disabled"
                      disabled={!checked}
                      control={<Radio />}
                      label={
                        <TranslatedFormItem
                          type="string"
                          content={radioData.explain!}
                          link={false}
                          onlyEng={false}
                        />
                      }
                    />
                  )
                );
              }
            )}
          </RadioGroup>
        </FormControl>
      </Fragment>
    );
  };

  // 체크박스 폼
  const answerFormCheckBox = (formId: string, additionOrderCount: string) => {
    const checkboxData = questionData!.additionalFormData?.formData[formId];
    const checkboxValueList = checkboxData!.selectContent;

    return (
      <Fragment>
        <SubTitle>
          <TranslatedFormItem
            type="string"
            content={questionData!.additionalFormData?.formData[formId].title!}
            link={false}
            onlyEng={false}
          />
        </SubTitle>
        <div>{checkboxData!.explain ? checkboxData!.explain : null}</div>
        {checkboxValueList?.formIds.map((formValueId: string, idx: number) => {
          const checkboxId = `Y&&${additionOrderCount}&&${formId}&&${formValueId}`;
          const checkboxData = checkboxValueList.formData[formValueId]!;
          const checked =
            answerData[checkboxId]?.content === checkboxData.explain!;
          return (
            checked && (
              <FormControlLabel
                key={formValueId + idx}
                control={<Checkbox />}
                checked={checked}
                disabled={!checked}
                label={
                  <TranslatedFormItem
                    content={checkboxData.explain!}
                    type="string"
                    link={false}
                    onlyEng={false}
                  />
                }
              />
            )
          );
        })}
      </Fragment>
    );
  };

  // 파일 폼
  const answerFormFile = (formId: string, additionOrderCount: string) => {
    const fileData = questionData!.additionalFormData?.formData[formId];
    const title = fileData!.title;
    const fileId = `Y&&${additionOrderCount}&&${formId}&&file`;
    const content =
      answerData[fileId] !== undefined ? answerData[fileId].content : "";

    return (
      <Fragment>
        <SubTitle>
          <TranslatedFormItem
            content={title!}
            type="string"
            link={false}
            onlyEng={false}
          />
        </SubTitle>
        <div>
          {fileData!.explain ? (
            <TranslatedFormItem
              content={fileData!.explain!}
              type="string"
              onlyEng={false}
              link={false}
            />
          ) : null}
        </div>
        <ShowFile fileId={content} />
      </Fragment>
    );
  };

  return questionData !== undefined &&
    formAnswers !== undefined &&
    answerData !== undefined ? (
    <>
      {Array.from(
        { length: additionalMaxCount + 1 },
        (x, orderCount: number) => {
          return (
            <div
              style={{
                paddingBottom: 10,
              }}
            >
              {/* <h3
                style={{
                  padding: "40px 0px 0px",
                  color: "grey",
                  fontWeight: "bold",
                }}
              >
                추가정보 {orderCount + 1}
              </h3> */}
              {questionData.additionalFormData!.formIds!.map((formId) =>
                answerForm(
                  formId,
                  orderCount.toString(),
                  questionData.additionalFormData!.formData[formId]!
                )
              )}
            </div>
          );
        }
      )}
    </>
  ) : null;
};
export default ApplicationAdditionalInfo;
