import React, {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store";
import MeetingTimeCard from "./MeetingTimeCard";
import styled from "styled-components";
import {
  MeetingData,
  MeetingTimeInfo,
} from "../../../../types/models/SubEvent";
import { Box, Grid } from "@material-ui/core";
import { parallaxCalculation } from "../../../../utils/momentTz";
import { useTranslation } from "react-i18next";
import {
  MeetingApplicantDataInterface,
  MyMeetingContext,
} from "./MeetingDetail";
import { useParams } from "react-router-dom";
import {
  impossibleMeetingList,
  getMyMeetings,
  removeUserImpossibleDay,
  RemoveUserImpossibleDayInterface,
} from "../../../../actions/meeting";
import { confirmModalOptions } from "../../../../utils/confirmModalOptions";
import { confirmAlert } from "react-confirm-alert";
import { removeLoading, setLoading } from "../../../../actions/loading";
import moment from "moment";
import { CountryApiInfo, CountryInfo } from "../../../../types/models/Country";
import Body1Typo from "../../../../components/Typhography/Body1Typo";

interface MeetingDataInterface {
  date: string;
  endTime: string;
  startTime: string;
  id: string;
  impossibleAt: string;
  showEndTime: string;
  showStartTime: string;
  showHostMeetingTime: string;
  showDate: string;
}

const MeetingTimeForm = ({
  meetingStatus,
  date,
  meetingSchedule,
}: {
  meetingStatus: string;
  date: moment.Moment;
  meetingSchedule: Dispatch<SetStateAction<MeetingData[]>>;
}) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const param: { id: string } = useParams();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [meetingData, setMeetingData] = useState<MeetingData[]>([]);
  const { myMeetingCheckList, secondId, disableStatus, disableLiftStatus } =
    useContext(MyMeetingContext);
  const dispatch = useDispatch();
  const userId = useSelector((state: AppState) => state.users.id);
  const [checkStatus, setCheckStatus] = useState<string[]>([]);

  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  useEffect(() => {
    if (subEvent) {
      // 국가 코드
      const countryCode = subEvent.mainEvent?.countryCode;
      // 서브이벤트 시작 날짜
      const subEventStartDate = parallaxCalculation(
        `${subEvent.subEventStartDate.split("T")[0]}T${
          subEvent.matchStartTime
        }`,
        countries![countryCode! as string] as any,
        countries![country!.countryCode!] as any
      );

      //현지시간
      let localStartOfWeek = date
        .clone()
        .startOf("week")
        .isSameOrAfter(subEventStartDate)
        ? date.clone().startOf("week")
        : moment(subEventStartDate); // subEventStartDate(현지날짜 변경되어야함) 보다 전날이면 안됨

      const endOfWeek = date
        .clone()
        .endOf("week")
        .isSameOrBefore(subEvent.subEventEndDate)
        ? date.clone().endOf("week")
        : moment(subEvent.subEventEndDate); // subEventEndDate(현지날짜 변경되엉야함) 보다 이후면 안됨
      let serverTimeStartOfWeek = moment(
        parallaxCalculation(
          localStartOfWeek.format("YYYY-MM-DDTHH:ss"),
          countries![country!.countryCode!] as any,
          ""
        )
      );
      //고려 사항 ------------------------------------
      // date와 meeting 시간들을 맞춰줘야함 나라별롤 다르기때문에
      // 날짜 container 만들기
      // -------------------------------------------

      // 나라별 시간을 따로 보여주기위해 showDate, showStartTime, showEndTime 추가

      let newTimeMeeting: MeetingData = {};
      let newTimeMeetingArr: MeetingData[] = [];
      // 최대 7일치 만들기

      for (
        let d =
          endOfWeek.diff(localStartOfWeek, "days") + 1 >= 8
            ? 7
            : endOfWeek.diff(localStartOfWeek, "days") + 1;
        d > 0;
        d--
      ) {
        const newTImes = [
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(-1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            countries!,
            countryCode!,
            country!,
            subEvent.impossibleDateAndTime
          ),
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            countries!,
            countryCode!,
            country!,
            subEvent.impossibleDateAndTime
          ),
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            countries!,
            countryCode!,
            country!,
            subEvent.impossibleDateAndTime
          ),
        ];

        newTimeMeeting = {
          date: serverTimeStartOfWeek.add(-1, "days").format("YYYY-MM-DD"), //한국시간
          showDate: localStartOfWeek.format("YYYY-MM-DD"), // 현지시간
          time: newTImes.filter(
            (d) => d.showDate == localStartOfWeek.format("YYYY-MM-DD")
          ),
        };
        localStartOfWeek.add(1, "days");
        serverTimeStartOfWeek.add(1, "days");
        newTimeMeetingArr.push(newTimeMeeting);
      }
      
      setMeetingData(newTimeMeetingArr);
      meetingSchedule(newTimeMeetingArr);
      // setMeetingData(newMeetings);
    }
  }, [date]);

  // 미팅 거부 Day 추가
  const dayBlockBtn = async (day: MeetingDataInterface[]) => {
    dispatch(setLoading());
    const data: MeetingApplicantDataInterface[] = day
      .filter(
        (tm: MeetingDataInterface) =>
          !myMeetingCheckList[`${tm.date}${tm.startTime}${tm.endTime}`]
      )
      .map(function (item: MeetingDataInterface) {
        return {
          subEventId: param.id, // 서브 이벤트
          applicant: secondId, // 신청자(application id)
          acceptor: "", // 수락자(application id)
          meetingId: item.date + item.startTime + item.endTime, // 미팅시간 key;
          status: "impossible", // 상태 [대기: waiting, 동의: agree, 비동의: disagree, 불가설정: impossible]
          date: item.date,
          startTime: item.startTime,
          endTime: item.endTime,
        };
      });
    const result: any = await dispatch(impossibleMeetingList(data));
    if (result === true) {
      dispatch(getMyMeetings(secondId));
    }
    dispatch(removeLoading());
  };

  //미팅 거부 day 제거
  const dayUnBlockBtn = async (day: string) => {
    const data: RemoveUserImpossibleDayInterface = {
      subEventId: param.id,
      date: day,
      applicantId: secondId,
      userId: userId!,
    };
    dispatch(setLoading());
    const result: any = await dispatch(removeUserImpossibleDay(data));
    if (result === true) {
      dispatch(getMyMeetings(secondId));
    }
    dispatch(removeLoading());
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    date: string
  ) => {
    if (checkStatus.some((item) => item == date)) {
      setCheckStatus((prevStatus) => {
        return prevStatus.filter((item) => item !== date);
      });
    } else {
      setCheckStatus((prevStatuses) => {
        return [...prevStatuses, date];
      });
    }
  };

  useEffect(() => {
    if (!disableStatus && !disableLiftStatus) {
      setCheckStatus([]);
    }
  }, [disableStatus, disableLiftStatus]);

  return meetingData !== undefined ? (
    <>
      {/* 미팅 상태 필터 */}
      <div style={{ marginBottom: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
        </Grid>
      </div>
      <MeetingForm>
        {/* 날짜 container  */}
        {Array.isArray(meetingData) &&
          meetingData.map((meeting: MeetingData, dateIndex: number) => {
            const other: any = meeting.time!.filter(
              (tm: any) => tm.impossibleAt === "N"
            );

            // 해당날짜에 참가자가 매칭신청중,매칭, 불가 신청했는지 검사
            const check = other.filter(
              (tm: MeetingDataInterface) =>
                myMeetingCheckList[`${tm.date}${tm.startTime}${tm.endTime}`]
            );

            let check2;
            try {
              //해당 날짜 중에 참가자가 불가설정한 시간이 있는지 검사 //trycatch 없으면 에러남
              check2 = other.some(
                (tm: MeetingDataInterface) =>
                  myMeetingCheckList[
                    `${tm.date}${tm.startTime}${tm.endTime}`
                  ][0].status === "impossible"
              );
            } catch (e) {
              check2 = false;
            }
            return (
              <div
                key={`metting-card-${dateIndex}`}
                style={{ maxWidth: "15rem", minWidth: "15rem" }}
              >
                <MeetingFormData>
                  <Body1Typo fontSize="16px" fontWeight="500">
                    {meeting.showDate}
                  </Body1Typo>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="flex-end"
                    maxWidth="150px"
                  >
                    {disableStatus && (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleCheckboxChange(e, meeting.date as string);
                        }}
                      />
                    )}
                    {disableLiftStatus && (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleCheckboxChange(e, meeting.date as string);
                        }}
                      />
                    )}
                    {/* 미팅 불가 설정 */}
                    {/* {meeting.time!.find((tm) => tm.impossibleAt === "N") &&
                      other.length !== check.length && (
                        <DayBlockBtn
                          disabled={
                            subEvent?.meetingAvailable === "N" ? true : false
                          }
                          onClick={() =>
                            confirmAlert(
                              confirmModalOptions({
                                title: t("meetingDetail.noMeetingSetting?"),
                                click: () => dayBlockBtn(other),
                              })
                            )
                          }
                        >
                          {t("meetingDetail.matchingDisabled")}
                        </DayBlockBtn>
                      )} */}
                    {/* 미팅 불가 해제 설정 */}
                    {/* {check2 && (
                      <DayBlockBtn
                        disabled={
                          subEvent?.meetingAvailable === "N" ? true : false
                        }
                        color="rgba(0,0,0,0.08)"
                        onClick={() =>
                          confirmAlert(
                            confirmModalOptions({
                              title: t("meetingDetail.cancelNoMeetingSeeting?"),
                              click: () => dayUnBlockBtn(meeting.showDate!),
                            })
                          )
                        }
                      >
                        {t("meetingDetail.matchingAbled")}
                      </DayBlockBtn>
                    )} */}
                  </Box>
                </MeetingFormData>

                <MeetingFormTime>
                  {meeting.time!.map((tm: any, timeIndex: number) => (
                    <>
                      <MeetingTimeCard
                        key={`metting-time-card-${timeIndex}`}
                        id={`${tm.date}${tm.startTime}${tm.endTime}`}
                        date={tm.date} // 한국시간
                        startTime={tm.startTime} // 한국시간
                        endTime={tm.endTime} // 한국시간
                        showDate={meeting.showDate!} //국가별 시간
                        showStartTime={tm.showStartTime} //국가별 시간
                        showEndTime={tm.showEndTime} //국가별 시간
                        impossibleAt={tm.impossibleAt}
                        meetingStatus={meetingStatus}
                        dayChecked={checkStatus}
                      />
                    </>
                  ))}
                </MeetingFormTime>
              </div>
            );
          })}
      </MeetingForm>
    </>
  ) : null;
};

export default MeetingTimeForm;

const MeetingForm = styled.div`
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
  &::-webkit-scrollbar-y {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  /* -ms-overflow-style: none; */
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(14.88rem, 15rem));
`;
const MeetingFormData = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9375rem;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.6);
`;
const MeetingFormTime = styled.div`
  display: grid;
  grid-gap: 8px;
  width: 100%;
`;

const DayBlockBtn = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 0.6rem;
  border-radius: 3px;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  background-color: ${(props) =>
    props.color !== undefined ? props.color : "transparent"};
  float: right;
  &:hover {
    outline: none;
    background-color: ${(props) =>
      props.color !== undefined ? props.color : "transparent"};
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

function timeArrGenerator(
  subEventStartDate: string,
  subEventEndDate: string,
  startDate: string,
  startTime: string,
  reqTime: number,
  breakTime: number,
  numPerDay: number,
  countries: CountryInfo,
  countryCode: string,
  country: CountryApiInfo,
  impossibleDateAndTime: string | undefined
): MeetingTimeInfo[] {
  // timecard 만들어주는 함수 -----
  // 서브이벤트 시작날
  // 서브이벤트 시작시간
  // 서브이벤트 끝나는날
  // 미팅 소요시간
  // 미팅 쉬는시간
  // 미팅 횟수
  // --------------------------
  // subEvent , countrycode 변경될때마다 업데이트

  let meetingTimeList: MeetingTimeInfo[] = []; // 필요데이터 {시작시간, 종료시간, 불가여부}
  // 날짜별 매칭시간 기준 값
  let impossibleStartTime = moment(`${startDate.split("T")[0]} ${startTime}`);

  if (impossibleStartTime.isBefore(subEventStartDate.split("T")[0]))
    return meetingTimeList;
  if (
    impossibleStartTime.isAfter(`${subEventEndDate.split("T")[0]}T${startTime}`)
  )
    return meetingTimeList;
  const disabledDateAndTime = impossibleDateAndTime
    ? JSON.parse(impossibleDateAndTime)
    : {}; //{년 : {월 : [day_time]}}
  let localStartTime = moment(
    parallaxCalculation(
      impossibleStartTime.format("YYYY-MM-DDTHH:mm:ss"),
      countries![countryCode as string] as any,
      countries![country!.countryCode!] as any
    )
  );
  // 날짜별 매칭시간 조회 (시작시간, 종료시간, 불가여부)
  Array.from({ length: numPerDay || 0 }, (x, i) => {
    meetingTimeList.push({
      startTime: impossibleStartTime.format("HH:mm"),
      impossibleAt: checkDisabledTime(impossibleStartTime, disabledDateAndTime), // 주최자 지정 불가시간
      endTime: impossibleStartTime.add(reqTime, "m").format("HH:mm"),
      date: impossibleStartTime.format("YYYY-MM-DD"),
      showDate: localStartTime.format("YYYY-MM-DD"),
      showStartTime: localStartTime.format("HH:mm"),
      showEndTime: localStartTime.add(reqTime, "m").format("HH:mm"),
    });
    localStartTime.add(breakTime, "m");
    impossibleStartTime = impossibleStartTime.add(breakTime, "m");
  });

  return meetingTimeList;
}

function checkDisabledTime(
  serverTime: moment.Moment,
  disabledDateAndTime: any
): "N" | "Y" {
  const [year, month, day] = serverTime.format("YYYY-MM-DD").split("-");
  if (
    disabledDateAndTime.hasOwnProperty(year) &&
    disabledDateAndTime[year].hasOwnProperty(month) &&
    disabledDateAndTime[year][month].length > 0 &&
    disabledDateAndTime[year][month].some(
      (d: string) => d == `${day}_${serverTime.format("HH:mm")}`
    )
  ) {
    return "Y";
  }
  return "N";
}

// array.forEach((meetings: MeetingData, index: number) => {
//   meetings!.time!.forEach((time: MeetingTimeInfo, idx) => {
//     // 주최지 시간
//     const hostMeetingTime = `${meetings.date} ${time.startTime} ~ ${time.endTime}`;

//     // 국가에 맞는 시작시간 조회
//     const countryStartTime = parallaxCalculation(
//       `${meetings.date}T${time.startTime}`,
//       countries![countryCode! as string] as any,
//       countries![country!.countryCode!] as any,
//       "YYYY-MM-DD HH:mm"
//     ) as string;

//     // 국가에 맞는 종료시간 조회
//     const countryEndTime = parallaxCalculation(
//       `${meetings.date}T${time.endTime}`,
//       countries![countryCode! as string] as any,
//       countries![country!.countryCode!] as any,
//       "YYYY-MM-DD HH:mm"
//     ) as string;

//     const splitStartTime = countryStartTime.split(" ");
//     const splitEndTime = countryEndTime.split(" ");

//     // 국가에맞는 새로운 시간 저장
//     let newMeetinTime: MeetingTimeInfo = time;

//     Object.assign(newMeetinTime, {
//       ...newMeetinTime,
//       showStartTime: splitStartTime[1],
//       showEndTime: splitEndTime[1],
//       showHostMeetingTime: hostMeetingTime,
//       date: meetings.date,
//     });

//     // 처음일경우 시간지정
//     if (prevDate === undefined) {
//       prevDate = splitStartTime[0];
//       newMeeting = {
//         date: meetings.date,
//         showDate: prevDate,
//         time: newMeetinTimes,
//       };
//     }

//     nextDate = splitStartTime[0];
//     if (prevDate !== nextDate) {
//       Object.assign(newMeeting, { time: newMeetinTimes });

//       newMeetings.push(newMeeting);
//       newMeetinTimes = [newMeetinTime];
//       newMeeting = {
//         date: meetings.date,
//         showDate: nextDate,
//         time: newMeetinTimes,
//       };

//       prevDate = nextDate;
//     } else {
//       newMeetinTimes.push(newMeetinTime);
//     }
//     if (
//       array.length === index + 1 &&
//       meetings!.time!.length === idx + 1
//     ) {
//       Object.assign(newMeeting, { time: newMeetinTimes });

//       newMeetings.push(newMeeting);
//     }
//   });
// });
