import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ProductGridContainer from "../../../../components/Grid/ProductGrid/ProductGridContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
  height: 36px;
  font-size: 24px;
  font-weight: 500;
  padding-left: 12px;
  margin-bottom: 40px;
  color: rgba(0 0 0, 0.87);
  &::before {
    position: absolute;
    left: 0;
    width: 4px;
    height: 28px;
    transform: translate(0%, 20%);
    content: "";
    border: 2px solid rgb(81, 45, 168);
    border-radius: 2px;
  }
`;

const ProductList = () => {
  const [t] = useTranslation("lang", { useSuspense: false });

  return (
    <Container>
      <Title>{t("product.title")}</Title>
      <ProductGridContainer />
    </Container>
  );
};

export default ProductList;
