import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import PxGridItem from "../../components/Grid/PxGridItem";
import Body1Typo from "../../components/Typhography/Body1Typo";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import { SubEventInfo } from "../../types/models/SubEvent";
import "moment/locale/ko";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 20px",
    },
  },
  cardImage: {
    width: 200,
    maxHeight: 145,
    objectFit: "fill",
    [theme.breakpoints.down("xs")]: {
      width: 120,
      maxHeight: 110,
    },
  },
  cardDetails: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

interface EventBannerProps {
  subEvent: SubEventInfo;
}
const EventBanner = ({ subEvent }: EventBannerProps) => {
  const classes = useStyles();
  const { i18n } = useTranslation("lang", { useSuspense: false });
  const history = useHistory();

  useEffect(() => {
    console.log(`subEvent : `, subEvent);
  }, [subEvent]);

  return (
    <PxGridItem fullwidth>
      <Card
        className={classes.cardRoot}
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.goBack();
        }}
      >
        <CardMedia
          component="img"
          className={classes.cardImage}
          image={
            subEvent.mainEvent?.image
              ? subEvent.mainEvent?.image
              : subEvent.mainEvent?.mainImageFile.filePath
          }
          alt={subEvent.mainEvent?.name + "Main Image"}
        />
        <div className={classes.cardDetails}>
          <CardContent>
            <SubHeadingTypo fontweight="bold">
              {i18n.language === "ko"
                ? subEvent.mainEvent?.name
                : subEvent.mainEvent?.nameEn ?? subEvent.mainEvent?.name}
            </SubHeadingTypo>
            <Body1Typo txtcolor="secondary">
              {`${moment(subEvent.mainEvent?.startDate).format(
                "yyyy-MM-DD"
              )} ~ ${moment(subEvent.mainEvent?.endDate).format("yyyy-MM-DD")}`}
            </Body1Typo>
          </CardContent>
        </div>
      </Card>
    </PxGridItem>
  );
};

export default EventBanner;
