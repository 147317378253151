import Notice from "../types/models/Notice";
import NoticeActionTypes from "../types/actions/NoticeAction";

const noticeDefaultState: Notice = {
  notice: {
    subEventId: "", // 서브이벤트 id
    title: "", // 제목
    content: "", // 내용
  },
  notices:  {
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
  },
};

const noticesReducer = (
  state = noticeDefaultState,
  action: NoticeActionTypes
): Notice => {
  switch (action.type) {
    case "GET_NOTICE":
      return { ...state, notice: action.notice };
    case "GET_NOTICES":
      return {
        notices: action.notices,
      };
    default:
      return state;
  }
};

export default noticesReducer;
