import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { ReactNode } from "react";

interface PxSlideDialogProps {
  title?: string;
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  className?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PxSlideDialog = ({
  open,
  children,
  title,
  onClose,
  className,
}: PxSlideDialogProps) => {
  return (
    <Dialog
      className={className}
      open={open}
      maxWidth="xs"
      onClose={onClose}
      TransitionComponent={Transition}
    >
      {title !== undefined && (
        <DialogTitle style={{ textAlign: "center", padding: "10px 24px" }}>
          {title}
        </DialogTitle>
      )}
      {children}
    </Dialog>
  );
};

export default PxSlideDialog;
