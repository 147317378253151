import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createSurveyFormAnswer,
  getSurveyAnswer,
  updateSurveyFormAnswer,
} from "../../../../actions/formAnswers";
import { getForm } from "../../../../actions/forms";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../../actions/loading";
import PxButton from "../../../../components/Buttons/PxButton";
import PxContainer from "../../../../components/Containers/PxContainer";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import TitleTypo from "../../../../components/Typhography/TitleTypo";
import useSubEventInfo from "../../../../hooks/useSubEventInfo";
import { AppState } from "../../../../store";
import {
  SurveyCreate,
  CounselFormAnswer as SurveyFormAnswer,
} from "../../../../types/models/CounselForm";
import { FormData } from "../../../../types/models/Form";
import SurveyDetail from "./SurveyDetail";

const Survey = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const subEventInfo = useSubEventInfo();

  const [surveyForm, setSurveyForm] = useState<FormData>();
  const [surveyFormAnswers, setSurveyFormAnswers] =
    useState<SurveyFormAnswer[]>();
  const [modify, setModify] = useState<boolean>(true);

  const form = useSelector((state: AppState) => state.forms);
  const surveyAnswers = useSelector(
    (state: AppState) => state.formAnswers.surveyAnswers
  );

  useEffect(() => {
    if (subEventInfo?.surveyForm) {
      dispatch(getForm(subEventInfo.surveyForm.toString()));
    }
    if (subEventInfo?.applicationId) {
      dispatch(getSurveyAnswer(subEventInfo?.applicationId));
    }
  }, [dispatch, subEventInfo]);

  useEffect(() => {
    if (form.id) {
      const formData = JSON.parse(form.content! as string);
      setSurveyForm(formData);
    }
  }, [form.content, form.id]);

  useEffect(() => {
    if (surveyAnswers?.length !== 0) {
      setSurveyFormAnswers(surveyAnswers);
    }
  }, [surveyAnswers]);

  // 저장 onClick method, 작성한 상담일지 저장 api call
  const onSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch(setLoadingAction());
    const data: SurveyFormAnswer[] = [];
    const checkBoxData: SurveyFormAnswer[] = [];
    // 작성 된 자율양식 데이터를 서버로 보내기전 알맞은 형태로 가공
    surveyForm!.formIds.forEach((formUuid) => {
      const surveyFormAnswer: SurveyFormAnswer = {
        id: surveyForm!.formData[formUuid].defaultId ?? "",
        title: surveyForm!.formData[formUuid].title ?? "",
        type: surveyForm!.formData[formUuid].type,
        uuid: formUuid,
        formId: form.id!,
      };

      switch (surveyForm!.formData[formUuid].type) {
        case "single":
          surveyFormAnswer["content"] =
            surveyForm!.formData[formUuid].radioContent?.content;
          surveyFormAnswer["answerUuid"] =
            surveyForm!.formData[formUuid].radioContent?.id;
          data.push(surveyFormAnswer);
          break;
        case "many":
          surveyForm!.formData[formUuid].checkBoxContent?.forEach(
            ({ id, content }, index) => {
              const checkBoxForm: SurveyFormAnswer = {
                id: surveyForm!.formData[formUuid].defaultId ?? "",
                title: surveyForm!.formData[formUuid].title ?? "",
                type: surveyForm!.formData[formUuid].type,
                uuid: formUuid,
                formId: form.id!,
              };
              checkBoxForm["content"] = content;
              checkBoxForm["answerUuid"] = id;
              checkBoxData.push(checkBoxForm);
            }
          );
          break;
        case "file":
          surveyFormAnswer["content"] =
            surveyForm!.formData[formUuid].fileContent?.fileId;
          data.push(surveyFormAnswer);
          break;
        case "star":
          surveyFormAnswer["content"] = surveyForm!.formData[formUuid]
            .content as string;
          data.push(surveyFormAnswer);
          break;
        default:
          surveyFormAnswer["content"] = surveyForm!.formData[formUuid]
            .content as string;
          data.push(surveyFormAnswer);
          break;
      }
    });

    const answerData = data.concat(checkBoxData);

    const survey: SurveyCreate = {
      applicationId: Number(subEventInfo?.applicationId),
      postSurveys: answerData,
    };

    let result: any;
    // 기존 등록한 답변이 있을 경우 update, 없을 경우 create
    if (surveyAnswers) {
      result = await dispatch(updateSurveyFormAnswer(survey));
    } else {
      result = await dispatch(createSurveyFormAnswer(survey));
    }

    dispatch(removeLoadingAction());

    if (result) {
      setModify(false);
    }
  };

  return (
    <PxContainer maxWidth="sm">
      <PxButton
        backgroundcolor={modify ? "grey" : "purple"}
        onClick={() => {
          setModify(!modify);
        }}
        style={{ float: "right" }}
      >
        <ButtonTypo>{modify ? "취소" : "수정"}</ButtonTypo>
      </PxButton>
      <PxGridContainer justify="center">
        <TitleTypo>
          {subEventInfo?.groupName} {t("survey.survey")}
        </TitleTypo>
        <SurveyDetail
          modify={!modify}
          surveys={surveyFormAnswers}
          applicationId={subEventInfo?.applicationId}
          surveyForm={surveyForm}
          setSurveyForm={setSurveyForm}
        />
        {modify && (
          <PxButton backgroundcolor="purple" onClick={onSubmit}>
            <ButtonTypo>{t("survey.save")}</ButtonTypo>
          </PxButton>
        )}
      </PxGridContainer>
    </PxContainer>
  );
};

export default Survey;
