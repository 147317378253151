import { AppActions } from "../store";
import { RoutePath } from "../types/models/RoutePath";
import { Dispatch } from "react";

export const setRoutePathAction = (routePath: RoutePath): AppActions => ({
  type: "SET_ROUTE_PATH",
  routePath: routePath,
});

export const removeRoutePathAction = (): AppActions => ({
  type: "REMOVE_ROUTE_PATH",
});

export const setRoutePath = (routePath: RoutePath) => (
  dispatch: Dispatch<AppActions>
) => {
  dispatch(setRoutePathAction(routePath));
};
