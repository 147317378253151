import React, { useEffect } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import Alert from "../../types/models/Alert";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { Button } from "@material-ui/core";

type PxAlertProps = ProviderContext & reduxStateProps;

const PxAlert: React.FC<PxAlertProps> = (props) => {
  const { enqueueSnackbar, closeSnackbar, alerts } = props;

  const action = (key: string | number) => (
    <>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
        style={{ color: "white" }}
      >
        확인
      </Button>
    </>
  );

  useEffect(() => {
    alerts.forEach((alert) => {
      enqueueSnackbar(alert.msg, {
        variant: alert.alertType,
        autoHideDuration: 1500,
        preventDuplicate: true,
        action,
      });
    });
  }, [alerts]); // eslint-disable-line

  return null;
};

interface reduxStateProps {
  alerts: Alert[];
}

const mapStateToProps = (state: AppState): reduxStateProps => ({
  alerts: state.alerts,
});

export default withSnackbar(connect(mapStateToProps)(PxAlert));
