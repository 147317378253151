import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotice, getNotices } from "../../../../actions/notice";
import { useParams, useHistory } from "react-router-dom";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import { AppState } from "../../../../store";
import TitleTypo from "../../../../components/Typhography/TitleTypo";
import styled from "styled-components";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import clsx from "clsx";

import {
  ArrowBack as ArrowBackIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@material-ui/icons";
import PageNotFound from "../../../../components/NoResult/PageNotFound";

const NoticeDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: subEventID, secondId }: { id: string; secondId: string } = useParams(); // 공지사항 id
  const { notice: content, notices } = useSelector((state: AppState) => state.notices);
  const [next, setNext] = useState<boolean>(false);
  const [prev, setPrev] = useState<boolean>(false);
  const [nextPath, setNextPath] = useState<string>("");
  const [prevPath, setPrevPath] = useState<string>("");
  const [index, setIndex] = useState<number>(0);
  const [axiosRequest, setAxiosRequest] = useState<boolean>(true);

  useEffect(() => {
    if (notices && notices.content.length <= 0) {
      dispatch(getNotices(subEventID));
      const index = notices!.content.findIndex((c) => c.id === parseInt(secondId));
      if (index >= 0) {
        setIndex(index);
      }
    }
  }, [subEventID]);

  useEffect(() => {
    const dataDispatch = async () => {
      const result: any = await dispatch(getNotice(secondId));
      setAxiosRequest(result);
    };
    dataDispatch();
    const index = notices!.content.findIndex((c) => c.id === parseInt(secondId));
    if (index >= 0) {
      setIndex(index);
    }
  }, [secondId]);

  useEffect(() => {
    if (notices!.content.length > index + 1 && notices!.content.length !== 0) {
      setNext(true);
      setNextPath(`/subEvent/${subEventID}/noticeList/${notices!.content[index + 1].id}`);
    } else {
      setNext(false);
    }
    if (index > 0 && notices!.content.length !== 0) {
      setPrev(true);
      setPrevPath(`/subEvent/${subEventID}/noticeList/${notices!.content[index - 1].id}`);
    } else {
      setPrev(false);
    }
  }, [content]);

  const nextBtn = () => {
    if (next) {
      history.replace(nextPath);
    }
  };
  const prevBtn = () => {
    if (prev) {
      history.replace(prevPath);
    }
  };

  return content !== undefined && content.title ? (
    <PxGridContainer>
      <PxGridItem fullwidth>
        <ArrowBackIcon className={classes.arrowBackIcon} onClick={() => history.goBack()} />
        <PageController>
          <ArrowBackIosIcon
            className={clsx(classes.arrowBackIcon, {
              [classes.disableIcon]: !prev,
            })}
            style={{ padding: 14 }}
            onClick={() => prevBtn()}
          />
          <ArrowForwardIosIcon
            className={clsx(classes.arrowBackIcon, {
              [classes.disableIcon]: !next,
            })}
            style={{ padding: 14 }}
            onClick={() => nextBtn()}
          />
        </PageController>
      </PxGridItem>

      <PxGridItem className={classes.companyInfoGridItem}>
        <TitleTypo gutterBottom>{content.title}</TitleTypo>
        <PxGridContainer direction="row">
          <Body1Typo txtcolor="black0.38" style={{ alignSelf: "center" }}>
            {moment(content.createdDate).format("YYYY-MM-DD")}
          </Body1Typo>
        </PxGridContainer>
        <div
          style={{
            marginTop: 20,
            wordBreak: "normal",
          }}
        >
          <ContentDiv dangerouslySetInnerHTML={{ __html: content.content }} />
        </div>
      </PxGridItem>
    </PxGridContainer>
  ) : axiosRequest ? null : (
    <PageNotFound />
  );
};

export default NoticeDetail;

const PageController = styled.div`
  float: right;
`;

const ContentDiv = styled.div`
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.75;
  img {
    max-width: 100%;
  }
`;

const useStyles = makeStyles((theme) => ({
  arrowBackIcon: {
    cursor: "pointer",
    width: 48,
    height: 48,
    fontSize: "1rem",
    padding: 12,
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  companyInfoGridItem: {
    paddingTop: 20,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  disableIcon: {
    color: "rgba(0,0,0, 0.38)",
    cursor: "unset",
  },
}));
