// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react";

import MeetingRoster from "../MeetingRoster";
import Navigation from ".";
import { useNavigation } from "../../providers/NavigationProvider";
import Chat from "../Chat";

const NavigationControl = () => {
  const { showNavbar, showRoster, showChat } = useNavigation();

  return (
    <>
      {showNavbar ? <Navigation /> : null}
      {showRoster ? <MeetingRoster /> : null}
      {showChat ? <Chat /> : null}
    </>
  );
};

export default NavigationControl;
