import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

// types
import { EventInfo } from "../../types/models/Event";

// material ui custom comp
import GridContainer from "../../components/Grid/PxGridContainer";
import GridItem from "../../components/Grid/PxGridItem";
import PxButton from "../../components/Buttons/PxButton";

import HeadlineTypo from "../../components/Typhography/HeadlineTypo";
import ButtonTypo from "../../components/Typhography/ButtonTypo";

import CardGridContainer from "../../components/Grid/CardGrid/CardGridContainer";
import { getMyApprovedEventList } from "../../actions/users";
import { AppState } from "../../store";
import Body1Typo from "../../components/Typhography/Body1Typo";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
`;

const Empty = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;

const EventList = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState({ page: 0, last: false });
  const [arr, setArr] = useState<Array<EventInfo>>([]);
  useEffect(() => {
    // dispatch(getMyLikeList());
    dispatch(getMyApprovedEventList());
  }, [history, dispatch]);
  if (!localStorage.token) {
    history.push("/auth/login");
  }
  const eventList = useSelector(
    (state: AppState) => state.users.approvedEventList
  );

  useEffect(() => {
    setArr([...eventList!.content!]);
    setPage({ page: eventList!.number!, last: eventList!.last! });
  }, [eventList]);
  useEffect(() => {
    if (eventList!.number === 0 && eventList!.content!.length <= 0) {
      dispatch(getMyApprovedEventList());
    }
  }, []);
  const addEvent = async (data: EventInfo[]) => {
    const result: any = await dispatch(
      getMyApprovedEventList(page.page + 1, data)
    );
    if (result) {
      // setArr([...result.content!]);
      setPage({ page: page.page + 1, last: page.last });
    }
  };

  const moreEventBtn = async (
    e: React.MouseEvent<HTMLButtonElement> | React.FC
  ) => {
    addEvent(arr);
  };

  return (
    <Container>
      <GridContainer
        justify="space-between"
        alignItems="baseline"
        // parent={true}
      >
        <GridItem>
          <GridContainer direction="row">
            <GridItem className="pb-1">
              <HeadlineTypo>{t("myEvents.myEvents")}</HeadlineTypo>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Contents>
          {(eventList && eventList.content.length <= 0) ||
          eventList === undefined ? (
            <Empty>
              {t("myEvents.emptyList")}
              <br />
            </Empty>
          ) : (
            <CardGridContainer eventList={arr}></CardGridContainer>
          )}
        </Contents>
      </GridContainer>

      <GridContainer justify="center" className="p-4">
        {!page.last && (
          <PxButton
            backgroundcolor="transparent"
            border={false}
            onClick={moreEventBtn}
          >
            <ButtonTypo txtcolor={"purple"}>{t("home.more")}</ButtonTypo>
          </PxButton>
        )}
      </GridContainer>
    </Container>
  );
};

export default EventList;
