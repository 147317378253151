import React, { useContext } from "react";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import { ChatContext } from "../ChatProvider/ChatProvider";
import clsx from "clsx";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import ChatIcon from "@material-ui/icons/Chat";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@keyframes blinker": {
      "0%": { backgroundColor: "#e0e0e0" },
      "50%": { backgroundColor: "red" },
      "100%": { backgroundColor: "#e0e0e0" },
    },
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
    fabAnimation: {
      animationName: "$blinker",
      animationDuration: "1s",
      animationIterationCount: "5",
    },
  })
);

export default function ToggleChatButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const { toggleSurveyFormEnabled, toggleMultiScreenEnabled } = useAppState();
  const {
    chatAlert,
    videoChatSidebarOpen,
    setVideoChatSidebarOpen,
  } = useContext(ChatContext);
  const [t] = useTranslation("lang", { useSuspense: false });

  return (
    <Tooltip title="Chat" placement="top" PopperProps={{ disablePortal: true }}>
      <Fab
        className={clsx(classes.fab, {
          [classes.fabAnimation]: chatAlert && !videoChatSidebarOpen,
        })} // 새로운 채팅이 왔을때의 버튼 애니메이션 (채팅창이 꺼져있고 chatAlert값이 true 일때 동작)
        onClick={() => {
          setVideoChatSidebarOpen(!videoChatSidebarOpen);
          toggleMultiScreenEnabled(false);
          toggleSurveyFormEnabled(false);
        }}
        disabled={props.disabled}
        style={videoChatSidebarOpen ? { color: "#bb86fc" } : {}}
      >
        <div>
          <ChatIcon />
          <CaptionTypo txtcolor="white">
            {t("videoController.chatting")}
          </CaptionTypo>
        </div>
      </Fab>
    </Tooltip>
  );
}
