import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import userReducer from "./reducers/users";
import alertReducer from "./reducers/alerts";
import eventReducer from "./reducers/events";
import subEventReducer from "./reducers/subEvents";
import UserActionTypes from "./types/actions/userAction";
import AlertActionTypes from "./types/actions/AlertAction";
import EventActionTypes from "./types/actions/EventAction";
import SubEventActionTypes from "./types/actions/SubEventAction";
import FormActionType from "./types/actions/FormAction";
import formReducer from "./reducers/forms";
import applicationReducer from "./reducers/applications";
import ApplicationActionTypes from "./types/actions/ApplicationAction";
import fileReducer from "./reducers/files";
import loadingReducer from "./reducers/loadings";
import FileActionTypes from "./types/actions/FileAction";
import LoadingActionTypes from "./types/actions/LoadingAction";
import MeetingActionTypes from "./types/actions/MeetingAction";
import meetingReducer from "./reducers/meetings";
import counselFormReducer from "./reducers/counselForm";
import CounselFormActionType from "./types/actions/CounselFormAction";
import routePathReducer from "./reducers/routePath";
import RoutePathActionTypes from "./types/actions/RoutePathAction";
import formAnswerReducer from "./reducers/formAnswers";
import FormAnswerActionType from "./types/actions/FormAnswerAction";
import CountryActionTypes from "./types/actions/CountryAction";
import countryReducer from "./reducers/countries";
import advertisingReducer from "./reducers/advertising";
import AdvertisingActionTypes from "./types/actions/AdvertisingAction";
import noticesReducer from "./reducers/notices";
import NoticeActionTypes from "./types/actions/NoticeAction";
import interpreterReducer from "./reducers/interpreters";
import InterpreterActionTypes from "./types/actions/InterpreterAction";
import managerReducer from "./reducers/managers";
import ManagerActionTypes from "./types/actions/ManagerAction";
import ChatActionTypes from "./types/actions/ChatActions";
import chatReducer from "./reducers/chats";
import webinarReducer from "./reducers/webinars";
import WebinarActionTypes from "./types/actions/WebinarAction";
import TableInfoActionTypes from "./types/actions/TableInfoAction";
import tableInfoReducer from "./reducers/tableInfo";
import MailActionTypes from "./types/actions/MailAction";
import mailReducer from "./reducers/mails";
import applicantCompanyReducer from "./reducers/applicantCompany";
import ApplicantCompanyActionTypes from "./types/actions/ApplicantCompany";
import ProductActionTypes from "./types/actions/productAction";
import productReducer from "./reducers/product";

const initialState = {};

export const rootReducer = combineReducers({
  users: userReducer,
  alerts: alertReducer,
  loadings: loadingReducer,
  events: eventReducer,
  subEvents: subEventReducer,
  files: fileReducer,
  forms: formReducer,
  mails: mailReducer,
  applications: applicationReducer,
  meetings: meetingReducer,
  counselForms: counselFormReducer,
  routePath: routePathReducer,
  formAnswers: formAnswerReducer,
  countries: countryReducer,
  advertisings: advertisingReducer,
  notices: noticesReducer,
  interpreters: interpreterReducer,
  managers: managerReducer,
  chats: chatReducer,
  tableInfo: tableInfoReducer,
  webinars: webinarReducer,
  applicantCompany: applicantCompanyReducer,
  product: productReducer,
});

const middleWare = [thunk as ThunkMiddleware<AppState, AppActions>];

export type AppState = ReturnType<typeof rootReducer>;

export type AppActions =
  | UserActionTypes
  | AlertActionTypes
  | EventActionTypes
  | SubEventActionTypes
  | FileActionTypes
  | FormActionType
  | ApplicationActionTypes
  | LoadingActionTypes
  | MeetingActionTypes
  | CounselFormActionType
  | RoutePathActionTypes
  | FormAnswerActionType
  | CountryActionTypes
  | AdvertisingActionTypes
  | NoticeActionTypes
  | InterpreterActionTypes
  | ManagerActionTypes
  | ChatActionTypes
  | TableInfoActionTypes
  | WebinarActionTypes
  | ApplicantCompanyActionTypes
  | MailActionTypes
  | ProductActionTypes;

export const store = createStore(
  rootReducer,
  initialState,
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middleWare)
    : composeWithDevTools(applyMiddleware(...middleWare))
);

export default store;
