import React, { useContext, useEffect } from "react";

import "./Chat.css";
import Messages from "./messages/Messages";
import Input from "./input/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ChatContext } from "../../video/components/ChatProvider/ChatProvider";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { setAlertAction } from "../../actions/alerts";
import { useTranslation } from "react-i18next";
interface ChatProps {
  contentHeight?: string | number;
}

const useStyles = makeStyles(() => ({
  circularProgress: { position: "relative", top: "50%", left: "45%" },
}));

const Chat = ({ contentHeight }: ChatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", { useSuspense: false });
  const { messages } = useContext(ChatContext);

  const {
    client,
    chatSidebarOpen,
    videoChatSidebarOpen,
    setInitialChatClient,
    setVideoChatSidebarOpen,
    setChatSidebarOpen,
    setUpVideoChatChannel,
  } = useContext(ChatContext);

  // 처음 접속 시 채팅 client 생성에 문제가 생겼을 경우
  // 채팅방에 참여할때 다시 채팅 client를 만들고 방에 참여하도록함.
  useEffect(() => {
    if (client === undefined) {
      // 일반 이벤트 참가자들끼리의 채팅방
      if (chatSidebarOpen) {
        setInitialChatClient().then((c) => {
          setChatSidebarOpen(false);
          dispatch(
            setAlertAction({
              id: "NoChatClient",
              msg: t("chatSidebar.noChatClient"),
              alertType: "warning",
            })
          );
        });
        // 영상통화 채팅방
      } else if (videoChatSidebarOpen) {
        setInitialChatClient()
          .then((c) => {
            if (c) {
              setUpVideoChatChannel(c);
            }
          })
          .catch((err) => {
            setVideoChatSidebarOpen(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSidebarOpen, videoChatSidebarOpen]);

  return (
    <div className="container" style={{ height: contentHeight ?? "100%" }}>
      {messages.isLoading === true ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <>
          <Messages />
          <Input />
        </>
      )}
    </div>
  );
};

export default Chat;
