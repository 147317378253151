import { RoutePath } from "../types/models/RoutePath";
import RoutePathActionTypes from "../types/actions/RoutePathAction";

const routePathDefaultState: RoutePath = {
  previousPath: "",
};

const routePathReducer = (
  state = routePathDefaultState,
  action: RoutePathActionTypes
): RoutePath => {
  switch (action.type) {
    case "SET_ROUTE_PATH":
      return action.routePath;
    case "REMOVE_ROUTE_PATH":
      return routePathDefaultState;
    default:
      return state;
  }
};

export default routePathReducer;
