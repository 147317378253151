import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "../../../../store";

// types

// @material-ui
import { TypographyProps } from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import styled from "styled-components";

// icons
import PxOutlinedTxtField from "../../../../components/Inputs/PxOutlinedTxtField";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import { countryPhoneNumberCode } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import PxBackDrop from "../../../../components/BackDrop/PxBackDrop";
import {
  deleteApplicant,
  getApplicant,
  getSubEventApplicationAction,
} from "../../../../actions/applications";
import PxDialog from "../../../../components/Dialog/PxDialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  arrowBackIcon: {
    cursor: "pointer",
    margin: 12,
    width: 24,
    height: 24,
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  titleTypo: {
    display: "inline-block",
    verticalAlign: "text-top",
  },
  managerAddInfoGridItem: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  countryCodeSelect: {
    display: "flex",
  },
  countryCodeInput: {
    padding: 10,
  },
  phoneNumberInput: {
    paddingLeft: 10,
  },
}));

// User type for autocomplete User list
interface UserType {
  id: string;
  company: string;
  position: string;
  countryNumber: string;
  email: string;
  name: string;
  phoneNumber: string;
}

// subtitle style
type TypoProp = TypographyProps;

const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const ManagerAdd = () => {
  const classes = useStyles();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const params: { id: string; secondId: string } = useParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const subEventBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );

  // 선택된 유저id
  const [userInfo, setUserInfo] = useState<UserType>({
    id: "",
    company: "",
    email: "",
    countryNumber: "82",
    phoneNumber: "",
    position: "",
    name: "",
  });

  useEffect(() => {
    const getApplicantInfo = async () => {
      const applicant: any = await dispatch(getApplicant(params.secondId));
      setUserInfo(applicant);
    };

    getApplicantInfo();
  }, [dispatch, params.secondId]);

  const onClickApplicantDelete = async () => {
    const result = await deleteApplicant(params.secondId);

    if (result) {
      const afterRemovedApplicants = subEventApplication.applicants.filter(
        (applicant) => applicant.id.toString() !== params.secondId
      );

      dispatch(
        getSubEventApplicationAction({
          ...subEventApplication,
          applicants: afterRemovedApplicants,
        })
      );

      history.goBack();
    }
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <PxBackDrop />
      <PxDialog open={dialogOpen} onClose={onCloseDialog}>
        <DialogContent>정말 삭제하시겠습니까?</DialogContent>
        <DialogActions>
          <PxButton backgroundcolor="purple" onClick={onClickApplicantDelete}>
            <ButtonTypo>삭제</ButtonTypo>
          </PxButton>
          <PxButton onClick={onCloseDialog}>
            <ButtonTypo>취소</ButtonTypo>
          </PxButton>
        </DialogActions>
      </PxDialog>
      <PxGridItem marginTop={20}>
        <ArrowBackIcon
          className={classes.arrowBackIcon}
          onClick={() => history.goBack()}
        />
      </PxGridItem>
      {/*  */}
      {subEventApplication.applicants.length !== 0 &&
        subEventApplication.applicants[0].id.toString() !== params.secondId &&
        subEventApplication.applicants[0].businessCard.id ===
          subEventBusinessCard?.id && (
          <PxButton
            backgroundcolor="grey"
            style={{ float: "right" }}
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <ButtonTypo>{t("common.delete")}</ButtonTypo>
          </PxButton>
        )}

      <MuiThemeProvider theme={theme}>
        <PxGridItem className={classes.managerAddInfoGridItem}>
          <form>
            {/* 이메일 */}
            <SubTitle>{t("managerAdd.email")}</SubTitle>
            <PxOutlinedTxtField
              name="email"
              value={userInfo.email}
              fullWidth
              inputpadding={10}
            />

            {/* 이름 */}
            <SubTitle>{t("managerAdd.name")}</SubTitle>
            <PxOutlinedTxtField
              name="name"
              value={userInfo.name}
              fullWidth
              inputpadding={10}
            />
            {/* 직함 */}
            <SubTitle>{t("managerAdd.position")}</SubTitle>
            <PxOutlinedTxtField
              name="position"
              value={userInfo.position}
              fullWidth
              inputpadding={10}
            />

            {/* 휴대번호 */}
            <SubTitle>{t("managerAdd.phoneNumber")}</SubTitle>
            <PxOutlinedFormControl fullWidth>
              <PxGridContainer direction="row">
                <PxGridItem xs={3} md={3}>
                  <PxSelectBox
                    native
                    className={classes.countryCodeSelect}
                    value={userInfo.countryNumber}
                    displayEmpty
                    inputProps={{
                      className: classes.countryCodeInput,
                      name: "countryNumber",
                    }}
                  >
                    <option value="" disabled>
                      {i18n.language === "ko" ? "국가코드" : "Country Code"}
                    </option>
                    {countryPhoneNumberCode.map((countryCode) => (
                      <option value={countryCode.code}>
                        {i18n.language === "ko"
                          ? countryCode.country
                          : countryCode.countryEn}
                      </option>
                    ))}
                  </PxSelectBox>
                </PxGridItem>
                <PxGridItem md={9} xs={9} className={classes.phoneNumberInput}>
                  <PxOutlinedTxtField
                    name="phoneNumber"
                    value={userInfo.phoneNumber}
                    fullWidth
                    inputpadding={10}
                    placeholder={t("mangerAdd.typePhoneNumber")}
                  />
                </PxGridItem>
              </PxGridContainer>
            </PxOutlinedFormControl>
          </form>
        </PxGridItem>
      </MuiThemeProvider>
    </>
  );
};

export default ManagerAdd;
