import { interval } from "d3-timer";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getMyMeetings } from "../../actions/meeting";
import { AppState } from "../../store";
import { ChatContext } from "../../video/components/ChatProvider/ChatProvider";
/*///////////////////////////////////////////////////////////////////////
subEvent Sidebar 미티현황의 수락대기 개수를 실시간으로 갱신해주기위한 provider
매 30초마다 내 미팅현황을 불러온 후 수락대기중인 미팅을 갱신한다.
///////////////////////////////////////////////////////////////////////*/

export interface NotificationContextType {
  meetingNoti?: number;
  unReadChatMessageNoti?: number;
}

export const NotificationContext = createContext<NotificationContextType>(
  null!
);

interface NotificationProviderProps {
  children: ReactNode;
}

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const { client, chatSidebarOpen } = useContext(ChatContext);
  const { id } = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const meetings = useSelector((state: AppState) => state.meetings.meetings);

  const [waitMeetingCount, setWaitMeetingCount] = useState<number>();
  const [unReadChatMessageCount, setUnReadChatMessageCount] =
    useState<number>();

  // 읽지않은 채팅메세지를 숫자를 가져온 후 state set
  const getUnreadChatMessages = useCallback(async () => {
    if (client && params.id) {
      const subScribedChannels = await client.getSubscribedChannels();

      const subEventChatChannels = subScribedChannels.items.filter((channel) =>
        channel.uniqueName.includes(params.id)
      );

      const unreadMessageCountArray = await Promise.all(
        subEventChatChannels.map((channel) => {
          return channel.getUnconsumedMessagesCount();
        })
      );

      const unreadMessageCount = unreadMessageCountArray.reduce(
        (a, b) => a + b,
        0
      );

      setUnReadChatMessageCount(unreadMessageCount);
    }
  }, [client, params.id]);

  useEffect(() => {
    // 채팅 sidebar (default=false)가 꺼질떄 읽지않은 메세지 개수를 불러온 후 unreadMeetingMessageCount state set
    if (!chatSidebarOpen) {
      getUnreadChatMessages();
    }

    // 60초마다 읽지 않은 메세지 개수불러옴 (채팅 sidebar가 꺼져있을때만 동작 => 안읽은 채팅메세지 sync가 안맞음)
    const interval = setInterval(() => {
      getUnreadChatMessages();
    }, [60000]);

    return () => {
      clearInterval(interval);
    };
  }, [chatSidebarOpen, getUnreadChatMessages]);

  // 60초마다 내 미팅정보를 불러옴
  useEffect(() => {
    let interval: any;
    if (id) {
      dispatch(getMyMeetings(id));
      interval = setInterval(() => {
        dispatch(getMyMeetings(id));
      }, [60000]);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [dispatch, id]);

  // 불러온 미팅 정보에서 수락대기중인 미팅들만 count 후 meetingNoti state에 set
  useEffect(() => {
    if (id && meetings?.length !== 0) {
      const myWaitingMeetings = meetings?.filter(
        (meeting) => meeting.applicant !== id && meeting.status === "waiting"
      ).length;

      setWaitMeetingCount(myWaitingMeetings);
    }
  }, [id, meetings]);

  return (
    <NotificationContext.Provider
      value={{
        meetingNoti: waitMeetingCount,
        unReadChatMessageNoti: unReadChatMessageCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
