import { useContext } from "react";
import { StateContext } from "../AppstateProvider";

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
