import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface Body3TypoProps {
  fontWeight?: number;
  txtcolor?:
    | "rgba(0,0,0,0.87)"
    | "secondary"
    | "purple"
    | "purple700"
    | "black0.6"
    | "azure";
  underLine?: boolean;
}

type Prop = Body3TypoProps & TypographyProps;

const Body3Typo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, underLine, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 16px;
  font-style: normal;
  letter-spacing: normal;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }
    if (props.txtcolor === "secondary") {
      return "#6d7278";
    }
    if (props.txtcolor === "azure") {
      return "#0091ea";
    }
    if (props.txtcolor === "purple700") {
      return "#512da8";
    }
    if (props.txtcolor === "black0.6") {
      return "rgba(0,0,0,0.6)";
    }

    return props.txtcolor;
  }};
  ${(props) => {
    if (props.underLine !== undefined && props.underLine) {
      return css`
        text-decoration: underline;
      `;
    }
    if (props.fontWeight !== undefined) {
      return css`
        font-weight: ${props.fontWeight};
      `;
    }
  }}
`;

Body3Typo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default Body3Typo;
