import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import React, { useState } from "react";
import PxButton from "../../../../components/Buttons/PxButton";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import CaptionTypo from "../../../../components/Typhography/CaptionTypo";
import WebainarTextAera from "./WebinarTextAera";
import { useTranslation } from "react-i18next";
import { Webinar, WebinarQA } from "../../../../types/models/Webinar";
import { CountryApiInfo, CountryInfo } from "../../../../types/models/Country";
import User from "../../../../types/models/User";
import WebinarAnswer from "./WebinarAnswer";
import { useDispatch } from "react-redux";
import { deleteWebinarQA } from "../../../../actions/webinar";
import useCalcTime from "../../../../hooks/useCalcTime";
import moment from "moment";

const Question = ({
  webinarQAData,
  webinar,
  user,
}: {
  webinarQAData: WebinarQA;
  country: CountryApiInfo;
  countries: CountryInfo;
  countryCode?: string;
  webinar: Webinar;
  user: User;
}) => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const [answerToggle, setAnswerToggle] = useState<boolean>(false);
  const [modifyToggle, setModifyToggle] = useState<boolean>(false);
  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    dispatch(deleteWebinarQA(webinarQAData.id, webinarQAData.questionOrAnswer));
  };
  const [calcTimeByTimezone] = useCalcTime();
  return (
    <>
      <Box padding="20px 10px">
        <PxGridContainer alignItems="center" justify="space-between">
          <Box display="flex" alignItems="baseline">
            <Body1Typo fontWeight="blod" fontSize="18px">
              Q:&nbsp;
            </Body1Typo>
            <Body1Typo fontWeight="600">
              {webinarQAData.userName}({webinarQAData.companyName})
            </Body1Typo>
          </Box>
          <CaptionTypo txtcolor="black0.38">
            {calcTimeByTimezone(
              `${moment(webinarQAData.modifiedDate, "YYYY-MM-DD HH:mm")
                .add(9, "hours")
                .format("YYYY-MM-DD HH:mm")}`,
              "YYYY-MM-DD HH:mm"
            )}
            &nbsp;
            {webinarQAData.createdDate === webinarQAData.modifiedDate
              ? ""
              : `(${t("webinar.edited")})`}
          </CaptionTypo>
        </PxGridContainer>

        <Body1Typo>
          {(webinar &&
            webinar.presenters &&
            webinar.presenters[0].applicantId === user.id) ||
          user.id === webinarQAData.userId ||
          webinarQAData.isPrivate === "N" ? (
            webinarQAData.content
              .split(/(?:\r\n|\r|\n)/g)
              .map((t) => <div>{t}</div>)
          ) : (
            <div>{t("webinar.secret")}</div>
          )}
        </Body1Typo>
        {(webinar &&
          webinar.presenters &&
          webinar.presenters[0].applicantId === user.id) ||
        user.id === webinarQAData.userId ? (
          <PxGridContainer
            alignItems="center"
            justify="flex-end"
            style={{
              marginTop: 5,
            }}
          >
            {webinarQAData.isPrivate === "Y" && (
              <Body1Typo
                fontSize="12px"
                fontWeight="700"
                txtcolor="purple700"
                style={{ marginTop: 8, marginRight: 10 }}
              >
                {t("webinar.secret")}
              </Body1Typo>
            )}
            {user.id === webinarQAData.userId ? (
              <PxButton
                boxShadow={false}
                backgroundcolor="pale-grey"
                padding="3px"
                onClick={() => setModifyToggle(!modifyToggle)}
              >
                {t("common.modify")}
              </PxButton>
            ) : (
              <PxButton
                boxShadow={false}
                backgroundcolor="pale-grey"
                padding="3px"
                onClick={() => setAnswerToggle(!answerToggle)}
              >
                {t("webinar.answer")}
              </PxButton>
            )}

            <PxButton
              boxShadow={false}
              borderColor="bluePurple"
              padding="3px"
              hover={false}
              onClick={handleDelete}
            >
              {t("common.delete")}
            </PxButton>
          </PxGridContainer>
        ) : null}
      </Box>
      <Box
        padding="0px 20px 20px 20px"
        display={answerToggle ? "block" : "none"}
      >
        <WebainarTextAera
          isPrivate={webinarQAData.isPrivate}
          questionId={webinarQAData.id}
          setModifyToggle={setAnswerToggle}
        />
      </Box>
      {modifyToggle && (
        <Box padding="0px 20px 20px 20px">
          <WebainarTextAera
            isPrivate={webinarQAData.isPrivate}
            id={webinarQAData.id}
            pastText={webinarQAData.content}
            setModifyToggle={setModifyToggle}
          />
        </Box>
      )}

      <Divider />
      {webinarQAData.answers &&
        webinarQAData.answers.length > 0 &&
        webinarQAData.answers.map(
          (a) =>
            webinar &&
            webinar.presenters &&
            webinarQAData.userId &&
            webinar.presenters[0].applicantId && (
              <WebinarAnswer
                key={a.id}
                webinarAnswer={a}
                user={user}
                writer={webinarQAData.userId}
                isPrivate={webinarQAData.isPrivate}
                presenter={webinar.presenters[0].applicantId}
                date={
                  calcTimeByTimezone(
                    `${moment(a.modifiedDate, "YYYY-MM-DD HH:mm")
                      .add(9, "hours")
                      .format("YYYY-MM-DD HH:mm")}`,
                    "YYYY-MM-DD HH:mm"
                  )!
                }
              />
            )
        )}
    </>
  );
};

export default Question;
