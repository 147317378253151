import React from "react";
import styled from "styled-components";

import FormControl, { FormControlProps } from "@material-ui/core/FormControl";

interface PxOutlinedFormControlProps extends FormControlProps {
  minwidth?: string;
}

const PxFormControl: React.FC<PxOutlinedFormControlProps> = styled(
  (props: PxOutlinedFormControlProps) => {
    const { minwidth, ...rest } = props;
    return <FormControl variant="outlined" {...rest} />;
  }
)`
  min-width: ${(props) => props.minwidth ?? "100px"};
`;

export default PxFormControl;
