import React, { useState } from "react";

import AudioInputList from "../DeviceSelector/AudioInputList/AudioInputList";
import AudioOutputList from "../DeviceSelector/AudioOutputList/AudioOutputList";
import { Dialog, DialogContent, Button, Theme } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import SettingsIcon from "@material-ui/icons/Settings";
import VideoInputList from "../DeviceSelector/VideoInputList/VideoInputList";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "black",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
    container: {
      width: "500px",
      [theme.breakpoints.down("xs")]: {
        width: "calc(100vw - 32px)",
      },
      "& .inputSelect": {
        width: "calc(100% - 35px)",
      },
    },
    listSection: {
      margin: "1em 0",
    },
    button: {
      float: "right",
    },
    paper: {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
      },
    },
  })
);

export function SettingBeforMeeting() {
  const classes = useStyles();  
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("lang", { useSuspense: false });

  return (
    <>
      <Tooltip title="" placement="top">
        <Fab
          className={classes.fab}
          onClick={() => setIsOpen(true)}
          data-cy-device-select
        >
          <div>
            <SettingsIcon />
            <CaptionTypo txtcolor="black0.6">
              {t("videoController.setting")}
            </CaptionTypo>
          </div>
        </Fab>
      </Tooltip>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        classes={{ paper: classes.paper }}
      >
        <DialogContent className={classes.container}>
          <div className={classes.listSection}>
            <AudioInputList />
          </div>
          <div className={classes.listSection}>
            <AudioOutputList />
          </div>
          <div className={classes.listSection}>
            <VideoInputList />
          </div>
          <Button className={classes.button} onClick={() => setIsOpen(false)}>
            {t("videoController.done")}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
