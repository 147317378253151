import React from "react";
import { useHistory } from "react-router-dom";
import FindId from "./FindId";
import FindPwd from "./FindPwd";
import styled from "styled-components";

const FindUserBox = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
`;

const FindUser = () => {
  const history = useHistory();
  return (
    <FindUserBox>
      <FindId />
      <FindPwd />
    </FindUserBox>
  );
};

export default FindUser;
