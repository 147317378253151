import ProductActionTypes from "../types/actions/productAction";
import { ProductList } from "../types/models/Product";


interface Product{
    productList: ProductList;
    productSearchWord: string;
}

const ProductDefaultState: Product = {
    productList: {
        content:[],
        pageable: {},
        last: false,
        totalElements: 0,
        totalPages: 0,
        size: 1,
        number: undefined,
        sort: {
            sorted: false,
            unsorted: true,
            empty: true
        },
        numberOfElements: 1,
        first: true,
        empty: false
    },
    productSearchWord: ""
}

const productReducer = (
    state = ProductDefaultState,
    action: ProductActionTypes
): Product => {
    switch (action.type) {
        case "GET_PRODUCT_LIST":
            return { ...state, productList: action.productList };
        case "SET_PRODUCT_SEARCH_WORD":
            return { ...state, productSearchWord: action.productSearchWord };
        default:
            return {...state};
    }
}

export default productReducer;

