// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import routes from '../constants/routes';

// export const BASE_URL = routes.HOME;
// const BASE_URL = "http://localhost:3001/chime/";
const BASE_URL = process.env.NODE_ENV === "production" ? "/chime/" : "http://localhost:3001/chime/";

console.log(`node_env : ${process.env.NODE_ENV}`);

export type MeetingFeatures = {
  Audio: {[key: string]: string};
}

export type CreateMeetingResponse = {
  MeetingId: string;
  MeetingFeatures: MeetingFeatures;
  MediaRegion: string;
}

export type AttendeeResponse = {
  AttendeeId: string;
  ExternalUserId: string;
  JoinToken: string;
}

export type JoinMeetingInfo = {
  Meeting: CreateMeetingResponse;
  Attendee: string;
}

interface MeetingResponse {
  JoinInfo: JoinMeetingInfo;
}

interface GetAttendeeResponse {
  name: string;
}

export async function fetchMeeting(
  meetingId: string,
  name: string,
  region: string,
  echoReductionCapability = false
): Promise<MeetingResponse> {

  const params = {
    roomId: meetingId,
    name: name,
    region: region,
    ns_es: String(echoReductionCapability)
  }

  const res = await fetch( BASE_URL + 'join?' + new URLSearchParams(params));

  const data = await res.json();

  if (data.error) {
    throw new Error(`Server error: ${data.error}`);
  }

  return data;
}


// 테스트 미팅 참석 
export async function fetchTestMeeting(meetingId: string, attendeeName: string) {
  
  const params = {
    meetingId: meetingId,
    attendeeName : attendeeName
  }
  
  const res = await fetch(BASE_URL + "testMeeting?" + new URLSearchParams(params));
  const data = await res.json();

  if (data.error) {
    throw new Error(`Server error: ${data.error}`);
  }

  return data;

}

// 테스트 미팅 참석자 호출
export async function getTestAttendee(
  meetingId: string,
  chimeAttendeeId: string): Promise<GetAttendeeResponse> {
  
  const params = {
    title: meetingId,
    attendee: encodeURIComponent(chimeAttendeeId)
  }

  const res = await fetch(BASE_URL + "testAttendee?" + new URLSearchParams(params), {
    method: 'GET'
  });
  
  if (!res.ok) {
    throw new Error('Invalid server response');
  }

  const data = await res.json();

  return {
    name: data.AttendeeInfo.Name
  };
  
}

export async function getAttendee(
  meetingId: string,
  chimeAttendeeId: string
): Promise<GetAttendeeResponse> {
  const params = {
    title: meetingId,
    attendee: encodeURIComponent(chimeAttendeeId),
  };

  const res = await fetch(BASE_URL + 'attendee?' + new URLSearchParams(params), {
    method: 'GET',
  });

  if (!res.ok) {
    throw new Error('Invalid server response');
  }

  const data = await res.json();

  return {
    name: data.AttendeeInfo.Name,
  };
}



export async function endMeeting(meetingId: string): Promise<void> {
  const params = {
    roomId: encodeURIComponent(meetingId),
  };

  const res = await fetch(BASE_URL + 'end?' + new URLSearchParams(params), {
    method: 'POST',
  });

  if (!res.ok) {
    throw new Error('Server error ending meeting');
  }
}

export async function attendeeOut(meetingId: string, chimeMeetingId: string, attendeeId: string): Promise<void>{
  
  const params = {
    meetingId: meetingId,
    chimeMeetingId:chimeMeetingId,
    attendeeId: attendeeId,
  }

  const res = await fetch(BASE_URL + `attendeeOut?` + new URLSearchParams(params));
}

export async function startTranscript (transcriptConfig:any, meetingId:string) {
  const param = {
    meetingId: meetingId,
    transcriptionConfig: transcriptConfig
  }

  const res = await fetch(BASE_URL + "transcriptStart",{headers:{'Content-Type': 'application/json'},method:"post",body: JSON.stringify(param)});
}


export async function testAttendeeOut(meetingId:string, chimeMeetingId:string, attendeeId: string):Promise<void> {
  const params = {
    meetingId: meetingId,
    chimeMeetingId: chimeMeetingId,
    attendeeId: attendeeId
  }

  const res = await fetch(BASE_URL + "testAttendeeOut?" + new URLSearchParams(params));
}

export const createGetAttendeeCallback = (meetingId: string) =>
  (chimeAttendeeId: string): Promise<GetAttendeeResponse> =>
    getAttendee(meetingId, chimeAttendeeId);

export const createTestGetAttendeeCallback = (meetingId: string) =>
  (chimeAttendeeId: string): Promise<GetAttendeeResponse> =>
    getTestAttendee(meetingId, chimeAttendeeId);


