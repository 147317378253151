import ApplicantCompanyActionTypes from "../types/actions/ApplicantCompany"
import { ApplicantCompany, ApplicantCompanyPage } from "../types/models/ApplicantCompany"
import { ApplicantList } from "../types/models/Application"

interface ApplicantCompanyState {
    applicantCompanyList: ApplicantCompanyPage;
    // applicantCompanyInfo: ApplicantCompany;
}

const applicantCompanyDefaultSate: ApplicantCompanyState = {
    applicantCompanyList: {
        content: [],
        pageable: "",
        last: false,
        totalElemnts: 0,
        totalPages: 0,
        size: 0,
        sort: {
            sorted: false,
            unsorted: false,
            empty: false,
          },
          numberOfElements: 0,
          first: false,
          empty: false,
    }
}


const applicantCompanyReducer = (
    state = applicantCompanyDefaultSate,
    action: ApplicantCompanyActionTypes
): ApplicantCompanyState => {
    switch (action.type) {
        case "GET_APPLICANT_COMPANY_LIST":
            
            return {...state, applicantCompanyList: action.applicantCompanyList}
    
        default:
            return state;
    }
}

export default applicantCompanyReducer;