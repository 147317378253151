import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { findEmailByCode, changePwd } from "../../actions/users";
import { useTranslation } from "react-i18next";
import { setAlert } from "../../actions/alerts";
import { AppState } from "../../store";

const ChangePwd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation("lang", { useSuspense: false });
  const param: { id: string } = useParams();
  const [isCapsLock, setIsCapsLock] = useState(false);
  const [changeUserPwd, setChangeUserPwd] = useState({
    temporaryCode: param.id,
    email: "",
    password: "",
    role: "user",
    country: "",
  });
  const [hidden, setHidden] = useState(true);

  const country = useSelector((state: AppState) => state.countries.country);

  const { email, password } = changeUserPwd;
  const passwordRegExp = /[A-Za-z0-9\d$@$!%*#?&]{8,}$/;
  const engCheck = /[a-z]/; //대문자 불가로 정함 소문자만!
  const numCheck = /[0-9]/;
  useEffect(() => {
    findUser();
  }, []);

  const findUser = async () => {
    const res = await dispatch(findEmailByCode(param.id));
    if (res !== undefined) {
      setChangeUserPwd({
        ...changeUserPwd,
        email: res.toString(),
        country: `${country?.continent}/${country?.city}`,
      });
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") event.preventDefault();

    const s = String.fromCharCode(event.keyCode);
    if (
      s.toUpperCase() === s &&
      s.toLowerCase() !== s &&
      !event.shiftKey &&
      event.key.toLowerCase() !== event.key
    ) {
      setIsCapsLock(true);
    } else {
      setIsCapsLock(false);
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    const name = e.target.name as string;
    let value = e.target.value as string;

    setChangeUserPwd({ ...changeUserPwd, [name]: value });
  };

  const submitChangePwd = async () => {
    if (
      !passwordRegExp.test(changeUserPwd.password) ||
      !changeUserPwd.password.match(engCheck) ||
      !changeUserPwd.password.match(numCheck)
    ) {
      dispatch(
        setAlert({
          id: "businessCard-blank",
          msg: t("regularExpression.password"),
          alertType: "warning",
        })
      );
      return;
    }
    const mailRes: any = await dispatch(changePwd(changeUserPwd));
    if (mailRes) history.replace("/auth/login");
  };

  return (
    <>
      <ChangeUserPwd>
        <Title onClick={() => setHidden(!hidden)}>
          {t("changePassword.changePassword")}
        </Title>
        <Content on={hidden}>
          <SubTitle>
            <span>
              {email === ""
                ? t("changePassword.couldNotFound")
                : `${t("changePassword.signUpEmail")}: ${email}`}
            </span>
          </SubTitle>
          <TextField
            margin="normal"
            fullWidth
            onKeyDown={handleOnKeyDown}
            type="password"
            id="password"
            label={t("changePassword.pw")}
            helperText={isCapsLock ? "CapsLock 키가 눌려있습니다." : ""}
            name="password"
            value={password}
            onChange={(e) => handleOnChange(e)}
          />
          {password.length > 0 && !password!.match(engCheck) && (
            <div style={{ color: "red" }}>
              {t("register.containLowercaseLetters")}
            </div>
          )}
          {password.length > 0 && !password!.match(numCheck) && (
            <div style={{ color: "red" }}>{t("register.containNumber")}</div>
          )}

          <SendMailButton type="button" onClick={submitChangePwd}>
            {t("changePassword.changePassword")}
          </SendMailButton>
        </Content>
      </ChangeUserPwd>
    </>
  );
};

export default ChangePwd;

const ChangeUserPwd = styled.div`
  width: 100%;
  min-width: 280px;
  max-width: 640px;
  padding: 24px;
`;

const ButtonBase = styled.button`
  font-size: 18px;
  padding: 0.7em 2em;
  border-radius: 3px;
  border: none;
  // margin: 0 0.32em;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const SendMailButton = styled(ButtonBase)`
  height: 56px;
  width: 100%;
  background: black;
  color: white;
  margin-top: 58px;
`;

const Title = styled.div`
  height: 36px;
  font-size: 24px;
  font-weight: bold;
  color: #282828;

  cursor: pointer;
`;

const SubTitle = styled.div`
  min-height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Content = styled.div`
  height: 0;
  transition: height 0.3s;
  transition-timing-function: linear;
  overflow: hidden;
  ${(props: { on: boolean }) => {
    if (props.on) {
      return `height:400px`;
    }
  }}
`;
