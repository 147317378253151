import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Button, { ButtonProps } from "@material-ui/core/Button";

interface PxButtonProps {
  padding?: string;
  backgroundcolor?:
    | "purple"
    | "bluePurple"
    | "grey"
    | "default"
    | "transparent"
    | "pale-grey"
    | "white87"
    | "black";
  hover?: boolean;
  endIconMargin?: string;
  border?: boolean;
  boxShadow?: boolean;
  borderRadius?: boolean;
  borderColor?: "silver" | "bluePurple" | "eggBlue";
  txtColor?:
    | "bluePurple"
    | "gray"
    | "lighterPurple"
    | "white87"
    | "white"
    | "purple";
  outline?: boolean;
  marginTop?: number;
}

type Prop = PxButtonProps & ButtonProps;

const PxButton: React.FC<Prop> = styled((props: Prop) => {
  const {
    backgroundcolor,
    padding,
    endIconMargin,
    border,
    borderColor,
    boxShadow,
    borderRadius,
    marginTop,
    txtColor,
    outline,
    ...rest
  } = props;
  return <Button variant="contained" {...rest} />;
})`
  .MuiButton-endIcon {
    margin: ${(props) => props.endIconMargin};
  }
  padding: ${(props) => props.padding ?? "10px 24px 10px 24px"};
  background-color: ${(props) => {
    if (props.backgroundcolor === "purple") {
      return "#5f4b8b";
    } else if (props.backgroundcolor === "bluePurple") {
      return "#512da8";
    } else if (props.backgroundcolor === "grey") {
      return "#eeeeee";
    } else if (props.backgroundcolor === "transparent") {
      return "transparent";
    } else if (props.backgroundcolor === "pale-grey") {
      return "#f6f7fa";
    } else if (props.backgroundcolor === "black") {
      return "rgba(0, 0, 0, 0.87)";
    } else if (props.backgroundcolor === "white87") {
      return "rgba(255, 255, 255, 0.87)";
    }
    return "white";
  }};
  color: ${(props) => {
    if (props.txtColor === "white") {
      return "#ffffff";
    } else if (props.txtColor === "bluePurple") {
      return "#512da8";
    } else if (props.backgroundcolor === "purple") {
      return "#5f4b8b";
    } else if (props.txtColor === "gray") {
      return "rgba(255, 255, 255, 0.6)";
    } else if (props.txtColor === "lighterPurple") return "rgb(191, 90, 242)";
    else if (props.txtColor === "white87") return "rgba(255, 255, 255, 0.87)";
    return "#000000";
  }};

  border-color: ${(props) => {
    if (props.borderColor === "silver") {
      return "#c7c8cb";
    } else if (props.borderColor === "bluePurple") {
      return "rgb(81, 45, 168)";
    } else if (props.borderColor === "eggBlue") return "rgb(250, 252, 254)";
    return "#dee2e6";
  }};

  border-radius: ${(props) => (props.borderRadius === false ? "0px" : "")};

  margin-top: ${(props) =>
    props.marginTop !== undefined ? props.marginTop + "px" : "0px"};

  &:focus {
    outline: ${(props) => (props.outline === false ? "none" : "")};
  }
  ${(props) => {
    let cssProps = "";
    if (props.hover !== undefined && props.hover === false) {
      cssProps += `
        border: none;
        box-shadow: none;

        &:hover {
          background-color: transparent;
          box-shadow: none;
        }
      `;
    }

    if (props.border !== undefined && props.border === true) {
      cssProps += `
        border-width: 1px;
        border-style: solid;
        box-shadow: none;
      `;
    }
    if (props.border !== undefined && props.border === false) {
      cssProps += `
        border: none;
        box-shadow: none;
      `;
    }

    if (props.boxShadow !== undefined && props.boxShadow === false) {
      cssProps += `
        box-shadow: none;
      `;
    }

    if (props.borderColor && props.borderColor === "silver") {
      cssProps += `
        border-color: #c7c8cb;
      `;
    }
    if (props.backgroundcolor === "purple") {
      cssProps += `
        & p {
          color: white;
        }
      `;
    }
    return css`
      ${cssProps}
    `;
  }}
`;

PxButton.defaultProps = {
  backgroundcolor: "default",
};

export default PxButton;
