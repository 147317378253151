import ApplicationActionTypes from "../types/actions/ApplicationAction";
import BusinessCard from "../types/models/BusinessCard";
import { Application, ApplicantList, PaginationApplication } from "../types/models/Application";
import { MeetingInfo } from "../types/models/Meeting";

interface applicationReduxStateProps {
  application: Application;
  subEventApplication: Application;
  applications: PaginationApplication;
  attendeeList: { businessCards: BusinessCard }[];
  applicants: ApplicantList[];
  applicationMeeting?: MeetingInfo[]; // 선택한 application과 접속한 유저의 application 간의 전체 미팅 리스트
  applicationMeetings?: MeetingInfo[]; // 접속한 유저의 전체 미팅리스트 (상태: agree, impossible, waiting)
}

const applicationDefaultState: applicationReduxStateProps = {
  application: {
    subEventId: "",
    applicationFormAnswers: [],
    businessCards: [],
    applicants: [],
    method: "pre",
  },
  subEventApplication: {
    subEventId: "",
    applicationFormAnswers: [],
    businessCards: [],
    applicants: [],
    method: "pre",
  },
  applications: {
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
  },
  attendeeList: [],
  applicants: [],
};

const applicationReducer = (
  state = applicationDefaultState,
  action: ApplicationActionTypes
): applicationReduxStateProps => {
  switch (action.type) {
    case "CREATE_APPLICATION":
      return { ...state, application: action.application };
    case "GET_APPLICATION":
      return { ...state, application: action.application };
    case "GET_APPLICATIONS":
      return { ...state, applications: action.applications };
    case "GET_ATTENDEE_LIST":
      return { ...state, attendeeList: action.attendeeList };
    case "REMOVE_APPLICATION":
      return {
        ...state,
        application: applicationDefaultState.application,
        applicationMeeting: undefined,
      };
    case "GET_SUB_EVENT_APPLICATION":
      return { ...state, subEventApplication: action.subEventApplication };
    case "REMOVE_MANAGERS":
      return { ...state, subEventApplication: action.application };
    case "REMOVE_APPLICANTS":
      return { ...state, applicants: [] };
    case "GET_APPLICANT_LIST":
      return { ...state, applicants: action.applicants };
    case "UPDATE_APPLICATION_ANSWERS":
      return { ...state, subEventApplication: action.application };
    case "GET_APPLICATION_MEETING":
      return { ...state, applicationMeeting: action.applicationMeeting };
    case "GET_APPLICATION_MEETINGS":
      return { ...state, applicationMeetings: action.applicationMeetings };
    case "REMOVE_APPLICATION_MEETINGS":
      return {
        ...state,
        applicationMeetings: undefined,
        applicationMeeting: undefined,
      };
    case "RESET_APPLICATIONS_STATE_ACTION":
      return {
        ...applicationDefaultState,
        subEventApplication: state.subEventApplication,
      };
    default:
      return state;
  }
};

export default applicationReducer;
