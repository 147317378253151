import React from "react";
import { Switch, RouteComponentProps, Route } from "react-router-dom";

// router
import { termsRoutes } from "../router/router";

// type
import RouteType from "../types/models/Route";

// material comp
import Grid from "@material-ui/core/Grid";

const Terms: React.FC<RouteComponentProps> = ({ match }) => {
  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/terms") {
        return (
          <Route
            path={`${prop.layout}${prop.path}`}
            component={prop.component}
            key={key}
          />
        );
      }
    });
  };

  return (
    <>
      <Grid container>
        <Switch>{getRoutes(termsRoutes)}</Switch>
      </Grid>
    </>
  );
};

export default Terms;
