import React, { Fragment, useContext } from "react";

// components
import FormItem from "./FormItem";
import PxButton from "../../components/Buttons/PxButton";

// context
import { FormContext } from "../../components/Providers/FormProvider/FormProvider";
import { Card } from "@material-ui/core";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import { useTranslation } from "react-i18next";

interface AdditionalFormProps {
  preview: boolean; // 미리보기 화면을 보여줄지 입력화면을 보여줄지 정해주는 boolean value
  subEventId?: string; // form이 속한 subEvent의 id 값
}

const AdditionalForm = ({ preview, subEventId }: AdditionalFormProps) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const { adFormData, addAdForm, deleteAdForm, checkOnlyEngReturnString } =
    useContext(FormContext);

  return (
    <Fragment>
      {adFormData?.adFormIds?.map((adFormIds, index) => (
        <Card style={{ padding: 10, marginTop: 10 }}>
          {preview !== true && index !== 0 && (
            <PxGridContainer justify="flex-end">
              <PxButton
                padding="10 10 10 10"
                onClick={() => deleteAdForm(index)}
              >
                <ButtonTypo>삭제</ButtonTypo>
              </PxButton>
            </PxGridContainer>
          )}
          {adFormIds.length !== 0 &&
            adFormIds.map((formId, index) => (
              <>
                <FormItem
                  key={index}
                  title={adFormData!.formData[formId].title}
                  type={adFormData!.formData[formId].type}
                  id={adFormData!.formData[formId].id}
                  content={adFormData!.formData[formId].content}
                  radioContent={adFormData!.formData[formId].radioContent}
                  requireCheck={adFormData!.formData[formId].requireCheck}
                  checkBoxContent={adFormData!.formData[formId].checkBoxContent}
                  fileContent={adFormData!.formData[formId].fileContent}
                  explainCheck={adFormData!.formData[formId].explainCheck}
                  explain={adFormData!.formData[formId].explain}
                  selectContent={adFormData!.formData[formId].selectContent}
                  keywordContent={adFormData!.formData[formId].keywordContent}
                  categoryContent={adFormData!.formData[formId].categoryContent}
                  preview={preview}
                  checkOnlyEngReturnString={checkOnlyEngReturnString}
                  subEventId={subEventId}
                  whtAdditionalPage={true}
                />
              </>
            ))}
        </Card>
      ))}
      {preview !== true && (
        <PxGridContainer justify="center">
          <PxButton
            className="mt-3 mb-5"
            backgroundcolor="purple"
            onClick={() => {
              addAdForm();
            }}
          >
            <ButtonTypo>{t("form.add")}</ButtonTypo>
          </PxButton>
        </PxGridContainer>
      )}
    </Fragment>
  );
};

export default AdditionalForm;
