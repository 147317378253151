import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column } from "material-table";
import { makeStyles } from "@material-ui/core/styles";
// custom ui comp
import PxTextButton from "../../../../components/Buttons/PxTextButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import PxTable from "../../../../components/Tables/PxTable";
import { useTranslation } from "react-i18next";
import PxDialog from "../../../../components/Dialog/PxDialog";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import { DialogActions, DialogContent } from "@material-ui/core";
import SubEventHeading from "../../../../components/Typhography/SubEventHeading";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import {
  getManagerList,
  removeManagers,
} from "../../../../actions/applications";

const useStyles = makeStyles((theme) => ({
  managerAddListButton: {
    marginRight: 5,
  },
  dialogRoot: {
    marginBottom: 100,
  },
}));

interface RowType {
  id: string; // applicant id
  name: string;
  email: string;
  phoneNumber: string;
  role: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const ManagerList: React.FC<RouteComponentProps> = ({ match }) => {
  const classes = useStyles();
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const { applicantId, applicants, id } = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const user = useSelector((state: AppState) => state.users);
  const [data, setData] = useState<RowType[]>([]);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("managerList.name"),
        field: "name",
        width: 200,
        headerStyle: {
          paddingLeft: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        cellStyle: {
          paddingLeft: 0,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      // {
      //   title: "직함",
      //   field: "position",
      // },
      {
        title: t("managerList.email"),
        field: "email",
        width: 240,
        headerStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("managerList.phoneNumber"),
        field: "phoneNumber",
        width: 160,
        headerStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "",
        field: "role",
        headerStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (applicants.length > 0 && id) {
      const callGetManagerList = async () => {
        const managerList: any = await dispatch(getManagerList(id));

        const data = managerList.map((manager: any) => {
          return {
            name: manager.name,
            email: manager.email,
            phoneNumber: manager.phoneNumber,
            id: manager.id,
            role: applicants[0].id === manager.id ? t("managerList.admin") : "",
          };
        });
        dispatch(setTableInfoLoadingAction(false));

        setState((prevCreateDate) => {
          return { ...prevCreateDate, data: data };
        });
      };

      callGetManagerList();
    }
  }, [applicants, dispatch, id, t]);

  const closeDialogOnClick = () => {
    setDeleteDialogOpen(false);
  };

  const deleteManagerOnClick = () => {
    const ids: string[] = (data as RowType[]).map((rowData: RowType) => {
      return rowData.id;
    });
    dispatch(removeManagers(ids, id!));
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <PxDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        title={"담당자 삭제"}
        className={classes.dialogRoot}
      >
        <DialogContent>
          <SubHeadingTypo>담당자를 삭제하시겠습니까 ?</SubHeadingTypo>
        </DialogContent>
        <DialogActions>
          <PxTextButton onClick={deleteManagerOnClick}>
            <ButtonTypo txtcolor="red">확인</ButtonTypo>
          </PxTextButton>
          <PxTextButton onClick={closeDialogOnClick}>
            <ButtonTypo>취소</ButtonTypo>
          </PxTextButton>
        </DialogActions>
      </PxDialog>

      <PxTable<RowType>
        title={
          <div style={{ width: "100%", maxWidth: 801 }}>
            <SubEventHeading>{t("managerList.managers")}</SubEventHeading>
            {applicantId === user.id && (
              <span style={{ float: "right", height: 36, marginLeft: 10 }}>
                <PxTextButton
                  height="100%"
                  onClick={() => history.push(`${match.url}/addedList`)}
                >
                  {t("managerList.addedList")}
                </PxTextButton>
                <PxTextButton
                  height="100%"
                  onClick={() => history.push(`${match.url}/add`)}
                >
                  {t("managerList.addManager")}
                </PxTextButton>
              </span>
            )}
          </div>
        }
        // components={{
        //   Toolbar: (props) => {
        //     return <MTableToolbar style={{ width: "100%" }} {...props} />;
        //   },
        // }}
        columns={state.columns}
        data={state.data}
        // onRowClick={(evt, selectedRow) => {
        //   history.push(`${match.url}/${selectedRow?.id}`);
        // }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          // toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: true,
          defaultExpanded: false,
          search: true,
          selection: false,
          showSelectAllCheckbox: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
        onRowClick={(e, rowData) => {
          if (rowData) {
            history.push(`${match.url}/${rowData.id}`);
          }
        }}
      />
    </>
  );
};

export default ManagerList;
