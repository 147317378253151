import Manager from "../types/models/Manager";
import { AppActions } from "../store";
import { postRequest, getRequest } from "../utils/utils";

export const createManagerAction = (manager: Manager): AppActions => ({
  type: "CREATE_MANAGER",
  manager: manager,
});

export const getManagerAction = (manager: Manager): AppActions => ({
  type: "GET_MANAGER",
  manager: manager,
});

export const getManagersAction = (managers: Manager[]): AppActions => ({
  type: "GET_MANAGERS",
  managers: managers,
});

export const createManager = (manager: Manager) => {
  return postRequest(manager, "/api/userManagerAuth");
};

export const getManager = (code: string) => {
  return getRequest(null, "/api/userManagerAuth", getManagerAction, {
    code: code,
  });
};

export const getManagers = (subEventId: string, applicationId: string) => {
  return getRequest(null, "/api/userManagerAuth/managers", getManagersAction, {
    subEventId: subEventId,
    applicationId: applicationId,
  });
};
