import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { AppState } from "../../../../store";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";

import { Column, MTableToolbar } from "material-table";
import PxTable from "../../../../components/Tables/PxTable";
import SubEventHeading from "../../../../components/Typhography/SubEventHeading";
import { getApplicantCompanyList } from "../../../../actions/applicantCompany";
import { getSubEvent } from "../../../../actions/subEvents";
import PxFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import styled from "styled-components";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import { MenuItem, OutlinedInput } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const TableTitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryTitle = styled.span`
  margin-right: 10px;
`;

interface RowType {
  name: string;
  method: string;
  id: number;
  company: string;
  keywrod: string;
  type: string;
  note: string;
  businessCardId: number;
  category: string;
  createdDate: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

let test: string = "";

const ParticipantInfo: React.FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation("lang", { useSuspense: false });
  const param: { id: string } = useParams();
  const applicantCompanyList = useSelector(
    (state: AppState) => state.applicantCompany.applicantCompanyList
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [type, setType] = useState("");
  const [actionData, setActionData] = useState({
    subEventId: Number(param.id),
    type: type,
  });
  // const [actionData, setActionData] = useState<{
  //   subEventId: number;
  //   type: string;
  // }>();
  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: "구분",
        field: "type",
      },
      {
        title: "회사명",
        field: "company",
      },
      {
        title: "담당자",
        field: "name",
      },
      {
        title: "등록일",
        field: "createdDate",
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (subEvent?.id === undefined) {
      dispatch(getSubEvent(param.id));
    }
  }, [subEvent]);

  useEffect(() => {
    let tableData: any = [];
    if (
      applicantCompanyList !== undefined &&
      applicantCompanyList.content.length !== 0
    ) {
      applicantCompanyList.content.map((listItem) => {
        const rowData: RowType = {
          name: listItem.name,
          method: listItem.method,
          id: listItem.id,
          company: listItem.company,
          keywrod: listItem.keyword,
          note: listItem.note,
          businessCardId: listItem.businessCardId,
          category: listItem.category,
          type: listItem.type,
          createdDate: listItem.createdDate,
        };
        tableData.push(rowData);
      });

      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });

      dispatch(setTableInfoLoadingAction(false));
    }
  }, [applicantCompanyList]);

  // const selectBoxOnChange = (
  //   e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  // ) => {
  //   // setType(e.target.value as string);
  //   setType(e.target.value as string);
  //   test = e.target.value as string;
  //   console.log(`value : ${e.target.value}`);
  //   setActionData({
  //     // subEventId: Number(param.id),
  //     ...actionData,
  //     type: e.target.value as string,
  //   });
  // };

  useEffect(() => {
    console.log(`change actionData `, actionData);
    // console.log(`change type `, type);
  }, [actionData]);

  useEffect(() => {
    console.log(`change type `, type);
  }, [type]);

  const selectBoxOnChange = useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setType(e.target.value as string);
      setActionData({
        ...actionData,
        type: e.target.value as string,
      });
    },
    []
  );

  return (
    <>
      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return <MTableToolbar {...props} />;
          },
        }}
        // title={<SubEventHeading>기업 정보</SubEventHeading>}
        title={
          <TableTitleWrap>
            <CategoryTitle>구분</CategoryTitle>
            <PxFormControl>
              <PxSelectBox
                value={type}
                onChange={(e) => selectBoxOnChange(e)}
                displayEmpty
                input={<OutlinedInput margin="dense" />}
              >
                <MenuItem value={""}>전체</MenuItem>
                <MenuItem value={"seller"}>{subEvent?.sgroupName}</MenuItem>
                <MenuItem value={"buyer"}>{subEvent?.bgroupName}</MenuItem>
              </PxSelectBox>
            </PxFormControl>
          </TableTitleWrap>
        }
        columns={state.columns}
        data={state.data}
        getAction={getApplicantCompanyList}
        totalPage={applicantCompanyList?.totalPages}
        actionData={actionData}
        onRowClick={(evt, selectedRow) => {
          history.push(`participantsList/${selectedRow?.id}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: true,
          defaultExpanded: false,
          search: true,
          selection: true,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
      {console.log(`actionData : `, actionData)}
    </>
  );
};

export default ParticipantInfo;
