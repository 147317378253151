import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

// material ui custom comp
import GridContainer from "../../components/Grid/PxGridContainer";
import GridItem from "../../components/Grid/PxGridItem";
import PxButton from "../../components/Buttons/PxButton";

import HeadlineTypo from "../../components/Typhography/HeadlineTypo";

import CardGridContainer from "../../components/Grid/CardGrid/CardGridContainer";
import { AppState } from "../../store";
import { getEventsAll } from "../../actions/events";
import { Layout } from "../Layout/Layout";
import ButtonTypo from "../Typhography/ButtonTypo";
import { EventInfo } from "../../types/models/Event";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
`;

const Empty = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;

const EventList = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const eventList = useSelector((state: AppState) => state.events.events!);
  const [page, setPage] = useState<number>(eventList.number!);
  const [arr, setArr] = useState<Array<EventInfo>>([]);
  const [lastPage, setLastPage] = useState<boolean>(false);
  useEffect(() => {
    setArr([...eventList.content!]);
    setLastPage(eventList.last!);
    setPage(eventList.number!);
  }, [eventList]);
  useEffect(() => {
    if (eventList.number === 0 && eventList.content!.length <= 0) {
      dispatch(getEventsAll());
    }
  }, []);
  const addEvent = async (data: EventInfo[]) => {
    const result: any = await dispatch(getEventsAll(page + 1, true, data));
    if (result) {
      // setArr([...result.content!]);
      setPage(page + 1);
      setLastPage(result.last as boolean);
    }
  };

  const moreEventBtn = async (
    e: React.MouseEvent<HTMLButtonElement> | React.FC
  ) => {
    addEvent(arr);
  };

  return (
    <Layout>
      <Container>
        <GridContainer
          justify="space-between"
          alignItems="baseline"
          // parent={true}
        >
          <GridItem>
            <GridContainer direction="row">
              <GridItem className="pb-1">
                <HeadlineTypo>이벤트</HeadlineTypo>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <Contents>
            {(eventList && eventList.content!.length <= 0) ||
            eventList.content === undefined ? (
              <Empty>
                {t("myEvents.emptyList")}
                <br />
              </Empty>
            ) : (
              <CardGridContainer eventList={arr}></CardGridContainer>
            )}
          </Contents>
        </GridContainer>

        <GridContainer justify="center" className="p-4">
          {!lastPage && (
            <PxButton
              backgroundcolor="transparent"
              border={false}
              onClick={moreEventBtn}
            >
              <ButtonTypo txtcolor={"purple"}>{t("home.more")}</ButtonTypo>
            </PxButton>
          )}
        </GridContainer>
      </Container>
    </Layout>
  );
};

export default EventList;
