import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column } from "material-table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { categoryAndCheckboxSelectTextToLinkAndText } from "../../../../utils/utils";

// actions
import {
  getApplications,
  getCategoryAndKeywordRelatedApplications,
  getNextRiseApplicationList,
} from "../../../../actions/applications";

// material comp
import PxTable from "../../../../components/Tables/PxTable";
import MenuItem from "@material-ui/core/MenuItem";
import SubEventHeading from "../../../../components/Typhography/SubEventHeading";
import PxDropDown from "../../../../components/Menu/PxDropDown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";

interface RowType {
  applicationId: number;
  applicationName: string;
  applicationKeyword: string;
  companyIntroduce: string;
  irAddress: string;
  meetingPreference: number;
  meetingPurpose: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}
type actionData = MatchingDataType | GeneralDataType;

interface MatchingDataType {
  applicationId: string;
  subEventId: string;
  category: string;
  keyword: string[];
  type: string;
  pageSize?: number;
}

interface GeneralDataType {
  subEventId: string;
  type: string;
}

const ApplicationList: React.FC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  const applications = useSelector(
    (state: AppState) => state.applications.applications
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const [searchParam, setSearchParam] = useState("");
  const [actionData, setActionData] = useState<actionData>();
  const [matchChecked, setMatchChecked] = useState(false);

  const defaultColumn: TableStateType = {
    columns: [
      {
        title: t("companyList.type"),
        field: "applicationType",
        width: "100px",
        headerStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingLeft: 0,
        },
        cellStyle: {
          overflow: "hidden",
          paddingLeft: 0,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("companyList.companyName"),
        field: "applicationName",
        width: "240px",
        headerStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        cellStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("companyList.manager"),
        field: "applicationManager",
        width: "240px",
        headerStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        cellStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("companyList.category"),
        field: "applicationCategory",
        width: "240px",
        headerStyle: {
          whiteSpace: "nowrap",
          width: "240px !important",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("companyList.keyword"),
        field: "applicationKeyword",
        width: "240px",
        headerStyle: {
          whiteSpace: "nowrap",
          width: "240px !important",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("companyList.note"),
        field: "applicationNote",
        width: "240px",
        headerStyle: {
          whiteSpace: "nowrap",
          width: "240px !important",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    ],
    data: [],
  };

  const [state, setState] = React.useState<TableStateType>(defaultColumn);

  // useEffect(() => {
  //   if (
  //     matchChecked &&
  //     subEvent?.matching === "Y" &&
  //     subEventApplication.subEventId !== ""
  //   ) {
  //     matchCheckedLoadData();
  //   }
  // }, [dispatch, matchChecked, subEventApplication, param.id, subEvent]);

  // const matchCheckedLoadData = async () => {
  //   const applicationKeywords = subEventApplication.keyword!.split(",");

  //   await dispatch(
  //     getCategoryAndKeywordRelatedApplications({
  //       applicationId: subEventApplication.id!,
  //       subEventId: param.id,
  //       category: subEventApplication.category!,
  //       keyword: applicationKeywords,
  //       type: subEventApplication.type!,
  //     })
  //   );
  //   dispatch(setTableInfoLoadingAction(false));
  // };

  useEffect(() => {
    if (subEventApplication.type && subEvent?.targetScope === "others") {
      setActionData({
        subEventId: param.id,
        type: subEventApplication.type === "buyer" ? "seller" : "buyer",
      });
    } else if (subEvent?.targetScope === "") {
      setActionData({
        subEventId: param.id,
        type: searchParam,
      });
    }
  }, [param.id, searchParam, subEvent, subEventApplication.type]);

  useEffect(() => {
    changeActionData(searchParam);
  }, [matchChecked, subEventApplication]);

  useLayoutEffect(() => {
    let tableData: any = [];
    if (
      applications &&
      Array.isArray(applications.content) &&
      applications.content.length > 0 &&
      subEvent?.eventId !== ""
    ) {
      applications.content.forEach((application) => {
        // 내 기업정보 제외
        if (application.id !== subEventApplication.id) {
          const rowData = {
            applicationApplicantId: application.applicantId,
            applicationApproval: application.approval,
            applicationCategory: application.category
              ? categoryAndCheckboxSelectTextToLinkAndText(
                  application.category!,
                  false
                )
              : "",
            applicationId: application.id,
            applicationKeyword: application.keyword,
            applicationMethod:
              application.method === "pre" ? "사전예약" : "현장등록",
            applicationName: application.name,
            applicationManager: application.applicants[0].businessCardId?.name,
            applicationType:
              application.type === "seller"
                ? subEvent?.sgroupName
                : subEvent?.bgroupName,
            applicationCreatedDate: moment(application.createdDate).format(
              "YYYY-MM-DD"
            ),
            applicationNote: application.note,
            meetingPreference: application.meetingPreference,
            meetingPurpose: application.meetingPurpose,
          };
          tableData.push(rowData);
        }
      });
      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [applications, subEvent]);

  const companyList = [
    {
      value: "",
      label: t("companyList.all"),
    },
    {
      value: "seller",
      label: subEvent?.sgroupName,
    },
    {
      value: "buyer",
      label: subEvent?.bgroupName,
    },
  ];

  const handeMatchChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      localStorage.setItem("applicationMatch", "Y");
    } else {
      dispatch(
        getApplications({
          subEventId: param.id,
          type: subEventApplication.type === "buyer" ? "seller" : "buyer",
        })
      );
      localStorage.removeItem("applicationMatch");
    }
    setMatchChecked(event.target.checked);
  };

  const onChangeSearchParam = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    const value = e.currentTarget.id;
    setSearchParam(value);
    changeActionData(value);
  };

  const changeActionData = useCallback(
    (value) => {
      matchChecked && subEvent?.matching === "Y"
        ? setActionData({
            applicationId: subEventApplication.id!,
            subEventId: param.id,
            category: subEventApplication.category!,
            keyword: subEventApplication.keyword!.split(","),
            type: subEventApplication.type!,
          })
        : value === actionData?.type &&
          setActionData({
            subEventId: param.id,
            type: value,
          });
    },
    [param.id, subEventApplication, matchChecked, subEvent]
  );

  return subEvent !== undefined &&
    subEventApplication.type &&
    actionData !== undefined ? (
    <>
      <PxTable<RowType>
        title={
          <div>
            <SubEventHeading>{t("companyList.participant")}</SubEventHeading>
            {subEvent.targetScope === "" && (
              <>
                <PxDropDown
                  label={
                    companyList.find((option) => option.value === searchParam)
                      ?.label
                  }
                >
                  {companyList.map((option) => (
                    <MenuItem
                      style={{ display: "inherit" }}
                      id={option.value}
                      key={option.value}
                      value={option.value}
                      onClick={onChangeSearchParam}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </PxDropDown>
              </>
            )}
            {subEvent?.matching === "Y" && (
              <FormControlLabel
                style={{ paddingLeft: 20 }}
                control={
                  <Switch
                    checked={matchChecked}
                    onChange={handeMatchChecked}
                    name="Match"
                    color="primary"
                  />
                }
                // labelPlacement="top"
                label="Match"
              />
            )}
          </div>
        }
        columns={state.columns}
        data={state.data}
        totalPage={applications.totalPages}
        totalElements={applications.totalElements}
        getAction={
          matchChecked && subEvent?.matching === "Y"
            ? getCategoryAndKeywordRelatedApplications
            : getApplications
        }
        actionData={actionData}
        onRowClick={(evt, selectedRow) => {
          evt?.preventDefault();
          history.push(`${match.url}/${selectedRow?.applicationId}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: true,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  ) : null;
};

export default ApplicationList;
