import React, { useEffect, useState } from "react";
import Participant from "./Participant";
import { makeStyles, styled as mStyled } from "@material-ui/core/styles";
import useVideoContext from "../../hooks/useVideoContext";
import useParticipants from "../../hooks/useParticipants";
import useSelectedParticipant from "../VideoProvider/hooks/useSelectedParticipant";
import styled from "styled-components";

import GroupsIcon from "../../../assets/icons/GroupsIcon.svg";
import ReorderIcon from "@material-ui/icons/Reorder";
import ViewStreamIcon from "@material-ui/icons/ViewStream";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useAppState } from "../AppStateProvider/hooks/useAppState";
import useMainSpeaker from "../../hooks/useMainSpeaker";
import Body1Typo from "../../../components/Typhography/Body1Typo";
import { LocalAudioTrack } from "twilio-video";
import ToggleBlackListBtn from "../ToggleBlackListButton/ToggleBlackListBtn";

export default function ParticipantStripForMany() {
  const {
    room: { localParticipant },
    localTracks,
  } = useVideoContext();
  const participants = useParticipants();
  const mainParticipant = useMainSpeaker();
  const classes = useStyles();
  const { PRESENTER_LANG, user, acceptHands } = useAppState();
  const [listMode, setListMode] = useState<boolean>(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const category = useSelector(
    (state: AppState) => state.subEvents.subEvent?.category
  );
  const audioTrack = localTracks.find(
    (track) => track.kind === "audio"
  ) as LocalAudioTrack;
  const [
    selectedParticipant,
    setSelectedParticipant,
  ] = useSelectedParticipant();

  useEffect(() => {
    if (matches === true && category === "presentation") setListMode(true);
    if (matches === false) setListMode(false);
  }, [matches, category]);

  useEffect(() => {
    if (
      user?.eventType === "presentation" &&
      participants.some((data) =>
        PRESENTER_LANG.some((p) => data.identity.includes(p))
      ) &&
      acceptHands?.sid != localParticipant.sid
    ) {
      audioTrack.disable();
    }
  }, [participants]);
  return (
    <Container>
      <ScrollContainer>
        <SideNav>
          <>
            <img
              alt="groupIcon"
              src={GroupsIcon}
              width="24"
              height="24"
              style={{ marginRight: 8 }}
            />
            <Body1Typo txtcolor="white0.56">
              {participants.length + 1}
            </Body1Typo>
          </>
          {user?.role === "presenter" && <ToggleBlackListBtn />}

          <ListRadio>
            <ViewStreamIcon
              className={classes.icon}
              style={{
                marginRight: 24,
                color: `${!listMode ? "rgb(252, 178, 30)" : "#a9aaad"}`,
              }}
              onClick={() => setListMode(false)}
            />
            <ReorderIcon
              className={classes.icon}
              style={{
                color: `${listMode ? "rgb(252, 178, 30)" : "#a9aaad"}`,
              }}
              onClick={() => setListMode(true)}
            />
          </ListRadio>
        </SideNav>

        {mainParticipant &&
          user?.role !== "presenter" &&
          PRESENTER_LANG.some((data) =>
            mainParticipant.identity.includes(data)
          ) && (
            <VideoItem>
              <Participant
                participant={mainParticipant}
                isSelected={selectedParticipant === mainParticipant}
                onClick={() => setSelectedParticipant(mainParticipant)}
                listMode={listMode}
              />
            </VideoItem>
          )}
        <VideoItem>
          <Participant
            participant={localParticipant}
            isSelected={selectedParticipant === localParticipant}
            onClick={() => setSelectedParticipant(localParticipant)}
            listMode={listMode}
            isLocal={true}
          />
        </VideoItem>
        {participants.map(
          (participant) =>
            !PRESENTER_LANG.some(
              (data) => participant.identity.includes(data) //발표자 제외
            ) && (
              <VideoItem>
                <Participant
                  key={participant.sid}
                  participant={participant}
                  isSelected={selectedParticipant === participant}
                  onClick={() => setSelectedParticipant(participant)}
                  listMode={listMode}
                />
              </VideoItem>
            )
        )}
      </ScrollContainer>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#000000",
    flexDirection: "row",
    height: theme.mainNavBarHeight,
    alignItems: "center",
  },
  chip: {
    float: "right",
    padding: 7,
    minWidth: 100,
    fontSize: "1rem",
    borderColor: "rgb(75, 76, 78)",
    color: "white",
    fontFamily: "NotoSansCJKkr",
    "& span": {
      padding: 0,
      transform: "translate(0%, 10%)",
    },
  },
  icon: {
    width: 36,
    height: 36,
    padding: 8,
  },
}));

const Container = mStyled("aside")(({ theme }) => ({
  padding: "0.5em",
  // backgroundColor: "#0000002e",
  [theme.breakpoints.down("xs")]: {
    overflowY: "initial",
    overflowX: "auto",
    padding: 0,
    display: "flex",
  },
}));

const ScrollContainer = mStyled("div")(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    display: "flex",
  },
}));

const SideNav = styled.span`
  align-items: center;
  background-color: rgb(40, 41, 42);
  height: 56px;
  position: sticky;
  display: flex;
  z-index: 2;
  flex-direction: row;
  top: -8px;
  @media only screen and (max-width: 599px) {
    display: none;
  }
`;

const ListRadio = styled.span`
  position: absolute;
  right: 24px;
`;

const VideoItem = styled.div`
  max-height: 138px;
  video {
    max-height: 143px;
  }
`;
