import { useEffect, useState } from "react";
import { RemoteParticipant } from "twilio-video";
import { useAppState } from "../components/AppStateProvider/hooks/useAppState";
import useVideoContext from "./useVideoContext";

export default function useParticipants() {
  const { room } = useVideoContext();
  const [participants, setParticipants] = useState(
    Array.from(room.participants.values())
  );
  const {user, setWaitingForRequest, setAcceptHands,PRESENTER_LANG} = useAppState();

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    const participantDisconnected = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  //발표자 없을때 손내리고 , 연결끊기
  useEffect(() => {
    if (
      !PRESENTER_LANG.some((p) => participants.some(data => data.identity.includes(p))) &&
      user?.eventType === "presentation" && user.role !== "presenter"
    ) {
      setWaitingForRequest(false);
      setAcceptHands(undefined);
    }
  },[participants])

  return participants;
}
