import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import {
  MeetingInfo,
  ChangeMeetingStateInterface,
  MeetingVideo,
  MeetingReqInfo,
  MeetingReqData,
  MeetingReqStatus,
  MeetingReqStatusParam,
} from "../types/models/Meeting";
import { Application } from "../types/models/Application";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert, getRequest, getRequestAndReturn, postRequest } from "../utils/utils";
import { CounselFormAnswer } from "../types/models/CounselForm";
import i18n from "../i18n/i18n";
import { RemoveImpossibleList } from "../views/subEvent/content/Meeting/MeetingDetail";

// 미사용중
export const createMeetingAction = (meeting: MeetingInfo): AppActions => ({
  type: "CREATE_MEETING",
  meeting,
});

export const getMeetingsAction = (meetings: MeetingInfo[]): AppActions => ({
  type: "GET_MEETINGS",
  meetings,
});

// 미사용중
export const getMeetingAction = (meeting: MeetingInfo): AppActions => ({
  type: "GET_MEETING",
  meeting,
});

export const getMeetingVideoAction = (
  meetingVideo: MeetingVideo
): AppActions => ({
  type: "SET_MEETING_VIDEO",
  meetingVideo,
});

export const getMeetingTargetsAction = (
  meetingTargets: Application[]
): AppActions => ({
  type: "GET_MEETING_TARGETS",
  meetingTargets,
});

export const getWaitingAcceptMeetingTargetsAction = (
  waitingAcceptMeetingTargets: Application[]
): AppActions => ({
  type: "GET_WAITING_ACCEPT_MEETING_TARGETS",
  waitingAcceptMeetingTargets,
});

export const getMeetingCounselListAction = (
  meetings: MeetingInfo[]
): AppActions => ({
  type: "GET_MEETING_COUNSEL_LIST",
  meetings,
});

export const resetMeetingTargetsAction = (): AppActions => ({
  type: "MEETING_TARGETS_RESET",
});

export const resetWaitingAcceptMeetingTargetsAction = (): AppActions => ({
  type: "WAITING_ACCEPT_MEETING_TARGETS_RESET",
});

export const resetMeetingsAction = (): AppActions => ({
  type: "MEETINGS_RESET",
});

export const resetMeetingAction = (): AppActions => ({
  type: "MEETING_RESET",
});

export const updateApplicantMeetingSurvey = (
  meetingSurvey: CounselFormAnswer[]
): AppActions => ({
  type: "UPDATE_APPLICANT_MEEETING_SURVEY",
  meetingSurvey: meetingSurvey,
});

export const updateAcceptorMeetingSurvey = (
  meetingSurvey: CounselFormAnswer[]
): AppActions => ({
  type: "UPDATE_ACCEPTOR_MEEETING_SURVEY",
  meetingSurvey: meetingSurvey,
});

export const resetMeetingsStateAction = (): AppActions => ({
  type: "MEETING_STATE_RESET",
});

// 미사용중
export const createMeeting =
  (meeting: MeetingInfo) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
    };

    const body = JSON.stringify(meeting);

    const path = `/api/meetings`;
    try {
      var res: AxiosResponse<Event> = await axios.post(path, body, config);

      return "success";
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return "error";
    }
  };

// 미사용중
export const getMeetings =
  <Meeting>(subEventId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/meetings?subEventId=${subEventId}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getMeetingsAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

// 미사용중
export const getMeeting =
  <Meeting>(meetingId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/meetings/detail?meetingId=${meetingId}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getMeetingAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

// 미팅현황 - 미팅 대상 리스트 조회
export const getMeetingTargets =
  <Meeting>(
    meetingId: string,
    subEventId: string,
    applicationId: string,
    type: string,
    searchName: string,
    searchKeyword: string,
    searchCategory: string
  ) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/applications/getMeetingTargets?meetingId=${meetingId}&subEventId=${subEventId}&applicationId=${applicationId}&type=${type}&searchName=${searchName}&searchKeyword=${searchKeyword}&searchCategory=${searchCategory}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getMeetingTargetsAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

// 미팅현황 - 미팅 대상 리스트 초기화
export const meetingTargetsActionStateToInitial =
  () => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(resetMeetingTargetsAction());
  };

// 미팅 수락대기 리스트 조회
export const getWaitingAcceptMeetingTargets =
  <Meeting>(meetingId: string, applicationId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/meetings/getWaitingAcceptMeetingTargets?meetingId=${meetingId}&applicationId=${applicationId}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getWaitingAcceptMeetingTargetsAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

// 미팅 수락대기 리스트 조회 리스트 초기화
export const waitingAcceptMeetingTargetsActionStateToInitial =
  () => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(resetWaitingAcceptMeetingTargetsAction());
  };

// 미팅 신청
export interface MeetingApplicantDataInterface {
  subEventId: string;
  applicant: string;
  acceptor: string;
  meetingId: string;
  status: string;
  mailPostToggleAt?: string;
}
export const applicationMeeting =
  (meetingApplicantData: MeetingApplicantDataInterface) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
    };

    const body = JSON.stringify(meetingApplicantData);

    const path = `/api/meetings/applicant`;
    try {
      await axios.post(path, body, config);

      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  };

// 미팅 신청 취소
export const cancelApplicationMeeting =
  (meetingsId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
      params: {},
      data: {},
    };

    const path = `/api/meetings/cancelApplicationMeeting/${meetingsId}/true`;
    try {
      const res: AxiosResponse = await axios.delete(path, config);
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  };

// 사용자 미팅 불가시간 제거
export const removeUserImpossibleMeeting =
  (meetingsId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
      params: {},
      data: {},
    };
    const path = `/api/meetings/removeImpossible/${meetingsId}/true"`;

    try {
      const res: AxiosResponse = await axios.delete(path, config);
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  };

export const impossibleMeetingList =
  (meetingApplicantData: MeetingApplicantDataInterface[]) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
    };

    const body = JSON.stringify(meetingApplicantData);

    const path = `/api/meetings/multipleImpossibleSave`;
    try {
      var res: AxiosResponse = await axios.post(path, body, config);
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
    };
  
    export const removeImpossibleMeetingList =
  (meetingIds: RemoveImpossibleList) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
    };

    const body = JSON.stringify(meetingIds);

    const path = `/api/meetings/multipleRemoveImpossible`;
    try {
      var res: AxiosResponse = await axios.post(path, body, config);
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  };

export interface RemoveUserImpossibleDayInterface {
  date: string;
  subEventId: string;
  applicantId: string;
  userId: number;
}

export const removeUserImpossibleDay =
  (data: RemoveUserImpossibleDayInterface) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "en" ? "en" : "ko",
      },
      params: {},
      data: {},
    };

    const path = `/api/meetings/removeImpossibleDay`;
    const body = JSON.stringify(data);

    try {
      const res: AxiosResponse = await axios.post(path, body, config);
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  };

// 내 미팅 신청리스트 조회
export const getMyMeetings =
  <Meeting>(applicationId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "ko" ? "ko" : "en",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/meetings/myMeeting?applicationId=${applicationId}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getMeetingsAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      if(error){
        dispatch(setAlertAction(alert));
        setTimeout(() => {
          dispatch(removeAlertAction(alert.id));
        });
      }
      
    }
  };

// 미팅 목록 초기화
export const meetingsActionStateToInitial =
  () => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(resetMeetingsAction());
  };

// 미팅 수락 or 거절
export const changeMeetingState =
  (changeMeetingStateData: ChangeMeetingStateInterface) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": i18n.language === "ko" ? "en" : "en",
      },
    };

    const body = JSON.stringify(changeMeetingStateData);

    const path = `/api/meetings/changeMeetingState`;
    try {
      var res: AxiosResponse<Event> = await axios.put(path, body, config);

      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  };

export const getMeetingVideo = (meetingId: number, applicantId: number) => {
  return getRequestAndReturn(
    "/api/meetings/videoCallCheck",
    {
      meetingId: meetingId,
      applicantId: applicantId,
    },
    getMeetingVideoAction
  );
};

export const getMeetingCounselList = (applicationId: number) => {
  return getRequest(
    null,
    "/api/meetings/meetingSurveyList",
    getMeetingCounselListAction,
    {
      applicationId: applicationId,
    }
  );
};

export const getApplicantMeetingHistory = (applicantId: string) => {
  return getRequestAndReturn("/api/meetingHistories/applicant", {
    applicantId: applicantId,
  });
};

export const getApplicantCancelMeetingHistory = (applicantId: string) => {
  return getRequestAndReturn("/api/meetingHistories/cancel", {
    applicantId: applicantId,
  });
};
