import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface SubHeadingTypoProps {
  fontweight?: string;
  txtcolor?: "rgba(0,0,0,0.87)" | "purple" | "black0.6";
  lineHeight?: number;
  fontSize?: string;
}

type Prop = SubHeadingTypoProps & TypographyProps;

const SuperHeadTypo: React.FC<Prop> = styled((props: Prop) => {
  const { fontweight, txtcolor, lineHeight, fontSize, ...rest } = props;
  return <Typography {...rest} />;
})`
  word-break: keep-all;
  font-size: ${(props) => {
    return props.fontSize ?? "34px";
  }};
  font-weight: ${(props) => {
    return props.fontweight === undefined ? "normal" : props.fontweight;
  }};
  font-style: normal;
  line-height: ${(props) => {
    return props.lineHeight === undefined ? "1.6" : props.lineHeight;
  }};
  letter-spacing: -0.4px;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }

    return props.txtcolor;
  }};
`;

SuperHeadTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default SuperHeadTypo;
