import React, { useEffect } from "react";
import { FormControl, MenuItem, Typography, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAudioInputDevices } from "../deviceHooks/deviceHooks";
import useVideoContext from "../../../hooks/useVideoContext";
import { LocalAudioTrack } from "twilio-video";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
});

export default function AudioInputList() {
  const classes = useStyles();
  const audioInputDevices = useAudioInputDevices();
  const {
    room: { localParticipant },
    localTracks,
    getLocalAudioTrack,
  } = useVideoContext();
  const [t] = useTranslation("lang", {useSuspense:false});

  const localAudioTrack = localTracks.find(
    (track) => track.kind === "audio"
  ) as LocalAudioTrack;
  const localAudioInputDeviceId =
    localAudioTrack?.mediaStreamTrack.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    localAudioTrack?.stop();
    getLocalAudioTrack(newDeviceId).then((newTrack) => {
      if (localAudioTrack) {
        const localTrackPublication =
          localParticipant?.unpublishTrack(localAudioTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit("trackUnpublished", localTrackPublication);
      }

      localStorage.setItem("audioInputDeviceId", newDeviceId);

      localParticipant?.publishTrack(newTrack);
    });
  }

  return (
    <div className={classes.container}>
      <div className="inputSelect">
        {audioInputDevices.length > 1 ? (
          <FormControl fullWidth>
            <Typography variant="h6">{t("videoController.audioInput")}</Typography>
            <Select
              onChange={(e) => replaceTrack(e.target.value as string)}
              value={localAudioInputDeviceId || ""}
            >
              {audioInputDevices.map((device) => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <>
            <Typography variant="h6">{t("videoController.audioInput")}</Typography>
            <Typography>
              {localAudioTrack?.mediaStreamTrack.label || "No Local Audio"}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}
