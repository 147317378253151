import Mail from "../types/models/Mail";
import MailActionTypes from "../types/actions/MailAction";

const mailDefaultState: Mail = {
  mail: undefined,
  mails: {
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
  },
};

const mails = (state = mailDefaultState, action: MailActionTypes): Mail => {
  switch (action.type) {
    case "CREATE_MAIL":
    case "GET_MAIL":
      return { mail: action.mail };
    case "GET_MAILS":
      return {
        ...state,
        mails: action.mails,
      };
  
    default:
      return state;
  }
};

export default mails;
