import React, { createContext, useContext, useState } from "react";
import {
  LocalAudioTrack,
  LocalDataTrack,
  LocalParticipant,
  LocalVideoTrack,
  RemoteParticipant,
  TwilioError,
} from "twilio-video";
import axios, { AxiosRequestConfig } from "axios";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { useTranslation } from "react-i18next";
import { Application } from "../../../types/models/Application";
import { getBlackList as getChatBlackList } from "../ChatProvider/ChatApi";
import { ChatContext } from "../ChatProvider/ChatProvider";
import { getBlackList } from "../../hooks/useSubscriptionUpdate";

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(): Promise<
    { token: string; user?: StateContextType["user"] } | string
  >;
  user?: {
    identity: string;
    roomName: string;
    eventType: "presentation" | "meeting";
    type: Application["type"] | "interpreter";
    role?: "none" | "presenter";
  };
  PRESENTER_LANG: Array<string>;
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  isSurveyFormEnabled: boolean;
  isMultiScreenEnabled: boolean;
  isVideoEnabled: boolean;
  setVideoEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSurveyFormEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMultiScreenEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setEmojiMessage: React.Dispatch<React.SetStateAction<Emoji>>;
  emojiMessage: Emoji;
  handsUp: Array<HandsUp>;
  waitingForRequest: boolean;
  roomSId: string;
  acceptHands: LocalParticipant | RemoteParticipant | undefined;
  blackList: string[];
  videoStopList: string[];
  unMuteList: string[];
  hostVideoStop: boolean;
  selfVideoStop: boolean;
  hostMute: boolean;
  selfMute: boolean;
  setActiveSinkId(sinkId: string): void;
  setHandsUp: React.Dispatch<React.SetStateAction<Array<HandsUp>>>;
  setWaitingForRequest: React.Dispatch<React.SetStateAction<boolean>>;
  setRoomSId: React.Dispatch<React.SetStateAction<string>>;
  setAcceptHands: React.Dispatch<
    React.SetStateAction<LocalParticipant | RemoteParticipant | undefined>
  >;
  setBlackList: React.Dispatch<React.SetStateAction<string[]>>;
  setUnMuteList: React.Dispatch<React.SetStateAction<string[]>>;
  setVideoStopList: React.Dispatch<React.SetStateAction<string[]>>;
  setHostVideoStop: React.Dispatch<React.SetStateAction<boolean>>;
  setSelfVideoStop: React.Dispatch<React.SetStateAction<boolean>>;
  setHostMute: React.Dispatch<React.SetStateAction<boolean>>;
  setSelfMute: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StateContext = createContext<StateContextType>(null!);

export interface Emoji {
  emoji:
    | "thumbsUp"
    | "clappingHands"
    | "grinningFace"
    | "purpleHeart"
    | "lightBulb"
    | "foldedHands"
    | "pensiveFace"
    | "faceWithTearOfJoy"
    | "faceWithOpenMouth"
    | "";
  num: number;
}

export interface HandsUp {
  audioTrack: LocalAudioTrack;
  videoTrack: LocalVideoTrack;
  dataTrack: LocalDataTrack;
  participant: RemoteParticipant | LocalParticipant;
}

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [t] = useTranslation("lang", { useSuspense: false });
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState("default");
  const [emoji, setEmoji] = useState<Emoji>({ emoji: "", num: 0 });
  const [waitingForRequest, setWaitingForRequest] = useState<boolean>(false);
  const [isSurveyFormEnabled, toggleSurveyFormEnabled] =
    useState<boolean>(false);
  const [isMultiScreenEnabled, toggleMultiscreenEnabled] =
    useState<boolean>(false);
  const { setChatBlock } = useContext(ChatContext);
  const [handsUp, setHandsUp] = useState<Array<HandsUp>>([]);
  const [roomSId, setRoomSId] = useState<string>("");
  const [isVideoEnabled, setVideoEnabled] = useState<boolean>(true);
  const [selfVideoStop, setSelfVideoStop] = useState<boolean>(false);
  const [videoStopList, setVideoStopList] = useState<string[]>([]);
  const [unMuteList, setUnMuteList] = useState<string[]>([]);
  const [hostVideoStop, setHostVideoStop] = useState<boolean>(false);
  const [hostMute, setHostMute] = useState<boolean>(false);
  const [selfMute, setSelfMute] = useState<boolean>(false);
  const [user, setUser] = useState<StateContextType["user"]>();
  const [acceptHands, setAcceptHands] = useState<
    LocalParticipant | RemoteParticipant | undefined
  >();
  const [blackList, setBlackList] = useState<string[]>([]);
  const PRESENTER_LANG = ["발표자", "Presenter"];
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  ); // id: applicationId(기업ID)
  const meetingVideo = useSelector(
    (state: AppState) => state.meetings.meetingVideo
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const interpreter = useSelector(
    (state: AppState) => state.interpreters.interpreter
  );
  const webinar = useSelector((state: AppState) => state.webinars.webinar);
  const { previousPath } = useSelector((state: AppState) => state.routePath);
  const myBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    isSurveyFormEnabled,
    toggleSurveyFormEnabled,
    isMultiScreenEnabled,
    toggleMultiScreenEnabled: toggleMultiscreenEnabled,
    isVideoEnabled,
    hostVideoStop,
    user,
    PRESENTER_LANG,
    emojiMessage: emoji,
    handsUp,
    waitingForRequest,
    roomSId,
    acceptHands,
    blackList,
    videoStopList,
    unMuteList,
    selfVideoStop,
    selfMute,
    hostMute,
    setVideoEnabled,
    setEmojiMessage: setEmoji,
    setHandsUp,
    setWaitingForRequest,
    setRoomSId,
    setAcceptHands,
    setBlackList,
    setVideoStopList,
    setUnMuteList,
    setHostVideoStop,
    setSelfVideoStop,
    setSelfMute,
    setHostMute,
  } as StateContextType;

  contextValue = {
    ...contextValue,
    getToken: () => getToken(),
  };

  const getToken: StateContextType["getToken"] = async () => {
    try {
      let applicantId: string; // 창가자id
      let roomId: string; // 비디오룸id
      let user: StateContextType["user"];
      if (previousPath.includes("interpreter")) {
        // 통역사 미팅룸
        applicantId = `${interpreter?.name} (${t(
          "interpreterSubEvent.interpreter"
        )})`;
        roomId = `${meetingVideo?.subEventId}|${meetingVideo?.id}`;
        user = {
          identity: applicantId,
          roomName: roomId,
          eventType: "meeting",
          type: "interpreter",
        };
      } else if (previousPath.includes("webinar")) {
        // 웨비나 비디오룸
        const usertype =
          subEventApplication.type === "buyer"
            ? t("webinar.presenter")
            : t("webinar.attendee");
        if (myBusinessCard === undefined) {
          applicantId = usertype;
        } else {
          applicantId = `${myBusinessCard.name} (${usertype})|${myBusinessCard.company}`;
        }
        roomId = `${webinar?.subEventId}|${webinar?.id}`;
        user = {
          identity: applicantId,
          roomName: roomId,
          eventType: "presentation",
          type: subEventApplication.type,
          role: subEventApplication.type === "buyer" ? "presenter" : "none",
        };
        if (subEventApplication.type !== "buyer") setHostMute(true);
      } else {
        // 미팅 비디오룸
        if (myBusinessCard === undefined) {
          applicantId = `(${subEventApplication.type})`;
        } else {
          applicantId = `${myBusinessCard.name} (${
            subEventApplication.type === "buyer"
              ? subEvent?.bgroupName
              : subEvent?.sgroupName
          })|${myBusinessCard.company}|${myBusinessCard.id}`;
        }
        roomId = `${meetingVideo?.subEventId}|${meetingVideo?.id}`;
        user = {
          identity: applicantId,
          roomName: roomId,
          eventType: "meeting",
          type: subEventApplication.type,
        };
      }

      setUser(user);
      getVideoBlackList(roomId);
      const config: AxiosRequestConfig = {
        params: {
          applicantId: applicantId,
          meetingId: roomId,
        },
      };

      const url =
        process.env.NODE_ENV === "production"
          ? "/video/videoCall/token"
          : "http://localhost:3001/video/videoCall/token";
      const res = await axios.get(url, config);
      const result: any = await getChatBlackList(roomId);
      if (
        result.find(
          (data: string) =>
            data === `${myBusinessCard?.name}|${myBusinessCard?.company}`
        )
      ) {
        setChatBlock(true);
      }
      setIsFetching(true);

      const token = res.data as string;
      return { token: token, user: user };
    } catch (err) {
      if (err.response) {
        if (err.response.status === 403) {
          alert(t("messageManager.refusedEntry"));
          window.history.back();
        }
        console.log(err);
      }

      setIsFetching(false);
      return "failed";
    }
  };

  const getVideoBlackList = async (roomId: string) => {
    const blackListReuslt = await getBlackList(roomId);
    if (blackListReuslt) {
      setBlackList(blackListReuslt);
    }
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken }}>
      {props.children}
    </StateContext.Provider>
  );
}
