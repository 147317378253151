import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AppActions, AppState } from "../../store";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";

// actions
import { getAuthToken, LoginInfo, getUser } from "../../actions/users";

// bs comp
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// material ui comp
import Container from "@material-ui/core/Container";

// custom ui comp
import OutlinedTxtField from "../../components/Inputs/OutlinedTxtField";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import PxButton from "../../components/Buttons/PxButton";
import User from "../../types/models/User";
import { useTranslation } from "react-i18next";
import { set } from "lodash";
import { changeImgPathToCloudfrontPath } from "../../utils/utils";

interface LoginForm {
  email: string;
  password: string;
}

type Props = reduxStateProps & DispatchProps;

const Login: React.FC<Props> = (props) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const lastLocation = useLastLocation();
  const { users, getUser } = props;
  const [loginForm, setLoginForm] = useState<LoginForm>({
    email: "",
    password: "",
  });
  const [isCapsLock, setIsCpasLock] = useState<boolean>(false);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const event = useSelector(
    (state: AppState) => state.subEvents.subEvent?.mainEvent
  );

  useEffect(() => {
    if (users.isAuthenticated === true) {
      getUser(localStorage.userId);
    }
  }, [users, getUser]);

  // user 로그인 성공 시 Redirect
  if (users.userId) {
    let redirectPath;
    if (lastLocation?.pathname === undefined) {
      redirectPath = "/";
    } else {
      // 비밀번호 변경 페이지에서 로그인 성공 후의 redirect 페이지
      if (lastLocation.pathname.includes("changePwd")) {
        redirectPath = "/";
      } else {
        redirectPath = lastLocation.pathname;
      }
    }

    return <Redirect to={redirectPath} />;
    // return <Redirect to={redirectPath === undefined ? "/" : redirectPath} />;
  }

  const handleOnChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setLoginForm({
      ...loginForm,
      [event.target.name as string]: event.target.value as string,
    });
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const s = String.fromCharCode(event.keyCode);
    if (
      s.toUpperCase() === s &&
      s.toLowerCase() !== s &&
      !event.shiftKey &&
      event.key.toLowerCase() !== event.key
    ) {
      setIsCpasLock(true);
    } else {
      setIsCpasLock(false);
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.getAuthToken(loginForm);
  };

  return (
    <Container maxWidth="xs">
      {subEvent !== undefined &&
        subEvent.mainEvent !== undefined &&
        subEvent?.eventId !== "" && (
          <>
            {/* <TitleTypo style={{ textAlign: "center" }} gutterBottom>
              {subEvent.mainEvent.name}
            </TitleTypo> */}
            <img
              src={
                subEvent.mainEvent.mainImageFile === null ||
                subEvent.mainEvent.mainImageFile === undefined
                  ? changeImgPathToCloudfrontPath(subEvent.mainEvent.image)
                  : changeImgPathToCloudfrontPath(
                      subEvent.mainEvent.mainImageFile.filePath
                    )
              }
              alt={subEvent.mainEvent.name}
              style={{ width: "100%", marginBottom: 20, cursor: "pointer" }}
              onClick={() => {
                history.push(`/event/detail/${subEvent.mainEvent?.id}`);
              }}
            />
          </>
        )}
      <form onSubmit={onSubmit}>
        <OutlinedTxtField
          label={t("login.email")}
          name="email"
          value={loginForm.email}
          fullWidth
          padding="0px 0px 20px 0px"
          onChange={handleOnChange}
        />
        <OutlinedTxtField
          label={t("login.password")}
          type="password"
          name="password"
          onKeyDown={handleOnKeyDown}
          style={{ imeMode: "disabled" }}
          value={loginForm.password}
          helperText={isCapsLock ? "CapsLock 키가 눌려있습니다." : ""}
          fullWidth
          padding="0px 0px 20px 0px"
          onChange={handleOnChange}
        />
        <PxButton
          className="my-4 p-2"
          fullWidth
          backgroundcolor="purple"
          type="submit"
        >
          <ButtonTypo className="font-weight-bold">
            {t("login.login")}
          </ButtonTypo>
        </PxButton>
      </form>
      {/* <Link to="/">
        <span className="text-muted">{t("login.needHelp?")}</span>
      </Link> */}
      <p />
      <Link to="/auth/findUser">
        <span className="text-muted">{t("login.findIdAndPW")}</span>
      </Link>
      {event && event.showLogo !== "N" ? (
        <Row className="mt-3">
          <Col>
            <span>{t("login.pexpoUser?")} </span>
            <Link to="/auth/register">
              <span className="text-muted"> {t("login.register")}</span>
            </Link>
          </Col>
        </Row>
      ) : (
        <Row className="mt-3">
          <Col>
            <span>{t("login.user?")} </span>
            <Link to="/auth/register">
              <span className="text-muted"> {t("login.register")}</span>
            </Link>
          </Col>
        </Row>
      )}
    </Container>
  );
};

interface reduxStateProps {
  users: User;
}

const mapStateToProps = (state: AppState) => ({
  users: state.users,
});

interface DispatchProps {
  getUser: (userId: string) => void;
  getAuthToken: ({ email, password }: LoginInfo) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): DispatchProps => ({
  getUser: bindActionCreators(getUser, dispatch),
  getAuthToken: bindActionCreators(getAuthToken, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
