import LoadingActionTypes from "../types/actions/LoadingAction";

const loadingDefaultState: boolean = false;

const userReducer = (
  state = loadingDefaultState,
  action: LoadingActionTypes
): boolean => {
  switch (action.type) {
    case "SET_LOADING":
      return true;
    case "REMOVE_LOADING":
      return loadingDefaultState;
    default:
      return state;
  }
};

export default userReducer;
