import React from "react";
import styled from "styled-components";

// bs comp
import Container, { ContainerProps } from "@material-ui/core/Container";

interface PxContainerProps {}

type Prop = ContainerProps & PxContainerProps;

const PxContainer: React.FC<Prop> = styled((props: Prop) => {
  const { ...rest } = props;
  return <Container {...rest} />;
})`
  padding: 0;
`;

export default PxContainer;
