import React, { ReactNode } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import uuid from "uuid";

// models
import Alert from "../../types/models/Alert";

// action
import { setAlert } from "../../actions/alerts";
import { useTranslation } from "react-i18next";

interface PxCopyToClipProps {
  children: ReactNode;
  text: string;
}

const PxCopyToCip = ({ children, text }: PxCopyToClipProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", { useSuspense: false });
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        const alert: Alert = {
          id: uuid.v4(),
          msg: t("mainNavBar.copyToClipBoard"),
          alertType: "success",
        };
        dispatch(setAlert(alert));
      }}
    >
      {children}
    </CopyToClipboard>
  );
};

export default PxCopyToCip;
