import React, { ReactNode } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

interface CustomDialogProps {
  title?: string;
  open: boolean;
  children: ReactNode;
  onClose?: () => void;
}

const CustomDialog = ({
  open,
  children,
  title,
  onClose,
}: CustomDialogProps) => {
  return (
    <Dialog open={open} maxWidth="xs" onClose={onClose}>
      {title !== undefined && (
        <DialogTitle style={{ textAlign: "center", padding: "10px 24px" }}>
          {title}
        </DialogTitle>
      )}
      {children}
    </Dialog>
  );
};

export default CustomDialog;
