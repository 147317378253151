import React, { useState, useEffect, useContext } from "react";
import {
  useParams,
  RouteProps,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { subEventRoutes } from "../router/router";

// action
import { getSubEvent, removeSubEvent } from "../actions/subEvents";
import {
  getSubEventApplication,
  resetApplicationAction,
  resetApplicationsSateAction,
} from "../actions/applications";

// material ui
import Navbar from "../views/subEvent/Navbar";
import Sidebar from "../views/subEvent/Sidebar";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import CssBaseline from "@material-ui/core/CssBaseline";

// type
import RouteType from "../types/models/Route";

// jss styles
import subEventStyles from "../assets/jss/subEventStyles";
import SubEventProvider from "../components/Providers/SubEventProvider/SubEventProvider";
import { AppState } from "../store";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import { MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import PxBreakpoints, {
  theme as breakTheme,
} from "../components/PxBreakpoint/PxBreakpoint";
import ChatSidebar from "../views/subEvent/ChatSidebar";
import NotificationProvider from "../views/subEvent/NotificationProvider";
import { resetMeetingsStateAction } from "../actions/meeting";
import { ChatContext } from "../video/components/ChatProvider/ChatProvider";
import Tip from "../components/Tips/SubEventTips";
import MeetingTips from "../components/Tips/MeetingTips";
interface SubEventProps {
  width: Breakpoint;
}

const theme = createMuiTheme({
  overrides: {
    MuiToolbar: {
      root: {
        width: "100%",
        paddingLeft: "0 !important",

        border: "none",
        boxShadow: "none",
        "& .MTableToolbar-title-494": {
          width: "100%",
        },
        "& .MTableToolbar-spacer-492": {
          display: "none",
        },
      },
      gutters: {
        [breakTheme.breakpoints.up(600)]: {
          paddingLeft: "0px !important",
        },
        paddingLeft: 0,
        paddingRight: 0,
      },
      regular: {
        paddingLeft: 0,
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
      },
    },
  },
});

const SubEvent: React.FC<SubEventProps & RouteProps> = (props) => {
  const classes = subEventStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>(); // id => subEventId
  const location = useLocation();
  const { width } = props;
  const pathSplit = location.pathname.split("/");
  const { chatStateReset } = useContext(ChatContext);
  const [open, setOpen] = useState<boolean>(true);

  const dispatch = useDispatch();
  const subEventApplication = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );
  const { isAuthenticated } = useSelector((state: AppState) => state.users);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  // 서브이벤트 페이지에서 나오면 서브이벤트 관련 redux state 초기화 및 채팅관련 데이터 초기화
  useEffect(() => {
    return () => {
      dispatch(resetMeetingsStateAction());
      dispatch(resetApplicationsSateAction());
      chatStateReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (width === "md" || width === "lg" || width === "xl") {
      setOpen(true);
    }
  }, [width]);

  // 서브이벤트 어플리케이션의 승인값이 approval이 아닐때는 접속못하게 막음
  useEffect(() => {
    if (subEventApplication.approval !== undefined) {
      if (
        subEventApplication.approval !== "approval" &&
        subEvent?.id !== undefined
      ) {
        if (subEvent) {
          // 내정보 페이지가 아닌 다른 페이지로 이동 할 경우 모두 이벤트 상세 페이지로 보냄
          if (!history.location.pathname.includes("myInfo")) {
            history.push(`/event/detail/${subEvent.mainEvent!.id}`);
          }
        } else {
          history.push("/");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEventApplication, subEvent]);

  // 서브이벤트 페이지에 접근했을때의 validation
  const checkIfUserSubEventAttendedOrNot = async () => {
    const result: any = await dispatch(getSubEventApplication(id));

    if (result === false) {
      history.push("/");
    }
    dispatch(getSubEvent(id));
  };

  useEffect(() => {
    checkIfUserSubEventAttendedOrNot();

    if (
      !location.pathname.includes("/meeting") &&
      !location.pathname.includes("/subEvent")
    ) {
      dispatch(removeSubEvent());
      dispatch(resetApplicationAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let idList = [":id", ":secondId"];
  let idNumber = 0;
  // path 설정 ( 모양: /:id/program/:secondId , 규칙: key는 idList에 지정된 순번대로 사용 )
  let pathEquals = "";
  pathSplit.map((path: string, idx: number) => {
    if (idx > 1 && is_number(path) === false) {
      pathEquals += `/${path}`;
    } else if (idx > 1 && is_number(path) === true) {
      pathEquals += `/${idList[idNumber]}`;
      idNumber++;
    }
  });

  // url 키 여부(숫자) 체크
  function is_number(value: string) {
    const reg = /^(\d)+$/;
    return reg.test(value);
  }

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      if (prop.path === pathEquals) {
        return (
          <PrivateRoute
            path={`/subEvent${pathEquals}`}
            component={prop.component}
            key={key}
            isAuthenticated={isAuthenticated!}
          />
        );
      }
    });
  };

  return (
    <div className={classes.breakpoints}>
      <CssBaseline />
      <SubEventProvider>
        <NotificationProvider>
          {subEvent?.category === "consulting" && <Tip />}

          <PxBreakpoints>
            <MuiThemeProvider theme={theme}>
              <Navbar
                open={open}
                setOpen={setOpen}
                navBarStyle={classes}
                screenWidth={width}
              />
              <Sidebar
                open={open}
                setOpen={setOpen}
                sideBarStyles={classes}
                screenWidth={width}
              />
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: open,
                })}
              >
                {/* <div className={classes.drawerHeader} /> */}

                <Switch>{getRoutes(subEventRoutes)}</Switch>
              </main>
              <ChatSidebar />
            </MuiThemeProvider>
          </PxBreakpoints>
        </NotificationProvider>
      </SubEventProvider>
    </div>
  );
};

export default withWidth()(SubEvent);
