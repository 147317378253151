import React, { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import HeadlineTypo from "../../../../components/Typhography/HeadlineTypo";
import { AppState } from "../../../../store";
import { useMyInfoContext } from "./useMyInfoContext";

const TitleDiv = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

// 탭 버튼 디자인(기업정보 추가정보 담당자)
const InfoChangeTapButton = styled((props) => {
  const { active, ...rest } = props;
  return <button {...rest} />;
})`
  margin: 20px 14px 15px 0;
  padding: 6.5px 15px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  ${(props: any) => {
    if (props.active === true) {
      return `
          background-color: #616161;
          color: white;
        `;
    } else if (props.active === false) {
      return `
          background-color: white;
          color: #616161;
          border: 1px solid #ccc;
        `;
    }
  }}
`;

const PxStyledButton = styled(PxButton)`
  float: right;
  margin-top: 15px;
`;

const MyInfoTopView = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const {
    activeStep,
    setActiveStep,
    setModify,
    modify,
    setMyInfoModify,
    myInfoModify,
    onClickSubmitButton,
    additionalForm,
    onClickMyInfoModifySubmit,
  } = useMyInfoContext();
  const { id } = useSelector((state: AppState) => state.users); // user id
  const { applicantId } = useSelector(
    (state: AppState) => state.applications.subEventApplication
  );

  const setCompanyAndAdditionalInfoModifyButton = useCallback(() => {
    // 담당자일 경우는 수정버튼 x
    if (id === applicantId) {
      if (activeStep === 0 || activeStep === 1) {
        if (modify === false) {
          return (
            <PxStyledButton
              padding={"10px 15px"}
              backgroundcolor="purple"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setModify(!modify);
              }}
            >
              <ButtonTypo>{t("myInfo.modify")}</ButtonTypo>
            </PxStyledButton>
          );
        } else {
          return (
            <>
              <PxStyledButton
                padding={"10px 15px"}
                backgroundcolor="grey"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  setModify(!modify);
                }}
                style={{ marginLeft: 10 }}
              >
                <ButtonTypo>{t("myInfo.cancel")}</ButtonTypo>
              </PxStyledButton>
              <PxStyledButton
                padding={"10px 15px"}
                backgroundcolor="purple"
                onClick={onClickSubmitButton}
              >
                <ButtonTypo>{t("myInfo.confirm")}</ButtonTypo>
              </PxStyledButton>
            </>
          );
        }
      }
    } else {
      return <></>;
    }
  }, [activeStep, applicantId, id, modify, onClickSubmitButton, setModify, t]);

  const setMyInfoModifyButton = useCallback(() => {
    if (activeStep === 2) {
      if (myInfoModify === false) {
        return (
          <PxStyledButton
            padding={"10px 15px"}
            backgroundcolor="purple"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setMyInfoModify(!myInfoModify);
            }}
          >
            <ButtonTypo>{t("myInfo.modify")}</ButtonTypo>
          </PxStyledButton>
        );
      } else {
        return (
          <>
            <PxStyledButton
              padding={"10px 15px"}
              backgroundcolor="grey"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setMyInfoModify(!myInfoModify);
              }}
              style={{ marginLeft: 10 }}
            >
              <ButtonTypo>{t("myInfo.cancel")}</ButtonTypo>
            </PxStyledButton>
            <PxStyledButton
              padding={"10px 15px"}
              backgroundcolor="purple"
              onClick={onClickMyInfoModifySubmit}
            >
              <ButtonTypo>{t("myInfo.confirm")}</ButtonTypo>
            </PxStyledButton>
          </>
        );
      }
    }
  }, [activeStep, myInfoModify, onClickMyInfoModifySubmit, setMyInfoModify, t]);

  return (
    <Fragment>
      <TitleDiv>
        <HeadlineTypo>{t("myInfo.myInfoModify")}</HeadlineTypo>
      </TitleDiv>
      <div>
        <InfoChangeTapButton
          active={activeStep === 2 ? true : false}
          onClick={() => {
            setActiveStep(2);
          }}
        >
          {t("myInfo.me")}
        </InfoChangeTapButton>
        <InfoChangeTapButton
          active={activeStep === 0 ? true : false}
          onClick={() => {
            setActiveStep(0);
          }}
        >
          {t("myInfo.myInfo")}
        </InfoChangeTapButton>
        {additionalForm && additionalForm.formIds.length !== 0 && (
          <InfoChangeTapButton
            active={activeStep === 1 ? true : false}
            onClick={() => {
              setActiveStep(1);
            }}
          >
            {t("myInfo.additionalInfo")}
          </InfoChangeTapButton>
        )}
        {setMyInfoModifyButton()}
        {setCompanyAndAdditionalInfoModifyButton()}
      </div>
    </Fragment>
  );
};

export default MyInfoTopView;
