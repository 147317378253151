import { AppActions } from "../store";
import { ApplicantCompanyPage } from "../types/models/ApplicantCompany";
import { getRequest } from "../utils/utils";



export const getApplicantCompanyListAction = (
    applicantCompayList:ApplicantCompanyPage
): AppActions => ({
    type: "GET_APPLICANT_COMPANY_LIST",
    applicantCompanyList:applicantCompayList
})

export const getApplicantCompanyList = (
    data: {subEventId:string, type:string}, page: number = 0, pageSize: number = 10, searchWord: string
) => {
    const path = "/api/applications/participantList";

    return getRequest(null,path,getApplicantCompanyListAction,{subEventId:data.subEventId, type:data.type,page:page,pageSize:pageSize,searchWord:searchWord})
}