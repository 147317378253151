import React, { useEffect } from "react";
import { FormControl, MenuItem, Typography, Select } from "@material-ui/core";
import { useAppState } from "../../AppStateProvider/hooks/useAppState";
import { useAudioOutputDevices } from "../deviceHooks/deviceHooks";
import { useTranslation } from "react-i18next";

export default function AudioOutputList() {
  const audioOutputDevices = useAudioOutputDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(
    (device) => device.deviceId === activeSinkId
  )?.label;
  useEffect(() => {
    console.log("audioOutputDevices :>> ", audioOutputDevices);
  }, [audioOutputDevices]);
  const [t] = useTranslation("lang", { useSuspense: false });

  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="h6">
            {t("videoController.audioOutput")}
          </Typography>
          <Select
            onChange={(e) => {
              localStorage.setItem(
                "audioOutputDeviceId",
                e.target.value as string
              );
              setActiveSinkId(e.target.value as string);
            }}
            value={activeSinkId}
          >
            {audioOutputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="h6">
            {t("videoController.audioOutput")}
          </Typography>
          <Typography>
            {activeOutputLabel || "System Default Audio Output"}
          </Typography>
        </>
      )}
    </div>
  );
}
