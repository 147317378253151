import React, { useCallback, useEffect, useState } from "react";
import ReactEmoji from "react-emoji";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../components/Grid/PxGridItem";
import Body2Typo from "../../../components/Typhography/Body2Typo";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import CompanyTypo from "../../../components/Typhography/CompanyTypo";
import SubHeadingTypo from "../../../components/Typhography/SubHeadingTypo";
import { AppState } from "../../../store";
import ToggleUserNameButton from "../../../video/components/ToggleChatButton/ToggleChatInfoDialog";
import "./Message.css";
interface MessageProps {
  text: string;
  author: { id: string; name: string; sid?: string };
  index: number;
}

const Message = ({ text, author, index }: MessageProps) => {
  const [isSentByCurrentUser, setIsSentByCurrentUser] = useState<boolean>(
    false
  );
  const history = useHistory();
  const myBusinessCard = useSelector(
    (state: AppState) => state.users.subEventBusinessCard
  );

  // 유저의 메세지 상태설정
  useEffect(() => {
    if (myBusinessCard && myBusinessCard.id) {
      if (author.id == myBusinessCard.id.toString()) {
        setIsSentByCurrentUser(true);
      } else {
        setIsSentByCurrentUser(false);
      }
    }
  }, [author.id, author.name, myBusinessCard]);

  // twilio chat identity 형식을 이름으로만 보여지도록 변경해줌 => "명함이름/명함회사이름" to "명함이름"
  const setUserName = useCallback(() => {
    return author.name.split("|")[0];
  }, [author]);

  const showMessage = () => {
    if (text.includes("#myInfo")) {
      const splitedText = text.split("/"); // #myInfo/회사이름/이름/직책/이메일/전화번호
      return (
        <PxGridContainer direction="column" className="infoMessage">
          <PxGridItem>
            <CompanyTypo>{splitedText[1]}</CompanyTypo>
          </PxGridItem>
          <PxGridContainer direction="row" alignItems="center">
            <SubHeadingTypo>{splitedText[2]}</SubHeadingTypo>
            <CaptionTypo style={{ paddingLeft: 4 }}>
              {splitedText[3] === "null" ? "" : splitedText[3]}
            </CaptionTypo>
          </PxGridContainer>
          <PxGridItem style={{ paddingLeft: 8 }}>
            <Body2Typo>{splitedText[4]}</Body2Typo>
            <Body2Typo>{splitedText[5]}</Body2Typo>
          </PxGridItem>
        </PxGridContainer>
      );
    } else {
      return isSentByCurrentUser ? (
        <div className="messageContainer justifyEnd">
          <p className="sentText pr-10">{setUserName()}</p>
          <div className="messageBox backgroundBlue">
            <p className="messageText colorWhite">{ReactEmoji.emojify(text)}</p>
          </div>
        </div>
      ) : (
        <div className="messageContainer justifyStart">
          <div className="messageBox backgroundLight">
            <p className="messageText colorDark">{ReactEmoji.emojify(text)}</p>
          </div>
          {/* 영상회의에서 채팅 */}
          {history.location.pathname.includes("video") ? (
            <ToggleUserNameButton
              identity={author.name}
              index={index}
              sid={author.sid}
            />
          ) : (
            <p className="sentText pl-10">{setUserName()}</p>
          )}
        </div>
      );
    }
  };

  return showMessage();
};

export default Message;
