import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";
import moment from "moment";

// actions

// custom ui comp
import PxTable from "../../../../components/Tables/PxTable";
import { useTranslation } from "react-i18next";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import { getSubEventMails } from "../../../../actions/mail";
import { MailInfo } from "../../../../types/models/Mail";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import SubEventHeading from "../../../../components/Typhography/SubEventHeading";

interface RowType {
  id: string;
  code: string;
  title: string;
  content: string;
  createdDate: string;
  createdUserId: string;
  subEventId: string;
  toMail: string;
  opened: "Y" | "N";
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}
type actionData = { subEventId: string; userId: number };

const MailList: React.FC<RouteComponentProps> = ({ match }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  const mails = useSelector((state: AppState) => state.mails.mails);
  const user = useSelector((state: AppState) => state.users);
  const [actionData, setActionData] = useState<actionData>({
    subEventId: param.id,
    userId: user.id || 0,
  });
  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("mailList.title"),
        field: "title",
        cellStyle: {
          width: "calc(100%-500px)",
          paddingRight: 0,
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("mailList.content"),
        field: "content",
        cellStyle: {
          maxWidth: "300px",
          fontSize: "15px",
          color: "rgba(0,0,0,0.38)",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("mailList.sentDate"),
        field: "createdDate",
        width: "120px",
        cellStyle: { width: "120px", paddingRight: 0 },
      },
    ],
    data: [],
  });

  useLayoutEffect(() => {
    if (user.id && user.id !== actionData.userId)
      setActionData({ subEventId: param.id, userId: user.id });
  }, [user.id, param.id]);

  useEffect(() => {
    let tableData: RowType[] = [];
    if (mails && Array.isArray(mails.content) && mails.content.length > 0) {
      mails.content.map((mail: MailInfo) => {
        const rowData: RowType = {
          title: mail.title,
          id: mail.id || "",
          code: mail.code || "",
          content: getContent(mail.content),
          createdDate: moment(mail.createdDate).format("YYYY-MM-DD"),
          createdUserId: mail.createdUserId || "",
          subEventId: mail.subEventId || "",
          toMail: mail.toMail || "",
          opened: mail.opened || "N",
        };
        tableData.push(rowData);
      });
      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [mails]);

  const getContent = (content: string) => {
    const item = content.split("^&");
    const type = item[0];
    switch (type) {
      case "newMeeting_kr":
      case "meetingRequest_kr":
        return `${item[3]}님 안녕하세요. ${
          type === "meetingRequest_kr" ? item[14] && item[14] : ""
        } 이벤트명: ${item[5]}(${item[6]}) 기간: ${item[7]}(GMT+9) ~ ${
          item[8]
        }(GMT+9) 요청자: ${item[9]} 대상자: ${item[10]}  미팅일시: ${
          item[11]
        }(GMT+9) ~ ${item[12]}(GMT+9)`;
      case "newMeeting_en":
      case "meetingRequest_en":
        return `Hello, ${item[3]} ${item[4]} Event Name: ${item[5]}(${item[6]}) Period: ${item[7]}(GMT+9) ~ ${item[8]}(GMT+9) Applicant: ${item[9]} Acceptor: ${item[10]}  Meeting time: ${item[11]}(GMT+9) ~ ${item[12]}(GMT+9)`;
      case "meetingRejected_en":
      case "meetingCancelled_en":
        return `Hello, ${item[3]} ${item[4]} Event Name: ${item[5]}(${item[6]}) Period: ${item[7]}(GMT+9) ~ ${item[8]}(GMT+9) Applicant: ${item[9]} Acceptor: ${item[10]}  Meeting time: ${item[11]}(GMT+9) ~ ${item[12]}(GMT+9)`;
      case "meetingRejected_kr":
      case "meetingCancelled_kr":
        return `${item[3]}님 안녕하세요. ${item[13] && item[13]}  이벤트명: ${
          item[4]
        }(${item[5]}) 기간: ${item[6]}(GMT+9) ~ ${item[7]}(GMT+9) 요청자: ${
          item[8]
        } 대상자: ${item[9]}  미팅일시: ${item[10]}(GMT+9) ~ ${
          item[11]
        }(GMT+9)`;
      case "eventApply_kr":
        return `${item[1]}님 안녕하세요. 이벤트에 참가해주셔서 감사합니다. 이벤트 명: ${item[2]} (${item[5]})  기간:${item[3]}(GMT+9) ~${item[4]}(GMT+9) 서브이벤트명:${item[5]} 서브이벤트 기간:${item[6]}(GMT+9) ~${item[7]}(GMT+9) 신청일시:${item[8]}`;
      case "eventApply_en":
        return `Hello, ${item[1]} Thank you for participating in the event. Event Name: ${item[2]} (${item[5]}) Period:${item[3]}(GMT+9) ~${item[4]}(GMT+9) Sub-event name:${item[5]} Sub-event period:${item[6]}(GMT+9) ~${item[7]}(GMT+9) Application time:${item[8]}`;
      case "eventApplyApproved_kr":
        return `${item[1]}님 안녕하세요.  회원님은 참가신청한 이벤트에 점속하여 전용메뉴를 사용할 수 있습니다.  이벤트 명: ${item[2]}  기간: ${item[3]}(GMT+9) ~ ${item[4]}(GMT+9)  참가신청 일시: ${item[5]}(GMT+9)  주최자 확정: ${item[6]}`;
      case "eventApplyApproved_en":
        return `Hello, ${item[1]}.  You can access the event and use the private menu.  Event Name: ${item[2]}Period: ${item[3]}(GMT+9) ~ ${item[4]}(GMT+9)  Participating application: ${item[5]}(GMT+9) Host Confirmation: ${item[6]}`;
      case "eventDday_kr":
        return `${item[1]}님 안녕하세요. 참여하신 이벤트가 하루 앞으로 다가왔습니다. 이벤트 명: ${item[2]} 기간: ${item[3]}(GMT+9) ~ ${item[4]}(GMT+9)`;
      case "eventDday_en":
        return `Hello, ${item[1]}. The event you participated in is just a day left.  Event Name: ${item[2]} Period: ${item[3]}(GMT+9) ~ ${item[4]}(GMT+9)`;
      default:
        return content.replace(/(<([^>]+)>)/gi, "");
    }
  };

  return (
    <>
      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return (
              <PxGridContainer direction="column">
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        title={
          <SubEventHeading>{t("subEventSideBar.mailing")}</SubEventHeading>
        }
        columns={state.columns}
        actionData={actionData}
        totalPage={mails?.totalPages}
        totalElements={mails?.totalElements}
        getAction={getSubEventMails}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.id}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
          rowStyle: (data, index, level) => {
            if (data.opened === "Y")
              return { backgroundColor: "rgba(0,0,0,0.06)" };
            return {};
          },
        }}
      />
    </>
  );
};

export default MailList;
