import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import CreateIcon from "@material-ui/icons/Create";

import { useAppState } from "../AppStateProvider/hooks/useAppState";
import CaptionTypo from "../../../components/Typhography/CaptionTypo";
import { ChatContext } from "../ChatProvider/ChatProvider";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@keyframes blinker": {
      "0%": { backgroundColor: "#e0e0e0" },
      "50%": { backgroundColor: "red" },
      "100%": { backgroundColor: "#e0e0e0" },
    },
    fab: {
      animationName: "$blinker",
      animationDuration: "1s",
      animationIterationCount: "5",

      boxShadow: "none",
      backgroundColor: "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
    },
  })
);

export default function ToggleSurveyFormButton(props: {
  disabled?: boolean;
  backgroundColor?: string;
}) {
  const classes = useStyles();
  const {
    isSurveyFormEnabled,
    toggleSurveyFormEnabled,
    toggleMultiScreenEnabled,
  } = useAppState();
  const { setVideoChatSidebarOpen } = useContext(ChatContext);
  const [t] = useTranslation("lang", { useSuspense: false });

  return (
    <Tooltip
      title="Meeting Note"
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        className={classes.fab}
        onClick={() => {
          toggleSurveyFormEnabled(!isSurveyFormEnabled);
          toggleMultiScreenEnabled(false);
          setVideoChatSidebarOpen(false);
        }}
        disabled={props.disabled}
        style={isSurveyFormEnabled ? { color: "#bb86fc" } : {}}
      >
        <div>
          <CreateIcon />
          <CaptionTypo txtcolor="white">
            {t("videoController.consultation")}
          </CaptionTypo>
        </div>
      </Fab>
    </Tooltip>
  );
}
