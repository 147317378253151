import SubEventActionTypes from "../types/actions/SubEventAction";
import SubEvent from "../types/models/SubEvent";

const subEventDefaultState: SubEvent = {
  subEvent: {
    eventId: "", // event pk
    matching: "", // 매칭 여부 (Y/N)
    sgroupName: "", // 참가그룹 A 명칭 (기존 셀러)
    bgroupName: "", // 참가그룹 B 명칭 (기존 바이어)
    // targetScope: "", // 참가기업 노출범위
    subEventStartDate: "", // 서브 이벤트 시작날짜
    subEventEndDate: "", // 서브 이벤트 끝날짜
    sellRegStartDate: "", // 셀러 참가신청 시작날짜
    sellRegEndDate: "", // 셀러 참가신청 끝날짜
    buyRegStartDate: "", // 바이어 참가신청 시작날짜
    buyRegEndDate: "", // 바이어 참가신청 끝날짜
    regStartDate: "", // 참가자 참가신청 시작날짜
    regEndDate: "", // 참가자 참가신청 끝날짜
    matchStartTime: "", // 매칭(미팅) 시작시간
    matchEndTime: "", // 매칭(미팅) 종료시간
    matchReqTime: 0, // 매칭(미팅) 소요시간
    matchBreakTime: 0, // 매칭(미팅) 휴식시간
    matchNumPerDay: 0, // 일일 매칭(미팅) 횟수
    onSiteRegit: "", // 현장등록 참관객 여부 (Y/N)
    session: "", // 세션 여부 (Y/N)
    meetingData: "", // 미팅 데이타 리스트
    meetingDataJson: [], // 미팅 데이타 json
  },
  subEvents: [],
};

const subEventReducer = (
  state = subEventDefaultState,
  action: SubEventActionTypes
): SubEvent => {
  switch (action.type) {
    case "GET_SUB_EVENTS":
      return { ...state, subEvents: action.subEvents };
    case "GET_SUB_EVENT":
      return { ...state, subEvent: action.subEvent };
    case "REMOVE_SUB_EVENT":
      return { ...state, subEvent: subEventDefaultState.subEvent };
    case "REMOVE_SUB_EVENTS":
      return { ...state, subEvents: [] };
    default:
      return state;
  }
};

export default subEventReducer;
