import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getEventsAll } from "../../../actions/events";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Host from "../../../assets/icons/bannerIcon.svg";

// types
import Event, { EventInfo } from "../../../types/models/Event";

// material ui custom comp
import GridContainer from "../../../components/Grid/PxGridContainer";
import GridItem from "../../../components/Grid/PxGridItem";

import PxButton from "../../../components/Buttons/PxButton";

import CardGridContainer from "../../../components/Grid/CardGrid/CardGridContainer";
// icons
// import FilterListIcon from "@material-ui/icons/FilterList";

// test images
import ButtonTypo from "../../../components/Typhography/ButtonTypo";
import HeadlineTypo from "../../../components/Typhography/HeadlineTypo";

import Body1Typo from "../../../components/Typhography/Body1Typo";

const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin-top: 40px;
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
`;

const HostNoticeBanner = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 24px 26px 32px;
  border-radius: 5px;
  border: solid 1px rgba(81, 65, 153, 0.1);
  background-color: rgba(81, 65, 153, 0.05);
  justify-content: space-between;
  margin-bottom: 40px;
  @media only screen and (max-width: 599px) {
    display: none;
  }
`;

const HomeBottomView = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const { events: eventList } = useSelector(
    (state: { events: Event }) => state.events
  );

  useEffect(() => {
    if (eventList!.content && eventList!.content.length <= 0) {
      dispatch(getEventsAll());
    }
  }, [eventList!.content]);

  const testArr: EventInfo[] = eventList!.content!;

  const moreEventBtn = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const result: any = await dispatch(
      getEventsAll(eventList!.number! + 1, true, eventList!.content!)
    );
    return <Link to="/list" />;
  };

  return (
    <Container>
      <GridItem>
        <GridContainer>
          <HostNoticeBanner>
            <div style={{ display: "flex", width: "70%" }}>
              <img src={Host} height="100%" />
              <div style={{ marginLeft: 20 }}>
                <div style={{ display: "flex" }}>
                  <Body1Typo fontSize="1.063rem" fontWeight="bold">
                    <Trans t={t} i18nKey="home.bannerMsgTitle">
                      <span style={{ color: "#512da8" }}>이벤트 주최자</span>
                      이신가요?
                    </Trans>
                  </Body1Typo>
                </div>
                <Body1Typo txtcolor="black0.6" fontSize="1rem">
                  {t("home.bannerMsgSubTitle")}
                </Body1Typo>
              </div>
            </div>
            <div>
              {" "}
              <PxButton
                backgroundcolor="bluePurple"
                txtColor="white"
                onClick={() => {
                  window.open("https://host.pexpo.io");
                }}
                boxShadow={false}
              >
                {t("home.open")}
              </PxButton>
            </div>
          </HostNoticeBanner>
        </GridContainer>
      </GridItem>

      <GridContainer
        justify="space-between"
        alignItems="baseline"
        // parent={true}
      >
        <GridItem>
          <GridContainer direction="row">
            <GridItem>
              <HeadlineTypo>{t("home.newEvent")}</HeadlineTypo>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Contents>
          <CardGridContainer
            eventList={testArr?.slice(undefined, 12)}
          ></CardGridContainer>
        </Contents>
      </GridContainer>

      <GridContainer justify="center" className="p-4">
        {eventList && (
          <Link to="/list">
            <PxButton
              backgroundcolor="transparent"
              border={false}
              onClick={moreEventBtn}
            >
              <ButtonTypo txtcolor={"purple"}> {t("home.more")}</ButtonTypo>
            </PxButton>
          </Link>
        )}
      </GridContainer>
    </Container>
  );
};

export default HomeBottomView;
