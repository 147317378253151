import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PxButton from "../../../../components/Buttons/PxButton";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { changeMeetingState } from "../../../../actions/meeting";
import { useParams } from "react-router-dom";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../../actions/loading";
import { getApplicationMeetings } from "../../../../actions/applications";
import { setAlertAction } from "../../../../actions/alerts";
import {
  ChangeMeetingStateInterface,
  MeetingInfo,
} from "../../../../types/models/Meeting";
import { useTranslation } from "react-i18next";

interface MeetingApproveOrRejectDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // setMeetingDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  status: "agree" | "disagree" | undefined;
  meeting?: MeetingInfo;
  isConfirmed?: boolean; // 체결된 미팅을 취소(disagree) 상태로 변경할 경우, 확인용 (dialog 문구 변경)
  meetingDateAndTime?: {
    date?: string;
    time?: string;
    showDate?: string;
    showTime?: string;
  };
}

const MeetingApproveOrRejectDialog = ({
  open,
  // setMeetingDialogOpen,
  setOpen,
  meeting,
  status,
  isConfirmed,
  meetingDateAndTime,
}: MeetingApproveOrRejectDialogProps) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const { application, subEventApplication } = useSelector(
    (state: AppState) => state.applications
  );

  const onClose = () => {
    setOpen(false);
  };

  const onClickConfirm = async () => {
    if (subEventApplication.id && application.id && meeting && status) {
      // 미팅신청 data
      const meetingData: ChangeMeetingStateInterface = {
        subEventId: params.id,
        acceptorId: subEventApplication.id!,
        meetingId: `${meeting.date}${meeting.startTime}${meeting.endTime}`.trim(),
        meetingsId: meeting.id!,
        status: status,
        mailPostToggleAt: "true",
      };

      setOpen(false);
      // setMeetingDialogOpen(false);
      dispatch(setLoadingAction());
      const result: any = await dispatch(changeMeetingState(meetingData));
      if (result) {
        // await dispatch(
        //   getApplicationMeeting({
        //     myApplicationId: subEventApplication.id,
        //     otherApplicationId: application.id,
        //   })
        // );
        await dispatch(
          getApplicationMeetings(application.id, subEventApplication.id)
        );
        dispatch(
          setAlertAction({
            id: "meetingConfirmed",
            msg: isConfirmed
              ? t("meetingApproveOrRejectDialog.cancelDialog")
              : status === "agree"
              ? t("meetingApproveOrRejectDialog.agreeDialog")
              : t("meetingApproveOrRejectDialog.rejectDialog"),
            alertType: "success",
          })
        );
      }
      dispatch(removeLoadingAction());
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {isConfirmed
          ? t("meetingApproveOrRejectDialog.meetingCancel")
          : status === "agree"
          ? t("meetingApproveOrRejectDialog.meetingAgree")
          : t("meetingApproveOrRejectDialog.meetingReject")}
      </DialogTitle>
      <DialogContent>
        <Body1Typo>
          {t("meetingApproveOrRejectDialog.company")}: {application.name}
        </Body1Typo>
        <Body1Typo>
          {t("meetingApproveOrRejectDialog.date")}:{" "}
          {`${meetingDateAndTime?.showDate} ${meetingDateAndTime?.showTime}`}
          {/* {`${meeting?.date} ${meeting?.startTime} ~ ${meeting?.endTime}(GMT+9)`} */}
        </Body1Typo>
      </DialogContent>
      <DialogActions>
        <PxButton disableElevation onClick={onClose}>
          <ButtonTypo>{t("meetingApproveOrRejectDialog.cancel")}</ButtonTypo>
        </PxButton>
        <PxButton
          disableElevation
          backgroundcolor="purple"
          onClick={onClickConfirm}
        >
          <ButtonTypo>{t("meetingApproveOrRejectDialog.confirm")}</ButtonTypo>
        </PxButton>
      </DialogActions>
    </Dialog>
  );
};

export default MeetingApproveOrRejectDialog;
