import React from "react";
import styled from "styled-components";

// material ui comp
import TextField, { OutlinedTextFieldProps } from "@material-ui/core/TextField";

// Partial <> 안의 모든 props들을 optional로 바꿈
interface PxOutlinedTxtFieldProps extends Partial<OutlinedTextFieldProps> {
  padding?: string;
  inputpadding?: number;
  fontsize?: number;
  resizeVertical?: boolean;
}

const PxOutlinedTxtField: React.FC<PxOutlinedTxtFieldProps> = styled(
  (props: PxOutlinedTxtFieldProps) => {
    const { padding, fontsize, inputpadding, resizeVertical, ...rest } = props;
    return (
      <TextField
        variant="outlined"
        inputProps={{
          style: {
            padding: inputpadding,
            fontSize: fontsize,
            resize: resizeVertical === true ? "vertical" : "none",
          },
        }}
        {...rest}
      />
    );
  }
)`
  padding: ${(props) => (props.padding == null ? "0" : props.padding)};
`;

PxOutlinedTxtField.defaultProps = {};

export default PxOutlinedTxtField;
