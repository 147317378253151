// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect } from "react";
import {
  Flex,
  SecondaryButton,
} from "amazon-chime-sdk-component-library-react";

import MeetingForm from "../MeetingForm";
import SIPMeeting from "../SIPMeeting";
import useToggle from "../../hooks/useToggle";
import SIPMeetingProvider from "../../providers/SIPMeetingProvider";
import { StyledDiv, StyledWrapper } from "./Styled";
import { useHistory, useParams } from "react-router-dom";

const MeetingFormSelector: React.FC = () => {
  const { isActive, toggle } = useToggle(false);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    if (parseInt(id) > 10 || parseInt(id) < 1) {
      history.replace("/");
    }
  }, []);

  const formToShow = isActive ? (
    <SIPMeetingProvider>
      <SIPMeeting />
    </SIPMeetingProvider>
  ) : (
    <MeetingForm />
  );
  const buttonText = isActive ? "Join without SIP" : "Join via SIP";

  return (
    <StyledWrapper>
      <StyledDiv style={{ display: "flex", alignItems: "center" }}>
        {formToShow}
      </StyledDiv>
      {/* <Flex container layout="fill-space-centered" style={{ padding: '2rem' }}>
        <SecondaryButton label={buttonText} onClick={toggle} />
      </Flex> */}
    </StyledWrapper>
  );
};

export default MeetingFormSelector;
