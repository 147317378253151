import React, { Fragment } from "react";
import MyInfoContentView from "./MyInfoContentView";
import MyInfoProvider from "./MyInfoProvider";
import MyInfoTopView from "./MyInfoTopView";

const MyInfo = () => {
  return (
    <Fragment>
      <MyInfoProvider>
        <MyInfoTopView />
        <MyInfoContentView />
      </MyInfoProvider>
    </Fragment>
  );
};

export default MyInfo;
