import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import TitleTypo from "../../../../components/Typhography/TitleTypo";
import { useDispatch, useSelector } from "react-redux";
import {
  getWebinar,
  getWebinarQA,
  resetWebinarAction,
} from "../../../../actions/webinar";
import { AppState } from "../../../../store";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import { Webinar } from "../../../../types/models/Webinar";
import { parallaxCalculation } from "../../../../utils/momentTz";
import styled from "styled-components";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import { getOnlyFileName } from "../../../../utils/utils";
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";
import Video from "../../../../video";
import Box from "@material-ui/core/Box";
import Body3Typo from "../../../../components/Typhography/Body3Typo";
import { Divider } from "@material-ui/core";
import Question from "./WebinarQuestion";
import WebinarTextAera from "./WebinarTextAera";
import Pagination from "../../../../components/Pagination/Pagination";

const WebinarContentDiv = styled.div`
  img {
    max-width: 100%;
  }
  line-height: 1.75;
  color: #616161;
  min-height: 140px;
  margin-top: 16px;
  margin-bottom: 20px;
`;

const PresenterAvatar = styled(Avatar)`
  margin: 0 10px;
  background-color: lightgrey;
  width: 40px;
  height: 40px;
`;

const useStyles = makeStyles((theme) => ({
  arrowBackIcon: {
    cursor: "pointer",
    margin: 12,
    width: 24,
    height: 24,
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  contentRoot: {
    marginTop: 24,
  },
  presenterCardRoot: {
    marginTop: 24,
    display: "flex",
  },
  presenterCardDetails: {
    paddingLeft: 10,
    display: "flex",
    flexDirection: "column",
  },
  presenterCardContent: {
    flex: "1 0 auto",
    padding: 0,
  },
  fileButton: {
    marginRight: 10,
  },
  button: {
    minWidth: "auto",
  },
}));

const WebinarDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation("lang", { useSuspense: false });
  const { secondId: webinarId } = useParams<{ secondId: string }>();
  const dispatch = useDispatch();
  const webinar = useSelector((state: AppState) => state.webinars.webinar);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { country, countries } = useSelector(
    (state: AppState) => state.countries
  );
  const user = useSelector((state: AppState) => state.users);
  const webinarQA = useSelector((state: AppState) => state.webinars.webinarQA);
  const [webinarData, setWebinarData] = useState<Webinar>();
  const [videoDialogOpen, setVideoDialogOpen] = useState<boolean>(false);
  const [qaSelect, setQASelect] = useState<"" | "complete" | "incomplete">("");
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    dispatch(getWebinar(webinarId));
    dispatch(getWebinarQA(webinarId, qaSelect, page));

    return () => {
      // 영상통화 접속일때는 webinar redux data 초기화x (유저 subscription 설정 시 사용)
      if (!history.location.pathname.includes("video")) {
        dispatch(resetWebinarAction());
      }
    };
  }, [dispatch, history.location.pathname, webinarId]);
  useEffect(() => {
    dispatch(getWebinarQA(webinarId, qaSelect, page));
  }, [qaSelect, page]);

  useEffect(() => {
    if (webinar && subEvent?.mainEvent && countries && country) {
      const countryCode = subEvent.mainEvent.countryCode;

      const newWebinarData = {
        ...webinar,
        startDate: parallaxCalculation(
          webinar.startDate,
          countries[countryCode as string] as any,
          countries[country.countryCode!] as any,
          "YYYY-MM-DD HH:mm"
        ),
        endDate: parallaxCalculation(
          webinar.endDate,
          countries[countryCode as string] as any,
          countries[country.countryCode!] as any,
          "YYYY-MM-DD HH:mm"
        ),
      };

      setWebinarData(newWebinarData);
    }
  }, [countries, country, subEvent, webinar]);

  const onClickFileDownLoad = (filePath: string, fileName: string) => {
    FileSaver.saveAs(filePath, fileName);
  };

  const onClickZoomOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    if (webinar?.zoomLink) {
      window.open(webinar.zoomLink, "_blank");
    } else {
      alert("줌링크가 없습니다. 담당자에게 문의 부탁드립니다.");
    }
  };

  return (
    <div>
      {videoDialogOpen === true && (
        <Video
          videoDialogOpen={videoDialogOpen}
          setVideoDialogOpen={setVideoDialogOpen}
        />
      )}
      <PxGridContainer alignItems="center" justify="space-between">
        <PxGridItem>
          <ArrowBackIcon
            className={classes.arrowBackIcon}
            onClick={() => history.goBack()}
          />
        </PxGridItem>
        {/* 입장하기 */}
        <PxGridItem>
          <PxButton
            disabled={webinar?.zoomLink ? false : true}
            backgroundcolor="purple"
            onClick={onClickZoomOpen}
          >
            <ButtonTypo>
              {webinar?.zoomLink ? t("webinar.enter") : t("webinar.preparing")}
            </ButtonTypo>
          </PxButton>
        </PxGridItem>
      </PxGridContainer>
      <div className={classes.contentRoot}>
        <TitleTypo fontSize={20}>{webinarData?.title}</TitleTypo>
        <Body1Typo txtcolor={"black0.38"}>
          {webinarData?.startDate} - {webinarData?.endDate}
        </Body1Typo>
        {webinar?.content && (
          <WebinarContentDiv
            dangerouslySetInnerHTML={{ __html: webinar!.content }}
          />
        )}
        {webinar?.atchFiles &&
          webinar.atchFiles.length > 0 &&
          webinar.atchFiles.map((file, index) => (
            <PxButton
              key={`file-${index}`}
              className={classes.fileButton}
              backgroundcolor="grey"
              startIcon={<AttachFileIcon />}
              onClick={(e) => {
                e.preventDefault();
                onClickFileDownLoad(
                  file.filePath,
                  getOnlyFileName(file.fileName, file.fileType)
                );
              }}
            >
              <ButtonTypo>
                {getOnlyFileName(file.fileName, file.fileType)}
              </ButtonTypo>
            </PxButton>
          ))}
        {webinar?.presenters && webinar.presenters.length > 0 && (
          <Card className={classes.presenterCardRoot} elevation={0}>
            <PresenterAvatar />
            <PxGridContainer direction="column">
              {webinar.presenters[0].applicants &&
                webinar.presenters[0].applicants.map((a, index) => (
                  <Fragment key={`presenter-${index}`}>
                    <SubHeadingTypo>{a.businessCard.name}</SubHeadingTypo>
                    <div>
                      <Body1Typo txtcolor="black0.6">
                        {a.businessCard.email}{" "}
                        {`(${a.businessCard.countryNumber})${a.businessCard.phoneNumber}`}
                      </Body1Typo>
                    </div>
                  </Fragment>
                ))}
            </PxGridContainer>
          </Card>
        )}
        <Box marginY="40px">
          <PxGridContainer direction="column" justify="space-between">
            <TitleTypo fontSize={21} fontweight="400" gutterBottom>
              {t("webinar.questions")}
            </TitleTypo>
            <WebinarTextAera />
            <PxGridContainer
              style={{ gap: 20, marginTop: 20, marginBottom: 10 }}
              justify="space-between"
              alignItems="flex-end"
            >
              <PxGridContainer
                style={{ gap: 15, width: "initial" }}
                justify="space-between"
                wrap="nowrap"
              >
                <PxButton
                  padding="0px"
                  className={classes.button}
                  border={false}
                  hover={false}
                  outline={false}
                  onClick={() => setQASelect("")}
                >
                  <Body3Typo
                    txtcolor={qaSelect !== "" ? "secondary" : undefined}
                  >
                    {t("webinar.all")}
                  </Body3Typo>
                </PxButton>
                <PxButton
                  className={classes.button}
                  padding="0px"
                  hover={false}
                  border={false}
                  outline={false}
                  onClick={() => setQASelect("complete")}
                >
                  <Body3Typo
                    txtcolor={qaSelect !== "complete" ? "secondary" : undefined}
                  >
                    {t("webinar.complete")}
                  </Body3Typo>
                </PxButton>
                <PxButton
                  className={classes.button}
                  padding="0px"
                  hover={false}
                  border={false}
                  outline={false}
                  onClick={() => setQASelect("incomplete")}
                >
                  <Body3Typo
                    txtcolor={
                      qaSelect !== "incomplete" ? "secondary" : undefined
                    }
                  >
                    {t("webinar.incomplete")}
                  </Body3Typo>
                </PxButton>
              </PxGridContainer>
              <Body1Typo fontSize="0.825rem" txtcolor="secondary">
                &nbsp;{webinarQA.totalElements}&nbsp;Questions
              </Body1Typo>
            </PxGridContainer>
            <Divider />
            {webinarQA &&
            webinarData &&
            countries &&
            country &&
            webinarQA.content.length > 0
              ? webinarQA.content.map((qa) => (
                  <Question
                    key={qa.id}
                    webinarQAData={qa}
                    countries={countries}
                    country={country}
                    countryCode={subEvent?.mainEvent?.countryCode}
                    webinar={webinarData}
                    user={user}
                  />
                ))
              : null}
          </PxGridContainer>
          <Pagination
            page={page}
            totalPage={webinarQA.totalPages}
            onChange={(e, page) => setPage(page - 1)}
          />
        </Box>
      </div>
    </div>
  );
};

export default WebinarDetail;
